import { PageHeader } from 'antd';
import PageContainer from 'components/PageContainer';
import styles from './index.module.scss';
import AuditLogTable from './AuditLogTable';

const AuditLogList = () => {
  return (
    <PageContainer>
      <PageHeader
        title={
          <div className={styles.flexContainer}>
            <span>Audit Log</span>
          </div>
        }
      />

      <AuditLogTable />
    </PageContainer>
  );
};

export default AuditLogList;
