import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _debounce from 'lodash/debounce';
import { useFormContext } from 'react-hook-form';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getPublishedPlansByName } from 'actions/plans';
import { BasicPublishedPlan } from 'types/plan';
import { Link } from 'react-router-dom';
import {
  TaxConfigurationForm,
  TaxConfigurationFormValue,
  TaxProduct,
  TaxProductTypes,
} from '../../types';
import styles from './index.module.scss';
import { TaxProductsProps } from './types';
import AddTaxProductsModal from './AddProductsModal';

const debounceTimeout = 300;

const TaxProducts = ({
  isModalOpen,
  onModalClose,
  data,
  isLoading,
}: TaxProductsProps) => {
  const [tableState, setTableState] = useState<TaxProduct[]>([]);
  const [editingTaxProducts, setEditingTaxProducts] = useState<TaxProduct[]>(
    []
  );
  const { setValue } = useFormContext<TaxConfigurationForm>();
  const { isLoading: isLoadingProducts, data: products } = useFetchActionObj<
    BasicPublishedPlan[]
  >(getPublishedPlansByName);

  useEffect(() => {
    if (data) setTableState(data.filter((taxProduct) => taxProduct.resourceId));
  }, [data]);

  const handleOnAddTaxProducts = useCallback(
    (taxProducts: TaxProduct[]) => {
      const newState = [...tableState, ...taxProducts];
      setTableState(newState);
      setValue(TaxConfigurationFormValue.TAX_CODES, newState);
      onModalClose();
    },
    [onModalClose, setValue, tableState]
  );

  const handleDelete = useCallback(
    (id: string) => {
      const filteredState = tableState.filter(
        (taxProduct) => taxProduct.resourceId !== id
      );
      setTableState(filteredState);
      setValue(TaxConfigurationFormValue.TAX_CODES, filteredState);
    },
    [setValue, tableState]
  );

  const handleStartEdit = useCallback(
    (taxProduct: TaxProduct) =>
      setEditingTaxProducts([...editingTaxProducts, taxProduct]),
    [editingTaxProducts]
  );

  const checkIsEditing = useCallback(
    (taxProductUuid: string) =>
      editingTaxProducts.some(
        (editingTaxProduct) => editingTaxProduct.resourceId === taxProductUuid
      ),
    [editingTaxProducts]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTaxCodeEdit = useCallback(
    _debounce((value: string, taxProductUuid: string) => {
      const newEditingState = editingTaxProducts.map((taxProduct) => {
        if (taxProduct.resourceId === taxProductUuid) {
          return {
            ...taxProduct,
            productTaxCode: value,
          };
        }
        return taxProduct;
      });
      setEditingTaxProducts(newEditingState);
    }, debounceTimeout),
    [editingTaxProducts]
  );

  const handleSave = useCallback(
    (taxProductUuid: string) => {
      const editedTaxProduct = editingTaxProducts.find(
        (taxProduct) => taxProduct.resourceId === taxProductUuid
      );

      if (!editedTaxProduct) return;

      const newState = tableState.map((taxProduct) => {
        if (taxProduct.resourceId === taxProductUuid) {
          return {
            ...editedTaxProduct,
          };
        }
        return taxProduct;
      });
      setTableState(newState);
      setValue(TaxConfigurationFormValue.TAX_CODES, newState);

      const filteredEditingTaxProducts = editingTaxProducts.filter(
        (taxProduct) => taxProduct.resourceId !== taxProductUuid
      );
      setEditingTaxProducts(filteredEditingTaxProducts);
    },
    [editingTaxProducts, setValue, tableState]
  );

  const handleCancel = useCallback(
    (taxProductUuid: string) => {
      const filteredEditingTaxProducts = editingTaxProducts.filter(
        (taxProduct) => taxProduct.resourceId !== taxProductUuid
      );
      setEditingTaxProducts(filteredEditingTaxProducts);
    },
    [editingTaxProducts]
  );

  const generateProductUrl = (
    productId: string,
    productType: TaxProductTypes
  ) => (productType === TaxProductTypes.PLAN ? `/plans/${productId}` : '');

  const getProductName = useCallback(
    (productId: string) =>
      products?.find((product) => product.uuid === productId)
        ?.administrativeName,
    [products]
  );

  const columns = useMemo(
    () => [
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product',
        render: (
          value: string,
          { resourceId, resourceType }: TaxProduct,
          rowIndex: number
        ) => {
          const productUrl = generateProductUrl(resourceId, resourceType);
          return <Link to={productUrl}>{getProductName(resourceId)}</Link>;
        },
      },
      {
        title: 'Product type',
        dataIndex: 'resourceType',
        key: 'resourceType',
      },
      {
        title: 'Tax code',
        dataIndex: 'productTaxCode',
        key: 'productTaxCode',
        render: (value: string, taxProduct: TaxProduct, rowIndex: number) => {
          return (
            <>
              {!checkIsEditing(taxProduct.resourceId) ? (
                <div className={styles.rowWrapper}>
                  <span>{taxProduct.productTaxCode}</span>
                  <div className={styles.actionWrapper}>
                    <EditOutlined
                      className={styles.pointer}
                      onClick={() => handleStartEdit(taxProduct)}
                    />
                    <DeleteOutlined
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete(taxProduct.resourceId);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.rowWrapper}>
                  <Input
                    defaultValue={taxProduct.productTaxCode}
                    onChange={(event) =>
                      handleTaxCodeEdit(
                        event.target.value,
                        taxProduct.resourceId
                      )
                    }
                  />
                  <div className={styles.actionWrapper}>
                    <span
                      className={`${styles.actionButton} ${styles.saveButton}`}
                      onClick={() => handleSave(taxProduct.resourceId)}
                    >
                      Save
                    </span>
                    <span
                      className={styles.actionButton}
                      onClick={() => handleCancel(taxProduct.resourceId)}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
    ],
    [
      checkIsEditing,
      getProductName,
      handleCancel,
      handleDelete,
      handleSave,
      handleStartEdit,
      handleTaxCodeEdit,
    ]
  );

  return (
    <>
      <Table columns={columns} dataSource={tableState} loading={isLoading} />
      <AddTaxProductsModal
        open={isModalOpen}
        onClose={onModalClose}
        onAdd={handleOnAddTaxProducts}
        addedProducts={tableState}
        products={products}
        isLoading={isLoadingProducts}
      />
    </>
  );
};

export default TaxProducts;
