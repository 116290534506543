import { PromotionType } from '@laminar-product/client-commons-core/core';
import { Modal } from 'antd';
import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import PromotionModal from 'pages/Promotions/components/PromotionModal';
import PromotionTable from 'pages/Promotions/components/PromotionTable';
import { useGetPromotions } from 'pages/Promotions/promotionUtils/promotions';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Paths } from 'types/paths';
import { PromotionRoutes } from 'types/promotions';
import { usePageParam, usePaginationObject } from 'utils/pagination';

const AffiliateList = () => {
  const [query, setQuery] = useState('');
  const history = useHistory();
  const [displayPromotionModal, setDisplayPromotionModal] = useState<boolean>();
  const page = usePageParam();
  const {
    pagination,
    data: promotions,
    isLoading,
    refresh,
  } = useGetPromotions({
    promotionType: PromotionType.COUPON,
    query,
    page,
  });
  const paginationProps = usePaginationObject(
    `${Paths.AFFILIATE}`,
    pagination,
    page
  );

  const onSearch = (value: string) => {
    setQuery(value);
    history.push(`${Paths.AFFILIATE}/page/1`);
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Affiliate promotions"
          extra={[
            <Search onSearch={onSearch} key="search" />,
            <Button
              key="createNewCoupon"
              type="primary"
              onClick={() => setDisplayPromotionModal(true)}
            >
              Create new promotion
            </Button>,
          ]}
        />

        <PromotionTable
          isLoading={isLoading}
          promotionRoute={PromotionRoutes.AFFILIATE}
          data={promotions}
          refresh={refresh}
          paginationConfig={paginationProps}
        />
      </PageContainer>

      <Modal
        visible={displayPromotionModal}
        onCancel={() => setDisplayPromotionModal(false)}
        destroyOnClose
        footer={null}
        title="Add new promotion"
      >
        <PromotionModal
          promotionType={PromotionType.COUPON}
          onCancel={() => setDisplayPromotionModal(false)}
          redirectTo={PromotionRoutes.AFFILIATE}
        />
      </Modal>
    </>
  );
};

export default AffiliateList;
