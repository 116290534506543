import { Asset } from 'types';

export enum GuestAccessConfiguration {
  ALL_FREE = 'ALL_FREE',
  SELECTED = 'SELECTED',
  NONE = 'NONE',
}

export interface GuestAccessGet {
  data: GuestAccess[];
  count: number;
}

export interface GuestAccess {
  uuid: string;
  configuration: GuestAccessConfiguration;
  regionUuid: string;
  assetUuidList?: string[];
}

export type GuestAccessForm = Omit<GuestAccess, 'assetUuidList'> & {
  assets: Asset[];
  guestAccess: boolean;
};
