import React, { useState } from 'react';
import Button from 'components/Button';
import FormContainer from 'components/FormContainer';
import { User } from 'types';
import FormGroup from 'components/FormGroup';
import { Input, Popconfirm, Select } from 'antd';
import {
  getUserActiveSubscription,
  getUserCurrentSubscription,
  getUsername,
} from 'utils/users';
import { ErrorMessage } from 'components/ErrorIndicator';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getSubscriptionOptions } from 'actions/plans';
import { prolongUserPlan } from 'actions/user';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import sharedStyles from '../index.module.scss';
import styles from './index.module.scss';

interface UserClientsProlongPlanProps {
  user?: User;
  onClose: () => void;
  refresh: () => void;
}

const UserClientsProlongPlan = ({
  user,
  onClose,
  refresh,
}: UserClientsProlongPlanProps) => {
  const [interval, setInterval] = useState<string>();
  const [duration, setDuration] = useState<string>();
  const [error, setError] = useState('');

  // todo this needs to be in the global state as it's used across few components
  const [subscriptionOptions] = useFetchAction(getSubscriptionOptions);
  const currentUserPlan = getUserCurrentSubscription(user)?.plan;

  const prolongPlan = async () => {
    const activeSubscription = getUserActiveSubscription(user);
    if (!activeSubscription || !interval || !duration) {
      validateForm();
      return;
    }

    await prolongUserPlan({
      intervalType: interval,
      amount: Number(duration),
      uuid: activeSubscription.subscriptionId,
    })
      .then(() => {
        notification.success({
          title: 'Success',
          description: 'The subscription has been prolonged.',
        });
      })
      .catch((e) => {
        captureError(e);
        notification.error({
          title: 'Error',
          description:
            'Something went wrong. Subscription has not been prolonged.',
        });
      })
      .finally(() => {
        onClose();
        refresh();
      });
  };

  function validateForm() {
    if (!interval) {
      setError('Please set the interval');
      return;
    }
    if (!duration) {
      setError('Please set the duration');
      return;
    }
  }

  return (
    <FormContainer>
      <form>
        <fieldset>
          <FormGroup
            label="User"
            input={<Input disabled value={getUsername(user)} />}
          />

          <FormGroup
            label="Current plan"
            input={
              <Input disabled value={currentUserPlan?.administrativeName} />
            }
          />

          <FormGroup
            label="Interval"
            input={
              <Select
                className={styles.intervalSelect}
                options={subscriptionOptions?.interval.map((i) => ({
                  label: i[0],
                  value: i[1],
                }))}
                value={interval}
                onSelect={(value: string) => setInterval(value?.toString())}
              />
            }
          />

          <FormGroup
            label="Duration"
            input={
              <Input
                type="number"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            }
          />

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <div className={sharedStyles.modalButtons}>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
            <Popconfirm
              placement="topRight"
              title={
                <div>
                  Are you sure you want to prolong{' '}
                  <strong>{currentUserPlan?.administrativeName}</strong> <br />{' '}
                  for <strong>{getUsername(user)}</strong> for{' '}
                  <strong>
                    {duration} {interval}S?
                  </strong>
                </div>
              }
              onConfirm={prolongPlan}
              okText="Yes"
              cancelText="No"
              className={styles.confirmationPopUp}
              disabled={!duration || !interval}
            >
              <Button type="primary">Prolong</Button>
            </Popconfirm>
          </div>
        </fieldset>
      </form>
    </FormContainer>
  );
};

export default UserClientsProlongPlan;
