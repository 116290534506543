import React from 'react';
import Search from 'components/Search';
import { Table, TablePaginationConfig } from 'antd';
import { AssetListTableStructure } from 'utils/table';
import { Link } from 'react-router-dom';
import { Asset } from 'types';
import formatLabel from 'utils/formatLabel';
import { Paths } from 'types/paths';
import styles from './index.module.scss';

const Column = Table.Column;

interface AssetsTableSearchableProps {
  loading?: boolean;
  setSearchQuery: (query: string) => void;
  data?: AssetListTableStructure[];
  pagination?: TablePaginationConfig;
}

const AssetsTableSearchable = ({
  loading,
  setSearchQuery,
  data,
  pagination = { pageSize: 7 },
}: AssetsTableSearchableProps) => (
  <>
    <div className={styles.searchWrapper}>
      <Search
        loading={loading}
        fullWidth
        placeholder="Search for assets"
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
    <Table
      loading={loading}
      pagination={pagination}
      expandRowByClick
      dataSource={data}
    >
      <Column
        title="Name"
        key="name"
        dataIndex="name"
        render={(name, asset: Asset) => (
          <Link to={`${Paths.ASSETS}/${asset.uuid}`}>{name}</Link>
        )}
      />
      <Column
        title="Asset type"
        key="assetType"
        dataIndex="assetType"
        render={formatLabel}
      />
      <Column key="actionColumn" dataIndex="actionColumn" />
    </Table>
  </>
);

export default AssetsTableSearchable;
