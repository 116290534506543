import { Asset } from 'types';
import {
  AssetLicense,
  AssetLicenseStatus,
  BaseLicense,
  BasicAssetLicenseCreate,
  ContentAvailability,
} from 'types/licenses';
import api from './api';

export interface CreateAssetLicenseProps {
  assetId: number;
  licenses: BasicAssetLicenseCreate[];
}

export const createAssetLicenses = async ({
  assetId,
  licenses,
}: CreateAssetLicenseProps) => {
  const { data } = await api().post<AssetLicense[]>(
    `/asset/${assetId}/license`,
    licenses
  );
  return data;
};

export interface GetAssetLicensesProps {
  assetId: number;
  licenseStatus?: AssetLicenseStatus;
}

export const getAssetLicenses = async ({
  assetId,
  licenseStatus,
}: GetAssetLicensesProps) => {
  const { data } = await api().get<AssetLicense[]>(
    `/asset/${assetId}/license`,
    {
      params: { status: licenseStatus },
    }
  );

  return data;
};

export interface DeleteAssetLicenseProps {
  assetId: number;
  licenseId: number;
}

export const deleteAssetLicense = async ({
  assetId,
  licenseId,
}: DeleteAssetLicenseProps) => {
  const { data } = await api().delete<void>(
    `/asset/${assetId}/license/${licenseId}`
  );
  return data;
};

interface PublishAssetLicensesProps {
  assetId: number;
  licenses: AssetLicense[];
}

export const publishAssetLicenses = async ({
  assetId,
  licenses,
}: PublishAssetLicensesProps): Promise<Asset> => {
  const { data } = await api().put(`/asset/${assetId}/v2/publish`, licenses);
  return data;
};

export const getContentAvailability = async (
  contentId: number
): Promise<ContentAvailability[]> => {
  const { data } = await api().get<ContentAvailability[]>(
    `/content/${contentId}/availability`
  );

  return data;
};

export interface CreateContentAvailabilityProps {
  contentId: number;
  availability: BaseLicense[];
}

export interface UpdateContentAvailabilityProps {
  contentId: number;
  availability: ContentAvailability[];
}

export const createContentAvailability = async ({
  contentId,
  availability,
}: CreateContentAvailabilityProps): Promise<string[]> => {
  const { data } = await api().post(
    `/content/${contentId}/availability`,
    availability
  );
  return data;
};

export const deleteContentAvailability = async (
  contentAvailabilityId: string
) => {
  const { data } = await api().delete<void>(
    `/content/${contentAvailabilityId}/availability`
  );
  return data;
};

export const editContentAvailability = async ({
  contentId,
  availability,
}: UpdateContentAvailabilityProps) => {
  const { data } = await api().patch<string[]>(
    `/content/${contentId}/availability`,
    availability
  );
  return data;
};

export const unpublishAssetLicenses = async (id: number): Promise<void> => {
  await api().put(`/asset/${id}/unpublish`);
};

export const publishAssetChildrenOrder = async (id: number) =>
  api().post(`/asset/${id}/order/publish`);
