import React, { ReactElement } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props {
  input: ReactElement;
  label: string;
  labelDescription?: React.ReactNode;
  errorMessage?: string | ReactElement;
  htmlFor?: string;
  overflowHiddenForInput?: boolean;
  vertical?: boolean;
}

const FormGroup = ({
  label,
  input,
  errorMessage,
  htmlFor,
  labelDescription,
  children,
  overflowHiddenForInput = true,
  vertical,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Props) => (
  <div
    className={cn(styles.root, vertical && styles.verticalContainer)}
    {...props}
  >
    <div className={styles.labelWrapper}>
      <label htmlFor={htmlFor}>{label}</label>
      {!!labelDescription && (
        <span className={styles.labelDescription}>{labelDescription}</span>
      )}
    </div>
    <div
      className={cn([
        styles.inputWrapper,
        overflowHiddenForInput && styles.inputOverflowHidden,
        vertical && styles.inputVertical,
      ])}
    >
      {input}
      {!!errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  </div>
);

export default FormGroup;
