import { Pagination } from 'types';
import { AuditLogConfigDetails } from 'types/auditLog';
import { PAGINATION_SIZE, pageToSkip } from 'utils/pagination';
import api from './api';

export const getAuditLogConfigPagination = async ({
  page,
}: {
  page?: number;
}): Promise<Pagination<AuditLogConfigDetails>> => {
  const { data } = await api().get(`/supervisor/audit-log`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
    },
  });

  return data;
};

export const getAuditLogActions = async (): Promise<string[]> => {
  const { data } = await api().get(`/supervisor/audit-log/actions`);
  return data;
};
