import {
  FeaturedAsset,
  FeaturedAssetEditPayload,
  FeaturedAssetStatus,
} from 'types/featuredAsset';
import { Pagination, pageToSkip } from 'utils/pagination';
import api from './api';

interface PaginationRequest {
  page?: number;
  limit?: number;
  name?: string;
  regions?: string[];
  statuses?: FeaturedAssetStatus[];
}

export const createFeaturedAsset = async (
  featuredAsset: FeaturedAsset
): Promise<FeaturedAsset> => {
  const { data } = await api().post(`/designer/featuredAsset`, featuredAsset);
  return data;
};

export const getFeaturedAssetsPagination = async ({
  page,
  limit,
  name,
  regions,
  statuses,
}: PaginationRequest): Promise<Pagination<FeaturedAsset>> => {
  const { data } = await api().get(`/designer/featuredAsset`, {
    params: {
      skip: pageToSkip(page, limit),
      limit,
      name,
      regions,
      statuses,
    },
  });

  return data;
};

export const removeFeaturedAsset = async (uuid: string): Promise<void> =>
  await api().delete(`/designer/featuredAsset/${uuid}`);

export const getFeaturedAssetDetails = async (
  uuid: string
): Promise<FeaturedAsset> => {
  const { data } = await api().get(`/designer/featuredAsset/${uuid}`);

  return data;
};

export const publishFeaturedAsset = async (uuid: string): Promise<void> =>
  await api().post(`/designer/featuredAsset/${uuid}/publish`);

export const unpublishFeaturedAsset = async (uuid: string): Promise<void> =>
  await api().post(`/designer/featuredAsset/${uuid}/unpublish`);

export const updateFeaturedAsset = async (
  featuredAsset: FeaturedAssetEditPayload
): Promise<FeaturedAsset> => {
  const { data } = await api().put(
    `/designer/featuredAsset/${featuredAsset.uuid}`,
    featuredAsset
  );

  return data;
};
