import { useCallback, useState } from 'react';
import { Col, Row } from 'antd';
import { getDashboardData } from 'actions/dashboard';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { AssetType } from '@laminar-product/client-commons-core/core';
import DashboardCards from './DashboardCards';
import DashboardBar from './DashboardBar';
import DashboardPie from './DashboardPie';
import DashboardTable from './DashboardTable';
import styles from './index.module.scss';

const Dashboard = () => {
  const periodDays = 7;
  const [assetType, setAssetType] = useState<string>(AssetType.MOVIE);

  const [dashboardData, isLoading] = useFetchAction(
    useCallback(
      () => getDashboardData({ assetType: assetType, periodDays: periodDays }),
      [assetType, periodDays]
    )
  );

  return (
    <div className={styles.root}>
      <Row gutter={[16, 16]} className={styles.row}>
        <DashboardCards data={dashboardData?.sourceTranscodedStatusesCount} />
      </Row>

      <Row gutter={[0, 16]} className={styles.row}>
        <DashboardBar
          assetsTableData={dashboardData?.lastAddedAssets}
          assetsBarData={dashboardData?.newlyAddedAssets}
          assetType={assetType}
          setAssetType={setAssetType}
          isLoading={isLoading}
        />
      </Row>

      <Row gutter={[16, 16]} className={styles.row}>
        <Col xs={24} sm={12} md={12}>
          <DashboardTable
            dataAssets={dashboardData?.lastUpdatedAssets}
            dataContent={dashboardData?.getLastUpdatedSourceTranscoded}
          />
        </Col>

        <Col xs={24} sm={24} md={12}>
          <DashboardPie data={dashboardData?.assetTypesCount} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
