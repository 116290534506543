import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  PlatformPartnerPrice,
  deletePlatformPartnerPlan,
  updatePlatformPartnerPlan,
} from 'actions/platformPartners';
import { Radio, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import Button from 'components/Button';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'types/paths';
import { PlatformPartnerPlan } from 'types/platformPartners';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import EditPartnerPlanModal from '../EditPartnerPlanModal';

interface PartnerPlansTableProps {
  data?: PlatformPartnerPlan[];
  partnerUuid: string;
  onPlanChange: () => void;
}

const PartnerPlansTable = ({
  data,
  partnerUuid,
  onPlanChange,
}: PartnerPlansTableProps) => {
  const [partnerPlanToEdit, setPartnerPlanToEdit] =
    useState<PlatformPartnerPlan>();

  const onDefaultValueChange = (
    checked: boolean,
    partnerPlan: PlatformPartnerPlan
  ) => {
    updatePlatformPartnerPlan(partnerUuid, partnerPlan.uuid, {
      default: checked,
    })
      .then(onPlanChange)
      .catch((e) => {
        notification.error({
          title: 'Something went wrong',
          description: `Changing default for ${partnerPlan.plan.administrativeName} failed - try again or contact support`,
        });
        captureError(e);
      });
  };

  const onDeletePartnerPlan = (partnerPlan: PlatformPartnerPlan) => {
    deletePlatformPartnerPlan(partnerUuid, partnerPlan.uuid)
      .then(onPlanChange)
      .catch((e) => {
        notification.error({
          title: 'Something went wrong',
          description: `Removing ${partnerPlan.plan.administrativeName} failed - try again or contact support`,
        });
        captureError(e);
      });
  };

  return (
    <>
      <Table
        loading={false}
        pagination={{ pageSize: 7 }}
        expandRowByClick
        dataSource={data}
      >
        <Column
          title="Plan"
          key="name"
          dataIndex={['plan', 'administrativeName']}
          render={(name, { plan }: any) =>
            plan.uuid ? (
              <Link to={`${Paths.PLANS}/${plan.uuid}`}>{name}</Link>
            ) : (
              <span> {name}</span>
            )
          }
        />
        <Column title="External ID" key="externalId" dataIndex="externalId" />
        <Column
          title="Plan price"
          key="price"
          dataIndex="price"
          render={(value: PlatformPartnerPrice) =>
            value ? value.amount + ' INR' : '-'
          }
        />
        <Column<PlatformPartnerPlan>
          title="Is Default?"
          key="default"
          dataIndex="default"
          render={(val, partnerPlan) => (
            <Radio
              name="default"
              checked={val}
              onChange={(e) =>
                onDefaultValueChange(e.target.checked, partnerPlan)
              }
            />
          )}
        />
        <Column<PlatformPartnerPlan>
          key="actionColumn"
          align="right"
          render={(val, partnerPlan) => (
            <>
              <Button
                icon={<EditOutlined />}
                shape="circle"
                type="text"
                onClick={() => setPartnerPlanToEdit(partnerPlan)}
              />
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                type="text"
                onClick={() => onDeletePartnerPlan(partnerPlan)}
              />
            </>
          )}
        />
      </Table>
      {partnerPlanToEdit && (
        <EditPartnerPlanModal
          partnerUuid={partnerUuid}
          partnerPlan={partnerPlanToEdit}
          onClose={() => setPartnerPlanToEdit(undefined)}
          onEdit={onPlanChange}
        />
      )}
    </>
  );
};

export default PartnerPlansTable;
