import React, { useEffect, useState } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, PageHeader, Table } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import { useGetRegions } from 'hooks/useRegions';
import { RegionFormProps } from 'pages/Regions/RegionForm/types';
import { ApiActionType, Region } from 'types';
import { useCountries } from 'hooks/useCountries';
import RegionForm from './RegionForm';

const { Column } = Table;

const Regions = () => {
  const { regions, isLoadingRegions, error } = useGetRegions();
  const { countries, isLoadingCountries } = useCountries();
  const [regionFormModalProps, setRegionFormModalProps] =
    useState<Omit<RegionFormProps, 'onSave' | 'onCancel'>>();

  useEffect(() => {
    setRegionFormModalProps(undefined);
  }, [regions]);

  return (
    <PageContainer>
      <PageHeader
        title="Regions"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() =>
              setRegionFormModalProps({
                defaultValues: undefined,
                type: ApiActionType.CREATE,
              })
            }
          >
            Add new region
          </Button>
        }
      />
      <Table
        loading={isLoadingRegions}
        indentSize={40}
        dataSource={regions}
        rowKey="name"
      >
        <Column title="Name" dataIndex="name" align="left" />
        <Column
          title=""
          dataIndex="edit"
          width={50}
          render={(_, region: Region) => (
            <Button
              shape="circle"
              onClick={() =>
                setRegionFormModalProps({
                  defaultValues: region,
                  type: ApiActionType.UPDATE,
                })
              }
            >
              <EditOutlined />
            </Button>
          )}
        />
      </Table>
      {error && <ErrorIndicator error={error} />}
      <Modal
        visible={!!regionFormModalProps}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setRegionFormModalProps(undefined)}
        destroyOnClose
      >
        <RegionForm
          onCancel={() => setRegionFormModalProps(undefined)}
          type={regionFormModalProps?.type}
          defaultValues={regionFormModalProps?.defaultValues}
          countries={countries}
          isLoadingCountries={isLoadingCountries}
        />
      </Modal>
    </PageContainer>
  );
};

export default Regions;
