import { Asset } from 'types';

export interface BasicContentDistributionConfiguration {
  name: string;
  regionUuid: string;
}

export interface ContentDistributionConfigBase {
  name: string;
  distributorUuid: string | null;
  regionUuid: string;
  primaryLanguage: string | null;
  uuid: string;
  maxStreamVideoQuality: string;
}

export interface ContentDistributionConfig
  extends ContentDistributionConfigBase {
  assetsUuids: string[] | null;
  sourceId?: string;
  appId?: string;
  type?: ContentDistributorType;
}

export enum ContentDistributorType {
  CATALOGUE_FEED = 'CATALOGUE_FEED',
  REGULAR = 'REGULAR',
}
export interface ContentDistributionConfigDetails
  extends ContentDistributionConfigBase {
  assets: Asset[]; //BE returns empty array if not exists
  assetsUuids: string[] | null;
  sourceId?: string;
  appId?: string;
  type?: ContentDistributorType;
}

export interface ContentDistributionConfigForm {
  name: string;
  distributorUuid: string;
  regionUuid: string;
  primaryLanguage: string;
  sourceId?: string;
  appId?: string;
  type?: ContentDistributorType;
}

export interface ContentDistributionUser {
  createdAt: string;
  distributorUuid: string;
  expiresAt: number;
  identification:
    | { type: ContentDistributionUserIdentification.EMAIL; email: string }
    | {
        type: ContentDistributionUserIdentification.PHONE_NUMBER;
        phoneNumber: string;
      }
    | { type: ContentDistributionUserIdentification.TOKEN; token: string };
  uuid: string;
}

export enum ContentDistributionUserIdentification {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  TOKEN = 'token',
}

export const isCatalogueFeedType = (type?: ContentDistributorType) =>
  type === 'CATALOGUE_FEED';
