import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  getContentDistributionConfigPagination,
  getContentDistributionUsersPagination,
} from 'actions/contentDistribution';
import { Table } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { Pagination } from 'types';
import {
  ContentDistributionConfig,
  ContentDistributionUser,
  ContentDistributionUserIdentification,
} from 'types/contentDistribution';
import { DATE_FORMAT, MAX_PAGINATION_LIMIT } from 'utils/constants';
import { usePageParam, usePaginationObject } from 'utils/pagination';
import moment from 'moment';
import { ColumnFilterItem } from 'antd/es/table/interface';

const { Column } = Table;

interface DistributorsClientsTableProps {
  query?: string;
}

interface DistributorsClientsFilters {
  distributorUuid?: string;
}

const DistributorsClientsTable = ({ query }: DistributorsClientsTableProps) => {
  const page = usePageParam();
  const [filters, setFilters] = useState<DistributorsClientsFilters>({});

  const { data: pagination, isLoading } = useFetchActionObj<
    Pagination<ContentDistributionUser>
  >(
    useCallback(
      () =>
        getContentDistributionUsersPagination({
          page,
          query,
          distributorUuid: filters.distributorUuid,
        }),
      [filters.distributorUuid, page, query]
    )
  );

  // We are retrieving distributors uuid from the paginated response, but because we assume the list is short and pages are big (100 items by default) it should be fine
  const { data: contentDistributionConfigs } = useFetchActionObj<
    Pagination<ContentDistributionConfig>
  >(
    useCallback(
      () =>
        getContentDistributionConfigPagination({ limit: MAX_PAGINATION_LIMIT }),
      []
    )
  );

  const allowedDistributorsUuidsFilters: ColumnFilterItem[] = useMemo(
    () =>
      (contentDistributionConfigs?.data || [])
        .filter((c) => !!c.distributorUuid)
        .map(({ distributorUuid }) => ({
          text: distributorUuid!,
          value: distributorUuid!,
        })),
    [contentDistributionConfigs]
  );

  const handleFiltersChange = ({
    distributorUuid,
  }: DistributorsClientsFilters) => setFilters({ distributorUuid });

  return (
    <Table
      rowKey="uuid"
      dataSource={pagination?.data}
      loading={isLoading}
      pagination={usePaginationObject(
        '/distributorsClients',
        pagination,
        page || 1
      )}
      onChange={(_, filters: DistributorsClientsFilters) =>
        handleFiltersChange(filters)
      }
    >
      <Column
        title="Email"
        dataIndex="email"
        render={(_, user: ContentDistributionUser) =>
          user.identification.type ===
          ContentDistributionUserIdentification.EMAIL
            ? user.identification.email
            : '-'
        }
      />
      <Column
        title="Phone number"
        dataIndex="phoneNumber"
        render={(_, user: ContentDistributionUser) =>
          user.identification.type ===
          ContentDistributionUserIdentification.PHONE_NUMBER
            ? user.identification.phoneNumber
            : '-'
        }
      />
      <Column
        title="Token"
        dataIndex="token"
        render={(_, user: ContentDistributionUser) =>
          user.identification.type ===
          ContentDistributionUserIdentification.TOKEN
            ? user.identification.token
            : '-'
        }
      />
      <Column
        title="Content distributor"
        dataIndex="distributorUuid"
        filters={allowedDistributorsUuidsFilters}
        filterMultiple={false}
        filteredValue={
          !!filters?.distributorUuid ? [filters.distributorUuid] : null
        }
      />
      <Column
        title="Access till date"
        dataIndex="expiresAt"
        render={(expiresAt: Date) => moment(expiresAt).format(DATE_FORMAT)}
      />
      <Column title="User ID" dataIndex="uuid" />
    </Table>
  );
};

export default DistributorsClientsTable;
