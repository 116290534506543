import { Alert, Button, PageHeader } from 'antd';
import PageContainer from 'components/PageContainer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  publishAssetChildrenOrder,
  publishAssetLicenses,
  unpublishAssetLicenses,
} from 'actions/license';
import { captureError } from 'utils/captureError';
import { useAssetDetailsContext } from 'contexts/assetContext';
import notification from 'utils/notification';
import { AddRegionLicense, AvailabilityTableData } from 'types/licenses';
import AddRegionModal from 'components/ScheduledPublish/AddRegionModal';
import EditLicenseModal from 'components/ScheduledPublish/EditLicenseModal';
import { isLicenseEditable } from 'utils/licenses';
import { useHistory } from 'react-router-dom';
import AvailabilityTable from '../AvailabilityTable';
import { useAssetAvailability } from '../utils/availabilityUtils';
import styles from './index.module.scss';
import AssetPublishingModal from './components/AssetPublishingModal';

const AssetPublish = () => {
  const { asset, refresh: refreshAsset } = useAssetDetailsContext();
  const [isAddRegionModalVisible, setIsAddRegionModalVisible] = useState(false);
  const [licenseToEdit, setLicenseToEdit] = useState<AvailabilityTableData>();
  const [shouldNotifyAboutPageExit, setShouldNotifyAboutPageExit] =
    useState(false);

  const [
    publishingConfirmationModalVisible,
    setPublishingConfirmationModalVisible,
  ] = useState(false);

  const history = useHistory();

  const {
    isLoadingAssetLicenses,
    noAssetLicensesAdded,
    notScheduledLicenses,
    scheduledLicenses,
    refreshAssetLicenses,
    setScheduledLicenses,
  } = useAssetAvailability({ assetId: asset.id });

  const shouldUnpublishAsset = useMemo(
    () => !scheduledLicenses?.length,
    [scheduledLicenses]
  );

  const shouldDisablePublishButton = useMemo(
    () =>
      !scheduledLicenses?.length &&
      !notScheduledLicenses?.find((l) => !!l.availableFrom),
    [notScheduledLicenses, scheduledLicenses]
  );

  const onPublishSuccess = () => {
    setScheduledLicenses([]);
    refreshAssetLicenses();

    notification.success({
      title: 'Success',
      description: `Asset has been ${
        shouldUnpublishAsset ? 'unpublished' : 'published'
      }!`,
    });

    if (!!asset?.childrenIds?.length && !shouldUnpublishAsset) {
      publishAssetChildrenOrder(asset.id);
    }

    setShouldNotifyAboutPageExit(false);
    setPublishingConfirmationModalVisible(false);
    refreshAsset();
  };

  const onPublishError = (error: any) => {
    notification.error({
      title: 'Error',
      description:
        error?.response?.data?.details ||
        `Something went wrong. Asset has not been ${
          shouldUnpublishAsset ? 'unpublished' : 'published'
        }.`,
    });
    captureError(error);
  };

  const { sendAction: publishAsset, isLoading: isPublishing } =
    useSendActionObj(
      useCallback(
        () =>
          publishAssetLicenses({
            assetId: asset.id,
            licenses: scheduledLicenses,
          }),

        [asset.id, scheduledLicenses]
      ),
      { onDone: onPublishSuccess, onError: onPublishError }
    );

  const { sendAction: unpublishAsset, isLoading: isUnpublishing } =
    useSendActionObj(
      useCallback(() => unpublishAssetLicenses(asset.id), [asset]),
      { onDone: onPublishSuccess, onError: onPublishError }
    );

  const onRegionLicenseRemove = (licenseToRemove: AvailabilityTableData) => {
    //If id exists the deletion is about asset license
    if (!('id' in licenseToRemove)) return;

    const licensesToUpdate =
      scheduledLicenses?.filter((l) => l.id !== licenseToRemove.id) || [];

    setScheduledLicenses(licensesToUpdate);
    setShouldNotifyAboutPageExit(true);
  };

  const onEdit = useCallback(
    (editedLicense: AvailabilityTableData) => {
      //If id exists the edit is about edit asset license which is drafted
      if (!('id' in editedLicense)) return;

      const editedLicenseIndex = scheduledLicenses.findIndex(
        (l) => l.id === editedLicense.id
      ); //Keep the order of items in array

      const updatedLicenses = scheduledLicenses.map((l, i) =>
        i === editedLicenseIndex ? editedLicense : l
      );

      setScheduledLicenses(updatedLicenses);
      setLicenseToEdit(undefined);
      setShouldNotifyAboutPageExit(true);
    },
    [scheduledLicenses, setScheduledLicenses]
  );

  const onAddDraftedLicenses = (draftedLicenses: AddRegionLicense[]) => {
    setScheduledLicenses((prev) => [...prev, ...draftedLicenses]);
    setShouldNotifyAboutPageExit(true);
  };

  const onPublishConfirm = () =>
    shouldUnpublishAsset ? unpublishAsset() : publishAsset();

  useEffect(() => {
    if (!shouldNotifyAboutPageExit) {
      return;
    }

    const unblock = history.block(() => {
      const result = window.confirm(
        'Unsaved changes were detected. Are you sure you want to leave this page? All changes will be lost.'
      );

      if (!result) {
        return false;
      }
    });

    return () => unblock();
  }, [history, scheduledLicenses, shouldNotifyAboutPageExit]);

  return (
    <PageContainer>
      <PageHeader
        title="Publish"
        extra={
          <Button
            disabled={noAssetLicensesAdded}
            icon={<PlusOutlined />}
            onClick={() => setIsAddRegionModalVisible(true)}
          >
            Add region
          </Button>
        }
      />

      {noAssetLicensesAdded && (
        <div className={styles.alert}>
          <Alert
            type="info"
            showIcon
            message="Please add at least one license to publish this asset in a region."
          />
        </div>
      )}

      <AvailabilityTable
        isLoading={isLoadingAssetLicenses}
        data={scheduledLicenses}
        onRemove={onRegionLicenseRemove}
        isEditable={isLicenseEditable}
        onEdit={setLicenseToEdit}
      />

      <Button
        icon={<GlobalOutlined />}
        className={styles.publishBtn}
        type="primary"
        disabled={shouldDisablePublishButton}
        loading={isPublishing}
        onClick={() => setPublishingConfirmationModalVisible(true)}
      >
        {shouldUnpublishAsset ? 'Unpublish' : 'Publish'}
      </Button>

      {isAddRegionModalVisible && (
        <AddRegionModal
          isOpen={isAddRegionModalVisible}
          onClose={() => setIsAddRegionModalVisible(false)}
          licenses={notScheduledLicenses}
          isLoadingDataTable={isLoadingAssetLicenses}
          onAddLicenses={onAddDraftedLicenses}
        />
      )}

      {licenseToEdit && (
        <EditLicenseModal
          license={licenseToEdit}
          onClose={() => setLicenseToEdit(undefined)}
          isOpen={!!licenseToEdit}
          onEdit={onEdit}
        />
      )}

      {publishingConfirmationModalVisible && (
        <AssetPublishingModal
          isVisible={publishingConfirmationModalVisible}
          asset={asset}
          onClose={() => setPublishingConfirmationModalVisible(false)}
          onConfirm={onPublishConfirm}
          isLoading={isPublishing || isUnpublishing}
          type={shouldUnpublishAsset ? 'unpublish' : 'publish'}
        />
      )}
    </PageContainer>
  );
};

export default AssetPublish;
