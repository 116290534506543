import _get from 'lodash/get';
import notification from 'utils/notification';

export const errorNotify = (error: Error) => {
  const errorDetails = _get(error, 'response.data.details');
  if (errorDetails) {
    notification.error({ title: 'Error', description: errorDetails });
  } else {
    notification.error({
      title: 'Error',
      description: 'An error occurred. Please try again later!',
    });
  }
};
