import React, { useCallback } from 'react';
import {
  useFetchAction,
  usePolling,
} from '@laminar-product/client-commons-core/hooks';
import { getSourceFiles } from 'actions/files';
import { Progress, Table } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import FileStatusTag from 'components/FileStatusTag';
import moment from 'moment';
import { FileStatus, SourceStatus, UploadedFile } from 'types';
import {
  useBoundToPages,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import { getModifiedDataSource } from './helpers';

const { Column } = Table;

const UploadFilesTable = ({
  localFile,
  query,
  progress,
  statuses,
}: {
  localFile?: UploadedFile;
  query?: string;
  progress?: number;
  statuses?: SourceStatus[];
}) => {
  const page = usePageParam();

  const [pagination, isLoading, error, refresh] = useFetchAction(
    useCallback(
      () =>
        getSourceFiles({
          page,
          fileStatuses: statuses ?? [
            SourceStatus.PROCESSING,
            SourceStatus.QUALITY_ERROR,
          ],
          searchQuery: query,
        }),
      [page, query, statuses]
    )
  );

  usePolling(refresh);

  useBoundToPages(`/files/upload`, pagination, page);

  const dataSource = getModifiedDataSource(localFile, pagination, page ?? 1);

  return (
    <>
      <ErrorIndicator error={error} />
      <Table
        rowKey="id"
        dataSource={dataSource}
        loading={pagination?.data?.length === 0 && isLoading}
        pagination={usePaginationObject('/files/upload', pagination, page ?? 1)}
      >
        <Column
          title="File name"
          key="name"
          dataIndex="name"
          render={(name, _, index) => (
            <span data-testid={`UploadedFilesTable__nameColumn--${index}`}>
              {name}
            </span>
          )}
        />
        <Column
          title="Status"
          key="status"
          dataIndex="status"
          render={(_, file: UploadedFile) => {
            if (
              file.status === FileStatus.UPLOADING &&
              progress !== undefined
            ) {
              return <Progress percent={progress * 100} showInfo={false} />;
            }
            return <FileStatusTag file={file} />;
          }}
        />
        <Column
          title="Uploaded"
          key="uploadDate"
          dataIndex="uploadDate"
          render={(_, file: UploadedFile) => (
            <span>
              {file.createdAt
                ? moment(file.createdAt).format('YYYY-MM-DD hh:mm:ss')
                : ''}
            </span>
          )}
        />
      </Table>
    </>
  );
};
export default UploadFilesTable;
