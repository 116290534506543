import '@laminar-product/client-commons-core/style.css';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import Result from 'ant-design-pro/lib/Result';
import 'firebase/auth';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import setupApp from 'setupApp';
import { createRoot } from 'react-dom/client';
import store from './store';
import App from './App';
import { TranslationProvider } from './contexts/translationContext';
import * as serviceWorker from './serviceWorker';
import './styles.less';
import './i18n.ts';

const history = createBrowserHistory();

const run = async () => {
  await setupApp(history);

  const rootElement = document.getElementById('root');
  if (rootElement === null) throw new Error('root element not found');

  const root = createRoot(rootElement);

  root.render(
    <ErrorBoundary
      fallback={
        <Result
          type="error"
          title="Unexpected error"
          description="Please refresh the page or contact support."
        />
      }
    >
      <Provider store={store}>
        <TranslationProvider>
          <Router history={history}>
            <App />
          </Router>
        </TranslationProvider>
      </Provider>
    </ErrorBoundary>
  );
};

run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
