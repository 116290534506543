import { PageHeader } from 'antd';
import PageContainer from 'components/PageContainer';
import Search from 'components/Search';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DistributorsClientsTable from './DistributorsClientsTable';

const DistributorsClientsList = () => {
  const [query, setQuery] = useState<string>('');
  const { push } = useHistory();

  return (
    <PageContainer>
      <PageHeader
        title="Content Distributors' Clients"
        extra={[
          <Search
            placeholder="Search"
            onSearch={(query) => {
              setQuery(query);
              push('/distributorsClients/page/1');
            }}
          />,
        ]}
      />

      <DistributorsClientsTable query={query} />
    </PageContainer>
  );
};

export default DistributorsClientsList;
