import React from 'react';
import styles from './index.module.scss';

const ComponentListHead = () => {
  return (
    <div className={styles.listHead}>
      <div className={styles.iconCol}></div>
      <div className={styles.componentCol}>Component</div>
      <div className={styles.sourceCol}>Source</div>
    </div>
  );
};

export default ComponentListHead;
