import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import FormGroup from 'components/FormGroup';
import { useForm } from 'react-hook-form';
import { AutoPopulateFilter } from 'types';
import MultiSelectDetails from 'components/MultiSelectAssetDetails';
import { useDebounceSelectHook } from 'hooks/debounceSelectHook';
import { useDictionary } from 'hooks/useDictionaries';
import { AssetType } from '@laminar-product/client-commons-core/core';

export interface AutoPopulateModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  defaultValues?: AutoPopulateFilter;
  onAdd: (filter: AutoPopulateFilter) => void;
}

const AutoPopulateModal = ({
  isModalOpen,
  onModalClose,
  defaultValues,
  onAdd,
}: AutoPopulateModalProps) => {
  const { getValues, setValue, register, unregister } =
    useForm<AutoPopulateFilter>({
      defaultValues,
    });
  const {
    data: genreData,
    isLoading: genreIsLoading,
    setQuery: genreQuery,
    selectedData: selectedGenres,
    setSelectedData: setSelectedGenres,
  } = useDebounceSelectHook(useDictionary, 'genres', defaultValues?.genres);
  const {
    data: moodData,
    isLoading: moodIsLoading,
    setQuery: moodQuery,
    selectedData: selectedMoods,
    setSelectedData: setSelectedMoods,
  } = useDebounceSelectHook(useDictionary, 'moods', defaultValues?.moods);
  const {
    data: tagsData,
    isLoading: tagIsLoading,
    setQuery: tagQuery,
    selectedData: selectedTags,
    setSelectedData: setSelectedTags,
  } = useDebounceSelectHook(useDictionary, 'tags', defaultValues?.tags);

  const [selectedTypes, setSelectedTypes] = useState<string[] | undefined>(
    defaultValues?.types
  );

  useEffect(() => {
    register('tags');
    register('moods');
    register('genres');
    register('types');

    return () => {
      unregister('tags');
      unregister('moods');
      unregister('genres');
      unregister('types');
    };
  }, [register, unregister]);

  return (
    <Modal
      visible={isModalOpen}
      onOk={() => onAdd(getValues())}
      okText="Save"
      onCancel={() => onModalClose()}
      title="Auto-populate"
    >
      <FormGroup
        label="Asset types"
        input={
          <MultiSelectDetails
            value={selectedTypes}
            data={Object.values(AssetType)}
            name="types"
            mode="multiple"
            filterOption
            placeholder="Add filter"
            onChange={(value: string[]) => {
              if (!!value) setValue('types', value);
              setSelectedTypes(value);
            }}
          />
        }
      />
      <FormGroup
        label="Genres"
        input={
          <MultiSelectDetails
            value={selectedGenres ? selectedGenres : []}
            data={genreData ? genreData : []}
            isLoading={genreIsLoading}
            name="genres"
            mode="multiple"
            onSearch={genreQuery}
            filterOption={false}
            placeholder="Add filter"
            onChange={(value: string[]) => {
              if (!!value) setValue('genres', value);
              setSelectedGenres(value);
              genreQuery('');
            }}
          />
        }
      />
      <FormGroup
        label="Moods"
        input={
          <MultiSelectDetails
            value={selectedMoods ? selectedMoods : []}
            data={moodData ? moodData : []}
            isLoading={moodIsLoading}
            name="moods"
            mode="multiple"
            onSearch={moodQuery}
            filterOption={false}
            placeholder="Add filter"
            onChange={(value: string[]) => {
              if (!!value) setValue('moods', value);
              setSelectedMoods(value);
              moodQuery('');
            }}
          />
        }
      />
      <FormGroup
        label="Tags"
        input={
          <MultiSelectDetails
            value={selectedTags ? selectedTags : []}
            data={tagsData ? tagsData : []}
            isLoading={tagIsLoading}
            name="tags"
            mode="tags"
            onSearch={tagQuery}
            placeholder="Add filter"
            onChange={(value: string[]) => {
              if (!!value) setValue('tags', value);
              setSelectedTags(value);
              tagQuery('');
            }}
          />
        }
      />
    </Modal>
  );
};

export default AutoPopulateModal;
