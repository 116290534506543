import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  editPrepaidCard,
  publishPrepaidCard,
  unpublishPrepaidCard,
} from 'actions/prepaid';
import { BasicPrepaidCard, PrepaidCard } from 'types/prepaidCard';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';

interface UsePrepaidCardActionsProps {
  id: string;
  onPublishUnpublishDone: () => void;
  onSaveDone: () => void;
}

export const usePrepaidCardActions = ({
  id,
  onPublishUnpublishDone,
  onSaveDone,
}: UsePrepaidCardActionsProps) => {
  const handleError = (error: any) => {
    notification.error({
      title: 'Error',
      description: 'An error occured. Please try again later!',
    });
    captureError(error);
  };

  const { sendAction: savePrepaid, isLoading: isSaving } = useSendActionObj<
    PrepaidCard,
    BasicPrepaidCard
  >(
    async (prepaidToSave) => {
      return await editPrepaidCard({
        prepaidCard: prepaidToSave,
        id,
      });
    },
    {
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'Prepaid card updated!',
        });
        onSaveDone();
      },
      onError: handleError,
    }
  );

  const { sendAction: unpublish, isLoading: isUnpublishing } =
    useSendActionObj<PrepaidCard>(
      async () => {
        return await unpublishPrepaidCard(id);
      },
      {
        onDone: () => {
          notification.success({
            title: 'Success',
            description: 'Prepaid card unpublished!',
          });
          onPublishUnpublishDone();
        },
        onError: handleError,
      }
    );

  const { sendAction: publish, isLoading: isPublishing } =
    useSendActionObj<PrepaidCard>(async () => await publishPrepaidCard(id), {
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'Prepaid card published!',
        });
        onPublishUnpublishDone();
      },
      onError: handleError,
    });

  return {
    savePrepaid,
    publish,
    unpublish,
    isSaving,
    isPublishing,
    isUnpublishing,
  };
};
