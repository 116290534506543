import { Input, InputNumber } from 'antd';
import FormGroup from 'components/FormGroup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const MultiCouponsForm = () => {
  const { errors, control } = useFormContext();

  return (
    <>
      <FormGroup
        label="Code"
        errorMessage={errors.code?.message}
        input={
          <Controller
            as={<Input placeholder="Code" />}
            control={control}
            name="code"
            rules={{ required: 'Required' }}
          />
        }
      />

      <FormGroup
        label="Total usage limit"
        errorMessage={errors.quantity?.message}
        input={
          <Controller
            as={<InputNumber min={1} placeholder="Total" />}
            control={control}
            name="quantity"
            rules={{ required: 'Required' }}
          />
        }
      />
    </>
  );
};

export default MultiCouponsForm;
