import React from 'react';
import { Checkbox, Input, InputNumber, Select, Switch } from 'antd';
import cn from 'classnames';
import FormGroup from 'components/FormGroup';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Quality as QualityType, Region as RegionInterface } from 'types';
import { PlanForm, PlanOptions } from 'types/plan';
import filterOptionsUsingLabels from 'utils/filterOptionsUsingLabels';
import styles from './index.module.scss';

interface OptionsDependent {
  options?: PlanOptions;
}

export const ScreenLimit = ({
  control,
  errors,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={<InputNumber min={1} />}
        control={control}
        name="screenLimit"
        rules={{ required: 'Required' }}
        placeholder="Screen limit"
      />
    }
    label="Screen limit"
    errorMessage={errors.screenLimit?.message}
  />
);

export const ProfileLimit = ({
  control,
  errors,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={<InputNumber min={1} max={6} />}
        control={control}
        name="profileLimit"
        rules={{ required: 'Required' }} //max count of profiles is hardcoded for now, there is no validation on backend
        placeholder="Profile limit"
      />
    }
    label="Profile limit"
    errorMessage={errors.profileLimit?.message}
  />
);

export const Quality = ({
  control,
  errors,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'> & OptionsDependent) => (
  <FormGroup
    input={
      <Controller
        control={control}
        name="quality"
        type="checkbox"
        rules={{ required: 'Required' }}
        as={({ value, onChange }) => (
          <Checkbox.Group value={value} onChange={(e) => onChange(e)}>
            {/* harcoded for now, when API will be changed, here will be map  */}
            <Checkbox
              key="SD"
              value={QualityType['540p']}
              className={styles.checkbox}
            >
              SD
            </Checkbox>
            <Checkbox
              key="HD"
              value={QualityType['1080p']}
              className={styles.checkbox}
            >
              HD
            </Checkbox>

            <Checkbox
              key="4K"
              value={QualityType['2160p']}
              className={styles.checkbox}
            >
              4K
            </Checkbox>
          </Checkbox.Group>
        )}
      />
    }
    label="Stream quality"
    errorMessage={(errors.quality as any)?.message}
  />
);

export const Casting = ({
  control,
  errors,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'> & OptionsDependent) => (
  <FormGroup
    input={
      <Controller
        control={control}
        name="casting"
        type="checkbox"
        defaultValue={true}
        as={({ value, onChange }) => (
          <Switch onChange={onChange} checked={value} />
        )}
      />
    }
    label="Casting"
    errorMessage={(errors.casting as any)?.message}
  />
);

export const Platform = ({
  control,
  errors,
  options,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'> & OptionsDependent) => (
  <FormGroup
    input={
      <Controller
        control={control}
        name="platforms"
        type="checkbox"
        rules={{ required: 'Required' }}
        as={({ value, onChange }) => (
          <Checkbox.Group value={value} onChange={onChange}>
            {options?.platforms.map((platform: string) => (
              <Checkbox
                key={platform}
                value={platform}
                className={cn(styles.checkbox, styles.capitalize)}
              >
                {platform.toLowerCase()}
              </Checkbox>
            ))}
          </Checkbox.Group>
        )}
      />
    }
    label="Platforms"
    errorMessage={(errors.platforms as any)?.message}
  />
);

export const Name = ({
  control,
  errors,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={Input}
        control={control}
        name="administrativeName"
        rules={{ required: 'Required' }}
        placeholder="Please enter plan name"
      />
    }
    label="Administrative name"
    errorMessage={errors.administrativeName?.message}
  />
);

export const Region = ({
  control,
  errors,
  regions,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'> & {
  regions: RegionInterface[];
}) => (
  <FormGroup
    errorMessage={errors.region?.uuid?.message}
    input={
      <Controller
        name="region.uuid"
        control={control}
        rules={{ required: 'Required' }}
        as={({ onChange, value }) => (
          <Select
            showSearch
            className={styles.fullWidth}
            allowClear
            placeholder="Please select region"
            value={value}
            onChange={onChange}
            options={regions.map((region) => ({
              label: region.name,
              value: region.uuid,
            }))}
            filterOption={filterOptionsUsingLabels}
          />
        )}
      />
    }
    label="Region"
  />
);

export const Travel = ({
  control,
  regions,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'> & {
  regions: RegionInterface[];
}) => (
  <FormGroup
    input={
      <Controller
        name="availableRegionsUuids"
        control={control}
        as={({ onChange, value }) => (
          <Select
            mode="multiple"
            className={styles.fullWidth}
            allowClear
            placeholder="Add regions"
            value={value}
            onChange={onChange}
            optionFilterProp="label"
            optionLabelProp="label"
            options={regions.map((region) => ({
              label: region.name,
              value: region.uuid,
            }))}
          />
        )}
      />
    }
    label="Travel to"
    labelDescription={
      <div className={styles.travelPlanDescription}>
        Allow users to use this plan in more regions
      </div>
    }
  />
);

export const AdDriven = ({
  control,
}: Pick<UseFormMethods<PlanForm>, 'control' | 'errors'>) => (
  <FormGroup
    className={styles.adDriven}
    input={
      <Controller
        name="adDriven"
        control={control}
        as={({ value, onChange }) => (
          <Switch onChange={onChange} checked={value} />
        )}
      />
    }
    label="Display Ads"
  />
);
