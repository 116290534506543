import React from 'react';
import styles from './index.module.scss';

const ManualPage = () => {
  return (
    <iframe
      title="help"
      src="https://laminardocs.bss.design/"
      height="100%"
      width="100%"
      frameBorder={0}
      className={styles.manual}
    />
  );
};

export default ManualPage;
