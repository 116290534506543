import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { ContentType } from '@laminar-product/client-commons-core/core';
import { getAssetContents } from 'actions/assets';
import { getContentFile } from 'actions/content';
import { useCallback } from 'react';
import { UploadedFile } from 'types';

const useAssetFile = (assetId: number, type: ContentType) => {
  const [contents, , contentError] = useFetchAction(
    useCallback(() => getAssetContents({ id: assetId }), [assetId])
  );
  const [file, , fileError] = useFetchAction<UploadedFile>(
    useCallback(() => {
      const content = contents?.find((c) => c.type === type);
      if (!content) {
        return Promise.reject(`No content with type ${type}`);
      }
      return getContentFile({ id: content.id });
    }, [contents, type])
  );

  return { file, fileError, contentError };
};

export default useAssetFile;
