import { Input, Tooltip } from 'antd';
import { SearchProps } from 'antd/lib/input';
import cn from 'classnames';
import { useState } from 'react';
import styles from './index.module.scss';

interface SearchCustomProps extends SearchProps {
  tooltipText?: string;
  fullWidth?: boolean;
  tooltipOpen?: boolean;
}

const Search = ({
  placeholder = 'Search by name',
  fullWidth,
  tooltipText,
  tooltipOpen,
  ...props
}: SearchCustomProps) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  return (
    <div className={cn(styles.root, [{ [styles.fullWidth]: fullWidth }])}>
      <Tooltip
        title={tooltipText}
        placement="topLeft"
        overlayInnerStyle={{ borderRadius: 8, fontSize: 12 }} //Overlay inner doesn't accept class
        open={tooltipOpen && isSearchFocused}
      >
        <Input.Search
          placeholder={placeholder}
          {...props}
          data-testid="Search__input"
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
        />
      </Tooltip>
    </div>
  );
};

export default Search;
