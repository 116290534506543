import React from 'react';
import { SubscriptionType, User } from 'types';
import { getUserCurrentSubscription } from 'utils/users';
import cn from 'classnames';
import _capitalize from 'lodash/capitalize';
import styles from './index.module.scss';

export const renderPlanStatus = (user?: User) => {
  const currentSubscription = getUserCurrentSubscription(user);
  if (!currentSubscription) return '-';

  const { isCancelationScheduled, active } = currentSubscription;

  const shouldDisplayActive = active && !isCancelationScheduled;
  const shouldDisplayExpiring = active && isCancelationScheduled;
  const shouldDisplayCancelled = !active;

  return (
    <div className={styles.planStatus}>
      <div
        className={cn(styles.pointer, {
          [styles.activePointer]: shouldDisplayActive,
          [styles.expiringPointer]: shouldDisplayExpiring,
          [styles.cancelledPointer]: shouldDisplayCancelled,
        })}
      />
      {shouldDisplayActive && 'Active'}
      {shouldDisplayExpiring && 'Expiring'}
      {shouldDisplayCancelled && 'Cancelled'}
    </div>
  );
};

export interface UserClientsFilters {
  userType?: string;
}

export const renderUserSubscriptionPaymentType = (user: User) => {
  const currentSubscription = getUserCurrentSubscription(user);

  if (!currentSubscription) return '-';

  if (currentSubscription.gateway) {
    return _capitalize(currentSubscription.gateway);
  }

  if (currentSubscription.type === SubscriptionType.PREPAID) {
    return 'Prepaid';
  }

  return '-';
};
