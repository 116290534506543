import AssetUuidChooser from 'components/AssetUuidChooser';
import FormGroup from 'components/FormGroup';
import React, { useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { FeaturedAsset } from 'types/featuredAsset';
import {
  DEFAULT_ASSET_STATUSES_FOR_PAGINATION,
  DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET,
} from 'utils/constants';
import styles from '../../index.module.scss';

const FeaturedAssetDefaults = () => {
  const { control, watch, errors } = useFormContext<FeaturedAsset>();

  const selectedRegionsUuids = useMemo(
    () => watch('regionIds') as string[],
    [watch]
  );

  const getCommonAssetChooser = (
    field: ControllerRenderProps<Record<string, any>>,
    placeholder: string
  ) => (
    <AssetUuidChooser
      onChoose={field.onChange}
      chosen={field.value}
      placeholder={placeholder}
      regionUuids={selectedRegionsUuids}
      statuses={DEFAULT_ASSET_STATUSES_FOR_PAGINATION}
      allowedTypes={DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET}
    />
  );

  return (
    <>
      <FormGroup
        input={
          <Controller
            render={(field) =>
              getCommonAssetChooser(field, 'Select default asset')
            }
            control={control}
            name="defaultFeaturedAssetUuid"
            rules={{ required: 'This field is required' }}
            defaultValue=""
          />
        }
        label="Default"
        errorMessage={errors?.defaultFeaturedAssetUuid?.message}
      />

      <FormGroup
        input={
          <Controller
            render={(field) =>
              getCommonAssetChooser(
                field,
                'Select default asset for kids profile'
              )
            }
            control={control}
            name="defaultFeaturedAssetForKidsUuid"
            rules={{ required: 'This field is required' }}
            defaultValue=""
          />
        }
        label="Default for kids profile"
        errorMessage={errors?.defaultFeaturedAssetForKidsUuid?.message}
      />

      <div className={styles.note}>
        Displayed only if nothing from the queue below matches the rules:
        <ul>
          <li>no asset is set up to current date,</li>
          <li>user's age doesn't match the asset age restriction,</li>
          <li>asset is not yet published.</li>
        </ul>
      </div>
    </>
  );
};

export default FeaturedAssetDefaults;
