import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getInterestsList } from 'actions/interests';
import { Select } from 'antd';
import { useCallback, useMemo, useState, useTransition } from 'react';
import styles from './index.module.scss';

const PER_DROPDOWN = 3;

interface InterestsSelectProps {
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
  withoutNoPreference?: boolean;
}

const InterestsSelect = (props: InterestsSelectProps) => {
  const [search, setSearch] = useState('');
  const [, startTransition] = useTransition();
  const { data: interestsResponse } = useFetchActionObj(
    useCallback(
      () => getInterestsList({ limit: PER_DROPDOWN, skip: 0, name: search }),
      [search]
    )
  );

  const interestsWithNoPreference = useMemo(() => {
    if (!interestsResponse) return { data: [], count: 0 };
    if (props.withoutNoPreference) return interestsResponse;

    return {
      ...interestsResponse,
      data: [
        ...interestsResponse.data,
        {
          id: 999,
          name: 'no_preference',
          translations: [
            {
              language: 'en',
              value: 'No Preference',
            },
          ],
        },
      ],
      count: interestsResponse.count + 1,
    };
  }, [interestsResponse, props.withoutNoPreference]);
  const parsedInterests = useMemo(() => {
    if (!interestsWithNoPreference.data) return [];
    return interestsWithNoPreference?.data.map(({ name, translations }) => ({
      label:
        translations?.find(({ language }) => language === 'en')?.value || name,
      value: name,
    }));
  }, [interestsWithNoPreference]);
  return (
    <Select
      {...props}
      className={styles.root}
      allowClear
      searchValue={search}
      onSearch={(search) => {
        startTransition(() => {
          setSearch(search);
        });
      }}
      mode="multiple"
      options={parsedInterests}
    />
  );
};

export default InterestsSelect;
