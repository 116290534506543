import React, { useMemo } from 'react';
import { Input } from 'antd';
import Button from 'components/Button';
import ButtonsRow from 'components/ButtonsRow';
import ErrorIndicator from 'components/ErrorIndicator';
import FormContainer from 'components/FormContainer';
import Select from 'components/Select';
import Space from 'components/Space';
import { useSendRegion } from 'hooks/useRegions';
import countriesWithName from 'locales/en/countries.json';
import { RegionFormProps } from 'pages/Regions/RegionForm/types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sendRegionAction } from 'store/regions/actions';
import { ApiActionType, RegionCreateForm } from 'types';
import styles from './index.module.scss';

const RegionForm = ({
  onCancel,
  type,
  defaultValues,
  countries,
  isLoadingCountries,
}: RegionFormProps) => {
  const { control, handleSubmit, errors } = useForm<RegionCreateForm>({
    defaultValues: {
      name: defaultValues?.name,
      countries: defaultValues?.countries?.map((c) => c.code),
    },
  });
  const dispatch = useDispatch();
  const { isLoading, error } = useSendRegion();

  const countriesOptions = useMemo(() => {
    const availableCountriesOptions =
      countries?.map((c) => ({
        label: c.name,
        value: c.code,
      })) || [];
    const selectedCountriesOptions =
      defaultValues?.countries?.map((country) => {
        const c = countriesWithName.find((c) => c.code === country.code);

        return {
          label: c?.name,
          value: c?.code,
        };
      }) || [];

    return [...availableCountriesOptions, ...selectedCountriesOptions];
  }, [countries, defaultValues]);

  const regionId = defaultValues?.id;

  const sendFormHandler = (form: RegionCreateForm) => {
    dispatch(sendRegionAction.request({ form, type, regionId }));
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(sendFormHandler)}>
        <fieldset>
          <Space direction="vertical" expand>
            <div>
              <label>Name</label>
              <Controller
                as={Input}
                control={control}
                type="text"
                name="name"
                rules={{ required: true }}
              />
              {errors.name && <div>This field is required</div>}
            </div>
            <div>
              <div>
                <label>Countries</label>
              </div>
              <Controller
                as={
                  <Select
                    selectMode="multiple"
                    filterOption={(input, option) =>
                      (option?.label as string)
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  />
                }
                className={styles.fullInput}
                name="countries"
                rules={{ required: true }}
                control={control}
                options={countriesOptions}
                loading={isLoadingCountries}
                showSearch
              />
              {errors.countries && <div>This field is required</div>}
            </div>
            <ButtonsRow>
              <Button disabled={isLoading} type="default" onClick={onCancel}>
                Cancel
              </Button>
              <Button htmlType="submit" loading={isLoading} type="primary">
                Save
              </Button>
            </ButtonsRow>
            <ErrorIndicator error={error} />
          </Space>
        </fieldset>
      </form>
    </FormContainer>
  );
};

RegionForm.defaultProps = {
  type: ApiActionType.CREATE,
};

export default RegionForm;
