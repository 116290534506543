import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { User, UserCreateForm } from 'types';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Select from 'components/Select';
import Space from 'components/Space';
import { Input } from 'antd';
import { addUser, getRoles } from 'actions/users';
import PageContainer from 'components/PageContainer';
import FormContainer from 'components/FormContainer';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import { isEmail, isPasswordValidLength } from 'utils/validators';
import styles from './index.module.scss';

const UserCreate = () => {
  const [roles] = useFetchAction<string[]>(getRoles);
  const { push } = useHistory();
  const { control, handleSubmit, errors, reset } = useForm<UserCreateForm>();

  const validate = (val: string, type: 'email' | 'password') => {
    if (type === 'email' && val.length > 0 && !isEmail(val)) {
      return 'Please provide a valid email address!';
    }

    if (type === 'password' && val.length > 0 && !isPasswordValidLength(val)) {
      return 'Password must contain at least 8 characters.';
    }
  };

  const validateRoles = (val: string[]) => {
    if (!val || val?.length === 0) return false;
  };

  const [sendForm, isLoading] = useSendAction<User, UserCreateForm>(
    (form) => addUser({ form }),
    {
      onDone: () => {
        reset();
        push('/users');
      },
      onError: (error: any) => {
        notification.error({
          title: 'Error',
          description:
            error?.response?.data?.details ||
            'There was an error during the request, please check provided values or try again later.',
        });
        captureError(error);
      },
    }
  );

  return (
    <PageContainer>
      <PageHeader title="Add new user" />
      <FormContainer>
        <form onSubmit={handleSubmit(sendForm)}>
          <fieldset>
            <Space direction="vertical" expand>
              <div>
                <label>Email</label>
                <Controller
                  as={Input}
                  control={control}
                  name="email"
                  rules={{
                    required: 'This field is required',
                    validate: (value) => validate(value, 'email'),
                  }}
                />
                {errors?.email && (
                  <div className={styles.errorMessage}>
                    {errors?.email?.message}
                  </div>
                )}
              </div>

              <div>
                <label>Password</label>
                <Controller
                  as={Input}
                  control={control}
                  name="password"
                  rules={{
                    required: 'This field is required',
                    validate: (value) => validate(value, 'password'),
                  }}
                  type="password"
                />
                {errors?.password && (
                  <div className={styles.errorMessage}>
                    {errors?.password?.message}
                  </div>
                )}
              </div>

              <div>
                <label>Role</label>
                <div>
                  <Controller
                    as={Select}
                    style={{ width: '100%' }}
                    name="roles"
                    control={control}
                    options={roles?.map((role) => ({
                      label: role,
                      value: role,
                    }))}
                    selectMode="multiple"
                    rules={{
                      validate: (value) => validateRoles(value),
                    }}
                  />
                </div>
                {errors?.roles && (
                  <div className={styles.errorMessage}>
                    At least one role is required to create an user
                  </div>
                )}
              </div>

              <Button htmlType="submit" disabled={isLoading}>
                Add
              </Button>
            </Space>
          </fieldset>
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default UserCreate;
