import { Button, Divider, Dropdown, Menu, Modal, Table, Tag } from 'antd';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import { FC, useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  deleteAssetCensorshipRule,
  getCensorshipsRules,
} from 'actions/censorships';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { CensorshipRule } from 'types/censorships';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import StringList from 'components/StringList';
import ErrorIndicator from 'components/ErrorIndicator';
import { generateCensorshipRuleUrl } from 'pages/CensorshipRules/utils';
import { errorNotify } from 'utils/errorNotify';
import notification from 'utils/notification';
import { useGetRegions } from 'hooks/useRegions';
import { useHistory } from 'react-router-dom';
import { captureError } from 'utils/captureError';
import { getCensorshipRuleLanguageNames } from 'utils/getCensorshipRuleLanguageNames';
import { useAllLanguages } from 'hooks/useLanguages';
import styles from './index.module.scss';
import AgeRatingModalForm from './components/AgeRatingModalForm';
import AIModeration from './components/AIModeration';

const { Column } = Table;

const AgeRatings: FC = () => {
  const { push } = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { assetDraft } = useAssetDetailsContext();
  const { id } = assetDraft;
  const [censorshipRules, isLoading, error, refresh] = useFetchAction<
    CensorshipRule[]
  >(useCallback(() => getCensorshipsRules({ assetId: id }), [id]));
  const [deleteCensorshipRuleId, setDeleteCensorshipRuleId] = useState<
    number | undefined
  >();
  const { regions, isLoadingRegions } = useGetRegions();
  const { getLanguageNameByIsoCode } = useAllLanguages();

  const [isDeleting, setIsDeleting] = useState(false);

  const closeModalHandler = () => setIsModalOpen(false);

  const closeConfirmationModalHandler = () =>
    setDeleteCensorshipRuleId(undefined);

  const deleteCensorshipRuleHandler = async () => {
    if (!deleteCensorshipRuleId) return;
    setIsDeleting(true);

    try {
      await deleteAssetCensorshipRule({
        censorshipRuleId: deleteCensorshipRuleId,
        assetId: id,
      });
      setIsDeleting(false);
      closeConfirmationModalHandler();
      refresh();
      notification.success({
        title: 'Success',
        description: 'Age rating has been deleted',
      });
    } catch (err) {
      setIsDeleting(false);
      errorNotify(err as any);
      captureError(err as any);
    }
  };

  const getRegionName = (regionUuid: string) =>
    regions?.find((region) => region.uuid === regionUuid)?.name;

  const censorshipRuleEditHandler = (
    rule: CensorshipRule,
    regionName?: string
  ) => push(generateCensorshipRuleUrl(rule, regionName));

  return (
    <div className={styles.root}>
      <AIModeration />

      <PageContainer>
        <PageHeader
          title="Age Ratings"
          extra={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              Add region
            </Button>
          }
        />
        <Divider />
        {error && <ErrorIndicator error={error} />}
        <Table
          dataSource={censorshipRules}
          loading={isLoading || isLoadingRegions}
        >
          <Column
            title="Region"
            dataIndex="region"
            render={(_, rule: CensorshipRule) => getRegionName(rule.regionUuid)}
          />
          <Column
            title="Age Rating Label"
            key="name"
            dataIndex="name"
            align="left"
            render={(_, rule: CensorshipRule) => (
              <Tag children={rule.name} className={styles.ratingLabel} />
            )}
          />
          <Column title="Minimum Age" dataIndex="minAge" key="minAge" />
          <Column
            title="Public Details"
            key="publicDetails"
            dataIndex="publicDetails"
            render={(_, rule: CensorshipRule | null) => {
              if (!rule) return null;
              return (
                <StringList
                  items={getCensorshipRuleLanguageNames({
                    translations: rule.translations,
                    getLanguageNameByIsoCode,
                  })}
                />
              );
            }}
          />
          <Column
            render={(_, rule: CensorshipRule) => (
              <Dropdown.Button
                overlay={
                  <Menu>
                    <Menu.Item
                      key="remove"
                      onClick={() => setDeleteCensorshipRuleId(rule.id)}
                    >
                      Delete
                    </Menu.Item>
                    <Menu.Item
                      key="edit"
                      onClick={() =>
                        censorshipRuleEditHandler(
                          rule,
                          getRegionName(rule.regionUuid)
                        )
                      }
                    >
                      Edit
                    </Menu.Item>
                  </Menu>
                }
              />
            )}
          />
        </Table>
      </PageContainer>

      {isModalOpen && (
        <Modal
          visible={isModalOpen}
          title="Add Region"
          footer={false}
          onCancel={closeModalHandler}
          centered
        >
          <AgeRatingModalForm
            refresh={refresh}
            onClose={closeModalHandler}
            assetId={id}
          />
        </Modal>
      )}
      <Modal
        title="Confirmation"
        visible={!!deleteCensorshipRuleId}
        onCancel={closeConfirmationModalHandler}
        onOk={deleteCensorshipRuleHandler}
        confirmLoading={isDeleting}
        centered
      >
        Are you sure you want to delete this age rating?
      </Modal>
    </div>
  );
};

export default AgeRatings;
