import axios from 'axios';
import api from './api';

interface UploadProgressCallback {
  (progress: number): void;
}

const maxSize = 55;

/**
 * Only alphanumbers and hyphen (-) and underscore (_)
 */
const allowedCharacters = /^[a-zA-Z0-9\-_]$/;
const allowedExtensions = [
  '3g2',
  '3gp',
  'ogv',
  'ogm',
  'ogg',
  'avi',
  'mkv',
  'mk3d',
  'mj2',
  'm2ts',
  'mpg',
  'mpg2',
  'mpeg',
  'mp4',
  'm4v',
  'm1v',
  'm2v',
  'mpa',
  'mxf',
  'mpv',
  'webm',
  'ts',
  'mov',
  'asf',
  'wmv',
  'qt',
  'flv',
  'swf',
  // allow upload of subtitles
  'srt',
];

const validateFileNamePart = (name: string): string | undefined => {
  if (name.length > maxSize) {
    return 'Too long';
  }

  for (const char of name.split('')) {
    if (!allowedCharacters.test(char)) {
      return 'Disallowed character ' + char;
    }
  }

  if (name.startsWith('_')) {
    return 'Cannot start with _';
  }

  if (name.startsWith('-')) {
    return 'Cannot start with -';
  }

  return undefined;
};

const validateFileExtension = (extension: string): string | undefined => {
  if (extension === undefined || extension.length === 0)
    return 'Extension is required';

  if (!allowedExtensions.includes(extension)) return 'Invalid extension';

  return undefined;
};

export const validateFileName = (fileName: string): string | undefined => {
  const [name, extension, ...rest] = fileName.split('.');

  if (rest.length > 0) return 'Disallowed character .';

  const nameError = validateFileNamePart(name);
  if (nameError) return nameError;

  const extensionError = validateFileExtension(extension);
  if (extensionError) return extensionError;

  return undefined;
};

export const initFileUpload = async (name: string) => {
  const response = await api().post(`/source/upload`, { name });
  return response.data;
};

export const uploadFileToS3 = async ({
  file,
  signedUrl,
  onProgress,
  params,
}: {
  file: File;
  signedUrl: string;
  onProgress: (percentage: number) => void;
  params: { [key: string]: string };
}) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.set(key, params[key]);
  });
  formData.set('file', file);

  return axios.post(signedUrl, formData, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (event) => onProgress(event.loaded / file.size),
  });
};

export const generateFileName = (file: File) =>
  Date.now() + '_' + file.name.toLowerCase();

export const uploadMultipartFile = async (
  file: File,
  uploadFileName: string,
  onProgress: UploadProgressCallback
) => {
  const { url, fields } = await initFileUpload(uploadFileName);

  // TODO: Enable retry (from @laminar-product/client-commons-ui)
  // await retry(
  //   () =>
  //     uploadFileToS3({
  //       file,
  //       signedUrl: url,
  //       params: fields,
  //       onProgress: onProgress,
  //     }),
  //   3
  // );

  await uploadFileToS3({
    file,
    signedUrl: url,
    params: fields,
    onProgress: onProgress,
  });
};
