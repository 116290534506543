import React, { useCallback } from 'react';
import { Form, Input, Radio, Select } from 'antd';
import { Controller, UseFormMethods } from 'react-hook-form';
import FormGroup from 'components/FormGroup';
import ContentChooser from 'components/ContentChooser';
import { contentTypes } from 'utils/constants';
import { Content, ContentCreateForm } from 'types';
import cn from 'classnames';
import styles from '../index.module.scss';

const ContentAssign = ({
  onSelectContent,
  selectedContent,
  onModeChange,
  mode,
  form,
  formProps,
}: {
  onSelectContent: (content?: Content) => void;
  selectedContent: Content | undefined;
  onModeChange: (mode: 'select' | 'create') => void;
  mode: 'select' | 'create';
  form: UseFormMethods<ContentCreateForm>;
  formProps: { labelCol: { span: number }; style: { marginTop: number } };
}) => {
  const resetContentState = useCallback(() => {
    onSelectContent(undefined);
    form.reset();
  }, [form, onSelectContent]);

  return (
    <>
      <Radio.Group
        onChange={(e) => {
          onModeChange(e.target.value);
          resetContentState();
        }}
        value={mode}
        className={cn(styles.radioButton, styles.flex)}
      >
        <Radio.Button value="create" className={styles.radioButton}>
          Create new content
        </Radio.Button>
        <Radio.Button value="select" className={styles.radioButton}>
          Select content
        </Radio.Button>
      </Radio.Group>
      {mode === 'select' && (
        <Form {...formProps}>
          <FormGroup
            label="Content"
            input={
              <ContentChooser
                onChoose={onSelectContent}
                chosen={selectedContent}
                filterHasFile={false}
                filterHasAsset={false}
              />
            }
          />
        </Form>
      )}
      {mode === 'create' && (
        <Form {...formProps}>
          <FormGroup
            label="Name"
            input={
              <Controller
                as={Input}
                control={form.control}
                type="text"
                name="title"
                rules={{ required: true }}
              />
            }
          />
          <FormGroup
            label="Type"
            input={
              <Controller
                as={<Select className={styles.fullWidth} />}
                control={form.control}
                name="type"
                rules={{ required: true }}
                options={contentTypes}
              />
            }
          />
        </Form>
      )}
    </>
  );
};

export default ContentAssign;
