import { IntervalType } from './plan';

export interface BasicPrepaidCard {
  title: string;
  planId: string;
  billingFrequency: IntervalType;
  intervalMultiplier?: number;
}

export interface PrepaidCard {
  uuid: string;
  title: string;
  planId: string;
  planName: string;
  billingFrequency: IntervalType;
  intervalMultiplier?: number;
  createdAt: string;
  status: PrepaidCardStatus;
  publishedAt?: string;
}

export interface PrepaidCardBatch {
  batchId: string;
  catologItemId: string;
  name: string;
  configuration: PrepaidCardBatchConfiguration;
  createdAt: string;
  isReadyToDownload: boolean;
  redeemed?: number;
}

export interface PrepaidCardBasicBatch {
  name: string;
  validFrom: string;
  validTo: string;
  count: number;
}

export interface PrepaidCardBatchConfiguration {
  validFrom: string;
  validTo: string;
  count: number;
}

export interface BatchPinExport {
  batchId: string;
  pinStatus?: BatchPinExportStatus;
  batchName?: string;
}

export enum PrepaidCardStatus {
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
}

export enum BatchPinExportStatus {
  BOTH = 'Both',
  NOT_REDEEMED = 'NotRedeemed',
  REDEEMED = 'Redeemed',
}

export interface BatchGenerateRequest {
  batch: PrepaidCardBasicBatch;
  prepaidCardUuid: string;
}

export interface PinDetails {
  pin: string;
  catalogItemId: string;
  batchId: string;
  batchName: string;
  validityPeriod: {
    from: string;
    to: string;
  };
  attributes: {
    type: string;
    resourceType: string;
    resourceId: string;
    billingFrequency: IntervalType;
  };
  redeemed?: boolean;
  redeemerId?: string;
  redeemedAt?: string;
}

export interface PinDetailsResponse {
  prepaid: PrepaidCard;
  pin: PinDetails;
}

export interface PrepaidTableFilters {
  status?: PrepaidCardStatus;
  planUuid?: string;
}
