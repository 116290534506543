import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  downloadAllPinsFromPrepaidCard,
  downloadPinsFromBatch,
} from 'actions/prepaid';
import { Modal, Radio } from 'antd';
import FormGroup from 'components/FormGroup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  BatchPinExport,
  BatchPinExportStatus,
  PrepaidCard,
  PrepaidCardBatch,
} from 'types/prepaidCard';
import { captureError } from 'utils/captureError';
import { ALL_BATCHES_VALUE, batchPinStatuses } from 'utils/constants';
import notification from 'utils/notification';
// @ts-ignore
import { CSVLink } from 'react-csv/lib';
import PrepaidCardsBatchSelector from '../PrepaidCardsBatchSelector';

interface PrepaidCardsBatchExportProps {
  isVisible: boolean;
  onClose: () => void;
  batches?: PrepaidCardBatch[];
  prepaidCard: PrepaidCard;
}

type CSVLinkRef =
  | (CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement })
  | null;

const PrepaidCardsBatchExport = ({
  isVisible,
  onClose,
  batches,
  prepaidCard,
}: PrepaidCardsBatchExportProps) => {
  const [dataToExport, setDataToExport] = useState<string | null>(null);
  const [exportedBatchName, setExportedBatchName] = useState<string>('');
  const csvLinkRef = useRef<CSVLinkRef>(null);
  const { control, errors, handleSubmit } = useForm<BatchPinExport>({
    mode: 'onSubmit',
    defaultValues: {
      batchId: ALL_BATCHES_VALUE,
      pinStatus: BatchPinExportStatus.BOTH,
    },
  });

  const handleError = (error: any) => {
    notification.error({
      title: 'Error',
      description:
        'There was an error during the request, please check provided values or try again later.',
    });

    captureError(error);
  };

  const {
    sendAction: downloadPrepaidCardAllPins,
    isLoading: isDownloadingPrepaidPins,
  } = useSendActionObj<string, BatchPinExport>(
    async ({ pinStatus }) =>
      await downloadAllPinsFromPrepaidCard({
        prepaidCardUuid: prepaidCard.uuid,
        pinRedeemStatus: pinStatus,
      }),
    {
      onDone: setDataToExport,
      onError: handleError,
    }
  );

  const { sendAction: downloadBatchPins, isLoading: isDownloadingBatchPins } =
    useSendActionObj<string, BatchPinExport>(
      async ({ batchId, pinStatus, batchName }) => {
        if (batchName) {
          setExportedBatchName(batchName);
        }

        return await downloadPinsFromBatch({
          prepaidCardUuid: prepaidCard.uuid,
          batchUuid: batchId,
          pinRedeemStatus: pinStatus,
        });
      },
      {
        onDone: setDataToExport,
        onError: handleError,
      }
    );

  const onSubmit = (values: BatchPinExport) => {
    //Downloading all pins in entire prepaid card
    if (values.batchId === ALL_BATCHES_VALUE) {
      return downloadPrepaidCardAllPins(values);
    }

    //Downloading pins for certain batch
    const batch = batches?.find((batch) => batch.batchId === values.batchId);
    downloadBatchPins({ ...values, batchName: batch?.name });
  };

  const getCSVFilename = useCallback(() => {
    let filename = prepaidCard.title;

    if (exportedBatchName) {
      filename = filename.concat('-', exportedBatchName);
    }

    return filename.replace(/ /g, '').concat('.csv');
  }, [exportedBatchName, prepaidCard.title]);

  useEffect(() => {
    if (dataToExport !== null && csvLinkRef?.current) {
      csvLinkRef.current.link.click();
      onClose();
    }
  }, [dataToExport, onClose]);

  return (
    <>
      <Modal
        title="Export batch to CSV"
        open={isVisible}
        onCancel={onClose}
        okText="Export"
        destroyOnClose
        onOk={handleSubmit(onSubmit)}
        confirmLoading={isDownloadingBatchPins || isDownloadingPrepaidPins}
      >
        <FormGroup
          input={
            <Controller
              render={(field) => (
                <PrepaidCardsBatchSelector
                  selectedBatchUuid={field.value}
                  onChoose={field.onChange}
                  batches={batches}
                />
              )}
              control={control}
              rules={{ required: 'This field is required' }}
              name="batchId"
            />
          }
          label="Batch"
          errorMessage={errors.batchId?.message}
          defaultValue=""
        />

        <FormGroup
          input={
            <Controller
              render={(field) => (
                <Radio.Group
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  options={batchPinStatuses}
                  defaultValue=""
                />
              )}
              control={control}
              name="pinStatus"
            />
          }
          label="PIN status"
          errorMessage={errors.pinStatus?.message}
        />
      </Modal>

      <CSVLink
        data={dataToExport || ''}
        filename={getCSVFilename()}
        ref={csvLinkRef}
        separator={`,`}
        enclosingCharacter={`"`}
        target="_blank"
      />
    </>
  );
};

export default PrepaidCardsBatchExport;
