import { Interest } from 'types/interests';
import api from './api';

interface GetInterestsList {
  data: Interest[];
  count: number;
}
interface GetInterestsListProps {
  skip: number;
  limit: number;
  name: string;
}

export const getInterestsList = async (props: GetInterestsListProps) => {
  const { data } = await api().get<GetInterestsList>('/interest', {
    params: props,
  });
  return data;
};

interface GetInterestConfig {
  interestEnabled: boolean;
}

export const getInterestConfig = async () => {
  const { data } = await api().get<GetInterestConfig>('/interest/settings');
  return data;
};

export const saveInterest = async (interest: Interest) =>
  api().post('/interest', interest);

export const changeInterestConfig = async (interestEnabled: boolean) =>
  api().patch('/interest/publish', { interestEnabled });

export const deleteInterest = async (name: string) =>
  api().delete(`/interest/${name}`);

export const updateInterest = async (interest: Interest) =>
  api().patch(`/interest/${interest.name}`, interest.translations);
