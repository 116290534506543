import { GlobalOutlined, WifiOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import React from 'react';
import { Modal } from 'antd';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { LiveStreamInfrastructureStatus } from 'types/live';
import AssetStatusTag from 'components/AssetStatusTag';
import { AssetStatus } from 'types';
import { endBroadcast, goLive } from 'actions/live';
import LiveTag from 'components/LiveTag';
import notification from 'utils/notification';
import styles from './HeaderAdditionalInfo.module.scss';

const HeaderAdditionalInfo = () => {
  const { liveDetails, isLiveType, assetDraft, setLiveDetails } =
    useAssetDetailsContext();
  const shouldUnblockLive = liveDetails?.status
    ? [
        LiveStreamInfrastructureStatus.RUNNING,
        LiveStreamInfrastructureStatus.LIVE,
      ].includes(liveDetails.status)
    : false;

  const onPublishError = (error: any) => {
    notification.error({
      title: 'Error',
      description:
        error?.response?.data?.details ||
        'There was an error during the request, please check asset configuration and try again.',
    });
  };

  const goLiveHandler = () =>
    Modal.confirm({
      title: 'Are you sure you want to go live?',
      content:
        'Event/Channel video will be available to watch in all published regions.',
      onOk: async () => {
        try {
          const details = await goLive(assetDraft.id);
          setLiveDetails?.(details);
        } catch (error) {
          onPublishError(error);
        }
      },
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
    });

  const endBroadcastHandler = () =>
    Modal.confirm({
      title: 'Are you sure you want to end broadcast?',
      onOk: async () => {
        try {
          const details = await endBroadcast(assetDraft.id);
          setLiveDetails?.(details);
        } catch (error) {
          onPublishError(error);
        }
      },
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
    });

  const liveButtonHandler = () =>
    liveDetails?.status === LiveStreamInfrastructureStatus.LIVE
      ? endBroadcastHandler()
      : goLiveHandler();

  const liveButtonText =
    liveDetails?.status === LiveStreamInfrastructureStatus.LIVE
      ? 'End broadcast'
      : 'Go live';

  return (
    <div className={styles.root}>
      <div className={styles.infoContainer}>
        {assetDraft.status === AssetStatus.PUBLISHED && (
          <GlobalOutlined className={styles.icon} />
        )}
        <AssetStatusTag status={assetDraft.status || AssetStatus.DRAFT} />
      </div>
      {isLiveType && (
        <>
          <div className={styles.infoContainer}>
            <WifiOutlined className={styles.icon} />
            <LiveTag details={liveDetails} />
          </div>
          <div className={styles.infoContainer}>
            <Button
              disabled={!shouldUnblockLive}
              type="primary"
              danger={
                liveDetails?.status === LiveStreamInfrastructureStatus.LIVE
              }
              onClick={liveButtonHandler}
            >
              {liveButtonText}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderAdditionalInfo;
