import api from './api';

export const generateLinkForClient = async ({
  userUuid,
  planUuid,
  priceUuid,
}: {
  userUuid: string;
  planUuid: string;
  priceUuid: string;
}): Promise<any> =>
  await api().post(`supervisor/client/${userUuid}/payment-link`, {
    planUuid,
    priceUuid,
  });
