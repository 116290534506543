import moment from 'moment';
import { AssetForm } from 'types';

export const validateLiveEventDates = (form: AssetForm) => {
  const { availableFrom, availableTo } = form;

  if (!availableFrom || !availableTo) {
    return 'Start date and end date are required';
  }

  if (
    !!availableFrom &&
    !!availableTo &&
    moment(Number(availableTo)).isSameOrBefore(Number(availableFrom), 'minute')
  ) {
    return 'Start date should be after end date';
  }
};
