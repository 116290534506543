import React, { useCallback } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { detachImage } from 'actions/assets';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import styles from './index.module.scss';

const ImageRemover = ({
  assetId,
  imageId,
  refresh,
}: {
  assetId: number;
  imageId: number;
  refresh: () => void;
}) => {
  const action = useCallback(
    () => detachImage({ id: assetId, imageId }),
    [assetId, imageId]
  );
  const [startRemoving, isProcessing, , , error] = useSendAction(action, {
    onDone: () => {
      refresh();
      notifyAboutRepublishRequirement();
    },
  });

  if (error) return <ErrorIndicator error={error} />;

  return (
    <Button
      icon={<DeleteOutlined />}
      className={styles.removeButton}
      onClick={() => startRemoving()}
      loading={isProcessing}
    />
  );
};

export default ImageRemover;
