import { PromotionType } from '@laminar-product/client-commons-core/core';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import {
  getPromotionsPagination,
  publishPromotion,
  unpublishPromotion,
  updatePromotion,
} from 'actions/promotions';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { DiscountType, Promotion } from 'types/promotions';
import { captureError } from 'utils/captureError';
import { errorNotify } from 'utils/errorNotify';
import notification from 'utils/notification';

export const usePromotionSave = (id: string, type: PromotionType) => {
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  const [save, isSaving] = useSendAction<Promotion, Promotion>(
    async (form) => {
      return await savePromotion(form, id, type);
    },
    {
      onDone: () => {
        setSaveSuccess(true);
        notification.success({
          title: 'Success',
          description: 'Promotion updated!',
        });
      },
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  return { save, isSaving, saveSuccess };
};

export const savePromotion = (
  form: Promotion,
  id: string,
  type: PromotionType
) => {
  const formToSave = prepareFormToSave(form, type);
  return updatePromotion({ uuid: id, promotion: formToSave });
};

const prepareFormToSave = (form: Promotion, type: PromotionType) => ({
  ...form,
  amount:
    form.discountType === DiscountType.AMOUNT
      ? Math.ceil((form.amount || 0) * 100)
      : form.amount,
  promotionType: type,
  startDate: moment(form.startDate).format(),
  endDate: moment(form.endDate).format(),
});

export const usePromotionPublish = (
  id: string,
  type: PromotionType,
  onPublishSuccess: () => void
) => {
  const [saveAndpublish, isPublishing] = useSendAction<Promotion, Promotion>(
    async (form) => {
      await savePromotion(form, id, type);
      return await publishPromotion(id);
    },
    {
      onDone: () => {
        onPublishSuccess();
        notification.success({
          title: 'Success',
          description: 'Promotion saved and published!',
        });
      },
      onError: errorNotify,
    }
  );

  return { saveAndpublish, isPublishing };
};

export const usePromotionUnpublish = (
  id: string,
  onUnpublishSuccess: () => void
) => {
  const [unpublish, isUnpublishing] = useSendAction<Promotion>(
    async () => {
      return await unpublishPromotion(id);
    },
    {
      onDone: () => {
        onUnpublishSuccess();
        notification.success({
          title: 'Success',
          description: 'Promotion unpublished!',
        });
      },
      onError: errorNotify,
    }
  );

  return { unpublish, isUnpublishing };
};

interface GetPromotionProps {
  promotionType?: PromotionType;
  page?: number;
  limit?: number;
  query?: string;
}

export const useGetPromotions = ({
  promotionType,
  query,
  limit,
  page = 1,
}: GetPromotionProps) => {
  const action = useCallback(
    () => getPromotionsPagination({ query, page, limit, promotionType }),
    [query, limit, page, promotionType]
  );
  const [pagination, isLoading, error, refresh] = useFetchAction(action);
  const data = pagination?.data || [];

  return { pagination, data, isLoading, refresh, error };
};
