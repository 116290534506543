import React, { useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { useDebounce } from '@laminar-product/client-commons-core/hooks';
import ErrorIndicator from 'components/ErrorIndicator';
import { Promotion } from 'types/promotions';
import { PlanPrice } from 'types/plan';
import { useGetPromotions } from 'pages/Promotions/promotionUtils/promotions';
import styles from './index.module.scss';

const { Option } = Select;

type PromotionsChooserProps = {
  onChoose: (promotion: Promotion) => void;
  placeholder?: string;
  prices?: PlanPrice[];
  activePricingUuid?: string;
};

const PromotionsChooser = ({
  onChoose,
  placeholder = 'Search for promotion',
  prices,
  activePricingUuid,
}: PromotionsChooserProps) => {
  const [query, setQuery] = useState('');
  const queryDebounced = useDebounce(query, 300);

  const {
    data: promotions,
    isLoading,
    error,
  } = useGetPromotions({
    query: queryDebounced,
  });

  const activePricePromotions = useMemo(
    () => prices?.find((price) => price.uuid === activePricingUuid)?.promotions,
    [prices, activePricingUuid]
  );

  const onSelectChange = (uuid: string) => {
    onChoose(
      promotions?.find((promotion: Promotion) => promotion.uuid === uuid)!
    );
  };

  return (
    <div className={styles.fullWidth}>
      <Select
        showSearch
        onSearch={setQuery}
        placeholder={placeholder}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onChange={onSelectChange}
        className={styles.fullWidth}
      >
        {promotions
          .filter(
            (promotion: Promotion) =>
              !activePricePromotions?.some(
                (existingPromotion) => existingPromotion.uuid === promotion.uuid
              ) && !!promotion.uuid
          )
          .map((promotion: Promotion) => (
            <Option key={promotion.uuid} value={promotion.uuid}>
              {promotion.name}
            </Option>
          ))}
      </Select>
      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default PromotionsChooser;
