import { Button, PageHeader } from 'antd';
import PageContainer from 'components/PageContainer';
import React, { useState } from 'react';
import PrepaidCardsTable from '../components/PrepaidCardsTable';
import PrepaidCardsPinSearch from '../components/PrepaidCardsPinSearch';
import PrepaidCardsCreateModal from '../components/PrepaidCardsCreateModal';

const PrepaidCardsList = () => {
  const [isPinSearchVisible, setIsPinSearchVisible] = useState(false);
  const [isCreateNewCardModalVisible, setIsCreateNewCardModalVisible] =
    useState(false);

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Prepaid cards"
          extra={[
            <Button
              key="searchForPin"
              onClick={() => setIsPinSearchVisible(true)}
            >
              Search for PIN
            </Button>,
            <Button
              key="createNewPrepaidCard"
              type="primary"
              onClick={() => setIsCreateNewCardModalVisible(true)}
            >
              Create new card
            </Button>,
          ]}
        />
        <PrepaidCardsTable />
      </PageContainer>

      {isPinSearchVisible && (
        <PrepaidCardsPinSearch
          isOpen={isPinSearchVisible}
          onClose={() => setIsPinSearchVisible(false)}
          modalTitle="Search for a pin"
        />
      )}

      {isCreateNewCardModalVisible && (
        <PrepaidCardsCreateModal
          isOpen={isCreateNewCardModalVisible}
          onClose={() => setIsCreateNewCardModalVisible(false)}
        />
      )}
    </>
  );
};

export default PrepaidCardsList;
