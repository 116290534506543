import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudUploadOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileImageOutlined,
  FileOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  HeartOutlined,
  KeyOutlined,
  MenuOutlined,
  PercentageOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  SmileOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import cn from 'classnames';
import { useActiveMenuKey } from 'hooks/activeMenuKeyHook';
import { usePermission } from 'hooks/permissionsHook';
import { Link } from 'react-router-dom';
import { Paths } from 'types/paths';
import { Permission } from 'utils/constants';
import { ReactComponent as AvodIcon } from 'assets/icons/avod_icon.svg';
import { ReactComponent as GuestIcon } from 'assets/icons/guest-icon.svg';
import navbarLogo from '../../pages/Login/Logo/laminar-logo.png';
import styles from './index.module.scss';

const { SubMenu } = Menu;
const { Sider } = Layout;

interface MainSidebarProps {
  fixed?: boolean;
}

const MainSidebar = ({ fixed }: MainSidebarProps) => {
  const menuKey = useActiveMenuKey(1, '/dashboard');
  const { canUse, canUseAny } = usePermission();

  return (
    <Sider className={cn(styles.sider, { [styles.fixed]: fixed })}>
      <div className={styles.center}>
        <Link to="/">
          <img src={navbarLogo} alt="Logo" className={styles.logo} />
        </Link>
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={[menuKey]}>
        <Menu.Item key="/" icon={<BarChartOutlined />}>
          <Link to="/" data-testid="Menu__link--dashboard">
            Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item key="/files" icon={<CloudUploadOutlined />}>
          <Link to={Paths.FILES} data-testid="Menu__link--files">
            Files
          </Link>
        </Menu.Item>
        {/* hide for now
                  <Menu.Item key="/live">
                    <Link to={Paths.LIVE}>
                      <VideoCameraOutlined />
                      Live
                    </Link>
                  </Menu.Item> */}
        <Menu.Item key="/content" icon={<AppstoreOutlined />}>
          <Link to={Paths.CONTENT} data-testid="Menu__link--content">
            Content
          </Link>
        </Menu.Item>
        <Menu.Item key="/interests" icon={<HeartOutlined />}>
          <Link to={Paths.INTERESTS} data-testid="Menu__link--interests">
            Interests
          </Link>
        </Menu.Item>
        <Menu.Item key="/assets" icon={<FileImageOutlined />}>
          <Link to={Paths.ASSETS} data-testid="Menu__link--assets">
            Assets
          </Link>
        </Menu.Item>
        <Menu.Item key="/seo" icon={<FileSearchOutlined />}>
          <Link to={Paths.SEO} data-testid="Menu__link--seo">
            SEO
          </Link>
        </Menu.Item>
        <Menu.Item key="/prepaidCards" icon={<CreditCardOutlined />}>
          <Link to={Paths.PREPAID_CARDS} data-testid="Menu__link--prepaidCards">
            Prepaid cards
          </Link>
        </Menu.Item>
        {/* Cannot put antd menu subitem and item in React fragment coz of bug with rendering*/}
        {/* There is link to reported issue on github */}
        {/* https://github.com/ant-design/ant-design/issues/18022 */}
        {canUseAny([Permission.GET_USER, Permission.GET_CLIENT]) && (
          <SubMenu
            icon={<TeamOutlined />}
            title="Users"
            data-testid="Submenu__users"
          >
            {canUse([Permission.GET_USER]) && (
              <Menu.Item key="/users">
                <Link to={Paths.USERS} data-testid="Menu__link--admins">
                  Admins
                </Link>
              </Menu.Item>
            )}

            {canUse([Permission.GET_CLIENT]) && (
              <Menu.Item key="/clients">
                <Link to={Paths.CLIENTS} data-testid="Menu__link--clients">
                  Clients
                </Link>
              </Menu.Item>
            )}

            <Menu.Item key="/distributorsClients">
              <Link
                to={Paths.DISTRIBUTORS_CLIENTS}
                data-testid="Menu__link--distributorsClients"
              >
                Distributors' clients
              </Link>
            </Menu.Item>
            {canUse([Permission.VIEW_AUDIT_LOGS]) && (
              <Menu.Item key="/auditLog">
                <Link to={Paths.AUDIT_LOG} data-testid="Menu__link--auditLog">
                  Audit Log
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {canUse([Permission.GET_PLAN]) && (
          <Menu.Item key="/plans" icon={<ShoppingCartOutlined />}>
            <Link to={Paths.PLANS} data-testid="Menu__link--products">
              Plans
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="/guestAccess" icon={<GuestIcon />}>
          <Link to={Paths.GUEST_ACCESS} data-testid="Menu__link--guestAccess">
            Guest Access
          </Link>
        </Menu.Item>

        <Menu.Item key="/avod" icon={<AvodIcon />}>
          <Link to={Paths.AVOD} data-testid="Menu__link--avod">
            Ads in free assets
          </Link>
        </Menu.Item>

        {canUse([Permission.GET_COUPON]) && (
          <SubMenu
            icon={<PercentageOutlined />}
            title="Promotions"
            data-testid="Submenu__promotions"
          >
            <Menu.Item key="/internal">
              <Link to={Paths.INTERNAL} data-testid="Submenu__link---internal">
                Internal
              </Link>
            </Menu.Item>
            <Menu.Item key="/affiliate">
              <Link to={Paths.AFFILIATE} data-testid="Submenu__link--affiliate">
                Affiliate
              </Link>
            </Menu.Item>
          </SubMenu>
        )}

        {canUse([Permission.GET_PAGE]) && (
          <Menu.Item key="/pages" icon={<FileOutlined />}>
            <Link to={Paths.PAGES} data-testid="Menu__link--pages">
              Pages
            </Link>
          </Menu.Item>
        )}

        {canUse([Permission.GET_MENU]) && (
          <Menu.Item key="/menus" icon={<MenuOutlined />}>
            <Link to={Paths.MENUS} data-testid="Menu__link--menus">
              Menus
            </Link>
          </Menu.Item>
        )}

        {canUse([Permission.GET_COLLECTION]) && (
          <Menu.Item key="/collections" icon={<UnorderedListOutlined />}>
            <Link to="/collections" data-testid="Menu__link--collections">
              Collections
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="/featured-assets" icon={<ScheduleOutlined />}>
          <Link to="/featured-assets" data-testid="Menu__link--featured-assets">
            Featured assets
          </Link>
        </Menu.Item>

        {canUse([Permission.CONTENT_DISTRIBUTION_READ]) && (
          <Menu.Item key="/contentDistribution" icon={<ShareAltOutlined />}>
            <Link
              to={Paths.CONTENT_DISTRIBUTION}
              data-testid="Menu__link--contentDistribution"
            >
              Content distribution
            </Link>
          </Menu.Item>
        )}

        {canUse([Permission.PLATFORM_PARTNER_READ]) && (
          <Menu.Item key="/partners" icon={<ShareAltOutlined />}>
            <Link to={Paths.PARTNERS} data-testid="Menu__link--partners">
              Platform partners
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="/tax-management" icon={<DollarOutlined />}>
          <Link
            to={Paths.TAX_MANAGEMENT}
            data-testid="Menu__link--tax-management"
          >
            Tax management
          </Link>
        </Menu.Item>

        {canUseAny([
          Permission.GET_REGION,
          Permission.GET_GENRE,
          Permission.GET_MOOD,
          Permission.GET_CENSORSHIP,
          Permission.GET_MODERATION,
        ]) && (
          <SubMenu
            icon={<SettingOutlined />}
            title="Settings"
            data-testid="Submenu__settings"
          >
            {canUse([Permission.GET_REGION]) && (
              <Menu.Item
                key="/regions"
                data-testid="Submenu__link--regions"
                icon={<GlobalOutlined />}
              >
                <Link to={Paths.REGIONS}>Regions</Link>
              </Menu.Item>
            )}
            {canUse([Permission.GET_CENSORSHIP]) && (
              <Menu.Item key="/censorships" icon={<KeyOutlined />}>
                <Link
                  to={Paths.CENSORSHIPS}
                  data-testid="Submenu__link--censorships"
                >
                  Age Ratings
                </Link>
              </Menu.Item>
            )}
            {canUse([Permission.GET_GENRE]) && (
              <Menu.Item key="/genres" icon={<FolderOpenOutlined />}>
                <Link to={Paths.GENRES} data-testid="Submenu__link--genres">
                  Genres
                </Link>
              </Menu.Item>
            )}
            {canUse([Permission.GET_MOOD]) && (
              <Menu.Item key="/moods" icon={<SmileOutlined />}>
                <Link to={Paths.MOODS} data-testid="Submenu__link--moods">
                  Moods
                </Link>
              </Menu.Item>
            )}
            {canUse([Permission.GET_MODERATION]) && (
              <Menu.Item key="/appropriateness-tags" icon={<TagsOutlined />}>
                <Link
                  to={Paths.APPROPRIATENESS_TAGS}
                  data-testid="Submenu__link--appropriateness-tags"
                >
                  Appropriateness Tags
                </Link>
              </Menu.Item>
            )}
            {canUse([Permission.GET_PLATFORM_LANGUAGES]) && (
              <Menu.Item key="/languages" icon={<GlobalOutlined />}>
                <Link
                  to={Paths.LANGUAGES}
                  data-testid="Submenu__link--languages"
                >
                  Languages
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
      </Menu>
    </Sider>
  );
};

export default MainSidebar;
