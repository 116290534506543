import { Button, Spin } from 'antd';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import StatusCard from 'components/StatusCard';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  FeaturedAsset,
  FeaturedAssetSource,
  FeaturedAssetStatus,
} from 'types/featuredAsset';
import { RouteIdParams } from 'utils/routeParams';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  mapAssetsToQueueTableItems,
  mapQueuedAssetsToUuids,
  useFeaturedAssetActions,
} from 'pages/FeaturedAssets/utils';
import AssetAddToEntityModal from 'components/AssetAddToEntityModal';
import { Asset } from 'types';
import notification from 'utils/notification';
import { getAssetsPagination } from 'actions/assets';
import { captureError } from 'utils/captureError';
import {
  convertLocalDateToUTCWithOffset,
  convertNumberToUTCWithOffset,
} from 'utils/timezones';
import _omit from 'lodash/omit';
import { DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET } from 'utils/constants';
import styles from '../../index.module.scss';
import FeaturedAssetGeneralDetails from '../FeaturedAssetGeneralDetails';
import FeaturedAssetDefaults from '../FeaturedAssetDefaults';
import DeleteFeaturedAssetModal from '../DeleteFeaturedAssetModal';
import FeaturedAssetScheduledQueue from '../FeaturedAssetScheduledQueue';

const FeaturedAssetDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const formMethods = useForm<FeaturedAsset>({});
  const { reset, handleSubmit, watch, setValue } = formMethods;

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [addAssetsToQueueModalOpen, setAddAssetsToQueueModalOpen] =
    useState(false);
  const [originalQueuedAssets, setOriginalQueuedAssets] = useState<
    FeaturedAssetSource[]
  >([]);

  const [currentQueuedAssets, setCurrentQueuedAssets] = useState<
    FeaturedAssetSource[]
  >([]);

  const [queuedUuidsInEditMode, setQueuedUuidsInEditMode] = useState<string[]>(
    []
  );
  const [isLoadingAssetsDetails, setIsLoadingAssetsDetails] = useState(false);
  const { push } = useHistory();

  const {
    featuredAsset,
    isLoadingFeaturedAsset,
    saveAndPublish,
    unpublish,
    isSavingAndPublishing,
    isUnpublishing,
    update,
    isUpdating,
  } = useFeaturedAssetActions({
    uuid: id,
  });

  const onRemoveSuccess = () => push('/featured-assets');

  const onAddAssetsToQueue = (assets: Asset[]) => {
    setCurrentQueuedAssets((prev) => [
      ...prev,
      ...mapAssetsToQueueTableItems(assets),
    ]);

    setQueuedUuidsInEditMode((prev) => [...prev, ...assets.map((a) => a.uuid)]);
  };

  useEffect(() => {
    if (!id) return;

    reset({ ...featuredAsset, assets: [] }); //Assets with details are fetched separately
    setOriginalQueuedAssets(featuredAsset?.assets || []); //Keep original data in state for comparing purposes
  }, [id, featuredAsset, reset, setValue]);

  useEffect(() => {
    if (!featuredAsset?.assets?.length) {
      return;
    }

    setIsLoadingAssetsDetails(true);

    getAssetsPagination({
      assetUuids: mapQueuedAssetsToUuids(featuredAsset.assets),
      limit: featuredAsset.assets.length,
    })
      .then((assetsDetails) => {
        //Extend assets from featured asset that includes dates with name
        const assetsWithNames = mapAssetsToQueueTableItems(assetsDetails.data);

        const queuedAssets: FeaturedAssetSource[] = featuredAsset.assets.map(
          (asset) => {
            const assetWithName = assetsWithNames.find(
              (f) => f.sourceId === asset.sourceId
            );

            //From BE we receive UTC value, need to transform based on timezone
            return {
              ...assetWithName,
              ...asset,
              dateFrom: convertNumberToUTCWithOffset(
                asset.dateFrom,
                featuredAsset.timezone
              ),
              dateTo: convertNumberToUTCWithOffset(
                asset.dateTo,
                featuredAsset.timezone
              ),
            };
          }
        );

        setCurrentQueuedAssets(queuedAssets);
      })
      .catch((e) => captureError(e))
      .finally(() => setIsLoadingAssetsDetails(false));
  }, [featuredAsset]);

  const onFormSubmit = (form: FeaturedAsset, isPublishAction?: boolean) => {
    if (queuedUuidsInEditMode?.length > 0) {
      notification.error({
        title: 'Save failed',
        description:
          'Some assets in the queue have unsaved changes! Please save all changes in the queue to save this featured asset.',
      });

      return;
    }

    //Convert dates to UTC format based on selected timezone
    const assets = currentQueuedAssets;
    const offset = form.timezone;
    const shouldRecalculateOffset = offset !== featuredAsset?.timezone;

    const assetsAfterOffset = assets?.map((asset) => {
      const dateFromAfterOffset = convertLocalDateToUTCWithOffset({
        date: asset.dateFrom,
        offset,
        shouldRecalculateOffset,
      });

      const dateToAfterOffset = convertLocalDateToUTCWithOffset({
        date: asset.dateTo,
        offset,
        shouldRecalculateOffset,
      });

      return {
        ...asset,
        dateTo: dateToAfterOffset,
        dateFrom: dateFromAfterOffset,
      };
    });

    const formWithAssets: FeaturedAsset = {
      ...form,
      uuid: id,
      assets: assetsAfterOffset || [],
    };

    const formToSave = _omit(formWithAssets, '_id', 'status', 'updatedAt'); //BE is sending all properties but not expecting it when updating
    isPublishAction ? saveAndPublish(formToSave) : update(formToSave);
  };

  if (isLoadingFeaturedAsset) {
    return <Spin size="large" />;
  }

  if (!featuredAsset) {
    return null;
  }

  return (
    <>
      <PageDetailsHeader>{watch('name')}</PageDetailsHeader>
      <div className={styles.root}>
        <FormProvider {...formMethods}>
          <div className={styles.leftWrapper}>
            <PageCard
              title="General details"
              children={
                <FeaturedAssetGeneralDetails
                  originalFeaturedAsset={featuredAsset}
                />
              }
            />

            <PageCard
              title="Default assets"
              children={<FeaturedAssetDefaults />}
            />

            <PageCard
              title={`Queue ${
                featuredAsset.scheduledAssetsCount
                  ? `(${featuredAsset.scheduledAssetsCount})`
                  : ''
              }`}
              children={
                <FeaturedAssetScheduledQueue
                  originalQueuedAssets={originalQueuedAssets}
                  setOriginalQueuedAssets={setOriginalQueuedAssets}
                  queuedUuidsInEditMode={queuedUuidsInEditMode}
                  setQueuedUuidsInEditMode={setQueuedUuidsInEditMode}
                  isLoading={isLoadingAssetsDetails}
                  currentlyDisplayed={featuredAsset.currentlyDisplayed}
                  currentQueuedAssets={currentQueuedAssets}
                  setCurrentQueuedAssets={setCurrentQueuedAssets}
                />
              }
              extra={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setAddAssetsToQueueModalOpen(true)}
                >
                  Add assets
                </Button>
              }
            />

            <div className={styles.buttons}>
              <Button
                type="primary"
                onClick={handleSubmit((values) => onFormSubmit(values, false))}
                loading={isUpdating}
              >
                Save
              </Button>

              {featuredAsset.status !== FeaturedAssetStatus.PUBLISHED && (
                <Button onClick={() => setIsRemoveModalVisible(true)}>
                  Remove
                </Button>
              )}
            </div>
          </div>
        </FormProvider>

        <StatusCard
          status={featuredAsset.status}
          title="Featured asset status"
          titleIcon={<InfoCircleOutlined />}
          unpublishConfirmDialogContent={`Are you sure you want to unpublish ${featuredAsset.name}?`}
          publishConfirmDialogContent={`Are you sure you want to save and publish ${featuredAsset.name}?`}
          onPublish={handleSubmit((values) => onFormSubmit(values, true))}
          onUnpublish={() => unpublish()}
          canPublish={true}
          canUnpublish={true}
          isLoading={isSavingAndPublishing || isUnpublishing}
          isSavingWhenPublishing
        />
      </div>

      {isRemoveModalVisible && (
        <DeleteFeaturedAssetModal
          featuredAsset={featuredAsset}
          onClose={() => setIsRemoveModalVisible(false)}
          isVisible={isRemoveModalVisible}
          onRemoveSuccess={onRemoveSuccess}
        />
      )}

      {addAssetsToQueueModalOpen && (
        <AssetAddToEntityModal
          modalTitle="Add assets to queue"
          selectedAssetsUuids={mapQueuedAssetsToUuids(currentQueuedAssets)}
          regionUuids={watch('regionIds')}
          open={addAssetsToQueueModalOpen}
          onClose={() => setAddAssetsToQueueModalOpen(false)}
          onAdd={onAddAssetsToQueue}
          allowedTypes={DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET}
        />
      )}
    </>
  );
};

export default FeaturedAssetDetails;
