import { useEffect, useState } from 'react';
import { useDebounce } from '@laminar-product/client-commons-core/hooks';
import _difference from 'lodash/difference';
import { DictionaryType } from 'store/dictionaries/types';
import { useDictionary } from './useDictionaries';

export const useDebounceSelectHook = <T,>(
  fetchAction: typeof useDictionary,
  type: DictionaryType,
  selected?: T[],
  parentData?: T[]
): {
  data?: string[];
  isLoading: boolean;
  error?: Error;
  query: string;
  setQuery: (query: string) => void;
  selectedData?: T[];
  setSelectedData: (data: T[]) => void;
  suggestions: T[];
} => {
  const [query, setQuery] = useState<string>('');
  const [selectedData, setSelectedData] = useState<T[]>([]);
  const queryDebounced = useDebounce(query, 300);

  const { data, isLoading, error } = fetchAction({
    query: queryDebounced,
    dictionaryType: type,
  });

  useEffect(() => {
    if (selected) {
      setSelectedData(selected);
    }
  }, [selected, setSelectedData]);

  const suggestions =
    selectedData && parentData ? _difference(parentData, selectedData) : [];

  return {
    data,
    isLoading,
    error,
    query,
    setQuery,
    selectedData,
    setSelectedData,
    suggestions,
  };
};
