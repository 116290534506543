import { AIRecognitionStatus } from 'types/ai';
import { createAsyncAction } from 'typesafe-actions';

interface SourceFileIdPayload {
  sourceFileId: string;
}

interface FetchAiRecognitionStatusSuccess extends SourceFileIdPayload {
  facesRecognitionStatus: AIRecognitionStatus;
  autoRecognitionStatus: AIRecognitionStatus;
}

interface RunAiFacesRecognitionRequest extends SourceFileIdPayload {
  faces: {
    name: string;
    key: string;
  }[];
}

export const fetchAiRecognitionStatusAction = createAsyncAction(
  'FETCH_AI_RECOGNITION_STATUS_REQUEST',
  'FETCH_AI_RECOGNITION_STATUS_SUCCESS',
  'FETCH_AI_RECOGNITION_STATUS_FAILURE'
)<SourceFileIdPayload, FetchAiRecognitionStatusSuccess, Error>();

export const runAiFacesRecognitionAction = createAsyncAction(
  'RUN_AI_FACES_RECOGNITION_REQUEST',
  'RUN_AI_FACES_RECOGNITION_SUCCESS',
  'RUN_AI_FACES_RECOGNITION_FAILURE'
)<RunAiFacesRecognitionRequest, SourceFileIdPayload, Error>();

export const runAutoAiRecognitionAction = createAsyncAction(
  'RUN_AUTO_AI_RECOGNITION_REQUEST',
  'RUN_AUTO_AI_RECOGNITION_SUCCESS',
  'RUN_AUTO_AI_RECOGNITION_FAILURE'
)<SourceFileIdPayload, SourceFileIdPayload, Error>();
