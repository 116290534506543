import moment from 'moment';
import { Page } from 'types/pages';
import { PAGINATION_SIZE } from 'utils/constants';
import { Pagination, pageToSkip } from 'utils/pagination';
import { mapPageViewsToPlatform, mapPlatformToPageViews } from 'utils/platform';
import api from './api';

export interface GetPagesQuerySort {
  sortBy: string; // API declares to accept 'updatedAt' | 'name' but it's hard to enforce that in the app itself
  sortingDir: 'ASCEND' | 'DESCEND';
}

export interface GetPagesQuery {
  name?: string;
  page?: number;
  sorter?: GetPagesQuerySort;
  filters?: any;
}

export const getPages = async (
  page?: number,
  query: GetPagesQuery = {}
): Promise<Pagination<Page>> => {
  const { data } = await api().get(`/designer/page`, {
    params: {
      ...query.filters,
      ...query.sorter,
      name: query.name || undefined,
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
    },
  });
  const transformedData = data.data.map((d: any) => ({
    ...d,
    updatedAt: moment(d.updatedAt),
    platforms: mapPageViewsToPlatform(d.views),
  }));
  return { data: transformedData, count: data.count };
};

export const getPage = async (pageId: string): Promise<Page> => {
  const { data } = await api().get(`/designer/page/${pageId}`);
  return {
    ...data,
    updatedAt: moment(data.updatedAt),
    platforms: mapPageViewsToPlatform(data.views),
  };
};

export const savePage = async ({ platforms, ...page }: Page): Promise<Page> => {
  const reqBody = {
    ...page,
    views: mapPlatformToPageViews(platforms),
  };

  const { data } = !!page.uuid
    ? await api().patch(`/designer/page/${page.uuid}`, reqBody)
    : await api().post(`/designer/page`, reqBody);

  return {
    ...data,
    updatedAt: moment(data.updatedAt),
    platforms: mapPageViewsToPlatform(data.views),
  };
};

export const publishPage = async (id: string) => {
  await api().post<void>(`/designer/page/${id}/publish`);
};

export const unpublishPage = async (id: string) => {
  await api().post<void>(`/designer/page/${id}/unpublish`);
};

export const removePage = async (id: string) => {
  await api().delete<void>(`/designer/page/${id}`);
};
