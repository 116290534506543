import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Breadcrumb, Button, Spin } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { breadCrumbsPath } from 'actions/assets';
import { Asset, BreadCrumbsPath } from 'types';
import _set from 'lodash/set';
import StickyHeader from 'components/StickyHeader';
import { PREVIEW_MODE } from 'utils/constants';
import { Paths } from 'types/paths';
import styles from './index.module.scss';

const PageDetailsHeader = ({
  children,
  loading,
  name,
  asset,
  badge,
  canPreviewDraft,
}: {
  children?: ReactNode;
  name?: string;
  loading?: boolean;
  asset?: Asset;
  badge?: ReactElement;
  canPreviewDraft?: boolean;
}) => {
  const { pathname } = useLocation<{
    path: { key: number; title: string }[];
  }>();
  const mainPage = pathname.split('/')[1];
  const [path, setPath] = useState<BreadCrumbsPath[]>([]);
  const [isBreadCrumbsLoading, setBreadCrumbsLoading] = useState<boolean>();
  const [previewId, setPreviewId] = useState(asset?.uuid);

  useEffect(() => {
    if (asset?.id) {
      setBreadCrumbsLoading(true);

      breadCrumbsPath({
        currentAssetId: asset.id,
      }).then((res) => {
        setPreviewId(res[0].uuid);
        setPath(res);
        setBreadCrumbsLoading(false);
      });
    }
  }, [asset]);

  useEffect(() => {
    if (name) {
      setPath((prevState) => {
        const lastItem = prevState.length - 1;
        return _set([...prevState], `[${lastItem}]`, {
          ...prevState[lastItem],
          name,
        });
      });
    }
  }, [name]);

  const openAssetPreview = () =>
    window.open(
      `${Paths.ASSET_PREVIEW}/${previewId}/preview?${PREVIEW_MODE}=true`,
      '_blank'
    );

  return (
    <React.Fragment>
      <StickyHeader hasBadge={!!badge}>
        <div className={styles.wrapper}>
          {!isBreadCrumbsLoading && !loading && badge}
          {(isBreadCrumbsLoading || loading) && <Spin />}
          {!!asset && !isBreadCrumbsLoading && !loading && (
            <div>
              <Breadcrumb separator=">" className={styles.breadCrumb}>
                {path?.length && (
                  <>
                    <Breadcrumb.Item href={`/${mainPage}`}>
                      <p className={styles.breadCrumbMainItem}>{mainPage}</p>
                    </Breadcrumb.Item>
                    {path.map((route, index) => (
                      <Breadcrumb.Item
                        key={route.uuid + index}
                        href={`/${mainPage}/${route.uuid}`}
                      >
                        <p
                          key={route.name + index}
                          className={styles.breadCrumbItem}
                        >
                          {route.name}
                        </p>
                      </Breadcrumb.Item>
                    ))}
                  </>
                )}
              </Breadcrumb>
              {!isBreadCrumbsLoading && !loading && (
                <span className={styles.name}>{name}</span>
              )}
            </div>
          )}
        </div>
        {children}
        {!canPreviewDraft && previewId && (
          <div className={styles.ActionButtons}>
            <Button
              onClick={openAssetPreview}
              icon={<CaretRightOutlined />}
              type="default"
            >
              Preview draft
            </Button>
          </div>
        )}
      </StickyHeader>
    </React.Fragment>
  );
};

export default PageDetailsHeader;
