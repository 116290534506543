import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  getPlatformPartner,
  getPlatformPartnerPlans,
} from 'actions/platformPartners';
import { Button, Input } from 'antd';
import ButtonsRow from 'components/ButtonsRow';
import FormGroup from 'components/FormGroup';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import StatusCard from 'components/StatusCard';
import { usePermission } from 'hooks/permissionsHook';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PlatformPartner } from 'types/platformPartners';
import { Permission } from 'utils/constants';
import notification from 'utils/notification';
import { RouteIdParams } from 'utils/routeParams';
import DeletePartnerModal from '../DeletePartnerModal';
import AddPartnerPlanModal from '../AddPartnerPlanModal';
import PartnerPlansTable from '../PartnerPlansTable';
import usePartnerActions from '../utils/usePartnerActions';
import styles from './index.module.scss';

const PlatformPartnerDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const {
    reset,
    formState: { isDirty },
    ...formMethods
  } = useForm<PlatformPartner>({ reValidateMode: 'onChange' });
  const [showAddPlanModal, toggleShowAddPlanModal] = useState(false);
  const { canUse } = usePermission();

  const { data: platformPartner, refresh: refreshPartner } = useFetchActionObj(
    useCallback(() => getPlatformPartner(id), [id]),
    useMemo(
      () => ({
        onDone: (data) => {
          reset(data);
        },
        onError: () => {
          notification.error({
            title: "Couldn't load the partner details",
            description: 'Refresh the page or contact support.',
          });
        },
      }),
      [reset]
    )
  );
  const partnerActions = usePartnerActions({ refreshPartner });

  const { data: platformPartnerPlans, refresh: refreshPlatformPartnerPlans } =
    useFetchActionObj(
      useCallback(() => getPlatformPartnerPlans(id), [id]),
      useMemo(
        () => ({
          onError: () => {
            notification.error({
              title: "Couldn't load the partner plans",
              description: 'Refresh the page or contact support.',
            });
          },
        }),
        []
      )
    );

  const onSubmit = formMethods.handleSubmit((formState) =>
    partnerActions.savePartner({ uuid: id, partner: formState })
  );

  const onPublish = async (uuid: string, partner: PlatformPartner) => {
    const isValid = await formMethods.trigger();
    if (isDirty && !isValid) {
      notification.error({
        title: 'Error',
        description: 'Make sure all required fields are filled',
      });
      return;
    }
    partnerActions.publishPartner({
      uuid,
      platformPartner: partner,
      shouldSave: isDirty && isValid,
    });
  };

  if (!platformPartner) return null;

  return (
    <>
      <PageDetailsHeader>
        {platformPartner.administrativeName}
      </PageDetailsHeader>
      <div className={styles.root}>
        <form onSubmit={onSubmit} className={styles.leftColumn}>
          <PageCard
            title="General"
            children={
              <>
                <FormGroup
                  input={
                    <Controller
                      placeholder="Enter the partner name"
                      as={Input}
                      control={formMethods.control}
                      name="administrativeName"
                      rules={{ required: true }}
                    />
                  }
                  label="Name"
                  errorMessage={formMethods.errors?.administrativeName?.message}
                  defaultValue=""
                />
                <FormGroup
                  input={
                    <Controller
                      placeholder="Enter the partner ID"
                      as={Input}
                      control={formMethods.control}
                      name="partnerId"
                      rules={{ required: true }}
                    />
                  }
                  label="Partner ID"
                  errorMessage={formMethods.errors?.partnerId?.message}
                  defaultValue=""
                />
              </>
            }
          />

          <PageCard
            title="Plans"
            headerActionButton={
              <Button
                icon={<PlusOutlined />}
                type="default"
                onClick={() => toggleShowAddPlanModal(true)}
              >
                Add plan
              </Button>
            }
          >
            <PartnerPlansTable
              data={platformPartnerPlans}
              partnerUuid={id}
              onPlanChange={refreshPlatformPartnerPlans}
            />
          </PageCard>

          <ButtonsRow align="left">
            {canUse([Permission.PLATFORM_PARTNER_EDIT]) && (
              <Button
                htmlType="submit"
                type="primary"
                loading={partnerActions.isSaving}
              >
                Save
              </Button>
            )}

            {canUse([Permission.PLATFORM_PARTNER_DELETE]) && (
              <Button
                htmlType="button"
                type="default"
                icon={<DeleteOutlined />}
                onClick={() => partnerActions.toggleDeletePartnerModal(true)}
              >
                Remove partner
              </Button>
            )}
          </ButtonsRow>
        </form>
        <StatusCard
          status={platformPartner.status}
          title="Partner status"
          titleIcon={<InfoCircleOutlined />}
          isSavingWhenPublishing={isDirty}
          canPublish={canUse([
            Permission.PLATFORM_PARTNER_PUBLISH,
            Permission.PLATFORM_PARTNER_EDIT,
          ])}
          canUnpublish={canUse([Permission.PLATFORM_PARTNER_UNPUBLISH])}
          onPublish={() =>
            onPublish(platformPartner.uuid, formMethods.getValues())
          }
          onUnpublish={() =>
            partnerActions.unpublishPartner({
              uuid: platformPartner.uuid,
              partnerName: platformPartner.administrativeName,
            })
          }
          publishConfirmDialogContent={`Are you sure you want to ${
            isDirty ? 'save and ' : ''
          }publish partner?`}
          unpublishConfirmDialogContent="Are you sure you want to unpublish partner?"
        />

        <DeletePartnerModal
          partner={platformPartner}
          isOpen={partnerActions.isDeletePartnerModalVisible}
          onClose={() => partnerActions.toggleDeletePartnerModal(false)}
        />
        <AddPartnerPlanModal
          partnerUuid={platformPartner.uuid}
          isOpen={showAddPlanModal}
          onClose={() => toggleShowAddPlanModal(false)}
          onAdd={refreshPlatformPartnerPlans}
        />
      </div>
    </>
  );
};

export default PlatformPartnerDetails;
