export const aiModerationRootLevels = [
  'Explicit Nudity',
  'Suggestive',
  'Violence',
  'Visually Disturbing',
  'Rude Gestures',
  'Drugs',
  'Tobacco',
  'Alcohol',
  'Gambling',
  'Hate Symbols',
];
