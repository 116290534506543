import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { TaxProduct } from 'pages/TaxManagement/types';
import { useGetRegions } from 'hooks/useRegions';
import TransferTable from 'components/TransferTable';
import { Region } from 'types';
import { Primitive } from '@sentry/types';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getDefaultTaxCodes } from 'actions/taxManagement';
import { AddTaxProductsModalProps, DataSourceType } from '../types';
import { mapProductsToAdd, mapToSourceData } from './utils';

const leftTableColumns = (regions?: Region[]) => [
  {
    dataIndex: 'Name',
    title: 'Name',
    render: (value: string, data: DataSourceType, rowIndex: number) => (
      <span>{data.title}</span>
    ),
  },
  {
    dataIndex: 'region',
    title: 'Region',
    render: (value: string, data: DataSourceType, rowIndex: number) => (
      <span>{data.region}</span>
    ),
    filters: regions?.map((region) => {
      return {
        text: region.name,
        value: region.uuid,
      };
    }),
    onFilter: (value: any, data: DataSourceType) => data.regionUuid === value,
  },
  {
    dataIndex: 'defaultTaxCode',
    title: 'Default Tax Code',
    render: (value: string, data: DataSourceType, rowIndex: number) => (
      <span>{data.productTaxCode}</span>
    ),
  },
];

const rightTableColumns = (regions?: Region[]) => [
  {
    dataIndex: 'Name',
    title: 'Name',
    render: (value: string, data: DataSourceType, rowIndex: number) => (
      <span>{data.title}</span>
    ),
  },
  {
    dataIndex: 'region',
    title: 'Region',
    render: (value: string, data: DataSourceType, rowIndex: number) => (
      <span>{data.region}</span>
    ),
    filters: regions?.map((region) => {
      return {
        text: region.name,
        value: region.uuid,
      };
    }),
    onFilter: (value: Primitive, data: DataSourceType) =>
      data.regionUuid === value,
  },
];

const AddTaxProductsModal = ({
  addedProducts,
  open,
  onClose,
  onAdd,
  products,
  isLoading,
}: AddTaxProductsModalProps) => {
  const [productsDataSource, setProductsDataSource] = useState<
    DataSourceType[]
  >([]);
  const [productsToAdd, setProductsToAdd] = useState<TaxProduct[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { regions } = useGetRegions();
  const [defaultTaxCodes, isLoadingDefaultTaxCodes] =
    useFetchAction(getDefaultTaxCodes);

  useEffect(() => {
    if (!products || !regions || isLoadingDefaultTaxCodes) return;
    setProductsDataSource(
      mapToSourceData({ products, regions, defaultTaxCodes })
    );
  }, [defaultTaxCodes, isLoadingDefaultTaxCodes, products, regions]);

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
    const productsToAdd = mapProductsToAdd(nextTargetKeys, productsDataSource);

    if (!productsToAdd) return;
    const filteredProducts = productsToAdd?.filter(
      (product) => product
    ) as TaxProduct[];
    setProductsToAdd(filteredProducts);
  };

  const filteredTransferDataSource = useMemo(
    () =>
      productsDataSource?.filter(
        (product) =>
          !addedProducts?.some(
            (addedProduct) => addedProduct.resourceId === product.key
          )
      ),
    [addedProducts, productsDataSource]
  );

  const handleOnAdd = () => {
    onAdd(productsToAdd);
    setSelectedKeys([]);
    setTargetKeys([]);
  };

  return (
    <Modal
      width={1000}
      title="Add products"
      okText="Add"
      onOk={handleOnAdd}
      visible={open}
      onCancel={onClose}
      confirmLoading={isLoading}
    >
      <TransferTable
        showSearch
        dataSource={filteredTransferDataSource || []}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        leftColumns={leftTableColumns(regions)}
        rightColumns={rightTableColumns(regions)}
        filterOption={(inputValue, item) =>
          item.title!.indexOf(inputValue) !== -1
        }
      />
    </Modal>
  );
};

export default AddTaxProductsModal;
