import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getFeaturedAssetDetails } from 'actions/featuredAsset';
import React, { useCallback } from 'react';

export interface FetchingFeaturedAssetNameProps {
  uuid: string;
}

/**
 * Fetches and displays featured asset name
 */
const FetchingFeaturedAssetName = ({
  uuid,
}: FetchingFeaturedAssetNameProps) => {
  const { data: featuredAsset } = useFetchActionObj(
    useCallback(() => getFeaturedAssetDetails(uuid), [uuid])
  );

  return <div>{featuredAsset?.name || ''}</div>;
};

export default FetchingFeaturedAssetName;
