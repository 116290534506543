import React, { useCallback, useEffect, useState } from 'react';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import {
  addAssetsToPlan,
  addPriceToPlan,
  editPlan,
  getPlan,
  getSubscriptionOptions,
} from 'actions/plans';
import { Button, message } from 'antd';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import PublicDetails from 'components/PublicDetails';
import AssetListPlan from 'pages/Plans/PlanDetails/components/AssetListPlan';
import AssetAddToEntityModal from 'components/AssetAddToEntityModal';
import Limitations from 'pages/Plans/PlanDetails/components/Limitations';
import Pricing from 'pages/Plans/PlanDetails/components/Pricing';
import PricingModal from 'pages/Plans/PlanDetails/components/PricingModal';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Asset } from 'types';
import { Plan, PlanForm, PlanOptions, PlanPrice } from 'types/plan';
import notification from 'utils/notification';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import { captureErrorAndNotify } from 'utils/captureError';
import _omit from 'lodash/omit';
import Basic from './components/Basic';
import styles from './index.module.scss';
import PlanStatusCard from './PlanStatusCard';
import PublicDetailsForm from './PublicDetailsForm';
import PublicLanguageDetails from './PublicLanguageDetails';

export interface PlanDetailsPageProps {
  options?: PlanOptions;
  id?: number;
  currentPlanRegionUuid?: string;
}

const PlanDetails = () => {
  const [options] = useFetchAction<PlanOptions>(getSubscriptionOptions);
  const {
    getValues,
    watch,
    setValue,
    register,
    unregister,
    handleSubmit,
    reset,
    ...formMethods
  } = useForm<PlanForm>({
    mode: 'onChange',
  });

  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [assignedAssets, setAssignedAssets] = useState<Asset[]>([]);
  const [assetAddToPlanModalOpen, setAssetAddToPlanModalOpen] = useState(false);
  const { canUse } = usePermission();

  const [savePricing, isSavingPricing] = useSendAction<PlanPrice, PlanPrice>(
    async (form) =>
      addPriceToPlan({
        interval: form.interval,
        intervalMultiplier: form.intervalMultiplier,
        currency: form.currency,
        amount: Math.ceil(form.amount * 100),
        planUuid: id,
        gateway: form.gateway,
        externalProductId: form.externalProductId,
        distributionChannels: form.distributionChannels,
      }),
    {
      onDone: (form) => {
        setValue('prices', [
          ...getValues('prices'),
          { ...form, amount: form.amount },
        ]);

        notification.success({
          title: 'Success',
          description: 'Price has been added to the plan!',
        });

        setPricingModalOpen(false);
      },
      onError: captureErrorAndNotify,
    }
  );

  const [sendForm, isProcessing] = useSendAction<PlanForm, Plan>(
    (form) =>
      editPlan({
        plan: form,
        id: id,
      }),
    {
      onDone: () => message.success('Plan has been saved!'),
    }
  );

  const { id } = useParams<{ id: string }>();

  const onSubmit = () => {
    const payload = getValues();

    // Set details for backward compatibility on Clients
    if (payload.metadata) {
      Object.keys(payload.metadata).forEach((lang) => {
        let planDetails: string[] = [];
        const featuredDetails = payload.metadata?.[lang]?.featuredDetails;

        featuredDetails?.forEach(
          (d, index) => d.value && planDetails.push(d.value)
        );

        payload.metadata![lang].details = planDetails;
      });
    }
    sendForm(payload);
  };

  useEffect(() => {
    if (id) {
      getPlan({ id }).then((res) => {
        setAssignedAssets(res.assets);

        reset({
          ...getValues(),
          ...res,
          availableRegionsUuids: res.availableRegions?.map((x) => x.uuid) || [],
        });
      });
    }
  }, [getValues, id, reset]);

  useEffect(() => {
    register('prices');
    register('metadata');
    return () => {
      unregister('prices');
      unregister('metadata');
    };
  }, [register, unregister]);

  const methods = {
    getValues,
    watch,
    setValue,
    register,
    unregister,
    handleSubmit,
    reset,
    ...formMethods,
  };
  const planRegion = watch('region');
  const metadata = watch('metadata');

  const addAssets = (assets: Asset[]) => {
    const uuidsToAdd = assets.map((asset) => asset.uuid);
    addAssetsToPlan({ assetUuids: uuidsToAdd, planUuid: id })
      .then(() => {
        setAssignedAssets((assigned) => [...assigned, ...assets]);
        notification.success({
          title: 'Success',
          description: 'Assets assigned to plan have been updated!',
        });
      })
      .catch(() => {
        notification.error({
          title: 'Error',
          description:
            'Something went wrong when assigning assets to plan. Please try again.',
        });
      });
  };

  // Populate old plan details with featuredDetails for backward compatibility
  const populateMetaData = useCallback(() => {
    if (!metadata) return;
    Object.keys(metadata).forEach((lang) => {
      if (!metadata[lang].featuredDetails) {
        metadata[lang].details?.forEach((detail) => {
          metadata[lang].featuredDetails = [
            ...(metadata[lang].featuredDetails || []),
            {
              moreInfo: '',
              value: detail,
            },
          ];
        });
      }
    });
    return metadata;
  }, [metadata]);

  const onRemoveLanguage = (lang: string) => {
    const translations = getValues('metadata');
    setValue('metadata', _omit(translations, lang));
  };

  useEffect(() => {
    const populatedMetaData = populateMetaData();

    setValue('metadata', populatedMetaData);
  }, [metadata, populateMetaData, setValue]);

  return (
    <>
      <PageDetailsHeader>{watch('administrativeName')} </PageDetailsHeader>
      <div className={styles.root}>
        <FormProvider {...methods}>
          <div className={styles.leftWrapper}>
            <PageCard
              title="General details"
              children={
                <Basic
                  options={options}
                  currentPlanRegionUuid={planRegion?.uuid}
                />
              }
            />
            <PublicDetails
              onRemoveLanguage={onRemoveLanguage}
              onSaveLanguage={(lang, { selectedLanguage, ...details }) => {
                const metadata = {
                  ...watch('metadata'),
                  [selectedLanguage!]: details,
                };
                if (selectedLanguage !== lang) {
                  delete metadata[lang];
                }
                setValue('metadata', metadata);
              }}
              items={watch('metadata')}
              renderLanguageDetails={(meta) => (
                <PublicLanguageDetails meta={meta} />
              )}
              renderLanguageForm={(control, errors, langCode) => (
                <PublicDetailsForm
                  control={control}
                  selectedLanguage={langCode}
                  errors={errors}
                />
              )}
            />
            <PageCard
              title="Devices & Quality"
              children={<Limitations options={options} />}
            />
            <PageCard
              title="Pricing"
              headerActionButton={
                <Button
                  type="primary"
                  onClick={() => setPricingModalOpen(true)}
                >
                  Add new pricing
                </Button>
              }
              children={<Pricing planUuid={id} />}
            />
            <PageCard
              title={`Assets in plan (${assignedAssets.length})`}
              headerActionButton={
                <Button
                  type="primary"
                  onClick={() => setAssetAddToPlanModalOpen(true)}
                >
                  Add
                </Button>
              }
              children={
                <AssetListPlan
                  assignedAssets={assignedAssets}
                  planUuid={id}
                  onAssignedAssets={setAssignedAssets}
                />
              }
            />
            {canUse([Permission.EDIT_PLAN]) && (
              <Button
                disabled={isProcessing}
                type="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            )}
          </div>
          <PlanStatusCard status={watch('status')} id={id} />
        </FormProvider>
      </div>

      <PricingModal
        options={options}
        open={pricingModalOpen}
        onClose={() => setPricingModalOpen(false)}
        addPrice={savePricing}
        isLoading={isSavingPricing}
        platforms={options?.platforms}
      />

      {/* added modalOpen to clear whole transfer list, there is no other possibility to clear search inputs on Cancel or Assign */}
      {planRegion?.uuid && assetAddToPlanModalOpen && (
        <AssetAddToEntityModal
          modalTitle="Add assets to plan"
          selectedAssetsUuids={assignedAssets?.map((a) => a.uuid)}
          regionUuids={[planRegion.uuid]}
          open={assetAddToPlanModalOpen}
          onClose={() => setAssetAddToPlanModalOpen(false)}
          onAdd={addAssets}
        />
      )}
    </>
  );
};

export default PlanDetails;
