import { Button } from 'antd';
import FormGroup from 'components/FormGroup';
import LanguageChooser from 'components/LanguageChooser';
import React, { ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';
import ButtonsRow from 'components/ButtonsRow';
import { BasePublicDetails } from '.';

interface PublicDetailsFormProps<T> {
  onCancel: () => void;
  onFormSubmit: (values: UnpackNestedValue<T> & BasePublicDetails) => void;
  renderLanguageForm: (
    control: Control<any>,
    errors: FieldErrors<any>,
    langCode: string
  ) => ReactNode;
  langDetails?: T & BasePublicDetails;
  selectedLanguages?: string[];
}

const PublicDetailsForm = <T,>({
  onCancel,
  onFormSubmit,
  langDetails,
  selectedLanguages,
  renderLanguageForm,
}: PublicDetailsFormProps<T>) => {
  const { control, handleSubmit, errors, setValue, watch } = useForm<any>({
    defaultValues: langDetails as BasePublicDetails,
  });

  const onSubmit: SubmitHandler<T & BasePublicDetails> = (
    data: UnpackNestedValue<T & BasePublicDetails>
  ) => {
    onFormSubmit(data);
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup
        label="Language"
        errorMessage={errors.selectedLanguage?.message}
        input={
          <Controller
            name="selectedLanguage"
            control={control}
            rules={{ required: 'Required' }}
            codesToExclude={selectedLanguages}
            onChoose={(langCode: string) =>
              setValue('selectedLanguage', langCode)
            }
            chosenLanguageCode={watch('selectedLanguage') as string}
            as={LanguageChooser}
          />
        }
      />
      {renderLanguageForm(control, errors, watch('selectedLanguage'))}
      <ButtonsRow>
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Save
        </Button>
      </ButtonsRow>
    </form>
  );
};

export default PublicDetailsForm;
