import React, { useState } from 'react';
import { Checkbox, Form, Modal, Transfer } from 'antd';
import countries from 'locales/en/countries.json';
import {
  TaxConfigurationForm,
  TaxConfigurationFormValue,
} from 'pages/TaxManagement/types';
import { useFormContext } from 'react-hook-form';
import { USCountryCode } from 'pages/TaxManagement/constants';
import { AddTaxCountriesModalProps, Country } from '../types';

import { getCountryData } from '../helpers';
import styles from './index.module.scss';

const mapToSourceData = (countries: Country[]) =>
  countries.map(({ code, name }) => {
    return {
      key: code,
      title: name || '',
    };
  });

const AddCountriesModal = ({
  addedCountries,
  open,
  onClose,
  onAdd,
}: AddTaxCountriesModalProps) => {
  const [countriesToAdd, setCountriesToAdd] = useState<Country[]>([]);
  const [form] = Form.useForm<TaxConfigurationFormValue>();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { setValue, getValues } = useFormContext<TaxConfigurationForm>();

  const filteredCountries = countries.filter((country) => {
    return !addedCountries.some((addedCountry) => {
      return country.code === addedCountry.code;
    });
  });
  const countriesSourceData = mapToSourceData(filteredCountries);

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
    setCountriesToAdd(mapCountriesToAdd(nextTargetKeys));
  };

  const mapCountriesToAdd = (countries: string[]): Country[] =>
    countries.map((code) => {
      const countryData = getCountryData(code);
      return {
        ...(countryData ? { ...countryData } : { code }),
      };
    });

  const handleOnAdd = () => {
    const {
      storageCountries,
      estimationCountries,
      billingAddressCountries,
      addressValidationCountries,
    } = getValues();

    if (form.getFieldValue(TaxConfigurationFormValue.ESTIMATION_COUNTRIES)) {
      setValue(TaxConfigurationFormValue.ESTIMATION_COUNTRIES, [
        ...(estimationCountries || []),
        ...targetKeys,
      ]);
    }

    if (form.getFieldValue(TaxConfigurationFormValue.STORAGE_COUNTRIES)) {
      setValue(TaxConfigurationFormValue.STORAGE_COUNTRIES, [
        ...(storageCountries || []),
        ...targetKeys,
      ]);
    }

    if (
      form.getFieldValue(TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES)
    ) {
      setValue(TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES, [
        ...(billingAddressCountries || []),
        ...targetKeys,
      ]);
    }

    if (
      form.getFieldValue(TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES)
    ) {
      setValue(TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES, [
        ...(addressValidationCountries || []),
        ...targetKeys.filter((key) => key === USCountryCode),
      ]);
    }

    onAdd(countriesToAdd);
    setCountriesToAdd([]);
    setTargetKeys([]);
  };

  return (
    <Modal
      width={800}
      title={'Add countries'}
      okText="Add"
      onOk={handleOnAdd}
      visible={open}
      onCancel={onClose}
    >
      <Transfer
        showSearch
        listStyle={{ width: '100%', height: 500 }}
        dataSource={countriesSourceData}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={(item) => item.title}
      />
      <div className={styles.checkboxWrapper}>
        <Form form={form} className={styles.form}>
          <Form.Item
            name={TaxConfigurationFormValue.ESTIMATION_COUNTRIES}
            valuePropName="checked"
            className={styles.formItem}
          >
            <Checkbox>Estimate</Checkbox>
          </Form.Item>
          <Form.Item
            name={TaxConfigurationFormValue.STORAGE_COUNTRIES}
            valuePropName="checked"
            className={styles.formItem}
          >
            <Checkbox>Report on</Checkbox>
          </Form.Item>
          <Form.Item
            name={TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES}
            valuePropName="checked"
            className={styles.formItem}
          >
            <Checkbox>Capture Billing Address</Checkbox>
          </Form.Item>
          <Form.Item
            name={TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES}
            valuePropName="checked"
            className={styles.formItem}
          >
            <Checkbox>Address Validation</Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCountriesModal;
