import React, { ReactElement } from 'react';
import Modal from 'antd/lib/modal/Modal';
import styles from './index.module.scss';

interface VideoModalProps {
  sidebarElement: ReactElement | ReactElement[];
  visible?: boolean;
  onCancel: () => void;
  videoElement: ReactElement | ReactElement[];
  height?: number;
}

const VideoModal = ({
  sidebarElement,
  visible,
  onCancel,
  videoElement,
  height,
}: VideoModalProps) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      keyboard={false}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      width="85vw"
      className={styles.modal}
    >
      <section className={styles.root}>
        <div
          className={styles.detailsCol}
          style={{
            height,
          }}
        >
          {sidebarElement}
        </div>
        {videoElement}
      </section>
    </Modal>
  );
};

export default VideoModal;
