import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getCollectionDetails } from 'actions/collections';
import React, { useCallback } from 'react';

export interface FetchingCollectionNameProps {
  uuid: string;
}

/**
 * Fetches and displays collection name
 */
const FetchingCollectionName = ({ uuid }: FetchingCollectionNameProps) => {
  const [collection] = useFetchAction(
    useCallback(() => getCollectionDetails(uuid), [uuid])
  );

  return <div>{collection?.name}</div>;
};

export default FetchingCollectionName;
