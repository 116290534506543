import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getCensorshipsRules } from 'actions/censorships';
import { useCallback, useEffect } from 'react';
import { Control, Controller } from 'react-hook-form';
import FormGroup from 'components/FormGroup';
import { AgeRatingForm, CensorshipRule } from 'types/censorships';
import { Select } from 'antd';

interface CensorshipRuleSelectorProps {
  control: Control<AgeRatingForm>;
  selectedRegion?: string;
  censorshipRuleErrorMessage?: string;
  onSelectedRegionChange: (key: keyof AgeRatingForm, value: any) => void;
}

export const CensorshipRuleSelector = ({
  control,
  censorshipRuleErrorMessage,
  selectedRegion,
  onSelectedRegionChange,
}: CensorshipRuleSelectorProps) => {
  const [data, isLoading] = useFetchAction<CensorshipRule[]>(
    useCallback(() => {
      if (selectedRegion)
        return getCensorshipsRules({ regionUuid: selectedRegion });
      return Promise.reject();
    }, [selectedRegion])
  );

  useEffect(() => {
    onSelectedRegionChange('censorshipRule', undefined);
  }, [selectedRegion, onSelectedRegionChange]);

  return (
    <FormGroup
      input={
        <Controller
          as={Select}
          control={control}
          style={{ width: '100%' }}
          name="censorshipRule"
          rules={{ required: 'Required' }}
          options={data?.map((censorshipRule) => ({
            label: `${censorshipRule.minAge}+ (${censorshipRule.name})`,
            value: censorshipRule.id,
          }))}
          loading={isLoading}
        />
      }
      label="Age Rating"
      errorMessage={censorshipRuleErrorMessage}
    />
  );
};
