import { useEffect, useMemo, useState } from 'react';
import { Menu as MenuAntd } from 'antd';
import { Region } from 'types';
import { useGetRegions } from 'hooks/useRegions';
import Search from 'components/Search';
import cn from 'classnames';
import styles from './index.module.scss';

const RegionsMenu = ({
  onRegionSelect,
  defaultKey,
  searchClassName,
}: {
  onRegionSelect: (region: Region) => void;
  defaultKey: any;
  searchClassName?: string;
}) => {
  const { regions } = useGetRegions();
  const [regionSearchQuery, setRegionSearchQuery] = useState('');

  useEffect(() => {
    if (regions && regions.length > 0) {
      onRegionSelect(regions[0]);
    }
  }, [onRegionSelect, regions]);

  const filteredRegions = useMemo(
    () =>
      regions?.filter((r) =>
        r.name.toLowerCase().includes(regionSearchQuery.toLowerCase())
      ),
    [regionSearchQuery, regions]
  );

  return (
    <MenuAntd
      mode="inline"
      className={styles.sidebar}
      selectedKeys={[defaultKey]}
    >
      <Search
        placeholder="Search region"
        className={cn(styles.search, searchClassName)}
        onChange={(event) => setRegionSearchQuery(event.target.value)}
      />

      {filteredRegions?.map((r: Region) => {
        return (
          <MenuAntd.Item key={r.uuid} onClick={() => onRegionSelect(r)}>
            {r.name}
          </MenuAntd.Item>
        );
      })}
    </MenuAntd>
  );
};

export default RegionsMenu;
