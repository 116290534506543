import React, { ReactNode } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import cn from 'classnames';
import styles from './index.module.scss';

interface SortableHandleType {
  disabled?: boolean;
}

export const DragHandle = SortableHandle<SortableHandleType>(
  ({ disabled }: { disabled?: boolean }) => (
    <MenuOutlined
      className={cn(styles.dragHandle, { [styles.disabled]: disabled })}
    />
  )
);

export const SortableItem = SortableElement((props: any) => <tr {...props} />);

export const SortableContainerTable = SortableContainer((props: any) => (
  <tbody {...props} />
));

export const createDraggableBodyRow =
  (getIndex: (props: any) => any, removeStyles?: boolean) => (props: any) => {
    const restProps = removeStyles
      ? { ...props, className: undefined, style: undefined }
      : props;

    return <SortableItem index={getIndex(props)} {...restProps} />;
  };

export const createDraggableContainer =
  <T extends string | number>(
    onDropRender: (sort: { oldIndex: T; newIndex: T }) => void
  ) =>
  (props: any) =>
    (
      <SortableContainerTable
        useDragHandle
        helperClass={styles.rowDragging}
        onSortEnd={onDropRender}
        {...props}
      />
    );

/**
 * To be used inside Column to hide elements when item is being
 * dragged. This is helpful for action buttons.
 */
export const HideOnDrag = ({ children }: { children: ReactNode }) => (
  <div className={styles.dragHide}>{children}</div>
);
