import React, { useEffect, useState } from 'react';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import { getLiveStreamProfiles } from 'actions/live';
import { useAssetDetailsContext } from 'contexts/assetContext';
import StreamInfrastructureCreate from './StreamInfrastructureCreate';
import StreamInfrastructureDetails from './StreamInfrastructureDetails';

const StreamInfrastructure = () => {
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const {
    liveDetails,
    setLiveDetails,
    liveDetailsLoading,
    streamProfiles,
    setStreamProfiles,
    asset,
  } = useAssetDetailsContext();

  useEffect(() => {
    if (isProfilesLoading || fetched) {
      return;
    }

    setIsProfilesLoading(true);
    getLiveStreamProfiles()
      .then(setStreamProfiles)
      .catch(console.error)
      .finally(() => {
        setIsProfilesLoading(false);
        setFetched(true);
      });
  }, [
    liveDetails,
    isProfilesLoading,
    streamProfiles.length,
    setStreamProfiles,
    fetched,
  ]);

  const onRefreshStreamingHandler = setLiveDetails;

  return (
    <PageContainer
      isLoading={(liveDetailsLoading && !liveDetails) || isProfilesLoading}
    >
      <PageHeader title="Stream infrastructure" />

      {!liveDetails ? (
        <StreamInfrastructureCreate
          assetId={asset.id}
          profiles={streamProfiles}
          onCreated={setLiveDetails}
          assetType={asset.type}
        />
      ) : (
        <StreamInfrastructureDetails
          onStartStreaming={onRefreshStreamingHandler}
          onShutdownStreaming={onRefreshStreamingHandler}
          details={liveDetails}
          assetId={asset.id}
          profiles={streamProfiles}
        />
      )}
    </PageContainer>
  );
};

export default StreamInfrastructure;
