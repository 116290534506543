import React, { useEffect } from 'react';
import { Input, Modal } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FormContainer from 'components/FormContainer';
import Button from 'components/Button';
import ButtonsRow from 'components/ButtonsRow';
import { ListingPageTranslation } from 'types/translations';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import LanguageChooser from 'components/LanguageChooser';
import _uniqueId from 'lodash/uniqueId';
import cn from 'classnames';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { DictionaryType } from 'store/dictionaries/types';
import { sendDictionaryAction } from 'store/dictionaries/actions';
import { useSendDictionary } from 'hooks/useDictionaries';
import { ApiActionType } from 'types';
import { RequestStatuses } from 'store/app/types';
import { usePlatformLanguages } from 'hooks/useLanguages';
import notification from 'utils/notification';
import { DEFAULT_TRANSLATION_ENGLISH_CODE } from 'pages/Menus/MenuCustomPage/constants';
import styles from './index.module.scss';

export interface ListingPageModalProps {
  open: boolean;
  close: () => void;
  dictionaryType: DictionaryType;
  label: string;
  defaultValues?: ListingPageTranslation;
}

const defaultEnglish = { value: '', langCode: 'en', id: _uniqueId() };

const ListingPageModal = ({
  close,
  open,
  label,
  dictionaryType,
  defaultValues,
}: ListingPageModalProps) => {
  const { handleSubmit, control, errors, setValue, watch } = useForm<{
    name: string;
    translations: ListingPageTranslation['translations'];
  }>({
    defaultValues: {
      translations: defaultValues?.translations.length
        ? defaultValues.translations
        : [defaultEnglish],
      name: defaultValues?.name,
    },
  });
  const { platformLanguages } = usePlatformLanguages();

  const dispatch = useDispatch();

  const { fields, remove, append } = useFieldArray<
    {
      langCode: string;
      value: string;
      id?: string;
    },
    'key'
  >({ control, name: 'translations', keyName: 'key' });

  const { isLoading, requestStatus } = useSendDictionary({ dictionaryType });

  useEffect(() => {
    if (requestStatus === RequestStatuses.RESOLVED) {
      setValue('translations', [defaultEnglish]);

      notification.success({
        title: 'Success',
        description:
          'Upload success. Your data will be displayed for up to 5 minutes',
      });
      close();
    }
  }, [close, requestStatus, setValue]);

  const sendFormHandler = (form: {
    name: string;
    translations: ListingPageTranslation['translations'];
  }) => {
    const englishTranslation = form.translations.find(
      (t) => !!t.value && t.langCode === DEFAULT_TRANSLATION_ENGLISH_CODE
    );

    if (!englishTranslation) {
      return notification.error({
        title: 'Error',
        description: 'Add public details at least in English to save',
      });
    }

    const data = {
      ...form,
      dictionaryType,
    };

    dispatch(
      sendDictionaryAction.request({
        ...data,
        actionType: defaultValues ? ApiActionType.UPDATE : ApiActionType.CREATE,
      })
    );
  };

  return (
    <Modal
      visible={open}
      title={!defaultValues ? `Add ${label}` : `Edit ${label}`}
      destroyOnClose
      footer={null}
      onCancel={close}
    >
      <FormContainer>
        <form onSubmit={handleSubmit(sendFormHandler)}>
          <fieldset>
            <FormGroup
              input={
                <Controller
                  name="name"
                  as={Input}
                  control={control}
                  disabled={!!defaultValues}
                  rules={{ required: 'Required' }}
                  onKeyDown={(event: React.KeyboardEvent) =>
                    event.nativeEvent.code === 'Space'
                      ? event.preventDefault()
                      : undefined
                  }
                />
              }
              label="Name"
              errorMessage={errors.name?.message}
            />
            <div className={styles.titleWrapper}>
              <span className={styles.title}>Public details</span>
              <Button
                disabled={fields.length === platformLanguages.length}
                type="primary"
                ghost
                icon={<PlusOutlined />}
                onClick={() =>
                  append({
                    value: '',
                    langCode: '',
                    id: _uniqueId(),
                  })
                }
              >
                Add language
              </Button>
            </div>
            {fields.map((translation, index) => (
              <div key={translation.id} className={styles.translationWrapper}>
                <div className={styles.customFormGroup}>
                  <Controller
                    defaultValue={translation.langCode}
                    control={control}
                    name={`translations[${index}].langCode`}
                    rules={{ required: 'Required' }}
                    as={
                      <LanguageChooser
                        enableMostUsed={false}
                        chosenLanguageCode={_get(
                          watch('translations'),
                          `[${index}].langCode`
                        )}
                        codesToExclude={
                          watch('translations').map(
                            (t) => t.langCode
                          ) as string[]
                        }
                        onChoose={(lang) =>
                          setValue(`translations[${index}].langCode`, lang)
                        }
                      />
                    }
                  />
                  {(errors?.translations || [])[index]?.langCode && (
                    <span className={styles.error}>Required</span>
                  )}
                </div>

                <div
                  className={cn(styles.customFormGroup, {
                    [styles.emptyIconLabel]: index === 0,
                  })}
                >
                  <Controller
                    defaultValue={translation.value}
                    name={`translations[${index}].value`}
                    as={Input}
                    control={control}
                    rules={{ required: 'Required' }}
                  />
                  {(errors?.translations || [])[index]?.value && (
                    <span className={styles.error}>Required</span>
                  )}
                </div>

                {index !== 0 && (
                  <DeleteOutlined
                    onClick={() => remove(index)}
                    className={styles.deleteIcon}
                  />
                )}
              </div>
            ))}
            <ButtonsRow>
              <Button
                disabled={isLoading}
                type="default"
                onClick={() => {
                  close();
                  setValue('translations', [defaultEnglish]);
                }}
              >
                Cancel
              </Button>
              <Button htmlType="submit" loading={isLoading} type="primary">
                Save
              </Button>
            </ButtonsRow>
          </fieldset>
        </form>
      </FormContainer>
    </Modal>
  );
};

export default ListingPageModal;
