import { Divider, Select, SelectProps, Space, Spin } from 'antd';
import { useGetRegions } from 'hooks/useRegions';
import React from 'react';
import { Region } from 'types';
import { MultipleRegionSelectValue, RegionOptionType } from 'types/licenses';
import filterOptionsUsingLabels from 'utils/filterOptionsUsingLabels';
import styles from './index.module.scss';

interface MultipleRegionSelectorProps extends SelectProps {
  selectedRegions?: Region[];
  onRegionsSelect: (regions?: Region[]) => void;
  regionsIdsToFilterOut?: number[];
}

const MultipleRegionSelector = ({
  allowClear = true,
  placeholder,
  selectedRegions,
  regionsIdsToFilterOut,
  onRegionsSelect,
}: MultipleRegionSelectorProps) => {
  const { regions, isLoadingRegions } = useGetRegions();
  const selectedRegionsIds = (selectedRegions || []).map((r) => r.id);

  const filteredRegions = (regions || []).filter(
    (r) => !regionsIdsToFilterOut?.includes(r.id)
  );

  const regionsOptions: RegionOptionType[] = filteredRegions.map((r) => ({
    value: r.id,
    label: r.name,
  }));

  const onSelect = (selectedValue: MultipleRegionSelectValue) => {
    if (selectedValue === 'selectAll') {
      onRegionsSelect(filteredRegions);
      return;
    }

    if (selectedValue === 'unselectAll') {
      onRegionsSelect([]);
      return;
    }

    //Admin can select one or multiple options
    const regions = filteredRegions?.filter((region) => {
      if (Array.isArray(selectedValue)) {
        return selectedValue.some((v) => v.value === region.id);
      }

      return region.id === selectedValue.value;
    });

    onRegionsSelect(regions);
  };

  return (
    <Select
      className={styles.fullWidth}
      allowClear={allowClear}
      placeholder={placeholder}
      value={selectedRegionsIds}
      notFoundContent={isLoadingRegions ? <Spin size="small" /> : null}
      onChange={(_, option) => onSelect(option)}
      mode="multiple"
      labelInValue
      filterOption={filterOptionsUsingLabels}
      options={regionsOptions}
      dropdownRender={(menu) => (
        <>
          <Space className={styles.selectWrappers}>
            <div
              className={styles.selectButton}
              onClick={() => onSelect('selectAll')}
            >
              <span className={styles.selectButtonText}>Select all</span>
            </div>
            <div
              className={styles.selectButton}
              onClick={() => onSelect('unselectAll')}
            >
              <span className={styles.selectButtonText}>Unselect all</span>
            </div>
          </Space>
          <Divider className={styles.divider} />

          {menu}
        </>
      )}
    ></Select>
  );
};

export default MultipleRegionSelector;
