import { Language } from '@laminar-product/client-commons-core/core';
import { Action, createReducer } from 'typesafe-actions';
import {
  fetchAllLanguages,
  fetchPlatformLanguages,
  updatePlatformLanguages,
} from './actions';

export interface PlatformLanguagesState {
  readonly allLanguages?: Language[];
  readonly platformLanguages: {
    availableLanguages?: Language[];
    defaultLanguageIsoCode?: string;
  };
  readonly error?: { [key: string]: Error };
}

export const initialState = {
  allLanguages: [],
  platformLanguages: {
    availableLanguages: undefined,
    defaultLanguageIsoCode: undefined,
  },
};

export const languagesReducer = createReducer<PlatformLanguagesState, Action>(
  initialState
)
  .handleAction(
    fetchAllLanguages.success,
    (state: PlatformLanguagesState, { payload }) => ({
      ...state,
      allLanguages: payload,
    })
  )
  .handleAction(
    fetchPlatformLanguages.success,
    (state: PlatformLanguagesState, { payload }) => ({
      ...state,
      platformLanguages: {
        availableLanguages: payload.availableLanguages,
        defaultLanguageIsoCode: payload.defaultLanguageIsoCode,
      },
    })
  )
  .handleAction(
    updatePlatformLanguages.success,
    (state: PlatformLanguagesState, { payload }) => ({
      ...state,
      platformLanguages: {
        ...state.platformLanguages,
        availableLanguages: payload,
      },
    })
  )
  .handleAction(
    [
      fetchAllLanguages.failure,
      fetchPlatformLanguages.failure,
      updatePlatformLanguages.failure,
    ],
    (state: PlatformLanguagesState, { payload, type }) => ({
      ...state,
      error: { ...state.error, [type]: payload },
    })
  );
