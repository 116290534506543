import {
  createPromotionCoupon,
  generateMultiplePromotionCoupons,
} from 'actions/promotions';
import { Radio } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import FormContainer from 'components/FormContainer';
import FormGroup from 'components/FormGroup';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Coupon, CouponType } from 'types/promotions';
import { MAX_TOTAL_COUPONS_COUNT, couponTypes } from 'utils/constants';
import notification from 'utils/notification';
import { errorNotify } from 'utils/errorNotify';
import { Pagination } from 'types';
import { captureError } from 'utils/captureError';
import styles from './index.module.scss';
import MultiCouponsForm from './MuliCouponsForm';
import SingleCouponsForm from './SingleCouponsForm';

interface CouponsModalProps {
  promotionUuid?: string;
  coupons?: Pagination<Coupon>;
  refresh: () => void;
  isModalVisible: boolean;
  setIsModalVisible: (state: boolean) => void;
}

const CouponsModal = ({
  coupons,
  refresh,
  isModalVisible,
  setIsModalVisible,
  promotionUuid,
}: CouponsModalProps) => {
  const formMethods = useForm({ mode: 'onChange' });
  const { control, handleSubmit, reset } = {
    ...formMethods,
  };
  const selectedCouponType = formMethods.watch('type');
  const [isSavingCoupons, setIsSavingCoupons] = useState<boolean>(false);

  const resetForm = () => {
    reset({
      type: CouponType.SINGLE,
      code: null,
      quantity: null,
    });
  };

  const submit = (coupon: Coupon) => {
    if (coupons) {
      const totalCountAfterGenerate = coupons.count + coupon.quantity;

      if (totalCountAfterGenerate > MAX_TOTAL_COUPONS_COUNT) {
        notification.error({
          title: 'Failed',
          description: (
            <div>
              You cannot generate more than 100 000 coupons per single
              promotion. The current count of generated coupons is
              <span className={styles.bold}> {coupons.count}.</span>
            </div>
          ),
        });
        return;
      }
    }

    addCoupon(coupon);
  };

  const checkCouponExist = (couponToCheck: Coupon) => {
    if (coupons?.data) {
      const coupon = coupons.data.find(
        (coupon) =>
          coupon.uuid !== couponToCheck.uuid &&
          coupon.code === couponToCheck.code
      );
      if (coupon) {
        notification.error({
          title: 'Error',
          description:
            'A coupon with the same code already exists for this promotion!',
        });
        return true;
      }
    }
    return false;
  };

  const addCoupon = (couponToAdd: Coupon) => {
    if (!promotionUuid) return;

    if (!checkCouponExist(couponToAdd)) {
      if (couponToAdd.type === CouponType.MULTI) {
        setIsSavingCoupons(true);

        createPromotionCoupon({ form: couponToAdd, promotionUuid })
          .then(() => {
            handleCouponsSuccess();
          })
          .catch((e) => {
            handleCouponsError(e);
          });
      }
    }

    if (couponToAdd.type === CouponType.SINGLE) {
      setIsSavingCoupons(true);

      generateMultiplePromotionCoupons({
        quantity: couponToAdd.quantity,
        promotionUuid,
      })
        .then(() => {
          handleCouponsSuccess();
        })
        .catch((e) => {
          handleCouponsError(e);
        });
    }
  };

  const handleCouponsSuccess = () => {
    refresh();
    setIsSavingCoupons(false);
    onClose();
    notification.success({
      title: 'Success',
      description: 'A coupon has been saved correctly!',
    });
  };

  const handleCouponsError = (e: Error) => {
    setIsSavingCoupons(false);
    captureError(e);
    errorNotify(e);
  };

  const onClose = () => {
    resetForm();
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Add coupon"
      visible={isModalVisible}
      onCancel={onClose}
      okText="Generate"
      cancelText="Cancel"
      onOk={handleSubmit(submit)}
      destroyOnClose
      okButtonProps={{ loading: isSavingCoupons, disabled: isSavingCoupons }}
    >
      <FormContainer>
        <FormProvider {...formMethods}>
          <FormGroup
            label="Coupon type"
            input={
              <Controller
                render={(field) => (
                  <Radio.Group
                    className={styles.flex}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    options={couponTypes}
                  />
                )}
                className={styles.flex}
                name="type"
                defaultValue={CouponType.SINGLE}
                control={control}
              />
            }
          />

          {selectedCouponType === CouponType.MULTI ? (
            <MultiCouponsForm />
          ) : (
            <SingleCouponsForm />
          )}
        </FormProvider>
      </FormContainer>
    </Modal>
  );
};

export default CouponsModal;
