import { Switch } from 'antd';
import cn from 'classnames';
import FormContainer from 'components/FormContainer';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button from 'components/Button';
import { PlusOutlined } from '@ant-design/icons';
import {
  useFetchActionObj,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import {
  deleteTaxCode,
  getTaxCodes,
  getTaxConfiguration,
  saveTaxCode,
  saveTaxConfiguration,
} from 'actions/taxManagement';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import PaymentGateways from './components/PaymentGateways';
import styles from './index.module.scss';
import TaxCodes from './components/TaxProducts';
import {
  TaxConfigurationForm,
  TaxConfigurationFormValue,
  TaxProduct,
} from './types';

import TaxCountries from './components/TaxCountries';
import { getDeletedTaxCodes, getEditedTaxCodes } from './utils';

const TaxManagement = () => {
  const [addCountriesModalOpen, setAddCountriesModalOpen] = useState(false);
  const [addTaxProductsModalOpen, setAddTaxProductsModalOpen] = useState(false);

  const formMethods = useForm<TaxConfigurationForm>({
    mode: 'onSubmit',
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    watch,
    register,
    unregister,
    setValue,
  } = formMethods;

  const canEdit = watch(TaxConfigurationFormValue.ENABLED);

  const {
    data: configurationData,
    isLoading: isLoadingConfiguration,
    refresh: refreshConfiguration,
  } = useFetchActionObj<TaxConfigurationForm>(getTaxConfiguration);

  const {
    data: taxCodes,
    isLoading: isLoadingTaxCodes,
    refresh: refreshTaxCodes,
  } = useFetchActionObj<TaxProduct[]>(getTaxCodes);

  useEffect(() => {
    register(TaxConfigurationFormValue.TAX_CODES);

    return () => {
      unregister(TaxConfigurationFormValue.TAX_CODES);
    };
  }, [register, unregister]);

  useEffect(() => {
    if (configurationData) {
      reset({
        ...configurationData,
      });
    }
  }, [configurationData, reset]);

  useEffect(() => {
    if (taxCodes) {
      setValue(TaxConfigurationFormValue.TAX_CODES, taxCodes);
    }
  }, [setValue, taxCodes]);

  const { sendAction: saveConfiguration, isLoading: isSavingConfiguration } =
    useSendActionObj<void, TaxConfigurationForm>(
      async (data) => {
        await saveTaxConfiguration(data);
        const taxCodesFromForm = data.taxCodes;
        const editedTaxCodes = getEditedTaxCodes(taxCodesFromForm, taxCodes);
        const deletedTaxCodes = getDeletedTaxCodes(taxCodesFromForm, taxCodes);

        if (deletedTaxCodes?.length) {
          const deleteTaxCodePromises = deletedTaxCodes.map(
            async (taxCode) => await deleteTaxCode(taxCode.resourceId)
          );

          await Promise.all(deleteTaxCodePromises);
        }

        if (editedTaxCodes?.length) {
          const saveTaxCodePromises = editedTaxCodes
            .filter((c) => !!c.resourceId)
            .map(async (taxCode) => await saveTaxCode(taxCode));
          await Promise.all(saveTaxCodePromises);
        }
      },
      {
        onDone: () => {
          refreshConfiguration();
          refreshTaxCodes();

          notification.success({
            title: 'Success',
            description: 'Tax configuration successfully saved',
          });
        },
        onError: (error) => {
          notification.error({
            title: 'Error',
            description: 'An error occurred. Please try again later!',
          });
          captureError(error);
        },
      }
    );

  const toggleAddCountriesModal = (isOpen: boolean) =>
    setAddCountriesModalOpen(isOpen);

  const toggleTaxProductsModalOpen = (isOpen: boolean) =>
    setAddTaxProductsModalOpen(isOpen);

  const onSubmit = () => {
    const payload = getValues();

    saveConfiguration(payload);
  };

  return (
    <>
      <PageDetailsHeader>Tax Management</PageDetailsHeader>
      <div className={styles.fullWidth}>
        <FormContainer>
          <FormProvider {...formMethods}>
            <PageCard title="Tax estimation">
              <div className={styles.flex}>
                <span className={styles.activeText}>Active</span>
                <Controller
                  name={TaxConfigurationFormValue.ENABLED}
                  control={control}
                  render={({ value, onChange }) => (
                    <Switch
                      onChange={(e) => onChange(e.valueOf())}
                      checked={value}
                    />
                  )}
                />
              </div>
            </PageCard>
            <div className={cn(styles.container, !canEdit && styles.disabled)}>
              <PageCard title="Payment Gateways">
                <PaymentGateways isLoading={isLoadingConfiguration} />
              </PageCard>
              <PageCard
                title="Countries"
                extra={[
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => toggleAddCountriesModal(true)}
                  >
                    Add countries
                  </Button>,
                ]}
              >
                <TaxCountries
                  data={configurationData}
                  onModalClose={() => toggleAddCountriesModal(false)}
                  isModalOpen={addCountriesModalOpen}
                  isLoading={isLoadingConfiguration}
                />
              </PageCard>
              <PageCard
                title="US Tax Codes"
                extra={[
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => toggleTaxProductsModalOpen(true)}
                  >
                    Add products
                  </Button>,
                ]}
              >
                <TaxCodes
                  data={taxCodes}
                  onModalClose={() => toggleTaxProductsModalOpen(false)}
                  isModalOpen={addTaxProductsModalOpen}
                  isLoading={isLoadingTaxCodes}
                />
              </PageCard>
            </div>
            <Button
              className={styles.saveButton}
              type="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isSavingConfiguration}
            >
              Save
            </Button>
          </FormProvider>
        </FormContainer>
      </div>
    </>
  );
};

export default TaxManagement;
