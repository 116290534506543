import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { createFeaturedAsset } from 'actions/featuredAsset';
import { Modal } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FeaturedAsset } from 'types/featuredAsset';
import { captureErrorAndNotify } from 'utils/captureError';
import notification from 'utils/notification';
import FeaturedAssetGeneralDetails from '../FeaturedAssetGeneralDetails';

interface CreateFeaturedAssetModalProps {
  isVisible?: boolean;
  onClose: () => void;
}

const CreateFeaturedAssetModal = ({
  isVisible,
  onClose,
}: CreateFeaturedAssetModalProps) => {
  const formMethods = useForm<FeaturedAsset>();
  const { handleSubmit } = formMethods;
  const { push } = useHistory();

  const { sendAction: create, isLoading } = useSendActionObj<
    FeaturedAsset,
    FeaturedAsset
  >(
    async (featuredAsset: FeaturedAsset) =>
      await createFeaturedAsset(featuredAsset),
    {
      onDone: (featuredAsset: FeaturedAsset) => {
        notification.success({
          title: 'Success',
          description: `Featured asset has been created!`,
        });

        push(`/featured-assets/${featuredAsset.uuid}`);
        onClose();
      },
      onError: captureErrorAndNotify,
    }
  );

  const onCreateFeaturedAsset = (featuredAsset: FeaturedAsset) =>
    create({
      ...featuredAsset,
      assets: [],
    });

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      destroyOnClose
      title="Add new featured asset"
      onOk={handleSubmit(onCreateFeaturedAsset)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <FeaturedAssetGeneralDetails />
      </FormProvider>
    </Modal>
  );
};

export default CreateFeaturedAssetModal;
