import { Alert, Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { Content, ContentType } from 'types';
import {
  DeleteOutlined,
  DownOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  AddRegionLicense,
  AssetLicense,
  AvailabilityTableData,
  BaseLicense,
  ContentAvailability,
} from 'types/licenses';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  createContentAvailability,
  editContentAvailability,
} from 'actions/license';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import { useContentAvailability } from 'pages/Assets/AssetDetails/utils/availabilityUtils';
import DeleteLicenseModal from 'components/ScheduledPublish/DeleteLicenseModal';
import EditLicenseModal from 'components/ScheduledPublish/EditLicenseModal';
import AvailabilityTable from 'pages/Assets/AssetDetails/AvailabilityTable';
import AddRegionModal from 'components/ScheduledPublish/AddRegionModal';
import {
  isLicenseEditable,
  mapLicensesToCreateContentAvailability,
} from 'utils/licenses';
import { Link } from 'react-router-dom';
import styles from '../../index.module.scss';
import MainContentCard from '../MainContentCard';

interface ContentRowProps {
  content: Content;
  onUnassign: () => void;
  isLoadingAssetLicenses?: boolean;
  isLoadingAssetContents?: boolean;
  assetLicenses?: AssetLicense[];
  noAssetLicensesAdded?: boolean;
  isUnasigning?: boolean;
}

const ContentRow = ({
  content,
  onUnassign,
  assetLicenses,
  isLoadingAssetLicenses,
  isLoadingAssetContents,
  noAssetLicensesAdded,
  isUnasigning,
}: ContentRowProps) => {
  const isMainContent = content?.type === ContentType.MAIN;
  const [isExpanded, setIsExpanded] = useState(isMainContent);
  const [licenseToDelete, setLicenseToDelete] =
    useState<AvailabilityTableData>();
  const [licenseToEdit, setLicenseToEdit] = useState<AvailabilityTableData>();

  const [isAddRegionModalVisible, setIsAddRegionModalVisible] = useState(false);
  const closeRegionModal = () => setIsAddRegionModalVisible(false);
  const {
    contentAvailability,
    isLoadingContentAvailability,
    refreshContentAvailability,
    contentLicensesAvailableToAdd,
  } = useContentAvailability({
    contentId: content.id,
    shouldFetchAvailability: isExpanded,
    assetLicenses,
  });

  const onUnassignClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onUnassign();
  };

  const onRowClickExpand = () => setIsExpanded(!isExpanded);

  const onSaveSuccess = () => {
    notification.success({
      title: 'Success',
      description:
        'Content availability has been saved! Please note that changed assets needs to be republished to reflect the changes.',
    });

    refreshContentAvailability();
    setIsAddRegionModalVisible(false);
    setLicenseToEdit(undefined);
  };

  const onSaveError = (error: any) => {
    notification.error({
      title: 'Error',
      description:
        error?.response?.data?.details ||
        'Something went wrong. Content availability has not been saved.',
    });
    captureError(error);
  };

  const {
    sendAction: createContentLicenses,
    isLoading: isCreatingContentLicenses,
  } = useSendActionObj(
    useCallback(
      (availability: BaseLicense[]) =>
        createContentAvailability({
          contentId: content.id,
          availability,
        }),
      [content.id]
    ),
    { onDone: onSaveSuccess, onError: onSaveError }
  );

  const {
    sendAction: updateContentLicenses,
    isLoading: isUpdatingContentLicenses,
  } = useSendActionObj(
    useCallback(
      (availability: ContentAvailability) =>
        editContentAvailability({
          contentId: content.id,
          availability: [availability],
        }),
      [content.id]
    ),
    { onDone: onSaveSuccess, onError: onSaveError }
  );

  const onAdd = (licenses: AddRegionLicense[]) => {
    const mappedLicensesToContentAvailability =
      mapLicensesToCreateContentAvailability(licenses);

    createContentLicenses(mappedLicensesToContentAvailability);
  };

  const onEdit = useCallback(
    (licenseToEdit: AvailabilityTableData) => {
      if (!('uuid' in licenseToEdit)) return;

      //NOTE: Uuid will be always provided for content availability when editing/removing
      updateContentLicenses(licenseToEdit);
    },
    [updateContentLicenses]
  );

  const onSetLicenseToEdit = (license: AvailabilityTableData) => {
    // NOTE: Content availability doesn't contain dateFrom - we have to pass it from related asset license

    const assetLicense = assetLicenses?.find(
      (assetLicense) => assetLicense.regionId === license.regionId
    );

    const extendedLicense = {
      ...license,
      dateFrom: assetLicense?.dateFrom,
      dateTo: assetLicense?.dateTo,
    };
    setLicenseToEdit(extendedLicense);
  };

  return (
    <div className={styles.row}>
      <div className={styles.main} onClick={onRowClickExpand}>
        <Link to={`/content/${content.id}`}>
          {isMainContent && (
            <PlayCircleOutlined className={styles.mainContentIcon} />
          )}
          <span className={styles.title}>{content.title}</span>
        </Link>

        <div className={styles.action}>
          <Button
            icon={<DeleteOutlined />}
            onClick={onUnassignClick}
            disabled={isLoadingAssetContents}
            loading={isUnasigning}
          >
            Unassign
          </Button>

          {isExpanded ? (
            <UpOutlined className={styles.expandIcon} />
          ) : (
            <DownOutlined className={styles.expandIcon} />
          )}
        </div>
      </div>

      {isExpanded && (
        <div className={styles.expandedContainer}>
          {isMainContent && (
            <div className={styles.expandedContentCard}>
              <MainContentCard mainContent={content} />
            </div>
          )}

          <div className={styles.expandedAvailability}>
            <span className={styles.header}>Availability</span>

            <Button
              icon={<PlusOutlined />}
              onClick={() => setIsAddRegionModalVisible(true)}
              disabled={isLoadingAssetLicenses || isLoadingAssetContents}
            >
              Add region
            </Button>

            {noAssetLicensesAdded && (
              <div className={styles.alert}>
                <Alert
                  type="info"
                  showIcon
                  message="Please add at least one license to publish this asset in a region."
                />
              </div>
            )}

            <div className={styles.availabilityTable}>
              <AvailabilityTable
                isLoading={isLoadingContentAvailability}
                data={contentAvailability}
                onRemove={(license) => setLicenseToDelete(license)}
                isEditable={isLicenseEditable}
                onEdit={onSetLicenseToEdit}
              />
            </div>
          </div>
        </div>
      )}

      {isAddRegionModalVisible && (
        <AddRegionModal
          isOpen={isAddRegionModalVisible}
          onClose={closeRegionModal}
          licenses={contentLicensesAvailableToAdd}
          isLoadingDataTable={
            isLoadingAssetLicenses || isLoadingContentAvailability
          }
          onAddLicenses={onAdd}
          isConfirmLoading={isCreatingContentLicenses}
        />
      )}

      {licenseToDelete && (
        <DeleteLicenseModal
          isOpen={!!licenseToDelete}
          license={licenseToDelete}
          onClose={() => setLicenseToDelete(undefined)}
          refreshLicenseTable={refreshContentAvailability}
          type="content"
        />
      )}

      {licenseToEdit && (
        <EditLicenseModal
          license={licenseToEdit}
          onClose={() => setLicenseToEdit(undefined)}
          isOpen={!!licenseToEdit}
          onEdit={onEdit}
          confirmLoading={isUpdatingContentLicenses}
        />
      )}
    </div>
  );
};

export default ContentRow;
