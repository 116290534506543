import React, { useCallback, useState } from 'react';
import { getAdminsPagination } from 'actions/users';
import { Pagination, User } from 'types';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import { usePageParam } from 'utils/pagination';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import ResetPasswordModalForm from 'pages/Users/UserList/ResetPasswordModal';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { errorNotify } from 'utils/errorNotify';
import UserTable from 'pages/Users/UserList/UserTable';
import { captureError } from 'utils/captureError';
import { Paths } from 'types/paths';

const UserList = () => {
  const page = usePageParam();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>();
  const [pagination, isLoading, error, reload] = useFetchAction<
    Pagination<User>
  >(useCallback(() => getAdminsPagination(page), [page]));

  const clearModal = () => {
    setModalOpen(false);
    setSelectedUserEmail(undefined);
  };

  const auth = getAuth();

  const handleSendPasswordResetEmail = (email: string) =>
    sendPasswordResetEmail(auth, email)
      .then(async () => {
        message.success(`Email has been successfully sent to ${email}!`);
      })
      .catch((e) => {
        errorNotify(e);
        captureError(e);
      })
      .finally(() => clearModal());

  return (
    <PageContainer>
      <PageHeader
        title="Users"
        extra={
          <Link key="add_user" to={`${Paths.USERS}/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Add new user
            </Button>
          </Link>
        }
      />
      <ErrorIndicator error={error} />
      <UserTable
        currentPage={page ?? 1}
        pagination={pagination}
        loading={isLoading}
        setModalOpen={setModalOpen}
        setSelectedUserEmail={setSelectedUserEmail}
        reload={reload}
      />
      {isModalOpen && selectedUserEmail && (
        <ResetPasswordModalForm
          visible={!!(isModalOpen && selectedUserEmail)}
          clearModal={clearModal}
          selectedUserEmail={selectedUserEmail}
          sendPasswordResetEmail={handleSendPasswordResetEmail}
        />
      )}
    </PageContainer>
  );
};

export default UserList;
