import { AssetCrew } from 'types';
import { api } from './api';

export const getCrewTypes = async (): Promise<string[]> => {
  const { data } = await api().get(`/crew/types`);

  return data;
};

export const getCrew = async (params?: {
  name?: string;
}): Promise<string[]> => {
  const { data } = await api().get(`/crew`, { params: { name: params?.name } });

  return data;
};

export const saveAssetCrew = async ({
  id,
  crew,
}: {
  id: string | number;
  crew: AssetCrew[];
}): Promise<void> => {
  await api().put(`/asset/${id}/crew`, crew);
};

export const getAssetCrew = async ({
  id,
}: {
  id: string | number;
}): Promise<AssetCrew[]> => {
  const { data } = await api().get(`/asset/${id}/crew`);

  return data;
};
