import { DragHandle, HideOnDrag } from 'components/DraggableTableItems';
import { DeleteOutlined, EditOutlined, HomeFilled } from '@ant-design/icons';
import DragAndDropTable from 'components/DragAndDropTable';
import { MenuLink } from 'types/menu';
import { Link } from 'react-router-dom';
import { Paths } from 'types/paths';
import StringList from 'components/StringList';
import useInterestsTableFilter from 'hooks/useInterestsTableFilter';
import styles from './index.module.scss';

export interface RegionPageMenuProps {
  links?: MenuLink[];
  onLinksOrderChange: (links: MenuLink[]) => void;
  onLinkDelete: (link: MenuLink) => void;
  onLinkEdit: (link: MenuLink) => void;
}

const MenuCustomPageElement = ({
  links,
  onLinkDelete,
  onLinkEdit,
  onLinksOrderChange,
}: RegionPageMenuProps) => {
  const { filters } = useInterestsTableFilter<MenuLink>({
    onFilter: (search, menuLink) =>
      !search || (menuLink.interests || []).includes(search as string),
  });
  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <DragHandle />,
    },
    {
      title: 'Page / Link',
      dataIndex: 'name',
      render: (name: string, _: MenuLink, index: number) =>
        index === 0 ? (
          <div className={styles.homePage}>
            <HomeFilled className={styles.homeIcon} />
            {name}
          </div>
        ) : (
          name
        ),
    },
    {
      title: 'Link to',
      dataIndex: 'linkTo',
      render: (_: string, row: MenuLink) => {
        return 'url' in row && row.url ? (
          <div className={styles.wrappedColumn}>
            <a href={row.url} target="_blank" rel="noopener noreferrer">
              {row.url}
            </a>
          </div>
        ) : (
          <Link to={`${Paths.PAGES}/${row.uuid}`}>{row.name}</Link>
        );
      },
    },
    {
      title: 'Interests',
      dataIndex: 'interests',
      render: (interests: string[]) => {
        const interestTranslations =
          interests?.map(
            (interest) =>
              filters.filters.find((filter) => {
                return filter.value === interest;
              })?.text || interest
          ) || [];
        return <StringList maxShowCount={3} items={interestTranslations} />;
      },
      ...filters,
    },
    {
      dataIndex: 'action',
      render: (_: string, link: MenuLink) => (
        <div className={styles.flexWrapper}>
          <HideOnDrag>
            <EditOutlined
              className={styles.editItem}
              onClick={() => onLinkEdit(link)}
            />
          </HideOnDrag>
          <HideOnDrag>
            <DeleteOutlined onClick={() => onLinkDelete(link)} />
          </HideOnDrag>
        </div>
      ),
    },
  ];

  return (
    <DragAndDropTable
      dataSource={links}
      onOrderChange={onLinksOrderChange}
      rowKey="order"
      columns={columns}
      pagination={false}
    />
  );
};

export default MenuCustomPageElement;
