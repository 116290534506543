import api from 'actions/api';
import { AIDetection } from 'types/ai';

export const addUnderscoreToLabel = (label: string) =>
  label.replace(new RegExp(' ', 'g'), '_');

export const runAutoAIRecognition = async (sourceId: string): Promise<void> => {
  const { data } = await api().put(`/ai/recognition/${sourceId}`);

  return data;
};

export const processAICustomLabel = async (id: string, label: string) => {
  const { data } = await api().post(`/ai/face/${id}/index`, {
    label: addUnderscoreToLabel(label),
  });

  return data;
};

export const runAIFaceRecognition = async (sourceId: string) => {
  const { data } = await api().put(`/ai/recognition/${sourceId}/face`);

  return data;
};

export const fetchAIRecognitionStatus = async (sourceId: string) => {
  const { data } = await api().get<AIDetection>(`/ai/recognition/${sourceId}`);

  return data;
};
