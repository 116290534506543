import { PaymentGateway } from 'types/plan';

export const PAYMENT_GATEWAYS_WITH_INR_CURRENCY = [
  PaymentGateway.PAYU,
  PaymentGateway.RAZORPAY,
];

export const isExternalPaymentGateway = (gateway: PaymentGateway) =>
  [
    PaymentGateway.APPLE,
    PaymentGateway.AMAZON,
    PaymentGateway.GOOGLE,
    PaymentGateway.RAZORPAY,
    PaymentGateway.ROKU,
  ].includes(gateway);

export const shouldProvideBillingAmount = (gateway: PaymentGateway) =>
  gateway === PaymentGateway.RAZORPAY || !isExternalPaymentGateway(gateway);

export const isAllowedToChangeCurrency = (gateway: PaymentGateway) =>
  !PAYMENT_GATEWAYS_WITH_INR_CURRENCY.includes(gateway);

export const shouldProvideExternalProductId = (gateway: PaymentGateway) =>
  [PaymentGateway.RAZORPAY].includes(gateway);
