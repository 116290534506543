import React, { useCallback, useEffect } from 'react';
import { updateType } from 'actions/content';
import { Select } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import LoadingIndicator from 'components/LoadingIndicator';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Space from 'components/Space';
import Button from 'components/Button';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { contentTypes } from 'utils/constants';
import { RouteIdParams } from 'utils/routeParams';

type Inputs = {
  type: string;
};

const TypeForm = ({
  type,
  onSubmit,
}: {
  type?: string;
  onSubmit: (data: Inputs) => void;
}) => {
  const { handleSubmit, errors, reset, formState, control } = useForm<Inputs>({
    defaultValues: { type },
  });

  useEffect(() => {
    reset({ type });
  }, [type, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <Space direction="vertical">
          <div>
            <div>
              <label>Type</label>
            </div>
            <Controller
              as={Select}
              style={{ width: 120 }}
              control={control}
              name="type"
              rules={{ required: true }}
              options={contentTypes}
            />
            {errors.type && <div>This field is required</div>}
          </div>
          <Button
            style={{ marginTop: '12px' }}
            type="primary"
            htmlType="submit"
            disabled={!formState.isDirty}
          >
            Save
          </Button>
        </Space>
      </fieldset>
    </form>
  );
};

const ContentTypeEdit = ({
  isLoading,
  type,
  error,
  onSave,
}: {
  isLoading: boolean;
  type?: string;
  error?: Error;
  onSave: () => void;
}) => {
  const { id } = useParams<RouteIdParams>();

  const [sendForm, isUpdating, fired, updatedType, updateError] = useSendAction<
    { type: string },
    Inputs
  >(
    useCallback(
      (inputs: Inputs) =>
        updateType({
          id: Number(id),
          type: inputs.type,
        }),
      [id]
    ),
    { onDone: onSave }
  );

  if (isLoading) return <LoadingIndicator />;

  if (error || updateError)
    return <ErrorIndicator error={error || updateError} />;

  return (
    <div>
      <Space direction="vertical" expand>
        {isUpdating && <LoadingIndicator />}
        {fired && !isUpdating && !updateError && <span>Saved!</span>}
        <TypeForm type={updatedType?.type || type} onSubmit={sendForm} />
      </Space>
    </div>
  );
};

export default ContentTypeEdit;
