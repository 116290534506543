import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Tooltip } from 'antd';
import styles from './index.module.scss';

const UserSearchTooltip = () => {
  const TooltipBody = (
    <div className={styles.tooltipBody}>
      <h3>How to search </h3>
      <div className={styles.tooltipDescription}>
        While searching, please note that you need to enter the exact email,
        uuid or phone number with the correct capitalization to match the
        information in the database and get results.
        <br />
        <br />
        <b>Examples</b>:
        <ul>
          <li>
            if phone number is +91222333444, you cannot search for 222333444,
          </li>
          <li>
            if email is john.brown@mail.com, you cannot search for
            John.Brown@mail.com.
          </li>
        </ul>
      </div>

      <Alert
        type="info"
        message={
          <div className={styles.alert}>
            You can also use the command <b>'explore:john'</b> to display all
            results that include 'john'. Please note that this search might take
            some time.
          </div>
        }
      />
    </div>
  );

  return (
    <Tooltip
      overlay={TooltipBody}
      color="white"
      overlayClassName={styles.tooltipInner}
      placement="bottomLeft"
    >
      <div className={styles.iconWrapper}>
        <InfoCircleOutlined className={styles.icon} />
      </div>
    </Tooltip>
  );
};

export default UserSearchTooltip;
