import React, { useEffect, useState } from 'react';
import { getAssetByUuid } from 'actions/assets';
import AssetChooser from 'components/AssetChooser';
import { Asset, AssetStatus } from 'types';
import { AssetType } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';

export interface AssetUuidChooserProps {
  chosen: string;
  onChoose: (uuid: string) => void;
  allowedTypes?: AssetType[];
  disabled?: boolean;
  regionUuids?: string[];
  placeholder?: string;
  statuses?: AssetStatus[];
}

/**
 * Small component built on top of AssetChooser that takes
 * care of fetching missing asset
 */
const AssetUuidChooser = ({
  chosen,
  onChoose,
  allowedTypes,
  disabled,
  regionUuids,
  placeholder,
  statuses,
}: AssetUuidChooserProps) => {
  const [internalChosen, setInternalChosen] = useState<Asset>();

  useEffect(() => {
    // If chosen is passed down, but our internal value is empty then fetch the asset
    if (!chosen || internalChosen) {
      return;
    }

    getAssetByUuid(chosen).then(setInternalChosen).catch(captureError);
  }, [chosen, internalChosen]);

  return (
    <AssetChooser
      allowedTypes={allowedTypes}
      chosenAsset={internalChosen}
      statuses={statuses}
      onChoose={(asset) => {
        setInternalChosen(asset);
        onChoose(asset?.uuid);
      }}
      disabled={disabled}
      regionUuids={regionUuids}
      placeholder={placeholder}
    />
  );
};

export default AssetUuidChooser;
