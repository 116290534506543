import { Divider } from 'antd';
import Pie from 'ant-design-pro/lib/Charts/Pie';
import { DashboardAssetTypesCount } from 'types/dashboard';
import styles from './index.module.scss';

//bottom section dashboard
interface DashboardPieProps {
  data?: DashboardAssetTypesCount[];
}

const DashboardPie = ({ data }: DashboardPieProps) => {
  let total = 0;

  const pieChartData = data?.map((asset) => {
    return { x: asset.type, y: Number(asset.count) };
  });

  if (pieChartData) {
    total = pieChartData.reduce((acc, curVal) => {
      acc += curVal.y;
      return acc;
    }, 0);
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Total assets</h1>
      <Divider className={styles.mt0} />

      <Pie
        total={`${total} assets`}
        subTitle={'Total'}
        hasLegend
        data={pieChartData}
        height={294}
      />
    </div>
  );
};

export default DashboardPie;
