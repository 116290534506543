import React, { useEffect, useState } from 'react';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import Button from 'components/Button';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Skeleton, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { usePolling } from '@laminar-product/client-commons-core/hooks';
import { ListingPageTranslation } from 'types/translations';
import { useTranslationContext } from 'contexts/translationContext';
import { findTranslationsByName } from 'utils/translation';
import { useDictionary } from 'hooks/useDictionaries';
import { DictionaryType } from 'store/dictionaries/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsLoading } from 'store/app/selectors';
import { archiveDictionaryAction } from 'store/dictionaries/actions';
import { useAllLanguages } from 'hooks/useLanguages';
import StringList from '../StringList';
import ListingPageModal from './ListingPageModal';
import styles from './index.module.scss';

export const ListingPage = ({
  title,
  label,
  dictionaryType,
  translationKey,
  useFetchDictionary,
}: {
  title: string;
  dictionaryType: DictionaryType;
  label: string;
  translationKey: string;
  useFetchDictionary: typeof useDictionary;
}) => {
  const [isModalOpen, setModalOpen] = useState<{
    defaultValues?: ListingPageTranslation;
    isOpen: boolean;
  }>({
    defaultValues: undefined,
    isOpen: false,
  });

  const [query, setQuery] = useState<string>('');
  const [archiveDictionary, setArchiveDictionary] = useState<
    string | undefined
  >();
  const { translations, refresh: refreshTranslations } =
    useTranslationContext();
  const dispatch = useDispatch();
  const dictionaryId = `ARCHIVE_DICTIONARY_${dictionaryType.toUpperCase()}`;
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, dictionaryId)
  );
  const { getLanguageNameByIsoCode } = useAllLanguages();

  const { data } = useFetchDictionary({ dictionaryType, query });

  useEffect(() => {
    refreshTranslations();
  }, [refreshTranslations]);

  usePolling(refreshTranslations);

  if (!data) return <Skeleton />;

  const mapToTable = (): { name: string }[] => data.map((d) => ({ name: d }));

  const closeModalHandler = () => setArchiveDictionary(undefined);

  const archiveDictionaryHandler = () => {
    archiveDictionary &&
      dispatch(
        archiveDictionaryAction.request({
          dictionaryType,
          name: archiveDictionary,
        })
      );
    closeModalHandler();
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title={title}
          extra={[
            <Search onSearch={setQuery} key="search" />,
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModalOpen({ isOpen: true })}
            >
              Create new {label}
            </Button>,
          ]}
        />
        <Table dataSource={mapToTable()} rowKey="name">
          <Column
            title="Name"
            key="name"
            render={(title, node: { name: string }) => <>{node.name}</>}
          />
          <Column
            title="Language"
            key="language"
            render={(title, node: { name: string }) => {
              const _findTranslationsByName = findTranslationsByName(
                node.name,
                translationKey,
                translations
              );

              return (
                <div className={styles.rowWrapper}>
                  <StringList
                    items={_findTranslationsByName.map((t) =>
                      getLanguageNameByIsoCode(t.langCode)
                    )}
                  />
                  <div className={styles.actionWrapper}>
                    <EditOutlined
                      className={styles.pointer}
                      onClick={() =>
                        setModalOpen({
                          isOpen: true,
                          defaultValues: {
                            name: node.name,
                            translations: _findTranslationsByName,
                          },
                        })
                      }
                    />
                    <DeleteOutlined
                      onClick={(event) => {
                        event.stopPropagation();
                        setArchiveDictionary(node.name);
                      }}
                    />
                  </div>
                </div>
              );
            }}
          />
        </Table>
      </PageContainer>

      {isModalOpen.isOpen && (
        <ListingPageModal
          open={isModalOpen.isOpen}
          close={() =>
            setModalOpen({ defaultValues: undefined, isOpen: false })
          }
          label={label}
          dictionaryType={dictionaryType}
          defaultValues={isModalOpen.defaultValues}
        />
      )}
      <Modal
        title="Confirmation"
        visible={!!archiveDictionary}
        onCancel={closeModalHandler}
        onOk={archiveDictionaryHandler}
        confirmLoading={isLoading}
        centered
      >
        Are you sure you want to archive {label}{' '}
        <strong>{archiveDictionary}</strong>?
      </Modal>
    </>
  );
};

export default ListingPage;
