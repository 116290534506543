import FormGroup from 'components/FormGroup';
import InterestsSelect from 'components/InterestsSelect';
import { Controller, UseFormMethods } from 'react-hook-form';
import { PlanForm } from 'types/plan';

type InterestsPlanType = Pick<UseFormMethods<PlanForm>, 'control'>;

const InterestsPlan = ({ control }: InterestsPlanType) => (
  <FormGroup
    input={
      <Controller
        control={control}
        name="interests"
        as={<InterestsSelect withoutNoPreference />}
      />
    }
    label="Interests"
  />
);

export default InterestsPlan;
