import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import React, { useCallback, useMemo, useState } from 'react';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu, PageHeader, Table } from 'antd';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getAssetLicenses } from 'actions/license';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { AssetLicense, LicenseRegion } from 'types/licenses';
import { captureError } from 'utils/captureError';
import DeleteLicenseModal from 'components/ScheduledPublish/DeleteLicenseModal';
import { convertDateToFormattedUTCLabel } from 'utils/timezones';
import AddLicenseModal from './components/AddLicenseModal';
import styles from './index.module.scss';

const { Column } = Table;

const AssetLicenses = () => {
  const { asset } = useAssetDetailsContext();

  const [isAddLicenseModalVisible, setIsAddLicenseModalVisible] =
    useState<boolean>();

  const [licenseToDelete, setLicenseToDelete] = useState<AssetLicense>();

  const {
    data: licenses,
    isLoading,
    refresh: refreshLicenseTable,
  } = useFetchActionObj<AssetLicense[]>(
    useCallback(() => getAssetLicenses({ assetId: asset.id }), [asset.id]),
    { onError: captureError }
  );

  const usedRegionIds = useMemo(
    () => licenses?.map((l) => l.regionId),
    [licenses]
  );

  const getFormattedDate = (date: string) =>
    convertDateToFormattedUTCLabel(date);

  return (
    <PageContainer>
      <PageHeader
        title="Licenses"
        extra={
          <Button
            type="default"
            onClick={() => setIsAddLicenseModalVisible(true)}
            icon={<PlusOutlined />}
          >
            Add licenses
          </Button>
        }
      />

      <Table rowKey="id" loading={isLoading} dataSource={licenses}>
        <Column
          title="Region"
          dataIndex="region"
          render={(region: LicenseRegion) => region.name}
        />
        <Column
          title="License start date"
          dataIndex="dateFrom"
          render={(dateFrom: string) => getFormattedDate(dateFrom)}
        />
        <Column
          title="License end date"
          dataIndex="dateTo"
          render={(dateTo: string) => getFormattedDate(dateTo)}
        />
        <Column
          title="Actions"
          align="right"
          render={(_, license: AssetLicense) => (
            <>
              <Dropdown
                className={styles.actionButton}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={license.id + 1}
                      onClick={() => setLicenseToDelete(license)}
                    >
                      Remove license
                    </Menu.Item>
                  </Menu>
                }
              >
                <MoreOutlined />
              </Dropdown>
            </>
          )}
        />
      </Table>

      {isAddLicenseModalVisible && (
        <AddLicenseModal
          isOpen={isAddLicenseModalVisible}
          onClose={() => setIsAddLicenseModalVisible(false)}
          refreshLicenseTable={refreshLicenseTable}
          regionsIdsToFilterOut={usedRegionIds}
        />
      )}

      {licenseToDelete && (
        <DeleteLicenseModal
          isOpen={!!licenseToDelete}
          license={licenseToDelete}
          onClose={() => setLicenseToDelete(undefined)}
          refreshLicenseTable={refreshLicenseTable}
          type="asset"
        />
      )}
    </PageContainer>
  );
};

export default AssetLicenses;
