import React from 'react';
import {
  CaretRightOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Input } from 'antd';
import cn from 'classnames';
import Button from 'components/Button';
import ButtonsRow from 'components/ButtonsRow';
import styles from './index.module.scss';

interface ReferencesListProps {
  items: any;
  onTakeSnapshot: () => void;
  onNameUpdate: (index: number, value: string) => void;
  onDelete: (index: number) => void;
  onCancel: () => void;
  onAnalyzeVideo: () => void;
}
const ReferencesList = ({
  items,
  onTakeSnapshot,
  onNameUpdate,
  onDelete,
  onCancel,
  onAnalyzeVideo,
}: ReferencesListProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>Detect new cast</span>
        <Button
          type="primary"
          ghost
          icon={<UserAddOutlined />}
          onClick={onTakeSnapshot}
        >
          Take a snapshot
        </Button>
      </div>
      <div className={styles.content}>
        <div className={cn(styles.row, styles.rowHeader)}>
          <span>REF</span>
          <span>NAME</span>
        </div>
        {items.map((item: any, index: number) => (
          <div className={cn(styles.row, styles.rowItem)} key={index}>
            <div className={styles.imageWrapper}>
              <img src={item.image} alt="" className={styles.imageRef} />
            </div>
            <div className={styles.itemData}>
              <Input
                value={item.name}
                autoFocus={index === 0}
                onChange={(e) => onNameUpdate(index, e.target.value)}
                className={styles.itemInput}
              />
              <DeleteOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(index);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <ButtonsRow className={styles.footer}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          icon={<CaretRightOutlined />}
          onClick={onAnalyzeVideo}
        >
          Re-analyze video
        </Button>
      </ButtonsRow>
    </div>
  );
};

export default ReferencesList;
