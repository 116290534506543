import { getContentFile } from 'actions/content';
import { Spin } from 'antd';
import MainContentAssign from 'components/MainContentAssign';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { isLiveAssetType } from 'pages/Assets/AssetDetails/utils';
import React, { useEffect, useState } from 'react';
import { Content, UploadedFile } from 'types';

interface MainContentCardProps {
  mainContent: Content;
}

const MainContentCard = ({ mainContent }: MainContentCardProps) => {
  const { asset } = useAssetDetailsContext();
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [mainContentDetails, setMainContentDetails] = useState<UploadedFile>();
  const isLiveType = isLiveAssetType(asset);

  useEffect(() => {
    if (!mainContent || !!mainContentDetails) {
      return;
    }

    setIsContentLoading(true);

    getContentFile({ id: mainContent.id })
      .then((details) => {
        setMainContentDetails(details);
      })
      .catch((e) => {})
      .finally(() => setIsContentLoading(false));
  }, [mainContent, mainContentDetails]);

  if (isLiveType) {
    return null;
  }

  if (isContentLoading) {
    return <Spin />;
  }

  return (
    <>
      <MainContentAssign
        mainContent={mainContent}
        mainContentDetails={mainContentDetails}
        asset={asset}
      />
    </>
  );
};

export default MainContentCard;
