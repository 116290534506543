import { Button, PageHeader, Table, Tooltip } from 'antd';
import PageContainer from 'components/PageContainer';
import React, { useCallback, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import { Permission } from 'utils/constants';
import StatusTag, { getStatusTagConfig } from 'components/StatusTag';
import { usePermission } from 'hooks/permissionsHook';
import { PlatformPartner } from 'types/platformPartners';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  Pagination,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import { getPlatformPartners } from 'actions/platformPartners';
import { useHistory } from 'react-router-dom';
import { captureErrorAndNotify } from 'utils/captureError';
import DeletePartnerModal from '../DeletePartnerModal';
import usePartnerActions from '../utils/usePartnerActions';
import CreatePartnerModal from './CreatePartnerModal';

const PartnersList = () => {
  const { canUse } = usePermission();
  const { push } = useHistory();
  const page = usePageParam();
  const [isCreatePartnerModalVisible, setIsCreatePartnerModalVisible] =
    useState(false);
  const [focusedPartner, setFocusedPartner] = useState<PlatformPartner>();

  const {
    data: platformPartners,
    isLoading,
    refresh: refreshPartnersList,
  } = useFetchActionObj<Pagination<PlatformPartner>>(
    useCallback(
      () =>
        getPlatformPartners({
          page,
        }),
      [page]
    ),
    {
      onError: captureErrorAndNotify,
    }
  );

  const partnerActions = usePartnerActions();
  const onRowClick = (data: PlatformPartner) => push(`/partners/${data.uuid}`);

  const onDeleteModalClose = () => {
    setFocusedPartner(undefined);
    partnerActions.toggleDeletePartnerModal(false);
  };

  const onDeleteClick = (
    event: React.MouseEvent,
    platformPartner: PlatformPartner
  ) => {
    event.stopPropagation();
    setFocusedPartner(platformPartner);
    partnerActions.toggleDeletePartnerModal(true);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Partners"
        extra={[
          canUse([Permission.PLATFORM_PARTNER_CREATE]) && (
            <Button
              key="createNewPartner"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsCreatePartnerModalVisible(true)}
            >
              Create new partner
            </Button>
          ),
        ]}
      />

      <Table
        dataSource={platformPartners?.data}
        onRow={(row: PlatformPartner) => ({
          onClick: () => onRowClick(row),
        })}
        rowClassName="clickable-row"
        loading={isLoading}
        pagination={usePaginationObject('/partners', platformPartners, page)}
      >
        <Column
          title="Name"
          key="administrativeName"
          dataIndex="administrativeName"
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => <StatusTag type={getStatusTagConfig(status)} />}
        />
        <Column
          dataIndex="action"
          align="right"
          render={(_, platformPartner: PlatformPartner) =>
            ['PUBLISHED', 'MODIFIED'].includes(platformPartner.status) ? (
              <Tooltip title="Cannot delete published platform partner">
                <Button
                  icon={<DeleteOutlined />}
                  shape="circle"
                  type="text"
                  disabled
                />
              </Tooltip>
            ) : (
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                type="text"
                onClick={(event) => onDeleteClick(event, platformPartner)}
              />
            )
          }
        />
      </Table>

      <CreatePartnerModal
        isOpen={isCreatePartnerModalVisible}
        onClose={() => setIsCreatePartnerModalVisible(false)}
      />

      {focusedPartner && (
        <DeletePartnerModal
          partner={focusedPartner}
          isOpen={partnerActions.isDeletePartnerModalVisible}
          onClose={onDeleteModalClose}
          refreshPartnersList={refreshPartnersList}
        />
      )}
    </PageContainer>
  );
};

export default PartnersList;
