import { Button, Modal } from 'antd';
import PageContainer from 'components/PageContainer';
import React, { useState } from 'react';
import { ExclamationCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { generateSitemap } from 'actions/sitemap';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import styles from '../../index.module.scss';

const Sitemap = () => {
  const [sitemapModalOpen, setSitemapModalOpen] = useState(false);
  const onGenerateSitemapButtonClick = () => setSitemapModalOpen(true);
  const onGenerateSitemapModalCancel = () => setSitemapModalOpen(false);

  const { sendAction: generate, isLoading } = useSendActionObj(
    generateSitemap,
    {
      onDone: () => {
        notification.success({
          description: 'Sitemap has been successfully generated!',
        });
        onGenerateSitemapModalCancel();
      },
      onError: (e) => {
        notification.error({
          description: 'Something went wrong. Please try again.',
        });
        captureError(e);
      },
    }
  );

  const onGenerateSitemapConfirm = () => generate();

  return (
    <>
      <PageContainer>
        <h1 className={styles.header}>Sitemap</h1>
        <Button
          icon={<GlobalOutlined />}
          type="primary"
          onClick={onGenerateSitemapButtonClick}
          size="middle"
          loading={isLoading}
        >
          Generate Sitemap
        </Button>
      </PageContainer>

      <Modal
        title="Confirmation"
        visible={sitemapModalOpen}
        onCancel={onGenerateSitemapModalCancel}
        onOk={onGenerateSitemapConfirm}
        confirmLoading={isLoading}
        centered
        className={styles.sitemapModal}
        cancelText="No"
      >
        <ExclamationCircleOutlined className={styles.warningIcon} />
        Are you sure you want to generate a new sitemap?
      </Modal>
    </>
  );
};

export default Sitemap;
