import { ApiActionType, Region, RegionCreateForm } from 'types';
import { CountryTranslation } from 'types/translations';
import { createAsyncAction } from 'typesafe-actions';

interface ISendRegionRequest {
  form: RegionCreateForm;
  type?: ApiActionType;
  regionId?: number;
}

interface ISendRegionSuccess {
  region: Region;
  type: ApiActionType;
}

export const fetchCountriesAction = createAsyncAction(
  'FETCH_COUNTRIES_REQUEST',
  'FETCH_COUNTRIES_SUCCESS',
  'FETCH_COUNTRIES_FAILURE'
)<undefined, CountryTranslation[], Error>();

export const fetchRegionsAction = createAsyncAction(
  'FETCH_REGIONS_REQUEST',
  'FETCH_REGIONS_SUCCESS',
  'FETCH_REGIONS_FAILURE'
)<undefined, Region[], Error>();

export const sendRegionAction = createAsyncAction(
  'SEND_REGION_REQUEST',
  'SEND_REGION_SUCCESS',
  'SEND_REGION_FAILURE'
)<ISendRegionRequest, ISendRegionSuccess, Error>();
