import { Select, Typography } from 'antd';
import React from 'react';
import { StreamingProfile } from 'types';
import cn from 'classnames';
import styles from './index.module.scss';
const { Option } = Select;
const { Text } = Typography;

export interface StreamingProfileChooserProps {
  onChoose: (id: string) => void;
  value?: string;
  data: StreamingProfile[];
  className?: string;
}

const StreamingProfileChooser = ({
  onChoose,
  value,
  data,
  className,
}: StreamingProfileChooserProps) => {
  return (
    <Select
      value={value}
      onChange={onChoose}
      className={cn(styles.fullWidth, className)}
    >
      {data?.map((profile) => (
        <Option key={profile.id} value={profile.id}>
          <div>
            <Text className={styles.block}>{profile.name}</Text>
            <Text type="secondary">{profile.description}</Text>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default StreamingProfileChooser;
