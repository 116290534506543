import { CensorshipRuleTranslations } from 'types/censorships';

const getCensorshipRuleLanguageNames = ({
  getLanguageNameByIsoCode,
  translations,
}: {
  getLanguageNameByIsoCode: (isoCode: string) => string;
  translations?: CensorshipRuleTranslations;
}) => {
  if (!translations) return [];
  return Object.keys(translations).map((translation) =>
    getLanguageNameByIsoCode(translation)
  );
};
export { getCensorshipRuleLanguageNames };
