import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import { getCensorshipRule, updateCensorshipRule } from 'actions/censorships';
import FormContainer from 'components/FormContainer';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RouteCensorshipRuleParams } from 'utils/routeParams';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { CensorshipRule } from 'types/censorships';
import PublicDetails from 'components/PublicDetails';
import { Breadcrumb } from 'antd';
import notification from 'utils/notification';
import formatLabel from 'utils/formatLabel';
import {
  TranslationDescription,
  TranslationName,
} from '../CensorshipRuleFormFields';
import { hasDefualtTranslation } from '../utils';
import CensorshipRuleGeneralDetails from './components/CensorshipRuleGeneralDetails';
import styles from './index.module.scss';

const CensorshipRuleDetails: FC = () => {
  const { id, region } = useParams<RouteCensorshipRuleParams>();

  // TODO: Move Censorship rules to redux state
  const [censorshipRule] = useFetchAction(
    useCallback(() => getCensorshipRule(id), [id])
  );
  const formMethods = useForm<CensorshipRule>({ mode: 'onChange' });
  const {
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    register,
    unregister,
  } = {
    ...formMethods,
  };

  useEffect(() => {
    if (censorshipRule) {
      reset({
        ...getValues(),
        ...censorshipRule,
      });
    }
  }, [getValues, censorshipRule, reset]);

  useEffect(() => {
    register('translations');
    return () => {
      unregister('translations');
    };
  }, [register, unregister]);

  const [sendForm, isProcessing] = useSendAction<
    CensorshipRule,
    CensorshipRule
  >(
    (form) =>
      updateCensorshipRule({
        censorshipToSave: form,
        id,
      }),
    {
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'Age rating has been saved!',
        });
      },
    }
  );

  const onSubmit = () => {
    const payload = getValues();
    sendForm(payload);
  };

  const removeLanguageHandler = (lang: string) => {
    const translations = getValues('translations');
    delete translations[lang];

    setValue('translations', translations);
  };

  const saveLanguageHandler = (
    lang: string,
    { selectedLanguage, ...details }: { selectedLanguage: string }
  ) => {
    const translation = {
      ...watch('translations'),
      [selectedLanguage!]: details,
    };
    if (selectedLanguage !== lang) delete translation[lang];

    setValue('translations', translation);
  };

  const checkForDefaultTranslation = () => {
    if (!censorshipRule) return false;

    const translations =
      censorshipRule.translations &&
      Object.keys(censorshipRule.translations).length > 0
        ? censorshipRule.translations
        : getValues('translations');
    return hasDefualtTranslation(translations);
  };

  return (
    <>
      <PageDetailsHeader>
        <div className={styles.headerWrapper}>
          <div className={styles.breadcrumbs}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item href={`/censorships`}>
                Age ratings
              </Breadcrumb.Item>
              <Breadcrumb.Item>{formatLabel(region)}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {watch('name')}
        </div>
      </PageDetailsHeader>
      <div className={styles.root}>
        <FormContainer>
          <FormProvider {...formMethods}>
            <div className={styles.cardsWrapper}>
              <PageCard title="General details" icon={<InfoCircleOutlined />}>
                <CensorshipRuleGeneralDetails />
              </PageCard>

              <PublicDetails
                onRemoveLanguage={removeLanguageHandler}
                onSaveLanguage={saveLanguageHandler}
                items={watch('translations')}
                renderLanguageDetails={(translation) => (
                  <>
                    <div className={styles.translationDetails}>
                      <span>Rating name</span>
                      <p>{translation.name}</p>
                    </div>
                    <div className={styles.translationDetails}>
                      <span>Rating Description</span>
                      <p>{translation.description}</p>
                    </div>
                  </>
                )}
                renderLanguageForm={(control, errors) => (
                  <>
                    <TranslationName control={control} errors={errors!} />
                    <TranslationDescription
                      control={control}
                      errors={errors!}
                    />
                  </>
                )}
              />
              <Button
                disabled={isProcessing || !checkForDefaultTranslation()}
                type="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </div>
          </FormProvider>
        </FormContainer>
      </div>
    </>
  );
};

export default CensorshipRuleDetails;
