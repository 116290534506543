import React from 'react';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Image, Spin } from 'antd';
import cn from 'classnames';
import { Image as IImage, ImageStatus } from 'types';
import getBase64 from 'utils/getBase64';
import styles from './index.module.scss';

interface Props {
  onImageFileChange?: (file?: File) => void;
  onImageDelete?: (id: number) => void;
  previewImage?: string;
  setPreviewImage?: (base64: string) => void;
  isLoading?: boolean;
  image?: IImage;
  error?: string;
  status?: string;
}

const ImagePlaceholder = ({
  onImageDelete,
  onImageFileChange,
  image,
  isLoading,
  setPreviewImage,
  previewImage,
  error,
}: Props) => {
  const previewImageBase64 = (files: FileList) => {
    if (setPreviewImage) {
      getBase64(files[0]).then((base) => setPreviewImage(base));
    }
  };

  if (error && image) {
    return (
      <>
        <div
          className={cn([styles.root, styles.borderedImageWrapper])}
          onClick={() => (onImageDelete ? onImageDelete(image.id) : null)}
        >
          <CloseCircleOutlined />
        </div>
        <span className={styles.error}>{error}</span>
      </>
    );
  }

  if (isLoading || image?.status === ImageStatus.CREATED) {
    return (
      <div className={`${styles.root}`}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {!image && !previewImage && (
        <div className={cn([styles.root, styles.borderedImageWrapper])}>
          <input
            type="file"
            accept="image"
            className={styles.inputPlaceholder}
            onChange={(e) => {
              previewImageBase64(e.target.files as FileList);
              if (onImageFileChange) {
                onImageFileChange(
                  e.target.files ? e.target.files[0] : undefined
                );
              }
            }}
          />
          <PlusOutlined />
          <div className={styles.addImageUpload}>Upload</div>
        </div>
      )}
      {image && (
        <div
          className={cn([
            styles.root,
            styles.imageWrapper,
            styles.backgroundTransparentGraphic,
          ])}
        >
          <div className={styles.options}>
            <DeleteOutlined
              onClick={() => (onImageDelete ? onImageDelete(image.id) : null)}
            />
          </div>
          <Image className={styles.image} src={image.url} />
        </div>
      )}
      {!image && previewImage && (
        <div className={cn([styles.root, styles.imageWrapper])}>
          <div className={styles.options}>
            <DeleteOutlined
              onClick={() => {
                if (onImageFileChange) {
                  onImageFileChange();
                }
                if (setPreviewImage) {
                  setPreviewImage('');
                }
              }}
            />
          </div>
          <Image className={styles.image} src={previewImage} />
        </div>
      )}
    </>
  );
};

export default ImagePlaceholder;
