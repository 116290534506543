import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import { Permission } from 'utils/constants';
import PlanList from './PlanList';
import PlanDetails from './PlanDetails';

export default () => (
  <Switch>
    <Route exact path={Paths.PLANS} component={PlanList} />
    <Route path={`${Paths.PLANS}/page/:page`} component={PlanList} />
    <AuthenticatedRoute
      path={`${Paths.PLANS}/:id`}
      component={PlanDetails}
      permissions={[Permission.GET_PLAN]}
    />
  </Switch>
);
