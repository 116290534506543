import {
  AddPlatformPartnerPlan,
  CreatePlatformPartner,
  PlatformPartner,
  PlatformPartnerPlan,
} from 'types/platformPartners';
import { Pagination, pageToSkip } from 'utils/pagination';
import { IntervalType } from 'types/plan';
import api from './api';

interface PaginationRequest {
  page?: number;
  limit?: number;
}

export interface PlatformPartnerPrice {
  currency?: string;
  interval?: IntervalType;
  intervalMultiplier?: number;
  amount?: number;
  gateway?: string;
  externalProductId?: string;
  distributionChannels?: string[];
  uuid?: string;
}

export const getPlatformPartners = async ({
  page,
  limit,
}: PaginationRequest) => {
  const { data } = await api().get<Pagination<PlatformPartner>>(
    `/platform-partner`,
    {
      params: {
        skip: pageToSkip(page, limit),
        limit,
      },
    }
  );

  return data;
};

export const createPlatformPartner = async (payload: CreatePlatformPartner) => {
  const { data } = await api().post<PlatformPartner>(
    `/platform-partner`,
    payload
  );
  return data;
};

export const getPlatformPartner = async (uuid: string) => {
  const { data } = await api().get<PlatformPartner>(
    `/platform-partner/${uuid}`
  );

  return data;
};

export const updatePlatformPartner = async (
  uuid: string,
  payload: Partial<PlatformPartner>
) => {
  const { data } = await api().patch<PlatformPartner>(
    `/platform-partner/${uuid}`,
    payload
  );
  return data;
};

export const publishPlatformPartner = async (uuid: string) => {
  await api().put<void>(`/platform-partner/${uuid}/publish`);
};

export const unpublishPlatformPartner = async (uuid: string) => {
  await api().put<void>(`/platform-partner/${uuid}/unpublish`);
};

export const deletePlatformPartner = async (uuid: string) => {
  await api().delete<void>(`/platform-partner/${uuid}`);
};

export const addPlanToPlatformPartner = async (
  partnerUuid: string,
  payload: AddPlatformPartnerPlan
) => {
  const { data } = await api().post(
    `/platform-partner/${partnerUuid}/plan`,
    payload
  );
  return data;
};

export const getPlatformPartnerPlans = async (partnerUuid: string) => {
  const { data } = await api().get<PlatformPartnerPlan[]>(
    `/platform-partner/${partnerUuid}/plan`
  );
  return data;
};

export const updatePlatformPartnerPlan = async (
  partnerUuid: string,
  planUuid: string,
  payload: Partial<PlatformPartnerPlan>
) => {
  const { data } = await api().patch<PlatformPartner>(
    `/platform-partner/${partnerUuid}/plan/${planUuid}`,
    payload
  );
  return data;
};

export const deletePlatformPartnerPlan = async (
  partnerUuid: string,
  planUuid: string
) => {
  const { data } = await api().delete<void>(
    `/platform-partner/${partnerUuid}/plan/${planUuid}`
  );
  return data;
};
