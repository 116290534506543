import {
  useFetchAction,
  usePolling,
} from '@laminar-product/client-commons-core/hooks';
import { getTranscodedFiles } from 'actions/files';
import { Table } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import FileStatusTag from 'components/FileStatusTag';
import React, { useCallback } from 'react';
import { FileStatus, UploadedFile } from 'types';
import {
  useBoundToPages,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import moment from 'moment';

const { Column } = Table;

const AssignedFilesTable = ({ query }: { query?: string }) => {
  const page = usePageParam();

  const [pagination, isLoading, error, refresh] = useFetchAction(
    useCallback(
      () =>
        getTranscodedFiles({
          page,
          fileStatuses: [FileStatus.ASSIGNED],
          searchQuery: query,
        }),
      [page, query]
    )
  );

  usePolling(refresh);

  useBoundToPages(`/files/assigned`, pagination, page);

  return (
    <>
      <ErrorIndicator error={error} />
      <Table
        rowKey="id"
        dataSource={pagination?.data}
        loading={pagination?.data?.length === 0 && isLoading}
        pagination={usePaginationObject(
          '/files/assigned',
          pagination,
          page ?? 1
        )}
      >
        <Column title="File name" key="name" dataIndex="name" />
        <Column
          title="Status"
          key="status"
          dataIndex="status"
          render={(_, file: UploadedFile) => (
            <FileStatusTag file={{ ...file, status: FileStatus.ASSIGNED }} />
          )}
        />
        <Column
          title="Uploaded"
          key="uploadDate"
          dataIndex="uploadDate"
          render={(_, file: UploadedFile) => (
            <span>
              {file.createdAt
                ? moment(file.createdAt).format('YYYY-MM-DD hh:mm:ss')
                : ''}
            </span>
          )}
        />
        <Column title="Assigned asset" key="asset" dataIndex="asset" />
        <Column title="Action" key="action" />
      </Table>
    </>
  );
};

export default AssignedFilesTable;
