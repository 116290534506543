import React from 'react';
import { AssetStatus } from 'types';
import { Tag } from 'antd';
import formatLabel from 'utils/formatLabel';
import styles from './index.module.scss';

const statusToColor = {
  [AssetStatus.NEW]: 'blue',
  [AssetStatus.DRAFT]: undefined,
  [AssetStatus.PUBLISHED]: 'green',
  [AssetStatus.SCHEDULED]: 'blue',
  [AssetStatus.UNPUBLISHED]: undefined,
};

const AssetStatusTag = ({ status }: { status: AssetStatus }) => (
  <Tag className={styles.root} color={statusToColor[status]}>
    {formatLabel(status)}
  </Tag>
);

export default AssetStatusTag;
