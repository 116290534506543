import { PaymentGateway } from '@laminar-product/client-commons-core/core';

export enum TaxamoConfigOptions {
  ESTIMATE = 'estimate',
  REPORT_ON = 'reportOn',
  CAPTURE_BILLING_ADDRESS = 'captureBillingAddress',
  ADDRESS_VALIDATION = 'addressValidation',
}

export interface PaymentGatewayData {
  key: number;
  paymentGateway: PaymentGateway;
}

export interface TaxProduct {
  productTaxCode: string;
  resourceId: string;
  resourceType: TaxProductTypes;
}

export enum TaxProductTypes {
  PLAN = 'PLAN',
}

export enum TaxConfigurationFormValue {
  ENABLED = 'enabled',
  ESTIMATION_COUNTRIES = 'estimationCountries',
  ADDRESS_VALIDATION_COUNTRIES = 'addressValidationCountries',
  ESTIMATION_GATEWAYS = 'estimationGateways',
  STORAGE_COUNTRIES = 'storageCountries',
  STORAGE_GATEWAYS = 'storageGateways',
  BILLING_ADDRESS_COUNTRIES = 'billingAddressCountries',
  BILLING_ADDRESS_GATEWAYS = 'billingAddressGateways',
  TAX_CODES = 'taxCodes',
}

export interface TaxConfigurationForm {
  [TaxConfigurationFormValue.ENABLED]: boolean;
  [TaxConfigurationFormValue.ESTIMATION_COUNTRIES]: string[];
  [TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES]: string[];
  [TaxConfigurationFormValue.ESTIMATION_GATEWAYS]: PaymentGateway[];
  [TaxConfigurationFormValue.STORAGE_COUNTRIES]: string[];
  [TaxConfigurationFormValue.STORAGE_GATEWAYS]: PaymentGateway[];
  [TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES]: string[];
  [TaxConfigurationFormValue.BILLING_ADDRESS_GATEWAYS]: PaymentGateway[];
  [TaxConfigurationFormValue.TAX_CODES]: TaxProduct[];
}

export interface SetInitialDataProps {
  configurationData: TaxConfigurationForm;
}
