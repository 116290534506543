import FileQualityInfo from 'components/FileQualityInfo';
import React, { useEffect, useState } from 'react';
import { FileStatus, UploadedFile } from 'types';
import { Progress } from 'antd';
import styles from './index.module.scss';

export interface FileStatusTagProps {
  file: UploadedFile;
}

const FileStatusTag = ({ file }: FileStatusTagProps) => {
  const [jobProgress, setJobProgress] = useState<number>();

  useEffect(() => {
    const progress = file.transcodeJobProgress?.jobPercentComplete;
    if (progress) {
      setJobProgress(progress);
    }
  }, [file.transcodeJobProgress]);

  return (
    <div className={styles.root}>
      <span className={styles.marginRight}>{file.status}</span>
      {file.metadataError && (
        <FileQualityInfo
          metadataError={file.metadataError}
          status={file.status}
        />
      )}
      {file.status === FileStatus.PROCESSING && (
        <>
          <span className={styles.progressValue}>
            {jobProgress ? `(${jobProgress})%` : '(N/A)'}
          </span>

          {jobProgress && (
            <div className={styles.progress}>
              <Progress
                percent={Math.round(jobProgress)}
                strokeWidth={3}
                showInfo={false}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FileStatusTag;
