import { Moment } from 'moment';

export interface FeaturedAsset {
  defaultFeaturedAssetUuid: string;
  defaultFeaturedAssetForKidsUuid: string;
  regionIds: string[];
  assets: FeaturedAssetSource[];
  timezone: number;
  name: string;
  uuid: string;
  status: FeaturedAssetStatus;
  currentlyDisplayed?: FeaturedAssetSource;
  scheduledAssetsCount?: number;
}

export interface FeaturedAssetSource {
  sourceId: string; //sourceId is in fact assetUuid
  dateFrom?: number | null | Moment;
  dateTo?: number | null | Moment;
  name?: string; //BE is not sending name but it exists after adding to queue on FE, need to fetch separately
}

export enum FeaturedAssetStatus {
  CREATED = 'CREATED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export type QueuedAssetTableAvailability = 'dateFrom' | 'dateTo';

export const FEATURE_ASSETS_OVERLAPPING_ERROR_CODE =
  'featured_asset/overlapping_dates';

export const FEATURE_ASSETS_SOURCE_NOT_IN_REGION_ERROR_CODE =
  'featured_asset/passed_unprocessable_assets';

export type FeaturedAssetEditPayload = Omit<
  FeaturedAsset,
  '_id' | 'updatedAt' | 'status'
>;

export interface FeaturedAssetSourceEditProps {
  assetToEdit: FeaturedAssetSource;
  type: 'edit' | 'remove';
  source: 'current' | 'original';
}

export interface FeaturedAssetEditModeChangeProps {
  uuid: string;
  type: 'addToEdit' | 'removeFromEdit';
}
