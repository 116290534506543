import { Content } from 'types';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { Pagination, usePaginationObject } from 'utils/pagination';
import { Paths } from 'types/paths';

const { Column } = Table;

const ContentTable = ({
  pagination,
  loading,
  currentPage,
}: {
  pagination?: Pagination<Content>;
  loading?: boolean;
  currentPage: number;
}) => {
  const history = useHistory();

  return (
    <Table
      dataSource={pagination?.data}
      loading={loading}
      onRow={(content: Content) => ({
        onClick: () => history.push(`${Paths.CONTENT}/${content.id}`),
      })}
      rowClassName="clickable-row"
      rowKey="id"
      pagination={usePaginationObject('/content', pagination, currentPage)}
    >
      <Column title="Title" dataIndex="title" key="title" />
      <Column title="Type" dataIndex="type" key="type" />
    </Table>
  );
};

export default ContentTable;
