import {
  mapMenuPlatformToArray,
  mapMenuPlatformToObject,
} from 'utils/platform';
import { Menu, MenuResponse } from 'types/menu';
import { api } from './api';

const mappedMenu = (menu: Menu): MenuResponse => ({
  name: menu.name,
  regions: menu.regions,
  uuid: menu.uuid,
  views: mapMenuPlatformToArray(menu.platforms),
});

export const createMenu = async (menu: Menu): Promise<Menu> => {
  const { data } = await api().post<MenuResponse>(
    `/designer/menu`,
    mappedMenu(menu)
  );

  return { ...data, platforms: mapMenuPlatformToObject(data.views) };
};

export const updateMenu = async (menu: Menu): Promise<Menu> => {
  const { data } = await api().patch<MenuResponse>(
    `/designer/menu/${menu.uuid}`,
    mappedMenu(menu)
  );

  return { ...data, platforms: mapMenuPlatformToObject(data.views) };
};

export const getMenus = async (): Promise<Menu[]> => {
  const { data } = await api().get<MenuResponse[]>(`/designer/menu`);
  return data.map((d) => ({
    uuid: d.uuid,
    regions: d.regions,
    name: d.name,
    platforms: mapMenuPlatformToObject(d.views),
  }));
};

export const getMenu = async ({ uuid }: { uuid: string }): Promise<Menu> => {
  const { data } = await api().get<MenuResponse>(`/designer/menu/${uuid}`);

  return { ...data, platforms: mapMenuPlatformToObject(data.views) };
};

export const removeMenu = async ({
  uuid,
}: {
  uuid: string;
}): Promise<string> => {
  const { data } = await api().delete(`/designer/menu/${uuid}`);

  return data;
};
