import React from 'react';
import Suggestions from 'components/Suggestions';
import useAICast from 'hooks/useAICast';
import _differenceWith from 'lodash/differenceWith';
import { AssetCrew } from 'types';
import styles from './AssetCrewSuggestions.module.scss';

interface AssetCrewSuggestionsProps {
  onClick: (crewName: string) => void;
  celebritiesToOmit: AssetCrew[];
}

const AssetCrewSuggestions = ({
  onClick,
  celebritiesToOmit,
}: AssetCrewSuggestionsProps) => {
  const assetCelebrities = useAICast();

  const celebritiesList = assetCelebrities
    ? _differenceWith(
        Object.keys(assetCelebrities),
        celebritiesToOmit,
        (assetC, cToOmit) => assetC === cToOmit.name
      )
    : [];

  return (
    <div className={styles.root}>
      <span className={styles.heading}>Suggestions</span>
      <div className={styles.suggestionsWrapper}>
        <Suggestions options={celebritiesList} onAdd={onClick} />
      </div>
    </div>
  );
};

export default AssetCrewSuggestions;
