import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Movie, Show } from '@laminar-product/client-commons-core/web';
import { PlaySquareOutlined } from '@ant-design/icons';
import { useAssetDetailsContext } from 'contexts/assetContext';
import LanguageChooser from 'components/LanguageChooser';
import { AssetMetadata } from 'types';
import {
  Asset as AssetCore,
  AssetLocationState,
  AssetType,
  ContentType,
  MovieConfig,
  ShowConfig,
} from '@laminar-product/client-commons-core/core';
import { getAssetContents, getAssetImages } from 'actions/assets';
import { getAssetCrew } from 'actions/crew';
import { getContentFile } from 'actions/content';
import StickyHeader from 'components/StickyHeader';
import { useLocation } from 'react-router-dom';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import styles from './index.module.scss';

const AssetPreview = () => {
  // TODO: Store current asset data in redux and use it here [LPD-1642]
  const { assetDraft } = useAssetDetailsContext();
  const { name, translations, id, type } = assetDraft;
  const [contents] = useFetchAction(
    useCallback(() => getAssetContents({ id }), [id])
  );
  const action = useCallback(
    () =>
      getAssetImages({
        id,
      }),
    [id]
  );
  const [images] = useFetchAction(action);
  const [downloadedCrew] = useFetchAction(
    useCallback(() => getAssetCrew({ id }), [id])
  );
  const [duration, setDuration] = useState<number>(0);
  const location = useLocation<AssetLocationState>();

  // Check for content types to determine wether to display "Watch" and "Trailer" buttons
  const contentTypes = contents?.reduce((acc, currVal) => {
    if (currVal.type) acc.push({ type: currVal.type, id: currVal.id });
    return acc;
  }, [] as any);

  const setContentDuration = useCallback(
    (id: number) =>
      getContentFile({ id }).then((res) => {
        setDuration(res.duration || 0);
      }),
    []
  );

  useEffect(() => {
    const mainContent = contents?.find(
      (content) => content.type === ContentType.MAIN
    );

    mainContent && setContentDuration(mainContent.id);
  }, [contents, setContentDuration]);

  const defaultLanguageCode = useMemo(
    () => translations && Object.keys(translations).find((key) => key === 'en'),
    [translations]
  );
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<
    string | undefined
  >(defaultLanguageCode);

  const getTranslation = (key: keyof AssetMetadata) => {
    if (translations && selectedLanguageCode) {
      if (translations[selectedLanguageCode])
        return translations[selectedLanguageCode][key];
    }
  };

  const changeLanguageHandler = (lang: string) => setSelectedLanguageCode(lang);

  const movieConfig: MovieConfig = {
    isPremium: true,
    previewMode: false,
    moveToSearch: () => {},
  };

  const showConfig: ShowConfig = {
    ...movieConfig,
    location,
  };

  const assetData: AssetCore = {
    ...assetDraft,
    id: String(assetDraft.id),
    parentId: String(assetDraft.parentId),
    name: getTranslation('name') || assetDraft.name,
    description: getTranslation('description') || assetDraft.description,
    year: assetDraft.year,
    contents: contentTypes || [],
    crew: (downloadedCrew as any) || [],
    images: images || [],
    duration,
    administrativeName: '',
    children: [],
  };

  const renderPreview = () => {
    switch (type) {
      case AssetType.MOVIE:
        return (
          <Movie
            asset={assetData}
            config={movieConfig}
            user={{} as any}
            openAssetInApp={() => {}}
          /> // Empty object to show user how asset will be look as on client ( with action buttons )
        );
      case AssetType.SHOW:
        return (
          <Show
            asset={assetData}
            config={showConfig}
            user={{} as any}
            openAssetInApp={() => {}}
          />
        );
      default:
        return (
          <div className={styles.typeError}>Unsupported type of asset</div>
        );
    }
  };

  return (
    <>
      <StickyHeader hasBadge>
        <div className={styles.wrapper}>
          <div className={styles.nameWrapper}>
            <PlaySquareOutlined
              style={{ color: '#0074ff', fontSize: '22px' }}
            />
            <span className={styles.name}>{name}</span>
          </div>
        </div>
        {translations && selectedLanguageCode && (
          <LanguageChooser
            className={styles.languageSelector}
            onChoose={changeLanguageHandler}
            chosenLanguageCode={selectedLanguageCode}
            codesToExclude={Object.keys(translations)}
            showSearch={false}
          />
        )}
      </StickyHeader>
      <div className={styles.preview}>{renderPreview()}</div>
    </>
  );
};

export default AssetPreview;
