import React from 'react';
import { Select as AntSelect } from 'antd';
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select';

interface SelectProps<ValueType> extends AntSelectProps<ValueType> {
  selectMode?: 'multiple' | 'tags' | undefined;
}

/**
 * This Select is a 1:1 copy of Ant Design Select, just with "mode" props renamed to "selectMode" to be
 * able to use it with react-hook-form's Controller.
 */
const Select = <ValueType extends SelectValue = SelectValue>({
  selectMode,
  ...props
}: SelectProps<ValueType>) => (
  <AntSelect style={{ width: '100%' }} mode={selectMode} {...props} />
);

export const Option = AntSelect.Option;
export const OptionGroup = AntSelect.OptGroup;

export default Select;
