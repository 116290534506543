import countries from 'locales/en/countries.json';
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestAction } from 'store/app/actions';
import { RequestStatuses } from 'store/app/types';
import { ApiActionType, Region } from 'types';
import { getType } from 'typesafe-actions';
import { captureError } from 'utils/captureError';
import { notUndefined } from 'utils/helpers';
import {
  fetchCountriesAction,
  fetchRegionsAction,
  sendRegionAction,
} from './actions';
import {
  createRegion,
  getAvailableCountries,
  getRegions,
  updateRegion,
} from './service';

export function* fetchCountriesSaga() {
  try {
    yield put(
      requestAction({
        action: getType(fetchCountriesAction.request),
        status: RequestStatuses.PENDING,
      })
    );

    const data: string[] = yield call(getAvailableCountries);
    const countriesWithNames = data
      .map((c) => countries.find((country) => country.code === c))
      .filter(notUndefined);
    yield put(fetchCountriesAction.success(countriesWithNames));
    yield put(
      requestAction({
        action: getType(fetchCountriesAction.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (err) {
    yield put(fetchCountriesAction.failure(err as any));
    yield put(
      requestAction({
        action: getType(fetchCountriesAction.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(err as any);
  }
}

export function* fetchRegionsSaga() {
  try {
    yield put(
      requestAction({
        action: getType(fetchRegionsAction.request),
        status: RequestStatuses.PENDING,
      })
    );

    const data: Region[] = yield call(getRegions);

    yield put(fetchRegionsAction.success(data));
    yield put(
      requestAction({
        action: getType(fetchRegionsAction.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (err) {
    yield put(fetchRegionsAction.failure(err as any));
    yield put(
      requestAction({
        action: getType(fetchRegionsAction.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(err as any);
  }
}

export function* sendRegionSaga({
  payload,
}: ReturnType<typeof sendRegionAction.request>) {
  try {
    const { form, type, regionId } = payload;
    if (!type) return;

    yield put(
      requestAction({
        action: getType(sendRegionAction.request),
        status: RequestStatuses.PENDING,
      })
    );

    const data: Region =
      type === ApiActionType.UPDATE && regionId
        ? yield call(updateRegion, { form, regionId })
        : yield call(createRegion, { form });

    yield put(sendRegionAction.success({ region: data, type }));
    yield put(
      requestAction({
        action: getType(sendRegionAction.request),
        status: RequestStatuses.RESOLVED,
      })
    );
    yield put(fetchCountriesAction.request());
  } catch (err) {
    yield put(sendRegionAction.failure(err as any));
    yield put(
      requestAction({
        action: getType(sendRegionAction.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(err as any);
  }
}

export default function* () {
  yield takeLatest(fetchCountriesAction.request, fetchCountriesSaga);
  yield takeLatest(fetchRegionsAction.request, fetchRegionsSaga);
  yield takeLatest(sendRegionAction.request, sendRegionSaga);
}
