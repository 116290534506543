import { rootAssetTypes } from 'constants/assetTypes';
import React, { useEffect, useState } from 'react';
import { Input, Radio, Switch } from 'antd';
import FormGroup from 'components/FormGroup';
import Select from 'components/Select';
import { Controller, useFormContext } from 'react-hook-form';
import { Region } from 'types';
import filterOptionsUsingLabels from 'utils/filterOptionsUsingLabels';
import styles from './index.module.scss';

interface PageGeneralDetailsFormProps {
  hasAssetType?: boolean;
  regions?: Region[];
}

const PageGeneralDetailsForm = ({
  hasAssetType,
  regions,
}: PageGeneralDetailsFormProps) => {
  const { errors, control } = useFormContext();
  const [pageType, setPageType] = useState<'customPage' | 'assetTypePage'>(
    'customPage'
  );
  const regionsOptions = regions?.map((r) => ({
    value: r.uuid,
    label: r.name,
  }));

  useEffect(() => {
    if (hasAssetType) {
      setPageType('assetTypePage');
    }
  }, [hasAssetType]);

  return (
    <>
      <FormGroup
        errorMessage={errors.name?.message}
        label="Administrative name"
        input={
          <Controller
            data-testid="PageGeneralDetailsForm__name_input"
            as={Input}
            control={control}
            name="name"
            rules={{ required: 'Please provide name' }}
          />
        }
      />

      <FormGroup
        label="Regions"
        errorMessage={errors.regions?.message}
        input={
          <Controller
            name="regions"
            placeholder="Regions"
            selectMode="multiple"
            control={control}
            rules={{ required: 'Please select at least one region' }}
            allowClear
            labelInValue
            className={styles.select}
            options={regionsOptions}
            as={Select}
            filterOption={filterOptionsUsingLabels}
          />
        }
      />

      <FormGroup
        label="Display page title"
        input={
          <Controller
            defaultValue={false}
            control={control}
            name="showTitle"
            as={({ value, onChange }) => (
              <Switch onChange={onChange} checked={value} />
            )}
          />
        }
      />

      <FormGroup
        label="Page type"
        input={
          <Radio.Group
            value={pageType}
            onChange={(e) => setPageType(e.target.value)}
          >
            <Radio value="customPage">Page</Radio>
            <Radio value="assetTypePage">Asset type page</Radio>
          </Radio.Group>
        }
      />
      {pageType === 'assetTypePage' && (
        <FormGroup
          label="Asset type"
          errorMessage={errors.assetType?.message}
          input={
            <Controller
              as={Select}
              name="assetType"
              placeholder="Asset types"
              className={styles.select}
              control={control}
              options={rootAssetTypes}
              labelInValue
              rules={{ required: 'Please select asset type' }}
            />
          }
        />
      )}
    </>
  );
};

export default PageGeneralDetailsForm;
