import React from 'react';
import { ImageStatus } from 'types';
import { Tag } from 'antd';
import formatLabel from 'utils/formatLabel';

const statusToColor = {
  [ImageStatus.CREATED]: 'blue',
  [ImageStatus.TRANSFORMED]: 'green',
  [ImageStatus.QUALITY_ERROR]: 'red',
};

const ImageStatusTag = ({ status }: { status: ImageStatus }) => (
  <Tag color={statusToColor[status]}>{formatLabel(status)}</Tag>
);

export default ImageStatusTag;
