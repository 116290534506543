import React from 'react';
import { Input, InputNumber, Radio } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { DiscountType } from 'types/promotions';
import { promotionTypes } from 'utils/constants';
import styles from './index.module.scss';

const PromotionsGeneralDetails = () => {
  const { errors, control, watch } = useFormContext();

  return (
    <>
      <FormGroup
        errorMessage={errors.name?.message}
        label="Administrative name"
        input={
          <Controller
            defaultValue=""
            as={<Input placeholder="Administrative name" />}
            control={control}
            name="name"
            rules={{ required: 'Please provide name' }}
          />
        }
      />

      <FormGroup
        label="Type"
        input={
          <Controller
            render={(field) => (
              <Radio.Group
                className={styles.flex}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                options={promotionTypes}
              />
            )}
            name="discountType"
            defaultValue={promotionTypes[0].value}
            control={control}
          />
        }
      />

      {watch('discountType') === DiscountType.PERIOD && (
        <FormGroup
          errorMessage={errors.amount?.message}
          label="Period (days)"
          input={
            <Controller
              as={
                <InputNumber placeholder="Period" type="number" precision={0} />
              }
              defaultValue={0}
              min={0}
              control={control}
              name="amount"
              rules={{ required: 'Please enter correct period value' }}
            />
          }
        />
      )}

      {watch('discountType') === DiscountType.PERCENTAGE && (
        <FormGroup
          errorMessage={errors.amount?.message}
          label="Discount percentage (%)"
          input={
            <Controller
              as={
                <InputNumber
                  placeholder="Value"
                  type="number"
                  precision={0}
                  min={0}
                  max={100}
                />
              }
              defaultValue={0}
              control={control}
              name="amount"
              rules={{ required: 'Please enter correct discount value' }}
            />
          }
        />
      )}

      {watch('discountType') === DiscountType.AMOUNT && (
        <FormGroup
          errorMessage={errors.amount?.message}
          label="Discount amount"
          input={
            <Controller
              as={
                <InputNumber
                  precision={2}
                  placeholder="Value"
                  type="number"
                  min={0}
                />
              }
              defaultValue={0}
              control={control}
              name="amount"
              rules={{ required: 'Please enter correct discount value' }}
            />
          }
        />
      )}
    </>
  );
};

export default PromotionsGeneralDetails;
