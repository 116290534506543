import React, { useEffect, useState } from 'react';
import { Collection, CollectionRegion } from 'types';
import { Alert, Form, Input, Select } from 'antd';
import FormGroup from 'components/FormGroup';
import { InfoCircleOutlined } from '@ant-design/icons';
import PageCard from 'components/PageCard';
import filterOptionsUsingLabels from 'utils/filterOptionsUsingLabels';
import { useGetRegions } from 'hooks/useRegions';

const CollectionGeneralDetails = ({
  collection,
  onCollectionChange,
  shouldValidate,
}: {
  collection: Collection;
  onCollectionChange: (collection: Collection) => void;
  shouldValidate: boolean;
}) => {
  const { regions: fetchedRegions } = useGetRegions();
  const [error, setError] = useState<string>();

  const { name, regions } = collection;

  useEffect(() => {
    if ((!name || !regions?.length) && shouldValidate) {
      setError('All fields are required.');
      return;
    }
    setError('');
  }, [shouldValidate, name, regions]);

  return (
    <PageCard title="General details" icon={<InfoCircleOutlined />}>
      <Form layout="horizontal" labelCol={{ span: 6 }}>
        <FormGroup
          label="Administrative name"
          input={
            <Input
              name="name"
              placeholder="Collection"
              value={collection?.name}
              onChange={(e) =>
                onCollectionChange({
                  ...collection,
                  name: e.target.value,
                })
              }
            />
          }
        />
        <FormGroup
          label="Regions"
          input={
            <Select<string[]>
              style={{ width: '100%' }}
              mode="multiple"
              allowClear
              placeholder="Regions"
              value={regions}
              filterOption={filterOptionsUsingLabels}
              options={fetchedRegions?.map((r) => ({
                value: r.uuid,
                label: r.name,
              }))}
              onChange={(options) => {
                const newRegions = options
                  .map((regionUuid) =>
                    fetchedRegions?.find((r) => r.uuid === regionUuid)
                  )
                  .filter((r) => r)
                  .map((r) => ({
                    name: r?.name,
                    uuid: r?.uuid,
                  })) as CollectionRegion[];

                if (collection)
                  onCollectionChange({
                    ...collection,
                    regions: newRegions.map((r) => r.uuid) as string[],
                  });
              }}
            />
          }
        />
      </Form>
      {error && <Alert message={error} type="error" />}
    </PageCard>
  );
};

export default CollectionGeneralDetails;
