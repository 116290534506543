import React from 'react';
import { FileStatus } from 'types';
import { Popover, Table } from 'antd';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons';

const { Column } = Table;

export interface FileQualityInfoProps {
  status: FileStatus;
  metadataError: any[];
}

const FileQualityInfo = ({ status, metadataError }: FileQualityInfoProps) => {
  let errors: React.ReactNode | undefined;

  if (metadataError && metadataError.length !== 0)
    errors = (
      <Table dataSource={metadataError} pagination={false} size="small">
        <Column title="Type" dataIndex="type" key="type" />
        <Column title="Expected" dataIndex="expected" key="expected" />
        <Column title="Provided" dataIndex="provided" key="provided" />
      </Table>
    );

  if (status === FileStatus.QUALITY_PASSED)
    return <CheckCircleFilled style={{ color: '#30A80C' }} />;

  if (status === FileStatus.QUALITY_WARNING) {
    return errors ? (
      <Popover
        placement="top"
        title={`Warnings`}
        content={errors}
        trigger="click"
      >
        <InfoCircleFilled style={{ color: '#E0D30B' }} />
      </Popover>
    ) : (
      <InfoCircleFilled style={{ color: '#E0D30B' }} />
    );
  }

  if (status === FileStatus.QUALITY_ERROR) {
    return errors ? (
      <Popover
        placement="top"
        title={`Errors`}
        content={errors}
        trigger="click"
      >
        <ExclamationCircleFilled style={{ color: '#E6350F' }} />
      </Popover>
    ) : (
      <ExclamationCircleFilled style={{ color: '#E6350F' }} />
    );
  }

  return null;
};

export default FileQualityInfo;
