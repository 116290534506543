import { CancelToken } from 'axios';
import {
  Channel,
  Content,
  ContentCreateForm,
  Metadata,
  UploadedFile,
} from 'types';
import { PAGINATION_SIZE, Pagination, pageToSkip } from 'utils/pagination';
import api from './api';

export const getContentsPagination = async (
  page?: number,
  query?: string
): Promise<Pagination<Content>> => {
  const { data } = await api().get(`/content`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      title: query,
    },
  });

  return data;
};

export const searchForContent = async ({
  cancelToken,
  title,
  hasFile,
  hasAsset,
}: {
  cancelToken?: CancelToken;
  title: string;
  hasFile?: boolean;
  hasAsset?: boolean;
}): Promise<Content[]> => {
  const { data } = await api().get(`/content`, {
    params: { title, hasAsset, hasTranscoded: hasFile },
    cancelToken,
  });

  return data?.data;
};

export const getContentDetails = async ({
  id,
  cancelToken,
}: {
  cancelToken?: CancelToken;
  id: string | number;
}): Promise<Content> => {
  const { data } = await api().get(`/content/${id}`, {
    cancelToken,
  });

  return data as Content;
};

export const createContent = async ({
  cancelToken,
  form,
}: {
  cancelToken?: CancelToken;
  form: ContentCreateForm;
}): Promise<Content> => {
  const { data } = await api().post(`/content`, form, {
    cancelToken,
  });

  return data;
};

export const assignAssetToContent = async ({
  cancelToken,
  id,
  assetId,
}: {
  id: string | number;
  assetId: string | number;
  cancelToken?: CancelToken;
}): Promise<Content> => {
  const { data } = await api().put<Content>(
    `/content/${id}/asset`,
    { id: assetId },
    {
      cancelToken,
    }
  );

  return data;
};

export const unassignAssetToContent = async ({
  cancelToken,
  id,
}: {
  id: number;
  cancelToken?: CancelToken;
}): Promise<Content> => {
  const { data } = await api().delete(`/content/${id}/asset`, {
    cancelToken,
  });

  return data as Content;
};

export const getContentFile = async ({
  id,
  cancelToken,
}: {
  cancelToken?: CancelToken;
  id: string | number;
}): Promise<UploadedFile> => {
  const { data } = await api().get(`/content/${id}/transcoded`, {
    cancelToken,
  });

  return data as UploadedFile;
};

export const getContentChannel = async ({
  id,
  cancelToken,
}: {
  cancelToken?: CancelToken;
  id: string | number;
}): Promise<Channel> => {
  const { data } = await api().get(`/content/${id}/live`, {
    cancelToken,
  });

  return data as Channel;
};

export const updateMetadata = async ({
  id,
  metadata,
  cancelToken,
}: {
  id: string | number;
  metadata: Metadata;
  cancelToken?: CancelToken;
}): Promise<Metadata> => {
  const { data } = await api().patch(`/content/${id}/metadata`, metadata, {
    cancelToken,
  });

  return data;
};

export const updateContent = async ({
  id,
  data,
}: {
  id: number;
  data: Content;
}): Promise<void> => await api().put(`/content/${id}`, data);

export const updateType = async ({
  id,
  type,
  cancelToken,
}: {
  id: string | number;
  type: string;
  cancelToken?: CancelToken;
}): Promise<Content> => {
  const { data } = await api().put(
    `/content/${id}`,
    { type },
    {
      cancelToken,
    }
  );

  return data;
};

export const getTranscodedMP4File = async (
  id: number
): Promise<{ url: string }> => {
  const { data } = await api().get(`/transcoded/${id}/mp4`);

  return data;
};
