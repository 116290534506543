import React, { useCallback, useEffect, useState } from 'react';
import { Pagination } from 'types';
import { Select, Spin } from 'antd';
import {
  useDebounce,
  useFetchActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { FeaturedAsset, FeaturedAssetStatus } from 'types/featuredAsset';
import {
  getFeaturedAssetDetails,
  getFeaturedAssetsPagination,
} from 'actions/featuredAsset';
import { captureError } from 'utils/captureError';

const { Option } = Select;

interface FeaturedAssetChooserProps {
  regionsUuids?: string[];
  chosenUuid?: string;
  onChoose: (uuid: string) => void;
}

const FeaturedAssetChooser = ({
  regionsUuids,
  chosenUuid,
  onChoose,
}: FeaturedAssetChooserProps) => {
  const [internalChosen, setInternalChosen] = useState<FeaturedAsset>();
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 300);

  const { data: pagination, isLoading } = useFetchActionObj<
    Pagination<FeaturedAsset>
  >(
    useCallback(
      () =>
        getFeaturedAssetsPagination({
          name: queryDebounced,
          regions: regionsUuids,
          statuses: [FeaturedAssetStatus.PUBLISHED],
        }),
      [queryDebounced, regionsUuids]
    )
  );

  const onChosenChange = useCallback(
    (featuredAsset: FeaturedAsset) => {
      setInternalChosen(featuredAsset);
      onChoose(featuredAsset.uuid);
    },
    [onChoose]
  );

  useEffect(() => {
    // If chosen uuid is passed down, but our internal value is empty then fetch the asset
    if (!chosenUuid || internalChosen) {
      return;
    }

    getFeaturedAssetDetails(chosenUuid)
      .then((f) => setInternalChosen(f))
      .catch(captureError);
  }, [chosenUuid, internalChosen]);

  return (
    <Select
      showSearch
      value={internalChosen?.uuid}
      notFoundContent={isLoading ? <Spin size="small" /> : 'No results'}
      onSearch={setQuery}
      onChange={(uuid: string) => {
        onChosenChange(pagination?.data?.find((f) => f.uuid === uuid)!);
      }}
      allowClear
      placeholder="Select featured asset"
    >
      {pagination?.data?.map((featuredAsset) => (
        <Option key={featuredAsset.uuid} value={featuredAsset.uuid}>
          {featuredAsset.name}
        </Option>
      ))}
    </Select>
  );
};

export default FeaturedAssetChooser;
