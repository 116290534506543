import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import { getAvailableStreamingProfiles, transcode } from 'actions/files';
import { Modal, Skeleton } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import Space from 'components/Space';
import StreamingProfileChooser from 'components/StreamingProfileChooser';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TranscodeAudioTrack, TranscodeSubtitle, UploadedFile } from 'types';
import AudioTrackForm from './AudioTrackForm';
import SubtitleTrackForm from './SubtitleTrackForm';
import useTransformedSourceTracks from './useTransformedSourceTracks';

export interface FileTranscodeModalProps {
  file?: UploadedFile;
  onCancel: () => void;
  onTranscode: () => void;
}

interface TranscodeParams {
  file: UploadedFile;
  audioTracks: TranscodeAudioTrack[];
  subtitles: TranscodeSubtitle[];
  streamingProfileId: string;
}

const FileTranscodeModal = ({
  file,
  onCancel,
  onTranscode,
}: FileTranscodeModalProps) => {
  const transformedSourceTracks = useTransformedSourceTracks(file);
  const [streamingProfiles] = useFetchAction(getAvailableStreamingProfiles);

  const [startTranscode, isProcessing, , , error] = useSendAction(
    useCallback(
      ({ file, audioTracks, subtitles, streamingProfileId }: TranscodeParams) =>
        transcode(file, audioTracks, subtitles, streamingProfileId),
      []
    ),
    { onDone: onTranscode }
  );

  const { control, handleSubmit, reset, errors, getValues, setValue } =
    useForm<{
      audioTracks: TranscodeAudioTrack[];
      subtitles: TranscodeSubtitle[];
      streamingProfileId: string;
    }>();

  const submit = handleSubmit(
    ({ audioTracks, subtitles, streamingProfileId }) =>
      startTranscode({
        audioTracks,
        subtitles,
        file: file!,
        streamingProfileId,
      })
  );

  const mapAudioDefaultTrack = (
    audio: TranscodeAudioTrack[],
    defaultAudioTrackStreamOrder?: number
  ) =>
    audio.map((a) => ({
      ...a,
      isDefault: a.streamOrder === defaultAudioTrackStreamOrder,
    }));

  const resetFormWithDefaultTrack = useCallback(
    (defaultAudioTrackStreamOrder: number) => {
      const { audioTracks } = getValues();

      const mappedAudio = mapAudioDefaultTrack(
        audioTracks,
        defaultAudioTrackStreamOrder
      );

      setValue('audioTracks', mappedAudio);
    },
    [getValues, setValue]
  );

  useEffect(() => {
    const { audio, subtitles } = transformedSourceTracks;

    const defaultAudioTrack = audio.find((a) => a.isDefault);

    const mappedAudio = mapAudioDefaultTrack(
      audio,
      !!defaultAudioTrack
        ? defaultAudioTrack.streamOrder
        : audio[0]?.streamOrder
    );

    reset({ subtitles, audioTracks: mappedAudio });
  }, [reset, transformedSourceTracks]);

  return (
    <Modal
      title="Transcode file"
      visible={file !== undefined}
      onOk={() => {
        if (file) submit();
      }}
      okText="Transcode"
      okButtonProps={{ loading: isProcessing }}
      onCancel={onCancel}
      width={900}
    >
      <Space direction="vertical">
        <h2>Streaming profile</h2>
        {streamingProfiles ? (
          <Controller
            render={(field) => (
              <StreamingProfileChooser
                value={field.value}
                onChoose={field.onChange}
                data={streamingProfiles}
              />
            )}
            control={control}
            name="streamingProfileId"
            defaultValue={streamingProfiles?.find((p) => p.default)?.id}
          />
        ) : (
          <Skeleton />
        )}
        <h2>Audio tracks</h2>
        {transformedSourceTracks.audio.length > 0 ? (
          <AudioTrackForm
            control={control}
            errors={errors}
            transformedSourceTracks={transformedSourceTracks.audio}
            onDefaultAudioTrackChange={resetFormWithDefaultTrack}
          />
        ) : (
          <Skeleton />
        )}
        {transformedSourceTracks.subtitles.length > 0 && (
          <>
            <h2>Subtitles</h2>
            <SubtitleTrackForm
              control={control}
              errors={errors}
              transformedSourceTracks={transformedSourceTracks.subtitles}
            />
          </>
        )}
        <ErrorIndicator error={error} />
      </Space>
    </Modal>
  );
};

export default FileTranscodeModal;
