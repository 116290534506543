import { ReactNode, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Alert, Button, Collapse, Modal } from 'antd';
import PageCard from 'components/PageCard';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { useAllLanguages } from 'hooks/useLanguages';
import styles from './index.module.scss';
import PublicDetailsForm from './PublicDetailsForm';

export interface BasePublicDetails {
  selectedLanguage?: string;
}
interface PublicDetailsProps<T extends FieldValues> {
  items?: {
    [lang: string]: T;
  };
  renderLanguageDetails: (langDetails: T) => ReactNode;
  renderLanguageForm: (
    control: Control<T>,
    error?: FieldErrors<T>,
    langCode?: string
  ) => ReactNode;
  onSaveLanguage: (lang: string, details: T) => void;
  onRemoveLanguage?: (lang: string) => void;
  showErrors?: boolean;
  disableAddButton?: boolean;
  canRemove?: boolean;
}

const PublicDetails = <T extends FieldValues>({
  items = {},
  renderLanguageForm,
  renderLanguageDetails,
  onRemoveLanguage,
  onSaveLanguage,
  showErrors,
  disableAddButton,
  canRemove = true,
}: PublicDetailsProps<T>) => {
  const [detailsModalVisible, toggleDetailsModalVisibility] = useState(false);
  const [currentItem, setCurrentItem] = useState<T & BasePublicDetails>();
  const selectedLanguages = items ? Object.keys(items) : [];
  const hasDefaultLanguage = selectedLanguages.includes('en');
  const { getLanguageNameByIsoCode } = useAllLanguages();

  return (
    <>
      <PageCard
        title="Public details"
        icon={<GlobalOutlined />}
        extra={
          <Button
            disabled={disableAddButton}
            type="primary"
            ghost
            icon={<PlusOutlined />}
            onClick={() => {
              toggleDetailsModalVisibility(true);
              setCurrentItem(undefined);
            }}
          >
            Add language
          </Button>
        }
      >
        {selectedLanguages?.length ? (
          <Collapse expandIconPosition="right">
            {selectedLanguages.map((lang: string) => (
              <Collapse.Panel
                header={getLanguageNameByIsoCode(lang)}
                key={lang}
                extra={
                  <div className={styles.collapseExtras}>
                    {canRemove && (
                      <DeleteOutlined
                        onClick={(event) => {
                          event.stopPropagation();
                          if (onRemoveLanguage) onRemoveLanguage(lang);
                        }}
                      />
                    )}
                    <EditOutlined
                      onClick={(event) => {
                        event.stopPropagation();
                        setCurrentItem({
                          selectedLanguage: lang,
                          ...items[lang],
                        });
                        toggleDetailsModalVisibility(true);
                      }}
                    />
                  </div>
                }
              >
                {renderLanguageDetails(items[lang])}
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : null}
        {!hasDefaultLanguage && (
          <Alert
            type={selectedLanguages.length || showErrors ? 'error' : 'info'}
            message={
              selectedLanguages.length || showErrors
                ? 'Add public details in English to publish.'
                : 'Add public details at least in English to publish.'
            }
          />
        )}
      </PageCard>

      <Modal
        visible={detailsModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => toggleDetailsModalVisibility(false)}
        destroyOnClose
        footer={null}
        title={!currentItem ? 'Add translation' : 'Edit translation'}
      >
        <PublicDetailsForm<T>
          renderLanguageForm={renderLanguageForm}
          onFormSubmit={(values) =>
            onSaveLanguage(values.selectedLanguage!, values as T)
          }
          onCancel={() => toggleDetailsModalVisibility(false)}
          langDetails={currentItem}
          selectedLanguages={selectedLanguages.filter(
            (l) => l !== currentItem?.selectedLanguage
          )}
        />
      </Modal>
    </>
  );
};

export default PublicDetails;
