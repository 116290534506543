export const videoPlayerButtonsOrder = [
  'PlayToggle',
  'VolumePanel',
  'DurationDisplay',
  'ProgressControl',
  'LiveDisplay',
  'SeekToLive',
  'RemainingTimeDisplay',
  'CustomControlSpacer',
  'DescriptionsButton',
  'FullscreenToggle',
  'PictureInPictureToggle',
];
