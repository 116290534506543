import { Select, Spin } from 'antd';
import { useGetRegions } from 'hooks/useRegions';
import React from 'react';
import { Region } from 'types';
import styles from './index.module.scss';
const { Option } = Select;

interface RegionSelectorProps {
  onChoose: (region: Region) => void;
  placeholder?: string;
  activeRegionUuid?: string;
  allowClear?: boolean;
}

const RegionSelector = ({
  onChoose,
  activeRegionUuid,
  placeholder = 'Please select region',
  allowClear = true,
}: RegionSelectorProps) => {
  const { regions, isLoadingRegions } = useGetRegions();

  const onRegionSelect = (regionUuid: string) => {
    const selectedRegion = regions?.find(
      (region) => region?.uuid === regionUuid
    );

    if (!!selectedRegion) {
      onChoose(selectedRegion);
    }
  };

  return (
    <Select
      className={styles.fullWidth}
      allowClear={allowClear}
      placeholder={placeholder}
      value={activeRegionUuid}
      notFoundContent={isLoadingRegions ? <Spin size="small" /> : null}
      onChange={onRegionSelect}
    >
      {regions?.map((region: Region) => (
        <Option key={region.uuid} value={region.uuid}>
          {region.name}
        </Option>
      ))}
    </Select>
  );
};

export default RegionSelector;
