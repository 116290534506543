import { UserAddOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import React from 'react';
import styles from './index.module.scss';

interface AddReferenceSnapshotProps {
  onTakeSnapshot: () => void;
}

const AddReferenceSnapshot = ({
  onTakeSnapshot,
}: AddReferenceSnapshotProps) => {
  return (
    <div className={styles.detailsContainer}>
      <div>Image</div>
      <h1 className={styles.heading}>Add reference frame</h1>
      <p className={styles.description}>
        Select frame in the video and take a snapshot to train the AI model. You
        can add multiple frames and analyze them together.
      </p>
      <Button
        type="primary"
        icon={<UserAddOutlined />}
        onClick={onTakeSnapshot}
      >
        Take a snapshot
      </Button>
    </div>
  );
};

export default AddReferenceSnapshot;
