import { call, delay, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { requestAction } from 'store/app/actions';
import { RequestStatuses } from 'store/app/types';
import { ApiActionType } from 'types';
import { ListingPageTranslation } from 'types/translations';
import { captureError } from 'utils/captureError';
import { errorNotify } from 'utils/errorNotify';
import {
  archiveDictionaryAction,
  fetchDictionaryAction,
  sendDictionaryAction,
} from './actions';
import {
  addGenre,
  addModeration,
  addMood,
  archiveGenre,
  archiveModeration,
  archiveMood,
  getGenres,
  getModerations,
  getMoods,
  getTags,
  updateGenre,
  updateModeration,
  updateMood,
} from './service';
import { DictionaryType } from './types';

const getAction = (
  dictionaryType: DictionaryType,
  actionType: ApiActionType
) => {
  switch (true) {
    // GENRES ACTIONS
    case actionType === ApiActionType.FETCH && dictionaryType === 'genres':
      return getGenres;
    case actionType === ApiActionType.CREATE && dictionaryType === 'genres':
      return addGenre;
    case actionType === ApiActionType.UPDATE && dictionaryType === 'genres':
      return updateGenre;
    case actionType === ApiActionType.DELETE && dictionaryType === 'genres':
      return archiveGenre;
    // MOODS ACTIONS
    case actionType === ApiActionType.FETCH && dictionaryType === 'moods':
      return getMoods;
    case actionType === ApiActionType.CREATE && dictionaryType === 'moods':
      return addMood;
    case actionType === ApiActionType.UPDATE && dictionaryType === 'moods':
      return updateMood;
    case actionType === ApiActionType.DELETE && dictionaryType === 'moods':
      return archiveMood;
    // TAG ACTIONS
    case actionType === ApiActionType.FETCH && dictionaryType === 'tags':
      return getTags;
    // APPROPRIATENESS TAGS
    case actionType === ApiActionType.FETCH && dictionaryType === 'moderations':
      return getModerations;
    case actionType === ApiActionType.CREATE &&
      dictionaryType === 'moderations':
      return addModeration;
    case actionType === ApiActionType.UPDATE &&
      dictionaryType === 'moderations':
      return updateModeration;
    case actionType === ApiActionType.DELETE &&
      dictionaryType === 'moderations':
      return archiveModeration;
  }
};

export function* fetchDictionarySaga({
  payload,
}: ReturnType<typeof fetchDictionaryAction.request>) {
  const { dictionaryType, query } = payload;
  const dictionaryId = `FETCH_DICTIONARY_${dictionaryType.toUpperCase()}`;

  try {
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.PENDING,
      })
    );

    const action = getAction(dictionaryType, ApiActionType.FETCH);
    const data: string[] = yield call(action!, query);

    yield put(fetchDictionaryAction.success({ data, dictionaryType }));
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (err) {
    yield put(
      fetchDictionaryAction.failure({ error: err as any, dictionaryId })
    );
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(err as any);
  }
}

export function* sendDictionarySaga({
  payload,
}: ReturnType<typeof sendDictionaryAction.request>) {
  const { name, translations, dictionaryType, actionType } = payload;
  const dictionaryId = `SEND_DICTIONARY_${dictionaryType.toUpperCase()}`;

  try {
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.PENDING,
      })
    );

    const action = getAction(dictionaryType, actionType);
    const data: ListingPageTranslation = { name, translations };

    yield call(action!, data);
    yield put(
      sendDictionaryAction.success({ name, dictionaryType, actionType })
    );

    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.RESOLVED,
      })
    );

    //Hacky solution for now. When setting RequestStatuses.RESOLVED and RequestStatuses.IDLE immediately the change is not detected by react component
    yield delay(500);

    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.IDLE,
      })
    );
  } catch (err) {
    yield put(
      sendDictionaryAction.failure({ error: err as any, dictionaryId })
    );
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.REJECTED,
      })
    );
    yield call(errorNotify, err as any);
    captureError(err as any);
  }
}

export function* archiveDictionarySaga({
  payload,
}: ReturnType<typeof archiveDictionaryAction.request>) {
  const { dictionaryType, name } = payload;
  const dictionaryId = `ARCHIVE_DICTIONARY_${dictionaryType.toUpperCase()}`;

  try {
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.PENDING,
      })
    );

    const action = getAction(dictionaryType, ApiActionType.DELETE);

    yield call(action!, name);
    yield put(archiveDictionaryAction.success({ dictionaryType, name }));
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (err) {
    yield put(
      archiveDictionaryAction.failure({ error: err as any, dictionaryId })
    );
    yield put(
      requestAction({
        action: dictionaryId,
        status: RequestStatuses.REJECTED,
      })
    );
    yield call(errorNotify, err as any);
    captureError(err as any);
  }
}

export default function* () {
  yield takeEvery(fetchDictionaryAction.request, fetchDictionarySaga);
  yield takeLeading(sendDictionaryAction.request, sendDictionarySaga);
  yield takeLeading(archiveDictionaryAction.request, archiveDictionarySaga);
}
