import React from 'react';
import styles from './index.module.scss';

/**
 * FormContainer is used to limit form width to avoid overwhelming the user.
 */
const FormContainer = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => (
  <div className={`${styles.root} ${className}`} {...props}>
    {children}
  </div>
);

export default FormContainer;
