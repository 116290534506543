import { api } from 'actions/api';
import { Region, RegionCreateForm } from 'types';

export const getAvailableCountries = async (): Promise<string[]> => {
  const { data } = await api().get<string[]>(`/countries/available`, {});

  return data;
};

export const getRegions = async (): Promise<Region[]> => {
  const { data } = await api().get<Region[]>(`/regions`);

  return data;
};

export const createRegion = async ({
  form,
}: {
  form: RegionCreateForm;
}): Promise<Region> => {
  const { data } = await api().post<Region>(`/regions`, form, {});

  return data;
};

export const updateRegion = async ({
  form,
  regionId,
}: {
  form: RegionCreateForm;
  regionId: number;
}): Promise<Region> => {
  const { data } = await api().put(`/regions/${regionId}`, form, {});

  return data;
};
