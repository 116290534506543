import {
  useDebounce,
  useFetchActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { getPublishedPlansByName } from 'actions/plans';
import { Select, Spin } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import React, { useCallback, useState } from 'react';
import { BasicPublishedPlan } from 'types/plan';
import styles from './index.module.scss';
const { Option } = Select;

export interface PlanChooserProps {
  onChoose: (plan: BasicPublishedPlan) => void;
  chosenPlanId?: string;
  placeholder?: string;
  disabled?: boolean;
}

// todo add filtering out already added plans
const PlanChooser = ({
  onChoose,
  placeholder = 'Search for plan',
  disabled,
  chosenPlanId,
}: PlanChooserProps) => {
  const [query, setQuery] = useState('');
  const queryDebounced = useDebounce(query, 300);
  const {
    data: plans,
    isLoading,
    error,
  } = useFetchActionObj<BasicPublishedPlan[]>(
    useCallback(() => getPublishedPlansByName(queryDebounced), [queryDebounced])
  );

  const onPlanSelect = (planUuid: string) => {
    const selectedPlan = plans?.find((plan) => plan?.uuid === planUuid);

    if (!!selectedPlan) {
      onChoose(selectedPlan);
    }
  };

  return (
    <div className={styles.fullWidth}>
      <Select
        value={chosenPlanId}
        showSearch
        onSearch={setQuery}
        placeholder={placeholder}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onChange={onPlanSelect}
        className={styles.fullWidth}
        disabled={disabled}
      >
        {plans?.map(
          (plan?: BasicPublishedPlan) =>
            !!plan && (
              <Option key={plan.uuid} value={plan.uuid}>
                {plan.administrativeName}
              </Option>
            )
        )}
      </Select>

      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default PlanChooser;
