import moment from 'moment';

export const skipOptionsVideoPlayerId = 'skipOptions__player';

export const toNumber = (str: string) => {
  const numbers = str.split(':');

  const hours = +numbers[0] * 3600;
  const minutes = +numbers[1] * 60;
  const total = hours + minutes + +numbers[2];

  return total;
};

export const toString = (n?: number) => {
  if (n === undefined) return '';
  return moment.utc(n * 1000).format('HH:mm:ss');
};

export const keyToType = (key: string): 'INTRO' | 'RECAP' | 'CAPTIONS' => {
  switch (key) {
    case 'intro':
      return 'INTRO';
    case 'recap':
      return 'RECAP';
    case 'credits':
      return 'CAPTIONS';
    default:
      throw new Error('incorrect skip options key');
  }
};

export const typeToKey = (type: 'INTRO' | 'RECAP' | 'CAPTIONS'): string => {
  switch (type) {
    case 'INTRO':
      return 'intro';
    case 'RECAP':
      return 'recap';
    case 'CAPTIONS':
      return 'credits';
    default:
      throw new Error('incorrect skip options type');
  }
};
