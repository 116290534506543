import React, { useEffect } from 'react';
import {
  GlobalOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  OrderedListOutlined,
  PictureOutlined,
  PlayCircleOutlined,
  StepForwardOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import InnerSider from 'components/InnerSider';
import PageContainer from 'components/PageContainer';
import PageDetailsHeader from 'components/PageDetailsHeader';
import { useAssetContentContext } from 'contexts/assetContentContext';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { ImageProvider } from 'contexts/imageContext';
import { useActiveMenuKey } from 'hooks/activeMenuKeyHook';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { RootState } from 'store';
import { fetchAiRecognitionStatusAction } from 'store/ai/actions';
import { selectAiAutoRecognitionStatus } from 'store/ai/selectors';
import { ContentType } from 'types';
import { AIRecognitionStatus } from 'types/ai';
import { AssetType } from '@laminar-product/client-commons-core/core';
import StreamInfrastructure from 'pages/Assets/AssetDetails/StreamInfrastructure';
import HeaderAdditionalInfo from 'pages/Assets/AssetDetails/HeaderAdditionalInfo';
import { Paths } from 'types/paths';
import AssetAIMetadata from './AssetAIMetadata';
import AssetBadge from './AssetBadge';
import Basic from './Basic';
import ChildrenOrdering from './ChildrenOrdering';
import {
  getLabelForChildrenOrdering,
  shouldPresentChildrenOrdering,
} from './childrenOrderingUtils';
import Contents from './Contents';
import Crew from './Crew';
import Images from './Images';
import SkipOptions from './SkipOptions';
import AssetFreemiumEpisodes from './FreemiumEpisodes';
import FreemiumIcon from './FreemiumEpisodes/components/FreemiumIcon';
import AgeRatings from './AgeRatings';
import AssetLicenses from './AssetLicenses';
import AssetPublish from './AssetPublish';

export default () => {
  const { assetDraft, uuid, isLoading, error, asset, isLiveType } =
    useAssetDetailsContext();
  const { type } = asset;
  const { sourceFileId } = useAssetContentContext();
  const aiRecognitionStatus = useSelector<RootState, AIRecognitionStatus>(
    (state) => selectAiAutoRecognitionStatus(state, sourceFileId)
  );
  const dispatch = useDispatch();
  const menuKey = useActiveMenuKey(3, '/details');

  useEffect(() => {
    if (sourceFileId) {
      dispatch(fetchAiRecognitionStatusAction.request({ sourceFileId }));
    }
  }, [dispatch, sourceFileId]);

  return (
    <>
      <PageDetailsHeader
        name={assetDraft.name}
        loading={isLoading}
        asset={asset}
        badge={<AssetBadge assetType={assetDraft.type} />}
        canPreviewDraft={isLiveType}
      >
        <HeaderAdditionalInfo />
      </PageDetailsHeader>
      <Layout>
        <InnerSider menuKey={menuKey}>
          <Menu.Item key="/details" icon={<InfoCircleOutlined />}>
            <Link to={`${Paths.ASSETS}/${uuid}`}>Details</Link>
          </Menu.Item>
          <Menu.Item key="/licenses" icon={<ToolOutlined />}>
            <Link to={`/assets/${uuid}/licenses`}>Licenses</Link>
          </Menu.Item>
          {isLiveType && (
            <>
              <Menu.Item key="/stream-infrastructure" icon={<WifiOutlined />}>
                <Link to={`${Paths.ASSETS}/${uuid}/stream-infrastructure`}>
                  Stream infrastructure
                </Link>
              </Menu.Item>
              <Menu.Item key="/trailer" icon={<PlayCircleOutlined />}>
                <Link to={`${Paths.ASSETS}/${uuid}/trailer`}>Trailer</Link>
              </Menu.Item>
            </>
          )}
          {!isLiveType && (
            <>
              <Menu.Item key="/contents" icon={<PlayCircleOutlined />}>
                <Link to={`${Paths.ASSETS}/${uuid}/contents`}>Contents</Link>
              </Menu.Item>
              <Menu.Item key="/cast" icon={<UserOutlined />}>
                <Link to={`${Paths.ASSETS}/${uuid}/cast`}>Cast</Link>
              </Menu.Item>
              <Menu.Item key="/skip-options" icon={<StepForwardOutlined />}>
                <Link to={`${Paths.ASSETS}/${uuid}/skip-options`}>
                  Skip options
                </Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item key="/images" icon={<PictureOutlined />}>
            <Link to={`${Paths.ASSETS}/${uuid}/images`}>Images</Link>
          </Menu.Item>
          <Menu.Item key="/age-ratings" icon={<MinusCircleOutlined />}>
            <Link to={`${Paths.ASSETS}/${uuid}/age-ratings`}>Age Ratings</Link>
          </Menu.Item>
          {!isLiveType && shouldPresentChildrenOrdering(type) && (
            <Menu.Item key="/children" icon={<OrderedListOutlined />}>
              <Link to={`${Paths.ASSETS}/${uuid}/children`}>
                {getLabelForChildrenOrdering(type)}
              </Link>
            </Menu.Item>
          )}
          {!isLiveType && aiRecognitionStatus === 'completed' && (
            <Menu.Item key="/ai" icon={<TeamOutlined />}>
              <Link to={`${Paths.ASSETS}/${uuid}/ai`}>AI metadata</Link>
            </Menu.Item>
          )}
          {type === AssetType.SHOW && (
            <Menu.Item key="/freemium-episodes" icon={<FreemiumIcon />}>
              <Link to={`${Paths.ASSETS}/${uuid}/freemium-episodes`}>
                Freemium Episodes
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/publish" icon={<GlobalOutlined />}>
            <Link to={`${Paths.ASSETS}/${uuid}/publish`}>Publish</Link>
          </Menu.Item>
        </InnerSider>
        {error && <ErrorIndicator error={error} />}
        <Switch>
          <Route exact path={`${Paths.ASSETS}/:id`}>
            <ImageProvider asset={asset}>
              <Basic />
            </ImageProvider>
          </Route>
          <Route path={`${Paths.ASSETS}/:id/contents`}>
            <Contents />
          </Route>
          <Route path={`${Paths.ASSETS}/:id/images`}>
            <Images />
          </Route>
          <Route path="/assets/:id/licenses">
            <AssetLicenses />
          </Route>
          {!isLiveType && aiRecognitionStatus === 'completed' && (
            <Route path={`${Paths.ASSETS}/:id/ai`}>
              <AssetAIMetadata />
            </Route>
          )}
          <Route path={`${Paths.ASSETS}/:id/publish`}>
            <AssetPublish />
          </Route>

          {asset.type === AssetType.SHOW && (
            <Route path={`${Paths.ASSETS}/:id/freemium-episodes`}>
              <PageContainer>
                <AssetFreemiumEpisodes asset={asset} />
              </PageContainer>
            </Route>
          )}
          <Route path={`${Paths.ASSETS}/:id/age-ratings`}>
            <AgeRatings />
          </Route>
          {isLiveType && (
            <>
              <Route path={`${Paths.ASSETS}/:id/stream-infrastructure`}>
                <StreamInfrastructure />
              </Route>
              <Route path={`${Paths.ASSETS}/:id/trailer`}>
                <Contents filterBy={[ContentType.TRAILER]} />
              </Route>
            </>
          )}
          {!isLiveType && (
            <>
              <Route path={`${Paths.ASSETS}/:id/cast`} component={Crew} />
              <Route
                path={`${Paths.ASSETS}/:id/skip-options`}
                component={SkipOptions}
              />
              <Route path={`${Paths.ASSETS}/:id/children`}>
                <PageContainer>
                  <ChildrenOrdering asset={asset} />
                </PageContainer>
              </Route>
            </>
          )}
        </Switch>
      </Layout>
    </>
  );
};
