import { Alert, Input, Select } from 'antd';
import FormGroup from 'components/FormGroup';
import { useGetRegions } from 'hooks/useRegions';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FeaturedAsset } from 'types/featuredAsset';
import { getTimezones } from 'utils/timezones';
import _isEqual from 'lodash/isEqual';
import styles from '../../index.module.scss';

const { Option } = Select;

interface FeaturedAssetGeneralDetailsProps {
  originalFeaturedAsset?: FeaturedAsset;
}

const FeaturedAssetGeneralDetails = ({
  originalFeaturedAsset,
}: FeaturedAssetGeneralDetailsProps) => {
  const { control, errors, watch } = useFormContext<FeaturedAsset>();
  const { regions = [] } = useGetRegions();

  const validateRegionsController = (value?: string[]) => {
    if (!value?.length) {
      return 'This field is required';
    }
  };

  const regionsHasChanged =
    originalFeaturedAsset?.regionIds &&
    !_isEqual(watch('regionIds'), originalFeaturedAsset.regionIds);

  return (
    <>
      <FormGroup
        input={
          <Controller
            placeholder="Enter the name"
            as={Input}
            control={control}
            name="name"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Administrative name"
        defaultValue=""
        errorMessage={errors?.name?.message}
      />

      <FormGroup
        input={
          <Controller
            name="regionIds"
            control={control}
            rules={{ validate: validateRegionsController }}
            render={({ onChange, value }) => (
              <Select
                mode="multiple"
                className={styles.fullWidth}
                allowClear
                placeholder="Select the regions"
                value={value}
                onChange={onChange}
                optionFilterProp="label"
                optionLabelProp="label"
                options={regions.map((region) => ({
                  label: region.name,
                  value: region.uuid,
                }))}
              />
            )}
          />
        }
        label="Regions"
        defaultValue={[]}
        errorMessage={(errors?.regionIds as any)?.message}
      />

      <FormGroup
        input={
          <Controller
            as={
              <Select
                placeholder="Select time zone"
                showSearch
                filterOption={(input, option) =>
                  option?.key.toLowerCase().includes(input.toLowerCase())
                }
                className={styles.fullWidth}
              >
                {getTimezones().map(({ label, offset }) => (
                  <Option key={label} value={offset}>
                    {label}
                  </Option>
                ))}
              </Select>
            }
            control={control}
            name="timezone"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Time zone"
        defaultValue=""
        errorMessage={errors?.timezone?.message}
      />

      {regionsHasChanged && (
        <Alert
          type="warning"
          showIcon
          message="Please make sure that all assets in queue and default assets are included in selected regions."
        />
      )}
    </>
  );
};

export default FeaturedAssetGeneralDetails;
