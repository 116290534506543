import FormGroup from 'components/FormGroup';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Radio, Switch } from 'antd';
import {
  GuestAccess,
  GuestAccessConfiguration,
  GuestAccessForm,
} from 'types/guestAccess';
import { useEffect } from 'react';
import AssetSearchableFormTable from 'components/AssetSearchableFormTable';
import styles from './index.module.scss';

interface GuestAccessDetailsProps {
  form: UseFormMethods<GuestAccessForm>;
  regionUuid?: string;
  config?: GuestAccess;
}

const GuestAccessDetails = ({
  form,
  config,
  regionUuid,
}: GuestAccessDetailsProps) => {
  const { watch, setValue, control, reset } = form;

  const displayGuestAccessHandler = (value: boolean) => {
    setValue('guestAccess', value);

    setValue(
      'configuration',
      value ? GuestAccessConfiguration.ALL_FREE : GuestAccessConfiguration.NONE
    );
  };

  const configurationValue = watch('configuration');

  useEffect(() => {
    if (!config || !regionUuid) return;

    reset({
      assets: [],
      guestAccess: config.configuration !== GuestAccessConfiguration.NONE,
      configuration: config.configuration || GuestAccessConfiguration.NONE,
    });
  }, [config, regionUuid, reset]);

  useEffect(() => {
    return () => {
      reset({
        assets: [],
        guestAccess: false,
        configuration: GuestAccessConfiguration.NONE,
      });
    };
  }, [reset]);

  return (
    <div className={styles.root}>
      <FormGroup
        input={
          <Controller
            control={control}
            name="guestAccess"
            render={({ value }) => (
              <Switch checked={value} onChange={displayGuestAccessHandler} />
            )}
          />
        }
        label="Guest access in free assets"
      />

      {configurationValue !== GuestAccessConfiguration.NONE && (
        <div className={styles.configWrapper}>
          <div className={styles.formWrapper}>
            <FormGroup
              input={
                <Controller
                  name="configuration"
                  control={control}
                  defaultValue={GuestAccessConfiguration.ALL_FREE}
                  render={({ value, onChange }) => (
                    <Radio.Group
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      className={styles.radioForm}
                    >
                      <Radio value={GuestAccessConfiguration.ALL_FREE}>
                        All free assets
                      </Radio>
                      <Radio value={GuestAccessConfiguration.SELECTED}>
                        Selected free assets
                      </Radio>
                    </Radio.Group>
                  )}
                />
              }
              label="Display in"
            />
          </div>

          {configurationValue === GuestAccessConfiguration.SELECTED && (
            <AssetSearchableFormTable
              assignedAssetUuids={config?.assetUuidList}
              regionUuid={regionUuid}
              form={form}
              selectedAssets={watch('assets')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GuestAccessDetails;
