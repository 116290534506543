import { Language } from '@laminar-product/client-commons-core/core';
import { createAsyncAction } from 'typesafe-actions';

export const fetchAllLanguages = createAsyncAction(
  'FETCH_LANGUAGES_REQUEST',
  'FETCH_LANGUAGES_SUCCESS',
  'FETCH_LANGUAGES_FAILURE'
)<undefined, Language[], Error>();

export const fetchPlatformLanguages = createAsyncAction(
  'FETCH_PLATFORM_LANGUAGES_REQUEST',
  'FETCH_PLATFORM_LANGUAGES_SUCCESS',
  'FETCH_PLATFORM_LANGUAGES_FAILURE'
)<
  undefined,
  { availableLanguages?: Language[]; defaultLanguageIsoCode?: string },
  Error
>();

export const updatePlatformLanguages = createAsyncAction(
  'UPDATE_PLATFORM_LANGUAGES_REQUEST',
  'UPDATE_PLATFORM_LANGUAGES_SUCCESS',
  'UPDATE_PLATFORM_LANGUAGES_FAILURE'
)<Language[], Language[], Error>();
