import { AIRecognitionStatus } from 'types/ai';
import { Action, createReducer } from 'typesafe-actions';
import { fetchAiRecognitionStatusAction } from './actions';

export interface AIState {
  readonly recognition: {
    readonly [sourceId: string]: {
      readonly faces: AIRecognitionStatus;
      readonly auto: AIRecognitionStatus;
    };
  };
}

export const initialState: AIState = {
  recognition: {},
};

export const aiReducer = createReducer<AIState, Action>(
  initialState
).handleAction(
  fetchAiRecognitionStatusAction.success,
  (state, { payload }) => ({
    ...state,
    recognition: {
      ...state.recognition,
      [payload.sourceFileId]: {
        auto: payload.autoRecognitionStatus,
        faces: payload.facesRecognitionStatus,
      },
    },
  })
);
