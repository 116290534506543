import React, { useCallback, useState } from 'react';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { GetPagesQuery, getPages } from 'actions/pages';
import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import { useGetRegions } from 'hooks/useRegions';
import { useHistory } from 'react-router-dom';
import { Pagination } from 'types';
import { Page } from 'types/pages';
import { usePageParam } from 'utils/pagination';
import PagesTable from './PagesTable';

const PagesList = () => {
  const { push } = useHistory();
  const currentPage = usePageParam();
  const [query, setQuery] = useState<GetPagesQuery>({
    sorter: { sortBy: 'updatedAt', sortingDir: 'DESCEND' }, // This is set in the Column component of the PagesTable
  });
  const { regions } = useGetRegions();
  const [pages, isLoading, , refreshPages] = useFetchAction<Pagination<Page>>(
    useCallback(() => {
      return getPages(currentPage, query);
    }, [currentPage, query])
  );

  const transformData = (data?: Page[]) =>
    data?.map((p) => ({
      ...p,
      regions: p.regions.map(
        (pageRegionUuid) =>
          regions?.find((r) => r.uuid === pageRegionUuid)?.name || ''
      ),
    }));

  return (
    <PageContainer>
      <PageHeader
        title="Pages"
        extra={[
          <Search
            key="search"
            onSearch={(searchQuery: string) => {
              setQuery({ ...query, name: searchQuery });
              push('/pages/page/1');
            }}
          />,
          <Button
            key="createNewPage"
            type="primary"
            onClick={() => push('/pages/create')}
          >
            Create new page
          </Button>,
        ]}
      />
      <PagesTable
        regions={regions}
        data={transformData(pages?.data)}
        count={pages?.count ?? 0}
        isDataLoading={isLoading}
        onChange={(query) => setQuery(query)}
        refreshData={refreshPages}
      />
    </PageContainer>
  );
};

export default PagesList;
