import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

const StickyHeader = ({
  children,
  hasBadge,
}: {
  children: React.ReactNode;
  hasBadge?: boolean;
}) => (
  <div className={cn(styles.header, { [styles.withBadge]: hasBadge })}>
    {children}
  </div>
);

export default StickyHeader;
