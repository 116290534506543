import React from 'react';
import { Badge } from 'antd';
import styles from './MenuTabPane.module.scss';

const MenuTabPane = ({
  platform,
  count,
}: {
  platform: string;
  count?: number;
}) => (
  <div className={styles.tab}>
    <span className={styles.tabName}>{platform.toLowerCase()}</span>
    <Badge className={styles.tabBadge} count={count} />
  </div>
);

export default MenuTabPane;
