import { useMemo } from 'react';
import getMostUsedLang from 'utils/getMostUsedLang';
import { LanguageType } from 'types/translations';

const useMostUsedLanguages = (
  codesToExclude?: string[],
  type: LanguageType = LanguageType.ALPHA_2
) =>
  useMemo(
    () => getMostUsedLang().filter((l) => !codesToExclude?.includes(l[type])),
    [codesToExclude, type]
  );

export default useMostUsedLanguages;
