import React, { useCallback, useState } from 'react';
import { Button, Modal, PageHeader, Table, Tag } from 'antd';
import PageContainer from 'components/PageContainer';
import {
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  deleteCensorshipRule,
  getCensorshipsRules,
  publishCensorshipRules,
} from 'actions/censorships';
import { CensorshipRule } from 'types/censorships';
import ErrorIndicator from 'components/ErrorIndicator';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import RegionsMenu from 'components/RegionsMenu';
import { errorNotify } from 'utils/errorNotify';
import { useHistory } from 'react-router-dom';
import { Region } from 'types';
import StringList from 'components/StringList';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import { useAllLanguages } from 'hooks/useLanguages';
import { getCensorshipRuleLanguageNames } from 'utils/getCensorshipRuleLanguageNames';
import { generateCensorshipRuleUrl, hasDefualtTranslation } from '../utils';
import styles from './index.module.scss';
import CensorshipRuleModalForm from './components/CensorshipRuleModalForm';

const { Column } = Table;

const CensorshipRules = () => {
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const [displayCensorshipModal, setDisplayCensorshipModal] = useState(false);
  const [deleteAgeRating, setDeleteAgeRating] = useState<number | undefined>();
  const [censorshipRules, isLoading, error, refresh] = useFetchAction<
    CensorshipRule[]
  >(
    useCallback(
      () => getCensorshipsRules({ regionUuid: selectedRegion?.uuid }),
      [selectedRegion]
    )
  );
  const { push } = useHistory();
  const { getLanguageNameByIsoCode } = useAllLanguages();

  const closeCensorshipModalHandler = () => setDisplayCensorshipModal(false);

  const closeConfirmationModalHandler = () => setDeleteAgeRating(undefined);

  const deleteCensorshipRuleHandler = async () => {
    if (!deleteAgeRating) return;

    try {
      await deleteCensorshipRule({ censorshipRuleId: deleteAgeRating });
      closeConfirmationModalHandler();
      refresh();
      notification.success({
        title: 'Success',
        description: 'Age rating has been deleted',
      });
    } catch (err) {
      errorNotify(err as any);
      captureError(err as any);
    }
  };

  const validateCensorhipRulePublicDetails = (
    censorshipRules: CensorshipRule[]
  ) => {
    const invalidRules = censorshipRules.filter(
      (rule) => !hasDefualtTranslation(rule.translations)
    );
    return invalidRules.length ? invalidRules : null;
  };

  const getInvalidRulesMessage = (censorshipRulesNames: string[]) => {
    if (censorshipRulesNames.length > 1) {
      return `Add public details at least in English to publish (Age ratings: ${censorshipRulesNames.join(
        ', '
      )})`;
    }
    return `Add public details at least in English to publish (Age rating: ${censorshipRulesNames[0]})`;
  };

  const [publish, isPublishing] = useSendAction(
    async () => {
      if (!censorshipRules) return;

      const invalidRules = validateCensorhipRulePublicDetails(censorshipRules);
      if (invalidRules) {
        return notification.error({
          title: 'Error',
          description: getInvalidRulesMessage(
            invalidRules.map((rule) => rule.name)
          ),
        });
      }
      await publishCensorshipRules();

      notification.success({
        title: 'Success',
        description: 'Age ratings has been published',
      });
    },
    {
      onError: errorNotify,
    }
  );

  return (
    <PageContainer>
      <PageHeader
        title="Age ratings"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setDisplayCensorshipModal(true)}
          >
            Create new rating
          </Button>
        }
      />
      {error && <ErrorIndicator error={error} />}
      <div className={styles.wrapper}>
        <RegionsMenu
          defaultKey={selectedRegion?.uuid}
          onRegionSelect={setSelectedRegion}
        />
        <Table
          loading={isLoading}
          indentSize={40}
          dataSource={censorshipRules}
          className={styles.censorshipTable}
        >
          <Column
            title="Age Rating Label"
            key="name"
            dataIndex="name"
            align="left"
            render={(_, rule: CensorshipRule) => (
              <Tag className={styles.ratingLabel}>{rule.name}</Tag>
            )}
          />
          <Column
            title="Minimum Age"
            key="minAge"
            dataIndex="minAge"
            align="left"
          />
          <Column
            title="Public details"
            key="publicDetails"
            dataIndex="publicDetails"
            align="left"
            render={(_, rule: CensorshipRule | null) => {
              if (!rule) return null;
              return (
                <StringList
                  items={getCensorshipRuleLanguageNames({
                    translations: rule.translations,
                    getLanguageNameByIsoCode,
                  })}
                />
              );
            }}
          />
          <Column
            title=""
            dataIndex="actionButtons"
            key="actionButtons"
            width={50}
            render={(_, rule: CensorshipRule) => (
              <div className={styles.actionButtonsWrapper}>
                <EditOutlined
                  onClick={() =>
                    push(generateCensorshipRuleUrl(rule, selectedRegion?.name))
                  }
                />
                <DeleteOutlined onClick={() => setDeleteAgeRating(rule.id)} />
              </div>
            )}
          />
        </Table>
      </div>
      <Button
        type="primary"
        disabled={isPublishing}
        onClick={publish as any}
        icon={<GlobalOutlined />}
      >
        Publish All
      </Button>
      {displayCensorshipModal && (
        <Modal
          visible={displayCensorshipModal}
          onCancel={closeCensorshipModalHandler}
          title="Create new rating"
          footer={null}
          destroyOnClose
        >
          <CensorshipRuleModalForm
            region={selectedRegion}
            onClose={closeCensorshipModalHandler}
          />
        </Modal>
      )}
      <Modal
        title="Confirmation"
        visible={!!deleteAgeRating}
        onCancel={closeConfirmationModalHandler}
        onOk={deleteCensorshipRuleHandler}
        confirmLoading={isLoading}
        centered
      >
        Are you sure you want to delete this age rating?
      </Modal>
    </PageContainer>
  );
};

export default CensorshipRules;
