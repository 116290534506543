import { call, put, takeLatest } from 'redux-saga/effects';
import { requestAction } from 'store/app/actions';
import { RequestStatuses } from 'store/app/types';
import { getType } from 'typesafe-actions';
import { captureError } from 'utils/captureError';
import { languages } from '@laminar-product/client-commons-core/core';
import {
  fetchAllLanguages,
  fetchPlatformLanguages,
  updatePlatformLanguages,
} from './actions';
import {
  getAllLanguages,
  getPlatformLanguages,
  updatePlatformLanguages as updatePlatformLanguagesApi,
} from './service';
import { PlatformAvailableLanguages, PlatformLanguage } from './types';

export function* fetchAllLanguagesSaga() {
  try {
    yield put(
      requestAction({
        action: getType(fetchAllLanguages.request),
        status: RequestStatuses.PENDING,
      })
    );

    const data: PlatformLanguage[] = yield call(getAllLanguages);

    const languagesWithLabel = languages.filter(({ alpha2 }) =>
      data.find((lang) => lang.isoCode === alpha2)
    );

    yield put(fetchAllLanguages.success(languagesWithLabel));
    yield put(
      requestAction({
        action: getType(fetchAllLanguages.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (error) {
    yield put(fetchAllLanguages.failure(error as any));
    yield put(
      requestAction({
        action: getType(fetchAllLanguages.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(error as any);
  }
}

export function* fetchPlatformLanguagesSaga() {
  try {
    yield put(
      requestAction({
        action: getType(fetchPlatformLanguages.request),
        status: RequestStatuses.PENDING,
      })
    );

    const {
      availableLanguagesIsoCodes,
      defaultLanguageIsoCode,
    }: PlatformAvailableLanguages = yield call(getPlatformLanguages);

    const languagesWithLabel = languages.filter(({ alpha2 }) =>
      (availableLanguagesIsoCodes || []).find(
        (lang) => lang?.isoCode === alpha2
      )
    );

    yield put(
      fetchPlatformLanguages.success({
        availableLanguages: languagesWithLabel,
        defaultLanguageIsoCode: defaultLanguageIsoCode?.isoCode,
      })
    );
    yield put(
      requestAction({
        action: getType(fetchPlatformLanguages.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (error) {
    yield put(fetchPlatformLanguages.failure(error as any));
    yield put(
      requestAction({
        action: getType(fetchPlatformLanguages.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(error as any);
  }
}

export function* updatePlatformLanguagesSaga({
  payload,
}: ReturnType<typeof updatePlatformLanguages.request>) {
  try {
    yield put(
      requestAction({
        action: getType(updatePlatformLanguages.request),
        status: RequestStatuses.PENDING,
      })
    );

    const platformLanguagesIsoCodes = payload.map((lang) => lang.alpha2);

    yield call(updatePlatformLanguagesApi, platformLanguagesIsoCodes);

    yield put(updatePlatformLanguages.success(payload));
    yield put(
      requestAction({
        action: getType(updatePlatformLanguages.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (error) {
    yield put(updatePlatformLanguages.failure(error as any));
    yield put(
      requestAction({
        action: getType(updatePlatformLanguages.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(error as any);
  }
}

export default function* () {
  yield takeLatest(fetchAllLanguages.request, fetchAllLanguagesSaga);
  yield takeLatest(fetchPlatformLanguages.request, fetchPlatformLanguagesSaga);
  yield takeLatest(
    updatePlatformLanguages.request,
    updatePlatformLanguagesSaga
  );
}
