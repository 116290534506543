import React from 'react';
import MultiSelectDetails from 'components/MultiSelectAssetDetails';
import Suggestions from 'components/Suggestions';
import FormGroup from 'components/FormGroup';
import { useDebounceSelectHook } from 'hooks/debounceSelectHook';
import { useDictionary } from 'hooks/useDictionaries';
import { DictionaryType } from 'store/dictionaries/types';
import styles from './index.module.scss';

const MultiSelectGeneralDetails = ({
  action,
  parentData,
  mainData,
  type,
  label,
  setValue,
  createNewAllowed,
}: {
  action: typeof useDictionary;
  mainData?: string[];
  parentData?: string[];
  label: string;
  type: DictionaryType;
  setValue: (label: string, data: string[]) => void;
  createNewAllowed?: boolean;
}) => {
  const {
    data,
    isLoading,
    setQuery,
    selectedData,
    setSelectedData,
    suggestions,
  } = useDebounceSelectHook(action, type, mainData, parentData);

  return (
    <FormGroup
      label={label}
      input={
        <>
          <MultiSelectDetails
            value={selectedData}
            data={data ?? []}
            isLoading={isLoading}
            name={label.toLowerCase()}
            mode={createNewAllowed ? 'tags' : 'multiple'}
            onSearch={setQuery}
            onChange={(value: string[]) => {
              if (!!value) setValue(type, value);
              setSelectedData(value);
              setQuery('');
            }}
          />
          {!!suggestions.length && (
            <div className={styles.suggestionsWrapper}>
              <Suggestions
                options={suggestions}
                onAdd={(tag) => {
                  const newTags = [...(selectedData ? selectedData : []), tag];
                  setValue(type, newTags);
                  setSelectedData(newTags);
                }}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default MultiSelectGeneralDetails;
