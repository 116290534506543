import { DatePicker } from 'antd';
import FormGroup from 'components/FormGroup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateLicenseBasicForm } from 'types/licenses';
import { DATE_FORMAT } from 'utils/constants';
import MultipleRegionSelector from 'components/MultipleRegionsSelector';
import styles from '../../index.module.scss';

interface AddLicenseFormProps {
  regionsIdsToFilterOut?: number[];
}

const AddLicenseForm = ({ regionsIdsToFilterOut }: AddLicenseFormProps) => {
  const { control, errors } = useFormContext<CreateLicenseBasicForm>();

  return (
    <>
      <FormGroup
        input={
          <Controller
            control={control}
            render={({ value, onChange }) => (
              <MultipleRegionSelector
                placeholder="Select regions"
                regionsIdsToFilterOut={regionsIdsToFilterOut}
                selectedRegions={value}
                onRegionsSelect={onChange}
              />
            )}
            name="regions"
            rules={{ required: true }}
          />
        }
        label="Regions"
        defaultValue=""
        vertical
        errorMessage={!!errors?.regions ? 'This field is required' : ''}
      />

      <FormGroup
        input={
          <Controller
            control={control}
            name="dateFrom"
            as={
              <DatePicker
                className={styles.dateInput}
                placeholder="Select start date"
                format={DATE_FORMAT}
              />
            }
            rules={{ required: 'This field is required' }}
          />
        }
        label="Start"
        vertical
        defaultValue=""
        errorMessage={errors?.dateFrom?.message}
      />

      <FormGroup
        input={
          <Controller
            control={control}
            name="dateTo"
            as={
              <DatePicker
                className={styles.dateInput}
                placeholder="Select end date"
                format={DATE_FORMAT}
              />
            }
            rules={{ required: 'This field is required' }}
          />
        }
        label="End"
        vertical
        defaultValue=""
        errorMessage={errors?.dateTo?.message}
      />
    </>
  );
};

export default AddLicenseForm;
