import { Dropdown, Menu, Table } from 'antd';
import { Pagination, User } from 'types';
import React, { useState } from 'react';
import { usePermission } from 'hooks/permissionsHook';
import { usePaginationObject } from 'utils/pagination';
import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Permission } from 'utils/constants';
import StatusTag, { StatusTagConfig } from 'components/StatusTag';
import EditUserModal from 'pages/Users/UserList/UserTable/EditUserModal';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { changeUserActiveStatus, changeUserRoles } from 'actions/user';
import ChangeUserStatusModal from 'pages/Users/UserList/UserTable/ChangeUserStatusModal';
import notification from 'utils/notification';
import UserSignOutMenuItem from 'components/UserSignOutMenuItem';
import { signOutAdminUser } from 'actions/users';
import styles from './index.module.scss';

const { Column } = Table;

const UserTable = ({
  pagination,
  currentPage,
  loading,
  setModalOpen,
  setSelectedUserEmail,
  reload,
}: {
  pagination?: Pagination<User>;
  currentPage: number;
  loading?: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedUserEmail: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  reload: () => void;
}) => {
  const [changeStatus, changeStatusLoading] = useSendAction<
    void,
    { email: string; activate: boolean }
  >(changeUserActiveStatus, {
    onDone: () => {
      reload();
      setChangeUserStatusModalOpen(false);
    },
  });

  const [changeRoles, changeRolesLoading] = useSendAction<
    void,
    { email: string; roles: string[] }
  >(changeUserRoles, {
    onDone: () => {
      reload();
      setEditUserModalOpen(false);
      notification.success({
        title: 'Success',
        description: 'Admin details updated!',
      });
    },
    onError: (error: any) => {
      setEditUserModalOpen(false);
      notification.error({
        title: 'Error',
        description:
          error?.response?.data?.details ||
          'There was an error during the request, please try again later.',
      });
    },
  });

  const { canUse } = usePermission();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [changeUserStatusModalOpened, setChangeUserStatusModalOpen] =
    useState<boolean>();
  const [editUserModalOpened, setEditUserModalOpen] = useState<boolean>();

  const setModalData = (email: string) => {
    setSelectedUserEmail(email);
    setModalOpen(true);
  };

  const getStatusTagConfig = (status: boolean): StatusTagConfig =>
    status
      ? { text: 'Active', color: 'green' }
      : { text: 'Inactive', color: 'gray' };

  return (
    <>
      <Table
        dataSource={pagination?.data}
        rowKey="id"
        loading={loading}
        pagination={usePaginationObject('/users', pagination, currentPage)}
      >
        <Column title="Email" dataIndex="email" key="email" />
        <Column
          title="Roles"
          dataIndex="roles"
          key="roles"
          render={(roles) => roles.join(', ')}
        />
        <Column
          title="Status"
          dataIndex="active"
          key="status"
          render={(status) => <StatusTag type={getStatusTagConfig(status)} />}
        />

        <Column
          key="options"
          align="right"
          render={(user: User) => (
            <>
              {canUse([Permission.EDIT_USER]) && (
                <EditOutlined
                  className={styles.button}
                  onClick={() => {
                    setSelectedUser(user);
                    setEditUserModalOpen(true);
                  }}
                />
              )}

              <Dropdown
                className={styles.button}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={user.id + 1}
                      onClick={() => setModalData(user.email)}
                    >
                      Reset password
                    </Menu.Item>

                    {canUse([Permission.DEACTIVATE_USER]) && (
                      <Menu.Item
                        key={user.id + 2}
                        onClick={() => {
                          setSelectedUser(user);
                          setChangeUserStatusModalOpen(true);
                        }}
                      >
                        {user.active ? 'Deactivate' : 'Activate'}
                      </Menu.Item>
                    )}

                    <UserSignOutMenuItem
                      user={user}
                      setSelectedUser={setSelectedUser}
                      permissions={[
                        Permission.SIGN_OUT_ADMIN_USER_FROM_ALL_DEVICES,
                      ]}
                      signOutUser={signOutAdminUser}
                    />
                  </Menu>
                }
              >
                <MoreOutlined />
              </Dropdown>
            </>
          )}
        />
      </Table>

      <EditUserModal
        user={selectedUser}
        onOk={(user) => {
          changeRoles({
            ...user,
          });
        }}
        onCancel={() => {
          setEditUserModalOpen(false);
          setSelectedUser(undefined);
        }}
        opened={editUserModalOpened}
        loading={changeRolesLoading}
      />

      <ChangeUserStatusModal
        opened={changeUserStatusModalOpened}
        onCancel={() => {
          setChangeUserStatusModalOpen(false);
          setSelectedUser(undefined);
        }}
        onOk={() => {
          if (!selectedUser) return;
          const { email, active } = selectedUser;
          changeStatus({
            email,
            activate: !active,
          });
        }}
        user={selectedUser}
        loading={changeStatusLoading}
      />
    </>
  );
};

export default UserTable;
