import { Select } from 'antd';
import React, { useEffect } from 'react';
import cn from 'classnames';
import { OptionType } from 'types/select';
import useMostUsedLanguages from 'hooks/useMostUsedLanguages';
import { usePlatformLanguages } from 'hooks/useLanguages';
import { LanguageType } from 'types/translations';
import { Language } from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';

const { Option, OptGroup } = Select;

export interface LanguageChooserProps {
  onChoose?: (langCode: string) => void;
  chosenLanguageCode?: string;
  codesToExclude?: string[];
  className?: string;
  showSearch?: boolean;
  enableMostUsed?: boolean;
  placeholder?: string;
  type?: LanguageType;
  overrideLanguageList?: Language[];
  disabled?: boolean;
}

const LanguageChooser = ({
  showSearch = true,
  onChoose,
  chosenLanguageCode,
  codesToExclude = [],
  className,
  enableMostUsed = true,
  placeholder = 'Choose language...',
  type = LanguageType.ALPHA_2,
  overrideLanguageList,
  disabled,
  ...otherProps
}: LanguageChooserProps) => {
  const mostUsedLanguages = useMostUsedLanguages(codesToExclude);
  const { platformLanguages, isLoadingLanguages, getLanguageByIsoCode } =
    usePlatformLanguages();

  const languages = overrideLanguageList || platformLanguages;

  const languageData = languages
    .filter((code) => !codesToExclude?.includes(code[type]))
    .filter((lang) =>
      enableMostUsed
        ? !mostUsedLanguages.some((l) => l[type] === lang[type])
        : true
    );

  const mostLanguagesSelected = mostUsedLanguages.every((l) =>
    codesToExclude?.includes(l[type])
  );

  const filterHelper = (
    value: string,
    option?: OptionType & { children: string }
  ) => !!option?.children?.toLowerCase().includes(value.toLowerCase());

  useEffect(() => {
    if (!onChoose || !mostUsedLanguages.length || chosenLanguageCode) return;
    onChoose(mostUsedLanguages[0][type]);
  }, [onChoose, mostUsedLanguages, chosenLanguageCode, type]);

  return (
    <Select
      showSearch={showSearch}
      onSelect={onChoose}
      className={cn(styles.fullWidth, className)}
      {...otherProps}
      filterOption={filterHelper}
      defaultValue={enableMostUsed ? mostUsedLanguages[0]?.label : null}
      placeholder={placeholder}
      loading={isLoadingLanguages}
      value={getLanguageByIsoCode(chosenLanguageCode)?.label}
      disabled={disabled}
    >
      {enableMostUsed && mostUsedLanguages.length && (
        <OptGroup label="Most used">
          {mostUsedLanguages.map((lang) => (
            <Option key={lang.alpha2} value={lang[type]}>
              {lang.label}
            </Option>
          ))}
        </OptGroup>
      )}

      {!enableMostUsed || mostLanguagesSelected ? (
        languageData.map((lang) => (
          <Option key={lang.alpha2} value={lang[type]}>
            {lang.label}
          </Option>
        ))
      ) : (
        <OptGroup label="All">
          {languageData.map((lang) => (
            <Option key={lang.alpha2} value={lang[type]}>
              {lang.label}
            </Option>
          ))}
        </OptGroup>
      )}
    </Select>
  );
};

export default LanguageChooser;
