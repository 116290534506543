import RegionsMenu from 'components/RegionsMenu';
import { useCallback, useMemo, useState } from 'react';
import { Region } from 'types';
import { GlobalOutlined } from '@ant-design/icons';
import PageContainer from 'components/PageContainer';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import {
  GuestAccess,
  GuestAccessConfiguration,
  GuestAccessForm,
} from 'types/guestAccess';
import PageHeader from 'components/PageHeader';
import {
  useFetchActionObj,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import {
  getGuestAccessConfiguration,
  updateGuestAccessConfiguration,
} from 'actions/guestAccess';
import { v4 as uuidv4 } from 'uuid';
import notification from 'utils/notification';
import styles from './index.module.scss';
import GuestAccessDetails from './components/GuestAccessDetails';

const GuestAccessPage = () => {
  const [selectedRegion, setSelectedRegion] = useState<Region>();

  const {
    data: guestAccessData,
    refresh,
    isLoading: isFetching,
  } = useFetchActionObj(useCallback(getGuestAccessConfiguration, []));

  const config = useMemo(
    () =>
      guestAccessData?.data.find((c) => c.regionUuid === selectedRegion?.uuid),
    [guestAccessData, selectedRegion]
  );

  const { sendAction, isLoading: isSaving } = useSendActionObj<
    void,
    GuestAccess
  >(useCallback(updateGuestAccessConfiguration, []), {
    onDone: () => {
      notification.success({
        title: 'Success',
        description: 'Configuration has been saved correctly!',
      });

      refresh();
    },
    onError: (error: any) => {
      notification.error({
        title: 'Error',
        description:
          error?.response?.data?.details ||
          'There was an error during the request, please check provided values or try again later.',
      });
    },
  });

  const form = useForm<GuestAccessForm>({
    defaultValues: {
      assets: [],
      configuration: GuestAccessConfiguration.NONE,
      regionUuid: '',
      uuid: '',
    },
    shouldUnregister: false,
  });

  const onSubmit = useCallback(async () => {
    const { configuration, assets } = form.getValues();

    if (!selectedRegion?.uuid) return;

    const assetUuidList = assets.map((a) => a.uuid);

    if (
      configuration === GuestAccessConfiguration.SELECTED &&
      !assetUuidList?.length
    ) {
      return notification.error({
        title: 'Error',
        description:
          'Please add assets to save configuration in Selected free assets mode',
      });
    }

    const payload = {
      uuid: config?.uuid || uuidv4(),
      regionUuid: selectedRegion.uuid,
      configuration: configuration || GuestAccessConfiguration.NONE,
      assetUuidList,
    };

    sendAction(payload);
  }, [config, form, selectedRegion, sendAction]);

  return (
    <div className={styles.root}>
      <RegionsMenu
        defaultKey={selectedRegion?.uuid}
        onRegionSelect={setSelectedRegion}
        searchClassName={styles.search}
      />

      <PageContainer isLoading={isFetching || isSaving}>
        <PageHeader
          title={selectedRegion?.name || 'Region name not found'}
          extra={[
            <Button type="primary" icon={<GlobalOutlined />} onClick={onSubmit}>
              Publish
            </Button>,
          ]}
        />

        <div className={styles.wrapper}>
          <GuestAccessDetails
            form={form}
            regionUuid={selectedRegion?.uuid}
            config={config}
            key={selectedRegion?.uuid} //Ensures correct re-render when changing region
          />
        </div>
      </PageContainer>
    </div>
  );
};

export default GuestAccessPage;
