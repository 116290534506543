import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import {
  getSourceFileAudioTracks,
  getSourceFileSubtitleTracks,
} from 'actions/files';
import _pick from 'lodash/pick';
import { useCallback, useMemo } from 'react';
import { TranscodeAudioTrack, TranscodeSubtitle, UploadedFile } from 'types';

const useTransformedSourceTracks = (
  file?: UploadedFile
): { audio: TranscodeAudioTrack[]; subtitles: TranscodeSubtitle[] } => {
  const [sourceAudioTracks] = useFetchAction(
    useCallback(() => {
      if (file) return getSourceFileAudioTracks(file);

      return Promise.resolve([]);
    }, [file])
  );

  const [sourceSubtitles] = useFetchAction(
    useCallback(() => {
      if (file) return getSourceFileSubtitleTracks(file.id);

      return Promise.resolve([]);
    }, [file])
  );

  return useMemo(
    () => ({
      audio:
        sourceAudioTracks?.map((audioTrack) => ({
          languageCode: audioTrack.languageCode,
          name: audioTrack.name,
          streamOrder: audioTrack.streamOrder,
          codingMode: audioTrack.codingMode,
          isDefault: audioTrack.isDefault ?? false,
        })) ?? [],
      subtitles:
        sourceSubtitles?.map((subtitle) =>
          _pick(subtitle, ['id', 'type', 'name', 'languageCode'])
        ) ?? [],
    }),
    [sourceAudioTracks, sourceSubtitles]
  );
};

export default useTransformedSourceTracks;
