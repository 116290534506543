import React from 'react';
import { Image as ImagePreview, Spin } from 'antd';
import Space from 'components/Space';
import { Image, ImageStatus } from 'types';
import { ImageType } from '@laminar-product/client-commons-core/core';
import { useAssetDetailsContext } from 'contexts/assetContext';
import ImageRemover from './ImageRemover';
import ImageUpload from './ImageUpload';
import styles from './index.module.scss';

const ImageProcessing = () => (
  <Space direction="vertical" align="center">
    <Spin size="large" />
    <div>Optimizing your image...</div>
  </Space>
);

const ImageView = ({
  image,
  refresh,
  type,
}: {
  image?: Image;
  type: ImageType;
  refresh: () => void;
}) => {
  const {
    asset: { id },
  } = useAssetDetailsContext();

  if (!image) {
    return <ImageUpload assetId={Number(id)} type={type} refresh={refresh} />;
  }

  return (
    <div className={styles.imageViewRoot}>
      {image.status === ImageStatus.TRANSFORMED && (
        <ImagePreview width={300} src={image.url} className={styles.image} />
      )}
      {image.status === ImageStatus.CREATED && <ImageProcessing />}
      <div className={styles.buttonRow}>
        <span className={styles.imageName}>{image.originalName}</span>
        <ImageRemover imageId={image.id} assetId={id} refresh={refresh} />
      </div>
    </div>
  );
};

export default ImageView;
