export enum Paths {
  FILES = '/files',
  ASSETS = '/assets',
  CONTENT = '/content',
  USERS = '/users',
  CLIENTS = '/clients',
  DISTRIBUTORS_CLIENTS = '/distributorsClients',
  AUDIT_LOG = '/auditLog',
  REGIONS = '/regions',
  PLANS = '/plans',
  PAGES = '/pages',
  MENUS = '/menus',
  COLLECTIONS = '/collections',
  GENRES = '/genres',
  MOODS = '/moods',
  APPROPRIATENESS_TAGS = '/appropriateness-tags',
  INTERNAL = '/internal',
  AFFILIATE = '/affiliate',
  CENSORSHIPS = '/censorships',
  PREPAID_CARDS = '/prepaidCards',
  SEO = '/seo',
  CONTENT_DISTRIBUTION = '/contentDistribution',
  PARTNERS = '/partners',
  TAX_MANAGEMENT = '/tax-management',
  LANGUAGES = '/languages',
  LIVE = '/live',
  ASSET_PREVIEW = '/assetPreview',
  AVOD = '/avod',
  GUEST_ACCESS = '/guestAccess',
  INTERESTS = '/interests',
}
