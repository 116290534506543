import React, { useCallback, useEffect, useState } from 'react';
import { searchForAssetParent } from 'actions/assets';
import { Asset } from 'types';
import { getFullAssetPath } from 'utils/path';
import { Select, Spin } from 'antd';
import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';
import styles from './index.module.scss';

const { Option } = Select;

interface AssetParentChooserProps {
  onChoose: (asset?: Asset) => void;
  chosenAsset?: Asset;
  onError?: (error: string) => void;
}

const AssetParentChooser = ({
  onChoose,
  chosenAsset,
  onError,
}: AssetParentChooserProps) => {
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 300);
  const [data, isLoading, error] = useFetchAction<Asset[]>(
    useCallback(
      () =>
        searchForAssetParent({
          query: queryDebounced,
          limit: 15,
        }),
      [queryDebounced]
    )
  );

  useEffect(() => {
    if (onError && error) onError(error.message);
  }, [error, onError]);

  return (
    <Select
      showSearch
      value={chosenAsset?.parentId}
      disabled={!data}
      onChange={(id) => {
        if (!id) {
          onChoose();
          return;
        }
        onChoose(data?.find((d) => d.id === id));
      }}
      placeholder={!data || isLoading ? 'Fetching' : 'Search for parent...'}
      loading={isLoading || !data}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={(value) => setQuery(value)}
      className={styles.fullWidth}
      allowClear
    >
      {data?.map((asset) => (
        <Option key={asset.id} value={asset.id}>
          {getFullAssetPath(asset, []).join(' > ')}
        </Option>
      ))}
    </Select>
  );
};

export default AssetParentChooser;
