import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { skipOptionsVideoPlayerId } from '../../utils';
import OverlayButton from '../OverlayButton';
import TrackPlaybackInput from '../TrackPlaybackInput';
import styles from './index.module.scss';

interface PlayerOverlayProps {
  setValue(key: string, value: string): void;
  className?: string;
}

const PlayerOverlay = ({ setValue, className }: PlayerOverlayProps) => {
  const [currentTime, setCurrentTime] = useState('');
  const onMenuItemClick = useCallback(
    (info: { key: string }) => {
      const key = info.key.split('_').join('.');
      setValue(key, currentTime);
    },
    [currentTime, setValue]
  );

  return (
    <div className={cn(styles.playerOverlay, className)}>
      <TrackPlaybackInput
        playerId={skipOptionsVideoPlayerId}
        onValueChange={setCurrentTime}
      />
      <OverlayButton onMenuItemClick={onMenuItemClick} />
    </div>
  );
};

export default PlayerOverlay;
