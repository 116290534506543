import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AssetLicense,
  BasicAssetLicenseCreate,
  CreateLicenseBasicForm,
} from 'types/licenses';
import moment from 'moment';
import notification from 'utils/notification';
import { createAssetLicenses } from 'actions/license';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { captureError } from 'utils/captureError';
import AddLicenseForm from '../AddLicenseForm';

interface AddLicenseModalProps {
  isOpen?: boolean;
  onClose: () => void;
  refreshLicenseTable: () => void;
  regionsIdsToFilterOut?: number[];
}

const AddLicenseModal = ({
  onClose,
  isOpen,
  refreshLicenseTable,
  regionsIdsToFilterOut,
}: AddLicenseModalProps) => {
  const { asset } = useAssetDetailsContext();
  const formMethods = useForm<CreateLicenseBasicForm>({ mode: 'onChange' });
  const { handleSubmit } = formMethods;

  const onCreateLicensesSuccess = () => {
    notification.success({
      title: 'Success',
      description: 'Licenses have been saved!',
    });
    onClose();
    refreshLicenseTable();
  };

  const onCreateLicensesFailure = (error: any) => {
    notification.error({
      title: 'Error',
      description:
        error?.response?.data?.details ||
        'There was an error during the request, please check provided values or try again later.',
    });

    captureError(error);
  };

  const { sendAction: createLicenses, isLoading } = useSendActionObj<
    AssetLicense[],
    BasicAssetLicenseCreate[]
  >(
    useCallback(
      (licenses) => createAssetLicenses({ assetId: asset.id, licenses }),
      [asset.id]
    ),
    {
      onDone: onCreateLicensesSuccess,
      onError: onCreateLicensesFailure,
    }
  );

  const onSubmit = (values: CreateLicenseBasicForm) => {
    const { dateFrom, dateTo, regions } = values;
    const validTo = moment(dateTo);
    const validFrom = moment(dateFrom);

    if (validTo < validFrom) {
      notification.error({
        title: 'Error',
        description: 'The validity range of the license is invalid',
      });

      return;
    }

    const mappedForm = regions?.map((r) => ({
      regionId: r.id,
      dateTo: moment.utc(validTo).endOf('day').valueOf(),
      dateFrom: moment.utc(validFrom).startOf('day').valueOf(),
    }));

    createLicenses(mappedForm);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title="Add licenses"
      okText="Add licenses"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <AddLicenseForm regionsIdsToFilterOut={regionsIdsToFilterOut} />
      </FormProvider>
    </Modal>
  );
};

export default AddLicenseModal;
