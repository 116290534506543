import { Image } from 'types';
import { ImageType } from '@laminar-product/client-commons-core/core';

export const initialAssetImageState: AssetImageState = {
  image: undefined,
  file: undefined,
  preview: undefined,
  error: undefined,
  loading: undefined,
};

type GenericImageType<T> = Record<ImageType, T>;

export interface AssetImageState {
  image?: Partial<GenericImageType<Image>>;
  file?: Partial<GenericImageType<File>>;
  preview?: Partial<GenericImageType<string>>;
  loading?: Partial<GenericImageType<boolean>>;
  error?: Partial<GenericImageType<string>>;
}

export enum AssetImageActionType {
  FILE = 'file',
  PREVIEW = 'preview',
  IMAGE = 'image',
  ERROR = 'error',
  LOADING = 'loading',
}

interface AssetImageFileAction {
  type: AssetImageActionType.FILE;
  payload: AssetImageState['file'];
}

interface AssetImagePreviewAction {
  type: AssetImageActionType.PREVIEW;
  payload: AssetImageState['preview'];
}

interface AssetImageErrorAction {
  type: AssetImageActionType.ERROR;
  payload: AssetImageState['error'];
}

interface AssetImageLoadingAction {
  type: AssetImageActionType.LOADING;
  payload: AssetImageState['loading'];
}

interface AssetImageImageAction {
  type: AssetImageActionType.IMAGE;
  payload: AssetImageState['image'];
}

export type AssetImageActions =
  | AssetImageFileAction
  | AssetImagePreviewAction
  | AssetImageErrorAction
  | AssetImageLoadingAction
  | AssetImageImageAction;

const stateExtractor = (
  state: AssetImageState,
  type: keyof AssetImageState,
  payload: AssetImageActions['payload']
) => {
  return { ...state[type], ...payload };
};

export function imageContextReducer(
  state: AssetImageState,
  action: AssetImageActions
) {
  switch (action.type) {
    case AssetImageActionType.FILE:
      return { ...state, file: stateExtractor(state, 'file', action.payload) };
    case AssetImageActionType.IMAGE:
      return {
        ...state,
        image: stateExtractor(state, 'image', action.payload),
      };
    case AssetImageActionType.PREVIEW:
      return {
        ...state,
        preview: stateExtractor(state, 'preview', action.payload),
      };
    case AssetImageActionType.LOADING:
      return {
        ...state,
        loading: stateExtractor(state, 'loading', action.payload),
      };
    case AssetImageActionType.ERROR:
      return {
        ...state,
        error: stateExtractor(state, 'error', action.payload),
      };
  }
}
