import { CouponType, DiscountType } from 'types/promotions';
import {
  AssetType,
  ImageType,
} from '@laminar-product/client-commons-core/core';
import { BatchPinExportStatus, PrepaidCardStatus } from 'types/prepaidCard';
import { AssetStatus } from 'types';

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const STATIC_URL = import.meta.env.VITE_STATIC_URL;
export const CAN_UPLOAD_SOURCE_FILES =
  import.meta.env.VITE_CAN_UPLOAD_SOURCE_FILES === 'true';
export const CAN_TRANSCODE_FILES = CAN_UPLOAD_SOURCE_FILES;

export const PAGINATION_SIZE = 20;
export const NESTED_PAGINATION_SIZE = 10;
export const MAX_PAGINATION_LIMIT = 100;

const ASPECT_RATIO_16_9 = '16:9';

const ASPECT_RATIO_235_1 = '2.35:1';

const ASPECT_RATIO_2_3 = '2:3';

const ASPECT_RATIO_1 = '1:1';

const ASPECT_RATIO_05 = '0.5';

const ASPECT_RATIO_358 = '3.58';

const ASPECT_RATIO_1777 = '1.777';

const ASPECT_RATIO_43 = '4:3';

export const IMAGE_TYPES_WITHOUT_CONTENT_DISTRIBUTORS = Object.values(
  ImageType
)?.filter((type) => ![ImageType.featureBannerHD].includes(type));

export const IMAGE_TYPE_DESCRIPTIONS: {
  [key in ImageType]: { aspectRatio: string; label: string; extra?: string };
} = {
  [ImageType.jumbotron]: {
    aspectRatio: ASPECT_RATIO_16_9,
    label: 'Background image',
    extra: 'JPG, 2560x1440',
  },
  [ImageType.thumbnail]: {
    aspectRatio: ASPECT_RATIO_16_9,
    label: 'Carousel image with logo',
    extra: 'JPG, 1400x788',
  },
  [ImageType.child]: {
    aspectRatio: ASPECT_RATIO_16_9,
    label: 'Episode image',
    extra: 'JPG, 2560x1440',
  },
  [ImageType.logo]: {
    aspectRatio: ASPECT_RATIO_235_1,
    label: 'Logo left',
    extra: 'PNG, 1568x664',
  },
  [ImageType.portrait]: {
    aspectRatio: ASPECT_RATIO_2_3,
    label: 'Portrait image',
    extra: 'JPG, 630x945',
  },
  [ImageType.square]: {
    aspectRatio: ASPECT_RATIO_1,
    label: 'Square image',
    extra: 'JPG, 2048x2048',
  },
  [ImageType.logoCentred]: {
    aspectRatio: ASPECT_RATIO_235_1,
    label: 'Logo centre',
    extra: 'PNG, 1568x664',
  },
  [ImageType.skyscraperWithLogo]: {
    aspectRatio: ASPECT_RATIO_05,
    label: 'Skyscraper with logo',
    extra: 'JPG, 1440x2880',
  },
  [ImageType.billboard]: {
    aspectRatio: ASPECT_RATIO_358,
    label: 'Billboard',
    extra: 'JPG, 2560x716',
  },
  [ImageType.backgroundCentred]: {
    aspectRatio: ASPECT_RATIO_1777,
    label: 'Background centred',
    extra: 'JPG, 2560x1440',
  },
  [ImageType.featureBannerHD]: {
    aspectRatio: '',
    label: 'Feature Banner HD',
    extra: 'JPG, 2280x699, 300 PPI',
  },
  [ImageType.squareLogo]: {
    aspectRatio: ASPECT_RATIO_1,
    label: 'Square with logo',
    extra: 'JPG, 2048x2048',
  },
  [ImageType.rectangle43Logo]: {
    aspectRatio: ASPECT_RATIO_43,
    label: 'Rectangle 4:3 with logo',
    extra: 'JPG, 2560x1920',
  },
};

export const CODING_TYPES = [
  {
    value: 'CODING_MODE_1_0',
    label: 'Mono',
  },
  {
    value: 'CODING_MODE_2_0',
    label: 'Stereo',
  },
  {
    value: 'CODING_MODE_5_1',
    label: '5.1',
  },
];

export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_DAYFIRST_FORMAT = 'DD/MM/YYYY';

export const contentTypes = [
  {
    label: 'Main',
    value: 'MAIN',
  },
  {
    label: 'Trailer',
    value: 'TRAILER',
  },
];

export const assetTypes = [
  {
    label: 'MOVIE',
    value: 'MOVIE',
  },
  {
    label: 'EPISODE',
    value: 'EPISODE',
  },
  {
    label: 'SEASON',
    value: 'SEASON',
  },
  {
    label: 'SHOW',
    value: 'SHOW',
  },
];

export enum PeriodSelectorLabels {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
}

export const periodSelectorValues = [
  {
    label: 'Day',
    value: 'DAY',
  },
  {
    label: 'Week',
    value: 'WEEK',
  },
  {
    label: 'Month',
    value: 'MONTH',
  },
  {
    label: 'Year',
    value: 'YEAR',
  },
];

export const promotionTypes = [
  {
    label: 'Free for a certain period',
    value: DiscountType.PERIOD,
  },
  {
    label: 'Discount on percentage',
    value: DiscountType.PERCENTAGE,
  },
  {
    label: 'Discount on amount',
    value: DiscountType.AMOUNT,
  },
];

export const couponTypes = [
  {
    label: 'Single use',
    value: CouponType.SINGLE,
  },
  {
    label: 'Multi use',
    value: CouponType.MULTI,
  },
];

export const batchPinStatuses = [
  {
    label: 'All',
    value: BatchPinExportStatus.BOTH,
  },
  {
    label: 'Not redeemed',
    value: BatchPinExportStatus.NOT_REDEEMED,
  },
  {
    label: 'Redeemed',
    value: BatchPinExportStatus.REDEEMED,
  },
];

export const prepaidCardStatuses = [
  {
    label: 'Draft',
    value: PrepaidCardStatus.DRAFT,
  },
  {
    label: 'Published',
    value: PrepaidCardStatus.PUBLISHED,
  },
  {
    label: 'Unpublished',
    value: PrepaidCardStatus.UNPUBLISHED,
  },
];

export enum Permission {
  SAVE_ASSET = 'SAVE_ASSET',
  GET_ASSET = 'GET_ASSET',
  EDIT_ASSET = 'EDIT_ASSET',
  PUBLISH_ASSET = 'PUBLISH_ASSET',
  EDIT_ASSET_CHILDREN_ORDER = 'EDIT_ASSET_CHILDREN_ORDER',
  PUBLISH_ASSET_CHILDREN_ORDER = 'PUBLISH_ASSET_CHILDREN_ORDER',
  SAVE_CONTENT = 'SAVE_CONTENT',
  EDIT_CONTENT = 'EDIT_CONTENT',
  GET_CONTENT = 'GET_CONTENT',
  GET_SOURCE = 'GET_SOURCE',
  UPLOAD_SOURCE = 'UPLOAD_SOURCE',
  TRANSCODE_SOURCE = 'TRANSCODE_SOURCE',
  GET_TRANSCODED = 'GET_TRANSCODED',
  EDIT_TRANSCODED = 'EDIT_TRANSCODED',
  GET_PLAN = 'GET_PLAN',
  SAVE_PLAN = 'SAVE_PLAN',
  EDIT_PLAN = 'EDIT_PLAN',
  PUBLISH_PLAN = 'PUBLISH_PLAN',
  GET_USER = 'GET_USER',
  ADD_USER = 'ADD_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  GET_LIVE = 'GET_LIVE',
  EDIT_LIVE = 'EDIT_LIVE',
  GET_CLIENT = 'GET_CLIENT',
  GET_IMAGE = 'GET_IMAGE',
  EDIT_IMAGE = 'EDIT_IMAGE',
  GET_COUPON = 'GET_COUPON',
  ADD_COUPON = 'ADD_COUPON',
  EDIT_COUPON = 'EDIT_COUPON',
  SAVE_CENSORSHIP = 'SAVE_CENSORSHIP',
  GET_CENSORSHIP = 'GET_CENSORSHIP',
  EDIT_CENSORSHIP = 'EDIT_CENSORSHIP',
  GET_COLLECTION = 'GET_COLLECTION',
  SAVE_COLLECTION = 'SAVE_COLLECTION',
  EDIT_COLLECTION = 'EDIT_COLLECTION',
  PUBLISH_COLLECTION = 'PUBLISH_COLLECTION',
  GET_REGION = 'GET_REGION',
  SAVE_REGION = 'SAVE_REGION',
  EDIT_REGION = 'EDIT_REGION',
  GET_LICENSE = 'GET_LICENSE',
  GET_TAG = 'GET_TAG',
  SAVE_TAG = 'SAVE_TAG',
  GET_CREW = 'GET_CREW',
  SAVE_CREW = 'SAVE_CREW',
  SAVE_ASSET_TYPE = 'SAVE_ASSET_TYPE',
  GET_ASSET_TYPE = 'GET_ASSET_TYPE',
  DELETE_CREW = 'DELETE_CREW',
  GET_GENRE = 'GET_GENRE',
  EDIT_GENRE = 'EDIT_GENRE',
  EDIT_MOOD = 'EDIT_MOOD',
  SAVE_GENRE = 'SAVE_GENRE',
  GET_MOOD = 'GET_MOOD',
  SAVE_MOOD = 'SAVE_MOOD',
  EDIT_USER = 'EDIT_USER',
  FULL_ROLE_MANAGEMENT = 'FULL_ROLE_MANAGEMENT',
  LIMITED_ROLE_MANAGEMENT = 'LIMITED_ROLE_MANAGEMENT',
  PROLONG_SUBSCRIPTION = 'PROLONG_SUBSCRIPTION',
  REMOVE_SUBSCRIPTION_MANUAL = 'REMOVE_SUBSCRIPTION_MANUAL',
  CREATE_SUBSCRIPTION_MANUAL = 'CREATE_SUBSCRIPTION_MANUAL',
  CONTENT_DISTRIBUTION_READ = 'CONTENT_DISTRIBUTION_READ',
  CONTENT_DISTRIBUTION_CREATE = 'CONTENT_DISTRIBUTION_CREATE',
  CONTENT_DISTRIBUTION_DELETE = 'CONTENT_DISTRIBUTION_DELETE',
  CONTENT_DISTRIBUTION_EDIT = 'CONTENT_DISTRIBUTION_EDIT',
  CONTENT_DISTRIBUTION_PUBLISH = 'CONTENT_DISTRIBUTION_PUBLISH',
  PREPAID_PUBLISH = 'PREPAID_PUBLISH',
  PREPAID_UNPUBLISH = 'PREPAID_UNPUBLISH',
  TAX_CONFIGURATION_EDIT = 'TAX_CONFIGURATION_EDIT',
  PLATFORM_PARTNER_READ = 'PLATFORM_PARTNER_READ',
  PLATFORM_PARTNER_CREATE = 'PLATFORM_PARTNER_CREATE',
  PLATFORM_PARTNER_EDIT = 'PLATFORM_PARTNER_EDIT',
  PLATFORM_PARTNER_PUBLISH = 'PLATFORM_PARTNER_PUBLISH',
  PLATFORM_PARTNER_DELETE = 'PLATFORM_PARTNER_DELETE',
  PLATFORM_PARTNER_UNPUBLISH = 'PLATFORM_PARTNER_UNPUBLISH',
  GET_MODERATION = 'GET_MODERATION',
  SAVE_MODERATION = 'SAVE_MODERATION',
  EDIT_MODERATION = 'EDIT_MODERATION',
  EDIT_PLATFORM_LANGUAGES = 'EDIT_PLATFORM_LANGUAGES',
  GET_PLATFORM_LANGUAGES = 'GET_PLATFORM_LANGUAGES',
  GET_PAGE = 'GET_PAGE',
  SAVE_PAGE = 'SAVE_PAGE',
  EDIT_PAGE = 'EDIT_PAGE',
  PUBLISH_PAGE = 'PUBLISH_PAGE',
  GET_MENU = 'GET_MENU',
  SAVE_MENU = 'SAVE_MENU',
  EDIT_MENU = 'EDIT_MENU',
  PUBLISH_MENU = 'PUBLISH_MENU',

  SIGN_OUT_ADMIN_USER_FROM_ALL_DEVICES = 'SIGN_OUT_ADMIN_USER_FROM_ALL_DEVICES',
  SIGN_OUT_CLIENT_USER_FROM_ALL_DEVICES = 'SIGN_OUT_CLIENT_USER_FROM_ALL_DEVICES',

  VIEW_AUDIT_LOGS = 'VIEW_AUDIT_LOGS',
  EXPORT_AUDIT_LOGS = 'EXPORT_AUDIT_LOGS',

  PAYMENT_LINK_GET = 'PAYMENT_LINK_GET',
  PAYMENT_LINK_GENERATE = 'PAYMENT_LINK_GENERATE',
}

export const PREVIEW_MODE = 'previewMode';

export const DEFAULT_LANGUAGE_CODE = 'en';

export const MAX_TOTAL_COUPONS_COUNT = 100000;

export const ALL_BATCHES_VALUE = 'ALL';

export const DEFAULT_ASSET_STATUSES_FOR_PAGINATION = [
  AssetStatus.SCHEDULED,
  AssetStatus.PUBLISHED,
];

export const DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET = [
  AssetType.MOVIE,
  AssetType.SHOW,
  AssetType.LIVE_CHANNEL,
  AssetType.LIVE_EVENT,
];

export const CREATE_NEW_COLLECTION_ROUTE = 'create-new-collection';
export const MIN_QUERY_LENGTH_CLIENT_SEARCH = 3;
