import React, { useCallback, useState } from 'react';
import Button from 'components/Button';
import FormContainer from 'components/FormContainer';
import { User } from 'types';
import { Input } from 'antd';
import { editUser } from 'actions/user';
import notification from 'utils/notification';
import { Controller, useForm } from 'react-hook-form';
import { isEmail, isPhoneNumber } from 'utils/validators';
import { UserClientData } from 'types/user';
import FormGroup from 'components/FormGroup';
import styles from '../index.module.scss';
import { handleChangeUserDataError } from './helpers';

interface UserClientsChangeUserProps {
  user?: User;
  onClose: () => void;
  refresh: () => void;
}

const UserClientsChangeUser = ({
  user,
  onClose,
  refresh,
}: UserClientsChangeUserProps) => {
  const [isSavingUserChanges, setIsSavingUserChanges] = useState(false);
  const { control, handleSubmit, errors } = useForm<UserClientData>({
    defaultValues: {
      email: user?.email,
      phoneNumber: user?.phoneNumber || undefined,
    },
  });
  const validate = (val: string, type: 'email' | 'phoneNumber') => {
    if (type === 'email' && val?.length > 0 && !isEmail(val)) {
      return 'Please provide a valid email address!';
    }

    if (
      type === 'phoneNumber' &&
      val?.length > 0 &&
      val.length < 9 &&
      !isPhoneNumber(val)
    ) {
      return 'PhoneNumber must contain at least 9 characters.';
    }
  };

  const sendForm = useCallback(
    async (data: UserClientData) => {
      if (!user) return;
      if (!data.email && !data.phoneNumber) {
        notification.error({
          title: 'Error',
          description: 'At least one the fields is required.',
        });
        return;
      }

      setIsSavingUserChanges(true);

      await editUser(user.uid, {
        email: data.email,
        phoneNumber: data.phoneNumber,
      })
        .then(() => {
          notification.success({
            title: 'Success',
            description: 'The user has been edited.',
          });
          onClose();
          refresh();
        })
        .catch((e) => {
          handleChangeUserDataError(e);
        })
        .finally(() => {
          setIsSavingUserChanges(false);
        });
    },
    [onClose, refresh, user]
  );

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(sendForm)}>
        <FormGroup
          input={
            <Controller
              as={Input}
              control={control}
              disabled={!!user?.email}
              name="email"
              rules={{
                validate: (value) => validate(value, 'email'),
              }}
            />
          }
          label="Email"
        />

        {errors?.email && (
          <div className={styles.errorMessage}>{errors?.email?.message}</div>
        )}

        <div>
          <FormGroup
            input={
              <Controller
                as={Input}
                control={control}
                name="phoneNumber"
                disabled={!!user?.phoneNumber}
                rules={{
                  validate: (value) => validate(value, 'phoneNumber'),
                }}
              />
            }
            label="Phone number"
            labelDescription="with country code"
          />
          {errors?.phoneNumber && (
            <div className={styles.errorMessage}>
              Please provide correct phone number with country code
            </div>
          )}
        </div>

        <div className={styles.modalButtons}>
          <Button type="default" htmlType="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={!!user?.email && !!user.phoneNumber}
            loading={isSavingUserChanges}
          >
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};

export default UserClientsChangeUser;
