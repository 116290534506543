import React, { useEffect, useState } from 'react';
import { Asset, Collection } from 'types';
import { Table } from 'antd';
import { DragHandle, HideOnDrag } from 'components/DraggableTableItems';
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import DragAndDropTable from 'components/DragAndDropTable';
import { Link } from 'react-router-dom';
import { getAssetsPagination } from 'actions/assets';
import { Paths } from 'types/paths';
import formatLabel from 'utils/formatLabel';
import styles from '../../index.module.scss';

const { Column } = Table;

const CollectionAssetTable = ({
  collection,
  onRemoveAutoPopulated,
  onEditAutoPopulated,
  onCollectionChange,
  onAssetWithDetailsChange,
  assetsWithDetails,
}: {
  collection: Collection;
  assetsWithDetails: Asset[];
  onAssetWithDetailsChange: (assets: Asset[]) => void;
  onEditAutoPopulated: () => void;
  onRemoveAutoPopulated: () => void;
  onCollectionChange: (collection: Collection) => void;
}) => {
  const { assets } = collection;
  const [isAssetsFetching, setIsAssetsFetching] = useState<boolean>();
  const [assetsFetched, setAssetsFetched] = useState<boolean>();

  const isAutopopulatedExist = (): boolean => {
    return Object.values(collection.filters ? collection.filters : {}).some(
      (filter) => filter && filter.length
    );
  };

  // run until asset by uuids are fetched
  useEffect(() => {
    if (assets?.length && !assetsFetched) {
      setIsAssetsFetching(true);
      getAssetsPagination({
        assetUuids: assets?.map(({ uuid }) => uuid),
        limit: assets?.length,
      }).then((response) => {
        setIsAssetsFetching(false);
        setAssetsFetched(true);
        onAssetWithDetailsChange(
          response.data
            .map((asset) => ({
              ...asset,
              order: assets.find((a) => a.uuid === asset.uuid)?.order,
            }))
            .sort((a, b) => (a.order && b.order ? a.order - b.order : -1))
        );
      });
    }
  }, [assets, assetsFetched, onAssetWithDetailsChange]);

  const onRemoveAsset = (asset: Asset) => {
    if (asset && collection) {
      const removeAndSort = (_assets: Asset[]) =>
        _assets
          ?.filter((a) => a.order !== asset.order)
          .map((asset, index) => ({ ...asset, order: index }));

      onCollectionChange({
        ...collection,
        assets: removeAndSort(assets!),
      });
      onAssetWithDetailsChange(removeAndSort(assetsWithDetails));
    }
  };

  const onOrderChange = (assets: Asset[]) =>
    onCollectionChange({ ...collection, assets });

  if (isAssetsFetching) {
    return <Table loading={isAssetsFetching} />;
  }

  return (
    <>
      {(!!assetsWithDetails.length || !isAutopopulatedExist()) && (
        <DragAndDropTable
          dataSource={assetsWithDetails}
          onOrderChange={(data) => {
            onAssetWithDetailsChange(data);
            onOrderChange(data);
          }}
          bordered={false}
          pagination={false}
          rowKey="order"
          size="small"
        >
          <Column
            width={30}
            title="Sort"
            dataIndex="sort"
            render={() => <DragHandle />}
          />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            render={(name, row: Asset) => (
              <Link to={`${Paths.ASSETS}/${row.uuid}`}>{name}</Link>
            )}
          />
          <Column
            title="Asset type"
            dataIndex="type"
            key="type"
            render={(type, asset: Asset) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {formatLabel(type)}
                <div className={styles.actionsWrapper}>
                  <HideOnDrag>
                    <DeleteOutlined
                      className={styles.tableIcon}
                      onClick={() => onRemoveAsset(asset)}
                    />
                  </HideOnDrag>
                </div>
              </div>
            )}
          />
        </DragAndDropTable>
      )}

      {collection.filters && isAutopopulatedExist() && (
        <Table
          bordered={false}
          pagination={false}
          showHeader={!assetsWithDetails.length}
          dataSource={[{ name: 'Auto-populated' }]}
          size="small"
        >
          <Column
            width={59}
            title="Sort"
            dataIndex="sort"
            render={() => <DragHandle disabled />}
          />
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            render={(type, autoPopulated: { name: string }) => (
              <span className={styles.italic}>{autoPopulated.name}</span>
            )}
          />
          <Column
            title="Asset type"
            dataIndex="type"
            key="type"
            render={() => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className={styles.actionsWrapper}>
                  <SettingOutlined
                    className={styles.tableIcon}
                    onClick={() => onEditAutoPopulated()}
                  />
                  <DeleteOutlined
                    className={styles.tableIcon}
                    onClick={() => onRemoveAutoPopulated()}
                  />
                </div>
              </div>
            )}
          />
        </Table>
      )}
    </>
  );
};

export default CollectionAssetTable;
