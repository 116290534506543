import { Pagination } from 'types';
import { BasicPublishedPlan, Plan, PlanOptions, PlanPrice } from 'types/plan';
import { PAGINATION_SIZE, pageToSkip } from 'utils/pagination';
import { Interest } from 'types/interests';
import { isObject } from 'lodash';
import api from './api';

const convertPlan = (plan: Plan) => ({
  ...plan,
  // todo: Backend should return always string[] instead of whole object. As it is in all of the other places.
  interests: (() => {
    const checkIfArrayContainsObjects = (plan.interests || []).some((i: any) =>
      isObject(i)
    );
    // note: that is a workaround for the backend inconsistency. That will keep backward compatibility.
    return checkIfArrayContainsObjects
      ? (plan.interests as unknown as Interest[]).map(({ name }) => name) || []
      : plan.interests || [];
  })(),
});

export const getPlansPagination = async ({
  interests,
  name,
  page,
  regionUuid,
}: {
  page?: number;
  regionUuid?: string;
  name?: string;
  interests?: string[];
}): Promise<Pagination<Plan>> => {
  const { data } = await api().get(`/plan`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      regionUuid,
      name,
      interests,
    },
  });

  return {
    ...data,
    data: data.data.map(convertPlan),
  };
};

export const getSubscriptionOptions = async (): Promise<PlanOptions> => {
  const { data } = await api().get(`/plan/option`);
  return data;
};

export const createPlan = async ({
  administrativeName,
  regionUuid,
}: {
  administrativeName: string;
  regionUuid: string;
}): Promise<Plan> => {
  const { data } = await api().post(`/plan`, {
    administrativeName,
    regionUuid,
  });
  return data;
};

export const getPlan = async ({ id }: { id: string }): Promise<Plan> => {
  const { data } = await api().get<Plan>(`/plan/${id}`);
  return convertPlan(data);
};

export const editPlan = async ({
  plan,
  id,
}: {
  id: string;
  plan: Plan;
}): Promise<Plan> => {
  const { region, ...payload } = plan;
  const { data } = await api().patch(`/plan/${id}`, {
    ...payload,
    regionUuid: plan.region.uuid,
  });

  return data;
};

export const addAssetsToPlan = async ({
  assetUuids,
  planUuid,
}: {
  assetUuids: string[];
  planUuid: string;
}): Promise<Plan> => {
  const { data } = await api().patch<Plan>(
    `/plan/${planUuid}/asset`,
    assetUuids
  );

  return data;
};

export const deleteAssetFromPlan = async ({
  uuid,
  planUuid,
}: {
  uuid: string;
  planUuid: string;
}): Promise<string> => {
  await api().delete(`/plan/${planUuid}/asset/${uuid}`);

  return uuid;
};

export const getPlanPrices = async (planUuid: string): Promise<PlanPrice[]> => {
  const { data } = await api().get<PlanPrice[]>(`/plan/${planUuid}/price`);

  return data;
};

export const addPriceToPlan = async ({
  planUuid,
  ...payload
}: Omit<PlanPrice, 'promotions' | 'uuid'>): Promise<PlanPrice> => {
  const { data } = await api().post<PlanPrice>(
    `/plan/${planUuid}/price`,
    payload
  );

  return data;
};

export const removePriceFromPlan = async ({
  planUuid,
  priceUuid,
}: {
  planUuid: string;
  priceUuid: string;
}): Promise<string> => {
  await api().delete<void>(`/plan/${planUuid}/price/${priceUuid}`);
  return priceUuid;
};

export const editPriceFromPlan = async ({
  planUuid,
  priceUuid,
  ...distributionChannels
}: {
  planUuid: string;
  priceUuid: string;
}): Promise<PlanPrice> => {
  const { data } = await api().put<PlanPrice>(
    `/plan/${planUuid}/price/${priceUuid}`,
    distributionChannels
  );
  return data;
};

export const addPromotionToPrice = async ({
  promotionUuid,
  priceUuid,
}: {
  promotionUuid: string;
  priceUuid: string;
}): Promise<PlanPrice> => {
  const { data } = await api().patch<PlanPrice>(`/price/${priceUuid}/`, {
    promotionUuid,
  });

  return data;
};

export const removePromotionFromPrice = async ({
  promotionUuid,
  priceUuid,
}: {
  promotionUuid: string;
  priceUuid: string;
}): Promise<PlanPrice> => {
  const { data } = await api().delete<PlanPrice>(
    `/price/${priceUuid}/promotion/${promotionUuid}`
  );
  return data;
};

export const publishPlan = async (planUuid: string) => {
  const { data } = await api().put(`/plan/${planUuid}/publish`);

  return data;
};

export const unpublishPlan = async (planUuid: string) => {
  const { data } = await api().put(`/plan/${planUuid}/unpublish`);

  return data;
};

//NOTE: As consulted with BE this endpoint should return only published plans with posibility to search by name
export const getPublishedPlansByName = async (
  name?: string
): Promise<BasicPublishedPlan[]> => {
  const { data } = await api().get(`/plan/published`, {
    params: {
      name,
    },
  });

  return data;
};
