const assetTypes = [
  {
    label: 'MOVIE',
    value: 'MOVIE',
  },
  {
    label: 'EPISODE',
    value: 'EPISODE',
  },
  {
    label: 'SEASON',
    value: 'SEASON',
  },
  {
    label: 'SHOW',
    value: 'SHOW',
  },
];

export const rootAssetTypes = [
  {
    label: 'MOVIE',
    value: 'MOVIE',
  },
  {
    label: 'SHOW',
    value: 'SHOW',
  },
];

export default assetTypes;
