import { Alert, Radio, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { CollectionDisplayRule, CollectionDisplayRuleForm } from 'types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AssetType } from '@laminar-product/client-commons-core/core';
import _capitalize from 'lodash/capitalize';
import displayRuleComingSoonImg from 'assets/display-rule-coming-soon.png';
import displayRuleReleasingImg from 'assets/display-rule-releasing.png';
import displayRuleReleasedImg from 'assets/display-rule-released.png';
import styles from '../../../../index.module.scss';

interface CollectionDisplayRuleRadioProps {
  displayRule: CollectionDisplayRuleForm;
}

interface CollectionDisplayRuleRadioConfig {
  label: string;
  appliesToTypes: AssetType[];
  description: React.ReactNode;
  extraNote?: React.ReactNode;
  imgSource?: string;
}

const CollectionDisplayRuleRadio = ({
  displayRule,
}: CollectionDisplayRuleRadioProps) => {
  const getConfig = useCallback(():
    | CollectionDisplayRuleRadioConfig
    | undefined => {
    switch (displayRule) {
      case 'ALL':
        return {
          label: 'All',
          appliesToTypes: Object.values(AssetType),
          description:
            'All assigned assets will be visible in this collection.',
        };
      case CollectionDisplayRule.COMING_SOON:
        return {
          label: 'Coming soon',
          appliesToTypes: [
            AssetType.MOVIE,
            AssetType.SHOW,
            AssetType.SEASON,
            AssetType.EPISODE,
          ],
          description: (
            <div>
              <b>Movie & Episode</b> is in status <b>Coming Soon</b> if:
              <ul>
                <li>Asset is published and Main Video Content is scheduled,</li>
                <li>Asset is published without Main Content Video.</li>
              </ul>
              <b>Season & Show</b> is in status <b>Coming Soon</b> if there is
              no episode with Main Content Video published.
            </div>
          ),
          extraNote: (
            <Alert
              type="info"
              message={
                <div>
                  Please note that by selecting this option{' '}
                  <b>
                    Live Events and Live Channels <u>WON'T</u>
                  </b>{' '}
                  be visible in this collection.
                </div>
              }
            />
          ),
          imgSource: displayRuleComingSoonImg,
        };
      case CollectionDisplayRule.RELEASED:
        return {
          label: 'Released',
          appliesToTypes: [
            AssetType.MOVIE,
            AssetType.SHOW,
            AssetType.SEASON,
            AssetType.EPISODE,
          ],
          description: (
            <div>
              <b>Season & Show</b> is in the status <b>Released</b> if all
              published episodes have published Main Video Content. <br />{' '}
              <b>Movie & Episode</b> is in status <b>Released</b> if it has Main
              Video Content published.
            </div>
          ),
          extraNote: (
            <Alert
              type="info"
              message={
                <div>
                  Please note that by selecting this option{' '}
                  <b>
                    Live Events and Live Channels <u>WON'T</u>
                  </b>{' '}
                  be visible in this collection.
                </div>
              }
            />
          ),
          imgSource: displayRuleReleasedImg,
        };
      case CollectionDisplayRule.RELEASING:
        return {
          label: 'Releasing',
          appliesToTypes: [AssetType.SHOW, AssetType.SEASON],
          description: (
            <div>
              <b>Season & Show</b> is in status <b>Releasing</b> if at least one
              episode is not published with Main Video Content and at least one
              episode in published with Main Video Content.
            </div>
          ),
          extraNote: (
            <Alert
              type="info"
              message={
                <div>
                  Please note that by selecting this option only{' '}
                  <b>Shows and Seasons</b> will be visible in this collection.
                </div>
              }
            />
          ),
          imgSource: displayRuleReleasingImg,
        };
    }
  }, [displayRule]);

  const config = getConfig();

  const getTooltipBody = useCallback(() => {
    if (!config) {
      return null;
    }

    const { appliesToTypes, label, description, extraNote, imgSource } = config;

    return (
      <div className={styles.displayRuleTooltipWrapper}>
        <h2>{label}</h2>

        <div className={styles.tooltipAssetTypeWrapper}>
          Applies to
          {appliesToTypes.map((type) => (
            <span className={styles.tooltipAssetType}>{_capitalize(type)}</span>
          ))}
        </div>

        {imgSource ? (
          <img src={imgSource} alt="" className={styles.tooltipImage} />
        ) : null}

        <div className={styles.tooltipDescription}>{description}</div>

        {extraNote ? (
          <div className={styles.tooltipExtraNote}>{extraNote} </div>
        ) : null}
      </div>
    );
  }, [config]);

  if (!config) {
    return null;
  }

  return (
    <Radio value={displayRule}>
      {config.label}
      <Tooltip
        overlay={getTooltipBody()}
        color="white"
        overlayClassName={styles.displayRuleTooltipInner}
      >
        <InfoCircleOutlined className={styles.displayRuleInfoIcon} />
      </Tooltip>
    </Radio>
  );
};

export default CollectionDisplayRuleRadio;
