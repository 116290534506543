import { Button } from 'antd';
import AssetsTableSearchable from 'components/AssetsTableSearchable';
import PageCard from 'components/PageCard';
import React, { useState } from 'react';
import { Asset } from 'types';
import { mapAssetListToTableStructure } from 'utils/table';
import { DeleteOutlined } from '@ant-design/icons';
import AssetAddToEntityModal from 'components/AssetAddToEntityModal';
import { NESTED_PAGINATION_SIZE } from 'utils/constants';
import styles from './index.module.scss';

interface ContentDistributionConfigAssetsProps {
  assignedAssets: Asset[];
  setAssignedAssets: (assets: Asset[]) => void;
  regionUuid?: string;
}

const ContentDistributionConfigAssets = ({
  assignedAssets,
  setAssignedAssets,
  regionUuid,
}: ContentDistributionConfigAssetsProps) => {
  const [query, setQuery] = useState<string>('');
  const [assetAddToConfigModalOpen, setAssetAddToConfigModalOpen] =
    useState(false);

  const onAddAssets = (assets: Asset[]) =>
    setAssignedAssets([...assignedAssets, ...assets]);

  const onRemoveAsset = (uuid: string) => {
    const filtered = assignedAssets?.filter((asset) => asset.uuid !== uuid);
    setAssignedAssets(filtered);
  };

  const data = mapAssetListToTableStructure({
    assets: assignedAssets,
    query,
    actionColumn: (asset: Asset) => (
      <div className={styles.actionCel}>
        <DeleteOutlined onClick={() => onRemoveAsset(asset.uuid)} />
      </div>
    ),
  });

  return (
    <>
      <PageCard
        title={`Assets in configuration (${assignedAssets?.length})`}
        headerActionButton={
          <Button
            type="primary"
            onClick={() => setAssetAddToConfigModalOpen(true)}
          >
            Add
          </Button>
        }
        children={
          <AssetsTableSearchable
            setSearchQuery={setQuery}
            data={data}
            pagination={{
              pageSize: NESTED_PAGINATION_SIZE,
              showSizeChanger: false,
            }}
          />
        }
      />

      {regionUuid && assetAddToConfigModalOpen && (
        <AssetAddToEntityModal
          modalTitle="Add assets to configuration"
          selectedAssetsUuids={assignedAssets?.map((a) => a.uuid)}
          regionUuids={[regionUuid]}
          open={assetAddToConfigModalOpen}
          onClose={() => setAssetAddToConfigModalOpen(false)}
          onAdd={onAddAssets}
        />
      )}
    </>
  );
};

export default ContentDistributionConfigAssets;
