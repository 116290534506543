import { RootState } from 'store/index';
import { DictionaryType } from './types';

export const getDictionary = (
  state: RootState,
  dictionaryType: DictionaryType
) => state.dictionaries[dictionaryType];

export const getError = (state: RootState, type: string) =>
  state.dictionaries.error && state.dictionaries.error[type];
