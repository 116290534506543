import React, { useState } from 'react';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import {
  usePlatformLanguages,
  useUpdatePlatformLanguages,
} from 'hooks/useLanguages';
import cn from 'classnames';
import { Language } from '@laminar-product/client-commons-core/core';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import AddLanguagesModal from './AddLanguagesModal';
import styles from './index.module.scss';

export const LanguagesPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { platformLanguages, defaultPlatformLanguage, isLoadingLanguages } =
    usePlatformLanguages();
  const { canUse } = usePermission();

  const { isUpdatingPlatformLanguages, handleUpdatePlatformLanguages } =
    useUpdatePlatformLanguages();

  const deleteLanguage = (isoCode: string) => {
    const filteredPlatformLanguages = platformLanguages?.filter(
      (lang) => lang.alpha2 !== isoCode
    );

    if (filteredPlatformLanguages?.length)
      handleUpdatePlatformLanguages(filteredPlatformLanguages);
  };

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Languages"
          extra={
            canUse([Permission.EDIT_PLATFORM_LANGUAGES]) && [
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModalOpen(true)}
              >
                Add Languages
              </Button>,
            ]
          }
        />
        <Table
          dataSource={platformLanguages}
          rowKey="name"
          loading={isLoadingLanguages || isUpdatingPlatformLanguages}
        >
          <Column
            title="Languages"
            key="languages"
            render={(data: Language) => {
              const isDefaultLanguage = data.alpha2 === defaultPlatformLanguage;
              return (
                <div className={styles.rowWrapper}>
                  <span className={cn(isDefaultLanguage && styles.disabled)}>
                    {data.label}
                  </span>
                  {!isDefaultLanguage &&
                    canUse([Permission.EDIT_PLATFORM_LANGUAGES]) && (
                      <DeleteOutlined
                        onClick={() => deleteLanguage(data.alpha2)}
                      />
                    )}
                </div>
              );
            }}
          />
        </Table>
      </PageContainer>
      {isModalOpen && (
        <AddLanguagesModal
          onClose={() => setModalOpen(false)}
          isVisible={isModalOpen}
          platformLanguages={platformLanguages}
          updatePlatformLanguages={handleUpdatePlatformLanguages}
        />
      )}
    </>
  );
};

export default LanguagesPage;
