import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Table } from 'antd';
import { PaymentGateway } from '@laminar-product/client-commons-core/core';
import { Controller, useFormContext } from 'react-hook-form';
import { mapPaymentGatewayDataToTable } from 'pages/TaxManagement/utils';
import { paymentGateways } from 'pages/TaxManagement/constants';
import {
  PaymentGatewayData,
  TaxConfigurationForm,
  TaxConfigurationFormValue,
} from '../../types';
import {
  CheckboxProps,
  ColumnSelectionProps,
  PaymentGatewaysProps,
} from './types';

const PaymentGateways = ({ isLoading }: PaymentGatewaysProps) => {
  const [paymentGatewayTableData, setPaymentGatewayTableData] =
    useState<PaymentGatewayData[]>();
  const { control, watch } = useFormContext<TaxConfigurationForm>();

  useEffect(() => {
    setPaymentGatewayTableData(mapPaymentGatewayDataToTable());
  }, []);

  // Handles column selection. This feature is avaliable by default in ant ^v4.18.0. We can refactor this after upgrading.
  const renderColumnSelectionCheckbox = ({
    onChange,
    name,
    value,
  }: ColumnSelectionProps) => (
    <Checkbox
      checked={value?.length === paymentGateways.length}
      onChange={(e) => onChange(e.target.checked ? paymentGateways : [])}
    >
      {name}
    </Checkbox>
  );

  // Handles setting tax configuration values for Payment gateways in form
  const renderCheckbox = useCallback(
    ({ onChange, paymentGateway, valuesKey }: CheckboxProps) => {
      const values: PaymentGateway[] = watch(valuesKey) ?? [];
      const newValue = (checked: boolean) =>
        checked
          ? [...values, paymentGateway]
          : values.filter((val) => val !== paymentGateway);
      return (
        <Checkbox
          checked={values.includes(paymentGateway)}
          onChange={(e) => onChange(newValue(e.target.checked))}
        />
      );
    },
    [watch]
  );

  const columns = useMemo(
    () => [
      {
        title: 'Payment Gateway',
        dataIndex: 'paymentGateway',
        key: 'paymentGateway',
      },
      {
        title: () => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.ESTIMATION_GATEWAYS}
            render={({ value, onChange }) =>
              renderColumnSelectionCheckbox({
                onChange,
                name: 'Estimate',
                value,
              })
            }
          />
        ),
        dataIndex: TaxConfigurationFormValue.ESTIMATION_GATEWAYS,
        key: TaxConfigurationFormValue.ESTIMATION_GATEWAYS,
        render: (
          value: boolean,
          { paymentGateway }: PaymentGatewayData,
          rowIndex: number
        ) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.ESTIMATION_GATEWAYS}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                valuesKey: TaxConfigurationFormValue.ESTIMATION_GATEWAYS,
                paymentGateway,
              })
            }
          />
        ),
      },
      {
        title: () => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.STORAGE_GATEWAYS}
            render={({ value, onChange }) =>
              renderColumnSelectionCheckbox({
                onChange,
                name: 'Report on',
                value,
              })
            }
          >
            Report on
          </Controller>
        ),
        dataIndex: TaxConfigurationFormValue.STORAGE_GATEWAYS,
        key: TaxConfigurationFormValue.STORAGE_GATEWAYS,
        render: (
          value: boolean,
          { paymentGateway }: PaymentGatewayData,
          rowIndex: number
        ) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.STORAGE_GATEWAYS}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                paymentGateway,
                valuesKey: TaxConfigurationFormValue.STORAGE_GATEWAYS,
              })
            }
          />
        ),
      },
      {
        title: () => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.BILLING_ADDRESS_GATEWAYS}
            render={({ value, onChange }) =>
              renderColumnSelectionCheckbox({
                onChange,
                name: 'Capture Billing Address',
                value,
              })
            }
          />
        ),
        dataIndex: TaxConfigurationFormValue.BILLING_ADDRESS_GATEWAYS,
        key: TaxConfigurationFormValue.BILLING_ADDRESS_GATEWAYS,
        render: (
          value: boolean,
          { paymentGateway }: PaymentGatewayData,
          rowIndex: number
        ) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.BILLING_ADDRESS_GATEWAYS}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                paymentGateway,
                valuesKey: TaxConfigurationFormValue.BILLING_ADDRESS_GATEWAYS,
              })
            }
          />
        ),
      },
    ],
    [control, renderCheckbox]
  );

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={paymentGatewayTableData}
      loading={isLoading}
    />
  );
};

export default PaymentGateways;
