import React from 'react';
import Select, { Option } from 'components/Select';
import { Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';

const MultiSelectDetails = ({
  data,
  isLoading,
  name,
  ...props
}: {
  data: string[];
  isLoading?: boolean;
  name: string;
} & SelectProps<string[]>) => {
  return (
    <Select
      placeholder={!data || isLoading ? 'Fetching' : `Search for ${name}...`}
      loading={isLoading || !data}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      style={{ width: '100%' }}
      allowClear
      {...props}
    >
      {data?.map((genre) => (
        <Option key={genre} value={genre}>
          {genre}
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelectDetails;
