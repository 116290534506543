import { CurrentUser, ProlongUserPlanDto, UserClientData } from 'types/user';
import api from './api';

export const getCurrentUser = async (): Promise<CurrentUser> => {
  const { data } = await api().get(`/user/current`);

  return data;
};

export const changeUserActiveStatus = async ({
  email,
  activate,
}: {
  email: string;
  activate: boolean;
}): Promise<void> =>
  api().patch(`/user/${activate ? 'activate' : 'deactivate'}?email=${email}`);

export const changeUserRoles = async ({
  roles,
  email,
}: {
  roles: string[];
  email: string;
}): Promise<void> => api().patch(`/user/${email}/role`, { roles });

export const cancelUserSubscription = async (
  subscriptionUuid: string
): Promise<void> =>
  api().delete(`/supervisor/subscription/${subscriptionUuid}`);

export const cancelUserPrepaidPlan = async (userUuid: string): Promise<void> =>
  api().delete(`/supervisor/pin-redemption/${userUuid}`);

export const prolongUserPlan = async (
  prolongPlanDetails: ProlongUserPlanDto
) => {
  const response = await api().put(
    '/supervisor/subscription/prolong',
    prolongPlanDetails
  );

  return response?.data;
};
export const editUser = async (uid: string, userData: UserClientData) => {
  const response = await api().patch(`/supervisor/client/${uid}`, userData);

  return response?.data;
};

export const getUserDetails = async (
  userUuid: string
): Promise<UserClientData> => {
  const { data } = await api().get(`/supervisor/client/${userUuid}`);
  return data;
};
