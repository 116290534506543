import React, { useCallback, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getPlansPagination } from 'actions/plans';
import { Modal, Table, Tag } from 'antd';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import _capitalize from 'lodash/capitalize';
import { useHistory } from 'react-router-dom';
import { Plan, Quality } from 'types/plan';
import Search from 'components/Search';
import notification from 'utils/notification';
import {
  Pagination,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import RegionsMenu from 'components/RegionsMenu';
import { Region } from 'types';
import StringList from 'components/StringList';
import formatLabel from 'utils/formatLabel';
import { Paths } from 'types/paths';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import useInterestsTableFilter from 'hooks/useInterestsTableFilter';
import styles from '../index.module.scss';
import PlanModal from '../components/PlanModal';

const getQualityLabels = (qualityValues: Quality[]) => {
  if (!qualityValues) return;
  const qualities = {
    '1080': 'HD',
    '2160': '4K',
    '720': undefined,
    '540': 'SD',
  };
  return qualityValues.map((q) => qualities[q]);
};

const { Column } = Table;

const ProductTable = ({
  pagination,
  loading,
  currentPage,
}: {
  pagination?: Pagination<Plan>;
  loading?: boolean;
  currentPage?: number;
}) => {
  const history = useHistory();
  const { filters } = useInterestsTableFilter<Plan>({
    onFilter: (search, plan) =>
      !search || !!plan.interests?.includes(search as string),
  });

  return (
    <Table
      className={styles.planTable}
      dataSource={pagination?.data}
      rowKey="id"
      loading={loading}
      onRow={(plan: Plan) => ({
        onClick: () => history.push(`${Paths.PLANS}/${plan.uuid}`),
      })}
      rowClassName="clickable-row"
      pagination={usePaginationObject('/plans', pagination, currentPage)}
    >
      <Column
        title="Plan name"
        dataIndex="administrativeName"
        key="administrativeName"
      />
      <Column
        title="Interests"
        dataIndex="interests"
        key="interests"
        render={(interests: string[]) => (
          <StringList items={interests} maxShowCount={3} />
        )}
        {...filters}
      />
      <Column
        title="Platforms"
        dataIndex="platforms"
        key="platforms"
        render={(platforms) =>
          platforms?.map((p: string) => _capitalize(p)).join(', ')
        }
      />
      <Column
        title="Quality"
        dataIndex="quality"
        key="quality"
        render={(quality) => getQualityLabels(quality)?.join(', ')}
      />
      <Column
        title="Travel to"
        dataIndex="availableRegions"
        key="availableRegions"
        render={(_, plan: Plan) => {
          const travelRegions = plan.availableRegions?.map(
            (region) => region.name
          );

          if (!travelRegions || !travelRegions.length) return '-';

          return <StringList items={travelRegions} />;
        }}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(status) => (
          <Tag color={status === 'PUBLISHED' ? 'green' : 'red'}>
            {formatLabel(status)}
          </Tag>
        )}
      />
    </Table>
  );
};

const ProductList = () => {
  const [query, setQuery] = useState<string | undefined>();
  const page = usePageParam();
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const [displayPlanModal, setDisplayPlanModal] = useState<boolean>(false);
  const { data, isLoading, error } = useFetchActionObj(
    useCallback(
      () =>
        getPlansPagination({
          name: query,
          page,
          regionUuid: selectedRegion?.uuid,
        }),
      [query, page, selectedRegion?.uuid]
    ),
    useMemo(
      () => ({
        onError: () => {
          notification.error({
            title: "Couldn't load plan list",
            description: 'Refresh the page or contact support.',
          });
        },
      }),
      []
    )
  );
  const { canUse } = usePermission();

  const paginationObj = usePaginationObject('/plans', data, page);
  const handleSearch = useCallback(
    (query: string) => {
      paginationObj.onChange(1);
      setQuery(query);
    },
    [paginationObj]
  );

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Plans"
          extra={[
            <Search
              onSearch={handleSearch}
              key="search"
              placeholder="Search plan by name"
            />,
            ...(canUse([Permission.SAVE_PLAN])
              ? [
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setDisplayPlanModal(true)}
                  >
                    Create new plan
                  </Button>,
                ]
              : []),
          ]}
        />
        <ErrorIndicator error={error} />

        <div className={styles.wrapper}>
          <RegionsMenu
            defaultKey={selectedRegion?.uuid}
            onRegionSelect={setSelectedRegion}
          />
          <ProductTable
            pagination={data}
            loading={isLoading}
            currentPage={page ?? 1}
          />
        </div>
        {displayPlanModal && (
          <Modal
            visible={displayPlanModal}
            onCancel={() => setDisplayPlanModal(false)}
            destroyOnClose
            footer={null}
            title={'Add new plan'}
          >
            <PlanModal />
          </Modal>
        )}
      </PageContainer>
    </>
  );
};

export default ProductList;
