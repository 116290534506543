import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { mapTaxCountriesDataToTable } from 'pages/TaxManagement/utils';
import { USCountryCode } from 'pages/TaxManagement/constants';
import {
  TaxConfigurationForm,
  TaxConfigurationFormValue,
  TaxamoConfigOptions,
} from '../../types';
import {
  CheckboxProps,
  ColumnSelectionProps,
  CountriesProps,
  Country,
} from './types';
import AddTaxCountriesModal from './AddTaxCountriesModal';
import { removeCountryFromFormState } from './helpers';

const TaxCountries = ({
  isModalOpen,
  onModalClose,
  data,
  isLoading,
}: CountriesProps) => {
  const [tableState, setTableState] = useState<Country[]>([]);
  const { control, watch, reset, getValues } =
    useFormContext<TaxConfigurationForm>();

  useEffect(() => {
    if (!data) return;
    setTableState(mapTaxCountriesDataToTable({ configurationData: data }));
  }, [data]);

  // Handles column selection. This feature is avaliable by default in ant ^v4.18.0. We can refactor this after upgrading.
  const renderColumnSelectionCheckbox = useCallback(
    ({ onChange, name, value }: ColumnSelectionProps) => (
      <Checkbox
        checked={tableState.length > 0 && value?.length === tableState?.length}
        onChange={(e) =>
          onChange(
            e.target.checked ? tableState.map((country) => country.code) : []
          )
        }
      >
        {name}
      </Checkbox>
    ),
    [tableState]
  );

  // Handles setting tax configuration values for Countries in form
  const renderCheckbox = useCallback(
    ({ onChange, code, valuesKey, disabled }: CheckboxProps) => {
      const values: string[] = watch(valuesKey) ?? [];
      const newValue = (checked: boolean) =>
        checked ? [...values, code] : values.filter((val) => val !== code);
      return (
        <Checkbox
          checked={values?.includes(code)}
          onChange={(e) => onChange(newValue(e.target.checked))}
          disabled={disabled}
        />
      );
    },
    [watch]
  );

  const handleDelete = useCallback(
    (code: string) => {
      const filteredState = tableState.filter(
        (country) => country.code !== code
      );
      setTableState(filteredState);
      const currentFormState = getValues();

      const newFormState = Object.fromEntries(
        Object.entries(currentFormState).map(([key, value]) => {
          if (
            [
              TaxConfigurationFormValue.STORAGE_COUNTRIES,
              TaxConfigurationFormValue.ESTIMATION_COUNTRIES,
              TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES,
              TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES,
            ].includes(key as TaxConfigurationFormValue)
          )
            return [key, removeCountryFromFormState(code, value)];
          return [key, value];
        })
      );

      reset(newFormState);
    },
    [getValues, reset, tableState]
  );

  const handleOnAddCountries = (countries: Country[]) => {
    setTableState([...tableState, ...countries]);
    onModalClose();
  };

  const columns = useMemo(
    () => [
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (value: boolean, country: Country, rowIndex: number) => (
          <span>{country.name}</span>
        ),
      },
      {
        title: () => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.ESTIMATION_COUNTRIES}
            render={({ value, onChange }) =>
              renderColumnSelectionCheckbox({
                onChange,
                name: 'Estimate',
                value,
              })
            }
          />
        ),
        dataIndex: TaxConfigurationFormValue.ESTIMATION_COUNTRIES,
        key: TaxConfigurationFormValue.ESTIMATION_COUNTRIES,
        render: (value: boolean, { code }: Country, rowIndex: number) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.ESTIMATION_COUNTRIES}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                code,
                valuesKey: TaxConfigurationFormValue.ESTIMATION_COUNTRIES,
              })
            }
          />
        ),
      },
      {
        title: () => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.STORAGE_COUNTRIES}
            render={({ value, onChange }) =>
              renderColumnSelectionCheckbox({
                onChange,
                name: 'Report on',
                value,
              })
            }
          >
            Report on
          </Controller>
        ),
        dataIndex: TaxConfigurationFormValue.STORAGE_COUNTRIES,
        key: TaxConfigurationFormValue.STORAGE_COUNTRIES,
        render: (value: boolean, { code }: Country, rowIndex: number) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.STORAGE_COUNTRIES}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                code,
                valuesKey: TaxConfigurationFormValue.STORAGE_COUNTRIES,
              })
            }
          />
        ),
      },
      {
        title: () => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES}
            render={({ value, onChange }) =>
              renderColumnSelectionCheckbox({
                onChange,
                name: 'Capture Billing Address',
                value,
              })
            }
          />
        ),
        dataIndex: TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES,
        key: TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES,
        render: (value: boolean, { code }: Country, rowIndex: number) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                code,
                valuesKey: TaxConfigurationFormValue.BILLING_ADDRESS_COUNTRIES,
              })
            }
          />
        ),
      },
      {
        title: 'Address Validation',
        dataIndex: TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES,
        key: TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES,
        render: (value: boolean, { code }: Country, rowIndex: number) => (
          <Controller
            control={control}
            name={TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES}
            render={({ onChange }) =>
              renderCheckbox({
                onChange,
                code,
                valuesKey:
                  TaxConfigurationFormValue.ADDRESS_VALIDATION_COUNTRIES,
                disabled: code !== USCountryCode,
              })
            }
          />
        ),
      },
      {
        dataIndex: 'action',
        key: TaxamoConfigOptions.CAPTURE_BILLING_ADDRESS,
        render: (value: boolean, country: Country, rowIndex: number) => (
          <DeleteOutlined
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(country.code);
            }}
          />
        ),
      },
    ],
    [control, handleDelete, renderCheckbox, renderColumnSelectionCheckbox]
  );

  return (
    <>
      <Table columns={columns} dataSource={tableState} loading={isLoading} />
      <AddTaxCountriesModal
        open={isModalOpen}
        onClose={onModalClose}
        onAdd={handleOnAddCountries}
        addedCountries={tableState}
      />
    </>
  );
};

export default TaxCountries;
