import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { appReducer } from 'store/app/reducer';
import { regionsReducer } from 'store/regions/reducer';
import { dictionariesReducer } from 'store/dictionaries/reducer';
import { StateType } from 'typesafe-actions';
import { languagesReducer } from 'store/languages/reducer';
import { aiReducer } from './ai/reducer';
import rootSaga from './saga';
import userReducer from './user/reducer';

const reducers = combineReducers({
  app: appReducer,
  regions: regionsReducer,
  dictionaries: dictionariesReducer,
  ai: aiReducer,
  languages: languagesReducer,
  user: userReducer,
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

export type RootState = StateType<typeof reducers>;
export default store;

sagaMiddleware.run(rootSaga);
