import { message } from 'antd';
import { call, put, takeLeading } from 'redux-saga/effects';
import { requestAction } from 'store/app/actions';
import { RequestStatuses } from 'store/app/types';
import { AIDetection, AIDetectionStatus, AIRecognitionStatus } from 'types/ai';
import { getType } from 'typesafe-actions';
import { captureError } from 'utils/captureError';
import {
  fetchAiRecognitionStatusAction,
  runAiFacesRecognitionAction,
  runAutoAiRecognitionAction,
} from './actions';
import {
  fetchAIRecognitionStatus,
  processAICustomLabel,
  runAIFaceRecognition,
  runAutoAIRecognition,
} from './service';

function getRecognitionStatus(
  aiStatuses?: (AIDetectionStatus | undefined)[]
): AIRecognitionStatus {
  if (aiStatuses?.every((s) => s === 'COMPLETED' || s === 'ERROR'))
    return 'completed';
  if (aiStatuses?.some((s) => s === 'PROCESSING')) return 'processing';

  return 'not_started';
}

export function* fetchAiRecognitionStatusSaga({
  payload,
}: ReturnType<typeof fetchAiRecognitionStatusAction.request>) {
  try {
    yield put(
      requestAction({
        action: getType(fetchAiRecognitionStatusAction.request),
        status: RequestStatuses.PENDING,
      })
    );

    const data: AIDetection = yield call(
      fetchAIRecognitionStatus,
      payload.sourceFileId
    );
    const facesRecognitionStatus = getRecognitionStatus([
      data.facesDetectionStatus,
    ]);
    const autoRecognitionStatus = getRecognitionStatus([
      data.celebrityDetectionStatus,
      data.moderationDetectionStatus,
    ]);

    yield put(
      fetchAiRecognitionStatusAction.success({
        sourceFileId: payload.sourceFileId,
        facesRecognitionStatus,
        autoRecognitionStatus,
      })
    );
    yield put(
      requestAction({
        action: getType(fetchAiRecognitionStatusAction.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (e) {
    yield put(fetchAiRecognitionStatusAction.failure(e as any));
    yield put(
      requestAction({
        action: getType(fetchAiRecognitionStatusAction.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(e as any);
  }
}

export function* runAutoAiRecognitionSaga({
  payload,
}: ReturnType<typeof runAutoAiRecognitionAction.request>) {
  try {
    yield put(
      requestAction({
        action: getType(runAutoAiRecognitionAction.request),
        status: RequestStatuses.PENDING,
      })
    );

    yield call(runAutoAIRecognition, payload.sourceFileId);

    yield put(
      runAutoAiRecognitionAction.success({ sourceFileId: payload.sourceFileId })
    );
    yield put(
      requestAction({
        action: getType(runAutoAiRecognitionAction.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (e) {
    yield put(runAutoAiRecognitionAction.failure(e as any));
    yield put(
      requestAction({
        action: getType(runAutoAiRecognitionAction.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(e as any);
  }
}

export function* runFacesRecognitionSaga({
  payload,
}: ReturnType<typeof runAiFacesRecognitionAction.request>) {
  try {
    yield put(
      requestAction({
        action: getType(runAiFacesRecognitionAction.request),
        status: RequestStatuses.PENDING,
      })
    );

    yield Promise.all(
      payload.faces.map(async (f) => await processAICustomLabel(f.key, f.name))
    );
    yield call(runAIFaceRecognition, payload.sourceFileId);
    message.success('The analysis has started.');
    yield put(
      runAiFacesRecognitionAction.success({
        sourceFileId: payload.sourceFileId,
      })
    );
    yield put(
      requestAction({
        action: getType(runAiFacesRecognitionAction.request),
        status: RequestStatuses.RESOLVED,
      })
    );
  } catch (e) {
    message.error(
      'There has been an error when starting the analysis - try again or contact support'
    );
    yield put(runAiFacesRecognitionAction.failure(e as any));
    yield put(
      requestAction({
        action: getType(runAiFacesRecognitionAction.request),
        status: RequestStatuses.REJECTED,
      })
    );
    captureError(e as any);
  }
}

export default function* () {
  yield takeLeading(
    [
      fetchAiRecognitionStatusAction.request,
      runAutoAiRecognitionAction.success,
      runAiFacesRecognitionAction.success,
    ],
    fetchAiRecognitionStatusSaga
  );
  yield takeLeading(
    runAutoAiRecognitionAction.request,
    runAutoAiRecognitionSaga
  );
  yield takeLeading(
    runAiFacesRecognitionAction.request,
    runFacesRecognitionSaga
  );
}
