export interface Translation {
  code: string;
  translations: { [key: string]: string };
}

export interface MappedTranslation {
  langLabel?: string;
  langCode: string;
  translations: { [key: string]: string }[];
}

export interface ListingPageTranslation {
  name: string;
  translations: { langCode: string; value: string; id?: string }[];
}

export interface CountryTranslation {
  code: string;
  name: string;
}

export enum LanguageType {
  ALPHA_2 = 'alpha2',
  ALPHA_3 = 'alpha3',
}
