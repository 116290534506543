import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import videojs from 'video.js';
import { toString } from '../../utils';

interface TrackPlaybackInputProps {
  className?: string;
  playerId: string;
  onValueChange?: (val: string) => void;
}

const TrackPlaybackInput = ({
  className,
  playerId,
  onValueChange,
}: TrackPlaybackInputProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const player = videojs.getPlayer(playerId);
    const onTimeUpdate = (e: any) => {
      const currentTime = player?.currentTime();
      setValue(toString(currentTime));
      if (onValueChange) onValueChange(toString(currentTime));
    };
    if (player) {
      player.on('timeupdate', onTimeUpdate);
    }

    return () => {
      if (player) player.off('timeupdate', onTimeUpdate);
    };
  }, [onValueChange, playerId]);
  return (
    <Input className={className} value={value} placeholder="00:00" readOnly />
  );
};

export default TrackPlaybackInput;
