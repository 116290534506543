import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  PlatformPartnerPrice,
  addPlanToPlatformPartner,
} from 'actions/platformPartners';
import { Input, Modal } from 'antd';
import FormGroup from 'components/FormGroup';
import PlanChooser from 'components/PlanChooser';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  AddPlatformPartnerPlan,
  PlatformPartner,
} from 'types/platformPartners';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import { IntervalType } from 'types/plan';
import PartnerPriceForm from '../components/PartnerPriceForm';

interface AddPartnerPlanModalProps {
  partnerUuid: string;
  isOpen: boolean;
  onClose: () => void;
  onAdd: () => void;
}

const AddPartnerPlanModal = ({
  isOpen,
  onClose,
  onAdd,
  partnerUuid,
}: AddPartnerPlanModalProps) => {
  const formMethods = useForm<AddPlatformPartnerPlan>();
  const { handleSubmit, errors, control } = formMethods;

  const handleOnClose = () => {
    formMethods.reset();
    onClose();
  };
  const [planPriceEnabled, setPlanPriceEnabled] = useState(false);
  const { sendAction: addPlanToPlatformPartnerAction, isLoading } =
    useSendActionObj<PlatformPartner, AddPlatformPartnerPlan>(
      (payload) => {
        const planPrice =
          planPriceEnabled && payload.price
            ? ({
                ...payload.price,
                amount: Number(payload.price.amount),
                interval: payload.price.interval || IntervalType.DAY,
                intervalMultiplier: Number(payload.price.intervalMultiplier),
                // Note: INR is hardcoded for now in the first phase
                currency: 'INR',
              } as PlatformPartnerPrice)
            : null;
        return addPlanToPlatformPartner(partnerUuid, {
          ...payload,
          price: planPrice,
        });
      },
      {
        onDone: () => {
          onAdd();
          handleOnClose();
        },
        onError: (error) => {
          notification.error({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
          });
          captureError(error as any);
        },
      }
    );

  const onSubmit = (values: AddPlatformPartnerPlan) =>
    addPlanToPlatformPartnerAction(values);

  return (
    <Modal
      visible={isOpen}
      onCancel={handleOnClose}
      title="Add plan"
      okText="Add"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <FormGroup
          input={
            <Controller
              placeholder="Select the plan"
              render={(field) => (
                <PlanChooser
                  onChoose={(plan) => field.onChange(plan.uuid)}
                  chosenPlanId={field.value}
                />
              )}
              control={control}
              name="planId"
              rules={{ required: 'This field is required' }}
            />
          }
          label="Plan"
          errorMessage={errors?.planId?.message}
          defaultValue=""
        />

        <FormGroup
          input={
            <Controller
              placeholder="Enter the external ID"
              as={Input}
              control={control}
              name="externalId"
              rules={{ required: 'This field is required' }}
            />
          }
          label="External ID"
          errorMessage={errors?.externalId?.message}
          defaultValue=""
        />

        <PartnerPriceForm
          control={control}
          errors={errors}
          planPriceEnabled={planPriceEnabled}
          setPlanPriceEnabled={setPlanPriceEnabled}
        />
      </FormProvider>
    </Modal>
  );
};

export default AddPartnerPlanModal;
