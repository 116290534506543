import { Select } from 'antd';
import React from 'react';
import { AssetAvailabilityValue } from 'types/licenses';
import styles from '../index.module.scss';

const { Option } = Select;

interface AssetAvailabilitySelectProps {
  value?: AssetAvailabilityValue;
  setValue: (value: AssetAvailabilityValue) => void;
  disabled?: boolean;
}

const AssetAvailabilitySelect = ({
  setValue,
  value = AssetAvailabilityValue.SCHEDULED,
  disabled,
}: AssetAvailabilitySelectProps) => {
  return (
    <Select
      className={styles.availabilitySelector}
      value={value}
      onChange={setValue}
      disabled={disabled}
    >
      <Option
        value={AssetAvailabilityValue.SCHEDULED}
        key={AssetAvailabilityValue.SCHEDULED}
      >
        Scheduled
      </Option>
      <Option
        value={AssetAvailabilityValue.IMMEDIATE}
        key={AssetAvailabilityValue.IMMEDIATE}
      >
        Immediately after published
      </Option>
    </Select>
  );
};

export default AssetAvailabilitySelect;
