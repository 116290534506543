import { Modal } from 'antd';
import React from 'react';
import { PlatformPartner } from 'types/platformPartners';
import usePartnerActions from '../utils/usePartnerActions';

interface DeletePartnerModalProps {
  partner: PlatformPartner;
  onClose: () => void;
  isOpen: boolean;
  refreshPartnersList?: () => void;
}

const DeletePartnerModal = ({
  onClose,
  partner,
  isOpen,
  refreshPartnersList,
}: DeletePartnerModalProps) => {
  const { deletePartner, isDeleting } = usePartnerActions({
    refreshPartnersList,
  });

  const onDeletePartner = () => {
    deletePartner({
      uuid: partner.uuid,
      partnerName: partner.administrativeName,
    });
    onClose?.();
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title={`Are you sure you want to remove ${partner.administrativeName} partner?`}
      okText="Yes"
      cancelText="No"
      onOk={onDeletePartner}
      confirmLoading={isDeleting}
    >
      Please note that all partner data will be erased and you will not be able
      to undo this action.
    </Modal>
  );
};

export default DeletePartnerModal;
