import {
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, PageHeader, Switch, Table, notification } from 'antd';
import PageContainer from 'components/PageContainer';
import { Interest } from 'types/interests';
import { useCallback, useState } from 'react';
import {
  useFetchActionObj,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import {
  changeInterestConfig,
  deleteInterest,
  getInterestConfig,
  getInterestsList,
} from 'actions/interests';
import { languages } from '@laminar-product/client-commons-core/core';
import StringList from 'components/StringList';
import modal from 'antd/lib/modal';
import styles from './index.module.scss';
import InterestsModal from './InterestsModal';

const { Column } = Table;
const PER_PAGE = 10;

const Interests = () => {
  const [modalState, setModalState] = useState<{
    selectedInterest?: Interest;
    isModalVisible: boolean;
  }>({
    isModalVisible: false,
  });
  const { data: interestData, refresh: refreshList } = useFetchActionObj(
    useCallback(
      () => getInterestsList({ skip: 0, limit: PER_PAGE, name: '' }),
      []
    )
  );
  const {
    data: interestConfig,
    isLoading: fetchingInterestConfig,
    refresh: refreshConfig,
  } = useFetchActionObj(getInterestConfig);
  const { sendAction: changeConfig, isLoading: changingInterestConfig } =
    useSendActionObj(changeInterestConfig, {
      onDone: async () => {
        await refreshConfig();
        refreshList();
        notification.success({
          message: 'Interest config updated',
        });
      },
    });
  const { sendAction: deleteAction, isLoading: deleting } = useSendActionObj(
    deleteInterest,
    {
      onDone: refreshList,
    }
  );
  const isLoading =
    fetchingInterestConfig || changingInterestConfig || deleting;
  const closeModalHandler = () => {
    setModalState({ isModalVisible: false, selectedInterest: undefined });
    refreshList();
  };
  const deleteInterestHandler = (name: string) =>
    modal.confirm({
      title: 'Are you sure you want to delete this interest?',
      content: 'This action cannot be undone',
      onOk: () => deleteAction(name),
    });
  const publishHandler = () => changeConfig(true);
  const interestsEnabled = !!interestConfig?.interestEnabled;

  return (
    <>
      <PageContainer>
        <PageHeader
          title={
            <div className={styles.headerWrapper}>
              <h1 className={styles.title}>Interests</h1>
              <Switch
                loading={isLoading}
                checked={interestsEnabled}
                onChange={changeConfig}
              />
            </div>
          }
          extra={
            interestsEnabled && (
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  setModalState({
                    isModalVisible: true,
                  })
                }
              >
                Add interest
              </Button>
            )
          }
        />
        {interestsEnabled && (
          <>
            <Table
              loading={false}
              indentSize={40}
              dataSource={interestData?.data}
              rowKey="name"
              pagination={{
                total: interestData?.count,
                pageSize: PER_PAGE,
                onChange: (page) => {
                  getInterestsList({
                    skip: (page - 1) * PER_PAGE,
                    limit: PER_PAGE,
                    name: '',
                  });
                },
              }}
            >
              <Column title="Name" dataIndex="name" align="left" width={500} />
              <Column
                title="Language"
                dataIndex="language"
                align="left"
                render={(_, interest: Interest) => {
                  return (
                    <StringList
                      maxShowCount={3}
                      items={interest.translations.map(
                        (t) =>
                          languages.find((l) => l.alpha2 === t.language)
                            ?.label || ''
                      )}
                    />
                  );
                }}
              />
              <Column
                title=""
                dataIndex="options"
                width={130}
                render={(_, interest: Interest) => (
                  <div>
                    <Button
                      type="text"
                      onClick={() =>
                        setModalState({
                          isModalVisible: true,
                          selectedInterest: interest,
                        })
                      }
                    >
                      <EditOutlined />
                    </Button>
                    <Button
                      type="text"
                      onClick={() => deleteInterestHandler(interest.name)}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                )}
              />
            </Table>
            <Button
              className={styles.publishButton}
              type="primary"
              icon={<GlobalOutlined />}
              onClick={publishHandler}
            >
              Publish
            </Button>
          </>
        )}
      </PageContainer>

      {modalState.isModalVisible && (
        <InterestsModal
          interest={modalState.selectedInterest}
          onClose={closeModalHandler}
        />
      )}
    </>
  );
};

export default Interests;
