import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { createBasicContentDistributionConfig } from 'actions/contentDistribution';
import { Modal } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  ContentDistributionConfig,
  ContentDistributionConfigForm,
} from 'types/contentDistribution';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import ContentDistributionConfigDetailsForm from '../ContentDistributionConfigDetailsForm';

interface ContentDistributionNewConfigurationProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContentDistributionNewConfiguration = ({
  isOpen,
  onClose,
}: ContentDistributionNewConfigurationProps) => {
  const formMethods = useForm<ContentDistributionConfigForm>({
    mode: 'onSubmit',
  });
  const { handleSubmit } = formMethods;
  const { push } = useHistory();

  const onCreateSuccess = (id: string) => {
    notification.success({
      title: 'Success',
      description: 'Content distribution configuration has been saved!',
    });
    onClose();
    push(`/contentDistribution/configuration/${id}`);
  };

  const { sendAction: createContentDistributionConfig, isLoading } =
    useSendActionObj<ContentDistributionConfig, ContentDistributionConfigForm>(
      (config) => createBasicContentDistributionConfig(config),
      {
        onDone: (config) => onCreateSuccess(config.uuid),
        onError: (error) => {
          notification.error({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
          });
          captureError(error as any);
        },
      }
    );

  const onSubmit = (values: ContentDistributionConfigForm) =>
    createContentDistributionConfig(values);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title="Create new configuration"
      okText="Create"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <ContentDistributionConfigDetailsForm />
      </FormProvider>
    </Modal>
  );
};

export default ContentDistributionNewConfiguration;
