import { SorterResult } from 'antd/lib/table/interface';
import { ReactNode } from 'react';
import { Asset } from 'types';

interface TransformSorterOutput {
  sortBy: string;
  sortingDir: 'ASCEND' | 'DESCEND';
}

/**
 * Transforms rc-table/Ant sorting configuration to our API sorting configuration.
 *
 * The input can be a single object or an array since Table allows for sorting on multiple columns, but this
 * transformer will output only a single configuration. Be careful! You probably don't want to use it
 * with multiple sorted columns.
 */
export const transformSorter = <T>(
  sort: SorterResult<T> | SorterResult<T>[]
): TransformSorterOutput | undefined => {
  const sortArr: SorterResult<T>[] = 'length' in sort ? sort : [sort];

  for (const sort of sortArr) {
    if (sort.field) {
      return {
        sortBy: sort.field.toString(),
        // TypeScript can't figure out how toUpperCase works
        sortingDir: (sort.order?.toUpperCase() ?? 'DESCEND') as
          | 'ASCEND'
          | 'DESCEND',
      };
    }
  }

  return undefined;
};

export interface AssetListTableStructure {
  name: string;
  assetType: string;
  actionColumn?: ReactNode;
}

interface MapAssetListToTableStructure {
  assets?: Asset[];
  query?: string;
  actionColumn?: (asset: Asset) => ReactNode;
}

export const mapAssetListToTableStructure = ({
  assets,
  query,
  actionColumn,
}: MapAssetListToTableStructure): AssetListTableStructure[] =>
  assets?.reduce((acc: AssetListTableStructure[], currentValue) => {
    if (
      !!query &&
      !currentValue.name.toLowerCase().includes(query.toLowerCase())
    )
      return acc;

    const structuredAsset = {
      name: currentValue.name,
      assetType: currentValue.type,
      actionColumn: actionColumn ? actionColumn(currentValue) : null,
      uuid: currentValue.uuid, //NOTE: Content distribution has no access to ID, we are now based redirects on uuid
    };

    acc.push(structuredAsset);
    return acc;
  }, []) || [];
