import ChartCard from 'ant-design-pro/lib/Charts/ChartCard';
import { Link } from 'react-router-dom';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import styles from './index.module.scss';

interface CardProps {
  title: string;
  textDisplayed: string;
  linkTo?: string;
  color: string;
  deltaTimeframe?: string;
  deltaValue?: number;
}

const Card = (props: CardProps) => {
  return (
    <ChartCard
      style={{ borderTop: `2px solid ${props.color}`, minHeight: '200px' }}
      title={props.title}
      total={props.textDisplayed}
      footer={
        props.linkTo && (
          <div className={styles.footer}>
            <Link to={props.linkTo}>See the list</Link>
          </div>
        )
      }
      contentHeight={46}
    >
      {props.deltaTimeframe && <span> {props.deltaTimeframe}</span>}

      {props.deltaValue !== undefined && props.deltaValue > 0 && (
        <span className={styles.delta}>
          +{props.deltaValue} <CaretUpFilled style={{ color: 'green' }} />
        </span>
      )}

      {props.deltaValue !== undefined && props.deltaValue < 0 && (
        <span className={styles.delta}>
          {props.deltaValue} <CaretDownFilled style={{ color: 'red' }} />
        </span>
      )}

      {props.deltaValue !== undefined && props.deltaValue === 0 && (
        <span className={styles.delta}>-</span>
      )}
    </ChartCard>
  );
};

export default Card;
