import axios, { CancelToken } from 'axios';
import { ImageUploadResponse } from 'types';
import { ImageType } from '@laminar-product/client-commons-core/core';
import api from './api';

export const initImageUpload = async ({
  name,
  cancelToken,
  type,
}: {
  cancelToken?: CancelToken;
  name: string;
  type: ImageType;
}): Promise<ImageUploadResponse> => {
  const { data } = await api().post(`/image`, { name, type }, { cancelToken });

  return data;
};

export const removeImage = async ({
  id,
  cancelToken,
}: {
  cancelToken?: CancelToken;
  id: string | number;
}): Promise<void> =>
  api().delete(`/image/${id}`, {
    cancelToken,
  });

export const uploadImageToS3 = async ({
  file,
  signedUrl,
  onProgress,
  cancelToken,
  params,
}: {
  file: File;
  signedUrl: string;
  onProgress?: (percentage: number) => void;
  params: { [key: string]: string };
  cancelToken?: CancelToken;
}) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.set(key, params[key]);
  });
  formData.set('file', file);

  return axios.post(signedUrl, formData, {
    headers: { 'Content-Type': file.type },
    onUploadProgress: (event) => {
      if (onProgress) onProgress(event.loaded / file.size);
    },
    cancelToken,
  });
};
