import React, { useCallback, useEffect, useState } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { updateContent } from 'actions/content';
import { Spin, Switch } from 'antd';
import Button from 'components/Button';
import GetAIMetadataModal from 'components/GetAIMetadataModal';
import VideoPlayer from 'components/VideoPlayer';
import { useAssetContentContext } from 'contexts/assetContentContext';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';
import { fetchAiRecognitionStatusAction } from 'store/ai/actions';
import { selectAiAutoRecognitionStatus } from 'store/ai/selectors';
import { Asset, Content, UploadedFile } from 'types';
import { AIRecognitionStatus } from 'types/ai';
import { VideoType } from '@laminar-product/client-commons-core/core';
import notification from 'utils/notification';
import { Paths } from 'types/paths';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import cn from 'classnames';
import styles from './index.module.scss';

interface MainContentAssignProps {
  mainContent?: Content;
  mainContentDetails?: UploadedFile;
  asset?: Asset;
}

const MainContentAssign = ({
  mainContent,
  mainContentDetails,
  asset,
}: MainContentAssignProps) => {
  const [isGetAIModalVisible, toggleIsGetAIModalVisible] = useState(false);
  const [isContentDownloadable, setIsContentDownloadable] = useState(false);
  const { sourceFileId, mainVideoUrlMP4 } = useAssetContentContext();
  const dispatch = useDispatch();
  const aiRecognitionStatus = useSelector<RootState, AIRecognitionStatus>(
    (state) => selectAiAutoRecognitionStatus(state, sourceFileId)
  );

  const { sendAction: editContent, isLoading: isEditingContent } =
    useSendActionObj<void, boolean>(
      useCallback(
        (downloadable: boolean) => {
          if (!mainContent) return Promise.reject({});

          const mainContentToSave: Content = {
            ...mainContent,
            downloadable,
          };

          return updateContent({
            id: mainContentToSave.id,
            data: mainContentToSave,
          });
        },
        [mainContent]
      ),
      {
        onDone: notifyAboutRepublishRequirement,
        onError: () => {
          notification.error({
            title: 'Error',
            description: 'Something went wrong. Please try again!',
          });
          setIsContentDownloadable(!!mainContent?.downloadable);
        },
      }
    );

  const onDownloadableChange = (value: boolean) => {
    setIsContentDownloadable(value);
    editContent(value);
  };

  useEffect(() => {
    let id: NodeJS.Timeout | null = null;
    if (aiRecognitionStatus === 'processing' && sourceFileId) {
      id = setInterval(() => {
        dispatch(fetchAiRecognitionStatusAction.request({ sourceFileId }));
      }, 10000);
    }
    if (aiRecognitionStatus === 'completed' && id) {
      clearInterval(id);
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [aiRecognitionStatus, dispatch, sourceFileId]);

  useEffect(() => {
    if (!mainContent) return;

    setIsContentDownloadable(!!mainContent.downloadable);
  }, [mainContent]);

  if (!mainContentDetails) {
    return (
      <div className={cn(styles.videoWrapper, styles.emptyVideoWrapper)}>
        <span>The assigned content has no video file</span>
      </div>
    );
  }

  return (
    <>
      {mainContent && mainContentDetails?.details && mainVideoUrlMP4 && (
        <div className={styles.videoWrapper}>
          {/* Temporary use mp4 file */}
          <VideoPlayer
            videoFile={{
              video: {
                raw: mainVideoUrlMP4,
                dash: { url: mainVideoUrlMP4, type: VideoType.MPD },
                hls: { url: mainVideoUrlMP4, type: VideoType.M3U8 },
              },
            }}
          />
          <div className={styles.videoInfo}>
            <div className={styles.videoInfoBody}>
              <div className={styles.infoWrapper}>
                <span>File name</span>
                <span>{mainContentDetails.name}</span>
              </div>

              <div className={styles.infoWrapper}>
                <span>AI Metadata</span>
                <span>
                  {aiRecognitionStatus === 'processing' ? (
                    <Spin size="small" />
                  ) : null}
                  {aiRecognitionStatus === 'completed' ? (
                    <Link
                      className={styles.aiReadyLink}
                      to={`${Paths.ASSETS}/${asset?.uuid}/ai`}
                    >
                      Ready
                    </Link>
                  ) : null}
                  {(!aiRecognitionStatus ||
                    aiRecognitionStatus === 'not_started') &&
                    '-'}
                </span>
              </div>

              <div className={styles.infoWrapper}>
                <span className={styles.downloadable}>Downloadable</span>

                <Switch
                  checked={isContentDownloadable}
                  onChange={onDownloadableChange}
                  disabled={isEditingContent}
                  className={styles.downloadableSwitch}
                />
              </div>
            </div>

            {(!aiRecognitionStatus ||
              aiRecognitionStatus === 'not_started') && (
              <Button
                onClick={() => toggleIsGetAIModalVisible(true)}
                icon={<PlayCircleOutlined />}
                className={styles.aiButton}
              >
                Get AI Metadata
              </Button>
            )}

            <GetAIMetadataModal
              isVisible={isGetAIModalVisible}
              onClose={() => toggleIsGetAIModalVisible(false)}
              sourceFileId={sourceFileId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MainContentAssign;
