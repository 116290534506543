import React, { useCallback, useEffect, useState } from 'react';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Select as AntdSelect, DatePicker, Input } from 'antd';
import AssetParentChooser from 'components/AssetParentChooser';
import FormContainer from 'components/FormContainer';
import FormGroup from 'components/FormGroup';
import ImagePlaceholder from 'components/ImagePlaceholder';
import Space from 'components/Space';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { useImageContext } from 'contexts/imageContext';
import {
  AssetImageActionType,
  AssetImageState,
} from 'contexts/imageContextReducer';
import { Controller, useForm } from 'react-hook-form';
import { Asset, AssetForm, Image } from 'types';
import { useDictionary } from 'hooks/useDictionaries';
import {
  AssetAccessType,
  AssetType,
  ImageType,
} from '@laminar-product/client-commons-core/core';
import {
  hasAssetAccessType,
  isLiveAssetType,
  isLiveEventType,
} from 'pages/Assets/AssetDetails/utils';
import AdvancedRadioElement from 'components/AdvancedRadioElement';
import Select from 'components/Select';
import {
  convertLocalDateToUTCWithOffset,
  convertNumberToUTCWithOffset,
  dateFormatWith12Time,
  defaultTimeZoneValue,
  getTimezones,
} from 'utils/timezones';
import { getAssetById } from 'actions/assets';
import _omit from 'lodash/omit';
import styles from './index.module.scss';
import MultiSelectGeneralDetails from './MultiSelectGeneralDetails';
import { validateLiveEventDates } from './validators';
import { GeneralDetailsFormSubmit } from '.';

const Option = AntdSelect.Option;

interface GeneralDetailsFormProps {
  onSubmit: (props: GeneralDetailsFormSubmit) => void;
  isSaving: boolean;
}

enum LIVE_AVAILABLE_KEYS {
  FROM = 'availableFrom',
  TO = 'availableTo',
  TIMEZONE = 'availabilityTimezone',
}

const GeneralDetailsForm = ({
  onSubmit,
  isSaving,
}: GeneralDetailsFormProps) => {
  const { assetDraft, setAssetDraft, isLoading } = useAssetDetailsContext();
  const isLiveType = isLiveAssetType(assetDraft);

  const getDefaultValueForAvailabilityTimezone = useCallback(() => {
    if (!isLiveType) {
      return null;
    }

    return !assetDraft.availabilityTimezone
      ? defaultTimeZoneValue
      : Number(assetDraft.availabilityTimezone);
  }, [assetDraft.availabilityTimezone, isLiveType]);

  const getDefaultValueForAvailabilityDate = useCallback(
    (type: 'availableTo' | 'availableFrom') => {
      if (!isLiveType || !assetDraft?.[type]) {
        return null;
      }

      return convertNumberToUTCWithOffset(
        assetDraft[type],
        getDefaultValueForAvailabilityTimezone()
      );
    },
    [assetDraft, getDefaultValueForAvailabilityTimezone, isLiveType]
  );

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    register,
    unregister,
    reset,
    getValues,
    watch,
    setError,
  } = useForm<AssetForm>();

  useEffect(() => {
    if (!assetDraft) {
      return;
    }

    reset({
      ...assetDraft,
      availabilityTimezone: getDefaultValueForAvailabilityTimezone(),
      availableTo: getDefaultValueForAvailabilityDate('availableTo'),
      availableFrom: getDefaultValueForAvailabilityDate('availableFrom'),
    });
  }, [
    assetDraft,
    getDefaultValueForAvailabilityDate,
    getDefaultValueForAvailabilityTimezone,
    reset,
  ]);

  const [parentAssetDraft, setParentAssetDraft] = useState<Asset | undefined>();
  const { state, detach, onDispatch } = useImageContext();

  const submit = (form: AssetForm) => {
    if (assetDraft.type !== AssetType.LIVE_EVENT) {
      //Only for LIVE EVENTS asset should be extended with timezone and availability dates

      const assetToSave = _omit(
        form,
        'availableFrom',
        'availableTo',
        'availabilityTimezone'
      ) as Asset;

      onSubmit({
        asset: assetToSave,
      });

      reset(assetToSave);
      return;
    }

    const datesValidationMessage = validateLiveEventDates(form);

    if (datesValidationMessage) {
      setError(LIVE_AVAILABLE_KEYS.TO, {
        message: datesValidationMessage,
      });
      return;
    }

    reset(form);

    const shouldRecalculateOffset =
      !assetDraft.availabilityTimezone ||
      assetDraft.availabilityTimezone !== form.availabilityTimezone;

    //From DatePicker we got moment object in UTC as it was initialized with default UTC date
    //Need to convert based on timezone offset
    const convertedAvailableFrom = convertLocalDateToUTCWithOffset({
      date: form.availableFrom,
      offset: form.availabilityTimezone,
      shouldRecalculateOffset,
    });

    const convertedAvailableTo = convertLocalDateToUTCWithOffset({
      date: form.availableTo,
      offset: form.availabilityTimezone,
      shouldRecalculateOffset,
    });

    const assetToSave: Asset = {
      ...form,
      availableFrom: convertedAvailableFrom,
      availableTo: convertedAvailableTo,
    };

    onSubmit({
      asset: assetToSave,
    });
  };

  useEffect(() => {
    const fields = [
      'tags',
      'moods',
      'genres',
      'moderations',
      'accessType',
      LIVE_AVAILABLE_KEYS.TO,
      LIVE_AVAILABLE_KEYS.FROM,
      LIVE_AVAILABLE_KEYS.TIMEZONE,
    ];

    fields.forEach((field) => register(field));

    return () => {
      fields.forEach((field) => unregister(field));
    };
  }, [register, unregister]);

  useEffect(() => {
    if (assetDraft.parentId) {
      getAssetById(assetDraft.parentId).then((asset) =>
        setParentAssetDraft(asset)
      );
    }
  }, [assetDraft.parentId]);

  useEffect(() => {
    if (isLoading || assetDraft.id || getValues().name === assetDraft.name)
      return;
    reset(assetDraft);
  }, [assetDraft, getValues, isLoading, reset]);

  const getElementFromState = (stateKey: keyof AssetImageState) =>
    assetDraft?.type === AssetType.EPISODE
      ? state[stateKey]?.child
      : state[stateKey]?.jumbotron;

  const returnProperImageType = (): ImageType =>
    assetDraft?.type === AssetType.EPISODE
      ? ImageType.child
      : ImageType.jumbotron;

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(submit)} id="asset-general-details-form">
        <fieldset>
          <Space direction="vertical" expand>
            <FormGroup
              errorMessage={errors.name?.message}
              label="Administrative name"
              input={
                <Controller
                  data-testid="GeneralDetailsForm__name_input"
                  as={Input}
                  control={control}
                  name="name"
                  rules={{ required: true }}
                />
              }
            />
            {!isLiveType && !assetDraft.parentId && (
              <FormGroup
                label="Parent asset"
                input={
                  <AssetParentChooser
                    chosenAsset={assetDraft}
                    onChoose={(asset) => {
                      setParentAssetDraft(asset);
                      setAssetDraft({ ...assetDraft, parentId: asset?.id });
                    }}
                  />
                }
              />
            )}
            {!isLiveType && assetDraft.parentId && parentAssetDraft && (
              <FormGroup
                label="Parent asset"
                input={
                  <div className={styles.box}>
                    {parentAssetDraft.name}
                    <DeleteOutlined
                      onClick={() =>
                        setAssetDraft({ ...assetDraft, parentId: undefined })
                      }
                    />
                  </div>
                }
              />
            )}

            <MultiSelectGeneralDetails
              action={useDictionary}
              mainData={assetDraft.genres}
              parentData={parentAssetDraft?.genres}
              label="Genres"
              type="genres"
              setValue={setValue}
            />

            <MultiSelectGeneralDetails
              action={useDictionary}
              mainData={assetDraft.moods}
              parentData={parentAssetDraft?.moods}
              label="Moods"
              type="moods"
              setValue={setValue}
            />

            <MultiSelectGeneralDetails
              action={useDictionary}
              mainData={assetDraft.tags}
              parentData={parentAssetDraft?.tags}
              label="Tags"
              type="tags"
              setValue={setValue}
              createNewAllowed
            />

            <MultiSelectGeneralDetails
              action={useDictionary}
              mainData={assetDraft.moderations}
              parentData={parentAssetDraft?.moderations}
              label="Appropriateness Tags"
              type="moderations"
              setValue={setValue}
            />

            {!isLiveType && (
              <FormGroup
                label="Year of release"
                errorMessage={errors.year?.message}
                input={
                  <Controller
                    as={Input}
                    placeholder="Enter a year of release"
                    type="number"
                    control={control}
                    name="year"
                    rules={{
                      minLength: { value: 4, message: 'Enter correct data' },
                      maxLength: { value: 4, message: 'Enter correct data' },
                    }}
                  />
                }
              />
            )}

            {isLiveEventType(assetDraft) && (
              <>
                <FormGroup
                  label="Time zone"
                  errorMessage={errors.availabilityTimezone?.message}
                  input={
                    <Select
                      value={watch(LIVE_AVAILABLE_KEYS.TIMEZONE)}
                      onSelect={(value: number) =>
                        setValue(LIVE_AVAILABLE_KEYS.TIMEZONE, value)
                      }
                      placeholder="Choose time zone"
                      showSearch
                      filterOption={(input, option) =>
                        option?.key.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {getTimezones().map(({ label, offset }) => (
                        <Option key={label} value={offset}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  }
                />
                <FormGroup
                  label="Start"
                  errorMessage={errors.availableFrom?.message}
                  input={
                    <DatePicker
                      format={dateFormatWith12Time}
                      placeholder="Select date and time"
                      showTime
                      onOk={(value) =>
                        setValue(LIVE_AVAILABLE_KEYS.FROM, value)
                      }
                      value={watch(LIVE_AVAILABLE_KEYS.FROM)}
                      allowClear={false}
                      showNow={false}
                    />
                  }
                />
                <FormGroup
                  label="End"
                  errorMessage={errors.availableTo?.message}
                  input={
                    <DatePicker
                      format={dateFormatWith12Time}
                      placeholder="Select date and time"
                      showTime
                      onOk={(value) => setValue(LIVE_AVAILABLE_KEYS.TO, value)}
                      value={watch(LIVE_AVAILABLE_KEYS.TO)}
                      allowClear={false}
                      showNow={false}
                    />
                  }
                />
              </>
            )}

            {hasAssetAccessType(assetDraft) && (
              <FormGroup
                label="Access type"
                input={
                  <div className={styles.advancedRadioWrapper}>
                    <AdvancedRadioElement
                      value={AssetAccessType.FREE}
                      description="Asset will be available to watch in all published regions for all registered users."
                      title="Free"
                      selected={watch('accessType')}
                      onSelect={() =>
                        setValue('accessType', AssetAccessType.FREE)
                      }
                      className={styles.radio}
                    />
                    <AdvancedRadioElement
                      value={AssetAccessType.PAID}
                      description={
                        <div>
                          <div>
                            Asset will be available to watch in all published
                            regions only for users with plans.
                          </div>
                          <div className={styles.extraPaidInfo}>
                            <InfoCircleOutlined />
                            Please note that asset must be added to plans.
                          </div>
                        </div>
                      }
                      className={styles.radio}
                      title="Paid"
                      selected={watch('accessType')}
                      onSelect={() =>
                        setValue('accessType', AssetAccessType.PAID)
                      }
                    />
                  </div>
                }
              />
            )}

            {!isLiveType && (
              <FormGroup
                label={
                  assetDraft?.type === AssetType.EPISODE
                    ? 'Episode thumbnail'
                    : 'Background thumbnail'
                }
                labelDescription="Aspect ratio 16:9"
                input={
                  <ImagePlaceholder
                    onImageFileChange={(file) =>
                      onDispatch({
                        type: AssetImageActionType.FILE,
                        payload: {
                          [returnProperImageType()]: file,
                        },
                      })
                    }
                    onImageDelete={(id) => {
                      detach(id, returnProperImageType());
                      onDispatch({
                        type: AssetImageActionType.ERROR,
                        payload: {
                          [returnProperImageType()]: '',
                        },
                      });
                    }}
                    isLoading={
                      (getElementFromState('loading') as boolean) || isSaving
                    }
                    image={getElementFromState('image') as Image}
                    previewImage={getElementFromState('preview') as string}
                    setPreviewImage={(preview) =>
                      onDispatch({
                        type: AssetImageActionType.PREVIEW,
                        payload: { [returnProperImageType()]: preview },
                      })
                    }
                    error={getElementFromState('error') as string}
                  />
                }
              />
            )}
          </Space>
        </fieldset>
      </form>
    </FormContainer>
  );
};

export default GeneralDetailsForm;
