import React from 'react';
import { Select } from 'antd';
import { periodSelectorValues } from 'utils/constants';
import styles from './index.module.scss';
const { Option } = Select;

interface PeriodSelectorProps {
  onChoose: (period: number) => void;
  choosenPeriod: number;
  disabled?: boolean;
}

const PeriodSelector = ({
  onChoose,
  choosenPeriod,
  disabled,
}: PeriodSelectorProps) => (
  <div className={styles.fullWidth}>
    <Select
      value={choosenPeriod}
      placeholder="Search for billing frequency"
      filterOption={false}
      onChange={onChoose}
      className={styles.fullWidth}
      disabled={disabled}
    >
      {periodSelectorValues?.map((period, index) => (
        <Option key={index} value={period.value}>
          {period.label}
        </Option>
      ))}
    </Select>
  </div>
);

export default PeriodSelector;
