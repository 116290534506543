import { Form, Radio } from 'antd';
import PageCard from 'components/PageCard';
import React, { useCallback } from 'react';
import {
  Collection,
  CollectionDisplayRule,
  CollectionDisplayRuleForm,
} from 'types';
import styles from '../../index.module.scss';
import CollectionDisplayRadioRule from './components/CollectionDisplayRadio';

interface CollectionDisplayRulesProps {
  collection: Collection;
  onCollectionChange: (collection: Collection) => void;
}

const CollectionDisplayRulesForm = ({
  collection,
  onCollectionChange,
}: CollectionDisplayRulesProps) => {
  const onDisplayRuleChange = useCallback(
    (rule: CollectionDisplayRuleForm) => {
      onCollectionChange({
        ...collection,
        displayRule: rule === 'ALL' ? null : rule,
      });
    },
    [collection, onCollectionChange]
  );

  return (
    <PageCard title="Display rules">
      <div className={styles.displayRulesDescription}>
        Display assets in state
      </div>

      <Form layout="horizontal" labelCol={{ span: 6 }}>
        <Radio.Group
          value={collection.displayRule || 'ALL'}
          onChange={(e) => onDisplayRuleChange(e.target.value)}
        >
          <CollectionDisplayRadioRule displayRule="ALL" />

          {Object.values(CollectionDisplayRule).map((rule) => (
            <CollectionDisplayRadioRule displayRule={rule} />
          ))}
        </Radio.Group>
      </Form>
    </PageCard>
  );
};

export default CollectionDisplayRulesForm;
