import React, { useEffect, useState } from 'react';
import { GlobalOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { publishPlan, unpublishPlan } from 'actions/plans';
import { Affix, Button, Card, Modal, message } from 'antd';
import AssetStatusTag from 'components/AssetStatusTag';
import { AssetStatus } from 'types';
import { errorNotify } from 'utils/errorNotify';
import cn from 'classnames';
import { PublishType } from 'types/publish';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import styles from './index.module.scss';

const PlanStatusCard = ({ id, status }: { id: string; status: string }) => {
  const [isPublished, toggleIsPublished] = useState(
    status === AssetStatus.PUBLISHED
  );
  const [confirmationModalOpened, setConfirmationModalOpen] =
    useState<PublishType>();
  const { canUse } = usePermission();

  const getModalMessage = (type?: PublishType) => {
    const keepInMindMsg =
      'Keep in mind that all assigned promotions will be also published.';
    switch (type) {
      case PublishType.PUBLISH:
        return `Are you sure you want to publish the plan? ${keepInMindMsg}`;
      case PublishType.REPUBLISH:
        return `Are you sure you want to re-publish the plan? ${keepInMindMsg}`;
      case PublishType.UNPUBLISH:
        return `Are you want to unpublish the plan?`;
    }
  };

  useEffect(() => {
    if (status) {
      toggleIsPublished(status === AssetStatus.PUBLISHED);
    }
  }, [status]);

  const [publish, isPublishingProduct] = useSendAction<void, string>(
    publishPlan,
    {
      onDone: () => {
        message.success('Published!');
        toggleIsPublished(true);
        setConfirmationModalOpen(undefined);
      },
      onError: errorNotify,
    }
  );

  const [unpublish, isUnpublishingProduct] = useSendAction<void, string>(
    unpublishPlan,
    {
      onDone: () => {
        message.success('Unpublished!');
        toggleIsPublished(false);
        setConfirmationModalOpen(undefined);
      },
      onError: errorNotify,
    }
  );

  return (
    <>
      <Affix offsetTop={60}>
        <Card
          className={styles.customCard}
          title={
            <>
              <InfoCircleOutlined />
              <span className={styles.customTitleCard}>Plan status</span>
            </>
          }
        >
          <div className={styles.customTitleForm}>
            <span>Status</span>
            <AssetStatusTag
              status={
                isPublished ? AssetStatus.PUBLISHED : AssetStatus.UNPUBLISHED
              }
            />
          </div>
          {!isPublished && (
            <Button
              disabled={isPublishingProduct}
              onClick={() => setConfirmationModalOpen(PublishType.PUBLISH)}
              danger={isPublished}
              type="primary"
              className={styles.button}
            >
              <GlobalOutlined />
              Publish
            </Button>
          )}
          {isPublished && canUse([Permission.PUBLISH_PLAN]) && (
            <>
              <Button
                disabled={isPublishingProduct || isUnpublishingProduct}
                onClick={() => setConfirmationModalOpen(PublishType.REPUBLISH)}
                type="primary"
                className={styles.button}
              >
                <GlobalOutlined />
                Republish
              </Button>
              <Button
                disabled={isUnpublishingProduct || isPublishingProduct}
                onClick={() => setConfirmationModalOpen(PublishType.UNPUBLISH)}
                danger
                type="primary"
                className={cn(styles.button, styles.unpublishButton)}
              >
                Unpublish
              </Button>
            </>
          )}
        </Card>
      </Affix>

      <Modal
        visible={!!confirmationModalOpened}
        title="Confirmation"
        confirmLoading={isPublishingProduct || isUnpublishingProduct}
        onCancel={() => setConfirmationModalOpen(undefined)}
        onOk={() =>
          confirmationModalOpened === PublishType.UNPUBLISH
            ? unpublish(id)
            : publish(id)
        }
      >
        {getModalMessage(confirmationModalOpened)}
      </Modal>
    </>
  );
};

export default PlanStatusCard;
