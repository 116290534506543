import countries from 'locales/en/countries.json';
import { SetTaxCountriesOptionProps } from './types';

export const getCountryData = (code: string) =>
  countries.find((countryData) => countryData.code === code);

export const setTaxCountriesOption = ({
  countries,
  option,
  value,
}: SetTaxCountriesOptionProps) =>
  countries.map((country) => ({ ...country, [option]: value }));

export const removeCountryFromFormState = (
  country: string,
  formValueCountries: string[]
) =>
  formValueCountries.filter((formValueCountry) => formValueCountry !== country);
