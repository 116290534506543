import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getPlanPrices } from 'actions/plans';
import { Select, Spin } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import React, { useCallback } from 'react';
import { PlanPrice } from 'types/plan';
import styles from './index.module.scss';
const { Option } = Select;

interface PlanChooserProps {
  onChoose: (plan?: PlanPrice) => void;
  selectedPlanUuid?: string;
  placeholder?: string;
  selectedPrice?: PlanPrice;
}

const PriceChooser = ({
  selectedPlanUuid,
  onChoose,
  placeholder = 'Search for price',
  selectedPrice,
}: PlanChooserProps) => {
  const {
    data: prices,
    isLoading,
    error,
  } = useFetchActionObj<PlanPrice[]>(
    useCallback(() => {
      if (!selectedPlanUuid) return Promise.resolve([] as PlanPrice[]);

      return getPlanPrices(selectedPlanUuid);
    }, [selectedPlanUuid])
  );

  return (
    <div className={styles.fullWidth}>
      <Select
        value={selectedPrice?.uuid}
        placeholder={placeholder}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onChange={(uuid: any) => {
          onChoose(prices?.find((price: PlanPrice) => price.uuid === uuid));
        }}
        className={styles.fullWidth}
      >
        {prices?.map((price: PlanPrice) => (
          <Option key={price.uuid} value={price.uuid!}>
            {price.amount / 100} {price.currency} | {price.interval} |{' '}
            {price.gateway}
          </Option>
        ))}
      </Select>

      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default PriceChooser;
