export const isEmail = (value: string) => {
  const email =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  // 256 being the max size of an email address
  return value.length < 256 && email.test(value);
};

export const isPasswordValidLength = (value: string) => value.length > 7;

export const isPhoneNumber = (value: string) => {
  const phoneNumber = /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return phoneNumber.test(value);
};
