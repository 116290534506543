import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import SeoSidebar from './components/SeoSidebar';
import styles from './index.module.scss';
import Sitemap from './components/Sitemap';

const SEO = () => (
  <div className={styles.container}>
    <SeoSidebar />

    <Switch>
      <Route exact path={Paths.SEO}>
        <Sitemap />
      </Route>
    </Switch>
  </div>
);

export default SEO;
