import { Alert, Modal, Table } from 'antd';
import AssetAvailabilityFrom from 'components/ScheduledPublish/AssetAvailabilityFrom';
import AssetAvailabilitySelect from 'components/ScheduledPublish/AssetAvailabilitySelect';
import React, { useCallback, useEffect, useState } from 'react';
import {
  AddRegionLicense,
  AssetAvailabilityValue,
  AssetLicense,
  LicenseEditProps,
  LicenseRegion,
  LicenseStatus,
} from 'types/licenses';
import {
  convertLocalDateToUTCWithOffset,
  convertNumberToUTCWithOffset,
  defaultTimeZoneValue,
  getCurrentDateInUTC,
} from 'utils/timezones';
import styles from '../index.module.scss';

const { Column } = Table;

interface AddRegionModalProps {
  isOpen?: boolean;
  onClose: () => void;
  licenses?: AssetLicense[];
  isLoadingDataTable?: boolean;
  onAddLicenses: (licenses: AddRegionLicense[]) => void;
  isConfirmLoading?: boolean;
}

const AddRegionModal = ({
  onClose,
  isOpen,
  licenses,
  isLoadingDataTable,
  onAddLicenses,
  isConfirmLoading,
}: AddRegionModalProps) => {
  const [selectedRegionsIds, setSelectedRegionsIds] = useState<number[]>([]);
  const [regionLicenses, setRegionLicenses] = useState<AddRegionLicense[]>([]);
  const [warningRegionsIds, setWarningRegionsIds] = useState<number[]>([]);

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: AssetLicense[]) => {
      const ids = selectedRows
        ?.map((l) => l.regionId)
        ?.filter((l) => !!l) as number[];

      setSelectedRegionsIds(ids);
    },
  };

  const isLicenseRegionSelectedToAdd = useCallback(
    (license: AddRegionLicense) =>
      selectedRegionsIds?.some((id) => id === license.regionId),
    [selectedRegionsIds]
  );

  const onAdd = () => {
    //Handle only regions selected by checkbox
    const updatedRegionsLicenses = regionLicenses?.filter(
      isLicenseRegionSelectedToAdd
    );

    const draftRegionsLicenses = updatedRegionsLicenses?.map(
      (updatedRegionLicense) => {
        const isScheduledLicense =
          updatedRegionLicense.availability ===
          AssetAvailabilityValue.SCHEDULED;

        //From DatePicker we got moment object in UTC as it was initialized with default UTC date
        //Need to convert based on timezone offset
        const availabilityFromAfterOffset = convertLocalDateToUTCWithOffset({
          date: updatedRegionLicense.availableFrom,
          offset: updatedRegionLicense.availabilityTimezone,
          shouldRecalculateOffset: true,
        });

        const currentDate = getCurrentDateInUTC();

        return {
          ...updatedRegionLicense,
          status: updatedRegionLicense?.status || LicenseStatus.DRAFT,
          ...(isScheduledLicense
            ? {
                availableFrom: availabilityFromAfterOffset,
                availabilityTimezone: updatedRegionLicense.availabilityTimezone,
              }
            : {
                availableFrom: currentDate,
                availabilityTimezone: defaultTimeZoneValue,
              }), //If not scheduled BE is expecting current date with default timezone
        };
      }
    );

    onAddLicenses(draftRegionsLicenses);
    onClose();
  };

  const onLicenseEdit = ({ licenseToEdit }: LicenseEditProps) => {
    const editedArray = regionLicenses?.length
      ? regionLicenses.map((l) =>
          l.regionId === licenseToEdit.regionId ? licenseToEdit : l
        )
      : [licenseToEdit];

    setRegionLicenses(editedArray);
  };

  useEffect(() => {
    if (licenses?.length) {
      const mappedLicenses: AddRegionLicense[] = licenses?.map((l) => ({
        ...l,
        availability: AssetAvailabilityValue.IMMEDIATE,
        availabilityTimezone: defaultTimeZoneValue,
        availableFrom: convertNumberToUTCWithOffset(
          l.dateFrom,
          defaultTimeZoneValue
        ),
      }));

      setRegionLicenses(mappedLicenses);
    }
  }, [licenses]);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title="Add region"
      okText={`Add ${
        selectedRegionsIds.length > 0 ? `(${selectedRegionsIds.length})` : ''
      }`}
      width="80vw"
      onOk={onAdd}
      okButtonProps={{
        disabled: !selectedRegionsIds?.length,
      }}
      confirmLoading={isConfirmLoading}
      maskClosable={false}
    >
      {!licenses?.length && (
        <div className={styles.alert}>
          <Alert
            type="warning"
            showIcon
            message="Please add more licenses to publish this asset in regions."
          />
        </div>
      )}

      <Table
        dataSource={regionLicenses}
        loading={isLoadingDataTable}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        rowKey="id"
      >
        <Column
          title="Region"
          dataIndex="region"
          render={(region: LicenseRegion) => <span>{region.name}</span>}
          width="25%"
        />

        <Column
          title="Availability"
          render={(_, license: AddRegionLicense) => (
            <AssetAvailabilitySelect
              value={license.availability}
              setValue={(value) =>
                onLicenseEdit({
                  licenseToEdit: { ...license, availability: value },
                })
              }
              disabled={!isLicenseRegionSelectedToAdd(license)}
            />
          )}
          width="25%"
        />

        <Column
          title="Available from"
          dataIndex="availableFrom"
          render={(_, license: AddRegionLicense) => (
            <AssetAvailabilityFrom
              license={license}
              isSelectedToAdd={isLicenseRegionSelectedToAdd(license)}
              onLicenseEdit={onLicenseEdit}
              setWarningRegionsIds={setWarningRegionsIds}
              isWarning={warningRegionsIds.some((r) => r === license.regionId)}
            />
          )}
          width="50%"
        />
      </Table>

      {!!warningRegionsIds?.length && (
        <div className={styles.dateAlert}>
          <Alert
            showIcon
            type="warning"
            message="Some dates do not match your region license start date"
          />
        </div>
      )}
    </Modal>
  );
};

export default AddRegionModal;
