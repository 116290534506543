import React, { useState } from 'react';
import { Asset } from 'types';
import { DeleteOutlined } from '@ant-design/icons';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { deleteAssetFromPlan } from 'actions/plans';
import AssetsTableSearchable from 'components/AssetsTableSearchable';
import { mapAssetListToTableStructure } from 'utils/table';
import styles from './index.module.scss';

const AssetListPlan = ({
  assignedAssets = [],
  planUuid,
  onAssignedAssets,
}: {
  assignedAssets: Asset[];
  onAssignedAssets: (assets: Asset[]) => void;
  planUuid: string;
}) => {
  const [query, setQuery] = useState<string>('');

  const { sendAction: deleteAsset, isLoading: isDeleting } = useSendActionObj<
    string,
    { planUuid: string; uuid: string }
  >((body) => deleteAssetFromPlan(body), {
    onDone: (uuid) =>
      onAssignedAssets(assignedAssets.filter((s) => s.uuid !== uuid)),
  });

  const data = mapAssetListToTableStructure({
    assets: assignedAssets,
    query,
    actionColumn: (asset: Asset) => (
      <div className={styles.actionCel}>
        <DeleteOutlined
          onClick={() => deleteAsset({ uuid: asset.uuid, planUuid })}
        />
      </div>
    ),
  });

  return (
    <AssetsTableSearchable
      setSearchQuery={setQuery}
      loading={isDeleting}
      data={data}
    />
  );
};

export default AssetListPlan;
