import { Button, Form, Input, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { languages } from '@laminar-product/client-commons-core/core';
import { FormInstance } from 'antd/es/form/Form';
import { useMemo } from 'react';
import { InterestTranslation } from 'types/interests';
import styles from './InterestsPublicDetailsForm.module.scss';

const InterestsPublicDetailsForm = ({ form }: { form: FormInstance }) => {
  const translations = Form.useWatch('translations', form) as
    | InterestTranslation[]
    | undefined;
  const mappedTranslations = useMemo(
    () =>
      languages
        .map(({ alpha2, label }) => ({
          label,
          value: alpha2,
        }))
        .filter(
          ({ value }) =>
            !translations?.some(({ language }) => language === value)
        ),
    [translations]
  );
  const selectedLanguage = (fieldName: number) =>
    languages.find(
      ({ alpha2 }) =>
        form.getFieldValue(['translations', fieldName, 'language']) === alpha2
    );

  return (
    <Form.List name="translations" rules={[]}>
      {(fields, { add, remove }) => (
        <>
          <div className={styles.header}>
            <span className={styles.label}>Public details</span>
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                add({
                  language: mappedTranslations[0]?.value || '',
                  value: '',
                })
              }
            >
              Add language
            </Button>
          </div>
          {fields.map((field) => {
            const selectedLang = selectedLanguage(field.name);
            return (
              <div className={styles.formGroup} key={field.key}>
                <Form.Item name={[field.name, 'language']}>
                  <Select
                    showSearch
                    className={styles.select}
                    filterOption={(input, option) =>
                      !!option?.label
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        label: selectedLang?.label || '',
                        value: selectedLang?.alpha2 || '',
                      },
                      ...mappedTranslations,
                    ]}
                    disabled={!field.name}
                  />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'value']}
                  rules={[
                    {
                      required: true,
                      message: 'Name is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {!!field.name && (
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => remove(field.name)}
                  />
                )}
              </div>
            );
          })}
        </>
      )}
    </Form.List>
  );
};

export default InterestsPublicDetailsForm;
