import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormContainer from 'components/FormContainer';
import { MinAge, Name } from 'pages/CensorshipRules/CensorshipRuleFormFields';
import { CensorshipRule } from 'types/censorships';

const CensorshipRuleGeneralDetails = () => {
  const { control, errors } = useFormContext<CensorshipRule>();

  return (
    <FormContainer>
      <Name control={control} errors={errors} />
      <MinAge control={control} errors={errors} />
    </FormContainer>
  );
};

export default CensorshipRuleGeneralDetails;
