import _uniq from 'lodash/uniq';
import { getCountryData } from './components/TaxCountries/helpers';
import { paymentGateways } from './constants';
import { SetInitialDataProps, TaxProduct } from './types';

export const mapPaymentGatewayDataToTable = () =>
  paymentGateways.map((paymentGateway, key) => ({
    paymentGateway,
    key,
  }));

export const mapTaxCountriesDataToTable = ({
  configurationData,
}: SetInitialDataProps) => {
  const {
    estimationCountries,
    storageCountries,
    billingAddressCountries,
    addressValidationCountries,
  } = configurationData;
  const countriesCodes = _uniq([
    ...estimationCountries,
    ...storageCountries,
    ...billingAddressCountries,
    ...addressValidationCountries,
  ]);

  return countriesCodes.map((code) => {
    const countryData = getCountryData(code);

    return {
      ...(countryData ? { ...countryData } : { code }),
    };
  });
};

export const getEditedTaxCodes = (
  taxCodesFromForm?: TaxProduct[],
  taxCodesFromApi?: TaxProduct[]
) =>
  taxCodesFromForm?.filter(
    (taxCodeFromForm) =>
      !taxCodesFromApi?.some(
        ({ resourceId, productTaxCode }) =>
          resourceId &&
          taxCodeFromForm.resourceId === resourceId &&
          taxCodeFromForm.productTaxCode === productTaxCode
      )
  );

export const getDeletedTaxCodes = (
  taxCodesFromForm?: TaxProduct[],
  taxCodesFromApi?: TaxProduct[]
) =>
  taxCodesFromApi?.filter(
    ({ resourceId }) =>
      resourceId &&
      !taxCodesFromForm?.some(
        (taxCodeFromForm) => resourceId === taxCodeFromForm.resourceId
      )
  );
