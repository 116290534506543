import React, { useCallback } from 'react';
import { getAssetImages } from 'actions/assets';
import { Image, ImageStatus } from 'types';
import ErrorIndicator from 'components/ErrorIndicator';
import { Col, PageHeader, Row, Skeleton, Typography } from 'antd';
import ImageStatusTag from 'components/ImageStatusTag';
import {
  useFetchAction,
  usePolling,
} from '@laminar-product/client-commons-core/hooks';
import {
  IMAGE_TYPES_WITHOUT_CONTENT_DISTRIBUTORS,
  IMAGE_TYPE_DESCRIPTIONS,
} from 'utils/constants';
import cn from 'classnames';
import PageContainer from 'components/PageContainer';
import { ImageType } from '@laminar-product/client-commons-core/core';
import { useAssetDetailsContext } from 'contexts/assetContext';
import ImageView from './ImageView';
import styles from './index.module.scss';

const { Title } = Typography;

interface RenderImagesSectionProps {
  imageTypes?: ImageType[];
  header: string;
}

const AssetImages = () => {
  const {
    asset: { id },
  } = useAssetDetailsContext();
  const action = useCallback(
    () =>
      getAssetImages({
        id: Number(id),
      }),
    [id]
  );
  const [data, isLoading, error, refresh] = useFetchAction<Image[]>(action);

  const refreshImages = useCallback(() => {
    if (data?.find((image) => image.status !== ImageStatus.TRANSFORMED)) {
      refresh();
    }
  }, [data, refresh]);

  usePolling(refreshImages);

  const imageForType = (type: ImageType) =>
    data?.find((image) => image.type === type);

  if (!data && isLoading) return <Skeleton />;

  if (error) return <ErrorIndicator error={error} />;

  const renderImagesSection = ({
    header,
    imageTypes,
  }: RenderImagesSectionProps) => (
    <PageContainer className={styles.root}>
      <PageHeader title={header} className={styles.header} />
      {imageTypes?.map((type) => {
        const image = imageForType(type);
        const typeDescription = IMAGE_TYPE_DESCRIPTIONS[type];

        return (
          <>
            <Row key={type}>
              <Col flex="auto">
                <Title
                  level={5}
                  className={cn(
                    !!typeDescription.extra && styles.titleWithSubtitle
                  )}
                >
                  {typeDescription?.label}{' '}
                  <small className={styles.aspectRatio}>
                    {typeDescription?.aspectRatio}
                  </small>
                  {!!image && <ImageStatusTag status={image?.status} />}
                </Title>
                <p className={styles.extra}>{typeDescription.extra}</p>
              </Col>
            </Row>
            <div className={styles.imageViewContainer}>
              <ImageView
                key={type}
                image={image}
                refresh={refresh}
                type={type}
              />
            </div>
          </>
        );
      })}
    </PageContainer>
  );

  return (
    <div>
      {renderImagesSection({
        header: 'Main images',
        imageTypes: IMAGE_TYPES_WITHOUT_CONTENT_DISTRIBUTORS,
      })}

      {renderImagesSection({
        header: 'Content distribution',
        imageTypes: [ImageType.featureBannerHD],
      })}
    </div>
  );
};

export default AssetImages;
