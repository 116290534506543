import { AxiosError } from 'axios';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';

const getErrorDesctiption = (errorCode?: string) => {
  switch (errorCode) {
    case NUMBER_EXIST_ERROR_CODE || EMAIL_EXIST_ERROR_CODE:
      return `The user with provided ${
        errorCode === NUMBER_EXIST_ERROR_CODE ? 'phone number' : 'email'
      } already exists.`;

    case WRONG_NUMBER_ERROR_CODE:
      return 'The provided phone number is invalid. User has not been updated.';

    case CANNOT_CHANGE_EMAIL_ERROR_CODE:
      return 'The email of selected user cannot be changed.';

    case CANNOT_CHANGE_NUMBER_ERROR_CODE:
      return 'The phone number of selected user cannot be changed.';

    default:
      return 'Something went wrong. User has not been edited.';
  }
};

export const handleChangeUserDataError = (e: AxiosError) => {
  captureError(e);

  const errorCode = e.response?.data?.code;

  notification.error({
    title: 'Error',
    description: getErrorDesctiption(errorCode),
  });
};

const NUMBER_EXIST_ERROR_CODE = 'auth/phone-number-already-exists';
const EMAIL_EXIST_ERROR_CODE = 'auth/email-already-exists';
const WRONG_NUMBER_ERROR_CODE = 'auth/invalid-phone-number';
const CANNOT_CHANGE_EMAIL_ERROR_CODE = 'user/email_cannot_be_changed';
const CANNOT_CHANGE_NUMBER_ERROR_CODE = 'user/phone_number_cannot_be_changed';
