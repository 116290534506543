import { Table } from 'antd';
import React, { useCallback, useState } from 'react';
import Column from 'antd/lib/table/Column';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { Pagination } from 'types';
import { ContentDistributionConfig } from 'types/contentDistribution';
import { usePageParam, usePaginationObject } from 'utils/pagination';
import { getContentDistributionConfigPagination } from 'actions/contentDistribution';
import { useGetRegions } from 'hooks/useRegions';
import DeleteButton from 'components/DeleteButton';
import notification from 'utils/notification';
import { useHistory } from 'react-router-dom';
import useContentDistributionConfigActions from 'pages/ContentDistribution/hooks/useContentDistributionConfigActions';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import { useAllLanguages } from 'hooks/useLanguages';
import ContentDistributionConfigRemove from '../ContentDistributionConfigRemove';

export interface RemoveConfigModal {
  isVisible: boolean;
  configToRemove?: ContentDistributionConfig;
}

const ContentDistributionTable = () => {
  const [removeConfigModal, setRemoveConfigModal] =
    useState<RemoveConfigModal>();
  const { push } = useHistory();
  const page = usePageParam();
  const { regions } = useGetRegions();
  const { canUse } = usePermission();

  const onRemoveSuccess = () => {
    notification.success({
      description: `${removeConfigModal?.configToRemove?.name} configuration has been successfully removed.`,
    });
    refreshContentDistributionConfigs();
    setRemoveConfigModal({ isVisible: false });
  };

  const { removeConfig, isRemoving } = useContentDistributionConfigActions({
    onRemoveSuccess,
  });
  const { getLanguageNameByIsoCode } = useAllLanguages();

  const {
    data: contentDistributionConfigs,
    isLoading,
    refresh: refreshContentDistributionConfigs,
  } = useFetchActionObj<Pagination<ContentDistributionConfig>>(
    useCallback(
      () =>
        getContentDistributionConfigPagination({
          page,
        }),
      [page]
    )
  );

  const onRowClick = (config: ContentDistributionConfig) =>
    push(`/contentDistribution/configuration/${config.uuid}`);

  return (
    <>
      <Table
        indentSize={40}
        dataSource={contentDistributionConfigs?.data}
        rowClassName="clickable-row"
        rowKey="uuid"
        loading={isLoading}
        pagination={usePaginationObject(
          '/contentDistribution',
          contentDistributionConfigs,
          page
        )}
        onRow={(config: ContentDistributionConfig) => ({
          onClick: () => onRowClick(config),
        })}
      >
        <Column title="Name" key="name" dataIndex="name" />
        <Column
          title="Region"
          dataIndex="regionUuid"
          key="regionUuid"
          render={(regionUuid: string) =>
            regions?.find((region) => region.uuid === regionUuid)?.name
          }
        />
        <Column
          title="Primary language"
          dataIndex="primaryLanguage"
          key="primaryLanguage"
          render={(langCode) => getLanguageNameByIsoCode(langCode)}
        />

        {canUse([Permission.CONTENT_DISTRIBUTION_DELETE]) && (
          <Column
            dataIndex="delete"
            key="delete"
            width={50}
            render={(_, config: ContentDistributionConfig) => (
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                  setRemoveConfigModal({
                    isVisible: true,
                    configToRemove: config,
                  });
                }}
              />
            )}
          />
        )}
      </Table>

      {removeConfigModal?.isVisible && (
        <ContentDistributionConfigRemove
          visible={removeConfigModal?.isVisible}
          config={removeConfigModal?.configToRemove}
          onClose={() => setRemoveConfigModal({ isVisible: false })}
          onOk={removeConfig}
          isLoading={isRemoving}
        />
      )}
    </>
  );
};

export default ContentDistributionTable;
