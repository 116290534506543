import {
  useFetchAction,
  usePolling,
} from '@laminar-product/client-commons-core/hooks';
import { getSourceFiles } from 'actions/files';
import { Table } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import FileStatusTag from 'components/FileStatusTag';
import React, { useCallback, useState } from 'react';
import { FileStatus, SourceStatus, UploadedFile } from 'types';
import {
  useBoundToPages,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import moment from 'moment';
import Button from 'components/Button';
import { CAN_TRANSCODE_FILES } from 'utils/constants';
import FileTranscodeModal from './FileTranscodeModal';

const { Column } = Table;

const TranscodeFilesTable = ({ query }: { query?: string }) => {
  const page = usePageParam();

  const [fileToTranscode, setFileToTranscode] = useState<UploadedFile>();

  const [pagination, isLoading, error, refresh] = useFetchAction(
    useCallback(
      () =>
        getSourceFiles({
          page,
          fileStatuses: [
            SourceStatus.PROCESSING,
            SourceStatus.QUALITY_PASSED,
            SourceStatus.QUALITY_WARNING,
          ],
          searchQuery: query,
        }),
      [page, query]
    )
  );

  usePolling(refresh);

  useBoundToPages(`/files/transcode`, pagination, page);

  const onClickTranscode = (file: UploadedFile) => () =>
    setFileToTranscode(file);

  return (
    <>
      {!!fileToTranscode && (
        <FileTranscodeModal
          onTranscode={() => setFileToTranscode(undefined)}
          file={fileToTranscode}
          onCancel={() => setFileToTranscode(undefined)}
        />
      )}
      <ErrorIndicator error={error} />
      <Table
        rowKey="id"
        dataSource={pagination?.data}
        loading={pagination?.data?.length === 0 && isLoading}
        pagination={usePaginationObject(
          '/files/transcode',
          pagination,
          page ?? 1
        )}
      >
        <Column title="File name" key="name" dataIndex="name" />
        <Column
          title="Status"
          key="status"
          dataIndex="status"
          render={(_, file: UploadedFile) => <FileStatusTag file={file} />}
        />
        <Column
          title="Uploaded"
          key="uploadDate"
          dataIndex="uploadDate"
          render={(_, file: UploadedFile) => (
            <span>
              {file.createdAt
                ? moment(file.createdAt).format('YYYY-MM-DD hh:mm:ss')
                : ''}
            </span>
          )}
        />
        {CAN_TRANSCODE_FILES && (
          <Column
            title="Action"
            dataIndex="status"
            key="action"
            render={(status: FileStatus, file: UploadedFile) => {
              if (
                status === FileStatus.QUALITY_PASSED ||
                status === FileStatus.QUALITY_WARNING
              )
                return (
                  <Button
                    data-testid={`TranscodedFilesTable__transcodeBtn--${file.id}`}
                    onClick={onClickTranscode(file)}
                  >
                    Transcode
                  </Button>
                );
            }}
          />
        )}
      </Table>
    </>
  );
};

export default TranscodeFilesTable;
