import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export interface ButtonsRowProps {
  children: ReactNode;
  className?: string;
  align?: 'left' | 'right';
}

/**
 * Just simple flex wrapper with top margin
 * Buttons are placed on the right. You probably want to use it with the modals.
 */
const ButtonsRow = ({
  children,
  className,
  align = 'right',
}: ButtonsRowProps) => {
  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.alignLeft]: align === 'left',
          [styles.alignRight]: align === 'right',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default ButtonsRow;
