import React, { useCallback, useState } from 'react';
import PageDetailsHeader from 'components/PageDetailsHeader';
import { useParams } from 'react-router-dom';
import { RouteIdParams } from 'utils/routeParams';
import PromotionDetails from 'pages/Promotions/components/PromotionDetails';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getPromotion } from 'actions/promotions';
import { Tag } from 'antd';
import { PromotionStatus } from 'types/promotions';
import styles from '../index.module.scss';

const InternalDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const [promotion, , , refresh] = useFetchAction(
    useCallback(() => getPromotion({ uuid: id }), [id])
  );
  const [pageHeader, setPageHeader] = useState<string>(promotion?.name ?? '');

  return (
    <>
      <PageDetailsHeader>
        {pageHeader}
        {promotion?.status === PromotionStatus.PUBLISHED && (
          <div className={styles.tag}>
            <Tag color="green">{promotion?.status}</Tag>
          </div>
        )}
      </PageDetailsHeader>
      <PromotionDetails
        refresh={refresh}
        setPageHeader={setPageHeader}
        promotion={promotion}
      />
    </>
  );
};

export default InternalDetails;
