import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import PageContainer from 'components/PageContainer';
import { Skeleton } from 'antd';
import { getMenus } from 'actions/menus';
import { Paths } from 'types/paths';
import styles from './index.module.scss';
import MenuCustomPage from './MenuCustomPage';

export default () => {
  const [menus] = useFetchAction(useCallback(() => getMenus(), []));

  if (!menus)
    return (
      <PageContainer className={styles.infoContainer}>
        <Skeleton />
      </PageContainer>
    );

  return (
    <div className={styles.flexWrapper}>
      <Switch>
        <Route
          exact
          path={`${Paths.MENUS}/:id`}
          render={() => <MenuCustomPage menus={menus} />}
        />
        <Redirect
          from={Paths.MENUS}
          to={`${Paths.MENUS}/${menus[0] ? menus[0].uuid : 'create-menu'}`}
        />
      </Switch>
    </div>
  );
};
