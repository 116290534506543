import React from 'react';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { DiscountType, Promotion } from 'types/promotions';
import { formatDate } from 'utils/formatDate';
import styles from './PromotionRow.module.scss';

const PromotionRow = ({
  promotion,
  onRemove,
}: {
  promotion: Promotion;
  onRemove: (uuid: string) => void;
}) => {
  const { name, startDate, endDate, amount, uuid, discountType } = promotion;

  const amountToDisplay = () => {
    if (!amount) return '-';

    if (discountType === DiscountType.AMOUNT) return amount / 100;

    return amount;
  };

  return (
    <div className={styles.row}>
      <div className={styles.rowInfo}>
        {/* @TODO To add when I will be sure what to put here*/}
        {/*<div className={styles.rowBadge}>MOCK</div>*/}
        <div className={styles.rowDetails}>
          <span>Promotion name</span>
          <span style={{ fontWeight: 'bold' }}>{name}</span>
        </div>
        <div className={styles.rowDetails}>
          <span>Value</span>
          <span>{amountToDisplay()}</span>
        </div>
        <div className={styles.rowDetails}>
          <span>Valid from</span>
          <span>{formatDate(startDate)}</span>
        </div>
        <div className={styles.rowDetails}>
          <span>Valid to</span>
          <span>{formatDate(endDate)}</span>
        </div>
      </div>
      <div className={styles.rowDeleteIcon} onClick={() => onRemove(uuid)}>
        <TrashIcon />
      </div>
    </div>
  );
};

export default PromotionRow;
