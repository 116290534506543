import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';

export const getTimezones = (): {
  label: string;
  value: string;
  offset: number;
}[] => {
  const timezones = momentTimezone.tz.names();

  const utcValue = (timezone: string) =>
    momentTimezone.tz(timezone).format('Z');

  return timezones.map((timezone) => ({
    label: `(UTC${utcValue(timezone)}) ${timezone}`,
    value: utcValue(timezone),
    offset: momentTimezone.tz(timezone).utcOffset(),
  }));
};

interface DisableDatesProps {
  startDate?: number;
  endDate?: number;
  current?: Moment;
}

export const disableDates = ({
  current,
  endDate,
  startDate,
}: DisableDatesProps) =>
  !current ||
  (!!startDate && current.valueOf() < startDate) ||
  (!!endDate && current.valueOf() > endDate);

export const dateFormatWith12Time = 'YYYY-MM-DD hh:mm A';
export const defaultTimeZoneValue = 0;

//Mapping offset number to format +(-)00:00;
export const mapTimezoneOffsetToHourLabel = (offset?: number) => {
  //checks both undefined and null
  if (offset == null) {
    return '-';
  }

  const positiveOffset = Math.abs(offset);

  const offsetHours = Math.floor(positiveOffset / 60);
  const offsetMinutes = positiveOffset % 60;

  const offsetHoursLabel =
    offsetHours < 10 ? String(offsetHours).padStart(2, '0') : offsetHours;

  const offsetMinutesLabel =
    offsetMinutes === 0 ? String(offsetMinutes).padEnd(2, '0') : offsetMinutes;

  return `${offset >= 0 ? '+' : '-'}${offsetHoursLabel}:${offsetMinutesLabel}`;
};

export const getCurrentDateInUTC = () => moment.utc(moment.now()).valueOf();

interface ConvertDateToUTCWithOffsetProps {
  offset?: number | null;
  date?: number | Moment | null; //Handles all cases from date picker and BE
  shouldRecalculateOffset?: boolean;
}

export const convertNumberToUTCWithOffset = (
  date?: number | Moment | null | string, //Handles all cases from date picker and BE
  timezone?: number | null
) => moment.utc(Number(date)).utcOffset(timezone || 0);

export const convertLocalDateToUTCWithOffset = ({
  date,
  offset,
  shouldRecalculateOffset,
}: ConvertDateToUTCWithOffsetProps) => {
  //Don't convert with offset if already in UTC, only if offset has changed
  if (moment(date).isUtcOffset() && !shouldRecalculateOffset) {
    return date?.valueOf();
  }

  const dateToUTC = moment(date).utc(true);

  return moment
    .utc(dateToUTC)
    .utcOffset(offset || 0, true)
    .valueOf();
};

export const convertDateToFormattedUTCLabel = (date: string | number) =>
  moment.utc(Number(date)).utcOffset(0).format(dateFormatWith12Time);
