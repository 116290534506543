import { Button } from 'antd';
import PageCard from 'components/PageCard';
import React, { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { PrepaidCard, PrepaidCardBatch } from 'types/prepaidCard';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { Pagination } from 'types';
import { getPrepaidCardBatchesPagination } from 'actions/prepaid';
import PrepaidCardsPinTable from '../components/PrepaidCardsPinTable';
import PrepaidCardsBatchGenerate from '../components/PrepaidCardsBatchGenerate';
import PrepaidCardsBatchExport from '../components/PrepaidCardsBatchExport';
import styles from './index.module.scss';

interface PrepaidCardsPinsProps {
  prepaidCard: PrepaidCard;
}

const PrepaidCardsPins = ({ prepaidCard }: PrepaidCardsPinsProps) => {
  const [isGenerateBatchModalVisible, setIsGenerateBatchModalVisible] =
    useState(false);
  const [isExportingModalVisible, setIsExportingModalVisible] = useState(false);
  const [page, setPage] = useState<number>(1);

  const {
    data: batches,
    isLoading: isLoadingBatches,
    refresh: refreshBatches,
  } = useFetchActionObj<Pagination<PrepaidCardBatch>>(
    useCallback(
      () =>
        getPrepaidCardBatchesPagination({
          prepaidCardUuid: prepaidCard.uuid,
          page,
        }),
      [prepaidCard, page]
    )
  );

  return (
    <>
      <PageCard
        title="PINs"
        extra={
          <div>
            <Button
              className={styles.actionButton}
              icon={<PlusOutlined />}
              onClick={() => setIsGenerateBatchModalVisible(true)}
            >
              Generate batch of PINs
            </Button>
            {!!batches?.data?.length && (
              <Button
                className={styles.actionButton}
                onClick={() => setIsExportingModalVisible(true)}
              >
                Export to CSV
              </Button>
            )}
          </div>
        }
        children={
          <PrepaidCardsPinTable
            data={batches?.data}
            total={batches?.count}
            loading={isLoadingBatches}
            page={page}
            setPage={setPage}
          />
        }
      />

      {isGenerateBatchModalVisible && (
        <PrepaidCardsBatchGenerate
          isVisible={isGenerateBatchModalVisible}
          onClose={() => setIsGenerateBatchModalVisible(false)}
          refreshBatches={refreshBatches}
          prepaidCard={prepaidCard}
        />
      )}

      {isExportingModalVisible && (
        <PrepaidCardsBatchExport
          isVisible={isExportingModalVisible}
          onClose={() => setIsExportingModalVisible(false)}
          batches={batches?.data}
          prepaidCard={prepaidCard}
        />
      )}
    </>
  );
};

export default PrepaidCardsPins;
