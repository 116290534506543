import { Alert, DatePicker, Modal, Select } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  AssetAvailabilityValue,
  AvailabilityTableData,
  EditRegionLicense,
} from 'types/licenses';
import { validateAvailableFromField } from 'utils/licenses';
import {
  convertLocalDateToUTCWithOffset,
  convertNumberToUTCWithOffset,
  dateFormatWith12Time,
  defaultTimeZoneValue,
  disableDates,
  getCurrentDateInUTC,
  getTimezones,
} from 'utils/timezones';
import cn from 'classnames';
import AssetAvailabilitySelect from '../AssetAvailabilitySelect';
import styles from '../index.module.scss';
const { Option } = Select;

interface EditLicenseModalProps {
  license: AvailabilityTableData;
  isOpen?: boolean;
  onClose: () => void;
  onEdit: (license: AvailabilityTableData) => void;
  confirmLoading?: boolean;
}

const EditLicenseModal = ({
  license,
  onClose,
  isOpen,
  onEdit,
  confirmLoading,
}: EditLicenseModalProps) => {
  const formMethods = useForm<EditRegionLicense>({
    mode: 'onChange',
  });

  const { control, watch, reset, getValues, errors, setValue } = formMethods;

  const onEditConfirm = useCallback(() => {
    const form = getValues();

    const isScheduledLicense =
      form.availability === AssetAvailabilityValue.SCHEDULED;

    const currentDate = getCurrentDateInUTC();

    //From DatePicker we got moment object in UTC as it was initialized with default UTC date
    //If admin changed the timezone we have te recalculate date based on new offset
    const availabilityFromAfterOffset = convertLocalDateToUTCWithOffset({
      date: form.availableFrom,
      offset: form.availabilityTimezone,
      shouldRecalculateOffset:
        license.availabilityTimezone !== form.availabilityTimezone,
    });

    const licenseToSave = {
      ...license,
      availability: isScheduledLicense
        ? AssetAvailabilityValue.SCHEDULED
        : AssetAvailabilityValue.IMMEDIATE,
      availableFrom: isScheduledLicense
        ? availabilityFromAfterOffset
        : currentDate,
      availabilityTimezone: isScheduledLicense
        ? form.availabilityTimezone
        : defaultTimeZoneValue, //If not scheduled BE is expecting current date with default timezone }
    };

    onEdit(licenseToSave);
  }, [getValues, license, onEdit]);

  useEffect(() => {
    if (license) {
      reset({
        ...license,
        availability: license?.availability || AssetAvailabilityValue.SCHEDULED,
        availableFrom: convertNumberToUTCWithOffset(
          license.availableFrom,
          license.availabilityTimezone
        ),
      });
    }
  }, [license, reset, setValue]);

  return (
    <Modal
      title={license.region.name}
      visible={isOpen}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      onOk={onEditConfirm}
      destroyOnClose
      confirmLoading={confirmLoading}
      maskClosable={false}
    >
      <div className={styles.availabilityWrapper}>
        <p className={styles.editHeaderSection}>Availability</p>
        <Controller
          name="availability"
          control={control}
          render={(field) => (
            <AssetAvailabilitySelect
              value={field.value}
              setValue={field.onChange}
            />
          )}
          defaultValue={AssetAvailabilityValue.SCHEDULED}
        />
      </div>

      {watch('availability') === AssetAvailabilityValue.SCHEDULED && (
        <div>
          <p className={styles.editHeaderSection}>Available from</p>

          <Controller
            name="availableFrom"
            control={control}
            render={(field) => (
              <DatePicker
                format={dateFormatWith12Time}
                placeholder="Select date and time"
                className={cn(
                  !!errors?.availableFrom && styles.warningDatePicker
                )}
                allowClear={false}
                value={field.value}
                onChange={field.onChange}
                showTime
                disabledDate={(current) =>
                  disableDates({
                    current,
                    endDate:
                      'dateTo' in license ? Number(license?.dateTo) : undefined,
                  })
                }
              />
            )}
            defaultValue={null}
            rules={{
              validate: (currentValue: moment.Moment) =>
                validateAvailableFromField({
                  currentValue,
                  isSelected: true,
                  license,
                }),
            }}
          />

          <Controller
            control={control}
            name="availabilityTimezone"
            as={
              <Select
                placeholder="Choose time zone"
                showSearch
                filterOption={(input, option) =>
                  option?.key.toLowerCase().includes(input.toLowerCase())
                }
                className={styles.timezoneItem}
              >
                {getTimezones().map(({ label, offset }) => (
                  <Option key={label} value={offset}>
                    {label}
                  </Option>
                ))}
              </Select>
            }
            defaultValue={null}
          />
        </div>
      )}

      {!!errors?.availableFrom && (
        <div className={styles.dateAlert}>
          <Alert
            showIcon
            type="warning"
            message="Some dates do not match your region license start date"
          />
        </div>
      )}
    </Modal>
  );
};

export default EditLicenseModal;
