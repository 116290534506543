import { Table } from 'antd';
import React from 'react';
import Column from 'antd/lib/table/Column';
import { PrepaidCardBatch } from 'types/prepaidCard';
import { DATE_FORMAT, NESTED_PAGINATION_SIZE } from 'utils/constants';
import moment from 'moment';

interface PrepaidCardsPinTableProps {
  data?: PrepaidCardBatch[];
  loading?: boolean;
  page?: number;
  setPage: (page: number) => void;
  total?: number;
}

const PrepaidCardsPinTable = ({
  data,
  loading,
  page,
  setPage,
  total,
}: PrepaidCardsPinTableProps) => (
  <Table
    loading={loading}
    indentSize={40}
    dataSource={data}
    rowKey="uuid"
    pagination={{
      pageSize: NESTED_PAGINATION_SIZE,
      current: page,
      hideOnSinglePage: true,
      total: total,
      onChange: setPage,
    }}
  >
    <Column title="Batch" key="name" dataIndex="name" />
    <Column
      title="Valid from"
      dataIndex={['configuration', 'validFrom']}
      key="validFrom"
      render={(validFrom: Date) => moment(validFrom).format(DATE_FORMAT)}
    />
    <Column
      title="Valid to"
      dataIndex={['configuration', 'validTo']}
      key="validTo"
      render={(validTo: Date) => moment(validTo).format(DATE_FORMAT)}
    />
    <Column
      title="Redemeed/Count"
      dataIndex="redeemed/count"
      key="count"
      render={(_, batch: PrepaidCardBatch) =>
        `${batch.redeemed || 0} / ${batch.configuration.count}`
      }
    />
    <Column
      title="Generated"
      dataIndex="createdAt"
      key="createdAt"
      render={(createdAt: Date) => moment(createdAt).format(DATE_FORMAT)}
    />
  </Table>
);

export default PrepaidCardsPinTable;
