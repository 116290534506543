import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Pagination, Spin } from 'antd';
import AddNewReference from 'components/AddVideoReference';
import Button from 'components/Button';
import { AITimestampMap } from 'types/ai';
import { PAGINATION_SIZE } from 'utils/constants';
import { useAssetContentContext } from 'contexts/assetContentContext';
import styles from '../../index.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface AICelebritiesProps {
  sourceFileId?: string;
  assetCelebrities?: AITimestampMap;
  aiFaceRecognitionStatus: string;
}

const AiCelebrities = ({
  sourceFileId,
  assetCelebrities,
  aiFaceRecognitionStatus,
}: AICelebritiesProps) => {
  // Temporary - use MP4 file
  const { mainVideoUrlMP4 } = useAssetContentContext();
  const [page, setPage] = useState(1);
  const [isAddVideoReferenceVisible, toggleIsVideoReferenceVisible] =
    useState(false);
  const celebritiesList = assetCelebrities ? Object.keys(assetCelebrities) : [];
  const startPagination = PAGINATION_SIZE * (page - 1);
  const pagedList = celebritiesList.slice(
    startPagination,
    startPagination + PAGINATION_SIZE
  );

  return (
    <section>
      <div className={styles.heading}>
        <h2>Cast</h2>
        <Button
          icon={<PlusOutlined />}
          onClick={() => toggleIsVideoReferenceVisible(true)}
        >
          Detect new cast
        </Button>
      </div>
      {aiFaceRecognitionStatus === 'processing' && (
        <Alert
          type="info"
          icon={<Spin indicator={antIcon} />}
          className={styles.infoMessage}
          showIcon
          message="Cast Analysis is in progress. It might take a while."
        />
      )}
      {!!celebritiesList.length && (
        <>
          <ul className={styles.list}>
            <li className={styles.listHeading}>Name</li>
            {pagedList.map((c) => (
              <li className={styles.listItem} key={c}>
                {c}
              </li>
            ))}
          </ul>
          <Pagination
            pageSize={PAGINATION_SIZE}
            current={page}
            onChange={(page) => setPage(page)}
            total={celebritiesList.length}
            hideOnSinglePage
          />
        </>
      )}
      <AddNewReference
        isVisible={isAddVideoReferenceVisible}
        onCancel={() => toggleIsVideoReferenceVisible(false)}
        videoUrl={mainVideoUrlMP4}
        sourceFileId={sourceFileId!}
      />
    </section>
  );
};

export default AiCelebrities;
