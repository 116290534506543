import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 *
 * @param {number} urlKeyPosition position of menu key in the url,
 * e.g. in `/assets/9/contents`, the menu key is `contents` and the position is 3
 * @param {string} [defaultKey='']
 * @returns
 */
export const useActiveMenuKey = (
  urlKeyPosition: number,
  defaultKey: string = ''
) => {
  const [menuKey, setMenuKey] = useState<string>('');
  const location = useLocation();

  const pathnameElement = location.pathname.split('/')[urlKeyPosition];

  useEffect(() => {
    const urlKey =
      pathnameElement !== undefined ? `/${pathnameElement}` : defaultKey;
    setMenuKey(urlKey);
  }, [location.pathname, urlKeyPosition, defaultKey, pathnameElement]);

  return menuKey;
};
