import { PageHeader as AntPageHeader } from 'antd';
import React, { ReactNode } from 'react';
import styles from './index.module.scss';

const PageHeader = ({
  title,
  extra,
  ...props
}: {
  title?: string;
  extra?: ReactNode;
}) => {
  return (
    <div className={styles.root}>
      <AntPageHeader
        className={styles.pageHeader}
        title={title}
        extra={extra}
        {...props}
      />
    </div>
  );
};

export default PageHeader;
