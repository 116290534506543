import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ContentCreateForm } from 'types';
import { createContent } from 'actions/content';
import ErrorIndicator from 'components/ErrorIndicator';
import PageHeader from 'components/PageHeader';
import { Input, Select } from 'antd';
import Button from 'components/Button';
import Space from 'components/Space';
import PageContainer from 'components/PageContainer';
import FormContainer from 'components/FormContainer';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { contentTypes } from 'utils/constants';

const ContentCreate = () => {
  const { push } = useHistory();
  const { control, handleSubmit, errors, reset } = useForm<ContentCreateForm>();
  const [sendForm, isLoading, , , error] = useSendAction<
    any,
    ContentCreateForm
  >((form) => createContent({ form }), {
    onDone: (content) => {
      reset();
      push(`/content/${content.id}`);
    },
  });

  return (
    <PageContainer>
      <PageHeader title="Create new content" />
      <FormContainer>
        <form onSubmit={handleSubmit(sendForm)}>
          <fieldset>
            <Space direction="vertical" expand>
              <div>
                <label>Title</label>
                <Controller
                  as={Input}
                  control={control}
                  type="text"
                  name="title"
                  rules={{ required: true }}
                />
                {errors.title && <div>This field is required</div>}
              </div>
              <div>
                <div>
                  <label>Type</label>
                </div>
                <Controller
                  as={Select}
                  style={{ width: 120 }}
                  control={control}
                  name="type"
                  rules={{ required: true }}
                  options={contentTypes}
                />
                {errors.type && <div>This field is required</div>}
              </div>
              <div>
                <Button htmlType="submit" disabled={isLoading}>
                  Create
                </Button>
              </div>
              <ErrorIndicator error={error} />
            </Space>
          </fieldset>
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default ContentCreate;
