import { CensorshipRule, CensorshipRuleTranslations } from 'types/censorships';
import { DEFAULT_LANGUAGE_CODE } from 'utils/constants';

export const generateCensorshipRuleUrl = (
  rule: CensorshipRule,
  regionName?: string
) => `/censorships/${regionName?.toLowerCase()}/${rule.id}`;

export const hasDefualtTranslation = (
  translations?: CensorshipRuleTranslations
) => {
  if (!translations) return false;
  return Object.keys(translations).includes(DEFAULT_LANGUAGE_CODE);
};
