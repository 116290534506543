import React, { useCallback } from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { InfoCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import PageContainer from 'components/PageContainer';
import { getContentDetails } from 'actions/content';
import ErrorIndicator from 'components/ErrorIndicator';
import PageDetailsHeader from 'components/PageDetailsHeader';
import { Content, ContentType } from 'types';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { useActiveMenuKey } from 'hooks/activeMenuKeyHook';
import InnerSider from 'components/InnerSider';
import { Paths } from 'types/paths';
import { RouteIdParams } from 'utils/routeParams';
import Basic from './Basic';
import ContentFile from './ContentFile';

const ContentDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const [data, isLoading, error, refresh] = useFetchAction<Content>(
    useCallback(() => getContentDetails({ id: id! }), [id])
  );
  const menuKey = useActiveMenuKey(3, '/details');

  return (
    <>
      <PageDetailsHeader>{data?.title}</PageDetailsHeader>
      <Layout>
        <InnerSider menuKey={menuKey}>
          <Menu.Item key="/details" icon={<InfoCircleOutlined />}>
            <Link to={`${Paths.CONTENT}/${id}`}>General details</Link>
          </Menu.Item>
          {data?.type !== ContentType.LIVE && (
            <Menu.Item key="/file" icon={<PlayCircleOutlined />}>
              <Link to={`${Paths.CONTENT}/${id}/file`}>File</Link>
            </Menu.Item>
          )}
        </InnerSider>
        <PageContainer>
          {error && <ErrorIndicator error={error} />}
          <Switch>
            <Route
              exact
              path={`${Paths.CONTENT}/:id`}
              render={() => (
                <Basic
                  isLoading={isLoading}
                  data={data}
                  error={error}
                  onSave={refresh}
                />
              )}
            />
            <Route
              exact
              path={`${Paths.CONTENT}/:id/file`}
              component={ContentFile}
            />
          </Switch>
        </PageContainer>
      </Layout>
    </>
  );
};

export default ContentDetails;
