import { Input, InputNumber } from 'antd';
import FormGroup from 'components/FormGroup';
import PeriodSelector from 'components/PeriodSelector';
import PlanChooser from 'components/PlanChooser';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BasicPrepaidCard } from 'types/prepaidCard';
import styles from './index.module.scss';

interface PrepaidCardsBasicCreateProps {
  canEdit?: boolean;
}

const PrepaidCardsBasicCreate = ({
  canEdit = true,
}: PrepaidCardsBasicCreateProps) => {
  const { control, errors } = useFormContext<BasicPrepaidCard>();

  return (
    <>
      <FormGroup
        input={
          <Controller
            placeholder="Enter the prepaid card title"
            as={Input}
            control={control}
            name="title"
            rules={{ required: 'This field is required' }}
            disabled={!canEdit}
          />
        }
        label="Title"
        errorMessage={errors.title?.message}
        defaultValue=""
      />

      <FormGroup
        input={
          <Controller
            render={(field) => (
              <PlanChooser
                disabled={!canEdit}
                onChoose={(plan) => field.onChange(plan.uuid)}
                chosenPlanId={field.value}
              />
            )}
            control={control}
            name="planId"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Plan"
        errorMessage={errors.planId?.message}
        defaultValue=""
      />

      <FormGroup
        input={
          <div className={styles.interval}>
            <Controller
              render={(field) => (
                <InputNumber precision={0} {...field} disabled={!canEdit} />
              )}
              control={control}
              rules={{ min: 1, required: 'This field is required' }}
              name="intervalMultiplier"
              type="number"
              defaultValue={1}
            />
            <Controller
              render={(field) => (
                <PeriodSelector
                  choosenPeriod={field.value}
                  onChoose={field.onChange}
                  disabled={!canEdit}
                />
              )}
              control={control}
              name="billingFrequency"
              rules={{ required: true }}
            />
          </div>
        }
        label="Plan Duration"
        errorMessage={!!errors.billingFrequency ? 'This field is required' : ''}
        defaultValue=""
      />
    </>
  );
};

export default PrepaidCardsBasicCreate;
