import PageCard from 'components/PageCard';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  ContentDistributionConfig,
  ContentDistributorType,
  isCatalogueFeedType,
} from 'types/contentDistribution';
import { RouteIdParams } from 'utils/routeParams';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import notification from 'utils/notification';
import { getContentDistributionConfig } from 'actions/contentDistribution';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { Permission } from 'utils/constants';
import { usePermission } from 'hooks/permissionsHook';
import { Asset } from 'types';
import PageContainer from 'components/PageContainer';
import PageDetailsHeader from 'components/PageDetailsHeader';
import ContentDistributionConfigDetailsForm from '../components/ContentDistributionConfigDetailsForm';
import ContentDistributionConfigAssets from '../components/ContentDistributionConfigAssets';
import ContentDistributionConfigRemove from '../components/ContentDistributionConfigRemove';
import useContentDistributionConfigActions from '../hooks/useContentDistributionConfigActions';
import ContentDistributionCatalogInputs from '../components/ContentDistibutionCatalogInputs';
import styles from './index.module.scss';

const ContentDistributionConfigDetails = () => {
  const [removeConfigModalVisible, setRemoveConfigModalVisible] =
    useState(false);
  const { id } = useParams<RouteIdParams>();
  const formMethods = useForm<ContentDistributionConfig>({
    mode: 'onSubmit',
  });
  const { reset, handleSubmit, watch } = formMethods;
  const { push } = useHistory();
  const [assignedAssets, setAssignedAssets] = useState<Asset[]>([]);
  const { canUse } = usePermission();

  const { data: config, refresh: refreshConfig } = useFetchActionObj(
    useCallback(() => getContentDistributionConfig(id), [id])
  );

  const onRemoveSuccess = () => {
    notification.success({
      description: `${config?.name} configuration has been successfully removed.`,
    });
    setRemoveConfigModalVisible(false);
    push('/contentDistribution');
  };

  const getAssignedAssetsUuids = () =>
    assignedAssets?.map((asset) => asset.uuid);

  const { removeConfig, updateConfig, isUpdating, isRemoving } =
    useContentDistributionConfigActions({
      onRemoveSuccess,
      refreshConfig,
      config,
    });

  const onSaveClick = ({
    type,
    sourceId,
    appId,
    ...config
  }: ContentDistributionConfig) => {
    // BE expects only type value if REGULAR is selected
    const configToSave: ContentDistributionConfig = {
      ...config,
      assetsUuids: getAssignedAssetsUuids(),
      ...(isCatalogueFeedType(type) ? { type, sourceId, appId } : { type }),
    };

    updateConfig({ config: configToSave, id });
  };

  useEffect(() => {
    if (!id || !config) return;

    reset({
      ...config,
      ...(isCatalogueFeedType(config.type)
        ? {
            type: config.type,
            appId: config.appId || '',
            sourceId: config.sourceId || '',
          }
        : { type: config.type || ContentDistributorType.REGULAR }),
    });

    setAssignedAssets(config.assets);
  }, [config, id, reset]);

  if (!config) return null;

  return (
    <>
      <PageDetailsHeader>{config?.name}</PageDetailsHeader>
      <PageContainer transparent>
        <FormProvider {...formMethods}>
          <PageCard
            title="General details"
            children={
              <>
                <ContentDistributionConfigDetailsForm />
                <ContentDistributionCatalogInputs />
              </>
            }
          />

          <ContentDistributionConfigAssets
            assignedAssets={assignedAssets}
            setAssignedAssets={setAssignedAssets}
            regionUuid={watch('regionUuid')}
          />

          <div className={styles.buttonGroup}>
            {canUse([Permission.CONTENT_DISTRIBUTION_EDIT]) && (
              <Button
                htmlType="button"
                type="primary"
                onClick={handleSubmit(onSaveClick)}
                loading={isUpdating}
              >
                Save
              </Button>
            )}

            {canUse([Permission.CONTENT_DISTRIBUTION_DELETE]) && (
              <Button
                htmlType="button"
                type="default"
                icon={<DeleteOutlined />}
                onClick={() => setRemoveConfigModalVisible(true)}
                loading={isRemoving}
              >
                Remove configuration
              </Button>
            )}
          </div>
        </FormProvider>
      </PageContainer>

      {removeConfigModalVisible && (
        <ContentDistributionConfigRemove
          visible={removeConfigModalVisible}
          config={config}
          onClose={() => setRemoveConfigModalVisible(false)}
          onOk={removeConfig}
          isLoading={isRemoving}
        />
      )}
    </>
  );
};

export default ContentDistributionConfigDetails;
