import React, { useMemo, useState } from 'react';
import { Menu as MenuAntd } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Region } from 'types';
import cn from 'classnames';
import { useGetRegions } from 'hooks/useRegions';
import { Paths } from 'types/paths';
import Search from 'components/Search';
import { Menu } from '../../../types/menu';
import styles from './index.module.scss';

const MenuRegionSidebar = ({
  menus,
  onRegionSelect,
}: {
  menus: Menu[];
  onRegionSelect: (region: Region) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const { regions } = useGetRegions();
  const [regionSearchQuery, setRegionSearchQuery] = useState('');

  const getMenu = (regionUuid: string): Menu | undefined =>
    menus.find((m) => m.regions.includes(regionUuid));

  const getKeyUuid = (regionUuid: string): string => {
    const menu = menus.find((m) => m.regions.includes(regionUuid));
    return menu && menu.uuid ? menu.uuid : regionUuid;
  };

  const findActiveKeyId = (): string => {
    if (id !== 'create-menu') {
      return id;
    }
    return '';
  };

  const filteredRegions = useMemo(
    () =>
      regions?.filter((r) =>
        r.name.toLowerCase().includes(regionSearchQuery.toLowerCase())
      ),
    [regionSearchQuery, regions]
  );

  return (
    <MenuAntd
      mode="inline"
      className={styles.sidebar}
      defaultSelectedKeys={[findActiveKeyId()]}
    >
      <Search
        placeholder="Search region"
        onChange={(event) => setRegionSearchQuery(event.target.value)}
        className={styles.search}
      />

      {filteredRegions?.map((r: Region) => {
        const _getMenu = getMenu(r.uuid);
        return (
          <MenuAntd.Item
            key={getKeyUuid(r.uuid)}
            className={cn({
              [styles.unpublished]: !Object.values(
                _getMenu?.platforms ? _getMenu.platforms : {}
              ).length,
            })}
          >
            <Link
              to={`${Paths.MENUS}/${_getMenu?.uuid || 'create-menu'}`}
              onClick={() => onRegionSelect(r)}
            >
              {r.name}
            </Link>
          </MenuAntd.Item>
        );
      })}
    </MenuAntd>
  );
};

export default MenuRegionSidebar;
