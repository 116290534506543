import {
  CustomPageItem,
  ExternalURLItem,
  MenuItemData,
  MenuLink,
} from 'types/menu';

export const customPageItemGuard = (
  data?: MenuItemData | MenuLink
): data is CustomPageItem => !!(data as CustomPageItem)?.page;
export const externalLinkItemGuard = (
  data?: MenuItemData | MenuLink
): data is ExternalURLItem => !!(data as ExternalURLItem)?.url;
