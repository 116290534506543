import React from 'react';
import { AssetChildren } from 'types';
import { Table } from 'antd';
import { TouchedAsset } from 'pages/Assets/AssetDetails/FreemiumEpisodes/index';
import RowNestedTable from './RowNestedTable';
const { Column } = Table;

const FreemiumEpisodesTable = ({
  assetChildren = [],
  assetDescendants,
  fetchAssetChildren,
  freemiumEpisodes,
  setFreemiumEpisodes,
  addTouchedAsset,
  orderByAssetOrderAsc,
}: {
  assetChildren?: AssetChildren[];
  assetDescendants: AssetChildren[];
  fetchAssetChildren: (id: number) => void;
  freemiumEpisodes: AssetChildren[];
  setFreemiumEpisodes: React.Dispatch<React.SetStateAction<AssetChildren[]>>;
  addTouchedAsset: (a: TouchedAsset) => void;
  orderByAssetOrderAsc: (a: AssetChildren[]) => AssetChildren[];
}) => {
  return (
    <Table
      dataSource={orderByAssetOrderAsc(assetChildren)}
      showHeader={false}
      pagination={false}
      rowKey={(node: AssetChildren) => node.name}
      expandable={{
        expandedRowRender: (record) => (
          <RowNestedTable
            record={record}
            assetDescendants={assetDescendants}
            freemiumEpisodes={freemiumEpisodes}
            setFreemiumEpisodes={setFreemiumEpisodes}
            addTouchedAsset={addTouchedAsset}
            orderByAssetOrderAsc={orderByAssetOrderAsc}
          />
        ),
        onExpand: (_, node) => fetchAssetChildren(node.id),
        rowExpandable: (record: AssetChildren) => record.childrenIds.length > 0,
      }}
    >
      <Column
        dataIndex="order"
        render={(order: number) => <div> Season {order} </div>}
      />
    </Table>
  );
};

export default FreemiumEpisodesTable;
