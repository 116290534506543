import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { Skeleton } from 'antd';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Asset } from 'types';
import { getAssetByUuid } from 'actions/assets';
import { RouteIdParams } from 'utils/routeParams';
import { LiveStreamInfrastructure, LiveStreamProfile } from 'types/live';
import { isLiveAssetType } from 'pages/Assets/AssetDetails/utils';
import { getLiveStreamDetails } from 'actions/live';
import notification from 'utils/notification';

interface AssetActions {
  asset: Asset;
  assetDraft: Asset;
  setAssetDraft: (asset?: Asset) => void;
  refresh: () => void;
  isLoading: boolean;
  error?: Error;
  liveDetails?: LiveStreamInfrastructure;
  setLiveDetails?: (details?: LiveStreamInfrastructure) => void;
  refreshLiveDetails?: () => void;
  liveDetailsLoading: boolean;
  isLiveType: boolean;
  streamProfiles: LiveStreamProfile[];
  setStreamProfiles: (profiles: LiveStreamProfile[]) => void;
  uuid: string;
}

const INTERVAL_TIMEOUT = 5000;

export const AssetContext = createContext<AssetActions>(null!);

export const useAssetDetailsContext = () =>
  useContext<AssetActions>(AssetContext);

export const AssetProvider = ({ ...props }) => {
  const { children } = props;
  const { id } = useParams<RouteIdParams>(); //NOTE: Redirects are based on uuid, actions to API (except asset details) still on ID
  const [liveDetails, setLiveDetails] = useState<LiveStreamInfrastructure>();
  const [liveDetailsLoading, setLiveDetailsLoading] = useState(false);
  const [liveError, setLiveError] = useState('');
  const [streamProfiles, setStreamProfiles] = useState<LiveStreamProfile[]>([]);
  const [asset, setAsset] = useState<Asset>();
  const [assetDraft, setAssetDraft] = useState<Asset>();
  const isLiveType = isLiveAssetType(assetDraft);

  const action = useCallback(() => getAssetByUuid(id), [id]);
  const [assetDetails, isLoading, error, refresh] =
    useFetchAction<Asset>(action);

  const streamDetailsIntervalRef = useRef<ReturnType<typeof setInterval>>();
  const resetLiveDetails = useCallback(() => {
    if (!streamDetailsIntervalRef.current) return;
    clearInterval(streamDetailsIntervalRef.current);
    setLiveDetails(undefined);
  }, []);

  const refreshLiveDetails = useCallback(async () => {
    if (!assetDraft) return;

    // @note if not exists this will return 404. Thats how it work. Then we want show stream profiles
    await getLiveStreamDetails(assetDraft.id)
      .then(setLiveDetails)
      .catch(resetLiveDetails);
  }, [assetDraft, resetLiveDetails]);

  useEffect(() => {
    if (assetDetails) {
      setAsset(assetDetails);
      setAssetDraft(assetDetails);
    }
  }, [assetDetails, setAsset, setAssetDraft]);

  useEffect(() => {
    if (!isLiveType || !assetDraft) return;
    setLiveDetailsLoading(true);
    // @note if not exists this will return 404. Thats how it work. Then we want show stream profiles
    getLiveStreamDetails(assetDraft.id)
      .then(setLiveDetails)
      .catch(resetLiveDetails)
      .finally(() => setLiveDetailsLoading(false));
  }, [assetDraft, isLiveType, resetLiveDetails]);

  useEffect(() => {
    if (!liveDetails || !isLiveType) return;

    streamDetailsIntervalRef.current = setInterval(
      () => refreshLiveDetails(),
      INTERVAL_TIMEOUT
    );

    return () => {
      if (!streamDetailsIntervalRef.current) return;
      clearInterval(streamDetailsIntervalRef.current);
    };
  }, [assetDraft, isLiveType, liveDetails, refresh, refreshLiveDetails]);

  useEffect(() => {
    if (!liveError || !liveDetails?.error) return;

    setLiveError(liveDetails.error);
    notification.error({
      title: 'Live stream error',
      description: liveDetails.error,
    });
  }, [liveDetails, liveError]);

  if (!assetDraft || !asset)
    return (
      <div data-testid="skeleton">
        <Skeleton />
      </div>
    );
  return (
    <AssetContext.Provider
      value={{
        asset,
        assetDraft,
        setAssetDraft,
        refresh,
        isLoading,
        error,
        liveDetails,
        setLiveDetails,
        liveDetailsLoading,
        isLiveType,
        streamProfiles,
        setStreamProfiles,
        uuid: id,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
