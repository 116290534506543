import { useEffect, useState } from 'react';
import axios, {
  CancelToken as AxiosCancelToken,
  CancelTokenSource,
} from 'axios';

export type CancelToken = AxiosCancelToken;

export const useCancelToken = (f: (token: CancelToken) => void) => {
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    f(cancelTokenSource.token);

    return () => cancelTokenSource.cancel();
  }, [f]);
};

export const useCancelTokenState = () => {
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const refresh = () => setCancelTokenSource(axios.CancelToken.source());

  useEffect(() => {
    return () => cancelTokenSource.cancel();
  }, [cancelTokenSource]);

  return [cancelTokenSource.token, cancelTokenSource.cancel, refresh] as [
    CancelToken,
    () => void,
    () => void
  ];
};

export const isCancelled = (error: any) => axios.isCancel(error);
