import React from 'react';
import { Card } from 'antd';
import cn from 'classnames';
import styles from './index.module.scss';
const PageCard = ({
  children,
  title,
  extra,
  icon,
  headerActionButton,
}: {
  children: React.ReactNode;
  title: string;
  headerActionButton?: React.ReactNode;
  extra?: React.ReactNode;
  icon?: React.ReactNode;
}) => {
  return (
    <Card
      title={
        <div>
          {icon}
          <span className={cn(styles.title, [{ [styles.noIcon]: !icon }])}>
            {title}
          </span>
          <span className={styles.actionButtonWrapper}>
            {headerActionButton}
          </span>
        </div>
      }
      className={styles.card}
      extra={extra}
    >
      {children}
    </Card>
  );
};

export default PageCard;
