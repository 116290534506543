import { usePrevious } from '@laminar-product/client-commons-core/hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsLoading, getRequestStatus } from 'store/app/selectors';
import { RequestStatuses } from 'store/app/types';
import {
  FetchDictionaryRequest,
  fetchDictionaryAction,
} from 'store/dictionaries/actions';
import { getDictionary, getError } from 'store/dictionaries/selectors';
import { DictionaryType } from 'store/dictionaries/types';

export const useDictionary = ({
  query,
  dictionaryType,
}: FetchDictionaryRequest) => {
  const prevquery = usePrevious(query);
  const dispatch = useDispatch();
  const dictionaryId = `FETCH_DICTIONARY_${dictionaryType.toUpperCase()}`;
  const dictionary = useSelector((state: RootState) =>
    getDictionary(state, dictionaryType)
  );
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, dictionaryId)
  );
  const error = useSelector((state: RootState) =>
    getError(state, dictionaryId)
  );
  const requestStatus = useSelector((state: RootState) =>
    getRequestStatus(state, dictionaryId)
  );

  useEffect(() => {
    if (query !== prevquery) {
      dispatch(fetchDictionaryAction.request({ query, dictionaryType }));
    } else if (!dictionary && requestStatus !== RequestStatuses.RESOLVED) {
      dispatch(fetchDictionaryAction.request({ dictionaryType }));
    }
  }, [dispatch, dictionary, requestStatus, query, dictionaryType, prevquery]);

  return { data: dictionary, error, isLoading };
};

export const useSendDictionary = ({
  dictionaryType,
}: {
  dictionaryType: DictionaryType;
}) => {
  const dictionaryId = `SEND_DICTIONARY_${dictionaryType.toUpperCase()}`;
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, dictionaryId)
  );
  const requestStatus = useSelector((state: RootState) =>
    getRequestStatus(state, dictionaryId)
  );
  const error = useSelector((state: RootState) =>
    getError(state, dictionaryId)
  );

  return { isLoading, error, requestStatus };
};
