import { NO_DEFAULT_TAX_CODE } from 'pages/TaxManagement/constants';
import { TaxProductTypes } from 'pages/TaxManagement/types';
import { Region } from 'types';
import { DataSourceType, DefaultTaxCode } from '../types';

export const findProductByKey = (key: string, products?: DataSourceType[]) =>
  products?.find((product) => product.key === key);

export const findRegionByUuid = (uuid: string, regions?: Region[]) =>
  regions?.find((region) => region.uuid === uuid);

export const getProductDefaultTaxCode = (
  productResourceType: TaxProductTypes,
  defaultTaxCodes?: DefaultTaxCode[]
) =>
  defaultTaxCodes?.find(
    ({ resourceType }) => resourceType === productResourceType
  )?.productTaxCode || NO_DEFAULT_TAX_CODE;
