import { useCallback, useEffect, useRef, useState } from 'react';
import ErrorIndicator from 'components/ErrorIndicator';
import { Select } from 'antd';
import {
  useDebounce,
  useFetchActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { Page, PageStatus } from 'types/pages';
import { getPage, getPages } from 'actions/pages';
import { Pagination } from 'types';
import _uniqBy from 'lodash/uniqBy';
import styles from './index.module.scss';

const CustomPageChooser = ({
  onChoose,
  chosenPage,
  placeholder = 'Search for page',
  excludedUuids = [],
  regions,
  defaultValue,
}: {
  onChoose: (page: Page) => void;
  chosenPage?: Page;
  placeholder?: string;
  excludedUuids?: string[];
  regions?: string[];
  defaultValue?: string;
}) => {
  const [pages, setPages] = useState<Page[]>([]);
  const [query, setQuery] = useState('');
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [defaultPage, setDefaultPage] = useState<Page | undefined>(undefined);
  const defaultPageFetched = useRef(false);
  const queryDebounced = useDebounce(query, 300);
  const { isLoading, error } = useFetchActionObj<Pagination<Page>>(
    useCallback(
      async () =>
        getPages(0, {
          name: queryDebounced.length ? queryDebounced : undefined,
          filters: { status: PageStatus.PUBLISHED, regions },
        }),

      [queryDebounced, regions]
    ),
    {
      onDone: useCallback(({ data }: { data: Page[] }) => setPages(data), []),
    }
  );
  const loading = isCustomLoading || isLoading;

  useEffect(() => {
    if (!defaultValue || defaultPageFetched.current) return;
    defaultPageFetched.current = true;
    setIsCustomLoading(true);
    getPage(defaultValue)
      .then((page) => {
        if (
          page.status !== PageStatus.PUBLISHED ||
          !page.regions.some((region) => regions?.includes(region))
        )
          return;
        setDefaultPage(page);
        setPages((prev) => _uniqBy([page, ...prev], 'uuid') as Page[]);
        onChoose(page);
      })
      .finally(() => setIsCustomLoading(false));
  }, [defaultValue, onChoose, regions]);

  return (
    <div className={styles.fullWidth}>
      <Select
        showSearch
        value={chosenPage?.uuid}
        loading={loading}
        placeholder={placeholder}
        onSearch={setQuery}
        onChange={(id: string) =>
          onChoose(pages.find((page) => page.uuid === id)!)
        }
        defaultValue={defaultPage?.uuid}
        filterOption={false}
        className={styles.fullWidth}
        options={pages
          .map((page) => ({
            value: page.uuid!,
            label: page.name,
          }))
          .filter((option) => !excludedUuids.includes(option.value))}
      />
      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default CustomPageChooser;
