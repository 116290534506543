import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { removeFeaturedAsset } from 'actions/featuredAsset';
import { Modal } from 'antd';
import React from 'react';
import { FeaturedAsset } from 'types/featuredAsset';
import { captureErrorAndNotify } from 'utils/captureError';
import notification from 'utils/notification';

interface DeleteFeaturedAssetModalProps {
  isVisible?: boolean;
  onClose: () => void;
  featuredAsset: FeaturedAsset;
  onRemoveSuccess: () => void;
}

const DeleteFeaturedAssetModal = ({
  onClose,
  isVisible,
  featuredAsset,
  onRemoveSuccess,
}: DeleteFeaturedAssetModalProps) => {
  const { sendAction: remove, isLoading: isRemoving } = useSendActionObj<
    void,
    void
  >(async () => await removeFeaturedAsset(featuredAsset.uuid), {
    onDone: () => {
      notification.success({
        title: 'Success',
        description: 'Featured asset has been removed!',
      });
      onRemoveSuccess();
    },
    onError: captureErrorAndNotify,
  });

  const onFeaturedAssetRemoveConfirm = () => remove();

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      title={`Are you sure you want to remove ${featuredAsset.name}?`}
      okText="Yes"
      cancelText="No"
      onOk={onFeaturedAssetRemoveConfirm}
      confirmLoading={isRemoving}
    >
      Please note that all data will be erased and you will not be able to undo
      this action.
    </Modal>
  );
};

export default DeleteFeaturedAssetModal;
