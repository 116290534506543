import React, { useCallback } from 'react';
import { Form, Input, Radio, Select } from 'antd';
import FormGroup from 'components/FormGroup';
import AssetChooser from 'components/AssetChooser';
import { Controller, UseFormMethods } from 'react-hook-form';
import { assetTypes } from 'utils/constants';
import { Asset, AssetCreateForm } from 'types';
import cn from 'classnames';
import styles from '../index.module.scss';

const AssetAssign = ({
  onSelectAsset,
  selectedAsset,
  onModeChange,
  mode,
  form,
  formProps,
}: {
  onSelectAsset: (asset?: Asset) => void;
  selectedAsset: Asset | undefined;
  onModeChange: (mode: 'select' | 'create') => void;
  mode: 'select' | 'create';
  form: UseFormMethods<AssetCreateForm>;
  formProps: { labelCol: { span: number }; style: { marginTop: number } };
}) => {
  const resetAssetState = useCallback(() => {
    onSelectAsset(undefined);
    form.reset();
  }, [form, onSelectAsset]);

  return (
    <>
      <Radio.Group
        onChange={(e) => {
          onModeChange(e.target.value);
          resetAssetState();
        }}
        value={mode}
        className={cn(styles.flex)}
      >
        <Radio.Button value="create" className={styles.radioButton}>
          Create new asset
        </Radio.Button>
        <Radio.Button value="select" className={styles.radioButton}>
          Select asset
        </Radio.Button>
      </Radio.Group>
      {mode === 'select' && (
        <Form {...formProps}>
          <FormGroup
            label="Asset"
            input={
              <AssetChooser
                onChoose={onSelectAsset}
                chosenAsset={selectedAsset}
              />
            }
          />
        </Form>
      )}
      {mode === 'create' && (
        <Form {...formProps}>
          <FormGroup
            label="Name"
            input={
              <Controller
                as={Input}
                control={form.control}
                type="text"
                name="name"
                rules={{ required: true }}
              />
            }
          />
          <FormGroup
            label="Type"
            input={
              <Controller
                as={<Select className={styles.fullWidth} />}
                control={form.control}
                name="type"
                rules={{ required: true }}
                options={assetTypes}
              />
            }
          />
        </Form>
      )}
    </>
  );
};

export default AssetAssign;
