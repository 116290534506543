import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileChooser from 'components/FileChooser';
import { assignContentToFile } from 'actions/files';
import ErrorIndicator from 'components/ErrorIndicator';
import { FileStatus, UploadedFile } from 'types';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Space from 'components/Space';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { RouteIdParams } from 'utils/routeParams';

const ContentFileChooser = ({ refresh }: { refresh: () => any }) => {
  const { id } = useParams<RouteIdParams>();
  const [selectedFile, setSelectedFile] = useState<UploadedFile>();
  const [assign, isAssigning, , , assignmentError] = useSendAction(
    useCallback(
      () =>
        assignContentToFile({
          id: selectedFile?.id!,
          contentId: id!,
        }),
      [id, selectedFile]
    ),
    { onDone: refresh }
  );

  return (
    <div>
      <PageHeader title="File assign" />
      <Space direction="vertical" expand>
        <FileChooser
          chosen={selectedFile}
          filterFileStatus={[FileStatus.UNASSIGNED]}
          onChoose={setSelectedFile}
        />
        <Button
          disabled={!selectedFile || isAssigning}
          onClick={() => assign()}
        >
          Assign
        </Button>
        <ErrorIndicator error={assignmentError} />
      </Space>
    </div>
  );
};

export default ContentFileChooser;
