import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { useCallback, useMemo } from 'react';
import {
  FeaturedAssetSource,
  FeaturedAssetSourceEditProps,
  QueuedAssetTableAvailability,
} from 'types/featuredAsset';
import { dateFormatWith12Time } from 'utils/timezones';

interface ScheduledQueueAvailabilityProps {
  type: QueuedAssetTableAvailability;
  isEditMode: boolean;
  item: FeaturedAssetSource;
  onAssetChange: (data: FeaturedAssetSourceEditProps) => void;
}

const ScheduledQueueAvailability = ({
  type,
  isEditMode,
  item,
  onAssetChange,
}: ScheduledQueueAvailabilityProps) => {
  const date = item[type];

  const datePickerValue = useMemo(() => {
    if (!date) {
      return;
    }

    return moment(date);
  }, [date]);

  const onDateValueChange = useCallback(
    (value: Moment | null) =>
      onAssetChange({
        assetToEdit: { ...item, [type]: value },
        type: 'edit',
        source: 'current',
      }),
    [item, onAssetChange, type]
  );

  return (
    <>
      {isEditMode ? (
        <DatePicker
          format={dateFormatWith12Time}
          showTime
          placeholder="Select date and time"
          value={datePickerValue}
          onChange={(value) => onDateValueChange(value)}
          allowClear={false}
        />
      ) : (
        <span>{moment(date).format(dateFormatWith12Time)}</span>
      )}
    </>
  );
};

export default ScheduledQueueAvailability;
