import React, { useState } from 'react';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/Button';
import { Checkbox, Input, Row, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { captureError } from 'utils/captureError';
import styles from './index.module.scss';
import imgUrl from './Logo/laminar-logo.png';

type Inputs = {
  email: string;
  password: string;
};

export default () => {
  const [rememberMe, setRememberMe] = useState(true);

  const handleRememberMe = (e: CheckboxChangeEvent) => {
    setRememberMe(e.target.checked);
  };

  const { errors, handleSubmit, control } = useForm<Inputs>({
    defaultValues: { email: '', password: '' },
  });

  const auth = getAuth();

  const onSubmit: SubmitHandler<Inputs> = ({ email, password }) => {
    const persistence = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;

    setPersistence(auth, persistence);

    signInWithEmailAndPassword(auth, email, password).catch((reason) => {
      message.error(reason.toString());
      captureError(reason);
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Row>
          <img src={imgUrl} alt="logo" className={styles.logo} />
        </Row>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Row className={styles.row}>
            <Controller
              as={Input}
              placeholder="Enter your username"
              prefix={<UserOutlined />}
              control={control}
              type="email"
              name="email"
              rules={{ required: true }}
              className={styles.input}
            />
          </Row>
          {errors.email && <div>This field is required</div>}

          <Row className={styles.row}>
            <Controller
              as={Input}
              placeholder="Enter your password"
              prefix={<LockOutlined />}
              control={control}
              type="password"
              name="password"
              rules={{ required: true }}
            />
          </Row>
          {errors.password && <div>This field is required</div>}

          <Row className={styles.row}>
            <Checkbox defaultChecked onChange={handleRememberMe}>
              Keep me logged in
            </Checkbox>
          </Row>

          <Row className={styles.row}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginButton}
            >
              Login
            </Button>
          </Row>
        </form>
      </div>
    </div>
  );
};
