import React, { FC } from 'react';
import FetchingAssetName from 'components/FetchingAssetName';
import FetchingCollectionName from 'components/FetchingCollectionName';
import featuredAssetIcon from 'assets/featured-asset-icon.svg';
import carouselIcon from 'assets/carousel-icon.svg';
import billboardIcon from 'assets/billboard-icon.svg';
import playIcon from 'assets/play-button.svg';
import { PageElementsType } from 'types/pages';
import FetchingFeaturedAssetName from 'components/FetchingFeaturedAssetName';

export interface PageComponentParamSpec {
  type: string;
  label: string;
}

export interface PageComponentSpec {
  type: string;
  label: string;
  elements: PageElementsType;
  params?: { [key: string]: PageComponentParamSpec };
  icon: FC;
  source?: ({ uuid }: { uuid: string }) => JSX.Element;
  canBeMoved: boolean;
}

export enum PageComponentType {
  CONTINUE_WATCHING = 'CONTINUE_WATCHING',
  LARGE_PORTRAIT_CAROUSEL = 'LARGE_PORTRAIT_CAROUSEL',
  PORTRAIT_CAROUSEL = 'PORTRAIT_CAROUSEL',
  CAROUSEL = 'CAROUSEL',
  JUMBOTRON = 'JUMBOTRON',
  PORTRAIT_EXPANDABLE_CAROUSEL = 'PORTRAIT_EXPANDABLE_CAROUSEL',
  BILLBOARD = 'BILLBOARD',
  SQUARE_CAROUSEL = 'SQUARE_CAROUSEL',
  SKYSCRAPER_CAROUSEL = 'SKYSCRAPER_CAROUSEL',
  LANDSCAPE_CAROUSEL = 'LANDSCAPE_CAROUSEL',
  /**
   * Featured asset also acts like jumbotron, but with scheduling capabilities
   *
   * Unfortunate naming inherited from BE
   */
  FEATURED_ASSET = 'FEATURED_ASSET',
}

export const Jumbotron: PageComponentSpec = {
  type: PageComponentType.JUMBOTRON,
  label: 'Featured asset',
  icon: () => <img src={featuredAssetIcon} alt="" />,
  params: {
    uuid: {
      type: 'asset',
      label: 'Asset',
    },
  },
  elements: PageElementsType.SINGLE,
  source: FetchingAssetName,
  canBeMoved: false,
};

export const FeaturedAsset: PageComponentSpec = {
  type: PageComponentType.FEATURED_ASSET,
  label: 'Scheduled featured asset',
  icon: () => <img src={featuredAssetIcon} alt="" />,
  elements: PageElementsType.FEATURED_ASSET,
  canBeMoved: false,
  params: {
    uuid: {
      type: 'featuredAsset',
      label: 'Source',
    },
  },
  source: FetchingFeaturedAssetName,
};

export const Carousel: PageComponentSpec = {
  type: PageComponentType.CAROUSEL,
  label: 'Carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const PortraitCarousel: PageComponentSpec = {
  type: PageComponentType.PORTRAIT_CAROUSEL,
  label: 'Portrait carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const PortraitExpandableCarousel: PageComponentSpec = {
  type: PageComponentType.PORTRAIT_EXPANDABLE_CAROUSEL,
  label: 'Portrait expandable carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const LargePortraitCarousel: PageComponentSpec = {
  type: PageComponentType.LARGE_PORTRAIT_CAROUSEL,
  label: 'Large portrait carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const ContinueWatching: PageComponentSpec = {
  type: PageComponentType.CONTINUE_WATCHING,
  label: 'Continue watching',
  icon: () => <img src={playIcon} alt="" />,
  canBeMoved: true,
  elements: PageElementsType.NONE,
};

export const Billboard: PageComponentSpec = {
  type: PageComponentType.BILLBOARD,
  label: 'Billboard',
  icon: () => <img src={billboardIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const SquareCarousel: PageComponentSpec = {
  type: PageComponentType.SQUARE_CAROUSEL,
  label: 'Square carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const SkyscraperCarousel: PageComponentSpec = {
  type: PageComponentType.SKYSCRAPER_CAROUSEL,
  label: 'Skyscraper carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const LandscapeCarousel: PageComponentSpec = {
  type: PageComponentType.LANDSCAPE_CAROUSEL,
  label: 'Landscape carousel',
  icon: () => <img src={carouselIcon} alt="" />,
  params: {
    uuid: {
      type: 'collection',
      label: 'Collection',
    },
  },
  elements: PageElementsType.MULTI,
  source: FetchingCollectionName,
  canBeMoved: true,
};

export const DEFAULT_COMPONENT_TYPE = Carousel.type;

export const PAGE_COMPONENTS = [
  Jumbotron,
  Carousel,
  PortraitCarousel,
  LargePortraitCarousel,
  ContinueWatching,
  PortraitExpandableCarousel,
  Billboard,
  SquareCarousel,
  SkyscraperCarousel,
  LandscapeCarousel,
  FeaturedAsset,
];

export const platformsCommonComponents = [
  PageComponentType.CONTINUE_WATCHING,
  PageComponentType.CAROUSEL,
  PageComponentType.JUMBOTRON,
  PageComponentType.FEATURED_ASSET,
];

export const webPageComponents = [
  ...platformsCommonComponents,
  PageComponentType.LARGE_PORTRAIT_CAROUSEL,
  PageComponentType.PORTRAIT_CAROUSEL,
  PageComponentType.PORTRAIT_EXPANDABLE_CAROUSEL,
];

export const mobilePageComponents = [
  ...platformsCommonComponents,
  PageComponentType.LANDSCAPE_CAROUSEL,
  PageComponentType.SKYSCRAPER_CAROUSEL,
  PageComponentType.SQUARE_CAROUSEL,
];

export const tvPageComponents = [
  ...platformsCommonComponents,
  PageComponentType.PORTRAIT_CAROUSEL,
  PageComponentType.BILLBOARD,
];

export const featuredComponents = [Jumbotron, FeaturedAsset];

export const isFeaturedComponent = (componentType: string) =>
  featuredComponents?.some((f) => f.type === componentType);
