import { Modal } from 'antd';
import React from 'react';

interface CouponsRemoveModalProps {
  isVisible: boolean;
  onClose: () => void;
  onOk: () => void;
}

const CouponsRemoveModal = ({
  isVisible,
  onClose,
  onOk,
}: CouponsRemoveModalProps) => {
  return (
    <Modal
      title="Remove confirmation"
      visible={isVisible}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      onOk={onOk}
    >
      <div>
        <span>
          Are you sure you want to delete this coupon? This operation cannot be
          undone.
        </span>
      </div>
    </Modal>
  );
};

export default CouponsRemoveModal;
