import { ApiActionType, Region } from 'types';
import { CountryTranslation } from 'types/translations';
import { Action, createReducer } from 'typesafe-actions';
import {
  fetchCountriesAction,
  fetchRegionsAction,
  sendRegionAction,
} from './actions';

export interface IRegionsState {
  readonly countries?: CountryTranslation[];
  readonly regions?: Region[];
  readonly error?: { [key: string]: Error };
}

export const initialState = {
  countries: undefined,
  regions: undefined,
  error: undefined,
};

export const regionsReducer = createReducer<IRegionsState, Action>(initialState)
  .handleAction(
    fetchCountriesAction.success,
    (state: IRegionsState, { payload }) => ({
      ...state,
      countries: payload,
    })
  )
  .handleAction(
    fetchRegionsAction.success,
    (state: IRegionsState, { payload }) => ({
      ...state,
      regions: payload,
    })
  )
  .handleAction(
    sendRegionAction.success,
    (state: IRegionsState, { payload: { type, region } }) => ({
      ...state,
      regions:
        type === ApiActionType.CREATE
          ? state.regions
            ? [...state.regions, region]
            : [region]
          : state.regions
          ? state.regions.map((stateRegion) => {
              if (stateRegion.id === region.id)
                stateRegion = {
                  ...region,
                  uuid: stateRegion.uuid, // BE is not returning uuid in response for update
                };
              return stateRegion;
            })
          : undefined,
    })
  )
  .handleAction(
    [
      fetchCountriesAction.failure,
      fetchRegionsAction.failure,
      sendRegionAction.failure,
    ],
    (state: IRegionsState, { payload, type }) => ({
      ...state,
      error: { ...state.error, [type]: payload },
    })
  );
