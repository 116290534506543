import React from 'react';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import PlayerTrackListComponent from './PlayerTrackList';

const vjsComponent = videojs.getComponent('Component');

class PlayerTrackList extends vjsComponent {
  constructor(player: videojs.Player, options: videojs.ComponentOptions) {
    super(player, options);
    this.addClass('laminar-player-track-list');

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount = () => {
    ReactDOM.render(
      <PlayerTrackListComponent player={this.player_} />,
      this.el()
    );
  };
}

videojs.registerComponent('LaminarPlayerTrackList', PlayerTrackList);

export default PlayerTrackList;
