import { useCallback } from 'react';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getAICelebritiesInfo, getAIFacesInfo } from 'actions/ai';
import { useAssetContentContext } from 'contexts/assetContentContext';
import { AITimestampMap } from 'types/ai';
import { merge } from 'utils/helpers';

const useAICast = () => {
  const { sourceFileId } = useAssetContentContext();

  const [assetCelebrities] = useFetchAction<AITimestampMap>(
    useCallback(async () => {
      if (sourceFileId) {
        let celebrityInfo, facesInfo;
        try {
          celebrityInfo = await getAICelebritiesInfo(sourceFileId);
          facesInfo = await getAIFacesInfo(sourceFileId);
        } finally {
          return merge(celebrityInfo?.celebrities, facesInfo?.faces);
        }
      }
    }, [sourceFileId])
  );

  return assetCelebrities;
};

export default useAICast;
