export enum CouponIndex {
  UUID = 'uuid',
  CODE = 'code',
  TYPE = 'type',
  QUANTITY = 'quantity',
  USED_COUNT = 'usedCount',
  DELETE = 'delete',
  PROMOTION_NAME = 'promotionName',
  DISCOUNT_TYPE = 'discountType',
  VALIDITY_FROM = 'startDate',
  VALIDITY_TO = 'endDate',
  DISCOUNT_PERIOD = 'duration',
  DISCOUNT_PERCENTAGE = 'percentage',
  DISCOUNT_AMOUNT = 'amount',
}

export const CSVheaders = [
  { label: 'Uuid', key: CouponIndex.UUID },
  { label: 'Code', key: CouponIndex.CODE },
  { label: 'Type', key: CouponIndex.TYPE },
  { label: 'Usage limit', key: CouponIndex.QUANTITY },
  { label: 'Used count', key: CouponIndex.USED_COUNT },
  { label: 'Administrative name', key: CouponIndex.PROMOTION_NAME },
  { label: 'Discount type', key: CouponIndex.DISCOUNT_TYPE },
  { label: 'Discount period', key: CouponIndex.DISCOUNT_PERIOD },
  { label: 'Discount percentage', key: CouponIndex.DISCOUNT_PERCENTAGE },
  { label: 'Discount amount', key: CouponIndex.DISCOUNT_AMOUNT },
  { label: 'Validity from', key: CouponIndex.VALIDITY_FROM },
  { label: 'Validity to', key: CouponIndex.VALIDITY_TO },
];
