import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';
import { getCrew } from 'actions/crew';
import { Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import React, { useCallback, useState } from 'react';
import styles from './CrewAdder.module.scss';

export interface CrewAdderProps {
  onAdd: (crew: string[]) => void;
  loading?: boolean;
}

const normalizeSearchInput = (rawName?: string) => {
  const name = rawName ?? '';
  return name?.length > 0 ? name : undefined;
};

const CrewAdder = ({ onAdd, loading }: CrewAdderProps) => {
  const [crewToAdd, setCrewToAdd] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>();
  const inputValueDebounced = useDebounce(inputValue, 300);

  const [crew, crewLoading] = useFetchAction(
    useCallback(
      () => getCrew({ name: normalizeSearchInput(inputValueDebounced) }),
      [inputValueDebounced]
    )
  );

  return (
    <div className={styles.root}>
      <Select<string[]>
        mode="tags"
        showSearch
        style={{ width: '100%', marginRight: 8 }}
        tokenSeparators={[',']}
        onChange={setCrewToAdd}
        onSearch={setInputValue}
        value={crewToAdd}
        loading={crewLoading || loading}
        placeholder="Type or select from options" // TODO: Change placeholder?
        options={crew?.map((crew) => ({ value: crew, label: crew }))}
      />
      <Button
        disabled={crewToAdd.length === 0}
        loading={loading}
        onClick={() => {
          onAdd(crewToAdd);
          setCrewToAdd([]);
        }}
        type="primary"
        icon={<PlusOutlined />}
      >
        Add
      </Button>
    </div>
  );
};

export default CrewAdder;
