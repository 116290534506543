import {
  UseSendActionParameters,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import DeleteButton from 'components/DeleteButton';

export interface ActionDeleteButtonProps {
  action: UseSendActionParameters<any, void>;
  onDone?: () => void;
  onError?: (error: Error) => void;
  disabledTooltipMessage?: string;
}

const DeleteActionButton = ({
  onDone,
  action,
  disabledTooltipMessage,
  onError,
}: ActionDeleteButtonProps) => {
  const [remove, isProcessing, , , error] = useSendAction<any>(action, {
    onDone,
    onError,
  });

  return (
    <DeleteButton
      danger={!!error}
      loading={isProcessing}
      onClick={(e) => {
        e.stopPropagation();
        remove();
      }}
      type="text"
      disabledTooltipMessage={disabledTooltipMessage}
    />
  );
};

export default DeleteActionButton;
