import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Spin, Transfer } from 'antd';
import { Asset, AssetStatus } from 'types';
import { TransferItem } from 'antd/lib/transfer';
import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';
import { getAssets } from 'actions/assets';
import _uniqBy from 'lodash/uniqBy';
import _differenceBy from 'lodash/differenceBy';
import Button from 'components/Button';
import { DEFAULT_ASSET_STATUSES_FOR_PAGINATION } from 'utils/constants';
import {
  AssetAccessType,
  AssetType,
} from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';

interface AssetAddToEntityModalProps {
  onAdd: (assets: Asset[]) => void;
  open: boolean;
  onClose: () => void;
  regionUuids: string[];
  selectedAssetsUuids: string[];
  modalTitle: string;
  allowedTypes?: AssetType[];
  accessTypes?: AssetAccessType[];
}

const pageLimit = 100;

const AssetAddToEntityModal = ({
  open,
  onClose,
  regionUuids,
  selectedAssetsUuids,
  modalTitle,
  onAdd,
  allowedTypes,
  accessTypes,
}: AssetAddToEntityModalProps) => {
  const [dataSource, setDataSource] = useState<TransferItem[]>([]);
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 500);
  const [page, setPage] = useState(1);
  const [canLoadMore, toggleLoadMore] = useState(true);
  const [pagination, isFetching] = useFetchAction<Asset[]>(
    useCallback(async () => {
      const data = await getAssets({
        query: queryDebounced,
        statuses: DEFAULT_ASSET_STATUSES_FOR_PAGINATION,
        regionUuids,
        limit: pageLimit,
        types: allowedTypes,
        accessTypes,
      });

      if (data.length === 0) {
        toggleLoadMore(false);
      }
      return data;
    }, [accessTypes, allowedTypes, queryDebounced, regionUuids])
  );

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [assetsToAdd, setAssetsToAdd] = useState<Asset[]>([]);

  const onConfirm = () => {
    onAdd(mapChosenAssets(targetKeys));
    onClose();
    setAssetsToAdd([]);
    setQuery('');
    setTargetKeys([]);
  };

  const filterAssetsToAdd = (nextTargetKeys: string[]) =>
    assetsToAdd.filter(({ uuid }) => nextTargetKeys.includes(uuid));

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    const data = await getAssets({
      query: queryDebounced,
      statuses: [AssetStatus.PUBLISHED],
      regionUuids,
      limit: pageLimit,
      page: newPage,
    });

    if (data.length === 0) {
      toggleLoadMore(false);
      return;
    }

    setAssetsToAdd((prev) => _uniqBy([...prev, ...data], ({ uuid }) => uuid));
    setDataSource((prev) =>
      _uniqBy([...prev, ...mapToSourceData(data)], ({ key }) => key)
    );
  };

  // filter selected assets and make array uniq
  // because antd transfer doesn't handle dynamic async dataSource
  const mapToSourceData = useCallback(
    (assets: Asset[]): TransferItem[] => {
      const filteredAssetsUuids = _differenceBy(
        _uniqBy(
          assets.map((a) => a.uuid),
          (a) => a
        ),
        selectedAssetsUuids
      );

      return filteredAssetsUuids?.map((u) => {
        const asset = assets?.find((a) => a.uuid === u);

        return {
          title: asset?.name,
          key: asset?.uuid,
          description: asset?.description,
        };
      });
    },
    [selectedAssetsUuids]
  );

  const mapChosenAssets = (nextTargetKeys: string[]): Asset[] => {
    const filteredAssets = filterAssetsToAdd(nextTargetKeys);
    const filteredAssetsKeys = filteredAssets.map(({ uuid }) => uuid);
    const newKeys = nextTargetKeys.filter(
      (key) => !filteredAssetsKeys.includes(key)
    );
    const chosenAssets = pagination?.filter(({ uuid }) =>
      newKeys.includes(uuid)
    );

    if (chosenAssets?.length) return [...filteredAssets, ...chosenAssets];
    return filteredAssets;
  };

  useEffect(() => {
    setPage(1);
    toggleLoadMore(true);
  }, [query]);

  useEffect(() => {
    if (!pagination) return;

    setDataSource((prev) =>
      _uniqBy([...prev, ...mapToSourceData(pagination)], ({ key }) => key)
    );
  }, [mapToSourceData, pagination]);

  useEffect(() => {
    if (pagination && query) {
      setAssetsToAdd((prev) =>
        _uniqBy([...prev, ...pagination], ({ uuid }) => uuid)
      );
    }
  }, [pagination, query]);

  return (
    <Modal
      width={1080}
      title={modalTitle}
      okText="Assign"
      onOk={onConfirm}
      visible={open}
      onCancel={onClose}
      okButtonProps={{ disabled: !targetKeys?.length }}
    >
      {isFetching && (
        <div className={styles.loader}>
          <Spin />
        </div>
      )}
      <Transfer
        showSearch
        onSearch={(direction, value) =>
          direction === 'left' ? setQuery(value) : null
        }
        listStyle={{ width: '50%', height: 500 }}
        dataSource={dataSource}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={setTargetKeys}
        onSelectChange={onSelectChange}
        render={(item) => (item.title ? item.title : '')}
      />
      <div className={styles.loadMoreContainer}>
        {canLoadMore && (
          <Button type="primary" onClick={onLoadMore}>
            Load more
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default AssetAddToEntityModal;
