import { Pagination } from 'types';
import {
  ContentDistributionConfig,
  ContentDistributionConfigDetails,
  ContentDistributionConfigForm,
  ContentDistributionUser,
} from 'types/contentDistribution';
import { PAGINATION_SIZE, pageToSkip } from 'utils/pagination';
import api from './api';

export const getContentDistributionConfigPagination = async ({
  page,
  limit,
}: {
  page?: number;
  limit?: number;
}): Promise<Pagination<ContentDistributionConfig>> => {
  const { data } = await api().get(
    `/content-distribution/admin/configuration`,
    {
      params: {
        skip: pageToSkip(page),
        limit: PAGINATION_SIZE,
      },
    }
  );

  return data;
};

export const createBasicContentDistributionConfig = async ({
  name,
  regionUuid,
  distributorUuid,
  primaryLanguage,
}: ContentDistributionConfigForm): Promise<ContentDistributionConfig> => {
  const { data } = await api().post(
    `/content-distribution/admin/configuration`,
    {
      name,
      regionUuid,
      distributorUuid,
      primaryLanguage,
      assetsUuids: [],
    }
  );
  return data;
};

export const removeContentDistributionConfig = async (
  configUuid: string
): Promise<void> =>
  await api().delete(`/content-distribution/admin/configuration/${configUuid}`);

export const getContentDistributionConfig = async (
  configUuid: string
): Promise<ContentDistributionConfigDetails> => {
  const { data } = await api().get<ContentDistributionConfigDetails>(
    `/content-distribution/admin/configuration/${configUuid}`
  );

  return data;
};

export const updateContentDistributionConfig = async ({
  config,
  id,
}: {
  id: string;
  config: ContentDistributionConfig;
}): Promise<ContentDistributionConfig> => {
  const { data } = await api().patch(
    `/content-distribution/admin/configuration/${id}`,
    {
      ...config,
    }
  );

  return data;
};

export const getContentDistributionUsersPagination = async ({
  page,
  query,
  distributorUuid,
}: {
  page?: number;
  query?: string;
  distributorUuid?: string;
}): Promise<Pagination<ContentDistributionUser>> => {
  const { data } = await api().get(`/content-distribution/admin/users`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      query,
      distributorUuid,
    },
  });

  return data;
};
