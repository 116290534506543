import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { ButtonProps } from 'antd/lib/button';
import { Tooltip } from 'antd';
import styles from './index.module.scss';

export interface DeleteButtonProps extends ButtonProps {
  disabledTooltipMessage?: string;
}

const DeleteButton = (props: DeleteButtonProps) => {
  if (!!props.disabledTooltipMessage) {
    return (
      <Tooltip title={props.disabledTooltipMessage} placement="left">
        <div className={styles.deleteButtonWrapper}>
          {/* Need to wrap into div as tooltip doesn't appear over disabled buttons */}
          <Button
            type="text"
            icon={<DeleteOutlined />}
            disabled
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </Tooltip>
    );
  }

  return <Button type="text" icon={<DeleteOutlined />} {...props} />;
};

export default DeleteButton;
