import React, { useEffect, useState } from 'react';
import { Collection } from 'types';
import { Input } from 'antd';
import _omit from 'lodash/omit';
import PublicDetails from 'components/PublicDetails';
import FormGroup from 'components/FormGroup';
import { Controller } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import styles from '../../index.module.scss';

const CollectionPublicDetails = ({
  collection,
  onCollectionChange,
  shouldValidate,
}: {
  collection: Collection;
  onCollectionChange: (collection: Collection) => void;
  shouldValidate: boolean;
}) => {
  const [error, setError] = useState<string>();

  let { metadata } = collection;

  useEffect(() => {
    if (
      !(metadata && Object.keys(metadata).length && metadata['en']) &&
      shouldValidate
    ) {
      setError(
        'You have to provide metadata, with obligatory English language'
      );
      return;
    }
    setError('');
  }, [metadata, shouldValidate]);

  return (
    <>
      <PublicDetails
        showErrors={!!error}
        items={collection.metadata}
        renderLanguageDetails={(meta) => (
          <>
            {Object.entries(meta).map(
              ([key, value]: [string, string]) =>
                key !== 'selectedLanguage' && (
                  <div className={styles.detailsElement}>
                    <span>{key}</span>
                    <p>{value}</p>
                  </div>
                )
            )}
          </>
        )}
        renderLanguageForm={(control, errors) => (
          <>
            <FormGroup
              label="Collection title"
              errorMessage={errors?.title?.message}
              input={
                <Controller
                  as={Input}
                  control={control}
                  name="title"
                  rules={{ required: 'Required' }}
                />
              }
            />
            <FormGroup
              label="Description"
              input={
                <Controller
                  as={TextArea}
                  showCount
                  control={control}
                  name="description"
                />
              }
            />
          </>
        )}
        onSaveLanguage={(lang, details) => {
          const metadata = {
            ...collection.metadata,
            [details.selectedLanguage!]: details,
          };
          if (details.selectedLanguage !== lang) {
            delete metadata[lang];
          }
          onCollectionChange({
            ...collection,
            metadata,
          });
        }}
        onRemoveLanguage={(lang) =>
          onCollectionChange({
            ...collection,
            metadata: _omit(collection.metadata, lang),
          })
        }
      />
    </>
  );
};

export default CollectionPublicDetails;
