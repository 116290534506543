import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AgeRatingForm } from 'types/censorships';
import Button from 'components/Button';
import Space from 'components/Space';
import FormContainer from 'components/FormContainer';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import ButtonsRow from 'components/ButtonsRow';
import { errorNotify } from 'utils/errorNotify';
import { Select } from 'antd';
import FormGroup from 'components/FormGroup';
import { useGetRegions } from 'hooks/useRegions';
import { assignAssetCensorshipRule } from 'actions/censorships';
import notification from 'utils/notification';
import { AgeRatingModalFormProps } from './types';
import { CensorshipRuleSelector } from './CensorshipRuleSelector';

const AgeRatingModalForm: FC<AgeRatingModalFormProps> = ({
  onClose,
  refresh,
  assetId,
}) => {
  const { regions, isLoadingRegions } = useGetRegions();

  const { control, handleSubmit, getValues, errors, watch, setValue } =
    useForm<AgeRatingForm>({
      mode: 'onChange',
      defaultValues: {
        region: '',
      },
    });
  const selectedRegion = watch('region');

  const [save, isSavingCensorship] = useSendAction<void, AgeRatingForm>(
    async () => {
      const { censorshipRule } = getValues();

      await assignAssetCensorshipRule({
        censorshipRuleId: censorshipRule,
        assetId,
      });
    },
    {
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'Age rating has been saved!',
        });

        refresh();
        onClose();
      },
      onError: errorNotify,
    }
  );

  useEffect(() => {
    if (!isLoadingRegions && regions?.length) {
      setValue('region', regions[0].uuid);
    }
  }, [regions, isLoadingRegions, setValue]);

  return (
    <FormContainer>
      <Space direction="vertical" expand>
        <FormGroup
          input={
            <Controller
              as={Select}
              control={control}
              style={{ width: '100%' }}
              name="region"
              rules={{ required: 'Required' }}
              options={regions?.map((region) => ({
                label: region.name,
                value: region.uuid,
              }))}
              loading={isLoadingRegions}
            />
          }
          label="Region"
          errorMessage={errors.region?.message}
        />
        <CensorshipRuleSelector
          control={control}
          censorshipRuleErrorMessage={errors.censorshipRule?.message}
          selectedRegion={selectedRegion}
          onSelectedRegionChange={setValue}
        />
        <ButtonsRow>
          <Button
            disabled={isSavingCensorship}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(save)}
            loading={isSavingCensorship}
            type="primary"
          >
            Save
          </Button>
        </ButtonsRow>
      </Space>
    </FormContainer>
  );
};

export default AgeRatingModalForm;
