import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InternalList from './InternalList';
import InternalDetails from './InternalDetails';

const Internal = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/page/:page`} component={InternalList} />
      <Route exact path={path} component={InternalList} />
      <Route path={`${path}/:id`} component={InternalDetails} />
    </Switch>
  );
};

export default Internal;
