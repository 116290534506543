import React from 'react';
import {
  statusLiveLabel,
  statusLiveTagColor,
} from 'pages/Assets/AssetDetails/utils/liveUtils';
import {
  LiveStreamInfrastructure,
  LiveStreamInfrastructureStatus,
} from 'types/live';
import { Tag, Tooltip } from 'antd';
import styles from './index.module.scss';

interface LiveTagProps {
  details?: LiveStreamInfrastructure;
}

const LiveTag = ({ details }: LiveTagProps) => {
  const { error, status } = details || {};
  return (
    <div className={styles.root}>
      <Tag color={statusLiveTagColor(status)} className={styles.tag}>
        {status === LiveStreamInfrastructureStatus.LIVE && (
          <div className={styles.dot} />
        )}
        {statusLiveLabel(status)}
      </Tag>
      {error && (
        <Tooltip title={error}>
          <Tag color="error">Error</Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default LiveTag;
