import { DatePicker, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import {
  AddRegionLicense,
  AssetAvailabilityValue,
  LicenseEditProps,
} from 'types/licenses';
import {
  dateFormatWith12Time,
  disableDates,
  getTimezones,
} from 'utils/timezones';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { validateAvailableFromField } from 'utils/licenses';
import moment, { Moment } from 'moment';
import styles from '../index.module.scss';

const { Option } = Select;
interface AssetAvailabilityFromProps {
  license: AddRegionLicense;
  isSelectedToAdd?: boolean;
  onLicenseEdit: (license: LicenseEditProps) => void;
  setWarningRegionsIds: React.Dispatch<React.SetStateAction<number[]>>;
  isWarning?: boolean;
}

const AssetAvailabilityFrom = ({
  license,
  isSelectedToAdd,
  onLicenseEdit,
  isWarning,
  setWarningRegionsIds,
}: AssetAvailabilityFromProps) => {
  const datePickerValue = useMemo(() => {
    if (!license.availableFrom) {
      return null;
    }

    return moment(license.availableFrom);
  }, [license]);

  const onAvailableFromEdit = useCallback(
    (availableFrom: Moment | null) => {
      if (!availableFrom) {
        return;
      }

      const validationMessage = validateAvailableFromField({
        currentValue: availableFrom,
        isSelected: isSelectedToAdd,
        license,
      });

      !!validationMessage
        ? setWarningRegionsIds((prev) => [...prev, license.regionId])
        : setWarningRegionsIds((prev) =>
            prev.filter((r) => r !== license.regionId)
          );

      onLicenseEdit({ licenseToEdit: { ...license, availableFrom } });
    },
    [isSelectedToAdd, license, onLicenseEdit, setWarningRegionsIds]
  );

  const onAvailabilityTimezoneEdit = useCallback(
    (availabilityTimezone: number) =>
      onLicenseEdit({ licenseToEdit: { ...license, availabilityTimezone } }),
    [license, onLicenseEdit]
  );

  if (license.availability !== AssetAvailabilityValue.SCHEDULED) {
    return null;
  }

  return (
    <div className={styles.availabilityFromContainer}>
      <DatePicker
        disabledDate={(current) =>
          disableDates({
            current,
            endDate: Number(license?.dateTo),
          })
        }
        format={dateFormatWith12Time}
        placeholder="Select date and time"
        className={cn(isWarning && styles.warningDatePicker)}
        disabled={!isSelectedToAdd}
        allowClear={false}
        value={datePickerValue}
        onChange={onAvailableFromEdit}
        showTime
      />

      <Select
        placeholder="Choose time zone"
        showSearch
        filterOption={(input, option) =>
          option?.key.toLowerCase().includes(input.toLowerCase())
        }
        className={styles.timezoneItem}
        disabled={!isSelectedToAdd}
        value={license.availabilityTimezone}
        onSelect={onAvailabilityTimezoneEdit}
      >
        {getTimezones().map(({ label, offset }) => (
          <Option key={label} value={offset}>
            {label}
          </Option>
        ))}
      </Select>

      {isWarning && (
        <ExclamationCircleOutlined className={styles.warningIcon} />
      )}
    </div>
  );
};

export default AssetAvailabilityFrom;
