import Button from 'components/Button';
import React from 'react';
import { User } from 'types';
import { getUsername } from 'utils/users';
import styles from '../index.module.scss';

interface UserClientsCancelPlanProps {
  user?: User;
  onClose?: () => void;
  onCancelConfirm?: () => void;
  loading?: boolean;
}

const UserClientsCancelPlan = ({
  user,
  onClose,
  onCancelConfirm,
  loading,
}: UserClientsCancelPlanProps) => (
  <>
    <div className={styles.cancelContainer}>
      Plan of <span className={styles.bold}>{getUsername(user)}</span> account
      will be canceled and user will be no longer charged.
    </div>
    <div className={styles.modalButtons}>
      <Button type="default" onClick={onClose}>
        No
      </Button>
      <Button
        loading={loading}
        htmlType="submit"
        type="primary"
        onClick={onCancelConfirm}
        danger
      >
        Yes, cancel
      </Button>
    </div>
  </>
);

export default UserClientsCancelPlan;
