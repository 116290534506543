import { DevicePlatform } from 'types/devices';
import { MenuItemType, UrlTranslation } from 'types/menu';

export const DEFAULT_TRANSLATION_ENGLISH_CODE = 'en';

export const initialUrlTranslations: UrlTranslation[] = [
  { langCode: DEFAULT_TRANSLATION_ENGLISH_CODE, title: '' },
];

export const menuItemTypes = [
  { label: 'Page', value: MenuItemType.CUSTOM_PAGE },
  { label: 'External URL', value: MenuItemType.EXTERNAL_LINK },
];

export const getAvailableDevicesForMenuItem = (menuType: MenuItemType) =>
  menuType === MenuItemType.CUSTOM_PAGE
    ? CUSTOM_PAGE_MENU_DEVICES
    : EXTERNAL_URL_MENU_DEVICES;

export const CUSTOM_PAGE_MENU_DEVICES = [
  DevicePlatform.WEB,
  DevicePlatform.MOBILE,
  DevicePlatform.TV,
];

export const EXTERNAL_URL_MENU_DEVICES = [
  DevicePlatform.WEB,
  DevicePlatform.MOBILE,
];
