import { DashboardData } from 'types/dashboard';
import api from './api';

interface DashboardDataProps {
  assetType: string;
  periodDays: number;
}

export const getDashboardData = async ({
  assetType,
  periodDays,
}: DashboardDataProps): Promise<DashboardData> => {
  const { data } = await api().get(`/dashboard`, {
    params: {
      assetType: assetType,
      periodDays: periodDays,
    },
  });
  return data;
};
