import { Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PrepaidCard,
  PrepaidCardStatus,
  PrepaidTableFilters,
} from 'types/prepaidCard';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getPrepaidCardsPagination, removePrepaid } from 'actions/prepaid';
import { Pagination } from 'types';
import { usePageParam, usePaginationObject } from 'utils/pagination';
import { prepaidCardStatuses } from 'utils/constants';
import DeleteActionButton from 'components/DeleteActionButton';
import { Paths } from 'types/paths';
import { captureErrorAndNotify } from 'utils/captureError';
import PrepaidCardsTablePlanFilter from '../PrepaidCardsTablePlanFilter';

const PrepaidCardsTable = () => {
  const page = usePageParam();
  const [filters, setFilters] = useState<PrepaidTableFilters>({});
  const [planFilterModalVisible, setPlanFilterModalVisible] = useState(false);
  const {
    data: prepaidCards,
    isLoading,
    refresh: refreshPrepaidTable,
  } = useFetchActionObj<Pagination<PrepaidCard>>(
    useCallback(
      () =>
        getPrepaidCardsPagination({
          page,
          planUuid: filters.planUuid,
          status: filters.status,
        }),
      [page, filters]
    )
  );
  const history = useHistory();

  const onRowClick = (prepaidCard: PrepaidCard) => {
    history.push(`${Paths.PREPAID_CARDS}/${prepaidCard.uuid}`);
  };

  const getProperStatusTagColor = (status: PrepaidCardStatus) => {
    switch (status) {
      case PrepaidCardStatus.PUBLISHED:
        return 'success';
      case PrepaidCardStatus.UNPUBLISHED:
        return 'warning';
      default:
        return 'default';
    }
  };

  const onPlanSelect = (planUuid?: string) => {
    setFilters((prevState) => ({ status: prevState.status, planUuid }));
    setPlanFilterModalVisible(false);
  };

  const handleFiltersChange = (filters: PrepaidTableFilters) => {
    setFilters((prevState) => ({
      status: filters.status as PrepaidCardStatus,
      planUuid: prevState.planUuid,
    }));
  };

  return (
    <Table
      indentSize={40}
      dataSource={prepaidCards?.data}
      rowClassName="clickable-row"
      rowKey="uuid"
      onRow={(prepaidCard: PrepaidCard) => ({
        onClick: () => onRowClick(prepaidCard),
      })}
      loading={isLoading}
      pagination={usePaginationObject('/prepaidCards', prepaidCards, page)}
      onChange={(_, filters: PrepaidTableFilters) =>
        handleFiltersChange(filters)
      }
    >
      <Column title="Title" key="title" dataIndex="title" />
      <Column title="Plan Duration" dataIndex="billingFrequency" key="type" />
      <Column
        title="Plan"
        dataIndex="planName"
        key="planName"
        filterDropdown={<PrepaidCardsTablePlanFilter onSelect={onPlanSelect} />}
        filterDropdownVisible={planFilterModalVisible}
        onFilterDropdownVisibleChange={setPlanFilterModalVisible}
        filteredValue={!!filters.planUuid ? [filters.planUuid] : null}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(status) => (
          <Tag color={getProperStatusTagColor(status)}>{status}</Tag>
        )}
        filters={prepaidCardStatuses?.map((prepaid) => ({
          text: prepaid.label,
          value: prepaid.value,
        }))}
        filterMultiple={false}
        filteredValue={!!filters.status ? [filters.status] : null}
      />
      <Column
        dataIndex="delete"
        key="delete"
        width={50}
        render={(_, prepaidCard: PrepaidCard) =>
          prepaidCard.status === PrepaidCardStatus.DRAFT ? (
            <DeleteActionButton
              onDone={refreshPrepaidTable}
              action={() => removePrepaid(prepaidCard.uuid)}
              onError={captureErrorAndNotify}
            />
          ) : null
        }
      />
    </Table>
  );
};

export default PrepaidCardsTable;
