import api from 'actions/api';
import { GuestAccess, GuestAccessGet } from 'types/guestAccess';

export const getGuestAccessConfiguration = async () => {
  const { data } = await api().get<GuestAccessGet>(`/anonymous-access`);
  return data;
};

export const updateGuestAccessConfiguration = async (body: GuestAccess) => {
  const { data } = await api().post<void>(`/anonymous-access`, body);
  return data;
};
