import React from 'react';
import { Layout, Menu } from 'antd';
import styles from './index.module.scss';
const { Sider } = Layout;

interface InnerSiderProps {
  menuKey: string;
  children: React.ReactNode;
}

/* Inner Sider is container for inner menus. Menu Items are component childrens */
const InnerSider = (props: InnerSiderProps) => {
  return (
    <Sider className={styles.sider}>
      <Menu
        className={styles.menu}
        mode="inline"
        selectedKeys={[props.menuKey]}
      >
        {props.children}
      </Menu>
    </Sider>
  );
};

export default InnerSider;
