import React, { useRef, useState } from 'react';
import { getPresignedUrlForAICustomLabel } from 'actions/ai';
import { uploadImageToS3 } from 'actions/images';
import VideoModal from 'components/VideoModal';
import VideoPlayer from 'components/VideoPlayer';
import uniqueId from 'lodash/uniqueId';
import { useDispatch } from 'react-redux';
import { runAiFacesRecognitionAction } from 'store/ai/actions';
import { VideoType } from 'types';
import { dataURItoBlob } from 'utils/files';
import { LVideoJsPlayer } from 'video.js';
import AddReferenceSnapshot from './AddReferenceSnapshot';
import styles from './index.module.scss';
import ReferencesList from './ReferencesList';
import useSnapshot from './useSnapshot';

const getSnapshotKey = (value: string) => {
  const result = /^faces\/(.+)\.jpg$/.exec(value);
  return result ? result[1] : '';
};

interface AddNewReferenceProps {
  isVisible: boolean;
  onCancel: () => void;
  videoUrl?: string;
  sourceFileId: string;
}

export interface ReferenceSnapshot {
  name: string;
  image: string;
  key: string;
}

const AddNewReference = ({
  isVisible,
  onCancel,
  videoUrl,
  sourceFileId,
}: AddNewReferenceProps) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [videoElHeight, setVideoElHeight] = useState<number>();

  const playerId = uniqueId('player');
  const [images, setImages] = useState<ReferenceSnapshot[]>([]);
  const dispatch = useDispatch();
  const takeSnapshot = useSnapshot(
    playerId,
    async (snapshot) => {
      const signedUrl = await getPresignedUrlForAICustomLabel();
      uploadImageToS3({
        signedUrl: signedUrl.url,
        params: signedUrl.fields,
        file: dataURItoBlob(snapshot.image, snapshot.name),
      });
      setImages([
        { ...snapshot, key: getSnapshotKey(signedUrl.fields.key) },
        ...images,
      ]);
    },
    videoContainerRef
  );

  const handleOnCancel = () => {
    setImages([]);
    onCancel();
  };

  const handleOnAnalyzeVideo = () => {
    dispatch(
      runAiFacesRecognitionAction.request({ sourceFileId, faces: images })
    );
    handleOnCancel();
  };

  const onDelete = (index: number) => {
    setImages(images.filter((img, i) => i !== index));
  };
  const onUpdate = (index: number, value: string) => {
    setImages(
      images.map((image, i) => {
        if (index === i) {
          return {
            ...image,
            name: value,
          };
        }
        return image;
      })
    );
  };

  const onPlayerReady = (player: LVideoJsPlayer) => {
    const actualHeight = player?.el().getBoundingClientRect()?.height;
    setVideoElHeight(!actualHeight || actualHeight < 500 ? 500 : actualHeight);
  };

  return (
    <VideoModal
      sidebarElement={
        <>
          {images.length ? (
            <ReferencesList
              items={images}
              onTakeSnapshot={takeSnapshot}
              onNameUpdate={onUpdate}
              onDelete={onDelete}
              onCancel={handleOnCancel}
              onAnalyzeVideo={handleOnAnalyzeVideo}
            />
          ) : (
            <AddReferenceSnapshot onTakeSnapshot={takeSnapshot} />
          )}
        </>
      }
      visible={isVisible}
      onCancel={handleOnCancel}
      height={videoElHeight}
      videoElement={
        <div className={styles.videoContainer} ref={videoContainerRef}>
          {videoUrl && (
            <VideoPlayer
              onPlayerReady={onPlayerReady}
              playerId={playerId}
              videoFile={{
                video: {
                  raw: videoUrl,
                  dash: { url: videoUrl, type: VideoType.MPD },
                  hls: { url: videoUrl, type: VideoType.M3U8 },
                },
              }}
            />
          )}
        </div>
      }
    />
  );
};

export default AddNewReference;
