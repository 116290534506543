import { AICelebrities, AIFaces, AILabels, AIModeration } from 'types/ai';
import api from './api';

export const removeUnderscoreFromKeys = (faces: AIFaces['faces']) => {
  return Object.keys(faces).reduce((acc, face) => {
    const newKey = face.replace(new RegExp('_', 'g'), ' ');
    acc[newKey] = faces[face];
    return acc;
  }, {} as AIFaces['faces']);
};

export const getAICelebritiesInfo = async (sourceId: string) => {
  const { data } = await api().get<AICelebrities>(
    `/ai/recognition/${sourceId}/celebrity`
  );
  return data;
};

export const getAILabelsInfo = async (sourceId: string) => {
  const { data } = await api().get<AILabels>(
    `/ai/recognition/${sourceId}/label`
  );
  return data.labels;
};

export const getAIFacesInfo = async (sourceId: string) => {
  const { data } = await api().get<AIFaces>(`/ai/recognition/${sourceId}/face`);

  if (data.faces) {
    data.faces = removeUnderscoreFromKeys(data.faces);
  }
  return data;
};

export const getAIModeration = async (sourceId: string) => {
  const { data } = await api().get<AIModeration>(
    `/ai/recognition/${sourceId}/moderation`
  );
  return data;
};

export const getPresignedUrlForAICustomLabel = async () => {
  const { data } = await api().get('/ai/face/upload');

  return data;
};
