import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const AssetIcon = ({ image }: { image?: string }) => {
  const style = image ? { backgroundImage: `url(${image})` } : undefined;

  return (
    <div className={styles.root} style={style}>
      {!style && (
        <div className={styles.iconWrapper}>
          <FileImageOutlined />
        </div>
      )}
    </div>
  );
};

export default AssetIcon;
