import { DefaultTaxCode } from 'pages/TaxManagement/components/TaxProducts/types';
import { TaxConfigurationForm, TaxProduct } from 'pages/TaxManagement/types';
import api from './api';

export const getTaxConfiguration = async (): Promise<TaxConfigurationForm> => {
  const { data } = await api().get(`/tax/configuration-admin`);

  return data;
};

export const saveTaxConfiguration = async (data: TaxConfigurationForm) =>
  await api().post<TaxConfigurationForm>(`/tax/configuration-admin`, data);

export const getTaxCodes = async () => {
  const { data } = await api().get<TaxProduct[]>(`/tax/tax-code`);

  return data;
};

export const saveTaxCode = async (
  taxProduct: TaxProduct
): Promise<TaxProduct> => await api().post('/tax/tax-code', taxProduct);

export const deleteTaxCode = async (taxProductId: string) =>
  await api().delete(`/tax/tax-code/${taxProductId}`);

export const getDefaultTaxCodes = async () => {
  const { data } = await api().get<DefaultTaxCode[]>(
    '/tax/configuration-default-tax-codes'
  );

  return data;
};
