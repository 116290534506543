import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { createPlatformPartner } from 'actions/platformPartners';
import { Input, Modal } from 'antd';
import FormGroup from 'components/FormGroup';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CreatePlatformPartner, PlatformPartner } from 'types/platformPartners';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';

interface CreatePartnerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreatePartnerModal = ({ isOpen, onClose }: CreatePartnerModalProps) => {
  const formMethods = useForm<CreatePlatformPartner>({});
  const { handleSubmit } = formMethods;
  const { push } = useHistory();

  const onCreateSuccess = (id: string) => {
    notification.success({
      title: 'Success',
      description: 'Platform partner has been created!',
    });
    onClose();
    push(`/partners/${id}`);
  };

  const { sendAction: createPlatformPartnerAction, isLoading } =
    useSendActionObj<PlatformPartner, CreatePlatformPartner>(
      (partner) => createPlatformPartner(partner),
      {
        onDone: (data) => onCreateSuccess(data.uuid),
        onError: (error) => {
          notification.error({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
          });
          captureError(error as any);
        },
      }
    );

  const onSubmit = (values: CreatePlatformPartner) =>
    createPlatformPartnerAction(values);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title="Create new partner"
      okText="Create"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <FormGroup
          input={
            <Controller
              placeholder="Enter the partner name"
              as={Input}
              control={formMethods.control}
              name="administrativeName"
              rules={{ required: 'This field is required' }}
            />
          }
          label="Name"
          errorMessage={formMethods.errors?.administrativeName?.message}
          defaultValue=""
        />

        <FormGroup
          input={
            <Controller
              placeholder="Enter the partner ID"
              as={Input}
              control={formMethods.control}
              name="partnerId"
              rules={{ required: 'This field is required' }}
            />
          }
          label="Partner ID"
          errorMessage={formMethods.errors?.partnerId?.message}
          defaultValue=""
        />
      </FormProvider>
    </Modal>
  );
};

export default CreatePartnerModal;
