import { Table } from 'antd';
import React from 'react';
import Column from 'antd/lib/table/Column';
import { AssetAccessType, AssetChildren } from 'types';
import AssetIcon from 'components/AssetIcon';
import { TouchedAsset } from 'pages/Assets/AssetDetails/FreemiumEpisodes/index';
import styles from 'pages/Assets/AssetDetails/FreemiumEpisodes/components/FreemiumTable/RowNestedTable/index.module.scss';
import { ImageType } from '@laminar-product/client-commons-core/core';

const RowNestedTable = ({
  record,
  assetDescendants,
  freemiumEpisodes,
  setFreemiumEpisodes,
  addTouchedAsset,
  orderByAssetOrderAsc,
}: {
  record: AssetChildren;
  assetDescendants: AssetChildren[];
  freemiumEpisodes: AssetChildren[];
  setFreemiumEpisodes: React.Dispatch<React.SetStateAction<AssetChildren[]>>;
  addTouchedAsset: (a: TouchedAsset) => void;
  orderByAssetOrderAsc: (a: AssetChildren[]) => AssetChildren[];
}) => {
  const onSelect = (asset: AssetChildren, selected: boolean) => {
    if (selected) {
      addTouchedAsset({
        id: asset.id,
        accessType: AssetAccessType.FREE,
      });
      setFreemiumEpisodes((prev) => [...prev, asset]);
      return;
    }

    addTouchedAsset({
      id: asset.id,
      accessType: AssetAccessType.PAID,
    });
    setFreemiumEpisodes((prev) => prev.filter((a) => a.id !== asset.id));
    return;
  };

  const getChildImageUrl = (asset: AssetChildren) =>
    asset.images.find((i) => i.type === ImageType.child)?.url;

  const getOrderedChildrenForParentRow = () =>
    orderByAssetOrderAsc(
      assetDescendants?.filter((f) => f.parentId === record.id)
    );

  return (
    <Table
      dataSource={getOrderedChildrenForParentRow()}
      pagination={false}
      showHeader={false}
      className={styles.nestedFreemiumTable}
      rowKey={(node: AssetChildren) => node.id}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: freemiumEpisodes.map((a) => a.id),
        onSelect: (asset: AssetChildren, selected: boolean) =>
          onSelect(asset, selected),
      }}
    >
      <Column
        title="Images"
        dataIndex={'images'}
        render={(_, node: AssetChildren) => (
          <AssetIcon image={getChildImageUrl(node)} />
        )}
      />
      <Column
        title="Order"
        render={(_, node: AssetChildren) => <div>Episode {node.order}</div>}
      />
      <Column title="Name" dataIndex="name" />
    </Table>
  );
};

export default RowNestedTable;
