import React, { useState } from 'react';
import Button from 'components/Button';
import FormContainer from 'components/FormContainer';
import { User } from 'types';
import FormGroup from 'components/FormGroup';
import { Input } from 'antd';
import { getUsername } from 'utils/users';
import PlanChooser from 'components/PlanChooser';
import { BasicPublishedPlan, PlanPrice } from 'types/plan';
import PriceChooser from 'components/PriceChooser';
import { ErrorMessage } from 'components/ErrorIndicator';
import { assignSubscriptionToClient } from 'actions/subscription';
import notification from 'utils/notification';
import { captureErrorAndNotify } from 'utils/captureError';
import styles from '../index.module.scss';
import UserClientsConfirmationModal from '../UserClientsConfirmationModal';

interface UserClientsChangePlanProps {
  user?: User;
  onClose: () => void;
  refresh: () => void;
}

const UserClientsChangePlan = ({
  user,
  onClose,
  refresh,
}: UserClientsChangePlanProps) => {
  const [selectedPlan, setSelectedPlan] = useState<BasicPublishedPlan>();
  const [selectedPrice, setSelectedPrice] = useState<PlanPrice>();
  const [error, setError] = useState('');
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState(false);

  const onPlanSelect = (plan?: BasicPublishedPlan) => {
    if (!plan) return;
    setSelectedPlan(plan);
    setSelectedPrice(undefined);
  };

  const onPriceSelect = (price?: PlanPrice) => {
    if (!price) return;
    setSelectedPrice(price);
  };

  const checkSubscription = async () => {
    if (!user) return;

    if (!selectedPlan || !selectedPrice) {
      setError('Plan and pricing are required!');
      return;
    }

    setConfirmationModalVisible(true);
  };

  const assignSubscription = async () => {
    if (!user || !selectedPlan || !selectedPrice) return;

    await assignSubscriptionToClient({
      userUuid: user.uid,
      planUuid: selectedPlan.uuid,
      priceUuid: selectedPrice.uuid,
    })
      .then(() => {
        notification.success({
          title: 'Success',
          description: 'The subscription has been assigned to the user.',
        });
      })
      .catch(captureErrorAndNotify)
      .finally(() => {
        setConfirmationModalVisible(false);
        onClose();
        refresh();
      });
  };

  return (
    <>
      <FormContainer>
        <form>
          <fieldset>
            <FormGroup
              label="User"
              input={<Input disabled value={getUsername(user)} />}
            />

            <FormGroup
              label="Plan"
              input={<PlanChooser onChoose={onPlanSelect} />}
            />

            <FormGroup
              label="Pricing"
              input={
                <PriceChooser
                  onChoose={onPriceSelect}
                  selectedPlanUuid={selectedPlan?.uuid}
                  selectedPrice={selectedPrice}
                />
              }
            />

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <div className={styles.modalButtons}>
              <Button type="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  checkSubscription();
                }}
              >
                Save
              </Button>
            </div>
          </fieldset>
        </form>
      </FormContainer>

      <UserClientsConfirmationModal
        onClose={() => setConfirmationModalVisible(false)}
        isVisible={isConfirmationModalVisible}
        onOk={assignSubscription}
        userName={getUsername(user)}
        newPlan={selectedPlan}
      />
    </>
  );
};

export default UserClientsChangePlan;
