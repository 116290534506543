import React, { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { getContentsPagination } from 'actions/content';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import { Link, useHistory } from 'react-router-dom';
import { Content } from 'types';
import { Pagination, usePageParam } from 'utils/pagination';
import { Paths } from 'types/paths';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import ContentTable from './ContentTable';

const ContentList = () => {
  const page = usePageParam();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const action = useCallback(
    () => getContentsPagination(page, searchTerm),
    [page, searchTerm]
  );
  const [pagination, isLoading, error] =
    useFetchAction<Pagination<Content>>(action);

  if (error) return <ErrorIndicator error={error} />;

  return (
    <PageContainer>
      <PageHeader
        title="Content"
        extra={
          <>
            <Search
              onSearch={(query) => {
                setSearchTerm(query);
                history.push(`${Paths.CONTENT}/page/1`);
              }}
              key="search"
            />
            <Link to={`${Paths.CONTENT}/create`}>
              <Button type="primary" icon={<PlusOutlined />}>
                Create new content
              </Button>
            </Link>
          </>
        }
      />
      <ContentTable
        loading={isLoading}
        currentPage={page ?? 1}
        pagination={pagination}
      />
    </PageContainer>
  );
};

export default ContentList;
