import { DatePicker, Input, InputNumber, Modal } from 'antd';
import FormGroup from 'components/FormGroup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  PrepaidCard,
  PrepaidCardBasicBatch,
  PrepaidCardBatch,
} from 'types/prepaidCard';
import moment from 'moment';
import { ErrorMessage } from 'components/ErrorIndicator';
import notification from 'utils/notification';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { generatePrepaidBatch } from 'actions/prepaid';
import { captureError } from 'utils/captureError';
import { DATE_DAYFIRST_FORMAT } from 'utils/constants';
import styles from './index.module.scss';

interface PrepaidCardsBatchGenerateProps {
  isVisible: boolean;
  onClose: () => void;
  refreshBatches: () => void;
  prepaidCard: PrepaidCard;
}

const PrepaidCardsBatchGenerate = ({
  isVisible,
  onClose,
  refreshBatches,
  prepaidCard,
}: PrepaidCardsBatchGenerateProps) => {
  const [error, setError] = useState<string>('');
  const { control, errors, handleSubmit } = useForm<PrepaidCardBasicBatch>({
    mode: 'onSubmit',
  });

  const { sendAction: generateBatch, isLoading: isGenerating } =
    useSendActionObj<PrepaidCardBatch, PrepaidCardBasicBatch>(
      async (batch) => {
        return await generatePrepaidBatch({
          batch: {
            ...batch,
            validFrom: moment(batch.validFrom).format(DATE_DAYFIRST_FORMAT),
            validTo: moment(batch.validTo).format(DATE_DAYFIRST_FORMAT),
          },
          prepaidCardUuid: prepaidCard.uuid,
        });
      },
      {
        onDone: () => {
          refreshBatches();
          notification.success({
            title: 'Success',
            description:
              'Batch generated! Please note that the card needs to be republished to reflect the changes.',
          });
          onClose();
        },
        onError: (error) => {
          notification.error({
            title: 'Error',
            description: 'An error occured. Please try again later!',
          });
          captureError(error);
        },
      }
    );

  const onConfirm = (values: PrepaidCardBasicBatch) => {
    const validTo = moment(values.validTo);
    const validFrom = moment(values.validFrom);

    if (validTo < validFrom) {
      setError('The validity range of the batch is invalid');
      return;
    }

    generateBatch(values);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="Generate batch of PINs"
      visible={isVisible}
      onCancel={onCancel}
      onOk={handleSubmit(onConfirm)}
      okText="Generate"
      destroyOnClose
      confirmLoading={isGenerating}
      className={styles.modal}
    >
      <FormGroup
        input={
          <Controller
            placeholder="Enter the batch name"
            as={Input}
            control={control}
            name="name"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Batch name"
        errorMessage={errors.name?.message}
        defaultValue=""
      />

      <FormGroup
        input={
          <Controller
            as={
              <DatePicker
                className={styles.fullWidth}
                placeholder="Select start date"
              />
            }
            control={control}
            name="validFrom"
            rules={{ required: 'Please provide correct data' }}
            defaultValue=""
          />
        }
        label="Valid from"
        errorMessage={errors.validFrom?.message}
        defaultValue=""
      />

      <FormGroup
        input={
          <Controller
            as={
              <DatePicker
                className={styles.fullWidth}
                placeholder="Select end date"
              />
            }
            control={control}
            name="validTo"
            rules={{ required: 'Please provide correct data' }}
            defaultValue=""
          />
        }
        label="Valid to"
        errorMessage={errors.validTo?.message}
        defaultValue=""
      />

      <FormGroup
        input={
          <Controller
            placeholder="Enter the number of PINs"
            as={<InputNumber className={styles.fullWidth} precision={0} />}
            type="number"
            control={control}
            name="count"
            rules={{ required: 'This field is required' }}
            className={styles.fullWidth}
          />
        }
        label="Number of PINs"
        errorMessage={errors.count?.message}
        defaultValue={1}
      />

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Modal>
  );
};

export default PrepaidCardsBatchGenerate;
