import React from 'react';
import { Menu as MenuAntd } from 'antd';
import { Link } from 'react-router-dom';
import { Paths } from 'types/paths';
import styles from '../../index.module.scss';

const SeoSidebar = () => (
  <MenuAntd
    mode="inline"
    className={styles.sidebar}
    defaultSelectedKeys={['sitemap']}
  >
    <MenuAntd.Item key="sitemap">
      <Link to={Paths.SEO}>Sitemap</Link>
    </MenuAntd.Item>
  </MenuAntd>
);

export default SeoSidebar;
