import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';

interface AuthenticatedRouteProps extends RouteProps {
  permissions: Permission[];
  redirectTo?: string;
  children?: React.ReactNode;
}

export default function AuthenticatedRoute({
  permissions,
  redirectTo = '/',
  children,
  ...rest
}: AuthenticatedRouteProps) {
  const { canUse } = usePermission();
  return canUse(permissions) ? (
    <Route {...rest} render={() => children} />
  ) : (
    <Redirect to={redirectTo} />
  );
}
