import { Metadata } from 'types';
import { Page } from 'types/pages';

export enum MenuItemType {
  CUSTOM_PAGE = 'CUSTOM_PAGE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

export enum MenuType {
  WEB = 'WEB',
  TV = 'TV',
  MOBILE = 'MOBILE',
}

export type MenuItemData = CustomPageItem | ExternalURLItem;

export interface MenuLink {
  metadata?: { [key: string]: Metadata };
  type: MenuItemType;
  uuid: string;
  name: string;
  order: number;
  url?: string;
  interests?: string[];
}

export interface MenuResponse {
  name: string;
  uuid?: string;
  regions: string[];
  views: { platform: string; links?: MenuLink[] }[];
}

export interface Menu {
  name: string;
  uuid?: string;
  regions: string[];
  platforms: {
    [key: string]: MenuLink[];
  };
}

export interface CustomPageItem {
  types: MenuType[];
  page: Page;
  type: MenuItemType;
  metadata?: { [key: string]: Metadata };
  uuid: string;
  interests?: string[];
}

export interface ExternalURLItem {
  type: MenuItemType;
  url: string;
  types: MenuType[];
  metadata: { [key: string]: Metadata };
  uuid: string;
  interests?: string[];
}

export interface UrlTranslation extends Metadata {
  langCode: string;
}
