import api from 'actions/api';
import { PlatformAvailableLanguages, PlatformLanguage } from './types';

export const getAllLanguages = async (): Promise<PlatformLanguage[]> => {
  const { data } = await api().get(`/platform-languages/available`);
  return data;
};

export const getPlatformLanguages =
  async (): Promise<PlatformAvailableLanguages> => {
    const { data } = await api().get(`/platform-languages`);
    return data;
  };

export const updatePlatformLanguages = async (
  data: string[]
): Promise<PlatformAvailableLanguages> =>
  await api().put(`/platform-languages`, { availableLanguagesIsoCodes: data });
