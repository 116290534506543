import {
  useFetchActionObj,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import {
  getFeaturedAssetDetails,
  publishFeaturedAsset,
  unpublishFeaturedAsset,
  updateFeaturedAsset,
} from 'actions/featuredAsset';
import { useCallback } from 'react';
import { Asset } from 'types';
import {
  FEATURE_ASSETS_OVERLAPPING_ERROR_CODE,
  FEATURE_ASSETS_SOURCE_NOT_IN_REGION_ERROR_CODE,
  FeaturedAsset,
  FeaturedAssetEditPayload,
  FeaturedAssetSource,
} from 'types/featuredAsset';
import { captureErrorAndNotify } from 'utils/captureError';
import notification from 'utils/notification';

interface UseFeaturedAssetActionsProps {
  uuid: string;
}

export const useFeaturedAssetActions = ({
  uuid,
}: UseFeaturedAssetActionsProps) => {
  const {
    data: featuredAsset,
    isLoading: isLoadingFeaturedAsset,
    refresh,
  } = useFetchActionObj(
    useCallback(() => getFeaturedAssetDetails(uuid), [uuid])
  );

  const handleError = (error: any) => {
    const errorCode = error?.response?.data?.code;
    let description;

    if (errorCode === FEATURE_ASSETS_OVERLAPPING_ERROR_CODE) {
      description =
        'Featured asset cannot be saved as the queue contains overlapping items.';
    }

    if (errorCode === FEATURE_ASSETS_SOURCE_NOT_IN_REGION_ERROR_CODE) {
      description =
        'Featured asset cannot be saved as configuration contains assets that are not published in selected regions.';
    }

    captureErrorAndNotify(error, description);
  };

  const { sendAction: unpublish, isLoading: isUnpublishing } = useSendActionObj<
    void,
    void
  >(async () => await unpublishFeaturedAsset(uuid), {
    onDone: () => {
      notification.success({
        title: 'Success',
        description: 'Featured asset has been unpublished!',
      });

      refresh();
    },
    onError: handleError,
  });

  const { sendAction: update, isLoading: isUpdating } = useSendActionObj<
    FeaturedAsset,
    FeaturedAssetEditPayload
  >(
    async (featuredAsset: FeaturedAssetEditPayload) =>
      await updateFeaturedAsset(featuredAsset),
    {
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'Featured asset has been saved!',
        });

        refresh();
      },
      onError: handleError,
    }
  );

  const { sendAction: saveAndPublish, isLoading: isSavingAndPublishing } =
    useSendActionObj<void, FeaturedAssetEditPayload>(
      async (featuredAsset: FeaturedAssetEditPayload) => {
        await updateFeaturedAsset(featuredAsset);
        return await publishFeaturedAsset(uuid);
      },
      {
        onDone: () => {
          notification.success({
            title: 'Success',
            description: 'Featured asset has been saved and published!',
          });

          refresh();
        },
        onError: handleError,
      }
    );

  return {
    featuredAsset,
    isLoadingFeaturedAsset,
    saveAndPublish,
    unpublish,
    isSavingAndPublishing,
    isUnpublishing,
    update,
    isUpdating,
  };
};

export const mapAssetsToQueueTableItems: (
  assets: Asset[]
) => FeaturedAssetSource[] = (assets) =>
  assets.map((a) => ({
    sourceId: a.uuid,
    name: a.name,
    dateFrom: null,
    dateTo: null,
  }));

export const mapQueuedAssetsToUuids = (assets?: FeaturedAssetSource[]) =>
  assets?.map((a) => a.sourceId) || [];
