import React, { useEffect, useMemo } from 'react';
import { AvodConfigBody, AvodConfiguration } from 'types';
import { Radio, Switch } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, UseFormMethods } from 'react-hook-form';
import styles from 'pages/Avod/components/AvodDetails/index.module.scss';
import AssetSearchableFormTable from 'components/AssetSearchableFormTable';
import { AvodForm } from 'pages/Avod/index';

interface AvodDetailsProps {
  adsConfig?: AvodConfigBody[];
  regionUuid?: string;
  form: UseFormMethods<AvodForm>;
}

const AvodDetails = ({ adsConfig, regionUuid, form }: AvodDetailsProps) => {
  const { watch, setValue, reset, control } = form;
  const adConfig = useMemo(
    () => adsConfig?.find((c) => c.regionUuid === regionUuid),
    [adsConfig, regionUuid]
  );

  useEffect(() => {
    if (!adConfig || !regionUuid) return;
    reset({
      assets: [],
      displayAds: adConfig.configuration !== AvodConfiguration.NONE,
      configuration: adConfig.configuration || AvodConfiguration.NONE,
    });
    return () => {
      reset({
        assets: [],
        displayAds: false,
      });
    };
  }, [adConfig, regionUuid, reset]);

  const displayAdsHandler = (value: boolean) => {
    setValue('displayAds', value);
    setValue(
      'configuration',
      value ? AvodConfiguration.ALL_FREE : AvodConfiguration.NONE
    );
  };

  return (
    <div className={styles.root}>
      <FormGroup
        input={
          <Controller
            control={control}
            name="displayAds"
            render={({ value }) => (
              <Switch checked={value} onChange={displayAdsHandler} />
            )}
          />
        }
        label="Display ads in free assets"
      />
      {watch('displayAds') && (
        <div className={styles.configWrapper}>
          <div className={styles.formWrapper}>
            <FormGroup
              input={
                <Controller
                  name="configuration"
                  control={control}
                  defaultValue={AvodConfiguration.ALL_FREE}
                  render={({ value, onChange }) => (
                    <Radio.Group
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      className={styles.radioForm}
                    >
                      <Radio value={AvodConfiguration.ALL_FREE}>
                        All free assets
                      </Radio>
                      <Radio value={AvodConfiguration.SELECTED}>
                        Selected free assets
                      </Radio>
                    </Radio.Group>
                  )}
                />
              }
              label="Display in"
            />
          </div>
          {watch('configuration') === AvodConfiguration.SELECTED && (
            <AssetSearchableFormTable
              assignedAssetUuids={adConfig?.assetUuidList}
              regionUuid={regionUuid}
              form={form}
              selectedAssets={watch('assets')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AvodDetails;
