import axios, { AxiosError, AxiosInstance } from 'axios';
import { getAuth } from 'firebase/auth';
import { BACKEND_URL } from 'utils/constants';
import qs from 'qs';

let _api: AxiosInstance;

export const api = () => {
  if (_api) {
    return _api;
  }

  const auth = getAuth();

  const instance = axios.create({
    baseURL: BACKEND_URL,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'comma',
        skipNulls: true,
      }),
  });

  instance.interceptors.request.use(
    async (config) => {
      if (auth.currentUser) {
        const token = await auth.currentUser?.getIdToken();

        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => {
      console.error('Interceptor: ', error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: any | AxiosError) => {
      if (error.isAxiosError) {
        const { response } = error as AxiosError;
        if (
          response?.status === 401 &&
          response.data.code === 'auth/unauthorized'
        ) {
          auth.signOut();
        }
      }
      return Promise.reject(error);
    }
  );

  _api = instance;
  return instance;
};

export default api;
