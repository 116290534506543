import React, { useCallback, useState } from 'react';
import { ContainerOutlined, InfoCircleOutlined } from '@ant-design/icons';
import PageDetailsHeader from 'components/PageDetailsHeader';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { RouteIdParams } from 'utils/routeParams';
import { Layout, Menu, Tag } from 'antd';
import { useActiveMenuKey } from 'hooks/activeMenuKeyHook';
import InnerSider from 'components/InnerSider';
import PromotionDetails from 'pages/Promotions/components/PromotionDetails';
import { getPromotion } from 'actions/promotions';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { PromotionStatus } from 'types/promotions';
import { Paths } from 'types/paths';
import CouponsTable from '../CouponsSection/CouponsTable';
import styles from '../index.module.scss';

const AffiliateDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const menuKey = useActiveMenuKey(3, '/details');

  const [promotion, , , refresh] = useFetchAction(
    useCallback(() => getPromotion({ uuid: id }), [id])
  );
  const [pageHeader, setPageHeader] = useState<string>(promotion?.name ?? '');

  return (
    <>
      <PageDetailsHeader>
        {pageHeader}
        {promotion?.status === PromotionStatus.PUBLISHED && (
          <div className={styles.tag}>
            <Tag color="green">{promotion?.status}</Tag>
          </div>
        )}
      </PageDetailsHeader>
      <Layout>
        <InnerSider menuKey={menuKey}>
          <Menu.Item key="/details" icon={<InfoCircleOutlined />}>
            <Link to={`${Paths.AFFILIATE}/${id}`}>Details</Link>
          </Menu.Item>
          <Menu.Item key="/coupons" icon={<ContainerOutlined />}>
            <Link to={`${Paths.AFFILIATE}/${id}/coupons`}>Coupons</Link>
          </Menu.Item>
        </InnerSider>

        <Switch>
          <Route exact path={`${Paths.AFFILIATE}/:id`}>
            <PromotionDetails
              refresh={refresh}
              setPageHeader={setPageHeader}
              promotion={promotion}
            />
          </Route>
          <Route exact path={`${Paths.AFFILIATE}/:id/coupons`}>
            <CouponsTable promotion={promotion} />
          </Route>

          <Route path={`${Paths.AFFILIATE}/:id/coupons/page/:page`}>
            <CouponsTable promotion={promotion} />
          </Route>
        </Switch>
      </Layout>
    </>
  );
};

export default AffiliateDetails;
