import { ExclamationCircleOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { errorNotify } from 'utils/errorNotify';
import notification from 'utils/notification';
import styles from './index.module.scss';

interface UserClientsSignOutConfirmationProps {
  email?: string;
  userUuid: string;
  closeModal: () => void;
  signOutUser: (userUuid: string) => Promise<void>;
}
const UserClientsSignOutConfirmation = ({
  email = '',
  userUuid,
  closeModal,
  signOutUser,
}: UserClientsSignOutConfirmationProps) => {
  const { sendAction: signOutUserAction, isLoading } = useSendActionObj<
    void,
    any
  >(signOutUser, {
    onDone: () => {
      notification.success({
        description: `${email} has been signed out on all devices.`,
      });
      closeModal();
    },
    onError: (error) => {
      errorNotify(error);
    },
  });
  return (
    <div>
      <h2>
        <ExclamationCircleOutlined
          className={styles.icon}
          width={22}
          height={22}
        />
        Sign out user on all devices
      </h2>
      <div className={styles.contentWrapper}>
        <p>
          Are you sure you want to sign out user <strong>{email}</strong> on all
          devices?
        </p>
        <p>
          The sign out may take a few minutes to fully take effect across all
          devices.
        </p>

        <div className={styles.buttons}>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            type="primary"
            danger
            onClick={() => signOutUserAction(userUuid)}
            loading={isLoading}
          >
            Sign out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserClientsSignOutConfirmation;
