import React, { useCallback, useState } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import { Asset, AvodConfigBody, AvodConfiguration, Region } from 'types';
import {
  useFetchActionObj,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { getAvodConfiguration, updateAvodConfiguration } from 'actions/avod';
import AvodDetails from 'pages/Avod/components/AvodDetails';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import RegionsMenu from 'components/RegionsMenu';
import notification from 'utils/notification';
import styles from './index.module.scss';

export type AvodForm = Omit<AvodConfigBody, 'assetUuidList'> & {
  displayAds: boolean;
  assets: Asset[];
};

const Avod = () => {
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const {
    data: config,
    refresh,
    isLoading: isFetching,
  } = useFetchActionObj(getAvodConfiguration);
  const { sendAction, isLoading } = useSendActionObj<void, AvodConfigBody>(
    useCallback(updateAvodConfiguration, []),
    {
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'Configuration has been saved correctly!',
        });

        refresh();
      },
      onError: (error: any) => {
        notification.error({
          title: 'Error',
          description:
            error?.response?.data?.details ||
            'There was an error during the request, please check provided values or try again later.',
        });
      },
    }
  );
  const form = useForm<AvodForm>({
    defaultValues: {
      displayAds: false,
      assets: [],
      configuration: AvodConfiguration.ALL_FREE,
    },
  });

  const onSubmit = useCallback(async () => {
    const { configuration, assets } = form.getValues();
    if (!selectedRegion?.uuid) return;
    const findConfig = config?.data?.find(
      ({ regionUuid }) => regionUuid === selectedRegion.uuid
    );
    const selectedAssets = () => {
      return assets?.map(({ uuid }) => uuid);
    };
    const payload = {
      uuid: findConfig?.uuid || uuidv4(),
      regionUuid: selectedRegion.uuid,
      configuration: configuration || AvodConfiguration.NONE,
      assetUuidList: selectedAssets(),
    };
    sendAction(payload);
  }, [config?.data, form, selectedRegion, sendAction]);

  return (
    <div className={styles.root}>
      <RegionsMenu
        defaultKey={selectedRegion?.uuid}
        onRegionSelect={setSelectedRegion}
        searchClassName={styles.search}
      />

      <PageContainer isLoading={isFetching || isLoading}>
        <PageHeader
          title={selectedRegion?.name || 'Region name not found'}
          extra={[
            <Button type="primary" icon={<GlobalOutlined />} onClick={onSubmit}>
              Publish
            </Button>,
          ]}
        />

        <div className={styles.wrapper}>
          <AvodDetails
            adsConfig={config?.data}
            regionUuid={selectedRegion?.uuid}
            form={form}
            key={selectedRegion?.uuid} //Ensures correct re-render when changing region
          />
        </div>
      </PageContainer>
    </div>
  );
};

export default Avod;
