import { Input } from 'antd';
import FormGroup from 'components/FormGroup';
import RegionSelector from 'components/RegionSelector';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BasicContentDistributionConfiguration } from 'types/contentDistribution';

const ContentDistributionConfigurationBasicCreate = () => {
  const { control, errors } =
    useFormContext<BasicContentDistributionConfiguration>();

  return (
    <>
      <FormGroup
        input={
          <Controller
            placeholder="Enter the configuration name"
            as={Input}
            control={control}
            name="name"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Name"
        errorMessage={errors?.name?.message}
        defaultValue=""
      />

      <FormGroup
        input={
          <Controller
            render={(field) => (
              <RegionSelector
                onChoose={(region) => field.onChange(region.uuid)}
                activeRegionUuid={field.value}
                allowClear={false}
              />
            )}
            control={control}
            name="regionUuid"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Region"
        errorMessage={errors?.regionUuid?.message}
        defaultValue=""
      />
    </>
  );
};

export default ContentDistributionConfigurationBasicCreate;
