import React, { useState } from 'react';
import { Divider, Menu, Table } from 'antd';
import moment from 'moment';
import {
  DashboardLastUpdatedAssets,
  DashboardLastUpdatedTranscoded,
} from 'types/dashboard';
import styles from './index.module.scss';

const AssetColumns = [
  {
    title: '#',
    dataIndex: 'number',
    width: '15%',
  },
  {
    title: 'Asset',
    dataIndex: 'asset',
    ellipsis: true,
    width: '60%',
  },
  {
    title: 'Date',
    dataIndex: 'updatedAt',
    ellipsis: true,
    width: '25%',
  },
];

const ContentColumns = [
  {
    title: '#',
    dataIndex: 'number',
  },
  {
    title: 'Content',
    dataIndex: 'content',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
  },
];

interface DashboardTableProps {
  dataAssets?: DashboardLastUpdatedAssets[];

  dataContent?: DashboardLastUpdatedTranscoded[];
}

//bottom section dashboard
const DashboardTable = ({ dataAssets, dataContent }: DashboardTableProps) => {
  const [assetType, setAssetType] = useState('assets');
  const [currentPage, setCurrentPage] = useState(1);

  const handleAssetTypeChange = (event: any) => {
    setAssetType(event.key);
    setCurrentPage(1);
  };

  const assetsData = dataAssets?.map((asset, index) => {
    return {
      number: index + 1,
      asset: asset.name,
      updatedAt: moment(asset.updatedAt).format('DD-MM-YYYY'),
    };
  });

  const contentData = dataContent?.map((content, index) => {
    return {
      number: index + 1,
      content: content.name,
      createdAt: moment(content.createdAt).format('DD-MM-YYYY'),
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h1 className={styles.header}>Last updated</h1>
        <Menu
          mode="horizontal"
          className={styles.typeMenu}
          defaultSelectedKeys={[assetType]}
          onClick={handleAssetTypeChange}
        >
          <Menu.Item key="assets">Assets</Menu.Item>
          <Menu.Item key="content">Content</Menu.Item>
        </Menu>
      </div>

      <Divider className={styles.mt0} />

      {assetType === 'assets' ? (
        <Table
          className={styles.table}
          size="middle"
          dataSource={assetsData}
          columns={AssetColumns}
          pagination={{
            position: ['bottomCenter'],
            pageSize: 5,
            onChange: (page) => setCurrentPage(page),
            current: currentPage,
          }}
        />
      ) : (
        <Table
          className={styles.table}
          size="middle"
          dataSource={contentData}
          columns={ContentColumns}
          pagination={{
            position: ['bottomCenter'],
            pageSize: 5,
            onChange: (page) => setCurrentPage(page),
            current: currentPage,
          }}
        />
      )}
    </div>
  );
};

export default DashboardTable;
