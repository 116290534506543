import { Language } from '@laminar-product/client-commons-core/core';

export interface PlatformLanguagesByIsoCodeParams {
  isoCodes?: string[];
  languages?: Language[];
}

export const getPlatformLanguagesByIsoCode = ({
  isoCodes,
  languages,
}: PlatformLanguagesByIsoCodeParams) =>
  languages?.filter((lang) => isoCodes?.some((code) => code === lang.alpha2));
