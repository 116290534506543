import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import Button from 'components/Button';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LiveStreamInfrastructure, LiveStreamProfile } from 'types/live';
import { createLiveStream } from 'actions/live';
import notification from 'utils/notification';
import AdvancedRadioElement from 'components/AdvancedRadioElement';
import { AssetType } from '@laminar-product/client-commons-core/core';
import styles from './StreamInfrastructureCreate.module.scss';

interface StreamInfrastructureCreateProps {
  onCreated?: (details: LiveStreamInfrastructure) => void;
  profiles?: LiveStreamProfile[];
  assetId: number;
  assetType: AssetType;
}

const StreamInfrastructureCreate = ({
  onCreated,
  profiles,
  assetId,
  assetType,
}: StreamInfrastructureCreateProps) => {
  const [selectedProfileUuid, setSelectedProfileUuid] = useState('');
  const limitReached =
    assetType === AssetType.LIVE_EVENT
      ? profiles?.every(
          ({ eventsLimit, eventsCount }) => eventsLimit <= eventsCount
        )
      : profiles?.every(
          ({ channelsLimit, channelsCount }) => channelsLimit <= channelsCount
        );
  const openConfirmationModal = () => {
    const profile = profiles?.find(
      (profile) => profile.uuid === selectedProfileUuid
    );

    if (!profile) return;

    const canCreate =
      assetType === AssetType.LIVE_EVENT
        ? profile.eventsCount <= profile.eventsLimit
        : profile.channelsCount <= profile.channelsLimit;

    if (!canCreate) {
      notification.error({ description: 'You can not create more streams' });
      return;
    }

    return Modal.confirm({
      title: 'Are you sure you want to create a new Stream Infrastructure?',
      onOk: create,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
    });
  };

  const create = async () => {
    try {
      const details = await createLiveStream(assetId, selectedProfileUuid);
      onCreated?.(details);
    } catch (e) {
      notification.error({
        title: 'Something went wrong',
        description: 'Failed to create Stream Infrastructure',
      });
    }
  };

  useEffect(() => {
    if (!profiles || !profiles.length) return;

    const availablePlanUuid =
      profiles.find(
        ({ eventsLimit, eventsCount, channelsLimit, channelsCount }) =>
          assetType === AssetType.LIVE_EVENT
            ? eventsCount < eventsLimit
            : channelsCount < channelsLimit
      )?.uuid || '';

    setSelectedProfileUuid(availablePlanUuid);
  }, [assetType, profiles]);

  if (!profiles?.length)
    return <>There are no added profiles. Please contact with Support.</>;

  return (
    <>
      <div className={styles.root}>
        {profiles?.map(
          ({
            name,
            description,
            uuid,
            isActive,
            channelsLimit,
            channelsCount,
            eventsLimit,
            eventsCount,
          }) => {
            if (!isActive) return null;
            const limitReached =
              assetType === AssetType.LIVE_EVENT
                ? eventsLimit <= eventsCount
                : channelsLimit <= channelsCount;
            const limit =
              assetType === AssetType.LIVE_EVENT ? eventsLimit : channelsLimit;
            return (
              <AdvancedRadioElement
                value={uuid}
                onSelect={setSelectedProfileUuid}
                selected={selectedProfileUuid}
                title={name}
                description={description}
                className={styles.radio}
                extra={
                  limitReached && (
                    <div className={styles.streamInfo}>
                      <div className={styles.wrapper}>
                        <InfoCircleOutlined />
                        <div className={styles.info}>
                          You have reached the limit of {limit} active channel
                          infrastructures for {name} profile.
                        </div>
                      </div>
                    </div>
                  )
                }
                disabled={limitReached}
              />
            );
          }
        )}
      </div>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={openConfirmationModal}
        disabled={limitReached}
      >
        Create
      </Button>
    </>
  );
};

export default StreamInfrastructureCreate;
