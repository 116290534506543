import React, { useCallback, useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import { getAssetCrew, getCrewTypes, saveAssetCrew } from 'actions/crew';
import { Button, Table, Typography } from 'antd';
import {
  DragHandle,
  HideOnDrag,
  createDraggableBodyRow,
  createDraggableContainer,
} from 'components/DraggableTableItems';
import ErrorIndicator from 'components/ErrorIndicator';
import LoadingIndicator from 'components/LoadingIndicator';
import uniqueId from 'lodash/uniqueId';
import { AssetCrew } from 'types';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import formatLabel from 'utils/formatLabel';
import { useAssetDetailsContext } from 'contexts/assetContext';
import AssetCrewSuggestions from './AssetCrewSuggestions';
import CrewAdder from './CrewAdder';
import styles from './index.module.scss';

const { Text } = Typography;
const { Column } = Table;

// After download we will mark each member of the crew with a temporary unique id
// this will help with array modifications
interface MarkedAssetCrew extends AssetCrew {
  id: string;
}

const Crew = () => {
  const {
    asset: { id },
  } = useAssetDetailsContext();
  const [crewTypes, isLoadingTypes] = useFetchAction(getCrewTypes);

  const [isTouched, setTouched] = useState<boolean>(false);
  const [downloadedCrew, isLoading, fetchError, refresh] = useFetchAction(
    useCallback(() => getAssetCrew({ id }), [id])
  );

  const [crew, setCrew] = useState<MarkedAssetCrew[]>([]);

  // Derrive mutable crew from downloaded one
  useEffect(() => {
    if (downloadedCrew) {
      setTouched(false);
      setCrew(downloadedCrew.map((c) => ({ ...c, id: uniqueId() })));
    }
  }, [downloadedCrew]);

  const [save, isSaving, , , saveError] = useSendAction<void, AssetCrew[]>(
    (crew) =>
      saveAssetCrew({
        id,
        crew: crew.map((c, index) => ({ ...c, order: index })),
      }),
    {
      onDone() {
        notifyAboutRepublishRequirement();
        refresh();
      },
    }
  );

  if (isLoadingTypes) {
    return <LoadingIndicator />;
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;

    const item = crew[oldIndex];

    // Remove old item
    const newArray = crew?.filter((_, index) => index !== oldIndex);

    // Place item back (warning: this mutates the array)
    newArray?.splice(newIndex, 0, item!);

    setCrew(newArray);
    setTouched(true);
  };

  return (
    <div>
      <ErrorIndicator error={fetchError} />
      {crewTypes?.map((type) => (
        <div key={type} className={styles.crewType}>
          <Text strong className={styles.crewTypeName}>
            {formatLabel(type)}
          </Text>
          <Table
            dataSource={crew?.filter((c) => c.type === type)}
            rowKey="id"
            pagination={false}
            showHeader={false}
            loading={isLoading}
            components={{
              body: {
                wrapper: createDraggableContainer(onSortEnd),
                row: createDraggableBodyRow((props) =>
                  crew?.findIndex((c) => c.id === props['data-row-key'])
                ),
              },
            }}
          >
            <Column
              width={30}
              title="Sort"
              dataIndex="sort"
              render={() => <DragHandle />}
            />
            <Column title="Name" dataIndex="name" key="name" />
            <Column
              width={40}
              title="Action"
              dataIndex="action"
              render={(_, crewMember: MarkedAssetCrew) => (
                <HideOnDrag>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCrew(crew.filter((c) => c.id !== crewMember.id));
                      setTouched(true);
                    }}
                  />
                </HideOnDrag>
              )}
            />
          </Table>
          <CrewAdder
            onAdd={(names) => {
              const newCrew = names.map((name, i) => ({
                name,
                type,
                order: i,
                id: uniqueId(),
              }));

              setCrew([...crew, ...newCrew]);
              setTouched(true);
            }}
          />
          {type === 'CAST' && (
            <AssetCrewSuggestions
              celebritiesToOmit={crew}
              onClick={(name: string) => {
                setCrew([
                  ...crew,
                  {
                    name,
                    type: 'CAST',
                    order: crew.length,
                    id: uniqueId(),
                  },
                ]);
                setTouched(true);
              }}
            />
          )}
        </div>
      ))}
      <Button
        type="primary"
        loading={isSaving}
        onClick={() => save(crew)}
        disabled={!isTouched}
        className={styles.saveButton}
      >
        Save
      </Button>
      <ErrorIndicator error={saveError} />
    </div>
  );
};

export default Crew;
