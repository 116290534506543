import { Modal } from 'antd';
import React from 'react';
import { Asset } from 'types';

interface AssetPublishingModalProps {
  isVisible?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  type?: 'publish' | 'unpublish';
  asset: Asset;
}

const AssetPublishingModal = ({
  onClose,
  onConfirm,
  isLoading,
  isVisible,
  type = 'publish',
  asset,
}: AssetPublishingModalProps) => {
  return (
    <Modal
      title={`Are you sure you want to ${
        type === 'publish' ? 'publish' : 'unpublish'
      }?`}
      visible={isVisible}
      onCancel={onClose}
      onOk={onConfirm}
      confirmLoading={isLoading}
    >
      {type === 'publish' && (
        <div>
          The asset
          <strong> {asset.name} </strong>
          will be visible in every region from the list and all details will be
          updated.
        </div>
      )}

      {type === 'unpublish' && (
        <div>
          The asset
          <strong> {asset.name} </strong>
          will be unpublished in all regions.
        </div>
      )}
    </Modal>
  );
};

export default AssetPublishingModal;
