import FormGroup from 'components/FormGroup';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Input } from 'antd';
import { ExternalURLItem, UrlTranslation } from 'types/menu';
import LanguageChooser from 'components/LanguageChooser';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DEFAULT_TRANSLATION_ENGLISH_CODE } from '../constants';
import styles from './index.module.scss';

interface MenuCustomURLProps {
  urlTranslations: UrlTranslation[];
  onUpdateUrlTranslation: (
    data: Partial<UrlTranslation>,
    index: number
  ) => void;
  onAddEmptyUrlTranslation: () => void;
  onRemoveUrlTranslation: (index: number) => void;
}

const MenuCustomURL = ({
  urlTranslations,
  onUpdateUrlTranslation,
  onAddEmptyUrlTranslation,
  onRemoveUrlTranslation,
}: MenuCustomURLProps) => {
  const { control, errors } = useFormContext<ExternalURLItem>();
  const langCodesToExclude = urlTranslations.map((t) => t.langCode);

  const validateUrl = (string: string) => {
    if (!string.startsWith('http://') && !string.startsWith('https://')) {
      return 'A valid URL should start with https:// or http://';
    }
  };

  return (
    <>
      <FormGroup
        label="URL"
        errorMessage={(errors.url as FieldError)?.message}
        input={
          <Controller
            name="url"
            control={control}
            rules={{
              required: 'Required',
              validate: validateUrl,
            }}
            defaultValue=""
            as={Input}
            placeholder="URL"
          />
        }
      />

      <span>Title</span>

      <div className={styles.translationsWrapper}>
        {urlTranslations.map((translation, index) => (
          <div className={styles.translationItem} key={index}>
            <LanguageChooser
              enableMostUsed={false}
              codesToExclude={langCodesToExclude}
              chosenLanguageCode={translation.langCode}
              onChoose={(langCode) =>
                onUpdateUrlTranslation({ langCode }, index)
              }
              disabled={
                translation.langCode === DEFAULT_TRANSLATION_ENGLISH_CODE
              }
              className={styles.languageChooser}
            />

            <Input
              onChange={(event) =>
                onUpdateUrlTranslation({ title: event.target.value }, index)
              }
              className={styles.titleInput}
              defaultValue=""
            />

            {translation.langCode !== DEFAULT_TRANSLATION_ENGLISH_CODE && (
              <DeleteOutlined
                className={styles.bin}
                onClick={() => onRemoveUrlTranslation(index)}
              />
            )}
          </div>
        ))}
      </div>

      <div
        className={styles.addActionWrapper}
        onClick={onAddEmptyUrlTranslation}
      >
        <PlusCircleOutlined />
        Add language
      </div>
    </>
  );
};

export default MenuCustomURL;
