import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsLoading, getRequestStatus } from 'store/app/selectors';
import { RequestStatuses } from 'store/app/types';
import { fetchRegionsAction, sendRegionAction } from 'store/regions/actions';
import { getError, getRegions } from 'store/regions/selectors';
import { getType } from 'typesafe-actions';

export const useGetRegions = () => {
  const dispatch = useDispatch();
  const regions = useSelector(getRegions);
  const isLoadingRegions = useSelector((state: RootState) =>
    getIsLoading(state, getType(fetchRegionsAction.request))
  );
  const error = useSelector((state: RootState) =>
    getError(state, getType(fetchRegionsAction.failure))
  );
  const requestStatus = useSelector((state: RootState) =>
    getRequestStatus(state, getType(fetchRegionsAction.request))
  );

  useEffect(() => {
    if (!regions && requestStatus !== RequestStatuses.RESOLVED) {
      dispatch(fetchRegionsAction.request());
    }
  }, [dispatch, regions, requestStatus]);

  return { regions, isLoadingRegions, error };
};

export const useSendRegion = () => {
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, getType(sendRegionAction.request))
  );
  const error = useSelector((state: RootState) =>
    getError(state, getType(sendRegionAction.failure))
  );

  return { isLoading, error };
};
