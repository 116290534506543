import { Button } from 'antd';
import PlanChooser from 'components/PlanChooser';
import React, { useState } from 'react';
import { BasicPublishedPlan } from 'types/plan';
import styles from '../index.module.scss';

interface PrepaidCardsTablePlanFilterProps {
  onSelect: (planUuid?: string) => void;
}

const PrepaidCardsTablePlanFilter = ({
  onSelect,
}: PrepaidCardsTablePlanFilterProps) => {
  const [selectedPlan, setSelectedPlan] = useState<BasicPublishedPlan>();
  const onConfirm = () => onSelect(selectedPlan?.uuid);

  const onReset = () => {
    setSelectedPlan(undefined);
    onSelect('');
  };

  return (
    <div className={styles.filterWrapper}>
      <PlanChooser
        onChoose={setSelectedPlan}
        chosenPlanId={selectedPlan?.uuid}
      />

      <div className={styles.buttonsWrapper}>
        <Button onClick={onReset}>Reset</Button>
        <Button
          onClick={onConfirm}
          className={styles.confirmButton}
          type="primary"
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default PrepaidCardsTablePlanFilter;
