import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormContainer from 'components/FormContainer';
import { useGetRegions } from 'hooks/useRegions';
import { AdDriven, Name, Region, Travel } from 'pages/Plans/PlanFormFields';
import { PlanForm } from 'types/plan';
import { PlanDetailsPageProps } from '..';
import InterestsPlan from './InterestsPlan';

const Basic = ({ options, currentPlanRegionUuid }: PlanDetailsPageProps) => {
  const { regions } = useGetRegions();
  const { control, errors, setValue, getValues, watch } =
    useFormContext<PlanForm>();
  const formFieldProps = { control, errors, options };
  const travelRegions = regions?.filter(
    (region) => region?.uuid !== currentPlanRegionUuid
  );

  useEffect(() => {
    if (currentPlanRegionUuid) {
      const selectedTravelPlans = getValues('availableRegionsUuids');
      const filteredTravelPlans = selectedTravelPlans?.filter(
        (id) => id !== currentPlanRegionUuid
      );

      setValue('availableRegionsUuids', filteredTravelPlans);
    }
  }, [currentPlanRegionUuid, setValue, getValues]);

  return (
    <FormContainer>
      <Name {...formFieldProps} />
      <Region {...formFieldProps} regions={regions ? regions : []} />
      <Travel
        {...formFieldProps}
        regions={travelRegions ? travelRegions : []}
      />
      <InterestsPlan {...formFieldProps} />
      {watch('adDriven') !== undefined && <AdDriven {...formFieldProps} />}
    </FormContainer>
  );
};

export default Basic;
