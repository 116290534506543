import React, { useEffect, useState } from 'react';
import { Collection } from 'types';
import { Alert, Form, Radio, Switch } from 'antd';
import FormGroup from 'components/FormGroup';
import cn from 'classnames';
import { Page } from 'types/pages';
import CustomPageChooser from 'components/CustomPageChooser';
import { getPage } from 'actions/pages';
import PageCard from 'components/PageCard';
import styles from '../index.module.scss';
const CollectionLinkTitle = ({
  collection,
  onCollectionChange,
  shouldValidate,
}: {
  collection: Collection;
  onCollectionChange: (collection: Collection) => void;
  shouldValidate: boolean;
}) => {
  const { link } = collection;

  const [error, setError] = useState<string>();
  const [titleLinkDisable, setTitleLinkDisable] = useState<boolean>(!link);
  const [selectedCustomPage, setSelectedCustomPage] = useState<Page>();

  useEffect(() => {
    if (link && link.type === 'CUSTOM_PAGE' && link.uuid) {
      getPage(link.uuid).then((page) => setSelectedCustomPage(page));
    }
  }, [link]);

  useEffect(() => {
    if (
      link?.type === 'CUSTOM_PAGE' &&
      !link.uuid &&
      !titleLinkDisable &&
      shouldValidate
    ) {
      setError('At least one custom page must be selected');
      return;
    }
    setError('');
  }, [titleLinkDisable, shouldValidate, link]);

  return (
    <PageCard
      title="Link title"
      extra={
        <Switch
          className={styles.marginButton}
          checked={!!link}
          onChange={(checked) => {
            setTitleLinkDisable(!checked);
            if (!checked && collection) {
              onCollectionChange({
                ...collection,
                link: undefined,
              });
              return;
            }
            if (collection)
              onCollectionChange({
                ...collection,
                link: { type: 'COLLECTION', uuid: collection.uuid },
              });
          }}
        />
      }
    >
      <Form
        layout="horizontal"
        labelCol={{ span: 6 }}
        className={cn({ [styles.disabled]: titleLinkDisable })}
      >
        <FormGroup
          label="Link to"
          input={
            <Radio.Group
              value={collection.link?.type}
              onChange={(e) => {
                setSelectedCustomPage(undefined);
                if (collection)
                  onCollectionChange({
                    ...collection,
                    link: {
                      type: e.target.value,
                      uuid:
                        e.target.value === 'COLLECTION'
                          ? collection.uuid
                          : undefined,
                    },
                  });
              }}
            >
              <Radio value="COLLECTION">Current Collection</Radio>
              <Radio value="CUSTOM_PAGE">Custom page</Radio>
            </Radio.Group>
          }
        />
        {collection.link?.type === 'CUSTOM_PAGE' && (
          <FormGroup
            label="Select page"
            input={
              <CustomPageChooser
                chosenPage={selectedCustomPage}
                regions={collection.regions}
                onChoose={(page) => {
                  setSelectedCustomPage(page);
                  onCollectionChange({
                    ...collection,
                    link: {
                      type: 'CUSTOM_PAGE',
                      uuid: page.uuid!,
                    },
                  });
                }}
              />
            }
          />
        )}
      </Form>
      {!!error && <Alert message={error} type="error" />}
    </PageCard>
  );
};

export default CollectionLinkTitle;
