import {
  Asset as AssetCore,
  AssetType,
  ImageType,
} from '@laminar-product/client-commons-core/core';
import { PaymentGateway, Plan } from 'types/plan';
import { Moment } from 'moment';
export interface UploadedFile {
  id: number;
  name: string;
  status: FileStatus;
  metadataError: FileMetadataError[] | null;
  details?: VideoFile;
  createdAt?: Date;
  sourceUuid?: string;
  transcodeJobProgress?: TranscodeJobProgress;
  duration?: number;
}

export interface TranscodeJobProgress {
  currentPhase: string;
  jobPercentComplete: number;
  retryCount: number;
}

export enum FileStatus {
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  PROCESSING = 'PROCESSING',
  TRANSCODED = 'TRANSCODED',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  QUALITY_ERROR = 'QUALITY_ERROR',
  QUALITY_WARNING = 'QUALITY_WARNING',
  QUALITY_PASSED = 'QUALITY_PASSED',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export enum SourceStatus {
  QUALITY_PASSED = 'QUALITY_PASSED',
  QUALITY_WARNING = 'QUALITY_WARNING',
  QUALITY_ERROR = 'QUALITY_ERROR',
  PROCESSING = 'PROCESSING',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  TRANSCODED = 'TRANSCODED',
}

export interface FileMetadataError {
  type: string;
  expected: string;
  provided: string;
}

export interface VideoFile {
  video: {
    dash: VideoDetails;
    hls: VideoDetails;
    raw?: string;
  };
  thumbnails?: {
    url: string;
  };
}

export interface VideoDetails {
  url: string;
  type: VideoType;
}

export enum VideoType {
  MPD = 'application/dash+xml',
  M3U8 = 'application/x-mpegURL',
}

type TransformedAsset = Omit<
  AssetCore,
  'id' | 'parentId' | 'children' | 'parent'
>;

export interface Asset extends TransformedAsset {
  id: number;
  uuid: string;
  parentId?: number;
  translations?: {
    [key: string]: AssetMetadata;
  };
  moderations?: string[];
  childrenIds?: number[];
  children?: TransformedAsset[];
  status?: AssetStatus;
  regions?: Region[];
  parent?: Asset;
  availabilityTimezone?: number | null;
}

export interface AssetForm
  extends Omit<Asset, 'availableFrom' | 'availableTo'> {
  availableFrom?: null | Moment;
  availableTo?: null | Moment;
}

export interface AssetChildren {
  id: number;
  name: string;
  parentId: number;
  order: number;
  images: { type: string; url: string }[];
  accessType: AssetAccessType;
  childrenIds: number[];
}

export interface MongoAsset {
  uuid: string;
  order: number;
  uniqueOrderId?: number;
  name?: string;
  type?: AssetType;
}

export interface AssetMetadata {
  name: string;
  description?: string;
  selectedLanguage?: string;
  shortDescription?: string;
}

export interface Metadata {
  title: string;
  description?: string;
  selectedLanguage?: string;
}

export enum AssetStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum AssetAccessType {
  PAID = 'PAID',
  FREE = 'FREE',
}

export type AssetCreateForm = {
  name: string;
  type: AssetType;
  parent?: number;
};

export enum ImageStatus {
  CREATED = 'CREATED',
  TRANSFORMED = 'TRANSFORMED',
  QUALITY_ERROR = 'QUALITY_ERROR',
}

export interface Image {
  id: number;
  type: ImageType;
  url: string;
  status: ImageStatus;
  originalName: string;
}

export interface ImageUploadResponse {
  id: number;
  signedUrl: {
    fields: {
      [key: string]: string;
    };
    url: string;
  };
}

export enum ContentType {
  MAIN = 'MAIN',
  TRAILER = 'TRAILER',
  EXTRA = 'EXTRA',
  LIVE = 'LIVE',
}

export interface Content {
  id: number;
  uuid: string;
  title: string;
  type: ContentType;
  file?: UploadedFile;
  asset?: Asset;
  metadata?: any;
  assetId?: string;
  createdAt?: string;
  description?: string;
  downloadable?: boolean;
}

export type ContentCreateForm = {
  title: string;
  type: string;
};

export interface Channel {
  id: number;
  name: string;
  input: string;
  spareInput: string;
  dashUrl: string;
  state: ChannelState;
}

export enum ChannelState {
  STOPPING = 'STOPPING',
  IDLE = 'IDLE',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  CREATING = 'CREATING',
}

export type ChannelCreateForm = {
  name: string;
};

export interface User {
  id: string;
  email: string;
  roles: string[];
  active: boolean;
  phoneNumber: string | null;
  uid: string;
  subscriptions: Subscription[];
  platformPartnerId?: string;
  platformPartnerName?: string;
  paymentLinks?: PaymentLink[];
}

export interface Subscription {
  active: boolean;
  amount: number;
  currency: Currency;
  interval: IntervalType;
  plan: Plan;
  subscriptionId: string;
  userId: string;
  createdAt?: string;
  nextPaymentDate?: string;
  isCancelationScheduled?: boolean;
  //@TODO: for now I don't know what types we've got here
  trialDuration: any;
  gateway?: PaymentGateway;
  type?: SubscriptionType;
}

export interface PaymentLink {
  _id: string;
  userId: string;
  planUuid: string;
  priceUuid: string;
  reqUuid: string;
  adminUser: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  externalId: string;
  url: string;
  validTill: string;
}

export enum SubscriptionType {
  PREPAID = 'PREPAID',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface UserCreateForm {
  email: string;
  password: string;
  roles: string[];
}

export interface Country {
  code: string;
  regionId: number;
}

export interface RegionCreateForm {
  name: string;
  countries: string[];
}

export interface Region {
  id: number;
  uuid: string;
  name: string;
  countries: Country[];
}

export interface CensorshipRule {
  id: number;
  name: string;
  description: string;
  minAge: number;
}

export type CensorshipRuleCreateForm = Omit<CensorshipRule, 'id'>;

export interface Product {
  id?: number;
  name: string;
  description: string;
  type: ProductType;
  status: string;
  platform: Platform[];
  quality: Quality[];
  screenLimit: number;
  trialPeriodDays?: number;
  prices: Price[];
  regionId: number;
  metadata?: { [key: string]: ProductMetaData };
}

export interface Price {
  active?: boolean;
  currency: Currency;
  interval: IntervalType;
  intervalCount?: number;
  amount: number;
  frequency?: string;
}

export enum Currency {
  USD = 'usd',
  PLN = 'pln',
}

export enum IntervalType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum Quality {
  '540p' = '540',
  '720p' = '720',
  '1080p' = '1080',
  '2160p' = '2160',
}

export enum ProductType {
  FREE = 'AD_FREE',
  PAID = 'PAID',
}

export interface ProductMetaData {
  title: string;
  description?: string;
  selectedLanguage?: string;
}

export enum Platform {
  MOBILE = 'MOBILE',
  SMART_TV = 'SMART_TV',
  TV_BOX = 'TV_BOX',
}

export interface ProductOptions {
  currency: [keyof Currency, Currency][];
  interval: [keyof IntervalType, IntervalType][];
  platform: Platform[];
  type: ProductType[];
  quality: [keyof Quality, Quality][];
}

export enum CollectionStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  CREATED = 'CREATED',
}

export interface CollectionRegion {
  id: number;
  name: string;
  uuid?: string;
}

export interface Collection {
  name: string;
  assets?: Asset[];
  regions?: string[];
  metadata?: { [key: string]: Metadata };
  link?: { type: 'COLLECTION' | 'CUSTOM_PAGE'; uuid?: string };
  filters?: AutoPopulateFilter;
  status?: CollectionStatus;
  linkTitle?: string;
  customPage?: number;
  uuid: string;
  displayRule?: CollectionDisplayRule | null;
}

export enum CollectionDisplayRule {
  RELEASED = 'RELEASED',
  RELEASING = 'RELEASING',
  COMING_SOON = 'COMING_SOON',
}

export type CollectionDisplayRuleForm = 'ALL' | CollectionDisplayRule;

export interface Pagination<T> {
  data: T[];
  count: number;
}

export interface SourceFileAudioTrack {
  streamOrder: number;
  name?: string;
  isDefault: boolean;
  rawLanguage?: string;
  languageCode?: string;
  sourceInputName?: string;
  codingMode?: string;
}

export type SubtitleType = 'EMBEDDED' | 'SRT';

export interface SourceFileSubtitleTrack {
  id: string;
  name: string;
  type: SubtitleType;
  sourceId?: number;
  rawLanguage?: string;
  languageCode?: string;
}

export interface TranscodeAudioTrack {
  isDefault: boolean;
  streamOrder: number;
  name?: string;
  languageCode?: string;
  codingMode?: string;
}

export interface TranscodeSubtitle {
  id: string;
  type: SubtitleType;
  name?: string;
  languageCode?: string;
}

export interface AssetCrew {
  name: string;
  type: string;
  order: number;
}

export interface BreadCrumbsPath {
  assetId: number;
  name: string;
  uuid: string;
}

export interface AssetMainDetails {
  description?: string;
  name?: string;
  type: AssetType;
  year?: number;
}

export interface ContentMainDetails {
  description?: string;
  title?: string;
}

export interface AutoPopulateFilter {
  genres: string[];
  types: string[];
  moods: string[];
  tags: string[];
}

export interface ContentRequest {
  name: string;
  metadata?: { [key: string]: Metadata };
  filters: AutoPopulateFilter;
  assets: { uuid: string; order: number }[];
  regions: string[];
  status: string;
}
export interface CollectionsFilters {
  status?: string[];
  regions?: string[];
}

export enum ApiActionType {
  FETCH = 'FETCH',
  UPDATE = 'EDIT',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export interface StreamingProfile {
  id: string;
  name: string;
  description: string;
  default: boolean;
  active: boolean;
}

export interface AvodConfigResponse {
  data: AvodConfigBody[];
  count: number;
}

export interface AvodConfigBody {
  uuid?: string;
  configuration: AvodConfiguration;
  regionUuid: string;
  assetUuidList: string[];
}

export enum AvodConfiguration {
  ALL_FREE = 'ALL_FREE',
  SELECTED = 'SELECTED',
  NONE = 'NONE',
}
