import { RootState } from 'store';
import { DEFAULT_PLATFORM_LANGUAGE } from './constants';

export const selectAllLanguages = (state: RootState) =>
  state.languages.allLanguages;

export const selectPlatformLanguages = (state: RootState) =>
  state.languages.platformLanguages?.availableLanguages || [];

export const selectDefaultPlatformLanguage = (state: RootState) =>
  state.languages.platformLanguages?.defaultLanguageIsoCode ||
  DEFAULT_PLATFORM_LANGUAGE;

export const selectError = (state: RootState, type: string) =>
  state.languages.error && state.languages.error[type];
