import { Modal } from 'antd';
import React from 'react';
import {
  ContentDistributionConfig,
  ContentDistributionConfigDetails,
} from 'types/contentDistribution';

interface ContentDistributionConfigRemoveProps {
  visible: boolean;
  onClose: () => void;
  config?: ContentDistributionConfigDetails | ContentDistributionConfig;
  onOk: (configUuid: string) => void;
  isLoading?: boolean;
}

const ContentDistributionConfigRemove = ({
  onClose,
  visible,
  config,
  onOk,
  isLoading,
}: ContentDistributionConfigRemoveProps) => {
  if (!config) return null;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={`Are you sure you want to remove ${config.name} configuration?`}
      okText="Yes"
      cancelText="No"
      onOk={() => onOk(config.uuid)}
      confirmLoading={isLoading}
    >
      Please note that all configuration data will be erased and you will not be
      able to undo this action.
    </Modal>
  );
};

export default ContentDistributionConfigRemove;
