import { Input } from 'antd';
import Space from 'components/Space';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { TranscodeSubtitle } from 'types';
import LanguageChooser from 'components/LanguageChooser';
import { LanguageType } from 'types/translations';
import { languages } from '@laminar-product/client-commons-core/core';

export interface SubtitleTrackFormProps {
  transformedSourceTracks: TranscodeSubtitle[];
  control: Control;
  errors: DeepMap<
    {
      subtitles: TranscodeSubtitle[];
    },
    FieldError
  >;
}

const SubtitleTrackForm = ({
  transformedSourceTracks,
  control,
  errors,
}: SubtitleTrackFormProps) => (
  <Space direction="vertical" expand>
    {transformedSourceTracks.map((subtitleTrack, index) => (
      <div key={subtitleTrack.name}>
        <div>Track {index + 1}</div>
        <Input.Group compact>
          {/* Instead of manually adding and removing fields we can just put empty controllers to do this for us */}
          <Controller
            control={control}
            as={() => null}
            name={`subtitles[${index}].id`}
          />
          <Controller
            control={control}
            as={() => null}
            name={`subtitles[${index}].type`}
          />
          <Controller
            render={(field) => (
              <LanguageChooser
                chosenLanguageCode={field.value}
                onChoose={field.onChange}
                enableMostUsed
                showSearch
                type={LanguageType.ALPHA_3}
                overrideLanguageList={languages}
              />
            )}
            control={control}
            name={`subtitles[${index}].languageCode`}
            rules={{ required: true }}
            data-testid={`SubtitleTrackForm__languageCode_selector--${index}`}
            defaultValue=""
          />
          <Controller
            as={Input}
            control={control}
            name={`subtitles[${index}].name`}
            placeholder="Track name"
            style={{ width: 'calc(100% - 150px)' }}
            rules={{ required: true }}
            data-testid={`SubtitleTrackForm__name_input--${index}`}
          />
          {errors.subtitles && errors.subtitles[index]?.name && (
            <div>Name is required</div>
          )}
          {errors.subtitles && errors.subtitles[index]?.languageCode && (
            <div>Language is required</div>
          )}
        </Input.Group>
      </div>
    ))}
  </Space>
);

export default SubtitleTrackForm;
