import { ApiActionType } from 'types';
import { ArchiveDictionary, SendDictionaryForm } from 'types/dictionaries';
import { createAsyncAction } from 'typesafe-actions';
import { DictionaryType } from './types';

export interface FetchDictionaryRequest {
  dictionaryType: DictionaryType;
  query?: string;
}

interface FetchDictionarySuccess {
  data: string[];
  dictionaryType: DictionaryType;
}

interface DictionaryActionFailure {
  error: Error;
  dictionaryId: string;
}

interface SendDictionarySuccess {
  name: string;
  dictionaryType: DictionaryType;
  actionType: ApiActionType;
}

export const fetchDictionaryAction = createAsyncAction(
  'FETCH_DICTIONARY_REQUEST',
  'FETCH_DICTIONARY_SUCCESS',
  'FETCH_DICTIONARY_FAILURE'
)<FetchDictionaryRequest, FetchDictionarySuccess, DictionaryActionFailure>();

export const sendDictionaryAction = createAsyncAction(
  'SEND_DICTIONARY_REQUEST',
  'SEND_DICTIONARY_SUCCESS',
  'SEND_DICTIONARY_FAILURE'
)<SendDictionaryForm, SendDictionarySuccess, DictionaryActionFailure>();

export const archiveDictionaryAction = createAsyncAction(
  'ARCHIVE_DICTIONARY_REQUEST',
  'ARCHIVE_DICTIONARY_SUCCESS',
  'ARCHIVE_DICTIONARY_FAILURE'
)<ArchiveDictionary, ArchiveDictionary, DictionaryActionFailure>();
