import { Moment } from 'moment';
import { Region } from 'types';

export interface BaseLicense {
  regionId: number;
  assetId: string;
  region: LicenseRegion;
  availableFrom?: number | Moment; //NOTE: Returned from BE as string, but need to be send as number
  availabilityTimezone?: number;
  status?: LicenseStatus;
}
export interface AssetLicense extends BaseLicense {
  dateFrom?: number | Moment; //NOTE: Returned from BE as string, but need to be send as number
  dateTo?: number | Moment;
  id: number;
}

export interface ContentAvailability extends BaseLicense {
  uuid: string;
}

export type AvailabilityTableData = (ContentAvailability | AssetLicense) & {
  availability?: AssetAvailabilityValue;
};

export type LicenseSourceType = 'content' | 'asset';

export interface BasicAssetLicenseCreate {
  dateFrom: number;
  dateTo: number;
  regionId: number;
}

export enum LicenseStatus {
  SCHEDULED = 'SCHEDULED',
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface LicenseRegion {
  id: number;
  uuid?: string;
  name?: string;
}
export interface CreateLicenseBasicForm {
  regions: Region[];
  dateFrom: number;
  dateTo: number;
}

export enum AssetAvailabilityValue {
  SCHEDULED = 'SCHEDULED',
  IMMEDIATE = 'IMMEDIATE',
}

export enum AssetLicenseStatus {
  SCHEDULED = 'SCHEDULED',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
}

export type AddRegionLicense = AssetLicense & {
  availability?: AssetAvailabilityValue;
};

export interface EditRegionLicense {
  availableFrom?: Moment;
  availabilityTimezone?: number;
  availability: AssetAvailabilityValue;
}

export interface AddRegionForm {
  regionsLicenses: AddRegionLicense[];
}

export const LICENSE_DELETE_PROHIBITED_ERROR_CODE =
  'license/removal_is_prohibited';

export interface LicenseEditProps {
  licenseToEdit: AddRegionLicense;
}

export interface RegionOptionType {
  value: number;
  label: string;
}

export type MultipleRegionSelectValue =
  | RegionOptionType
  | RegionOptionType[]
  | 'selectAll'
  | 'unselectAll';
