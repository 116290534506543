import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import ContentList from './ContentList';
import ContentCreate from './ContentCreate';
import ContentDetails from './ContentDetails';

export default () => (
  <Switch>
    <Route exact path={Paths.CONTENT} component={ContentList} />
    <Route path={`${Paths.CONTENT}/page/:page`} component={ContentList} />
    <Route path={`${Paths.CONTENT}/create`} component={ContentCreate} />
    <Route path={`${Paths.CONTENT}/:id`} component={ContentDetails} />
  </Switch>
);
