import { aiModerationRootLevels } from 'constants/aiModeration';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getAIModeration } from 'actions/ai';
import { useAssetContentContext } from 'contexts/assetContentContext';
import React, { useCallback, useRef, useState } from 'react';
import { AiModerationDetails } from 'types/ai';
import useAssetFile from 'hooks/useAssetFile';
import { ContentType } from '@laminar-product/client-commons-core/core';
import uniqueId from 'lodash/uniqueId';
import { useAssetDetailsContext } from 'contexts/assetContext';
import ModerationModal from '../ModerationModal';
import styles from '../../index.module.scss';

const AIModeration = () => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const {
    asset: { id },
  } = useAssetDetailsContext();

  const { sourceFileId } = useAssetContentContext();
  const { file } = useAssetFile(id, ContentType.MAIN);
  const playerId = uniqueId('player');

  const [moderationModal, setModerationModalOpen] = useState<{
    mainCategory?: string;
    data?: AiModerationDetails;
    isOpen: boolean;
  }>({ isOpen: false });

  const [assetModeration] = useFetchAction(
    useCallback(async () => {
      if (sourceFileId) {
        const celebrityInfo = await getAIModeration(sourceFileId);
        return celebrityInfo.contentModeration;
      }
    }, [sourceFileId])
  );

  const assetModerationList = assetModeration
    ? Object.entries(assetModeration)
    : [];

  return (
    <div className={styles.moderationRoot}>
      <h2 className={styles.moderationHeading}>Inappropriate content levels</h2>
      <ul className={styles.moderationList}>
        {aiModerationRootLevels.map((moderation) => {
          const mod = assetModerationList.find((m) => m?.[0] === moderation);
          const value = mod?.[1]?.timestamps.length;
          return (
            <li
              key={moderation}
              className={styles.moderationItem}
              onClick={() =>
                setModerationModalOpen({
                  isOpen: true,
                  data: mod?.[1],
                  mainCategory: moderation,
                })
              }
            >
              <span className={styles.moderationValue}>{value || 'N/A'}</span>
              <p className={styles.moderationKey}>{moderation}</p>
            </li>
          );
        })}
      </ul>

      {moderationModal.isOpen && (
        <ModerationModal
          onClose={() => setModerationModalOpen({ isOpen: false })}
          data={moderationModal.data}
          mainCategory={moderationModal.mainCategory!}
          videoContainerRef={videoContainerRef}
          playerId={playerId}
          videoFile={file?.details}
        />
      )}
    </div>
  );
};

export default AIModeration;
