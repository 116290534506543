import { PromotionType } from '@laminar-product/client-commons-core/core';
import { Moment } from 'moment';

export interface BasicPromotion {
  name: string;
  discountType: DiscountType;
  startDate: string;
  endDate: string;
  promotionType: PromotionType;
  amount?: number;
}

export type GetPromotionsPaginationProps = {
  query?: string;
  page: number;
  limit?: number;
  promotionType?: PromotionType;
};

export interface Promotion {
  uuid: string;
  name: string;
  discountType: DiscountType;
  promotionType: PromotionType;
  startDate: string;
  endDate: string;
  amount?: number;
  coupons?: Coupon[];
  status: string;
}

export interface PromotionForm {
  name: string;
  discountType: DiscountType;
  promotionType: PromotionType;
  startDate: Moment;
  endDate: Moment;
  amount?: number;
  coupons?: Coupon[];
}

export enum Period {
  DAY = 1,
  WEEK = 7,
  MONTH = 30,
  YEAR = 365,
}

export enum DiscountType {
  PERIOD = 'PERIOD',
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface Coupon {
  uuid: string;
  type: CouponType;
  code: string;
  quantity: number;
  usedCount: number;
}

export enum CouponType {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE',
}

export enum PromotionRoutes {
  INTERNAL = 'internal',
  AFFILIATE = 'affiliate',
}

export enum CouponKeys {
  uuid = 'uuid',
  type = 'type',
  code = 'code',
  quantity = 'quantity',
}

export enum PromotionStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface CouponCSV {
  uuid: string;
  type: CouponType;
  code: string;
  quantity: number;
  promotionName: string;
  discountType: DiscountType;
  startDate: string;
  endDate: string;
  duration?: Period;
  amount?: number;
}
