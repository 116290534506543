import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormGroup from 'components/FormGroup';
import {
  Control,
  Controller,
  FieldErrors,
  useFormContext,
} from 'react-hook-form';
import { PlanMetaData } from 'types/plan';
import ReactQuill from 'react-quill';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import 'react-quill/dist/quill.snow.css';
interface PublicDetailsFormProps {
  control: Control<PlanMetaData>;
  selectedLanguage?: string;
  errors?: FieldErrors<PlanMetaData>;
}

const PublicDetailsForm = ({
  control,
  errors,
  selectedLanguage,
}: PublicDetailsFormProps) => {
  const { getValues } = useFormContext();
  const modules = ['bold', 'italic', 'underline', 'link'];
  const [moreInfoEditorOpened, setMoreInfoEditorOpened] = useState<number[]>(
    []
  );

  const displayMoreInfoEditor = (i: number) =>
    setMoreInfoEditorOpened((opened) => [...opened, i]);

  const removeMoreInfoEditor = (i: number) => {
    const filteredMoreInfoEditorState = moreInfoEditorOpened.filter(
      (moreInfoEditor) => moreInfoEditor !== i
    );

    setMoreInfoEditorOpened(filteredMoreInfoEditorState);
  };

  const isMoreInfoEditorOpened = (i: number) =>
    moreInfoEditorOpened.some((moreInfoEditor) => moreInfoEditor === i);

  const getMoreInfoIndex = useCallback(
    (index: number) => {
      if (!selectedLanguage) return;

      const values = getValues();

      if (
        !!values?.metadata?.[selectedLanguage]?.featuredDetails?.[index]
          ?.moreInfo
      ) {
        return index;
      }
    },
    [getValues, selectedLanguage]
  );

  useEffect(() => {
    let moreInfoIndexes: number[] = [];
    Array.from({ length: 4 }).map((el, i) => {
      const moreInfoIndex = getMoreInfoIndex(i);
      if (moreInfoIndex !== undefined) {
        moreInfoIndexes.push(moreInfoIndex);
      }
      return moreInfoIndexes;
    });
    setMoreInfoEditorOpened(moreInfoIndexes);
  }, [getMoreInfoIndex]);

  return (
    <>
      <FormGroup
        label="Plan name"
        errorMessage={errors?.name?.message}
        input={
          <Controller
            as={Input}
            control={control}
            name="name"
            rules={{ required: 'Required' }}
            defaultValue=""
          />
        }
      />
      <FormGroup
        label="Description"
        errorMessage={errors?.description?.message}
        input={
          <Controller
            as={TextArea}
            control={control}
            name="description"
            rules={{ required: 'Required' }}
            defaultValue=""
          />
        }
      />
      <p className={styles.featuredTitle}>
        <strong>Featured details</strong>
        <span>(max 26 characters per line)</span>
      </p>

      {Array.from({ length: 4 }).map((el, i) => (
        <>
          <FormGroup
            label={`Line ${i + 1}`}
            errorMessage={errors?.details && errors?.details[i]?.message}
            overflowHiddenForInput={false}
            input={
              <>
                <Controller
                  as={TextArea}
                  control={control}
                  name={`featuredDetails[${i}].value`}
                  defaultValue=""
                />
                {!isMoreInfoEditorOpened(i) && (
                  <div
                    className={styles.addMoreInfo}
                    onClick={() => displayMoreInfoEditor(i)}
                  >
                    + Add more info on hover
                  </div>
                )}
                {isMoreInfoEditorOpened(i) && (
                  <div className={styles.textEditorWrapper}>
                    <Controller
                      render={(field) => (
                        <>
                          <ReactQuill
                            theme="snow"
                            onChange={field.onChange}
                            modules={{ toolbar: modules }}
                            preserveWhitespace
                            value={field.value}
                            defaultValue=""
                          />

                          <div
                            className={styles.removeMoreInfoEditor}
                            onClick={() => {
                              field.onChange('');
                              removeMoreInfoEditor(i);
                            }}
                          >
                            <DeleteOutlined />
                            <span className={styles.removeMoreInfoText}>
                              Remove more info
                            </span>
                          </div>
                        </>
                      )}
                      control={control}
                      name={`featuredDetails[${i}].moreInfo`}
                    />
                  </div>
                )}
              </>
            }
          />
        </>
      ))}
    </>
  );
};

export default PublicDetailsForm;
