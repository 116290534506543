import { Modal } from 'antd';
import React from 'react';
import { PrepaidCard, PrepaidCardStatus } from 'types/prepaidCard';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface PrepaidCardsConfirmationModalProps {
  isOpen?: boolean;
  onClose: () => void;
  prepaidCard: PrepaidCard;
  onConfirm: () => void;
  isLoading?: boolean;
}

const PrepaidCardsConfirmationModal = ({
  isOpen,
  onClose,
  prepaidCard,
  onConfirm,
  isLoading,
}: PrepaidCardsConfirmationModalProps) => {
  const { status, title } = prepaidCard;

  const getModalTitle = () => {
    switch (status) {
      case PrepaidCardStatus.PUBLISHED:
        return 'Are you sure you want to unpublish?';

      case PrepaidCardStatus.UNPUBLISHED:
        return 'Are you sure you want to publish?';

      case PrepaidCardStatus.DRAFT:
        return (
          <div className={styles.warningWrapper}>
            <ExclamationCircleOutlined className={styles.warningIcon} />
            Are you sure you want to publish?
          </div>
        );
    }
  };

  return (
    <Modal
      visible={isOpen}
      onOk={onConfirm}
      okText="Yes"
      cancelText="No"
      onCancel={onClose}
      title={getModalTitle()}
      confirmLoading={isLoading}
    >
      {status === PrepaidCardStatus.DRAFT && (
        <span>
          You will no longer be able to edit the following details of{' '}
          <span className={styles.bold}>{title}</span> card after publishing it:
          <ul>
            <li>Card title</li>
            <li>Plan</li>
            <li>Plan Duration</li>
          </ul>
        </span>
      )}

      {status === PrepaidCardStatus.PUBLISHED && (
        <span>
          All PINs of the <span className={styles.bold}>{title}</span> card
          which are still valid will not be available for redeeming.
        </span>
      )}

      {status === PrepaidCardStatus.UNPUBLISHED && (
        <span>
          All valid PINS of the <span className={styles.bold}>{title}</span>{' '}
          card will be available to redeem.
        </span>
      )}
    </Modal>
  );
};

export default PrepaidCardsConfirmationModal;
