import { Modal } from 'antd';
import React from 'react';
import { User } from 'types';
import styles from './ChangeUserStatusModal.module.scss';

const ChangeUserStatusModal = ({
  opened,
  onCancel,
  loading,
  onOk,
  user,
}: {
  opened?: boolean;
  onCancel: () => void;
  onOk: () => void;
  loading: boolean;
  user?: User;
}) => (
  <Modal
    visible={opened}
    confirmLoading={loading}
    onOk={onOk}
    onCancel={onCancel}
  >
    <p className={styles.info}>
      Are you sure you want to{' '}
      {user?.active ? (
        <span className={styles.deactivate}>deactivate</span>
      ) : (
        <span className={styles.activate}>activate</span>
      )}{' '}
      <span className={styles.email}>{user?.email}</span>?
    </p>
  </Modal>
);

export default ChangeUserStatusModal;
