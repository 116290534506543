import _isArray from 'lodash/isArray';
import _mergeWith from 'lodash/mergeWith';

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

function customizer(objValue: Object, srcValue: Object) {
  if (_isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
/**
 * Merge two objects. If those objects have keys with the same name and with array values, merge those values
 *
 * @export
 * @param {Object} o1 obj1
 * @param {Object} o2 obj2
 * @returns merged object
 */
export function merge(o1: any, o2: any) {
  return _mergeWith(o1, o2, customizer);
}
