import moment from 'moment';
import {
  AssetLicense,
  AvailabilityTableData,
  BaseLicense,
  LicenseStatus,
} from 'types/licenses';

export const mapLicensesToCreateContentAvailability: (
  licenses: AssetLicense[]
) => BaseLicense[] = (licenses: AssetLicense[]) =>
  licenses?.map(
    ({ assetId, regionId, region, availabilityTimezone, availableFrom }) => ({
      assetId,
      regionId,
      region,
      availabilityTimezone,
      availableFrom,
    })
  );

export const isLicenseScheduled = (license: AvailabilityTableData) =>
  license.availableFrom;

export const isLicenseEditable = (license: AvailabilityTableData) =>
  license?.status === LicenseStatus.DRAFT ||
  license?.status === LicenseStatus.UNPUBLISHED;

interface ValidateAvailableFromFieldProps {
  currentValue?: moment.Moment;
  isSelected?: boolean;
  license?: AvailabilityTableData;
}

export const validateAvailableFromField = ({
  currentValue,
  license,
  isSelected,
}: ValidateAvailableFromFieldProps) => {
  if (
    isSelected &&
    license &&
    'dateFrom' in license &&
    !!currentValue &&
    currentValue?.valueOf() < Number(license.dateFrom)
  ) {
    return 'Some dates do not match your region license start date';
  }
};
