import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormContainer from 'components/FormContainer';
import Space from 'components/Space';
import {
  Casting,
  Platform,
  ProfileLimit,
  Quality,
  ScreenLimit,
} from 'pages/Plans/PlanFormFields';
import { Plan } from 'types/plan';
import { PlanDetailsPageProps } from '..';

const Limitations = ({ options }: PlanDetailsPageProps) => {
  const { control, errors } = useFormContext<Plan>();

  const formFieldProps = { control, errors, options };

  return (
    <FormContainer>
      <Space direction="vertical" expand>
        <Platform {...formFieldProps} />
        <Quality {...formFieldProps} />
        <ScreenLimit {...formFieldProps} />
        <ProfileLimit {...formFieldProps} />
        <Casting {...formFieldProps} />
      </Space>
    </FormContainer>
  );
};

export default Limitations;
