import Button from 'components/Button';
import React from 'react';
import { ButtonProps } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface ButtonsProps extends ButtonProps {
  loading?: boolean;
  onClick: () => void;
  text: string;
  noIcon?: boolean;
}

export const PublishButton = ({
  loading,
  onClick,
  text,
  noIcon,
  ...props
}: ButtonsProps) => (
  <Button
    {...props}
    type="primary"
    htmlType="button"
    loading={loading}
    className={styles.publishButton}
    onClick={onClick}
    icon={noIcon ? undefined : <GlobalOutlined />}
  >
    {text}
  </Button>
);
