import React, { useCallback, useMemo, useState } from 'react';
import { unassignAssetToContent } from 'actions/content';
import { getAssetContents } from 'actions/assets';
import { Content, ContentType } from 'types';
import {
  useFetchActionObj,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import { useAssetDetailsContext } from 'contexts/assetContext';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';
import { Modal, Spin } from 'antd';
import _groupBy from 'lodash/groupBy';
import { useAssetAvailability } from '../utils/availabilityUtils';
import styles from './index.module.scss';
import ContentRow from './components/ContentRow';
import ContentSelector from './components/ContentSelector';
import { contentSortComparator, getContentSectionHeader } from './utils';
interface ContentTableProps {
  filterBy?: ContentType[];
}

const ContentsAssetDetails = ({ filterBy }: ContentTableProps) => {
  const {
    asset: { id },
  } = useAssetDetailsContext();

  const [contentToUnassign, setContentToUnassign] = useState<Content>();

  const { isLoadingAssetLicenses, assetLicenses, noAssetLicensesAdded } =
    useAssetAvailability({
      assetId: id,
    });

  const onGetContentsError = useCallback(
    () =>
      notification.error({
        title: 'Error',
        description: 'There was an error when fetching contents for the asset',
      }),
    []
  );

  const {
    data,
    isLoading: isLoadingAssetContents,
    refresh,
  } = useFetchActionObj(
    useCallback(() => getAssetContents({ id }), [id]),
    { onError: onGetContentsError }
  );

  const [unassign, isUnasigning] = useSendAction<Content, void>(
    useCallback(
      () =>
        unassignAssetToContent({
          id: contentToUnassign?.id!,
        }),
      [contentToUnassign]
    ),
    {
      onDone: () => {
        refresh();
        notifyAboutRepublishRequirement();
        setContentToUnassign(undefined);
      },
      onError: (error: any) => {
        notification.error({
          title: 'Error',
          description:
            error?.response?.data?.details ||
            'Something went wrong. Content has not been unassigned.',
        });
        captureError(error);
      },
    }
  );

  const onUnassignButtonClick = (content: Content) =>
    setContentToUnassign(content);

  const sortedContentGroups = useMemo(() => {
    const sorted = data?.sort((content) => contentSortComparator(content));
    const dataGroupedByContentType = _groupBy(sorted, 'type');

    return dataGroupedByContentType;
  }, [data]);

  return (
    <div className={styles.container}>
      <ContentSelector
        assignedContents={data}
        isLoading={isLoadingAssetContents}
        refreshAssignedContent={refresh}
        filterBy={filterBy}
      />

      {isLoadingAssetContents ? (
        <div className={styles.spinner}>
          <Spin size="large" />
        </div>
      ) : null}

      {Object.entries(sortedContentGroups)?.map(([type, contentArray]) => (
        <>
          <div className={styles.sectionHeader}>
            {getContentSectionHeader(type)}
          </div>

          {contentArray?.map((content, index) => (
            <ContentRow
              key={index}
              content={content}
              onUnassign={() => onUnassignButtonClick(content)}
              isLoadingAssetLicenses={isLoadingAssetLicenses}
              assetLicenses={assetLicenses}
              noAssetLicensesAdded={noAssetLicensesAdded}
              isUnasigning={isUnasigning}
            />
          ))}
        </>
      ))}

      {contentToUnassign && (
        <Modal
          visible={!!contentToUnassign}
          onCancel={() => setContentToUnassign(undefined)}
          title={`Are you sure you want to unassign video content?`}
          okText="Yes"
          cancelText="No"
          onOk={() => unassign()}
          confirmLoading={isUnasigning}
        >
          Please note that <b>{contentToUnassign.title}</b> video content will
          be unpublished from all regions and all scheduled releases will be
          cancelled.
        </Modal>
      )}
    </div>
  );
};

export default ContentsAssetDetails;
