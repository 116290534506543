import { useCallback, useEffect, useMemo, useState } from 'react';
import FormContainer from 'components/FormContainer';
import { Table } from 'antd';
import PromotionRow from 'pages/Plans/PlanDetails/components/Pricing/PromotionRow';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { EditOutlined } from '@ant-design/icons';
import PromotionsChooser from 'components/PromotionsChooser';
import cn from 'classnames';
import { PaymentGateway, Plan, PlanOptions, PlanPrice } from 'types/plan';
import {
  useFetchAction,
  useFetchActionObj,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import {
  addPromotionToPrice,
  editPriceFromPlan,
  getPlanPrices,
  getSubscriptionOptions,
  removePromotionFromPrice,
} from 'actions/plans';
import { useFormContext } from 'react-hook-form';
import { captureError, captureErrorAndNotify } from 'utils/captureError';
import { errorNotify } from 'utils/errorNotify';
import { shouldProvideBillingAmount } from 'utils/pricing';
import { Price } from 'types';
import notification from 'utils/notification';
import { DevicePlatform } from 'types/devices';
import PricingModal from '../PricingModal';
import styles from './index.module.scss';

const { Column } = Table;
export interface PricingPageProps {
  options?: PlanOptions;
}

const Pricing = ({ planUuid }: { planUuid: string }) => {
  const { getValues, setValue, watch } = useFormContext<Plan>();
  const [activePricingUuid, setActivePricingUuid] = useState<string>();
  const [pricingModal, setPricingModal] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<PlanPrice | null>(null);
  const [options] = useFetchAction<PlanOptions>(getSubscriptionOptions);

  const {
    data: prices,
    isLoading: isFetching,
    refresh: refreshPrices,
  } = useFetchActionObj<PlanPrice[]>(
    useCallback(() => getPlanPrices(planUuid), [planUuid]),
    useMemo(
      () => ({
        onDone: (prices) => setValue('prices', prices),
      }),
      [setValue]
    )
  );
  const updatePrice = (price: PlanPrice) =>
    setValue(
      'prices',
      getValues('prices').map((p) => (p.uuid === price.uuid ? price : p))
    );

  const [assignPromotion, isAssigningPromotion] = useSendAction<
    PlanPrice,
    { promotionUuid: string; priceUuid: string }
  >(addPromotionToPrice, {
    onDone: updatePrice,
    onError: (error: any) => {
      errorNotify(error);
      captureError(error);
    },
  });

  const [removePromotion, isRemovingPromotion] = useSendAction<
    PlanPrice,
    { promotionUuid: string; priceUuid: string }
  >(removePromotionFromPrice, {
    onDone: refreshPrices,
  });

  useEffect(() => {
    if (prices) {
      setValue('prices', prices);
    }
  }, [getValues, prices, setValue]);

  const getFormattedAmount = (amount: number) =>
    Number.isInteger(amount / 100) ? amount / 100 + '.00' : amount / 100;
  const defaultPlatform: DevicePlatform[] = [
    DevicePlatform.WEB,
    DevicePlatform.TV,
    DevicePlatform.MOBILE,
  ];

  const togglePricingModal = (
    open: boolean,
    price: PlanPrice | null = null
  ) => {
    setSelectedPrice(price);
    setPricingModal(open);
  };

  const savePricing = async (form: PlanPrice) => {
    const payload = {
      distributionChannels: form?.distributionChannels?.map(String) || [],
    };
    const priceUuid = selectedPrice?.uuid || '';
    try {
      const updatedPrice = await editPriceFromPlan({
        planUuid,
        priceUuid,
        ...payload,
      });

      const updatedPrices = getValues('prices').map((price) =>
        price.uuid === updatedPrice.uuid ? { ...price, ...updatedPrice } : price
      );
      setValue('prices', updatedPrices);
      notification.success({
        title: 'Success',
        description: 'Price has been successfully updated!',
      });
      togglePricingModal(false);
    } catch (error) {
      console.error('Error while saving pricing:', error);
      captureErrorAndNotify(error);
      togglePricingModal(false);
    }
  };
  return (
    <FormContainer>
      <Table
        loading={isFetching || isAssigningPromotion || isRemovingPromotion}
        expandRowByClick
        dataSource={watch('prices')}
        pagination={false}
        rowKey={(record) => record.uuid}
        expandable={{
          expandedRowRender: (record) =>
            record.promotions.map((p) => (
              <PromotionRow
                promotion={p}
                onRemove={(promotionUuid) =>
                  removePromotion({ promotionUuid, priceUuid: record.uuid })
                }
              />
            )),
          rowExpandable: (record) => !!record.promotions?.length,
        }}
      >
        <Column
          title="Billing frequency"
          key="interval"
          dataIndex="interval"
          render={(interval, row: any) =>
            `${row.intervalMultiplier ?? 1} ${interval}`
          }
        />
        <Column
          title="Amount"
          key="amount"
          render={(_, row: Price & { gateway: PaymentGateway }) =>
            !shouldProvideBillingAmount(row.gateway)
              ? '-'
              : `${getFormattedAmount(
                  row.amount
                )} ${row.currency?.toUpperCase()}`
          }
        />
        <Column title="Payment Channel" key="gateway" dataIndex="gateway" />
        <Column
          title="Sales Channel"
          key="distributionChannels"
          dataIndex="distributionChannels"
          render={(distributionChannels) =>
            distributionChannels?.length ? distributionChannels.join(', ') : '-'
          }
        />
        <Column
          title="External Payment ID"
          key="externalProductId"
          dataIndex="externalProductId"
        />
        <Column
          key="actions"
          dataIndex="uuid"
          render={(uuid: string, row: PlanPrice) => (
            <div
              onClick={(e) => e.stopPropagation()}
              className={styles.actionsCel}
            >
              <span
                className={cn(styles.assignPromotionAction, [
                  {
                    [styles.disabledAssignPromoLabel]:
                      activePricingUuid && activePricingUuid !== uuid,
                  },
                ])}
                onClick={() => {
                  setActivePricingUuid(uuid);
                }}
              >
                + Assign promotion
              </span>
              <span
                className={styles.pointer}
                onClick={() => togglePricingModal(true, row)}
              >
                <EditOutlined />
              </span>
            </div>
          )}
        />
      </Table>
      {pricingModal && selectedPrice && (
        <PricingModal
          isDisabledChangePaymentChannel={true}
          paymentGateway={selectedPrice.gateway}
          intervalMultiplier={selectedPrice.intervalMultiplier ?? 1}
          interval={selectedPrice.interval}
          currency={selectedPrice.currency}
          amount={getFormattedAmount(selectedPrice.amount)}
          isDisabledChangeBillingFrequency={true}
          isDisabledChangeBillingValue={true}
          isDisabledChangeAmount={true}
          isDisabledCurrency={true}
          options={options}
          open={pricingModal}
          onClose={() => togglePricingModal(false)}
          platforms={defaultPlatform}
          selectedPlatform={selectedPrice.distributionChannels}
          addPrice={savePricing}
          isLoading={isFetching}
        />
      )}
      {!!activePricingUuid?.length && (
        <div className={styles.searchWrapper}>
          <PromotionsChooser
            prices={watch('prices')}
            placeholder="Assign promotion"
            onChoose={(promotion) => {
              if (activePricingUuid)
                assignPromotion({
                  promotionUuid: promotion.uuid,
                  priceUuid: activePricingUuid,
                });
              setActivePricingUuid(undefined);
            }}
            activePricingUuid={activePricingUuid}
          />
          <span
            onClick={() => setActivePricingUuid(undefined)}
            className={styles.trash}
          >
            <TrashIcon />
          </span>
        </div>
      )}
    </FormContainer>
  );
};

export default Pricing;
