import { Modal } from 'antd';
import React from 'react';
import { BasicPublishedPlan } from 'types/plan';
import styles from '../index.module.scss';

interface UserClientsConfirmationModalProps {
  onClose: () => void;
  isVisible?: boolean;
  onOk?: () => void;
  userName?: string;
  newPlan?: BasicPublishedPlan;
}

const UserClientsConfirmationModal = ({
  onClose,
  isVisible,
  onOk,
  userName,
  newPlan,
}: UserClientsConfirmationModalProps) => {
  return (
    <Modal
      title="Change plan?"
      visible={isVisible}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      onOk={onOk}
    >
      <div>
        Are you sure you want to change the plan of the user{' '}
        <span className={styles.bold}>{userName}</span> to{' '}
        <span className={styles.bold}>{newPlan?.administrativeName}</span>?
      </div>
    </Modal>
  );
};

export default UserClientsConfirmationModal;
