import React, { useState } from 'react';
import {
  LiveStreamInfrastructure,
  LiveStreamInfrastructureStatus,
  LiveStreamProfile,
} from 'types/live';
import Button from 'components/Button';
import { Modal } from 'antd';
import moment from 'moment';
import {
  CheckOutlined,
  CopyOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import {
  createLiveStream,
  shutdownLiveStream,
  startLiveStream,
} from 'actions/live';
import notification from 'utils/notification';
import LiveTag from 'components/LiveTag';
import styles from './StreamInfrastructureDetails.module.scss';

interface StreamInfrastructureDetailsProps {
  assetId: number;
  details?: LiveStreamInfrastructure;
  profiles?: LiveStreamProfile[];
  onStartStreaming?: (details?: LiveStreamInfrastructure) => void;
  onShutdownStreaming?: (details?: LiveStreamInfrastructure) => void;
}

const StreamInfrastructureDetails = ({
  assetId,
  details,
  profiles,
  onStartStreaming,
  onShutdownStreaming,
}: StreamInfrastructureDetailsProps) => {
  const [copied, setCopied] = useState(false);
  const { createdAt, profileUuid, status, inputStreams } = details || {};
  const copyUrlToClipboard = () => {
    if (!inputStreams || !inputStreams[0]) return;
    navigator.clipboard.writeText(inputStreams[0].primaryUrl);
    setCopied(true);
  };
  const shouldShowStartStreamButton = () => {
    if (!status) return true;
    return (
      [
        LiveStreamInfrastructureStatus.NOT_CREATED,
        LiveStreamInfrastructureStatus.CREATED,
      ].includes(status) ||
      (status === LiveStreamInfrastructureStatus.INITIALIZING &&
        !!details?.error)
    );
  };
  const shouldShowShutdownStreamButton = () => {
    if (!status) return true;
    return [
      LiveStreamInfrastructureStatus.STARTING,
      LiveStreamInfrastructureStatus.LIVE,
      LiveStreamInfrastructureStatus.RUNNING,
      LiveStreamInfrastructureStatus.CREATED,
    ].includes(status);
  };

  const shutdownStreaming = async () => {
    try {
      const details = await shutdownLiveStream(assetId);
      onShutdownStreaming?.(details);
    } catch (error) {
      notification.error({
        title: 'Something went wrong',
        description: 'Failed to shutdown stream',
      });
    }
  };

  const startStreaming = async () => {
    const shouldCreate =
      status === LiveStreamInfrastructureStatus.INITIALIZING &&
      !!details?.error;
    try {
      const newDetails =
        shouldCreate && details
          ? await createLiveStream(assetId, details?.profileUuid)
          : await startLiveStream(assetId);
      onStartStreaming?.(newDetails);
    } catch (error) {
      notification.error({
        title: 'Something went wrong',
        description: 'Failed to start stream',
      });
    }
  };

  const openConfirmationModal = (title: string, onOk: () => void) =>
    Modal.confirm({
      title,
      onOk,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
    });

  const onStartStreamingClick = () =>
    openConfirmationModal(
      `Are you sure you want to ${
        details?.error ? 'restart' : 'start'
      } streaming?`,
      startStreaming
    );
  const onShutdownStreamingClick = () =>
    openConfirmationModal(
      'Are you sure you want to shutdown streaming?',
      shutdownStreaming
    );
  const shouldDisableShutdownStreamingButton =
    details?.status === LiveStreamInfrastructureStatus.LIVE;

  const buttonLabelText =
    details?.error && LiveStreamInfrastructureStatus.INITIALIZING
      ? 'Restart'
      : 'Start Streaming';

  return (
    <div className={styles.root}>
      {!!inputStreams?.length && (
        <div className={styles.group}>
          <div className={styles.label}>URL</div>
          {inputStreams[0]?.primaryUrl}
          <Button
            className={styles.button}
            icon={!copied ? <CopyOutlined /> : <CheckOutlined />}
            onClick={copyUrlToClipboard}
          >
            {!copied ? 'Copy URL' : 'URL copied'}
          </Button>
        </div>
      )}
      <div className={styles.group}>
        <div className={styles.label}>Status</div>
        <LiveTag details={details} />
      </div>
      <div className={styles.group}>
        <div className={styles.label}>Profile</div>
        {profiles?.find(({ uuid }) => profileUuid === uuid)?.name}
      </div>
      <div className={styles.group}>
        <div className={styles.label}>Created</div>
        {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
      </div>

      <div className={styles.buttonContainer}>
        {shouldShowStartStreamButton() && (
          <Button onClick={onStartStreamingClick} type="primary">
            {buttonLabelText}
          </Button>
        )}
        {shouldShowShutdownStreamButton() && (
          <Button
            disabled={shouldDisableShutdownStreamingButton}
            onClick={onShutdownStreamingClick}
            danger
            icon={<PoweroffOutlined />}
          >
            Shutdown Infrastructure
          </Button>
        )}
      </div>
    </div>
  );
};

export default StreamInfrastructureDetails;
