import React, { ReactNode } from 'react';
import { Space as AntSpace } from 'antd';
import { SpaceProps as AntSpaceProps } from 'antd/lib/space';

interface SpaceProps extends AntSpaceProps {
  expand?: boolean;
  children: ReactNode;
}

const Space = ({ children, expand, ...props }: SpaceProps) => (
  <AntSpace
    {...props}
    style={{ width: expand ? '100%' : undefined, ...props.style }}
  >
    {children}
  </AntSpace>
);

export default Space;
