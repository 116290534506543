import React, { useCallback, useEffect, useState } from 'react';
import { Button, Skeleton, Table, Typography } from 'antd';
import { Asset } from 'types';
import { DragHandle } from 'components/DraggableTableItems';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import {
  changeAssetChildrenOrder,
  getAssetsForParentWithOrder,
} from 'actions/assets';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import DragAndDropTable from 'components/DragAndDropTable';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { useHistory } from 'react-router-dom';
import { getLabelForChildrenOrdering } from '../childrenOrderingUtils';
import styles from './index.module.scss';
const { Title } = Typography;
const { Column } = Table;

const ChildrenOrdering = ({ asset }: { asset?: Asset }) => {
  const { setAssetDraft } = useAssetDetailsContext();
  const [fetchedChildren, isFetchingChildren, , refresh] = useFetchAction(
    useCallback(
      () => getAssetsForParentWithOrder({ parentId: asset?.id! }),
      [asset]
    )
  );

  const [children, setChildren] = useState<Asset[]>();
  const [isDirty, setIsDirty] = useState<boolean>();
  const { push } = useHistory();

  const [save] = useSendAction(
    useCallback(
      () =>
        changeAssetChildrenOrder({
          id: asset?.id!,
          order: children?.map((a) => a.id)!,
        }),
      [asset, children]
    ),
    {
      onDone: refresh,
    }
  );

  const orderAssets = (assets?: Asset[]): Asset[] =>
    _orderBy(assets, ['order'], ['asc']);

  useEffect(() => {
    if (fetchedChildren) {
      setChildren(orderAssets(fetchedChildren));
    }
  }, [fetchedChildren]);

  if (!asset) return <Skeleton />;

  return (
    <>
      <Title level={4}>{getLabelForChildrenOrdering(asset.type)}</Title>
      <DragAndDropTable
        dataSource={children}
        onOrderChange={(newChildren) => {
          setIsDirty(!_isEqual(newChildren, orderAssets(children)));
          setChildren(newChildren);
        }}
        bordered={false}
        loading={isFetchingChildren}
        pagination={false}
        rowKey="id"
      >
        <Column
          width={30}
          title="Sort"
          dataIndex="sort"
          render={() => <DragHandle />}
        />
        <Column
          title="#"
          dataIndex="index"
          width={50}
          key="index"
          render={(_, _2, index) => index + 1}
        />
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          render={(text, row: Asset) => (
            <span
              className={styles.link}
              onClick={() => {
                setAssetDraft(undefined);
                push(`/assets/${row.uuid}`);
              }}
            >
              {text}
            </span>
          )}
        />
      </DragAndDropTable>
      <Button
        type="primary"
        disabled={!isDirty}
        onClick={() => {
          save();
          setIsDirty(false);
        }}
        style={{ marginTop: 20 }}
      >
        Save
      </Button>
    </>
  );
};

export default ChildrenOrdering;
