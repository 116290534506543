import React from 'react';
import { PlanMetaData } from 'types/plan';
import styles from './index.module.scss';

interface PublicLanguageDetailsProps {
  meta: PlanMetaData;
}

const PublicLanguageDetails = ({ meta }: PublicLanguageDetailsProps) => {
  return (
    <>
      <div className={styles.metadataDetails}>
        <span>Plan name</span>
        <p>{meta.name}</p>
      </div>
      <div className={styles.metadataDetails}>
        <span>Description</span>
        <p>{meta.description}</p>
      </div>
      {(meta.details || meta.featuredDetails) && (
        <div className={styles.metadataDetails}>
          <span>Featured details</span>
          <div>
            {meta.featuredDetails
              ? meta.featuredDetails.map((d) => <p>{d.value}</p>)
              : meta.details?.map((d) => <p>{d}</p>)}{' '}
            {/* Get details for backward compatibility */}
          </div>
        </div>
      )}
    </>
  );
};

export default PublicLanguageDetails;
