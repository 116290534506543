import { useEffect, useState } from 'react';
import { GlobalOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { getPage, publishPage, savePage, unpublishPage } from 'actions/pages';
import { Input, Tag, message } from 'antd';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import PublicDetails, { BasePublicDetails } from 'components/PublicDetails';
import { useGetRegions } from 'hooks/useRegions';
import _omit from 'lodash/omit';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Page, PageMetadata } from 'types/pages';
import { RouteIdParams } from 'utils/routeParams';
import { PublishButton } from 'components/PublishButton';
import TextArea from 'antd/lib/input/TextArea';
import { errorNotify } from 'utils/errorNotify';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import formatLabel from 'utils/formatLabel';
import styles from './index.module.scss';
import PageGeneralDetailsForm from './PageGeneralDetailsForm';
import PageLayout from './PageLayout';

interface PagePublicDetails extends PageMetadata, BasePublicDetails {}

const isMetadataValid = (metadata?: { [key: string]: PageMetadata }) =>
  !!metadata && Object.keys(metadata).length > 0 && !!metadata.en;

const PageDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const { push } = useHistory();
  const [page, setPage] = useState<Page>({} as Page);
  const { regions } = useGetRegions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formMethods = useForm<any>({ mode: 'onChange' });
  const { reset, watch, getValues, trigger } = formMethods;

  const handleError = (e: any) => {
    setIsSubmitting(false);
    const error = e.isAxiosError ? e.response.data.details : e.message;

    if (e?.response?.data?.code === 'page/views_are_required') {
      notification.error({
        title: 'Error',
        description:
          'You cannot save an empty page. Please add at least one component to the page layout.',
      });
      return;
    }

    errorNotify(error);
  };

  const [save, isSavingPageInProgress] = useSendAction<void, void>(
    async () => {
      const pageToSave = await preparePageToSave();
      if (pageToSave) {
        const data = await savePage(pageToSave);
        setIsSubmitting(false);
        message.success('Saved!');
        push(`/pages/${data?.uuid}`);
      }
    },
    {
      onError: (error) => {
        handleError(error);
        captureError(error);
      },
    }
  );
  const [saveAndPublish, isPublishingPageInProgress] = useSendAction<
    void,
    string
  >(
    async () => {
      const pageToSave = await preparePageToSave();
      if (pageToSave) {
        const savedPage = await savePage(pageToSave);
        await publishPage(savedPage.uuid!);
        setPage({ ...savedPage, status: 'PUBLISHED' });
        message.success('Published!');
      }
    },
    {
      onError: handleError,
    }
  );

  const [unpublish, isUnpublishingPageInProgress] = useSendAction<void, string>(
    unpublishPage,
    {
      onDone: () => {
        setPage({ ...page, status: 'UNPUBLISHED' });
        message.success('Unpublished!');
      },
      onError: handleError,
    }
  );

  const preparePageToSave = async (): Promise<Page | undefined> => {
    setIsSubmitting(true);
    const isValidForm = await trigger();
    if (!isValidForm || !isMetadataValid(page.metadata)) {
      return;
    }
    const formValues = getValues();
    const pageToSave = {
      ...page,
      name: formValues.name,
      regions: formValues.regions.map((r: any) => r.value),
      settings: {
        showTitle: formValues.showTitle,
        assetType: formValues.assetType?.value,
      },
    };

    return pageToSave;
  };

  useEffect(() => {
    if (id && regions) {
      getPage(id).then((page) => {
        setPage(page);
        reset({
          ...page,
          regions: page.regions?.map((r) => ({
            value: r,
            label: regions.find((reg) => reg.uuid === r)?.name,
          })),
          showTitle: page.settings?.showTitle,
          assetType: {
            value: page.settings?.assetType,
            label: page.settings?.assetType,
          },
        });
      });
    }
  }, [reset, id, regions]);

  const isPagePublished = page?.status === 'PUBLISHED';

  return (
    <>
      <PageDetailsHeader>
        {page?.name ?? 'New page'}

        {isPagePublished && (
          <div className={styles.tag}>
            <Tag color="green">{formatLabel(page?.status)}</Tag>
          </div>
        )}
      </PageDetailsHeader>
      <FormProvider {...formMethods}>
        <form className={styles.content}>
          <PageCard title="General details" icon={<InfoCircleOutlined />}>
            <PageGeneralDetailsForm
              regions={regions}
              hasAssetType={!!page.settings?.assetType}
            />
          </PageCard>
          <PublicDetails<PagePublicDetails>
            showErrors={isSubmitting && !isMetadataValid(page.metadata)}
            items={page?.metadata}
            renderLanguageDetails={(langDetails) => (
              <>
                {Object.entries(langDetails).map(
                  ([key, value]: [string, string]) => (
                    <div className={styles.metadataDetails}>
                      <span>{key}</span>
                      <p>{value}</p>
                    </div>
                  )
                )}
              </>
            )}
            renderLanguageForm={(control) => (
              <>
                <FormGroup
                  label="Title"
                  input={
                    <Controller
                      as={Input}
                      control={control}
                      name="title"
                      rules={{ required: true }}
                    />
                  }
                />
                <FormGroup
                  label="Description"
                  input={
                    <Controller
                      as={TextArea}
                      showCount
                      control={control}
                      name="description"
                    />
                  }
                />
              </>
            )}
            onRemoveLanguage={(lang) =>
              setPage({
                ...page,
                metadata: _omit(page.metadata, lang),
              })
            }
            onSaveLanguage={(lang, { selectedLanguage, ...details }) => {
              const metadata = {
                ...page.metadata,
                [selectedLanguage!]: details,
              };
              if (selectedLanguage !== lang) {
                delete metadata[lang];
              }
              setPage({
                ...page,
                metadata,
              });
            }}
          />
          <PageLayout
            hasRegions={watch('regions')?.length > 0}
            componentsByPlatform={
              page.platforms ?? { MOBILE: [], WEB: [], TV: [] }
            }
            setComponents={(platforms) =>
              setPage({
                ...page,
                platforms,
              })
            }
          />
          <div className={styles.buttonGroup}>
            <Button
              htmlType="button"
              type="primary"
              loading={isSavingPageInProgress}
              onClick={() => save()}
            >
              Save
            </Button>
            <PublishButton
              text={isPagePublished ? 'Save and republish' : 'Save and publish'}
              loading={isPublishingPageInProgress}
              onClick={() => saveAndPublish(page.uuid!)}
              icon={<GlobalOutlined />}
            />
            {isPagePublished && (
              <PublishButton
                ghost
                danger
                loading={isUnpublishingPageInProgress}
                onClick={() => unpublish(page.uuid!)}
                icon={<GlobalOutlined />}
                text="Unpublish"
              />
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PageDetails;
