import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const Suggestions = ({
  options,
  onAdd,
}: {
  options: string[];
  onAdd: (option: string) => void;
}) => (
  <div className={styles.root}>
    {options.map((c) => (
      <div className={styles.listItem} key={c} onClick={() => onAdd(c)}>
        {c}
        <PlusOutlined />
      </div>
    ))}
  </div>
);

export default Suggestions;
