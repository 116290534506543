import { Input, Radio, Select } from 'antd';
import Space from 'components/Space';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { TranscodeAudioTrack } from 'types';
import { CODING_TYPES } from 'utils/constants';
import LanguageChooser from 'components/LanguageChooser';
import { LanguageType } from 'types/translations';
import { languages } from '@laminar-product/client-commons-core/core';
import styles from './AudioTrackForm.module.scss';

export interface AudioTrackFormProps {
  transformedSourceTracks: TranscodeAudioTrack[];
  control: Control;
  errors: DeepMap<
    {
      audioTracks: TranscodeAudioTrack[];
    },
    FieldError
  >;
  onDefaultAudioTrackChange: (defaultAudioTrackStreamOrder: number) => void;
}

const AudioTrackForm = ({
  transformedSourceTracks,
  control,
  errors,
  onDefaultAudioTrackChange,
}: AudioTrackFormProps) => (
  <Space direction="vertical" expand>
    {transformedSourceTracks.map((audioTrack, index) => (
      <div key={audioTrack.streamOrder}>
        <div>Track {index + 1}</div>
        <div className={styles.inputGroup}>
          {/* Instead of manually adding and removing fields we can just put empty controllers to do this for us */}
          <Controller
            control={control}
            as={() => null}
            name={`audioTracks[${index}].streamOrder`}
            defaultValue=""
          />

          <Controller
            render={(field) => (
              <LanguageChooser
                chosenLanguageCode={field.value}
                onChoose={field.onChange}
                enableMostUsed
                placeholder="Select track language"
                showSearch
                type={LanguageType.ALPHA_3}
                overrideLanguageList={languages}
              />
            )}
            control={control}
            name={`audioTracks[${index}].languageCode`}
            className={styles.fixedInput}
            rules={{ required: 'Language field is required' }}
            data-testid={`AudioTrackForm__languageCode_input--${index}`}
            defaultValue=""
          />

          <Controller
            as={Input}
            control={control}
            name={`audioTracks[${index}].name`}
            placeholder="Track name"
            className={styles.fullWidthInput}
            rules={{ required: 'Name field is required' }}
            data-testid={`AudioTrackForm__name_input--${index}`}
            defaultValue=""
          />
          <Controller
            as={Select}
            name={`audioTracks[${index}].codingMode`}
            control={control}
            className={styles.fixedInput}
            options={CODING_TYPES}
            showSearch
            placeholder="Coding type"
            rules={{ required: 'Coding mode field is required' }}
            data-testid={`AudioTrackForm__codingType_selector--${index}`}
            defaultValue=""
          />

          <div className={styles.defaultRadio}>
            <span className={styles.defaultRadioText}>Default</span>
            <Controller
              control={control}
              render={(field) => (
                <Radio
                  checked={field.value}
                  onClick={() =>
                    onDefaultAudioTrackChange(audioTrack.streamOrder)
                  }
                />
              )}
              name={`audioTracks[${index}].isDefault`}
              defaultValue={false}
            />
          </div>
        </div>
        {!!errors.audioTracks?.length &&
          Object.values(errors?.audioTracks?.[index] || {}).map((e) => (
            <div className={styles.errorMessage}>{e?.message}</div>
          ))}
      </div>
    ))}
  </Space>
);

export default AudioTrackForm;
