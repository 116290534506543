import { createContext, useCallback, useContext, useMemo } from 'react';
import { Translation } from 'types/translations';
import { fetchTranslations } from 'utils/translation';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { usePlatformLanguages } from 'hooks/useLanguages';

interface TranslationActions {
  translations: Translation[];
  refresh: () => void;
}

export const TranslationContext = createContext<TranslationActions>(null!);

export const useTranslationContext = () =>
  useContext<TranslationActions>(TranslationContext);

export const TranslationProvider = ({ ...props }) => {
  const { children } = props;
  const { platformLanguages } = usePlatformLanguages();
  const platformLanguagesIsoCodes = useMemo(
    () => platformLanguages?.map((lang) => lang.alpha2),
    [platformLanguages]
  );

  const [translations, , , refresh] = useFetchAction<Translation[]>(
    useCallback(
      () => fetchTranslations(platformLanguagesIsoCodes),
      [platformLanguagesIsoCodes]
    )
  );

  return (
    <TranslationContext.Provider
      value={{
        translations: !translations?.length ? [] : translations,
        refresh,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
