import { Dropdown, Menu, Table } from 'antd';
import React from 'react';
import {
  AssetAvailabilityValue,
  AvailabilityTableData,
  LicenseRegion,
} from 'types/licenses';
import { MoreOutlined } from '@ant-design/icons';
import {
  dateFormatWith12Time,
  mapTimezoneOffsetToHourLabel,
} from 'utils/timezones';
import StatusTag, { Status, getStatusTagConfig } from 'components/StatusTag';
import moment from 'moment';
import styles from './index.module.scss';

const { Column } = Table;

interface LicenseAvailabilityTableProps {
  isLoading?: boolean;
  data?: AvailabilityTableData[];
  onRemove: (license: AvailabilityTableData) => void;
  isEditable: (license: AvailabilityTableData) => boolean;
  onEdit?: (license: AvailabilityTableData) => void;
}

const AvailabilityTable = ({
  data,
  isLoading,
  onRemove,
  isEditable,
  onEdit,
}: LicenseAvailabilityTableProps) => {
  const shouldHideAvailableFromDate = (license: AvailabilityTableData) =>
    ('availability' in license &&
      license.availability === AssetAvailabilityValue.IMMEDIATE) ||
    !license.availableFrom;

  const getAvailableFromDate = (license: AvailabilityTableData) => {
    if (shouldHideAvailableFromDate(license)) {
      return 'Immediately after published';
    }

    //In table we receive UTC format that have to be converted based on timezone offset
    const availableFrom = Number(license.availableFrom);
    const timezoneOffset = license.availabilityTimezone || 0;

    const formattedAvailableFrom = moment(availableFrom)
      .utc()
      .utcOffset(timezoneOffset)
      .format(dateFormatWith12Time);

    const timezoneLabel = mapTimezoneOffsetToHourLabel(timezoneOffset);

    return `${formattedAvailableFrom}, ${timezoneLabel}`;
  };

  return (
    <Table rowKey="regionId" loading={isLoading} dataSource={data}>
      <Column
        title="Region"
        dataIndex="region"
        render={(region: LicenseRegion) => region.name}
      />
      <Column
        title="Status"
        dataIndex="status"
        render={(_, license: AvailabilityTableData) => (
          <span>
            {license?.status ? (
              <StatusTag type={getStatusTagConfig(license.status as Status)} />
            ) : (
              '-'
            )}
          </span>
        )}
      />
      <Column
        title="Available from"
        dataIndex="availableFrom"
        render={(_, license: AvailabilityTableData) =>
          getAvailableFromDate(license)
        }
      />

      <Column
        title="Actions"
        key="options"
        align="right"
        render={(_, license: AvailabilityTableData, index) => (
          <>
            <Dropdown
              className={styles.actionButton}
              overlay={
                <Menu>
                  <Menu.Item key={index + 1} onClick={() => onRemove(license)}>
                    Remove
                  </Menu.Item>

                  {isEditable(license) && onEdit && (
                    <Menu.Item key={index + 2} onClick={() => onEdit(license)}>
                      Edit
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <MoreOutlined />
            </Dropdown>
          </>
        )}
      />
    </Table>
  );
};

export default AvailabilityTable;
