import { AssetType } from '@laminar-product/client-commons-core/core';
import { Asset } from 'types';

export const isLiveAssetType = (asset?: Asset) =>
  asset
    ? [AssetType.LIVE_CHANNEL, AssetType.LIVE_EVENT].includes(asset.type)
    : false;

export const isLiveChannelType = (asset?: Asset) =>
  asset?.type === AssetType.LIVE_CHANNEL;
export const isLiveEventType = (asset?: Asset) =>
  asset?.type === AssetType.LIVE_EVENT;

export const ASSET_TYPES_WITH_ACCESS_TYPE = [
  AssetType.LIVE_CHANNEL,
  AssetType.LIVE_EVENT,
  AssetType.MOVIE,
];

export const hasAssetAccessType = (asset?: Asset) =>
  asset ? ASSET_TYPES_WITH_ACCESS_TYPE.includes(asset.type) : false;
