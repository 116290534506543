import React, {
  Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  useFetchAction,
  usePolling,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import { attachImage, detachImage, getAssetImages } from 'actions/assets';
import { initImageUpload, uploadImageToS3 } from 'actions/images';
import { Asset, Image, ImageStatus } from 'types';
import { errorNotify } from 'utils/errorNotify';
import { ImageType } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import {
  AssetImageActionType,
  AssetImageActions,
  AssetImageState,
  imageContextReducer,
  initialAssetImageState,
} from './imageContextReducer';

export interface ImageActions {
  detach: (id: number, type: ImageType) => void;
  onDispatch: (action: AssetImageActions) => void;
  state: AssetImageState;
  assetImages: Image[];
  attach: (arg: { file: File; type: ImageType }) => void;
}

const uploadImage = async ({
  file,
  type,
  assetId,
}: {
  file: File;
  type: ImageType;
  assetId: number;
}) => {
  const { signedUrl, id } = await initImageUpload({
    name: file.name,
    type,
  });

  await uploadImageToS3({
    file,
    signedUrl: signedUrl.url,
    params: signedUrl.fields,
  });

  await attachImage({ id: assetId, imageId: id });
};

export const ImageContext = createContext<ImageActions>(null!);

export const useImageContext = () => useContext<ImageActions>(ImageContext);

interface ImageProviderProps {
  asset: Asset;
  children: React.ReactNode;
}

export const ImageProvider = (props: ImageProviderProps) => {
  const { children, asset } = props;
  const [state, dispatch] = useReducer<
    Reducer<AssetImageState, AssetImageActions>
  >(imageContextReducer, initialAssetImageState);

  const detachImageAction = useCallback(
    async (imageId: number, type: ImageType) => {
      dispatch({
        type: AssetImageActionType.LOADING,
        payload: { [type]: true },
      });
      await detachImage({ id: asset.id, imageId });
      dispatch({
        type: AssetImageActionType.IMAGE,
        payload: { [type]: undefined },
      });
      dispatch({
        type: AssetImageActionType.FILE,
        payload: { [type]: undefined },
      });
      dispatch({
        type: AssetImageActionType.LOADING,
        payload: { [type]: false },
      });
    },
    [asset.id]
  );

  const [assetImages, , , refresh] = useFetchAction<Image[]>(
    useCallback(
      () =>
        getAssetImages({
          id: Number(asset.id),
        }),
      [asset.id]
    )
  );

  const imageForType = useCallback(
    (type: ImageType): Image | undefined =>
      assetImages?.find((image) => image.type === type),
    [assetImages]
  );

  const [startImageUpload] = useSendAction<
    void,
    { file: File; type: ImageType }
  >(
    useCallback(
      ({ file, type }) => {
        return uploadImage({
          file,
          type,
          assetId: Number(asset.id),
        });
      },
      [asset.id]
    ),
    {
      onDone: () => refresh(),
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  const refreshImages = useCallback(() => {
    if (
      assetImages?.find((image) => image.status !== ImageStatus.TRANSFORMED)
    ) {
      refresh();
    }
  }, [assetImages, refresh]);

  usePolling(refreshImages);

  const checkImage = useCallback(
    (type: ImageType) => {
      const image = imageForType(type);
      if (image) {
        dispatch({
          type: AssetImageActionType.PREVIEW,
          payload: { [type]: '' },
        });
        dispatch({
          type: AssetImageActionType.IMAGE,
          payload: { [type]: image },
        });
        dispatch({
          type: AssetImageActionType.LOADING,
          payload: { [type]: false },
        });

        switch (image.status) {
          case ImageStatus.QUALITY_ERROR:
            dispatch({
              type: AssetImageActionType.ERROR,
              payload: { [type]: 'Quality error' },
            });
            dispatch({
              type: AssetImageActionType.LOADING,
              payload: { [type]: false },
            });
            break;
          case ImageStatus.TRANSFORMED:
            dispatch({
              type: AssetImageActionType.ERROR,
              payload: { [type]: '' },
            });
            dispatch({
              type: AssetImageActionType.LOADING,
              payload: { [type]: false },
            });
            break;
        }
      }
    },
    [imageForType]
  );

  useEffect(() => {
    if (assetImages) {
      assetImages.forEach((assetImage) => checkImage(assetImage.type));
    }
  }, [assetImages, checkImage]);

  return (
    <ImageContext.Provider
      value={{
        assetImages: assetImages ? assetImages : [],
        attach: startImageUpload,
        detach: detachImageAction,
        onDispatch: dispatch,
        state,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};
