import { createContext, useContext } from 'react';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getCurrentUser } from 'actions/user';
import { CurrentUser } from 'types/user';
import PageContainer from 'components/PageContainer';
import { Skeleton } from 'antd';

export interface CurrentUserActions {
  currentUser?: CurrentUser;
}

export const CurrentUserContext = createContext<CurrentUserActions>(null!);

export const usePermissionContext = () =>
  useContext<CurrentUserActions>(CurrentUserContext);

export const CurrentUserProvider = ({ ...props }) => {
  const { children } = props;

  const [currentUser] = useFetchAction(getCurrentUser);

  if (!currentUser) {
    return (
      <PageContainer>
        <Skeleton />
      </PageContainer>
    );
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
