import React, { useCallback, useState } from 'react';
import ErrorIndicator from 'components/ErrorIndicator';
import { Collection, CollectionsFilters, Pagination } from 'types';
import { Select, Spin } from 'antd';
import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';
import { getCollectionsPagination } from 'actions/collections';

const { Option } = Select;

interface CollectionChooserProps {
  onChoose: (collection: Collection) => void;
  chosen?: Collection;
  filter?: CollectionsFilters;
}

const CollectionChooser = ({
  onChoose,
  chosen,
  filter,
}: CollectionChooserProps) => {
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 300);
  const [pagination, isLoading, error] = useFetchAction<Pagination<Collection>>(
    useCallback(
      () => getCollectionsPagination(filter, undefined, queryDebounced),
      [queryDebounced, filter]
    )
  );

  // If the chosen collection is passed, but it's not present in the actual search results then
  // we need to glue it to the data to have label displayed correctly
  let correctedData = pagination?.data ?? [];
  if (chosen && !correctedData?.find((c) => c.uuid === chosen?.uuid)) {
    correctedData = [...correctedData, chosen];
  }

  return (
    <div>
      <Select
        showSearch
        value={chosen?.uuid}
        placeholder="Search for collection"
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={(value) => setQuery(value)}
        onChange={(uuid: string) => {
          onChoose(
            pagination?.data?.find((collection) => collection.uuid === uuid)!
          );
        }}
        style={{ width: '100%' }}
      >
        {correctedData?.map((collection) => (
          <Option key={collection.uuid} value={collection.uuid}>
            {collection.name}
          </Option>
        ))}
      </Select>
      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default CollectionChooser;
