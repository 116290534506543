import { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Modal, Radio } from 'antd';
import CustomPageChooser from 'components/CustomPageChooser';
import FormGroup from 'components/FormGroup';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import _intersectionBy from 'lodash/intersectionBy';
import {
  CustomPageItem,
  ExternalURLItem,
  MenuItemData,
  MenuItemType,
  MenuLink,
  MenuType,
  UrlTranslation,
} from 'types/menu';
import notification from 'utils/notification';
import InterestsSelect from 'components/InterestsSelect';
import { DevicePlatform } from 'types/devices';
import classNames from 'classnames';
import MenuCustomURL from './MenuCustomURL';
import {
  DEFAULT_TRANSLATION_ENGLISH_CODE,
  getAvailableDevicesForMenuItem,
  initialUrlTranslations,
  menuItemTypes,
} from './constants';
import styles from './MenuCustomPageModal.module.scss';
import { customPageItemGuard } from './utils';

export interface CustomPageMenuModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  onAdd: (data: MenuItemData) => void;
  onSave: (data: MenuItemData) => void;
  links?: { [key: string]: MenuLink[] };
  regions?: string[];
  selectedLink?: MenuLink;
}

const MenuCustomPageModal = ({
  isModalOpen,
  onModalClose,
  onAdd,
  onSave,
  links,
  regions,
  selectedLink,
}: CustomPageMenuModalProps) => {
  const getSelectedTypes = useMemo(
    () =>
      Object.entries(links || {}).reduce(
        (acc, [key, value]) =>
          value.some((link) => {
            const selectedLinkUuid = customPageItemGuard(selectedLink)
              ? selectedLink.page.uuid
              : selectedLink?.uuid;
            const linkUuid = customPageItemGuard(link)
              ? link.page.uuid
              : link.uuid;
            return selectedLinkUuid === linkUuid;
          })
            ? [...acc, key as MenuType]
            : acc,
        [] as MenuType[]
      ),
    [links, selectedLink]
  );
  const formMethods = useForm<MenuItemData>({
    defaultValues: selectedLink
      ? {
          ...selectedLink,
          types: getSelectedTypes,
        }
      : { type: MenuItemType.CUSTOM_PAGE, interests: [] },
  });
  const { handleSubmit, control, watch, setValue, errors } = formMethods;
  const type = watch('type');
  const availableDevices = getAvailableDevicesForMenuItem(type);
  const [excludedPagesUuids, setExcludedPagesUuids] = useState<string[]>();
  const [urlTranslations, setUrlTranslations] = useState<UrlTranslation[]>(
    initialUrlTranslations
  );

  const onUpdateUrlTranslation = useCallback(
    (data: Partial<UrlTranslation>, index: number) => {
      const updatedUrlTranslations = urlTranslations.map((t, i) =>
        i === index ? { ...t, ...data } : t
      );

      setUrlTranslations(updatedUrlTranslations);
    },
    [urlTranslations]
  );

  const onAddEmptyUrlTranslation = useCallback(() => {
    setUrlTranslations((prev) => [...prev, { langCode: '', title: '' }]);
  }, []);

  const onRemoveUrlTranslation = useCallback(
    (index: number) =>
      setUrlTranslations(urlTranslations.filter((_, i) => i !== index)),
    [urlTranslations]
  );

  useEffect(() => {
    if (links) {
      const commonLinksUuid = _intersectionBy(
        links.WEB,
        links.MOBILE,
        links.TV,
        'uuid'
      )
        .filter((link) => selectedLink?.uuid !== link.uuid && link.uuid)
        .map((link) => link.uuid);

      setExcludedPagesUuids(commonLinksUuid);
    }
  }, [links, selectedLink]);

  const onExternalURLMenuSubmit = (menu: ExternalURLItem) => {
    const enTranslationProvided = urlTranslations.some(
      (t) =>
        t.langCode === DEFAULT_TRANSLATION_ENGLISH_CODE && t.title.length > 0
    );

    if (!enTranslationProvided) {
      return notification.error({
        title: 'Error',
        description: `Please provide at least english translation for the menu with URL!`,
      });
    }

    //Save only translations with filled title and convert to proper object than BE expects
    const filledTranslations = urlTranslations.filter(
      (f) => f.langCode && f.title.length > 0
    );

    const metadata = filledTranslations.reduce(
      (o, { langCode, title }) => Object.assign(o, { [langCode]: { title } }),
      {}
    );

    selectedLink
      ? onSave({
          ...menu,
          metadata,
          types: menu.types.sort((a, b) => (a > b ? -1 : 1)),
          uuid: selectedLink.uuid,
        })
      : onAdd({
          ...menu,
          metadata,
          types: menu.types.sort((a, b) => (a > b ? -1 : 1)),
        });
  };

  const onCustomPageMenuSubmit = (menu: CustomPageItem) => {
    const payload = {
      ...menu,
      types: menu.types.sort((a, b) => (a > b ? -1 : 1)),
    };

    if (!menu.page.uuid) {
      formMethods.setError('page', {
        type: 'required',
        message: 'Please select the page. That field is required.',
      });
      return;
    }
    return selectedLink
      ? onSave({
          ...payload,
          uuid: selectedLink.uuid,
        })
      : onAdd(payload);
  };

  //Check if menu already exists for selected devices
  const checkIfMenuExists = (data: MenuItemData) => {
    let menuExistsIn: string[] = [];

    if (links) {
      data.types.forEach((type) => {
        const menuExists = links[type]?.some((link) =>
          'url' in data ? link.url === data.url : link.uuid === data.page.uuid
        );

        if (menuExists) {
          menuExistsIn.push(type);
        }
      });
    }
    return menuExistsIn;
  };

  const onSubmit = (data: MenuItemData) => {
    const menuExistsIn = checkIfMenuExists(data);
    if (menuExistsIn.length && !selectedLink) {
      notification.error({
        title: 'Error',
        description: `Item with given configuration exists in ${menuExistsIn} menu!`,
      });
      return;
    }
    'url' in data
      ? onExternalURLMenuSubmit(data)
      : onCustomPageMenuSubmit(data);
  };

  useEffect(() => {
    if (!selectedLink) return;
    formMethods.register('types');
  }, [formMethods, selectedLink]);

  return (
    <Modal
      visible={isModalOpen}
      onOk={handleSubmit(onSubmit)}
      okText={selectedLink ? 'Save' : 'Add'}
      onCancel={onModalClose}
      title={selectedLink ? 'Edit menu item' : 'Add menu item'}
      destroyOnClose
      maskClosable={false}
    >
      <FormProvider {...formMethods}>
        {!selectedLink && (
          <FormGroup
            label="Devices"
            vertical
            input={
              <Controller
                name="types"
                control={control}
                rules={{ required: 'Required' }}
                defaultValue={availableDevices}
                render={(props) => (
                  <Checkbox.Group {...props}>
                    {availableDevices.map((d) => (
                      <Checkbox
                        key={d}
                        value={d}
                        className={classNames(styles.checkbox, {
                          [styles.tvCheckbox]: d === DevicePlatform.TV,
                        })}
                      >
                        {d.toLowerCase()}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                )}
              />
            }
          />
        )}
        <FormGroup
          label="Type"
          vertical
          input={
            <Controller
              render={(field) => (
                <Radio.Group
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue(
                      'types',
                      getAvailableDevicesForMenuItem(e.target.value)
                    );
                  }}
                  options={menuItemTypes}
                />
              )}
              name="type"
              defaultValue={MenuItemType.CUSTOM_PAGE}
              control={control}
            />
          }
        />
        {type === MenuItemType.CUSTOM_PAGE && (
          <FormGroup
            label="Page"
            vertical
            errorMessage={
              'page' in errors ? (errors.page as FieldError)?.message : ''
            }
            input={
              <Controller
                name="page"
                control={control}
                rules={{
                  required: 'Please select the page. That field is required',
                }}
                defaultValue={selectedLink?.uuid || null}
                render={({ onChange, value }) => (
                  <CustomPageChooser
                    excludedUuids={excludedPagesUuids}
                    onChoose={onChange}
                    chosenPage={value}
                    regions={regions}
                    defaultValue={selectedLink?.uuid}
                  />
                )}
              />
            }
          />
        )}
        {type === MenuItemType.EXTERNAL_LINK && (
          <MenuCustomURL
            urlTranslations={urlTranslations}
            onUpdateUrlTranslation={onUpdateUrlTranslation}
            onAddEmptyUrlTranslation={onAddEmptyUrlTranslation}
            onRemoveUrlTranslation={onRemoveUrlTranslation}
          />
        )}
        <FormGroup
          label="Interests"
          vertical
          input={
            <Controller
              name="interests"
              control={control}
              defaultValue={selectedLink?.interests || []}
              render={({ onChange }) => (
                <InterestsSelect
                  onChange={onChange}
                  defaultValue={selectedLink?.interests || []}
                />
              )}
            />
          }
        />
      </FormProvider>
    </Modal>
  );
};

export default MenuCustomPageModal;
