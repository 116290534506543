import React, { ReactNode } from 'react';
import { AxiosError } from 'axios';

interface ErrorIndicatorProps {
  error: Error | AxiosError | undefined;
  showResponseMessage?: boolean;
}

const determineIfAxiosError = (
  error: Error | AxiosError
): error is AxiosError => {
  return (error as AxiosError).response !== undefined;
};

export const ErrorMessage = ({ children }: { children: ReactNode }) => {
  return <div style={{ color: 'red' }}>{children}</div>;
};

// Was about to name it ErrorMessage but react-hook-form also uses that name
const ErrorIndicator = ({
  error,
  showResponseMessage = false,
}: ErrorIndicatorProps) => {
  if (!error) {
    return null;
  }

  if (determineIfAxiosError(error)) {
    const displayMessage =
      showResponseMessage && error.response?.data?.message != null;
    const message = error.response?.data?.message;

    if (error.response?.status === 404)
      return (
        <ErrorMessage>
          {displayMessage
            ? message
            : "We're sorry, but we did not found what you're looking for."}
        </ErrorMessage>
      );

    if (error.response?.status === 400)
      return (
        <ErrorMessage>
          {displayMessage
            ? message
            : 'There was an error during the request, please check provided values and try again.'}
        </ErrorMessage>
      );

    return (
      <ErrorMessage>
        {displayMessage ? message : 'Something went wrong.'}
      </ErrorMessage>
    );
  }

  return <ErrorMessage>{error.message}</ErrorMessage>;
};

export default ErrorIndicator;
