import * as yup from 'yup';
import { toNumber } from './utils';
import { SkipOptionsFormItem } from './';

const rangesOverlap = (
  val: SkipOptionsFormItem,
  other: SkipOptionsFormItem
) => {
  const firstVal = {
    start: toNumber(val.start),
    end: toNumber(val.end),
  };
  const secondVal = {
    start: toNumber(other.start),
    end: toNumber(other.end),
  };
  if (secondVal.start < firstVal.start) {
    return secondVal.end < firstVal.start;
  } else {
    return secondVal.start > firstVal.end;
  }
};

function checkStartBeforeEnd(end: string, schema: yup.StringSchema) {
  return schema
    .test('lessThan', 'Start should be before end', (val?: string) => {
      if (!val && !end) return true;
      return val ? toNumber(val) < toNumber(end) : false;
    })
    .typeError('INVALID_RANGE_VALUES');
}

function checkRangesDontOverlap(
  value: SkipOptionsFormItem,
  schema: yup.AnySchema
) {
  if (!value) return schema;
  return schema.test(
    'overlap',
    'Ranges overlap',
    (curValue: SkipOptionsFormItem) => {
      if (!curValue.checked || !value.checked) return true;
      return rangesOverlap(curValue, value);
    }
  );
}

export const formValidationSchema = yup.object().shape(
  {
    intro: yup
      .object()
      .shape({
        start: yup.string().when('end', checkStartBeforeEnd),
        end: yup.string(),
      })
      .when('credits', checkRangesDontOverlap)
      .when('recap', checkRangesDontOverlap),
    recap: yup
      .object()
      .shape({
        start: yup.string().when('end', checkStartBeforeEnd),
        end: yup.string(),
      })
      .when('credits', checkRangesDontOverlap)
      .when('intro', checkRangesDontOverlap),
    credits: yup
      .object()
      .shape({
        start: yup.string().when('end', checkStartBeforeEnd),
        end: yup.string(),
      })
      .when('recap', checkRangesDontOverlap)
      .when('intro', checkRangesDontOverlap),
  },
  [
    ['intro', 'recap'],
    ['intro', 'credits'],
    ['credits', 'recap'],
  ]
);
export default formValidationSchema;
