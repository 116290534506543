import _clone from 'lodash/fp/clone';
import _curry from 'lodash/fp/curry';
import _setWith from 'lodash/fp/setWith';

/**
 * Similar to lodash set but returns a new object
 *
 * @param {any} sourceObj - object to set the property on
 * @param {string} path - path to the property
 * @param {any} value - the new value
 *
 * @returns new object with the new value for the property
 */
const setIn = _curry((sourceObj: any, path: string, value: any) =>
  _setWith(_clone, path, value, _clone(sourceObj))
);

export default setIn;
