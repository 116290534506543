import React, { useEffect, useState } from 'react';
import { Collection, CollectionsFilters } from 'types';
import CollectionChooser from 'components/CollectionChooser';
import { getCollectionDetails } from 'actions/collections';
import { cancelable } from 'cancelable-promise';

export interface CollectionUuidChooserProps {
  chosen: string;
  onChoose: (uuid: string) => void;
  filter?: CollectionsFilters;
}

/**
 * Small component built on top of CollectionChooser that takes
 * care of fetching missing collection
 */
const CollectionUuidChooser = ({
  chosen,
  onChoose,
  filter,
}: CollectionUuidChooserProps) => {
  const [internalChosen, setInternalChosen] = useState<Collection>();

  useEffect(() => {
    // If chosen is passed down, but our internal value is empty then fetch the asset
    if (chosen && !internalChosen) {
      const promise = cancelable<Collection>(getCollectionDetails(chosen));

      promise.then(setInternalChosen);

      return () => promise.cancel();
    }
  }, [chosen, internalChosen]);

  return (
    <CollectionChooser
      chosen={internalChosen}
      filter={filter}
      onChoose={(collection) => {
        setInternalChosen(collection);
        onChoose(collection.uuid);
      }}
    />
  );
};

export default CollectionUuidChooser;
