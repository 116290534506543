import React from 'react';
import { useForm } from 'react-hook-form';
import { CensorshipRule } from 'types/censorships';
import Button from 'components/Button';
import Space from 'components/Space';
import FormContainer from 'components/FormContainer';
import { createCensorshipRule } from 'actions/censorships';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import ButtonsRow from 'components/ButtonsRow';
import { errorNotify } from 'utils/errorNotify';
import { useHistory } from 'react-router-dom';
import { MinAge, Name } from 'pages/CensorshipRules/CensorshipRuleFormFields';
import { Region } from 'types';
import { generateCensorshipRuleUrl } from 'pages/CensorshipRules/utils';
import notification from 'utils/notification';
import { captureError } from 'utils/captureError';

const CensorshipRuleModalForm = ({
  region,
  onClose,
}: {
  region?: Region;
  onClose: () => void;
}) => {
  const { control, handleSubmit, getValues, errors } = useForm<CensorshipRule>({
    mode: 'onSubmit',
  });
  const { push } = useHistory();

  const [save, isSavingCensorship] = useSendAction<void, CensorshipRule>(
    async () => {
      if (!region) return;

      const { name, minAge } = getValues();
      const censorshipToSave = {
        name: name,
        minAge: minAge,
        regionUuid: region.uuid,
      };

      const data = await createCensorshipRule({ censorshipToSave });
      notification.success({
        title: 'Success',
        description: 'Age rating has been saved!',
      });
      push(generateCensorshipRuleUrl(data, region.name));
    },
    {
      onError: (err) => {
        errorNotify(err);
        captureError(err);
        onClose();
      },
    }
  );

  return (
    <FormContainer>
      <Space direction="vertical" expand>
        <Name control={control} errors={errors} />
        <MinAge control={control} errors={errors} />
        <ButtonsRow>
          <Button
            disabled={isSavingCensorship}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(save)}
            loading={isSavingCensorship}
            type="primary"
          >
            Save
          </Button>
        </ButtonsRow>
      </Space>
    </FormContainer>
  );
};

export default CensorshipRuleModalForm;
