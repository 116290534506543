import { Subscription, User } from 'types';
import _minBy from 'lodash/maxBy';

export const getUsername = (user?: User) => {
  if ((user?.email === null && user?.phoneNumber === null) || !user) return '-';

  return [user?.email, user?.phoneNumber].filter((data) => !!data).join(', ');
};

export const getUserCurrentSubscription = (user?: User) => {
  const activeSubscription = getUserActiveSubscription(user);

  if (!!activeSubscription) return activeSubscription;

  const newestSubscription = _minBy(
    user?.subscriptions,
    (subscription: Subscription) => subscription?.createdAt
  );

  if (!!newestSubscription) return newestSubscription;
};

export const getUserActiveSubscription = (user?: User) =>
  user?.subscriptions?.find((s) => s.active);

export const isPlanCancelled = (user: User) =>
  getUserActiveSubscription(user)?.active === false;
