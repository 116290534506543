import {
  useFetchAction,
  usePolling,
} from '@laminar-product/client-commons-core/hooks';
import { getTranscodedFiles } from 'actions/files';
import { Table } from 'antd';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import FileStatusTag from 'components/FileStatusTag';
import React, { useCallback, useState } from 'react';
import { FileStatus, UploadedFile } from 'types';
import {
  useBoundToPages,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import moment from 'moment';
import AssignFileModal from './AssignFileModal';

const { Column } = Table;

const ReadyFilesTable = ({ query }: { query?: string }) => {
  const [fileToAssign, setFileToAssign] = useState<UploadedFile>();

  const page = usePageParam();

  const [pagination, isLoading, error, refresh] = useFetchAction(
    useCallback(
      () =>
        getTranscodedFiles({
          page,
          fileStatuses: [FileStatus.UNASSIGNED],
          searchQuery: query,
        }),
      [page, query]
    )
  );

  usePolling(refresh);

  useBoundToPages(`/files/ready`, pagination, page);

  return (
    <>
      {!!fileToAssign && (
        <AssignFileModal
          isOpen={!!fileToAssign}
          file={fileToAssign}
          closeModal={() => setFileToAssign(undefined)}
        />
      )}

      <ErrorIndicator error={error} />
      <Table
        rowKey="id"
        dataSource={pagination?.data}
        loading={pagination?.data?.length === 0 && isLoading}
        pagination={usePaginationObject('/files/ready', pagination, page ?? 1)}
      >
        <Column title="File name" key="name" dataIndex="name" />
        <Column
          title="Status"
          key="status"
          dataIndex="status"
          render={(_, file: UploadedFile) => (
            <FileStatusTag file={{ ...file, status: FileStatus.UNASSIGNED }} />
          )}
        />
        <Column
          title="Uploaded"
          key="uploadDate"
          dataIndex="uploadDate"
          render={(_, file: UploadedFile) => (
            <span>
              {file.createdAt
                ? moment(file.createdAt).format('YYYY-MM-DD hh:mm:ss')
                : ''}
            </span>
          )}
        />
        <Column
          title="Action"
          key="action"
          render={(status: FileStatus, file: UploadedFile) => (
            <Button onClick={() => setFileToAssign(file)}>Assign</Button>
          )}
        />
      </Table>
    </>
  );
};

export default ReadyFilesTable;
