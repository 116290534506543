import { SerializedPageSettings } from '@laminar-product/client-commons-core/core';
import { DevicePlatform } from './devices';

export interface PageView {
  platform: DevicePlatform;
  components?: PageComponent[];
}

export enum PageStatus {
  CREATED = 'CREATED',
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
}

export enum PageElementsType {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
  FEATURED_ASSET = 'FEATURED_ASSET',
}

export interface Page {
  uuid?: string;
  name: string;
  updatedAt: moment.Moment;
  status: string;
  metadata?: { [key: string]: PageMetadata };
  regions: string[];
  settings?: SerializedPageSettings;
  platforms?: PagePlatform;
}

export type PagePlatform = {
  [key in DevicePlatform]: PageComponent[];
};

export interface PageMetadata {
  title: string;
  description?: string;
}

export interface PageComponent {
  type: string;
  uuid?: string;
  builderId: string;
  elements: PageElementsType;
}
