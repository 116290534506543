import { RefObject, useRef } from 'react';
import videojs from 'video.js';
import { ReferenceSnapshot } from './';

interface MousePositionState {
  startX: number;
  startY: number;
  x: number;
  y: number;
}

// width of the borders of the snapshot rectangle
const rectLineWidth = 2;

const useSnapshot = (
  playerId: string,
  onAddSnapshot: (snapshot: ReferenceSnapshot) => void,
  containerRef: RefObject<HTMLDivElement>
) => {
  const isMouseDown = useRef(false);
  const mousePosition = useRef<MousePositionState>({
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
  });

  const countMouseCoordinates = (e: MouseEvent, canvas: HTMLCanvasElement) => {
    const x = e.offsetX - canvas.offsetLeft;
    const y = e.offsetY - canvas.offsetTop;

    return { x, y };
  };
  const createImage = (
    coords: MousePositionState,
    canvas: HTMLCanvasElement
  ) => {
    const imageCanvas = document.createElement('canvas');
    const imageCanvasCtx = imageCanvas.getContext('2d');
    imageCanvas.width = coords.x;
    imageCanvas.height = coords.y;
    const rectImageData = canvas
      .getContext('2d')
      ?.getImageData(
        coords.startX + rectLineWidth,
        coords.startY + rectLineWidth,
        coords.x - rectLineWidth * 2,
        coords.y - rectLineWidth * 2
      );

    imageCanvasCtx?.putImageData(rectImageData!, 0, 0);
    const imgData = imageCanvas.toDataURL();
    imageCanvas.remove();

    return imgData;
  };
  const onMouseDown = (e: MouseEvent, canvas: HTMLCanvasElement) => {
    const { x, y } = countMouseCoordinates(e, canvas);
    mousePosition.current.startX = x;
    mousePosition.current.startY = y;
    isMouseDown.current = true;
  };
  const onMouseUp = (e: MouseEvent, canvas: HTMLCanvasElement) => {
    isMouseDown.current = false;
    const snapshot = {
      image: createImage(mousePosition.current, canvas),
      name: '',
      key: '',
    };
    onAddSnapshot(snapshot);
    canvas.parentNode?.removeChild(canvas);
  };
  const onMouseMove = (
    e: MouseEvent,
    canvas: HTMLCanvasElement,
    videoEl: HTMLVideoElement
  ) => {
    if (!isMouseDown.current) return;

    const { x, y } = countMouseCoordinates(e, canvas);
    const mouseState = mousePosition.current;
    const width = x - mouseState.startX;
    const height = y - mouseState.startY;
    const ctx = canvas.getContext('2d');
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    canvas
      .getContext('2d')
      ?.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
    ctx?.beginPath();
    ctx?.rect(mouseState.startX, mouseState.startY, width, height);
    mousePosition.current.x = width;
    mousePosition.current.y = height;
    ctx!.strokeStyle = '#40a9ff';
    ctx!.lineWidth = rectLineWidth;
    ctx!.stroke();
  };

  const takeSnapshot = () => {
    const player = videojs.getPlayer(playerId);
    const videoEl = player?.tech().el() as HTMLVideoElement;

    if (!player?.paused()) player?.pause();
    // reset is required cause the component is not disposed,
    // and user might close the modal while the selection is still in progress
    isMouseDown.current = false;
    mousePosition.current = {
      startX: 0,
      startY: 0,
      x: 0,
      y: 0,
    };

    const overlayCanvas = document.createElement('canvas');
    overlayCanvas.height = videoEl.offsetHeight;
    overlayCanvas.width = videoEl.offsetWidth;
    const overlayCtx = overlayCanvas.getContext('2d');
    overlayCanvas.onmousedown = (e) => onMouseDown(e, overlayCanvas);
    overlayCanvas.onmouseup = (e) => onMouseUp(e, overlayCanvas);
    overlayCanvas.onmousemove = (e) => onMouseMove(e, overlayCanvas, videoEl);
    containerRef.current?.appendChild(overlayCanvas);

    overlayCtx?.drawImage(
      videoEl,
      0,
      0,
      overlayCanvas.width,
      overlayCanvas.height
    );
  };

  return takeSnapshot;
};

export default useSnapshot;
