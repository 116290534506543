import React, { useCallback, useEffect, useState } from 'react';
import ErrorIndicator from 'components/ErrorIndicator';
import { searchForContent } from 'actions/content';
import { Content, ContentType } from 'types';
import { Select, Spin } from 'antd';
import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';
import styles from './index.module.scss';

const { Option } = Select;

interface ContentChooserProps {
  onChoose: (content: Content) => void;
  chosen?: Content;
  filterHasFile?: boolean;
  filterHasAsset?: boolean;
  filterBy?: ContentType[];
  assignedContents?: Content[];
}

const ContentChooser = ({
  onChoose,
  chosen,
  filterHasFile,
  filterHasAsset,
  filterBy,
  assignedContents,
}: ContentChooserProps) => {
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 300);
  const [data, isLoading, error, refresh] = useFetchAction<Content[]>(
    useCallback(
      () =>
        searchForContent({
          title: queryDebounced,
          hasAsset: filterHasAsset,
          hasFile: filterHasFile,
        }),
      [filterHasAsset, filterHasFile, queryDebounced]
    )
  );

  const [contentData, setContentData] = useState<Content[] | undefined>(data);

  useEffect(() => {
    const contentData = filterBy
      ? data?.filter((d) => filterBy.includes(d.type))
      : data;
    setContentData(contentData);
  }, [data, filterBy, setContentData]);

  useEffect(() => {
    if (assignedContents) {
      refresh();
    }
  }, [assignedContents, refresh]);

  return (
    <div className={styles.searchWrapper}>
      <Select
        showSearch
        value={chosen?.id}
        placeholder="Search for content"
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={(value) => setQuery(value)}
        onChange={(id: number) => {
          onChoose(data?.find((content) => content.id === id)!);
        }}
        className={styles.searchInput}
      >
        {contentData?.map((content) => (
          <Option key={content.id} value={content.id}>
            {content.title}
          </Option>
        ))}
      </Select>
      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default ContentChooser;
