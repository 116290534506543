import PageBuilder from 'pages/PageBuilder';
import Plans from 'pages/Plans';
import Regions from 'pages/Regions';
import Users from 'pages/Users';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Permission } from 'utils/constants';
import { useDictionary } from 'hooks/useDictionaries';
import Coupons from 'pages/Promotions/Affiliate';
import { AssetContentProvider } from 'contexts/assetContentContext';
import { AssetProvider } from 'contexts/assetContext';
import AssetPreview from 'components/AssetPreview';
import CensorshipRules from 'pages/CensorshipRules';
import TaxManagement from 'pages/TaxManagement';
import PrepaidCards from 'pages/PrepaidCards';
import SEO from 'pages/SEO';
import { Paths } from 'types/paths';
import ContentDistribution from 'pages/ContentDistribution';
import PlatformPartners from 'pages/PlatformPartners';
import { LanguagesPage } from 'pages/Languages';
import FeaturedAssets from 'pages/FeaturedAssets';
import Avod from 'pages/Avod';
import GuestAccess from 'pages/GuestAccess';
import Interests from 'pages/Interests';
import ListingPage from './components/ListingPage';
import Assets from './pages/Assets';
import Collections from './pages/Collections';
import Content from './pages/Content';
// hide for now import Live from './pages/Live';
import Dashboard from './pages/Dashboard';
import Files from './pages/Files';
import Menus from './pages/Menus';
import Internal from './pages/Promotions/Internal';
import AuthenticatedRoute from './components/AuthenticatedRoute';

const AppRoute = () => (
  <Switch>
    <Route exact path="/">
      <Dashboard />
    </Route>
    <Route path={Paths.FILES}>
      <Files />
    </Route>
    {/* hide for now
                  <Route path={Paths.LIVE}>
                    <Live />
                  </Route> */}
    <Route path={Paths.ASSETS}>
      <Assets />
    </Route>
    <Route path={Paths.CONTENT}>
      <Content />
    </Route>
    <Route path={Paths.INTERESTS}>
      <Interests />
    </Route>
    <AuthenticatedRoute path={Paths.USERS} permissions={[Permission.GET_USER]}>
      <Users />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      path={Paths.CLIENTS}
      permissions={[Permission.GET_CLIENT]}
    >
      <Users />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      path={Paths.DISTRIBUTORS_CLIENTS}
      permissions={[Permission.CONTENT_DISTRIBUTION_READ]}
    >
      <Users />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      path={Paths.AUDIT_LOG}
      permissions={[Permission.VIEW_AUDIT_LOGS]}
    >
      <Users />
    </AuthenticatedRoute>
    <Route path={Paths.REGIONS}>
      <Regions />
    </Route>
    <AuthenticatedRoute path={Paths.PLANS} permissions={[Permission.GET_PLAN]}>
      <Plans />
    </AuthenticatedRoute>
    <Route path={Paths.GUEST_ACCESS}>
      <GuestAccess />
    </Route>
    <Route path={Paths.AVOD}>
      <Avod />
    </Route>
    <Route path={Paths.PAGES}>
      <PageBuilder />
    </Route>
    <Route path={Paths.MENUS}>
      <Menus />
    </Route>
    <Route path={Paths.COLLECTIONS}>
      <Collections />
    </Route>
    <Route path={Paths.GENRES}>
      <ListingPage
        title="Genres"
        label="genre"
        dictionaryType="genres"
        translationKey="genre"
        useFetchDictionary={useDictionary}
      />
    </Route>
    <Route path={Paths.MOODS}>
      <ListingPage
        title="Moods"
        label="mood"
        dictionaryType="moods"
        translationKey="mood"
        useFetchDictionary={useDictionary}
      />
    </Route>
    <Route path={Paths.APPROPRIATENESS_TAGS}>
      <ListingPage
        title="Appropriateness Tags"
        label="Appropriateness Tag"
        dictionaryType="moderations"
        translationKey="moderation"
        useFetchDictionary={useDictionary}
      />
    </Route>
    <Route path={Paths.INTERNAL}>
      <Internal />
    </Route>
    <Route path={Paths.AFFILIATE}>
      <Coupons />
    </Route>
    <Route path={`${Paths.ASSET_PREVIEW}/:id/preview`}>
      <AssetProvider>
        <AssetContentProvider>
          <AssetPreview />
        </AssetContentProvider>
      </AssetProvider>
    </Route>
    <Route path={Paths.CENSORSHIPS}>
      <CensorshipRules />
    </Route>
    <Route path={Paths.PREPAID_CARDS}>
      <PrepaidCards />
    </Route>
    <Route path={Paths.SEO}>
      <SEO />
    </Route>
    <Route path={Paths.CONTENT_DISTRIBUTION}>
      <ContentDistribution />
    </Route>
    <Route path={Paths.PARTNERS}>
      <PlatformPartners />
    </Route>
    <Route path="/featured-assets">
      <FeaturedAssets />
    </Route>
    <AuthenticatedRoute
      path={Paths.TAX_MANAGEMENT}
      permissions={[Permission.TAX_CONFIGURATION_EDIT]}
    >
      <TaxManagement />
    </AuthenticatedRoute>
    <AuthenticatedRoute
      path={Paths.LANGUAGES}
      permissions={[Permission.GET_PLATFORM_LANGUAGES]}
    >
      <LanguagesPage />
    </AuthenticatedRoute>
  </Switch>
);

export default AppRoute;
