export interface LiveStreamProfile {
  description: string;
  isActive: boolean;
  name: string;
  uuid: string;
  channelsCount: number;
  channelsLimit: number;
  eventsCount: number;
  eventsLimit: number;
}

export enum LiveStreamInfrastructureStatus {
  NOT_CREATED = 'NOT_CREATED',
  INITIALIZING = 'INITIALIZING',
  CREATED = 'CREATED',
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  SHUTTING_DOWN = 'SHUTTING_DOWN',
  LIVE = 'LIVE',
}

interface LiveStreamInfrastructureInput {
  fallbackUrl: string;
  primaryUrl: string;
  type: string;
  uuid: string;
}

interface LiveStreamInfrastructureOutput {
  drm: string;
  packaging: string;
  resolution: string;
  ssai: string;
  url: string;
  uuid: string;
}

export interface LiveStreamInfrastructure {
  createdAt: string;
  error: string;
  outputStreams: LiveStreamInfrastructureOutput[];
  inputStreams: LiveStreamInfrastructureInput[];
  profileUuid: string;
  status: LiveStreamInfrastructureStatus;
  updatedAt: string;
  uuid: string;
}
