import { Col } from 'antd';
import Card from 'components/Charts/Card';
import { DashboardSourceStatusesCount } from 'types/dashboard';
import { Paths } from 'types/paths';
import { FileStatus } from 'types';
import styles from './index.module.scss';

interface DashboardCardsProps {
  data?: {
    today: DashboardSourceStatusesCount[];
    yesterday: DashboardSourceStatusesCount[];
  };
}

const DashboardCards = ({ data }: DashboardCardsProps) => {
  const qualityError = { status: FileStatus.QUALITY_ERROR, value: 0, delta: 0 };
  const processing = {
    status: FileStatus.PROCESSING,
    value: 0,
    delta: 0,
  };
  const assigned = { status: FileStatus.ASSIGNED, value: 0, delta: 0 };
  const unassigned = { status: FileStatus.UNASSIGNED, value: 0, delta: 0 };

  const currentCount = [qualityError, processing, unassigned, assigned];

  currentCount.forEach((item) => {
    const todayValue = data?.today.find(
      (value) => value.status === item.status
    );

    const yesterdayValue = data?.yesterday.find(
      (value) => value.status === item.status
    );

    if (todayValue) {
      item.value = Number(todayValue.count);

      if (yesterdayValue) {
        item.delta = Number(todayValue.count) - Number(yesterdayValue.count);
      }
    }
  });

  return (
    <>
      <Col xs={24} sm={12} md={6} className={styles.col}>
        <Card
          title="Quality error"
          textDisplayed={`${currentCount[0].value} files`}
          linkTo={`${Paths.FILES}/failed`}
          color="red"
          deltaTimeframe="Since yesterday"
          deltaValue={currentCount[0].delta}
        />
      </Col>

      <Col xs={24} sm={12} md={6} className={styles.col}>
        <Card
          title="Processing"
          textDisplayed={`${currentCount[1].value} files`}
          color="grey"
        />
      </Col>

      <Col xs={24} sm={12} md={6} className={styles.col}>
        <Card
          title="Ready to assign"
          textDisplayed={`${currentCount[2].value} files`}
          linkTo={`${Paths.FILES}/ready`}
          color="deepSkyBlue"
        />
      </Col>

      <Col xs={24} sm={12} md={6} className={styles.col}>
        <Card
          title="Assigned"
          textDisplayed={`${currentCount[3].value} files`}
          linkTo={`${Paths.FILES}/assigned`}
          color="lightGreen"
          deltaTimeframe="Since yesterday"
          deltaValue={currentCount[3].delta}
        />
      </Col>
    </>
  );
};

export default DashboardCards;
