import { createSelector } from 'reselect';
import { IRequestAction, RequestStatuses } from 'store/app/types';
import { RootState } from 'store/index';

const getAction = (state: RootState, action: string) =>
  state.app.requestActions.find(
    (requestAction: IRequestAction) => requestAction.action === action
  );

export const getIsLoading = createSelector(
  getAction,
  (action) => action?.status === RequestStatuses.PENDING
);

export const getRequestStatus = createSelector(
  getAction,
  (action) => action?.status
);
