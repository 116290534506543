import { Menu, Modal } from 'antd';
import { usePermission } from 'hooks/permissionsHook';
import UserClientsSignOutConfirmation from 'pages/Users/UserListClients/UserListClientsModals/UserClientsSignOutConfirmation';
import { useState } from 'react';
import { User } from 'types';
import { Permission } from 'utils/constants';

interface UserSignOutMenuItemProps {
  user?: User;
  setSelectedUser: (user: User | undefined) => void;
  permissions: Permission[];
  signOutUser: (userUuid: string) => Promise<any>;
}
const UserSignOutMenuItem = ({
  user,
  setSelectedUser,
  permissions,
  signOutUser,
}: UserSignOutMenuItemProps) => {
  const { canUse } = usePermission();
  const [signOutModalVisible, setSignOutModalVisible] = useState(false);

  const onSignOutModalClose = () => {
    setSignOutModalVisible(false);
    setSelectedUser(undefined);
  };

  const signOutUserOnClickHandler = (user?: User) => () => {
    setSelectedUser(user);
    setSignOutModalVisible(true);
  };

  return (
    <>
      {canUse(permissions) && (
        <Menu.Item
          key={user?.id + '_sign_out_user'}
          onClick={signOutUserOnClickHandler(user)}
        >
          Sign out on all devices
        </Menu.Item>
      )}

      <Modal
        open={signOutModalVisible}
        onCancel={onSignOutModalClose}
        destroyOnClose
        closable={false}
        footer={null}
      >
        {user && (
          <UserClientsSignOutConfirmation
            email={user.email}
            userUuid={user.uid}
            closeModal={onSignOutModalClose}
            signOutUser={signOutUser}
          />
        )}
      </Modal>
    </>
  );
};

export default UserSignOutMenuItem;
