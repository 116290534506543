import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import PageContainer from 'components/PageContainer';
import Space from 'components/Space';
import { Title } from 'components/Typography';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { useImageContext } from 'contexts/imageContext';
import { AssetImageActionType } from 'contexts/imageContextReducer';
import { Asset, ImageStatus } from 'types';
import { returnProperImageType } from 'utils/returnProperImageType';
import GeneralDetailsForm from './GeneralDetailsForm';
import styles from './index.module.scss';

interface GeneralDetailsProps {
  updateAsset: (asset: Asset) => void;
  isSaving?: boolean;
}

export interface GeneralDetailsFormSubmit {
  asset: Asset;
  image?: File;
  imageStatus?: ImageStatus;
}

const GeneralDetails = ({ updateAsset, isSaving }: GeneralDetailsProps) => {
  const { attach, state, onDispatch } = useImageContext();
  const { assetDraft } = useAssetDetailsContext();

  const onSubmit = ({ asset }: GeneralDetailsFormSubmit) => {
    updateAsset(asset);

    if (state?.file?.[returnProperImageType(assetDraft)]) {
      onDispatch({
        type: AssetImageActionType.LOADING,
        payload: { [returnProperImageType(assetDraft)]: true },
      });
      attach({
        file: state.file[returnProperImageType(assetDraft)] as File,
        type: returnProperImageType(assetDraft),
      });
    }
  };

  return (
    <PageContainer>
      <Space direction="vertical" expand>
        <Title level={5}>
          <span className={styles.iconWrapper}>
            <InfoCircleOutlined />
          </span>
          General details
        </Title>
        <GeneralDetailsForm isSaving={!!isSaving} onSubmit={onSubmit} />
      </Space>
    </PageContainer>
  );
};

export default GeneralDetails;
