import { CancelToken } from 'axios';
import {
  FileStatus,
  SourceFileAudioTrack,
  SourceFileSubtitleTrack,
  StreamingProfile,
  TranscodeAudioTrack,
  TranscodeSubtitle,
  UploadedFile,
} from 'types';
import { PAGINATION_SIZE, Pagination, pageToSkip } from 'utils/pagination';
import api from './api';

export const transcode = (
  file: UploadedFile,
  audioTracks: TranscodeAudioTrack[],
  subtitles: TranscodeSubtitle[] = [],
  streamingProfileId?: string
) =>
  api().put(`/source/${file.id}/transcode`, {
    transcodeAudioTracks: audioTracks,
    transcodeSubtitles: subtitles,
    streamingProfileId,
  });

export const getSourceFileAudioTracks = async (
  file: UploadedFile
): Promise<SourceFileAudioTrack[]> => {
  const { data } = await api().get(`/source/${file.id}/audio`);

  return data;
};

export const getSourceFileSubtitleTracks = async (
  fileId: number
): Promise<SourceFileSubtitleTrack[]> => {
  const { data } = await api().get<SourceFileSubtitleTrack[]>(
    `/source/${fileId}/subtitles`
  );

  return data;
};

interface AssignContentToFileParameters {
  cancelToken?: CancelToken;
  id: string | number;
  contentId: string | number;
}

export const assignContentToFile = async ({
  id,
  contentId,
  cancelToken,
}: AssignContentToFileParameters): Promise<void> => {
  await api().put(
    `/transcoded/${id}/content`,
    { id: contentId },
    {
      cancelToken,
    }
  );
};

export const unassignFromContent = async ({
  id,
  cancelToken,
}: {
  id: string | number;
  cancelToken?: CancelToken;
}): Promise<void> => {
  await api().delete(`/transcoded/${id}/content`, {
    cancelToken,
  });
};

export interface GetFilesOptions {
  searchQuery?: string;
  page?: number;
  fileStatuses?: FileStatus[] | string[];
}

export const getTranscodedFiles = async ({
  searchQuery,
  page,
  fileStatuses,
}: GetFilesOptions): Promise<Pagination<UploadedFile>> => {
  const { data } = await api().get(`/transcoded`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      status: fileStatuses?.join(','),
      query: searchQuery,
    },
  });

  return data;
};

export const getSourceFiles = async ({
  searchQuery,
  page,
  fileStatuses,
}: GetFilesOptions): Promise<Pagination<UploadedFile>> => {
  const { data } = await api().get(`/source`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      statuses: fileStatuses?.join(','),
      name: searchQuery,
    },
  });

  return data;
};

export const getSourceFileSummary = async (): Promise<{
  [key: string]: number;
}> => {
  const { data } = await api().get(`/source/summary`);

  return data;
};

export const getAvailableStreamingProfiles = async (): Promise<
  StreamingProfile[]
> => {
  const { data } = await api().get(`/source/streaming-profiles`);

  return data;
};
