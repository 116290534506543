import { useMemo } from 'react';
import { VideoFile } from 'types';

export const getSources = (videoFile: VideoFile) => {
  const dash = {
    src: videoFile.video.dash.url,
    type: videoFile.video.dash.type,
    keySystems: {
      'com.widevine.alpha': {
        supportedConfigurations: [
          {
            audioCapabilities: [
              {
                robustness: 'SW_SECURE_CRYPTO',
                contentType: 'audio/mp4;codecs="mp4a.40.2"',
              },
            ],
            videoCapabilities: [
              {
                robustness: 'SW_SECURE_CRYPTO',
                contentType: 'video/mp4;codecs="avc1.640028"',
              },
            ],
          },
        ],
        url: 'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=6E01EB',
      },
    },
  };

  const hls = {
    src: videoFile.video.hls.url,
    type: videoFile.video.hls.type,
  };

  return { dash, hls };
};

const useSources = (videoFile: VideoFile) => {
  const sources = useMemo(() => getSources(videoFile), [videoFile]);

  return sources;
};

export default useSources;
