import { FileStatus, Pagination, UploadedFile } from 'types';

export const getModifiedDataSource = <T extends UploadedFile>(
  localFile?: UploadedFile,
  pagination?: Pagination<T>,
  currentPage?: number
) => {
  if (!localFile) return pagination?.data;

  // Check if current local upload is also present in current pagination data
  const isCurrentUploadPresentInPagination = pagination?.data.some(
    (file) =>
      file.name === localFile.name && file.status === FileStatus.UPLOADING
  );

  if (isCurrentUploadPresentInPagination) {
    // If it is then add progress data to that file in pagination data
    return pagination?.data?.map((file) => {
      if (
        file.name === localFile.name &&
        file.status === FileStatus.UPLOADING
      ) {
        return file; // TODO: Rethink progress
        // return { ...file, progress: localFile.progress };
      }
      return file;
    });
  }

  if ((currentPage ?? 1) === 1) {
    // If it is not, but we are on the first page - let's add our local file
    return [localFile, ...(pagination?.data || [])];
  }

  return pagination?.data;
};
