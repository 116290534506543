import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserListClients from './UserListClients';
import DistributorsClientsList from './DistibutorsClientsList';
import DistributorsClientsList2 from './AuditLog';

export default () => (
  <Switch>
    <Route exact path={Paths.USERS} component={UserList} />
    <Route path={`${Paths.USERS}/page/:page`} component={UserList} />
    <Route path={`${Paths.USERS}/create`} component={UserCreate} />
    <Route exact path={Paths.CLIENTS} component={UserListClients} />
    <Route path={`${Paths.CLIENTS}/page/:page`} component={UserListClients} />
    <Route
      exact
      path={Paths.DISTRIBUTORS_CLIENTS}
      component={DistributorsClientsList}
    />
    <Route
      path={`${Paths.DISTRIBUTORS_CLIENTS}/page/:page`}
      component={DistributorsClientsList}
    />
    <Route exact path={Paths.AUDIT_LOG} component={DistributorsClientsList2} />
    <Route
      path={`${Paths.AUDIT_LOG}/page/:page`}
      component={DistributorsClientsList2}
    />
  </Switch>
);
