import isNaN from 'lodash/isNaN';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Pagination } from 'types';
import { PAGINATION_SIZE } from './constants';

export const parsePage = (page: any) => {
  const n = Number(page);

  if (isNaN(n)) return undefined;

  return n;
};

export type { Pagination } from 'types';
export { PAGINATION_SIZE } from './constants';

export const usePageParam = () => {
  const { page } = useParams<{ page: string }>();
  return parsePage(page);
};

/**
 * Creates pagination object for Table component
 * @param urlPrefix Prefix for the page URL i.e. /users
 * @param pagination Pagination object from backend
 * @param currentPage Current page
 */
export const usePaginationObject = (
  urlPrefix: string,
  pagination?: { count: number },
  currentPage?: number
) => {
  const { push } = useHistory();

  return {
    total: pagination?.count || 0,
    pageSize: PAGINATION_SIZE,
    current: currentPage,
    onChange: (page: number) => {
      if (page <= 0) {
        push(urlPrefix);
      } else {
        push(`${urlPrefix}/page/${page}`);
      }
    },
    showSizeChanger: false,
  };
};

export const pageToSkip = (page?: number, limit?: number) =>
  page ? (page - 1) * (limit || PAGINATION_SIZE) : 0;

export const getLastPage = <T>(pagination: Pagination<T>) => {
  return Math.max(Math.ceil(pagination.count / PAGINATION_SIZE), 1);
};

/**
 * Automatically move to the first page of the pagination
 * if our current page is ouf of bounds i.e. pagination has 2 pages, but
 * current user is on 5th page
 * @param urlPrefix Prefix for the page URL i.e. /users
 * @param pagination Pagination object from backend
 * @param currentPage Current page
 */
export const useBoundToPages = <T>(
  urlPrefix: string,
  pagination?: Pagination<T>,
  currentPage?: number
) => {
  const { push } = useHistory();

  useEffect(() => {
    if (!pagination) return;

    const lastPage = getLastPage(pagination);

    if ((currentPage ?? 1) > lastPage) {
      // Move to first page
      push(urlPrefix);
    }
  }, [currentPage, pagination, push, urlPrefix]);
};
