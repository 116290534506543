import axios from 'axios';
import {
  ListingPageTranslation,
  MappedTranslation,
  Translation,
} from 'types/translations';
import _flatMap from 'lodash/flatMap';
import _uniqueId from 'lodash/uniqueId';
import * as Sentry from '@sentry/react';
import { STATIC_URL } from './constants';
import { captureError } from './captureError';

export const fetchTranslations = async (
  platformLanguages: string[]
): Promise<Translation[]> => {
  const translations: Translation[] = [];
  if (!STATIC_URL) {
    return Promise.reject(() => {
      Sentry.captureException('STATIC_URL for translations is not provided');
      console.error('STATIC_URL for translations is not provided');
    });
  }
  for (let i = 0; i < platformLanguages?.length; i++) {
    try {
      const translationData = await axios.get<{ [key: string]: string }>(
        `${STATIC_URL}/translations/${platformLanguages[i]}.json`
      );

      translations.push({
        code: platformLanguages[i],
        translations: translationData.data,
      });
      // I left the catch empty, because if the backend doesn't return any file,
      // it returns 403 and stops other languages fetch
    } catch (error) {
      captureError(error as any);
    }
  }
  return translations;
};

export const mapJsonTranslations = (
  dataType: string,
  translations: Translation[]
): MappedTranslation[] =>
  translations.map((t) => ({
    langCode: t.code,
    translations: Object.entries(t.translations)
      .filter(([key]: [string, string]) => key.includes(dataType))
      .map(([key, value]: [string, string]) => {
        const name = key.split('.')[1];
        return {
          [name]: value,
        };
      }),
  }));

export const findTranslationsByName = (
  name: string,
  dataType: string,
  translations: Translation[]
): ListingPageTranslation['translations'] =>
  _flatMap(
    mapJsonTranslations(dataType, translations),
    (data: MappedTranslation) => ({
      id: _uniqueId(),
      langCode: data.langCode,
      value: data.translations
        .map((t) =>
          Object.entries(t)
            .filter(([key, value]: [string, string]) =>
              key === name ? value : false
            )
            .map(([_, value]) => value)
        )
        .join(''),
    })
  ).filter((t) => t.value.length);
