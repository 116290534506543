import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row } from 'antd';
import AssetChooser from 'components/AssetChooser';
import PageCard from 'components/PageCard';
import Space from 'components/Space';
import { Asset, AutoPopulateFilter, Collection } from 'types';
import { DEFAULT_ASSET_STATUSES_FOR_PAGINATION } from 'utils/constants';
import styles from '../../index.module.scss';
import AutoPopulateModal from './AutoPopulateModal';
import CollectionAssetTable from './CollectionAssetTable';

const CollectionAssets = ({
  collection,
  onCollectionChange,
  shouldValidate,
}: {
  collection: Collection;
  onCollectionChange: (collection: Collection) => void;
  shouldValidate: boolean;
}) => {
  const [openAutoPopulatedModal, setOpenAutoPopulatedModal] = useState<{
    open: boolean;
    filters?: AutoPopulateFilter;
  }>({ open: false });

  const [assetsWithDetails, setAssetsWithDetails] = useState<Asset[]>([]);
  const [chosenAsset, choseAsset] = useState<Asset>();
  const [error, setError] = useState<string>();

  const { assets, regions, filters } = collection;

  useEffect(() => {
    if (
      shouldValidate &&
      !assets?.length &&
      !(
        assets?.length ||
        Object.values(filters ? filters : {})
          .flat()
          .some((filter) => filter)
      )
    ) {
      setError('At least one asset or auto-populate must be added');
      return;
    }
    setError('');
  }, [assets, filters, shouldValidate]);

  const addAsset = (asset: Asset) => {
    if (assets && collection) {
      const assetWithOrder = {
        ...asset,
        order: assets.length + 1,
      };
      onCollectionChange({
        ...collection,
        assets: [...assets, assetWithOrder],
      });
      setAssetsWithDetails([...assetsWithDetails, assetWithOrder]);
    }
  };

  const checkIfFiltersAdded = (): boolean =>
    Object.values(collection.filters ? collection.filters : {})
      .flat()
      .some((value) => value);

  return (
    <>
      <PageCard
        title="Collection assets"
        extra={
          !checkIfFiltersAdded() && (
            <Button
              type="primary"
              className={styles.marginButton}
              onClick={() => {
                setOpenAutoPopulatedModal({
                  ...openAutoPopulatedModal,
                  open: true,
                });
              }}
            >
              Auto-populate
            </Button>
          )
        }
      >
        <Space direction="vertical" expand>
          <CollectionAssetTable
            assetsWithDetails={assetsWithDetails}
            onAssetWithDetailsChange={setAssetsWithDetails}
            onCollectionChange={onCollectionChange}
            collection={collection}
            onRemoveAutoPopulated={() => {
              const resetFilters = {
                tags: [],
                moods: [],
                genres: [],
                types: [],
              };
              setOpenAutoPopulatedModal({
                ...openAutoPopulatedModal,
                filters: resetFilters,
              });
              if (collection)
                onCollectionChange({
                  ...collection,
                  filters: resetFilters,
                });
            }}
            onEditAutoPopulated={() => {
              setOpenAutoPopulatedModal({
                filters: collection.filters,
                open: true,
              });
            }}
          />
          <Row gutter={[8, 8]}>
            <Col flex="auto">
              <AssetChooser
                onChoose={choseAsset}
                chosenAsset={chosenAsset}
                regionUuids={regions}
                statuses={DEFAULT_ASSET_STATUSES_FOR_PAGINATION}
                assetsUuidsToHide={assets?.map((a) => a.uuid)}
              />
            </Col>
            <Col flex="100px">
              <Button
                disabled={!chosenAsset}
                type="primary"
                icon={<PlusOutlined />}
                style={{ width: '100%' }}
                onClick={() => {
                  if (chosenAsset) {
                    addAsset(chosenAsset);
                    choseAsset(undefined);
                  }
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Space>

        {!!error && <Alert message={error} type="error" />}
      </PageCard>

      {openAutoPopulatedModal.open && (
        <AutoPopulateModal
          isModalOpen={openAutoPopulatedModal.open}
          onModalClose={() =>
            setOpenAutoPopulatedModal({
              ...openAutoPopulatedModal,
              open: false,
            })
          }
          defaultValues={openAutoPopulatedModal.filters}
          onAdd={(filters) => {
            setOpenAutoPopulatedModal({ open: false, filters });
            onCollectionChange({
              ...collection,
              filters,
            });
          }}
        />
      )}
    </>
  );
};

export default CollectionAssets;
