export const arrayMove = <T extends any[]>(
  array: T,
  oldIndex: number,
  newIndex: number
) => {
  if (oldIndex === newIndex) return array;

  const item = array[oldIndex];
  const newArray = (array ?? []).filter(
    (_: any, index: number) => index !== oldIndex
  );
  newArray?.splice(newIndex, 0, item);

  return newArray;
};
