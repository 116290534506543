import { DevicePlatform } from 'types/devices';
import { PagePlatform } from 'types/pages';
import { PlatformEntries } from '..';

export const getPlatforms = (platforms: PagePlatform, builderId: string) => {
  const platformsByBuilderId = (
    Object.entries(platforms) as PlatformEntries
  ).reduce((acc, [platform, elements]) => {
    elements.forEach(({ builderId }) => {
      acc[builderId] = acc[builderId]
        ? acc[builderId].add(platform)
        : new Set([platform]);
    });
    return acc;
  }, {} as { [builderId: string]: Set<DevicePlatform> });

  return Array.from(platformsByBuilderId[builderId] || []);
};
