import { DragHandle } from 'components/DraggableTableItems';
import Spacer from 'components/Spacer';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import React from 'react';
import { PageComponent } from 'types/pages';
import { PAGE_COMPONENTS } from 'utils/pageComponents';
import styles from './index.module.scss';

export interface ComponentTileProps {
  component: PageComponent;
  onDelete: () => void;
  onEdit: () => void;
}

const getColumns = (component: PageComponent) => {
  const spec = PAGE_COMPONENTS.find((c) => c.type === component.type);

  if (spec) {
    return {
      label: spec.label,
      Icon: spec.icon,
      Source: spec.source,
      canBeMoved: spec.canBeMoved,
    };
  }

  return {
    label: '',
    Icon: () => null,
    Source: (_: any) => null,
    canBeMoved: false,
  };
};

const ComponentTile = ({ component, onDelete, onEdit }: ComponentTileProps) => {
  const { label, Icon, Source, canBeMoved } = getColumns(component);

  return (
    <div className={styles.root}>
      <div className={styles.iconCol}>
        <Icon />
      </div>
      <div className={styles.componentCol}>{label}</div>
      {Source && (
        <div className={styles.sourceCol}>
          {component.uuid && <Source uuid={component.uuid} />}
        </div>
      )}
      <Spacer />
      <DeleteOutlined
        as="button"
        className={styles.deleteButton}
        onClick={onDelete}
      />
      {Source && (
        <EditOutlined
          className={styles.editButton}
          as="button"
          onClick={onEdit}
        />
      )}
      <div className={styles.dragHandle}>
        <DragHandle disabled={!canBeMoved} />
      </div>
    </div>
  );
};

export default ComponentTile;
