import React, { useCallback, useState } from 'react';
import { Divider, Skeleton } from 'antd';
import Button from 'antd/lib/button';
import { Asset, AssetAccessType, AssetChildren } from 'types';
import Space from 'components/Space';
import { changeAssetAccessType, getAssetsChildren } from 'actions/assets';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import _orderBy from 'lodash/orderBy';
import { errorNotify } from 'utils/errorNotify';
import { captureError } from 'utils/captureError';
import FreemiumEpisodesTable from './components/FreemiumTable';
import styles from './index.module.scss';
import { notifyAboutPublishRequirement } from './utils/notification';

export interface TouchedAsset {
  id: number;
  accessType: AssetAccessType;
}

const AssetFreemium = ({ asset }: { asset: Asset }) => {
  const [assetDescendants, setAssetDescendants] = useState<AssetChildren[]>([]);
  const [freemiumEpisodes, setFreemiumEpisodes] = useState<AssetChildren[]>([]);
  const [touchedAssets, setTouchedAssets] = useState<TouchedAsset[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const assetsToSave = useCallback(
    () =>
      touchedAssets.filter((a) => {
        const asset = assetDescendants.find(
          (descendant) => descendant.id === a.id
        );

        if (asset && asset.accessType !== a.accessType) return a;

        return null;
      }),
    [assetDescendants, touchedAssets]
  );

  const [fetchedChildren, isFetchingChildren] = useFetchAction(
    useCallback(
      () =>
        getAssetsChildren({
          parentId: asset.id,
          accessType: AssetAccessType.PAID,
        }),
      [asset]
    )
  );

  const [save] = useSendAction<void, TouchedAsset[]>(
    useCallback(async (assets: TouchedAsset[]) => {
      setIsSaving(true);
      try {
        for (const asset of assets) {
          await changeAssetAccessType(asset).then(() => {
            setAssetDescendants((prev) => [
              ...prev.filter((a) => a.id !== asset!.id),
              {
                ...prev.find((a) => a.id === asset.id)!,
                accessType: asset.accessType,
              },
            ]);
          });
        }
        setTouchedAssets([]);
        notifyAboutPublishRequirement();
      } catch (e) {
        errorNotify(e as any);
        captureError(e as any);
      } finally {
        setIsSaving(false);
      }
    }, [])
  );

  const addTouchedAsset = (asset: TouchedAsset) => {
    if (touchedAssets.some((a) => a.id === asset.id)) {
      setTouchedAssets((prev) => [
        ...prev.filter((a) => a.id !== asset.id),
        asset,
      ]);
      return;
    }

    setTouchedAssets((prev) => [...prev, asset]);
    return;
  };

  const fetchAssetChildren = (id: number) => {
    if (assetDescendants.some((a) => a.parentId === id)) return;

    return getAssetsChildren({ parentId: id }).then((res) => {
      setAssetDescendants((prev) => [...prev, ...res]);
      setFreemiumEpisodes((prev) => [
        ...prev,
        ...res.filter((f) => f.accessType === AssetAccessType.FREE),
      ]);
    });
  };

  const orderByAssetOrderAsc = (assets?: AssetChildren[]): AssetChildren[] =>
    _orderBy(assets, ['order'], ['asc']);

  if (!asset || isFetchingChildren) return <Skeleton />;

  return (
    <div>
      <h1 className={styles.moderationHeader}>Freemium Episodes</h1>
      <h2 className={styles.moderationSubheader}>
        All users will get an access to freemium assets without any payment
      </h2>
      <Divider />
      <Space direction="vertical" expand>
        <FreemiumEpisodesTable
          assetChildren={fetchedChildren}
          assetDescendants={assetDescendants}
          fetchAssetChildren={fetchAssetChildren}
          freemiumEpisodes={freemiumEpisodes}
          setFreemiumEpisodes={setFreemiumEpisodes}
          addTouchedAsset={addTouchedAsset}
          orderByAssetOrderAsc={orderByAssetOrderAsc}
        />
        <Button
          htmlType="submit"
          disabled={assetsToSave().length < 1}
          className={styles.actionBtn}
          loading={isSaving}
          type="primary"
          onClick={() => save(assetsToSave())}
        >
          Save
        </Button>
      </Space>
    </div>
  );
};

export default AssetFreemium;
