import { getPromotionCouponsPagination } from 'actions/promotions';
import Button from 'components/Button';
import React, { useCallback, useRef, useState } from 'react';
// @ts-ignore
import { CSVLink } from 'react-csv/lib';
import { CouponCSV, DiscountType, Promotion } from 'types/promotions';
import { CSVheaders, CouponIndex } from 'utils/coupons';
import moment from 'moment';
import { captureError } from 'utils/captureError';

interface CouponsCSVProps {
  promotion?: Promotion;
  couponsCount?: number;
}

const CouponsCSV = ({ promotion, couponsCount }: CouponsCSVProps) => {
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [allCoupons, setAllCoupons] = useState<CouponCSV[]>([]);
  const [isExportingCSV, setIsExportingCSV] = useState<boolean>(false);
  const generatedTime = moment().format('DD-MM-YYYY');

  const getHeadersByPromotionType = useCallback(() => {
    if (!promotion) return [];

    const keysToRemoveMap = {
      [DiscountType.AMOUNT]: [
        CouponIndex.DISCOUNT_PERCENTAGE,
        CouponIndex.DISCOUNT_PERIOD,
      ],
      [DiscountType.PERCENTAGE]: [
        CouponIndex.DISCOUNT_PERIOD,
        CouponIndex.DISCOUNT_AMOUNT,
      ],
      [DiscountType.PERIOD]: [
        CouponIndex.DISCOUNT_AMOUNT,
        CouponIndex.DISCOUNT_PERCENTAGE,
      ],
    };

    const keysToRemove = keysToRemoveMap[promotion.discountType];

    return CSVheaders.filter((header) => !keysToRemove.includes(header.key));
  }, [promotion]);

  const fetchAllCoupons = () => {
    if (!promotion || !couponsCount) return;

    setIsExportingCSV(true);
    getPromotionCouponsPagination({
      promotionUuid: promotion.uuid,
      couponsCount,
    })
      .then((coupons) => {
        const couponsToExport: CouponCSV[] = coupons.data.map((coupon) => ({
          ...coupon,
          promotionName: promotion.name,
          discountType: promotion.discountType,
          startDate: moment(promotion.startDate).format('DD-MM-YYYY'),
          endDate: moment(promotion.endDate).format('DD-MM-YYYY'),
          amount: promotion.amount,
          percentage: promotion.amount,
          duration: promotion.amount,
        }));

        setAllCoupons(couponsToExport);

        if (csvLinkRef?.current) {
          csvLinkRef.current.link.click();
        }
      })
      .catch((e) => captureError(e))
      .finally(() => {
        setIsExportingCSV(false);
      });
  };

  return (
    <>
      <Button loading={isExportingCSV} onClick={fetchAllCoupons}>
        Download as .csv
      </Button>
      <CSVLink
        data={allCoupons}
        headers={getHeadersByPromotionType()}
        filename={
          promotion
            ? `${promotion.name}_coupons_${generatedTime}.csv`
            : `promotion_coupons_${generatedTime}.csv`
        }
        ref={csvLinkRef}
        separator=";"
        target="_blank"
      />
    </>
  );
};

export default CouponsCSV;
