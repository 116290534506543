import React from 'react';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { deleteAssetMetadata, updateMetadata } from 'actions/assets';
import { Input } from 'antd';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import PublicDetails from 'components/PublicDetails';
import { useAssetDetailsContext } from 'contexts/assetContext';
import useUpdateAssetDetails from 'hooks/useUpdateAssetDetails';
import { Controller } from 'react-hook-form';
import { AssetMetadata } from 'types';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import styles from './index.module.scss';
import GeneralDetails from './pages/GeneralDetails';

const TextArea = Input.TextArea;

export default () => {
  const { assetDraft, setAssetDraft } = useAssetDetailsContext();
  const { isSaving, updateAsset } = useUpdateAssetDetails();

  const [sendMetadata] = useSendAction<AssetMetadata, AssetMetadata>(
    (metadata) => updateMetadata(assetDraft.uuid, metadata),
    {
      onDone: (metadata) =>
        setAssetDraft({
          ...assetDraft,
          translations: {
            ...assetDraft.translations,
            [metadata.selectedLanguage as string]: metadata,
          },
        }),
    }
  );

  const [removeMetadata] = useSendAction<
    { [key: string]: AssetMetadata },
    string
  >((lang) => deleteAssetMetadata(assetDraft.uuid, lang), {
    onDone: (data) => {
      setAssetDraft({ ...assetDraft, translations: data });
    },
  });

  return (
    <>
      <div className={styles.fullWidth}>
        <GeneralDetails updateAsset={updateAsset} isSaving={isSaving} />
        <div className={styles.publicDetailsContainer}>
          <PublicDetails<AssetMetadata>
            renderLanguageDetails={(value) => (
              <>
                <div className={styles.detailsElement}>
                  <span>Name</span>
                  <p>{value.name}</p>
                </div>
                <div className={styles.detailsElement}>
                  <span>Description</span>
                  <p>{value.description}</p>
                </div>
                {value.shortDescription && (
                  <div className={styles.detailsElement}>
                    <span>Short description</span>
                    <p>{value.shortDescription}</p>
                  </div>
                )}
              </>
            )}
            renderLanguageForm={(control, errors) => (
              <>
                <FormGroup
                  label="Name"
                  errorMessage={errors?.name?.message}
                  input={
                    <Controller
                      as={Input}
                      control={control}
                      name="name"
                      rules={{ required: 'Required' }}
                    />
                  }
                />
                <FormGroup
                  label="Description"
                  input={
                    <Controller
                      as={TextArea}
                      showCount
                      control={control}
                      name="description"
                    />
                  }
                />
                <FormGroup
                  label="Short description"
                  input={
                    <Controller
                      as={TextArea}
                      showCount
                      control={control}
                      name="shortDescription"
                    />
                  }
                />
              </>
            )}
            items={assetDraft.translations}
            onSaveLanguage={(_, details) => {
              sendMetadata(details);
              notifyAboutRepublishRequirement();
            }}
            onRemoveLanguage={(lang) => {
              removeMetadata(lang);
              notifyAboutRepublishRequirement();
            }}
          />
        </div>
        <div className={styles.buttonGroup}>
          <Button
            data-testid="AssetDetailsForm__submit"
            type="primary"
            htmlType="submit"
            form="asset-general-details-form"
            disabled={isSaving}
            loading={isSaving}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
