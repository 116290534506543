import React, { useCallback } from 'react';
import {
  createDraggableBodyRow,
  createDraggableContainer,
} from 'components/DraggableTableItems';
import { Table } from 'antd';
import { TableProps } from 'antd/es/table';

export interface DragAndDropProps<T> {
  onOrderChange: (data: T[]) => void;
}

const DragAndDropTable = <T extends { [key: string]: any }>({
  ...props
}: DragAndDropProps<T> & TableProps<T>) => {
  const { onOrderChange, rowKey, dataSource } = props;

  const onDropRender = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      if (!dataSource || oldIndex === newIndex) return;
      const item = dataSource[oldIndex];
      const newArray = dataSource?.filter((_, index) => index !== oldIndex);
      newArray?.splice(newIndex, 0, item);
      if (onOrderChange) onOrderChange(newArray ? newArray : []);
    },
    [dataSource, onOrderChange]
  );

  return (
    <Table
      {...props}
      dataSource={dataSource}
      rowKey={rowKey}
      components={{
        body: {
          wrapper: createDraggableContainer(onDropRender),
          row: createDraggableBodyRow(
            (props: any) =>
              dataSource?.findIndex(
                (d: T) => d[rowKey as string] === props['data-row-key']
              ),
            true
          ),
        },
      }}
    >
      {props.children}
    </Table>
  );
};

export default DragAndDropTable;
