import api from 'actions/api';
import { AvodConfigBody, AvodConfigResponse } from 'types';

export const getAvodConfiguration = async () => {
  const { data } = await api().get<AvodConfigResponse>(`/ad-configuration`);
  return data;
};

export const updateAvodConfiguration = async (body: AvodConfigBody) => {
  const { data } = await api().post<void>(`/ad-configuration`, body);
  return data;
};
