import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { Content, ContentType } from 'types';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { assignAssetToContent } from 'actions/content';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import notification from 'utils/notification';
import ContentChooser from 'components/ContentChooser';
import { Button } from 'antd';
import { PlayCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from '../../index.module.scss';
interface ContentSelectorProps {
  assignedContents?: Content[];
  isLoading?: boolean;
  refreshAssignedContent: () => void;
  filterBy?: ContentType[];
}

const ContentSelector = ({
  assignedContents,
  isLoading,
  refreshAssignedContent,
  filterBy,
}: ContentSelectorProps) => {
  const {
    asset: { id },
  } = useAssetDetailsContext();
  const [selectedContent, setSelectedContent] = useState<Content>();

  const noContentAdded = useMemo(
    () => !assignedContents?.length && !isLoading,
    [assignedContents, isLoading]
  );

  const [assign, isAssigning] = useSendAction(
    useCallback(
      () =>
        assignAssetToContent({
          id: selectedContent?.id!,
          assetId: id!,
        }),
      [id, selectedContent]
    ),
    {
      onDone: () => {
        refreshAssignedContent();
        setSelectedContent(undefined);
        notifyAboutRepublishRequirement();
      },
      onError: (error: any) => {
        notification.error({
          title: 'Error',
          description:
            error?.response?.data?.details ||
            'Something went wrong. Content has not been unassigned.',
        });
      },
    }
  );

  const onAssignClick = useCallback(() => {
    const hasAlreadyMainContent = assignedContents?.some(
      (c) => c.type === ContentType.MAIN
    );

    if (hasAlreadyMainContent && selectedContent?.type === ContentType.MAIN) {
      notification.error({
        title: 'Error',
        description:
          'Asset has already main content assigned. Please unassign it to continue.',
      });
      return;
    }

    assign();
  }, [assign, assignedContents, selectedContent]);

  return (
    <div
      className={cn(
        styles.contentChooserWrapper,
        noContentAdded && styles.contentChooserCentered
      )}
    >
      {noContentAdded && (
        <div className={styles.noContentWrapper}>
          <div className={styles.playIconWrapper}>
            <PlayCircleOutlined className={styles.playIcon} />
          </div>
          <p className={styles.noContentNote}>
            Assign your first video content
          </p>
        </div>
      )}

      <div className={styles.contentChooser}>
        <ContentChooser
          chosen={selectedContent}
          onChoose={setSelectedContent}
          filterHasAsset={false}
          filterBy={filterBy}
          assignedContents={assignedContents}
        />

        <Button
          disabled={!selectedContent || isLoading}
          loading={isAssigning}
          onClick={onAssignClick}
          className={styles.assignBtn}
          type="primary"
          icon={<PlusOutlined />}
        >
          Assign content
        </Button>
      </div>
    </div>
  );
};

export default ContentSelector;
