import { Language } from '@laminar-product/client-commons-core/core';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsLoading } from 'store/app/selectors';
import {
  fetchAllLanguages,
  fetchPlatformLanguages,
  updatePlatformLanguages,
} from 'store/languages/actions';
import {
  selectAllLanguages,
  selectDefaultPlatformLanguage,
  selectError,
  selectPlatformLanguages,
} from 'store/languages/selectors';
import { selectIsUserVerified } from 'store/user/selectors';
import { getType } from 'typesafe-actions';
import notification from 'utils/notification';

export const useAllLanguages = () => {
  const dispatch = useDispatch();
  const languages = useSelector(selectAllLanguages);
  const isLoadingLanguages = useSelector((state: RootState) =>
    getIsLoading(state, getType(fetchAllLanguages.request))
  );
  const isUserVerified = useSelector(selectIsUserVerified);
  const error = useSelector((state: RootState) =>
    selectError(state, getType(fetchAllLanguages.failure))
  );
  const getLanguageNameByIsoCode = useCallback(
    (isoCode: string) =>
      languages?.find((lang) => lang.alpha2 === isoCode)?.label || '',
    [languages]
  );

  useEffect(() => {
    if (!isUserVerified) return;
    if (!languages?.length && !isLoadingLanguages) {
      dispatch(fetchAllLanguages.request());
    }
  }, [dispatch, isLoadingLanguages, isUserVerified, languages]);

  return { languages, isLoadingLanguages, error, getLanguageNameByIsoCode };
};

export const usePlatformLanguages = () => {
  const dispatch = useDispatch();
  const platformLanguages = useSelector(selectPlatformLanguages);
  const defaultPlatformLanguage = useSelector(selectDefaultPlatformLanguage);
  const isUserVerified = useSelector(selectIsUserVerified);

  const getLanguageByIsoCode = useCallback(
    (isoCode?: string) =>
      platformLanguages.find((lang) => lang.alpha2 === isoCode),
    [platformLanguages]
  );

  const isLoadingLanguages = useSelector((state: RootState) =>
    getIsLoading(state, getType(fetchPlatformLanguages.request))
  );
  const error = useSelector((state: RootState) =>
    selectError(state, getType(fetchPlatformLanguages.failure))
  );

  useEffect(() => {
    if (!isUserVerified) return;
    if (!platformLanguages.length && !isLoadingLanguages && !error) {
      dispatch(fetchPlatformLanguages.request());
    }
  }, [dispatch, error, isLoadingLanguages, isUserVerified, platformLanguages]);

  return {
    platformLanguages,
    defaultPlatformLanguage,
    isLoadingLanguages,
    error,
    getLanguageByIsoCode,
  };
};

export const useUpdatePlatformLanguages = () => {
  const dispatch = useDispatch();
  const isUpdatingPlatformLanguages = useSelector((state: RootState) =>
    getIsLoading(state, getType(updatePlatformLanguages.request))
  );
  const error = useSelector((state: RootState) =>
    selectError(state, getType(updatePlatformLanguages.failure))
  );

  const handleUpdatePlatformLanguages = useCallback(
    (data: Language[]) => dispatch(updatePlatformLanguages.request(data)),
    [dispatch]
  );

  useEffect(() => {
    if (error) {
      notification.error({
        title: 'Error',
        description:
          'Something went wrong when updating languages. Please try again.',
      });
    }
  }, [error]);

  return {
    isUpdatingPlatformLanguages,
    error,
    handleUpdatePlatformLanguages,
  };
};
