import { Tag } from 'antd';
import React from 'react';
import { PageStatus } from 'types/pages';
import formatLabel from 'utils/formatLabel';

const statusToColor = {
  [PageStatus.CREATED]: 'blue',
  [PageStatus.PUBLISHED]: 'green',
  [PageStatus.UNPUBLISHED]: undefined,
};

const PageStatusTag = ({ status }: { status: PageStatus }) => (
  <Tag color={statusToColor[status]}>{formatLabel(status)}</Tag>
);

export default PageStatusTag;
