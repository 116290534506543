import { notification as AntdNotification } from 'antd';
import { ArgsProps } from 'antd/es/notification';
import { ReactNode } from 'react';

export type NotificationProps = {
  title?: ReactNode;
} & Omit<ArgsProps, 'message'>;

enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

const defaults: NotificationProps = { duration: 5 };

const body = (type: NotificationType, data: NotificationProps) => {
  const { title, ...rest } = data;
  AntdNotification[type]({
    message: title,
    ...defaults,
    ...rest,
  });
};

const error = (data: NotificationProps) => body(NotificationType.ERROR, data);
const success = (data: NotificationProps) =>
  body(NotificationType.SUCCESS, data);
const warning = (data: NotificationProps) =>
  body(NotificationType.WARNING, data);
const info = (data: NotificationProps) => body(NotificationType.INFO, data);

export default { error, success, warning, info };
