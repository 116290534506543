import React, { useCallback, useState } from 'react';
import { FileStatus, Pagination, UploadedFile } from 'types';
import { getTranscodedFiles } from 'actions/files';
import ErrorIndicator from 'components/ErrorIndicator';
import { Select, Spin } from 'antd';
import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';

const { Option } = Select;

interface FileChooserProps {
  onChoose: (file: UploadedFile) => void;
  chosen?: UploadedFile;
  filterFileStatus?: FileStatus[];
}

const FileChooser = ({
  onChoose,
  chosen,
  filterFileStatus,
}: FileChooserProps) => {
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 300);
  const [pagination, isLoading, error] = useFetchAction<
    Pagination<UploadedFile>
  >(
    useCallback(
      () =>
        getTranscodedFiles({
          searchQuery: queryDebounced,
          fileStatuses: filterFileStatus,
        }),
      [filterFileStatus, queryDebounced]
    )
  );

  return (
    <div>
      <Select
        showSearch
        value={chosen?.id}
        placeholder="Search for file"
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={(value) => setQuery(value)}
        onChange={(id: number) => {
          onChoose(pagination?.data?.find((file) => file.id === id)!);
        }}
        style={{ width: '100%' }}
      >
        {pagination?.data?.map((file) => (
          <Option key={file.id} value={file.id}>
            {file.name}
          </Option>
        ))}
      </Select>
      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default FileChooser;
