import { Modal } from 'antd';
import React, { useState } from 'react';
import Select, { Option } from 'components/Select';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAllLanguages } from 'hooks/useLanguages';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Language } from '@laminar-product/client-commons-core/core';
import { getPlatformLanguagesByIsoCode } from '../utils';
import styles from './index.module.scss';

interface AddLanguagesModalProps {
  onClose: () => void;
  isVisible: boolean;
  platformLanguages: Language[];
  updatePlatformLanguages: (data: Language[]) => void;
}

interface LanguagesForm {
  languages: string[];
}

const AddLanguagesModal = ({
  isVisible,
  onClose,
  platformLanguages,
  updatePlatformLanguages,
}: AddLanguagesModalProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [platformLanguagesToAdd, setPlatformLanguagesToAdd] = useState<
    Language[]
  >([]);
  const formMethods = useForm<LanguagesForm>({
    mode: 'onSubmit',
  });
  const { control, handleSubmit, reset, watch } = formMethods;
  const { languages, isLoadingLanguages } = useAllLanguages();

  const filteredLanguages = languages?.filter(
    (lang) =>
      !platformLanguages?.some(
        (platformLang) => platformLang.alpha2 === lang.alpha2
      )
  );

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleOnOk = (values: LanguagesForm) => {
    const platformLanguagesToAdd = getPlatformLanguagesByIsoCode({
      isoCodes: values.languages,
      languages,
    });

    if (!platformLanguagesToAdd?.length) return;

    setPlatformLanguagesToAdd(platformLanguagesToAdd);
    setIsConfirmationModalOpen(true);
  };

  const handleOnConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmationModalOnOk = () => {
    updatePlatformLanguages([...platformLanguages, ...platformLanguagesToAdd]);
    onClose();
  };

  return (
    <>
      <Modal
        visible={isVisible && !isConfirmationModalOpen}
        title="Add Languages"
        onCancel={handleOnClose}
        onOk={handleSubmit(handleOnOk)}
        okText={`Add (${watch('languages')?.length || 0})`}
      >
        <FormProvider {...formMethods}>
          <Controller
            as={
              <Select
                selectMode="multiple"
                filterOption={(input, option) =>
                  String(option?.children)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {filteredLanguages?.map(({ alpha2, label }) => (
                  <Option key={alpha2} value={alpha2}>
                    {label}
                  </Option>
                ))}
              </Select>
            }
            placeholder="Languages"
            loading={isLoadingLanguages}
            style={{ width: '100%' }}
            allowClear
            showSearch
            control={control}
            name="languages"
          />
        </FormProvider>
      </Modal>
      <Modal
        visible={isConfirmationModalOpen}
        onCancel={handleOnConfirmationModalClose}
        onOk={handleConfirmationModalOnOk}
        centered
        okText="Yes"
        cancelText="No"
        closable={false}
      >
        <div className={styles.modalWrapper}>
          <div className={styles.iconWrapper}>
            <ExclamationCircleOutlined
              style={{ color: 'orange', fontSize: 30 }}
            />
            <div className={styles.textBold}>
              Are you sure you want to add these languages to your platform?
            </div>
          </div>
          <div>
            <ul>
              {platformLanguagesToAdd.map(({ label }) => (
                <li>{label}</li>
              ))}
            </ul>
          </div>
          Users will be able to select one of these languages as their app
          language.
        </div>
      </Modal>
    </>
  );
};

export default AddLanguagesModal;
