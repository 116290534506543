import { Layout, Spin, message } from 'antd';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import Login from 'pages/Login';
import ManualPage from 'pages/Manual';
import { useEffect, useState } from 'react';
import { CurrentUserProvider } from 'contexts/currentUserContext';
import useQueryParam from 'hooks/useQueryParam';
import { PREVIEW_MODE } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserVerified } from 'store/user/selectors';
import { setUserVerified } from 'store/user/actions';
import styles from './App.module.scss';
import AppRoute from './AppRoute';
import MainSidebar from './components/MainSidebar';
import TopBar from './components/TopBar';

const { Header, Content: ContentLayout } = Layout;

const App = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const previewMode = useQueryParam(PREVIEW_MODE);
  const isUserVerified = useSelector(selectIsUserVerified);
  const dispatch = useDispatch();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, function (user) {
      setLoading(false);

      if (user) {
        dispatch(setUserVerified(true));
      } else {
        dispatch(setUserVerified(false));
      }
    });

    return () => unsubscribe();
  });

  useEffect(() => {
    if (isUserVerified) {
      auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
        const hasRoles = !!idTokenResult.claims.roles?.length;
        if (!hasRoles) {
          signOut(auth);
          message.error(
            'The email and password you entered did not match our records. Please double-check and try again.'
          );
        }
        setIsAdmin(hasRoles);
      });
    }
  }, [isAdmin, isUserVerified, auth]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  if (!isUserVerified || !isAdmin) {
    return <Login />;
  }

  if (window.location.pathname === '/help') {
    return <ManualPage />;
  }

  return (
    <CurrentUserProvider>
      {previewMode ? (
        <AppRoute />
      ) : (
        <Layout className={styles.layout}>
          <MainSidebar fixed />
          <Layout className={styles.layoutContent}>
            <Header className={styles.headerContent}>
              <TopBar />
            </Header>
            <ContentLayout>
              <AppRoute />
            </ContentLayout>
          </Layout>
        </Layout>
      )}
    </CurrentUserProvider>
  );
};

export default App;
