import api from './api';

export const assignSubscriptionToClient = async ({
  userUuid,
  planUuid,
  priceUuid,
}: {
  userUuid: string;
  planUuid: string;
  priceUuid: string;
}): Promise<any> =>
  await api().post(`/supervisor/subscription/publish`, {
    userUid: userUuid,
    planUuid,
    priceUuid,
  });
