import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getAuditLogConfigPagination } from 'actions/auditLog';
import { Table } from 'antd';
import moment from 'moment';
import { useCallback } from 'react';
import { Pagination } from 'types';
import { AuditLogConfigDetails } from 'types/auditLog';
import { usePageParam, usePaginationObject } from 'utils/pagination';
const { Column } = Table;

const AuditLogTable = () => {
  const page = usePageParam();

  const { data: pagination, isLoading } = useFetchActionObj<
    Pagination<AuditLogConfigDetails>
  >(
    useCallback(
      () =>
        getAuditLogConfigPagination({
          page,
        }),
      [page]
    )
  );

  const transformedData = (pagination?.data as AuditLogConfigDetails[])?.filter(
    (log: AuditLogConfigDetails) => {
      return moment(log.createdAt).isAfter(moment().subtract(3, 'months'));
    }
  );

  return (
    <div>
      <Table
        rowKey="uuid"
        dataSource={transformedData}
        loading={isLoading}
        pagination={usePaginationObject('/AuditLog', pagination, page || 1)}
      >
        <Column
          dataIndex="timeStamp"
          render={(_, log: AuditLogConfigDetails) =>
            log?.createdAt.slice(0, -2)
          }
        />
        <Column
          title="Agent"
          dataIndex="agent"
          render={(_, log: AuditLogConfigDetails) => log?.adminUser}
        />
        <Column
          title="Client"
          dataIndex="clientEmail"
          render={(_, log: AuditLogConfigDetails) => log?.user}
        />
        <Column
          title="Action Type"
          dataIndex="actionType"
          render={(_, log: AuditLogConfigDetails) => log.action}
        />
        <Column
          title="Details"
          dataIndex="details"
          render={(_, log: AuditLogConfigDetails) => log?.details}
        />
      </Table>
    </div>
  );
};
export default AuditLogTable;
