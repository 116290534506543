import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  PlatformPartnerPrice,
  addPlanToPlatformPartner,
  updatePlatformPartnerPlan,
} from 'actions/platformPartners';
import { Input, Modal } from 'antd';
import FormGroup from 'components/FormGroup';
import PlanChooser from 'components/PlanChooser';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  AddPlatformPartnerPlan,
  PlatformPartner,
  PlatformPartnerPlan,
} from 'types/platformPartners';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import { IntervalType } from 'types/plan';
import { removePriceFromPlan } from 'actions/plans';
import PartnerPriceForm from '../components/PartnerPriceForm';

interface AddPartnerPlanModalProps {
  partnerUuid: string;
  partnerPlan: PlatformPartnerPlan;
  onClose: () => void;
  onEdit: () => void;
}

const EditPartnerPlanModal = ({
  onClose,
  onEdit,
  partnerUuid,
  partnerPlan,
}: AddPartnerPlanModalProps) => {
  const [planPriceEnabled, setPlanPriceEnabled] = useState(
    partnerPlan.price !== null
  );
  const formMethods = useForm<AddPlatformPartnerPlan>({
    defaultValues: partnerPlan,
  });
  const { handleSubmit, control, errors } = formMethods;
  const { sendAction: editPlanToPlatformPartnerAction, isLoading } =
    useSendActionObj<PlatformPartner, AddPlatformPartnerPlan>(
      async (payload) => {
        const planPrice =
          planPriceEnabled && payload.price
            ? ({
                ...payload.price,
                amount: Number(payload.price.amount),
                interval: payload.price.interval || IntervalType.DAY,
                intervalMultiplier: Number(payload.price.intervalMultiplier),
                // Note: INR is hardcoded for now in the first phase
                currency: 'INR',
              } as PlatformPartnerPrice)
            : undefined;

        const parsedPayload = {
          ...payload,
          price: planPrice,
        };

        if (!planPrice && partnerPlan.price?.uuid) {
          await removePriceFromPlan({
            planUuid: partnerPlan.plan.uuid,
            priceUuid: partnerPlan.price.uuid,
          });
          return addPlanToPlatformPartner(partnerUuid, {
            ...parsedPayload,
            planId: partnerPlan.plan.uuid,
          });
        }

        return updatePlatformPartnerPlan(
          partnerUuid,
          partnerPlan.uuid,
          parsedPayload
        );
      },
      {
        onDone: () => {
          onEdit();
          onClose();
        },
        onError: (error) => {
          notification.error({
            title: 'Error',
            description: 'Something went wrong. Please try again.',
          });
          captureError(error as any);
        },
      }
    );

  return (
    <Modal
      visible={!!partnerPlan}
      onCancel={onClose}
      title="Edit"
      okText="Save"
      onOk={handleSubmit(editPlanToPlatformPartnerAction)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <FormGroup
          input={
            <Controller
              render={(field) => (
                <PlanChooser
                  disabled
                  onChoose={(plan) => field.onChange(plan.uuid)}
                  chosenPlanId={field.value}
                />
              )}
              control={control}
              name="plan.uuid"
            />
          }
          label="Plan"
          errorMessage={errors?.planId?.message}
        />

        <FormGroup
          input={
            <Controller
              placeholder="Enter the external ID"
              as={Input}
              control={control}
              name="externalId"
              rules={{ required: 'This field is required' }}
            />
          }
          label="External ID"
          errorMessage={errors?.externalId?.message}
        />

        <PartnerPriceForm
          control={control}
          errors={errors}
          planPriceEnabled={planPriceEnabled}
          setPlanPriceEnabled={setPlanPriceEnabled}
        />
      </FormProvider>
    </Modal>
  );
};

export default EditPartnerPlanModal;
