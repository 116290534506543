import { Button, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { Coupon, Promotion } from 'types/promotions';
import { DeleteOutlined, PercentageOutlined } from '@ant-design/icons';
import PageCard from 'components/PageCard';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import {
  getPromotionCouponsPagination,
  removeCoupon,
} from 'actions/promotions';
import { Pagination } from 'types';
import { errorNotify } from 'utils/errorNotify';
import { usePageParam, usePaginationObject } from 'utils/pagination';
import { CouponIndex } from 'utils/coupons';
import { captureError } from 'utils/captureError';
import styles from './index.module.scss';
import CouponsModal from './CouponsModal';
import CouponsCSV from './CouponsCSV';
import CouponsRemoveModal from './CouponsRemoveModal';

interface CouponsTableProps {
  promotion?: Promotion;
}

const CouponsTable = ({ promotion }: CouponsTableProps) => {
  const page = usePageParam();
  const [selectedCouponId, setSelectedCouponId] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] =
    useState<boolean>(false);
  const [coupons, isGettingCoupons, , refreshCoupons] = useFetchAction<
    Pagination<Coupon>
  >(
    useCallback(() => {
      if (!promotion) return Promise.reject();

      return getPromotionCouponsPagination({
        promotionUuid: promotion.uuid,
        page,
      });
    }, [promotion, page])
  );

  const handleRemove = () => {
    if (!selectedCouponId) return;

    removeCoupon(selectedCouponId)
      .then(() => {
        refreshCoupons();
      })
      .catch((e) => {
        captureError(e);
        errorNotify(e);
      })
      .finally(() => {
        setSelectedCouponId('');
        setIsRemoveModalVisible(false);
      });
  };

  return (
    <>
      <div className={styles.fullWidth}>
        <PageCard
          title="Coupons"
          icon={<PercentageOutlined />}
          extra={
            <div className={styles.buttons}>
              {coupons?.data && coupons?.data.length > 0 && (
                <CouponsCSV
                  promotion={promotion}
                  couponsCount={coupons.count}
                />
              )}
              <Button type="primary" onClick={() => setIsModalVisible(true)}>
                Add coupon
              </Button>
            </div>
          }
        >
          <Table
            loading={isGettingCoupons}
            indentSize={40}
            dataSource={coupons?.data || []}
            rowClassName="clickable-row"
            rowKey="uuid"
            pagination={usePaginationObject(
              `/affiliate/${promotion?.uuid}/coupons`,
              coupons,
              page
            )}
          >
            <Column title="Code" dataIndex={CouponIndex.CODE} />
            <Column title="Type" dataIndex={CouponIndex.TYPE} />
            <Column
              title="Used"
              dataIndex={CouponIndex.USED_COUNT}
              render={(used: number | null, coupon: Coupon) =>
                `${used || 0}/${coupon.quantity}`
              }
            />
            <Column
              dataIndex={CouponIndex.DELETE}
              key="delete"
              width={50}
              render={(_, coupon: Coupon) => (
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedCouponId(coupon?.uuid);
                    setIsRemoveModalVisible(true);
                  }}
                />
              )}
            />
          </Table>

          <CouponsModal
            coupons={coupons}
            refresh={refreshCoupons}
            promotionUuid={promotion?.uuid}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />

          <CouponsRemoveModal
            isVisible={isRemoveModalVisible}
            onClose={() => setIsRemoveModalVisible(false)}
            onOk={handleRemove}
          />
        </PageCard>
      </div>
    </>
  );
};

export default CouponsTable;
