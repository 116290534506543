import { Pagination } from 'types';
import {
  BasicPrepaidCard,
  BatchGenerateRequest,
  BatchPinExportStatus,
  PinDetailsResponse,
  PrepaidCard,
  PrepaidCardBatch,
  PrepaidCardStatus,
} from 'types/prepaidCard';
import { NESTED_PAGINATION_SIZE } from 'utils/constants';
import { PAGINATION_SIZE, pageToSkip } from 'utils/pagination';
import api from './api';

export const createPrepaidCard = async ({
  title,
  planId,
  billingFrequency,
  intervalMultiplier,
}: BasicPrepaidCard): Promise<PrepaidCard> => {
  const { data } = await api().post(`/prepaid/prepaid`, {
    title,
    planId,
    billingFrequency,
    intervalMultiplier,
  });
  return data;
};

export const getPrepaidCardsPagination = async ({
  page,
  planUuid,
  status,
}: {
  page?: number;
  planUuid?: string;
  status?: PrepaidCardStatus;
}): Promise<Pagination<PrepaidCard>> => {
  const { data } = await api().get(`/prepaid/prepaid`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      planId: planUuid,
      status,
    },
  });

  return data;
};

export const getPrepaidCard = async ({
  uuid,
}: {
  uuid: string;
}): Promise<PrepaidCard> => {
  const { data } = await api().get(`/prepaid/prepaid/${uuid}`);
  return data;
};

export const editPrepaidCard = async ({
  prepaidCard,
  id,
}: {
  id: string;
  prepaidCard: BasicPrepaidCard;
}): Promise<PrepaidCard> => {
  const { data } = await api().patch(`/prepaid/prepaid/${id}`, {
    ...prepaidCard,
  });

  return data;
};

export const getPrepaidCardBatchesPagination = async ({
  page = 0,
  prepaidCardUuid,
  limit = NESTED_PAGINATION_SIZE,
}: {
  page?: number;
  prepaidCardUuid: string;
  limit?: number;
}): Promise<Pagination<PrepaidCardBatch>> => {
  const { data } = await api().get(
    `/prepaid/prepaid/${prepaidCardUuid}/batch`,
    {
      params: {
        skip: pageToSkip(page, limit),
        limit,
      },
    }
  );

  return data;
};

export const publishPrepaidCard = async (
  uuid: string
): Promise<PrepaidCard> => {
  const { data } = await api().post(`/prepaid/prepaid/${uuid}/publish`);
  return data;
};

export const unpublishPrepaidCard = async (
  uuid: string
): Promise<PrepaidCard> => {
  const { data } = await api().post(`/prepaid/prepaid/${uuid}/unpublish`);
  return data;
};

export const generatePrepaidBatch = async ({
  batch,
  prepaidCardUuid,
}: BatchGenerateRequest): Promise<PrepaidCardBatch> => {
  const { data } = await api().post(
    `/prepaid/prepaid/${prepaidCardUuid}/batch`,
    {
      ...batch,
    }
  );
  return data;
};

export const downloadPinsFromBatch = async ({
  batchUuid,
  pinRedeemStatus,
  prepaidCardUuid,
}: {
  batchUuid: string;
  pinRedeemStatus?: BatchPinExportStatus;
  prepaidCardUuid: string;
}): Promise<string> => {
  const { data } = await api().get(
    `/prepaid/prepaid/${prepaidCardUuid}/batch/${batchUuid}/pins`,
    {
      params: {
        redeemed: pinRedeemStatus,
      },
    }
  );
  return data;
};

export const downloadAllPinsFromPrepaidCard = async ({
  prepaidCardUuid,
  pinRedeemStatus,
}: {
  prepaidCardUuid: string;
  pinRedeemStatus?: BatchPinExportStatus;
}): Promise<string> => {
  const { data } = await api().get(`/prepaid/prepaid/${prepaidCardUuid}/pins`, {
    params: { redeemed: pinRedeemStatus },
  });
  return data;
};

export const findPinDetails = async (
  pinValue: string
): Promise<PinDetailsResponse> => {
  const { data } = await api().get(`/prepaid/pin/${pinValue}`);
  return data;
};

export const removePrepaid = async (prepaidCardUuid: string): Promise<void> =>
  await api().delete(`/prepaid/prepaid/${prepaidCardUuid}`);
