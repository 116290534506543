import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { deleteAssetLicense, deleteContentAvailability } from 'actions/license';
import { Modal } from 'antd';
import { useAssetDetailsContext } from 'contexts/assetContext';
import React, { useCallback } from 'react';
import {
  AvailabilityTableData,
  LICENSE_DELETE_PROHIBITED_ERROR_CODE,
  LicenseSourceType,
} from 'types/licenses';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import styles from '../index.module.scss';

interface DeleteLicenseModalProps {
  isOpen?: boolean;
  onClose: () => void;
  refreshLicenseTable: () => void;
  license: AvailabilityTableData;
  type: LicenseSourceType;
}

const DeleteLicenseModal = ({
  onClose,
  refreshLicenseTable,
  isOpen,
  license,
  type,
}: DeleteLicenseModalProps) => {
  const { asset } = useAssetDetailsContext();

  const onDeleteSuccess = () => {
    notification.success({
      title: 'Success',
      description: `${
        type === 'content' ? 'Content availability' : 'License'
      } has been removed! Please note that changed assets needs to be republished to reflect the changes.`,
    });
    onClose();
    refreshLicenseTable();
  };

  const onDeleteFailure = (error: any) => {
    if (error?.response?.data?.code === LICENSE_DELETE_PROHIBITED_ERROR_CODE) {
      notification.error({
        title: `You cannot remove license from ${license.region.name} region`,
        description: `Before license removal, please make sure you have unpublished this asset and all of its children from ${license.region.name} region.`,
      });

      return;
    }

    notification.error({
      title: 'Error',
      description:
        error?.response?.data?.details ||
        `Something went wrong. ${
          type === 'content' ? 'Content availability' : 'License'
        } has not been removed.`,
    });

    captureError(error);
  };

  //If uuid the deletion is about content availability, otherwise about asset license
  const { sendAction: deleteLicense, isLoading: isDeleting } = useSendActionObj(
    useCallback(() => {
      if (type === 'content' && 'uuid' in license) {
        return deleteContentAvailability(license.uuid);
      }

      if (type === 'asset' && 'id' in license) {
        return deleteAssetLicense({ assetId: asset.id, licenseId: license.id });
      }

      return Promise.resolve();
    }, [asset.id, license, type]),
    {
      onDone: onDeleteSuccess,
      onError: onDeleteFailure,
    }
  );

  const onDeleteConfirm = () => deleteLicense();

  return (
    <Modal
      title={`Are you sure you want to remove a ${
        type === 'content' ? 'content availability' : 'license'
      } in ${license.region.name} region?`}
      visible={isOpen}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      onOk={onDeleteConfirm}
      confirmLoading={isDeleting}
    >
      <div>
        {type === 'asset' && (
          <div>
            Please note that all license data will be erased and you will not be
            able to undo this action.
          </div>
        )}

        {type === 'content' && (
          <div>
            Please note that video content won't be available in{' '}
            <span className={styles.bold}>{license.region.name}</span> region.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteLicenseModal;
