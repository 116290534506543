import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FeaturedAssetDetails from './components/FeaturedAssetDetails';
import FeaturedAssetsList from './components/FeaturedAssetsList';

const FeaturedAssets = () => (
  <Switch>
    <Route exact path="/featured-assets" component={FeaturedAssetsList} />
    <Route path="/featured-assets/page/:page" component={FeaturedAssetsList} />
    <Route path="/featured-assets/:id" component={FeaturedAssetDetails} />
  </Switch>
);

export default FeaturedAssets;
