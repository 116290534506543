import { RootState } from 'store';
import { AIRecognitionStatus } from 'types/ai';

export const selectAiAutoRecognitionStatus = (
  state: RootState,
  sourceFileId?: string
): AIRecognitionStatus => {
  return sourceFileId
    ? state.ai.recognition[sourceFileId]?.auto
    : 'not_started';
};

export const selectAiFaceRecognitionStatus = (
  state: RootState,
  sourceFileId?: string
): AIRecognitionStatus => {
  return sourceFileId
    ? state.ai.recognition[sourceFileId]?.faces
    : 'not_started';
};
