import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import ContentDistributionConfigDetails from './ContentDistributionConfigDetails';
import ContentDistributionConfigList from './ContentDistributionConfigList';

const ContentDistribution = () => (
  <Switch>
    <Route
      exact
      path={Paths.CONTENT_DISTRIBUTION}
      component={ContentDistributionConfigList}
    />
    <Route
      path={`${Paths.CONTENT_DISTRIBUTION}/page/:page`}
      component={ContentDistributionConfigList}
    />
    <Route
      path={`${Paths.CONTENT_DISTRIBUTION}/configuration/:id`}
      component={ContentDistributionConfigDetails}
    />
  </Switch>
);

export default ContentDistribution;
