import React, { ChangeEvent, useCallback, useState } from 'react';
import { getClientsPagination } from 'actions/users';
import { Pagination, User } from 'types';
import PageHeader from 'components/PageHeader';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import {
  useFetchAction,
  useFetchActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { usePageParam } from 'utils/pagination';
import Search from 'components/Search';
import { useHistory } from 'react-router';
import Button from 'components/Button';
import PrepaidCardsPinSearch from 'pages/PrepaidCards/components/PrepaidCardsPinSearch';
import { PlatformPartner } from 'types/platformPartners';
import { getPlatformPartners } from 'actions/platformPartners';
import {
  MAX_PAGINATION_LIMIT,
  MIN_QUERY_LENGTH_CLIENT_SEARCH,
} from 'utils/constants';
import { Paths } from 'types/paths';
import UserClientsTable from './UserClientsTable';
import { UserClientsFilters } from './UserClientsTable/helpers';
import UserSearchTooltip from './UserSearchTooltip';

const UserListClients = () => {
  const page = usePageParam();
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<UserClientsFilters>({});
  const [whoRedeemedModalOpen, setWhoRedeemedModalOpen] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const history = useHistory();

  const action = useCallback(
    () =>
      getClientsPagination({
        page: page ?? 1,
        query,
        platformPartnerId: filters?.userType,
      }),
    [filters, page, query]
  );

  const [pagination, isLoading, error, refresh] =
    useFetchAction<Pagination<User>>(action);

  const { data: platformPartners } = useFetchActionObj<
    Pagination<PlatformPartner>
  >(
    useCallback(() => getPlatformPartners({ limit: MAX_PAGINATION_LIMIT }), [])
  );

  const onSearchSubmit = useCallback(
    (queryValue: string) => {
      if (!canSearch) {
        return;
      }

      const formattedQuery = queryValue.replace(/\s/g, ''); //Remove all spaces from query string

      setQuery(formattedQuery);
      history.push(`${Paths.CLIENTS}/page/1`);
    },
    [canSearch, history]
  );

  const onSearchQueryChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      //Handles allowing for search flag
      setCanSearch(value.length >= MIN_QUERY_LENGTH_CLIENT_SEARCH);

      //Handles reset to default list if query is empty
      if (value.length === 0) {
        setQuery('');
      }
    },
    []
  );

  if (error) return <ErrorIndicator error={error} />;

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Clients"
          extra={[
            <Button
              key="whoRedeemed"
              onClick={() => setWhoRedeemedModalOpen(true)}
            >
              Who redeemed
            </Button>,
            <Search
              placeholder="Search"
              loading={isLoading}
              onSearch={onSearchSubmit}
              tooltipText="Enter at least 3 characters to search"
              tooltipOpen={!canSearch}
              onChange={onSearchQueryChange}
            />,
            <UserSearchTooltip />,
          ]}
        />
        <UserClientsTable
          page={page ?? 1}
          pagination={pagination}
          loading={isLoading}
          refresh={refresh}
          filters={filters}
          setFilters={setFilters}
          platformPartners={platformPartners?.data}
        />
      </PageContainer>

      {whoRedeemedModalOpen && (
        <PrepaidCardsPinSearch
          isOpen={whoRedeemedModalOpen}
          onClose={() => setWhoRedeemedModalOpen(false)}
          modalTitle="Who redeemed"
          searchingForRedeemedByUser
        />
      )}
    </>
  );
};

export default UserListClients;
