import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import classNames from 'classnames';
import styles from './index.module.scss';

interface PageContainerProps {
  isLoading?: boolean;
  transparent?: boolean;
}

const PageContainer = ({
  children,
  className,
  isLoading,
  transparent,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  PageContainerProps) => (
  <div className={styles.root}>
    <div
      className={classNames(styles.content, className, {
        [styles.transparent]: transparent,
      })}
      {...props}
    >
      {isLoading ? <LoadingIndicator /> : children}
    </div>
  </div>
);

export default PageContainer;
