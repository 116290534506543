import { Divider, Modal } from 'antd';
import Button from 'components/Button';
import React from 'react';
import ButtonsRow from 'components/ButtonsRow';

const ResetPasswordModalForm = ({
  selectedUserEmail,
  clearModal,
  visible,
  sendPasswordResetEmail,
}: {
  selectedUserEmail: string;
  clearModal: () => void;
  visible: boolean;
  sendPasswordResetEmail: (email: string) => Promise<void>;
}) => (
  <Modal
    visible={visible}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
    onCancel={() => clearModal()}
    closable={false}
    destroyOnClose
    footer={false}
    title={'Reset Password'}
  >
    <p>
      Are you sure you want to reset password for <b>{selectedUserEmail}</b> ?
      User will receive an email with a link to a form to reset his own
      password.
    </p>
    <Divider type="horizontal" />
    <ButtonsRow>
      <Button onClick={() => clearModal()}>No, cancel</Button>
      <Button
        type="primary"
        onClick={() => sendPasswordResetEmail(selectedUserEmail)}
      >
        Yes, reset
      </Button>
    </ButtonsRow>
  </Modal>
);

export default ResetPasswordModalForm;
