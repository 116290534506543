import { findPinDetails } from 'actions/prepaid';
import { getUserDetails } from 'actions/user';
import { Button, Input, Modal } from 'antd';
import { ErrorMessage } from 'components/ErrorIndicator';
import React, { useState } from 'react';
import { PinDetailsResponse } from 'types/prepaidCard';
import { UserClientData } from 'types/user';
import { captureError } from 'utils/captureError';
import styles from './index.module.scss';
import PrepaidCardsPinSearchResult from './PrepaidCardsPinSearchResult';

interface PrepaidCardsPinSearchProps {
  isOpen: boolean;
  onClose: () => void;
  searchingForRedeemedByUser?: boolean;
  modalTitle: string;
}

const PrepaidCardsPinSearch = ({
  isOpen,
  onClose,
  searchingForRedeemedByUser,
  modalTitle,
}: PrepaidCardsPinSearchProps) => {
  const [pinValue, setPinValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [hasSearchedForPin, setHasSearchedForPin] = useState(false);
  const [pinDetails, setPinDetails] = useState<PinDetailsResponse>();
  const [pinUserDetails, setPinUserDetails] = useState<UserClientData>();
  const [isLoading, setIsLoading] = useState(false);

  const searchPinDetails = async () => {
    setError('');
    setIsLoading(true);

    try {
      const pinDetails = await findPinDetails(pinValue);

      if (pinDetails?.pin) {
        setPinDetails(pinDetails);
      }

      if (pinDetails?.pin?.redeemerId) {
        const user = await getUserDetails(pinDetails?.pin.redeemerId);
        setPinUserDetails(user);
      }
    } catch (error) {
      captureError(error as any);
    }

    setIsLoading(false);
    setHasSearchedForPin(true);
  };

  const onSubmit = () => {
    if (!pinValue?.length) {
      setError('This field is required');
      return;
    }

    setPinDetails(undefined);
    searchPinDetails();
  };

  const onInputValueChange = (value: string) => {
    setPinValue(value);
    setHasSearchedForPin(false);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title={modalTitle}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="Close"
      onOk={onClose}
    >
      <div className={styles.wrapper}>
        <Input
          value={pinValue}
          onChange={(e) => onInputValueChange(e.target.value)}
          placeholder="Enter PIN number"
        />

        <Button
          onClick={onSubmit}
          disabled={hasSearchedForPin}
          className={styles.searchButton}
          loading={isLoading}
        >
          Search
        </Button>
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!pinDetails && hasSearchedForPin && (
        <p className={styles.error}>
          Sorry, it seems that PIN{' '}
          <span className={styles.bold}>{pinValue}</span> does not exist or
          there was an error during the request. Please check provided values or
          try again later.
        </p>
      )}

      {!!pinDetails && (
        <div className={styles.resultWrapper}>
          <PrepaidCardsPinSearchResult
            pinDetails={pinDetails}
            searchingForRedeemedByUser={searchingForRedeemedByUser}
            pinUserDetails={pinUserDetails}
          />
        </div>
      )}
    </Modal>
  );
};

export default PrepaidCardsPinSearch;
