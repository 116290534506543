import React, { useCallback, useMemo } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  FeaturedAssetEditModeChangeProps,
  FeaturedAssetSource,
  FeaturedAssetSourceEditProps,
} from 'types/featuredAsset';
import { Button } from 'antd';
import moment from 'moment';
import styles from '../../index.module.scss';

interface ScheduledQueueAssetEditProps {
  originalAsset?: FeaturedAssetSource;
  isEditMode: boolean;
  onEditModeChange: (data: FeaturedAssetEditModeChangeProps) => void;
  onAssetChange: (data: FeaturedAssetSourceEditProps) => void;
  item: FeaturedAssetSource;
}

const ScheduledQueueAssetEdit = ({
  originalAsset,
  isEditMode,
  onAssetChange,
  item,
  onEditModeChange,
}: ScheduledQueueAssetEditProps) => {
  const { sourceId, dateFrom, dateTo } = item;

  const availableDatesProvided = useMemo(
    () => dateFrom && dateTo,
    [dateFrom, dateTo]
  );

  const onRemoveAssetFromQueue = () => {
    onAssetChange({ assetToEdit: item, source: 'current', type: 'remove' });
    onAssetChange({ assetToEdit: item, source: 'original', type: 'remove' });
    onEditModeChange({ uuid: sourceId, type: 'removeFromEdit' });
  };

  const onSaveEditingAsset = useCallback(() => {
    //Besides form update original state after saving
    onAssetChange({ assetToEdit: item, source: 'original', type: 'edit' });
    onEditModeChange({ uuid: item.sourceId, type: 'removeFromEdit' });
  }, [item, onAssetChange, onEditModeChange]);

  const onCancelEditingAsset = useCallback(() => {
    if (originalAsset) {
      onAssetChange({
        assetToEdit: originalAsset,
        source: 'current',
        type: 'edit',
      });
    }

    onEditModeChange({ uuid: item.sourceId, type: 'removeFromEdit' });
  }, [item.sourceId, onAssetChange, onEditModeChange, originalAsset]);

  const onEditIconPress = () =>
    onEditModeChange({ uuid: item.sourceId, type: 'addToEdit' });

  const isDatesValidationError = useCallback(
    () =>
      !!dateFrom &&
      !!dateTo &&
      moment(Number(dateTo)).isSameOrBefore(Number(dateFrom), 'minute'),

    [dateFrom, dateTo]
  );

  return (
    <>
      <div className={styles.actionIconsWrapper}>
        {isEditMode && (
          <>
            <Button
              type="primary"
              disabled={!availableDatesProvided || isDatesValidationError()}
              onClick={onSaveEditingAsset}
            >
              Save
            </Button>

            <Button
              onClick={
                !!originalAsset ? onCancelEditingAsset : onRemoveAssetFromQueue
              }
            >
              {!!originalAsset ? 'Cancel' : 'Remove'}
            </Button>
          </>
        )}

        {!isEditMode && (
          <>
            <EditOutlined
              className={styles.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                onEditIconPress();
              }}
            />

            <DeleteOutlined
              className={styles.actionIcon}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveAssetFromQueue();
              }}
            />
          </>
        )}
      </div>

      {isDatesValidationError() && (
        <p className={styles.invalidDatesError}>
          Available to date should be after available from date
        </p>
      )}
    </>
  );
};

export default ScheduledQueueAssetEdit;
