import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch } from 'react-redux';
import { runAutoAiRecognitionAction } from 'store/ai/actions';
import styles from './index.module.scss';

interface GetAIMetadataModalProps {
  isVisible: boolean;
  onClose: () => void;
  onOk?: () => void;
  sourceFileId?: string;
}

const GetAIMetadataModal = ({
  isVisible,
  onClose,
  onOk,
  sourceFileId,
}: GetAIMetadataModalProps) => {
  const dispatch = useDispatch();

  const runAi = () => {
    if (!sourceFileId) {
      message.error('Source file is missing');
    } else {
      dispatch(runAutoAiRecognitionAction.request({ sourceFileId }));
    }
    onClose();
  };
  return (
    <Modal
      title="Are you sure you want to run AI Analysis"
      visible={isVisible}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      onOk={runAi}
    >
      <div className={styles.root}>
        <ExclamationCircleFilled className={styles.icon} />
        <span className={styles.message}>
          You won't be able to stop this process and unassign selected content
          from the asset.
        </span>
      </div>
    </Modal>
  );
};

export default GetAIMetadataModal;
