import React, { useCallback, useEffect } from 'react';
import { updateContent } from 'actions/content';
import { Divider, Input } from 'antd';
import { Content, ContentMainDetails } from 'types';
import ErrorIndicator from 'components/ErrorIndicator';
import LoadingIndicator from 'components/LoadingIndicator';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Space from 'components/Space';
import Button from 'components/Button';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { errorNotify } from 'utils/errorNotify';
import { RouteIdParams } from 'utils/routeParams';
import ContentTypeEdit from '../ContentTypeEdit';

const MainContentDetailsForm = ({
  content,
  onSave,
}: {
  content: Content;
  onSave: (data: Content) => void;
}) => {
  const { handleSubmit, errors, reset, formState, control } =
    useForm<ContentMainDetails>({
      mode: 'onSubmit',
      defaultValues: content,
    });

  const onSubmit = (form: ContentMainDetails) =>
    onSave({ ...content, ...form });

  useEffect(() => {
    reset(content);
  }, [content, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <Space direction="vertical">
          <div>
            <label>Title</label>
            <Controller as={Input} control={control} name="title" required />
            {errors?.title && <div>This field is required</div>}
          </div>

          <div>
            <label>Description</label>
            <Controller
              as={Input}
              control={control}
              name="description"
              required
            />
            {errors?.description && <div>This field is required</div>}
          </div>
          <Button
            style={{ marginTop: '12px' }}
            type="primary"
            htmlType="submit"
            disabled={!formState.isDirty}
          >
            Save
          </Button>
        </Space>
      </fieldset>
    </form>
  );
};

const BasicContentDetails = ({
  isLoading,
  data,
  error,
  onSave,
}: {
  isLoading: boolean;
  data?: Content;
  error?: Error;
  onSave: () => void;
}) => {
  const { id } = useParams<RouteIdParams>();

  const [sendForm, isUpdating, fired, , updateError] = useSendAction<
    void,
    Content
  >(
    useCallback(
      (data: Content) =>
        updateContent({
          id: Number(id),
          data,
        }),
      [id]
    ),
    { onDone: onSave, onError: errorNotify }
  );

  if (isLoading) return <LoadingIndicator />;

  if (error || updateError)
    return <ErrorIndicator error={error || updateError} />;

  if (!data) return null;

  return (
    <div>
      <Space direction="vertical" expand>
        {isUpdating && <LoadingIndicator />}
        {fired && !isUpdating && !updateError && <span>Saved!</span>}
        <MainContentDetailsForm content={data} onSave={sendForm} />
        <Divider plain>Edit Content Type</Divider>
        <ContentTypeEdit
          type={data?.type}
          isLoading={isLoading}
          error={error}
          onSave={onSave}
        />
      </Space>
    </div>
  );
};

export default BasicContentDetails;
