import { Collection, CollectionsFilters } from 'types';
import { PAGINATION_SIZE, Pagination, pageToSkip } from 'utils/pagination';
import api from './api';

const mapCollection = (collection: Collection) => ({
  ...collection,
  assets: collection.assets?.map((asset, index) => ({
    order: index + 1,
    uuid: asset.uuid,
  })),
});

export const getCollectionsPagination = async (
  filters: CollectionsFilters = {},
  page?: number,
  query?: string
): Promise<Pagination<Collection>> => {
  const { regions, status } = filters;
  const { data } = await api().get<Pagination<Collection>>(
    `/designer/collection`,
    {
      params: {
        name: query,
        skip: pageToSkip(page),
        limit: PAGINATION_SIZE,
        statuses: status,
        regions: regions,
      },
    }
  );

  return data;
};

export const createCollection = async (
  collection: Collection
): Promise<Collection> => {
  const { data } = await api().post<Collection>(
    `/designer/collection`,
    mapCollection(collection)
  );

  return data;
};

export const updateCollection = async (
  collection: Collection
): Promise<Collection> => {
  const { data } = await api().patch<Collection>(
    `/designer/collection/${collection.uuid}`,
    mapCollection(collection)
  );

  return data;
};

export const removeCollection = ({ uuid }: { uuid: string }) =>
  api().delete(`/designer/collection/${uuid}`);

export const getCollectionDetails = async (
  uuid: string
): Promise<Collection> => {
  const { data } = await api().get(`/designer/collection/${uuid}`);

  return data;
};

export const publishCollection = async (uuid: string): Promise<void> => {
  const { data } = await api().put(`/designer/collection/${uuid}/publish`);

  return data;
};

export const unpublishCollection = async (uuid: string): Promise<void> => {
  const { data } = await api().put(`/designer/collection/${uuid}/unpublish`);

  return data;
};
