import Bar from 'ant-design-pro/lib/Charts/Bar';
import { Col, Divider, Menu, Row, Table } from 'antd';
import moment from 'moment';
import {
  DashboardLastAddedAssets,
  DashboardNewlyAddedAssets,
} from 'types/dashboard';
import { AssetType } from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';

const assetsColumns = [
  {
    title: '#',
    dataIndex: 'number',
    render: (text: string) => (
      <span className={styles.tableElementNumber}>{text}</span>
    ),
    width: '15%',
  },
  {
    title: 'Asset title',
    dataIndex: 'asset',
    render: (text: string) => (
      <span className={styles.tableElementTitle}>{text}</span>
    ),
    ellipsis: true,
    width: '60%',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    ellipsis: true,
    width: '25%',
  },
];

interface DashboardBarProps {
  assetsTableData?: DashboardLastAddedAssets[];
  assetsBarData?: DashboardNewlyAddedAssets[];
  assetType: string;
  setAssetType: (assetType: string) => void;
  isLoading?: boolean;
}

const getDaysBefore = (daysRequired: number) => {
  let days = [];

  for (let i = daysRequired - 1; i >= 0; i--) {
    days.push(moment().subtract(i, 'days').format('DD-MM-YYYY'));
  }
  return days;
};

const DashboardBar = ({
  assetsTableData,
  assetsBarData,
  assetType,
  setAssetType,
  isLoading,
}: DashboardBarProps) => {
  let barChartData: { x: string; y: number }[] = [];
  let days = getDaysBefore(7);

  days.forEach((day) => {
    let x = day;
    let y = 0;

    assetsBarData?.forEach((assetData) => {
      if (moment(assetData.date).format('DD-MM-YYYY') === day) {
        y += Number(assetData.count);
      }
    });

    barChartData.push({
      x: x,
      y: y,
    });
  });

  //FOR NOW TABLE SHOWS LAST 8 ADDED ASSETS (After changing backend here will be sorting by type)
  const assetsData = assetsTableData?.map((asset, index) => {
    return {
      number: index + 1,
      asset: asset.name,
      createdAt: moment(asset.createdAt).format('DD-MM-YYYY'),
    };
  });

  return (
    <>
      <Col span={24} className={styles.container}>
        <h1 className={styles.header}>Newly added assets</h1>
        <Divider className={styles.mb0} />

        <div className={styles.menuContainer}>
          <Menu
            selectedKeys={[assetType]}
            mode="horizontal"
            className={styles.noBorder}
            onClick={(event) => setAssetType(event.key.toString())}
          >
            <Menu.Item key={AssetType.MOVIE}>Movies</Menu.Item>
            <Menu.Item key={AssetType.SHOW}>Shows</Menu.Item>
            <Menu.Item key={AssetType.EPISODE}>Episodes</Menu.Item>
            <Menu.Item key={AssetType.SEASON}>Seasons</Menu.Item>
          </Menu>
        </div>

        <Divider className={styles.mt0} />

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <div className={styles.barContainer}>
              <Bar title="" height={400} data={barChartData} />
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8}>
            <div className={styles.tableContainer}>
              <Table
                loading={isLoading}
                showHeader={false}
                dataSource={assetsData}
                columns={assetsColumns}
                size="middle"
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DashboardBar;
