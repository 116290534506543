import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Asset, AssetStatus } from 'types';
import cn from 'classnames';
import { getAssetsForParent, getAssetsPagination } from 'actions/assets';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import PageHeader from 'components/PageHeader';
import { Skeleton, Table } from 'antd';
import { DataNode } from 'antd/lib/tree';
import PageContainer from 'components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import AssetIcon from 'components/AssetIcon';
import AssetStatusTag from 'components/AssetStatusTag';
import Search from 'components/Search';
import {
  Pagination,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import flatMapDeep from 'lodash/flatMapDeep';
import { AssetType } from '@laminar-product/client-commons-core/core';
import formatLabel from 'utils/formatLabel';
import { Paths } from 'types/paths';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import styles from './index.module.scss';

interface AssetPlaceholder extends Omit<Partial<Asset>, 'children'> {
  kind: 'placeholder';
  children?: AssetOrPlaceholder[];
}

/**
 * In our tree model we will store objects which are either TreeAsset or AssetPlaceholder
 *
 * The reason for placeholder is that Table doesn't support "loadData" prop as Tree does.
 * Placeholder will make Table display expand button and we will just present Skeleton or just empty rows
 * until real data comes in.
 *
 * I did try 2 different ways of getting around that:
 * - using expandable, but rc-table will expand even if the content of expandable is null
 *   and it will also not indent the children for some reason. It's clearly not designed for that
 * - creating a small column with my own expand button, but I would need to create a button
 *   that mimics the rc-table one (which has animations and so on...) and then nest tables, but
 *   nested tables won't have column synchronized, so they won't match
 */
type AssetOrPlaceholder = TreeAsset | AssetPlaceholder;

interface TreeAsset extends Omit<Partial<Asset>, 'children'> {
  children?: AssetOrPlaceholder[];
}

interface AssetDataNode extends DataNode {
  status?: AssetStatus;
  type?: AssetType;
  thumbnail?: string;
  uuid?: string;
}

const updateAssetTree = (
  tree: AssetOrPlaceholder[] | undefined,
  id: number,
  children: TreeAsset[]
): AssetOrPlaceholder[] | undefined => {
  if (!tree) return undefined;

  return tree.map((node) => {
    if (node.id === id) {
      return {
        ...node,
        children,
      };
    } else if (node) {
      return {
        ...node,
        children: updateAssetTree(node.children, id, children),
      };
    }
    return node;
  });
};

const assetToTreeAsset = (asset: Asset): TreeAsset => ({
  // Create placeholders from ids
  children:
    (asset?.childrenIds?.length || 0) > 0
      ? asset.childrenIds?.map((id) => ({
          id,
          kind: 'placeholder',
        }))
      : undefined,
  ...asset,
});

const assetToDataNode = (a: AssetOrPlaceholder): AssetDataNode => {
  const asset = a as TreeAsset;

  return {
    key: String(asset.id),
    title: asset.name,
    isLeaf: asset?.childrenIds?.length === 0,
    status: asset?.status,
    type: asset.type,
    thumbnail: asset?.images?.find((image) => image.type === 'thumbnail')?.url,
    children: asset?.children?.map(assetToDataNode),
    uuid: asset.uuid,
  };
};

interface AssetListFilters {
  type?: AssetType[];
}

const AssetList = () => {
  const history = useHistory();
  const page = usePageParam();
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState<AssetListFilters>({});

  const {
    data: pagination,
    isLoading,
    error,
  } = useFetchActionObj<Pagination<Asset>>(
    useCallback(
      () =>
        getAssetsPagination({
          page,
          hasParent: false,
          query,
          types: filters.type,
        }),
      [filters.type, page, query]
    )
  );

  const handleFiltersChange = ({ type }: AssetListFilters) =>
    setFilters({ type });

  const allowedAssetTypeFilters: ColumnFilterItem[] = Object.values(AssetType)
    .filter((type) => ![AssetType.SEASON, AssetType.EPISODE].includes(type))
    .map((value) => ({ text: formatLabel(value).toUpperCase(), value }));

  const [assetTree, setAssetTree] = useState<AssetOrPlaceholder[]>();

  useEffect(() => {
    setAssetTree(pagination?.data?.map(assetToTreeAsset));
  }, [pagination]);

  const treeData = assetTree?.map(assetToDataNode);
  const paginationProps = usePaginationObject('/assets', pagination, page);

  if (error) return <ErrorIndicator error={error} />;

  const loadData = async (node: DataNode) => {
    // Find asset in the tree structure in order to prevent loading children if they are already loaded

    // Flatten asset tree for easy search - could be replaced with traverse
    const assets = flatMapDeep(assetTree, (a) => [a, ...(a?.children ?? [])]);
    const asset = assets?.find((a) => a.id === node.key);

    if (asset) {
      const hasPlaceholders = asset.children?.find(
        (a) => (a as AssetPlaceholder).kind
      );

      if (!hasPlaceholders) {
        return;
      }
    }

    const children = await getAssetsForParent({ parentId: node.key });

    const newAssetTree = updateAssetTree(
      assetTree,
      Number(node.key),
      children.map(assetToTreeAsset)
    );

    setAssetTree(newAssetTree);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Assets"
        extra={[
          <Search
            onSearch={(q) => {
              setQuery(q);
              history.push(`${Paths.ASSETS}/page/1`);
            }}
            key="search"
          />,
          <Link to={`${Paths.ASSETS}/create`} key="create-asset">
            <Button type="primary" icon={<PlusOutlined />}>
              Create new asset
            </Button>
          </Link>,
        ]}
      />
      {/* I strongly recommend reading related comment on AssetOrPlaceholder type */}
      <Table
        loading={isLoading}
        indentSize={40}
        dataSource={treeData}
        onExpand={(_, node) => loadData(node)}
        rowClassName={cn(styles.assetTableRow, 'clickable-row')}
        onRow={(node) => ({
          onClick() {
            history.push(`${Paths.ASSETS}/${node.uuid}`);
          },
        })}
        pagination={paginationProps}
        onChange={(_, filters: AssetListFilters) =>
          handleFiltersChange(filters)
        }
      >
        <Column
          title="Name"
          key="name"
          dataIndex="title"
          render={(title, node: AssetDataNode) =>
            title ? (
              <>
                <AssetIcon image={node.thumbnail} />
                {title}
              </>
            ) : (
              <Skeleton.Input size="small" style={{ width: 200 }} />
            )
          }
        />
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          render={(type) => formatLabel(type).toUpperCase()}
          filters={allowedAssetTypeFilters}
          filteredValue={!!filters?.type?.length ? filters.type : null}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status: AssetStatus) => <AssetStatusTag status={status} />}
        />
      </Table>
    </PageContainer>
  );
};

export default AssetList;
