import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import PrepaidCardsDetails from './PrepaidCardsDetails';
import PrepaidCardsList from './PrepaidCardsList';

const PrepaidCards = () => (
  <Switch>
    <Route exact path={Paths.PREPAID_CARDS} component={PrepaidCardsList} />
    <Route
      path={`${Paths.PREPAID_CARDS}/page/:page`}
      component={PrepaidCardsList}
    />
    <Route
      path={`${Paths.PREPAID_CARDS}/:id`}
      component={PrepaidCardsDetails}
    />
  </Switch>
);

export default PrepaidCards;
