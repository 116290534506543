import React, { useCallback, useState } from 'react';
import {
  useDebounce,
  useFetchAction,
} from '@laminar-product/client-commons-core/hooks';
import { getAssetsPagination } from 'actions/assets';
import { Select, Spin } from 'antd';
import ErrorIndicator from 'components/ErrorIndicator';
import { Asset, AssetStatus, Pagination } from 'types';
import { getFullAssetPath } from 'utils/path';
import { AssetType } from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';

const { Option } = Select;

interface AssetChooserProps {
  onChoose: (asset: Asset) => void;
  chosenAsset?: Asset;
  placeholder?: string;
  regionUuids?: string[];
  uuids?: string[];
  disabledAssetIds?: number[];
  statuses?: AssetStatus[];
  allowedTypes?: AssetType[];
  assetsUuidsToHide?: string[];
  disabled?: boolean;
}

const AssetChooser = ({
  onChoose,
  chosenAsset,
  placeholder = 'Search for asset',
  uuids,
  disabledAssetIds,
  regionUuids,
  statuses,
  allowedTypes,
  assetsUuidsToHide,
  disabled,
}: AssetChooserProps) => {
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 300);
  const [pagination, isLoading, error] = useFetchAction<Pagination<Asset>>(
    useCallback(
      () =>
        getAssetsPagination({
          query: queryDebounced,
          assetUuids: uuids,
          regionUuids,
          statuses,
        }),
      [queryDebounced, regionUuids, statuses, uuids]
    )
  );

  // If the chosen asset is passed, but it's not present in the actual search resutls then
  // we need to glue it to the data to have label displayed correctly
  let correctedData =
    pagination?.data?.filter((a) => !assetsUuidsToHide?.includes(a.uuid)) ?? [];

  if (chosenAsset && !correctedData?.find((a) => a.id === chosenAsset?.id)) {
    correctedData = [...correctedData, chosenAsset];
  }

  return (
    <div className={styles.fullWidth}>
      <Select
        disabled={disabled}
        showSearch
        value={chosenAsset?.id}
        placeholder={placeholder}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={setQuery}
        onChange={(id: number) => {
          onChoose(pagination?.data.find((asset: Asset) => asset.id === id)!);
        }}
        className={styles.fullWidth}
        allowClear
        onDropdownVisibleChange={(open) => !open && setQuery('')}
      >
        {correctedData
          ?.filter((asset: Asset) =>
            allowedTypes
              ? allowedTypes?.length > 0 && allowedTypes.includes(asset.type)
              : true
          )
          ?.map((asset: Asset) => (
            <Option
              key={asset.id}
              value={asset.id}
              disabled={
                disabledAssetIds?.length
                  ? disabledAssetIds.includes(asset.id)
                  : false
              }
            >
              {getFullAssetPath(asset, []).join(' > ')}
            </Option>
          ))}
      </Select>
      {error && <ErrorIndicator error={error} />}
    </div>
  );
};

export default AssetChooser;
