import { Select } from 'antd';
import React from 'react';
import { PrepaidCardBatch } from 'types/prepaidCard';
import { ALL_BATCHES_VALUE } from 'utils/constants';
import styles from './index.module.scss';
const { Option } = Select;

interface PrepaidCardsBatchSelectorProps {
  batches?: PrepaidCardBatch[];
  onChoose: (batchUuid: string) => void;
  selectedBatchUuid?: string;
}

const PrepaidCardsBatchSelector = ({
  batches,
  onChoose,
  selectedBatchUuid,
}: PrepaidCardsBatchSelectorProps) => (
  <Select
    className={styles.fullWidth}
    dropdownMatchSelectWidth
    filterOption={false}
    value={selectedBatchUuid}
    onChange={onChoose}
    placeholder="Select batch to export"
  >
    <Option key="all" value={ALL_BATCHES_VALUE}>
      All batches
    </Option>

    {batches?.map((batch) => (
      <Option key={batch.batchId} value={batch.batchId}>
        {batch.name}
      </Option>
    ))}
  </Select>
);

export default PrepaidCardsBatchSelector;
