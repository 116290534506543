import React from 'react';
import cn from 'classnames';
import _isEqual from 'lodash/isEqual';
import { VideoFile } from 'types';
import { Marker, MarkerTimestamp } from 'types/player';
import videojs, { LVideoJsPlayer } from 'video.js';
import './components/PlayerTrackList';
import styles from './index.module.scss';
import './player.scss';
import { getSources } from './useSources';
import { videoPlayerButtonsOrder } from './VideoPlayerButtonsOrder';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css';
import 'video.js/dist/video-js.css';
import 'videojs-markers/dist/videojs.markers.css';
import 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import 'videojs-contrib-eme';
import 'videojs-seek-buttons';
import 'videojs-markers';
import 'videojs-sprite-thumbnails';

interface VideoPlayerProps {
  videoFile: VideoFile;
  onPlayerReady?: (player: LVideoJsPlayer) => void;
  onCanPlay?: (player: LVideoJsPlayer) => void;
  onPlayerProgress?: (player: LVideoJsPlayer) => void;
  onPlayerLoadedData?: (player: LVideoJsPlayer) => void;
  onPlayerError?: (error: { code: number; message: string } | null) => void;
  options?: videojs.PlayerOptions & { fill: boolean };
  cover?: boolean;
  playerId?: string;
  markers?: Marker[];
  seekOptions?: { forward?: number; back?: number };
}

const initialOptions: videojs.PlayerOptions = {
  html5: {
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    hls: {
      overrideNative: true,
    },
  },
  controls: true,
  fluid: true,
  nativeControlsForTouch: true,
  controlBar: {
    children: videoPlayerButtonsOrder,
  },
  plugins: {
    httpSourceSelector: {
      default: 'auto',
    },
  },
  tracks: [
    {
      default: true,
    },
  ],
};

const shouldUseHLS = (videoNode: HTMLVideoElement) => {
  // player.current.canPlayType('application/vnd.apple.mpegURL') actually returns maybe for Chrome at least on macOS
  // video's canPlayType returns ''
  return videoNode.canPlayType('application/vnd.apple.mpegURL') === 'maybe';
};

class VideoPlayer extends React.Component<VideoPlayerProps> {
  player: LVideoJsPlayer | undefined;
  private videoNode = React.createRef<HTMLVideoElement>();

  private setPlayerSources = (videoFile: VideoFile) => {
    const sources = getSources(videoFile);
    if (videoFile.video.raw) {
      this.player?.src({ src: videoFile.video.raw, type: 'video/mp4' });
      return;
    }
    if (this.videoNode.current && shouldUseHLS(this.videoNode.current)) {
      this.player?.src(sources.hls);
    } else {
      this.player?.src(sources.dash);
    }
  };

  private mapMarkerTimestamp = (timestamp: MarkerTimestamp) => {
    if (typeof timestamp === 'number') return { time: timestamp / 1000 };
    return {
      time: timestamp.start,
      duration: timestamp.duration,
    };
  };

  private mapMarkers = (markers: Marker[]) =>
    markers
      .map((marker) =>
        marker.timestamps.map((timestamp: MarkerTimestamp) => ({
          text: marker.tag,
          class: marker.className,
          ...this.mapMarkerTimestamp(timestamp),
        }))
      )
      .flat();

  componentDidMount() {
    const {
      videoFile,
      options,
      onPlayerReady,
      onPlayerError,
      onPlayerLoadedData,
      onPlayerProgress,
      onCanPlay,
    } = this.props;
    const seekOptions = this.props.seekOptions || {
      forward: 15,
      back: 15,
    };

    const finalOptions: videojs.PlayerOptions = {
      ...initialOptions,
      poster: videoFile?.thumbnails?.url,
      controls: true,
      ...options,
    };

    this.player = videojs(
      this.videoNode.current!,
      finalOptions
    ) as unknown as LVideoJsPlayer;

    this.player.controlBar.CustomControlSpacer.addChild(
      'LaminarPlayerTrackList'
    );
    this.player.eme();
    this.player.httpSourceSelector();
    this.player.seekButtons(seekOptions);

    this.setPlayerSources(videoFile);

    this.player.ready(() => {
      if (onPlayerReady) onPlayerReady(this.player!);
    });
    this.player.on('error', () => {
      if (onPlayerError) onPlayerError(this.player!.error());
    });
    this.player.on('canplay', () => {
      if (onCanPlay) onCanPlay(this.player!);
    });
    this.player.on('progress', () => {
      if (onPlayerProgress) onPlayerProgress(this.player!);
    });
    this.player.on('loadeddata', () => {
      if (onPlayerLoadedData) onPlayerLoadedData(this.player!);
    });

    this.player.markers({
      markers: this.props.markers ? this.mapMarkers(this.props.markers) : [],
      markerStyle: { backgroundColor: '#f9624e' },
      markerTip: { text: (marker) => marker.text },
    });
  }

  componentDidUpdate(prevProps: VideoPlayerProps) {
    const playerMarkers = this.player?.markers;

    if (
      !_isEqual(prevProps.videoFile, this.props.videoFile) &&
      this.props.videoFile
    ) {
      this.player!.reset();
      this.setPlayerSources(this.props.videoFile);
    }

    if (playerMarkers && this.props.markers !== prevProps.markers) {
      if (!this.props.markers?.length) {
        playerMarkers.removeAll();
      } else {
        playerMarkers.add(this.mapMarkers(this.props.markers));
      }
    }
  }

  componentWillUnmount() {
    this.player!.dispose();
  }

  render() {
    const { cover, playerId } = this.props;

    return (
      <div
        className={cn(styles.player, 'laminar-video-player', {
          [styles.cover]: cover,
        })}
      >
        <div data-vjs-player>
          <video
            id={playerId}
            data-testid="VideoPlayer__video"
            ref={this.videoNode}
            className="video-js vjs-big-play-centered"
            crossOrigin="anonymous"
          />
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
