import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getAssetLicenses, getContentAvailability } from 'actions/license';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AddRegionLicense,
  AssetLicense,
  ContentAvailability,
} from 'types/licenses';
import { captureError } from 'utils/captureError';
import { isLicenseScheduled } from 'utils/licenses';
import _differenceBy from 'lodash/differenceBy';

interface UseAssetAvailabilityProps {
  assetId: number;
}

export const useAssetAvailability = ({
  assetId,
}: UseAssetAvailabilityProps) => {
  //Publish table contains only already scheduled licenses + drafted
  const [scheduledLicenses, setScheduledLicenses] = useState<
    AddRegionLicense[]
  >([]);

  const {
    data: assetLicenses,
    isLoading: isLoadingAssetLicenses,
    refresh: refreshAssetLicenses,
  } = useFetchActionObj<AssetLicense[]>(
    useCallback(() => getAssetLicenses({ assetId }), [assetId]),
    { onError: captureError }
  );

  useEffect(() => {
    const scheduled = assetLicenses?.filter(isLicenseScheduled) || [];
    setScheduledLicenses(scheduled);
  }, [assetLicenses]);

  //Add region modal contains only not scheduled licenses and no draft licenses
  const notScheduledLicenses = useMemo(
    () => _differenceBy(assetLicenses, scheduledLicenses, 'id'),
    [scheduledLicenses, assetLicenses]
  );

  const noAssetLicensesAdded = useMemo(
    () => !assetLicenses?.length && !isLoadingAssetLicenses,
    [isLoadingAssetLicenses, assetLicenses]
  );

  return {
    assetLicenses,
    isLoadingAssetLicenses,
    noAssetLicensesAdded,
    scheduledLicenses,
    notScheduledLicenses,
    refreshAssetLicenses,
    setScheduledLicenses,
  };
};

interface UseContentAvailabilityProps {
  contentId: number;
  shouldFetchAvailability?: boolean;
  assetLicenses?: AssetLicense[];
}

export const useContentAvailability = ({
  contentId,
  shouldFetchAvailability,
  assetLicenses,
}: UseContentAvailabilityProps) => {
  const {
    data: contentAvailability,
    isLoading: isLoadingContentAvailability,
    refresh: refreshContentAvailability,
  } = useFetchActionObj<ContentAvailability[]>(
    useCallback(() => {
      if (shouldFetchAvailability) {
        return getContentAvailability(contentId);
      }

      return Promise.resolve([]);
    }, [contentId, shouldFetchAvailability]),
    { onError: captureError }
  );

  //NOTE: Available licenses from asset expect already added to content
  const contentLicensesAvailableToAdd = useMemo(
    () =>
      assetLicenses?.filter(
        (assetLicense) =>
          !contentAvailability?.some(
            (contentLicense) =>
              assetLicense.regionId === contentLicense.regionId
          )
      ),
    [assetLicenses, contentAvailability]
  );

  return {
    contentAvailability,
    isLoadingContentAvailability,
    refreshContentAvailability,
    contentLicensesAvailableToAdd,
  };
};
