import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Select, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import ButtonsRow from 'components/ButtonsRow';
import { DevicePlatform, DevicePlatformKey } from 'types/devices';
import {
  DEFAULT_COMPONENT_TYPE,
  PAGE_COMPONENTS,
  PageComponentType,
  isFeaturedComponent,
  mobilePageComponents,
  tvPageComponents,
  webPageComponents,
} from 'utils/pageComponents';
import { PageComponent, PageElementsType, PagePlatform } from 'types/pages';
import _sortBy from 'lodash/sortBy';
import AdditionalFormItems, { checkFormReady } from './AdditionalFormItems';
import styles from './PageLayoutForm.module.scss';
import { getPlatforms } from './helpers';

interface PageLayoutFormProps {
  formState?: 'ADD' | 'EDIT';
  onCancel: () => void;
  onAdd: (platforms: DevicePlatform[], component: PageComponent) => void;
  onEdit: (platforms: DevicePlatform[], component: PageComponent) => void;
  selectedComponent?: PageComponent;
  componentsByPlatform: PagePlatform;
}

const emptyPageComponentValues: PageComponent = {
  type: DEFAULT_COMPONENT_TYPE,
  elements: PageElementsType.SINGLE,
  builderId: '',
};

const PageLayoutForm = ({
  formState,
  onCancel,
  onAdd,
  onEdit,
  selectedComponent,
  componentsByPlatform,
}: PageLayoutFormProps) => {
  const [currentComponentValues, setCurrentComponentValues] =
    useState<PageComponent>(emptyPageComponentValues);
  const [platforms, setPlatforms] = useState<DevicePlatform[]>([]);
  const [componentType, setComponentType] = useState<string>(
    DEFAULT_COMPONENT_TYPE
  );

  const shouldHideOnWeb = (componentType: PageComponentType) =>
    !webPageComponents.includes(componentType);

  const shouldHideOnMobile = (componentType: PageComponentType) =>
    !mobilePageComponents.includes(componentType);

  const shouldHideOnTV = (componentType: PageComponentType) =>
    !tvPageComponents.includes(componentType);

  useEffect(() => {
    if (!formState) {
      setCurrentComponentValues(emptyPageComponentValues);
      setComponentType(DEFAULT_COMPONENT_TYPE);
    }
    if (formState && selectedComponent) {
      const { type, builderId, uuid, ...rest } = selectedComponent;
      setCurrentComponentValues({ type, uuid, builderId, ...rest });
      if (builderId) {
        setPlatforms(getPlatforms(componentsByPlatform, builderId));
      }
      setComponentType(type);
    }
  }, [selectedComponent, formState, componentsByPlatform]);

  // const hasFeaturedAsset = (platform: DevicePlatformKey) =>
  //   pagePlatforms[platform]?.some((p) => isFeaturedComponent(p.type));

  const hasFeaturedAsset = (platform: DevicePlatformKey) => {
    if (!isFeaturedComponent(componentType)) return false;

    return componentsByPlatform[platform]?.some((p) =>
      isFeaturedComponent(p.type)
    );
  };

  const allowFeaturedAsset = !platforms?.some((p) => hasFeaturedAsset(p));

  const onValueChange = (name: string, value: any) => {
    setCurrentComponentValues((prev) => ({ ...prev, [name]: value }));
  };

  const canSaveFeaturedAsset =
    formState === 'ADD' && isFeaturedComponent(componentType)
      ? allowFeaturedAsset
      : true;

  const formValid =
    checkFormReady(componentType, currentComponentValues) &&
    canSaveFeaturedAsset &&
    platforms?.length;

  const pageComponents = _sortBy(
    PAGE_COMPONENTS?.map((spec) => ({
      label: spec.label,
      value: spec.type,
    })),
    (c) => c.label
  );

  return (
    <Modal
      visible={!!formState}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      width={600}
      title={formState === 'ADD' ? 'Add component' : 'Edit component'}
    >
      <Form labelCol={{ span: 6, className: styles.label }}>
        <Form.Item label="Component type">
          <Select<string>
            onChange={setComponentType}
            value={componentType}
            disabled={formState === 'EDIT'}
            options={pageComponents}
          />
        </Form.Item>
        <AdditionalFormItems
          componentType={componentType}
          values={currentComponentValues}
          onValueChange={onValueChange}
        />
        <Form.Item
          style={{ display: formState === 'ADD' ? 'unset' : 'none' }}
          label="Devices"
        >
          <Checkbox.Group
            value={platforms}
            onChange={(p) => {
              setPlatforms(p as DevicePlatform[]);
            }}
          >
            {!shouldHideOnWeb(componentType as PageComponentType) && (
              <Checkbox value={DevicePlatform.WEB}>Web</Checkbox>
            )}

            {!shouldHideOnMobile(componentType as PageComponentType) && (
              <Checkbox value={DevicePlatform.MOBILE}>Mobile</Checkbox>
            )}

            {!shouldHideOnTV(componentType as PageComponentType) && (
              <Checkbox value={DevicePlatform.TV}>TV</Checkbox>
            )}
          </Checkbox.Group>
        </Form.Item>
        <ButtonsRow>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          {formState === 'ADD' && (
            <Tooltip
              title={
                !allowFeaturedAsset
                  ? `Selected platform already has featured asset`
                  : null
              }
              className={styles.message}
            >
              <Button
                htmlType="submit"
                disabled={!formValid}
                type="primary"
                onClick={() => {
                  onAdd(platforms, {
                    ...currentComponentValues,
                    type: componentType,
                  });
                  setPlatforms([]);
                }}
              >
                Add
              </Button>
            </Tooltip>
          )}
          {formState === 'EDIT' && (
            <Button
              htmlType="submit"
              disabled={!formValid}
              type="primary"
              onClick={() => onEdit(platforms, currentComponentValues)}
            >
              Save
            </Button>
          )}
        </ButtonsRow>
      </Form>
    </Modal>
  );
};

export default PageLayoutForm;
