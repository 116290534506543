import { Modal } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface ConfirmDialogProps {
  visible: boolean;
  onClose: () => void;
  onOk: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
}

const ConfirmDialog = ({
  onClose,
  visible,
  onOk,
  children,
  isLoading,
}: ConfirmDialogProps) => {
  return (
    <Modal
      okText="Yes"
      cancelText="No"
      confirmLoading={isLoading}
      onOk={onOk}
      onCancel={onClose}
      visible={visible}
    >
      <div className={styles.confirmDialog__content}>
        <ExclamationCircleOutlined className={styles.confirmDialog__icon} />
        {children}
      </div>
    </Modal>
  );
};

export default ConfirmDialog;
