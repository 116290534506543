import _reduce from 'lodash/reduce';
import { MenuLink } from 'types/menu';
import { DevicePlatform } from 'types/devices';
import { PagePlatform, PageView } from 'types/pages';
import { v4 as uuid } from 'uuid';

export const mapPageViewsToPlatform = (pageViews?: PageView[]) => {
  return pageViews?.reduce((acc, curVal) => {
    acc[curVal.platform] =
      curVal.components?.map((c) => ({
        ...c,
        builderId: c.builderId ?? uuid(),
      })) ?? [];
    return acc;
  }, {} as PagePlatform);
};

export const mapPlatformToPageViews = (
  pagePlatforms: PagePlatform = { WEB: [], MOBILE: [], TV: [] }
) => {
  return _reduce(
    pagePlatforms,
    (acc, curVal, platformKey) => {
      acc.push({
        platform: platformKey as DevicePlatform,
        components: curVal,
      });
      return acc;
    },
    [] as PageView[]
  );
};

export const mapMenuPlatformToArray = (data: {
  WEB?: MenuLink[];
  TV?: MenuLink[];
  MOBILE?: MenuLink[];
}): { platform: string; links?: MenuLink[] }[] =>
  Object.entries(data).map(
    ([platform, links]: [string, MenuLink[] | undefined]) => {
      return { platform, links };
    }
  );

export const mapMenuPlatformToObject = (
  views: { platform: string; links?: MenuLink[] }[]
): { [key: string]: MenuLink[] } => {
  return _reduce(
    views,
    (acc, cur) => {
      acc[cur.platform] = cur.links ?? [];
      return acc;
    },
    {} as { [key: string]: MenuLink[] }
  );
};
