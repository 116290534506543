import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AffiliateList from './AffiliateList';
import AffiliateDetails from './AffiliateDetails';
const Coupons = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/page/:page`} component={AffiliateList} />
      <Route exact path={path} component={AffiliateList} />
      <Route path={`${path}/:id`} component={AffiliateDetails} />
    </Switch>
  );
};

export default Coupons;
