import { Input, Radio } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ContentDistributionConfigForm,
  ContentDistributorType,
  isCatalogueFeedType,
} from 'types/contentDistribution';

const ContentDistributionCatalogInputs = () => {
  const { control, errors, watch } =
    useFormContext<ContentDistributionConfigForm>();

  const isCatalogueFeed = isCatalogueFeedType(watch('type'));

  return (
    <>
      <FormGroup
        input={
          <Controller
            name="maxStreamVideoQuality"
            control={control}
            defaultValue="HD"
            render={({ value, onChange }) => (
              <Radio.Group
                onChange={(e) => onChange(e.target.value)}
                value={value}
              >
                <Radio value="HD">HD</Radio>
                <Radio value="UHD">4K</Radio>
              </Radio.Group>
            )}
          />
        }
        label="Max stream quality"
      />

      <FormGroup
        input={
          <Controller
            name="type"
            control={control}
            defaultValue={ContentDistributorType.REGULAR}
            render={({ value, onChange }) => (
              <Radio.Group
                onChange={(e) => onChange(e.target.value)}
                value={value}
              >
                <Radio value={ContentDistributorType.REGULAR}>
                  Regular Distributor
                </Radio>
                <Radio value={ContentDistributorType.CATALOGUE_FEED}>
                  Catalogue feed
                </Radio>
              </Radio.Group>
            )}
          />
        }
        label="Distributor type"
      />

      {isCatalogueFeed && (
        <>
          <FormGroup
            input={
              <Controller
                placeholder="Enter the Application ID"
                as={Input}
                control={control}
                name="appId"
                rules={{ required: 'This field is required' }}
              />
            }
            label="Application ID"
            errorMessage={errors?.appId?.message}
            defaultValue=""
          />

          <FormGroup
            input={
              <Controller
                placeholder="Enter the Source ID"
                as={Input}
                control={control}
                name="sourceId"
                rules={{ required: 'This field is required' }}
              />
            }
            label="Source ID"
            errorMessage={errors?.sourceId?.message}
            defaultValue=""
          />
        </>
      )}
    </>
  );
};

export default ContentDistributionCatalogInputs;
