import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import PlatformPartnerDetails from './PartnersDetails';
import PartnersList from './PartnersList';

const PlatformPartners = () => (
  <Switch>
    <Route exact path={Paths.PARTNERS} component={PartnersList} />
    <Route path={`${Paths.PARTNERS}/page/:page`} component={PartnersList} />
    <Route path={`${Paths.PARTNERS}/:id`} component={PlatformPartnerDetails} />
  </Switch>
);

export default PlatformPartners;
