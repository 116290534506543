import React from 'react';
import { Form } from 'antd';
import { PAGE_COMPONENTS, PageComponentParamSpec } from 'utils/pageComponents';
import AssetUuidChooser from 'components/AssetUuidChooser';
import CollectionUuidChooser from 'components/CollectionUuidChooser';
import { PageStatus } from 'types/pages';
import { useFormContext } from 'react-hook-form';
import { OptionType } from 'types/select';
import { DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET } from 'utils/constants';
import FeaturedAssetChooser from 'components/FeaturedAssetChooser';
import { AssetStatus } from 'types';

const additionalFormItemsMap = Object.fromEntries(
  PAGE_COMPONENTS.map((c) => [c.type, c.params])
);

export const checkFormReady = (
  componentType: string,
  values: { [key: string]: any }
) => {
  const additionalItems = additionalFormItemsMap[componentType];

  if (!additionalItems) {
    return true;
  }

  // We will filter our values to get only fields which are either empty or contains errors
  // if the array is empty that means our form is ready
  return (
    Object.entries<PageComponentParamSpec>(additionalItems).filter(
      ([paramName, _]) => values[paramName]
    ).length > 0
  );
};

const AdditionalFormItems = ({
  componentType,
  onValueChange,
  values,
}: {
  componentType: string;
  values: any;
  onValueChange: (name: string, value: any) => void;
}) => {
  const additionalItems = additionalFormItemsMap[componentType];
  const { getValues } = useFormContext();

  const regionsUuids = (getValues('regions') as OptionType[] | undefined)?.map(
    (r) => r.value
  );

  if (!additionalItems) {
    return null;
  }

  return (
    <>
      {Object.entries<PageComponentParamSpec>(additionalItems).map(
        ([paramName, paramSpec]) => {
          return (
            <Form.Item key={paramName} label={paramSpec.label}>
              {paramSpec.type === 'asset' && (
                <AssetUuidChooser
                  onChoose={(uuid) => onValueChange(paramName, uuid)}
                  chosen={values[paramName]}
                  allowedTypes={DEFAULT_ASSET_TYPES_FOR_FEATURED_ASSET}
                  statuses={[AssetStatus.PUBLISHED]}
                />
              )}

              {paramSpec.type === 'collection' && regionsUuids?.length && (
                <CollectionUuidChooser
                  filter={{
                    status: [PageStatus.PUBLISHED],
                    regions: regionsUuids,
                  }}
                  onChoose={(uuid) => onValueChange(paramName, uuid)}
                  chosen={values[paramName]}
                />
              )}

              {paramSpec.type === 'featuredAsset' && regionsUuids?.length && (
                <FeaturedAssetChooser
                  onChoose={(uuid) => onValueChange(paramName, uuid)}
                  chosenUuid={values[paramName]}
                  regionsUuids={regionsUuids}
                />
              )}
            </Form.Item>
          );
        }
      )}
    </>
  );
};

export default AdditionalFormItems;
