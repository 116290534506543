import { Table } from 'antd';
import PageContainer from 'components/PageContainer';
import React, { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Column from 'antd/lib/table/Column';
import StatusTag, { getStatusTagConfig } from 'components/StatusTag';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { FeaturedAsset, FeaturedAssetStatus } from 'types/featuredAsset';
import { getFeaturedAssetsPagination } from 'actions/featuredAsset';
import { useHistory } from 'react-router-dom';
import {
  Pagination,
  usePageParam,
  usePaginationObject,
} from 'utils/pagination';
import Search from 'components/Search';
import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import DeleteButton from 'components/DeleteButton';
import CreateFeaturedAssetModal from '../CreateFeaturedAssetModal';
import DeleteFeaturedAssetModal from '../DeleteFeaturedAssetModal';

const FeaturedAssetsList = () => {
  const { push } = useHistory();
  const page = usePageParam();
  const [name, setName] = useState<string>('');

  const [
    isCreateFeaturedAssetModalVisible,
    setIsCreateFeaturedAssetModalVisible,
  ] = useState(false);

  const [featuredAssetToRemove, setFeaturedAssetToRemove] =
    useState<FeaturedAsset>();

  const {
    data: pagination,
    isLoading,
    refresh,
  } = useFetchActionObj<Pagination<FeaturedAsset>>(
    useCallback(() => getFeaturedAssetsPagination({ page, name }), [name, page])
  );

  const onRemoveSuccess = () => {
    refresh();
    setFeaturedAssetToRemove(undefined);
  };

  const onRowClick = (featuredAsset: FeaturedAsset) =>
    push(`/featured-assets/${featuredAsset.uuid}`);

  return (
    <PageContainer>
      <PageHeader
        title="Featured assets"
        extra={[
          <Search
            placeholder="Search by name"
            loading={isLoading}
            onSearch={(query) => {
              setName(query);
              push('/featured-assets/page/1');
            }}
          />,
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateFeaturedAssetModalVisible(true)}
          >
            Create new featured asset
          </Button>,
        ]}
      />

      <Table
        dataSource={pagination?.data}
        loading={isLoading}
        onRow={(config: FeaturedAsset) => ({
          onClick: () => onRowClick(config),
        })}
        rowClassName="clickable-row"
        pagination={usePaginationObject('/featured-assets', pagination, page)}
      >
        <Column title="Administrative name" key="name" dataIndex="name" />

        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => <StatusTag type={getStatusTagConfig(status)} />}
        />

        <Column
          dataIndex="action"
          align="right"
          render={(_, featuredAsset: FeaturedAsset) => (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                setFeaturedAssetToRemove(featuredAsset);
              }}
              disabledTooltipMessage={
                featuredAsset.status === FeaturedAssetStatus.PUBLISHED
                  ? 'Cannot remove published featured asset'
                  : undefined
              }
            />
          )}
        />
      </Table>

      {isCreateFeaturedAssetModalVisible && (
        <CreateFeaturedAssetModal
          isVisible={isCreateFeaturedAssetModalVisible}
          onClose={() => setIsCreateFeaturedAssetModalVisible(false)}
        />
      )}

      {featuredAssetToRemove && (
        <DeleteFeaturedAssetModal
          featuredAsset={featuredAssetToRemove}
          onClose={() => setFeaturedAssetToRemove(undefined)}
          isVisible={!!featuredAssetToRemove}
          onRemoveSuccess={onRemoveSuccess}
        />
      )}
    </PageContainer>
  );
};

export default FeaturedAssetsList;
