import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initializeApp } from 'firebase/app';
import { History } from 'history';
import { captureError } from 'utils/captureError';

const initMonitoring = (history: History) => {
  if (import.meta.env.ENVIRONMENT === 'development') {
    console.log('Sentry is not loaded in development build.');
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    tracesSampleRate: 1.0,
    release: 'admin-panel@' + import.meta.env.VITE_RELEASE_VERSION,
  });
};

const enableMocks = () => {
  if (import.meta.env.VITE_ENABLE_MOCKING === 'true') {
    const worker = require('./mocks/index.js');
    worker.default.start();
  }
};

const initFirebase = () => {
  try {
    const credentials = import.meta.env.VITE_FIREBASE_CREDENTIALS;

    if (!credentials) throw new Error();

    const firebaseCredentials = JSON.parse(credentials);
    initializeApp(firebaseCredentials);
  } catch (e) {
    captureError(new Error('Missing or invalid firebase credentials'));
  }
};

const setupApp = (history: History) => {
  const isDev = import.meta.env.NODE_ENV === 'development';
  if (isDev) {
    enableMocks();
  } else {
    initMonitoring(history);
  }
  initFirebase();
};

export default setupApp;
