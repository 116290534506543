import { Button } from 'antd';
import PageCard from 'components/PageCard';
import PageDetailsHeader from 'components/PageDetailsHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PrepaidCard, PrepaidCardStatus } from 'types/prepaidCard';
import { RouteIdParams } from 'utils/routeParams';
import StatusCard from 'components/StatusCard';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getPrepaidCard } from 'actions/prepaid';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import PrepaidCardsConfirmationModal from '../components/PrepaidCardsConfirmationModal';
import PrepaidCardsPins from '../PrepaidCardsPins';
import PrepaidCardsBasicCreate from '../components/PrepaidCardsBasicCreate';
import { usePrepaidCardActions } from '../helpers';
import styles from './index.module.scss';

const PrepaidCardsDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const formMethods = useForm<PrepaidCard>({
    mode: 'onChange',
  });
  const { canUse } = usePermission();
  const { reset, handleSubmit, formState } = formMethods;
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  const { data: prepaidCard, refresh: refreshCard } = useFetchActionObj(
    useCallback(() => getPrepaidCard({ uuid: id }), [id])
  );

  const onPublishUnpublishDone = () => {
    setIsConfirmationModalVisible(false);
    refreshCard();
  };

  const {
    publish,
    savePrepaid,
    unpublish,
    isSaving,
    isPublishing,
    isUnpublishing,
  } = usePrepaidCardActions({
    id,
    onPublishUnpublishDone: onPublishUnpublishDone,
    onSaveDone: refreshCard,
  });

  const onPublishUnpublishConfirm = () => {
    if (!prepaidCard) return;

    if (prepaidCard.status === PrepaidCardStatus.PUBLISHED) {
      unpublish();
      return;
    }

    publish();
  };

  useEffect(() => {
    if (!id || !prepaidCard) return;

    const { billingFrequency, planId, title, intervalMultiplier } = prepaidCard;

    reset({
      title,
      planId,
      billingFrequency,
      intervalMultiplier,
    });
  }, [id, prepaidCard, reset]);

  if (!prepaidCard) return null;

  return (
    <>
      <PageDetailsHeader>{prepaidCard.title}</PageDetailsHeader>
      <div className={styles.root}>
        <FormProvider {...formMethods}>
          <div className={styles.leftWrapper}>
            <PageCard
              title="General details"
              children={
                <>
                  <PrepaidCardsBasicCreate
                    canEdit={prepaidCard.status === PrepaidCardStatus.DRAFT}
                  />

                  {prepaidCard.status === PrepaidCardStatus.DRAFT && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit(savePrepaid)}
                      loading={isSaving}
                      disabled={!formState.isDirty}
                    >
                      Save
                    </Button>
                  )}
                </>
              }
            />
            <PrepaidCardsPins prepaidCard={prepaidCard} />
          </div>
          <StatusCard
            status={prepaidCard.status.toUpperCase()}
            title="Prepaid card status"
            titleIcon={<InfoCircleOutlined />}
            onPublish={() => setIsConfirmationModalVisible(true)}
            onUnpublish={() => setIsConfirmationModalVisible(true)}
            canPublish={canUse([Permission.PREPAID_PUBLISH])}
            canUnpublish={canUse([Permission.PREPAID_UNPUBLISH])}
          />
        </FormProvider>
      </div>

      <PrepaidCardsConfirmationModal
        isOpen={isConfirmationModalVisible}
        onClose={() => setIsConfirmationModalVisible(false)}
        prepaidCard={prepaidCard}
        onConfirm={onPublishUnpublishConfirm}
        isLoading={isPublishing || isUnpublishing}
      />
    </>
  );
};
export default PrepaidCardsDetails;
