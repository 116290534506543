import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import avatar from 'assets/avatar-topbar.png';
import styles from './index.module.scss';

const TopBar = () => {
  const { push } = useHistory();
  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => push('/'));
  };
  //HIDE FOR NOW
  //const [searchToggle, setSearchToggle] = useState(false);

  return (
    <>
      <div className={styles.root}>
        {/* HIDE FOR NOW
          <div className={styles.search}>
            <HeaderSearch open={searchToggle} />
            <SearchOutlined onClick={() => setSearchToggle(!searchToggle)} />
          </div>

          <div className={styles.notification}>
            <Badge count={3} offset={[8, 0]}>
              <BellOutlined />
            </Badge>
          </div>
        */}

        <div>
          <a target="_blank" rel="noopener noreferrer" href="/help">
            <QuestionCircleOutlined />
          </a>
        </div>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={handleSignOut}
                key="logout"
                icon={<LogoutOutlined />}
              >
                Logout
              </Menu.Item>
            </Menu>
          }
        >
          <Avatar size="large" className={styles.avatar} src={avatar} />
        </Dropdown>
      </div>
    </>
  );
};

export default TopBar;
