import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import CollectionList from './CollectionList';
import CollectionDetails from './CollectionDetails';

export default () => (
  <Switch>
    <Route exact path={Paths.COLLECTIONS} component={CollectionList} />
    <Route
      path={`${Paths.COLLECTIONS}/page/:page`}
      component={CollectionList}
    />
    <Route path={`${Paths.COLLECTIONS}/:id`} component={CollectionDetails} />
    <Route
      path={`${Paths.COLLECTIONS}/create-new-collection`}
      component={CollectionDetails}
    />
  </Switch>
);
