import React from 'react';
import { PinDetailsResponse } from 'types/prepaidCard';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';
import { UserClientData } from 'types/user';
import { Paths } from 'types/paths';
import styles from '../index.module.scss';

interface PrepaidCardsPinSearchResultProps {
  pinDetails: PinDetailsResponse;
  searchingForRedeemedByUser?: boolean;
  pinUserDetails?: UserClientData;
}

const PrepaidCardsPinSearchResult = ({
  pinDetails,
  searchingForRedeemedByUser,
  pinUserDetails,
}: PrepaidCardsPinSearchResultProps) => {
  const history = useHistory();

  const userLabel =
    pinUserDetails?.email || pinUserDetails?.phoneNumber || pinUserDetails?.uid;

  const renderPinField = ({
    header,
    value,
    onValueClick,
  }: {
    header: string;
    value?: string;
    onValueClick?: () => void;
  }) => (
    <div className={styles.fieldContainer}>
      <span className={styles.fieldHeader}>{header}</span>{' '}
      <span
        className={cn(styles.fieldValue, {
          [styles.link]: !!onValueClick,
        })}
        onClick={onValueClick}
      >
        {value}
      </span>
    </div>
  );

  const navigateToCardDetails = (cardUuid: string) =>
    history.push(`${Paths.PREPAID_CARDS}/${cardUuid}`);

  if (searchingForRedeemedByUser) {
    return (
      <div>
        {!pinDetails.pin.redeemed && (
          <p>
            PIN <span className={styles.bold}>{pinDetails.pin.pin}</span> is
            valid and not redeemed.
          </p>
        )}

        {pinDetails.pin.redeemed === true && (
          <p>
            <span className={styles.bold}>{pinDetails.pin.pin}</span> has been
            redeemed by {userLabel} user on{' '}
            {moment(pinDetails.pin.redeemedAt).format(DATE_FORMAT)}.
          </p>
        )}
      </div>
    );
  }

  return (
    <div className={styles.pinDetails}>
      <p className={styles.pinHeader}>{pinDetails.pin.pin}</p>
      {renderPinField({
        header: 'Card',
        value: pinDetails.prepaid.title,
        onValueClick: () => navigateToCardDetails(pinDetails.prepaid.uuid),
      })}
      {renderPinField({
        header: 'Batch',
        value: pinDetails.pin.batchName,
      })}
      {renderPinField({
        header: 'Valid',
        value: `${moment(pinDetails.pin.validityPeriod.from).format(
          DATE_FORMAT
        )} - ${moment(pinDetails.pin.validityPeriod.to).format(DATE_FORMAT)}`,
      })}
      {renderPinField({
        header: 'Status',
        value: pinDetails.pin?.redeemed
          ? `Redeemed by ${userLabel} user on ${moment(
              pinDetails.pin.redeemedAt
            ).format(DATE_FORMAT)}`
          : 'Not redeemed',
      })}
    </div>
  );
};

export default PrepaidCardsPinSearchResult;
