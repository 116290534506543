import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import Button from 'components/Button';

interface OverlayButtonProps {
  onMenuItemClick: (info: { key: string; item: React.ReactInstance }) => void;
}

const OverlayButton = ({ onMenuItemClick }: OverlayButtonProps) => {
  const menu = (
    <Menu onClick={onMenuItemClick}>
      <Menu.Item key="intro_start">Intro Start</Menu.Item>
      <Menu.Item key="intro_end">Intro End</Menu.Item>
      <Menu.Item key="recap_start">Recap Start</Menu.Item>
      <Menu.Item key="recap_end">Recap End</Menu.Item>
      <Menu.Item key="credits_start">Credits Start</Menu.Item>
      <Menu.Item key="credits_end">Credits End</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="primary">
        Set timestamp as <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default OverlayButton;
