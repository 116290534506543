import React, { useCallback, useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import {
  getContentSkipOptions,
  updateContentSkipOptions,
} from 'actions/skipOptions';
import { Card, Checkbox, Empty } from 'antd';
import cn from 'classnames';
import Button from 'components/Button';
import VideoPlayer from 'components/VideoPlayer';
import { useAssetContentContext } from 'contexts/assetContentContext';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-maskedinput';
import { Marker } from 'types/player';
import { notUndefined } from 'utils/helpers';
import notification from 'utils/notification';
import videojs, { LVideoJsPlayer } from 'video.js';
import { VideoType } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import PlayerOverlay from './components/PlayerOverlay';
import { formValidationSchema } from './formValidation';
import styles from './index.module.scss';
import {
  keyToType,
  skipOptionsVideoPlayerId,
  toNumber,
  toString,
  typeToKey,
} from './utils';

const skipOptions = ['intro', 'recap', 'credits'];

function getErrorMessage(errors: any) {
  const error = errors.intro || errors.recap || errors.credits;
  if (error?.start?.type === 'lessThan') {
    return 'End time must be after start time';
  }
  if (error?.type === 'overlap') {
    return 'Entered ranges cannot overlap';
  }
}

export interface SkipOptionsFormItem {
  checked?: boolean;
  start: string;
  end: string;
}
interface SkipOptionsForm {
  intro: SkipOptionsFormItem;
  recap: SkipOptionsFormItem;
  credits: SkipOptionsFormItem;
}

const SkipOptions = () => {
  const { mainContent, mainVideoUrlMP4 } = useAssetContentContext();
  const [videoElHeight, setVideoElHeight] = useState<number>();
  const { handleSubmit, control, watch, setValue, errors, reset } =
    useForm<SkipOptionsForm>({
      mode: 'onChange',
      resolver: yupResolver(formValidationSchema),
    });
  const onPlayerReady = useCallback((player: LVideoJsPlayer) => {
    const actualHeight = player?.el().getBoundingClientRect()?.height;
    setVideoElHeight(!actualHeight || actualHeight < 350 ? 350 : actualHeight);
  }, []);

  useEffect(() => {
    const fetchOptions = async (uuid: string) => {
      const data = await getContentSkipOptions(uuid);
      const formData = data?.reduce((acc, cur) => {
        const key = typeToKey(cur.type);
        acc[key] = {
          checked: cur.active,
          start: toString(cur.start),
          end: toString(cur.end),
        };
        return acc;
      }, {} as Record<string, SkipOptionsFormItem>);
      reset(formData);
    };
    if (mainContent?.uuid) {
      fetchOptions(mainContent.uuid);
    }
  }, [mainContent, reset]);

  const [saveSkipOptions] = useSendAction<void, any>(
    useCallback(
      async (values: SkipOptionsForm) => {
        if (mainContent) {
          const payload = Object.entries(values)
            .map(([key, value]) => {
              if (!value.start && !value.end) return undefined;
              return {
                type: keyToType(key),
                start: value.start ? toNumber(value.start) : undefined,
                end: value.end ? toNumber(value.end) : undefined,
                active: !!value.checked,
              };
            })
            .filter(notUndefined);

          updateContentSkipOptions(mainContent.uuid, payload).then(() => {
            notification.success({
              title: 'Success',
              description: 'Asset frames has been saved',
            });
          });
        }
      },
      [mainContent]
    ),
    {
      onError: (e) => {
        captureError(e);
        notification.error({
          title: 'Failed',
          description: 'Please check frames and try again or contact support.',
        });
      },
    }
  );

  useEffect(() => {
    const eventHandler = () => {
      const player = videojs.getPlayer(
        skipOptionsVideoPlayerId
      ) as unknown as LVideoJsPlayer;

      if (player) onPlayerReady(player);
    };
    window.addEventListener('resize', eventHandler);

    return () => {
      window.removeEventListener('resize', eventHandler);
    };
  }, [onPlayerReady]);

  const markers: Marker[] = Object.entries(watch()).map(([key, value]) => ({
    tag: key,
    className: styles[`marker-${key}`],
    timestamps:
      value.start && value.end
        ? [
            {
              start: toNumber(value.start),
              duration: toNumber(value.end) - toNumber(value.start),
            },
          ]
        : [],
  }));

  if (!mainContent) {
    return (
      <div className={styles.root}>
        <Card className={styles.emptyCard}>
          <Empty
            description={
              <span className={styles.emptyInfo}>
                There is no main content assigned to this asset
              </span>
            }
          />
        </Card>
      </div>
    );
  }

  return (
    <div
      className={styles.root}
      style={{
        height: videoElHeight,
      }}
    >
      <Card
        className={styles.card}
        headStyle={{ border: 'none' }}
        bodyStyle={{ padding: 0, width: 344, flex: 1 }}
        title={
          <>
            <InfoCircleOutlined />
            <span className={styles.title}>Skip Options</span>
          </>
        }
      >
        <form className={styles.form} onSubmit={handleSubmit(saveSkipOptions)}>
          <div className={styles.content}>
            <div className={styles.heading}>
              <span className={styles.headingLabel}>Start(hh:mm:ss)</span>
              <span className={styles.headingLabel}>End(hh:mm:ss)</span>
            </div>
            {skipOptions.map((o) => (
              <div className={cn(styles.row, styles[o])} key={o}>
                <Controller
                  name={`${o}.checked`}
                  control={control}
                  render={({ value, onChange }) => (
                    <Checkbox
                      className={cn(styles.checkbox, {
                        [styles.checkboxDisabled]: !value,
                      })}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    >
                      {o}
                    </Checkbox>
                  )}
                />
                <Controller
                  placeholder="00:00:00"
                  className={styles.input}
                  name={`${o}.start`}
                  as={<MaskedInput mask="11:11:11" />}
                  control={control}
                />

                <Controller
                  placeholder="00:00:00"
                  className={styles.input}
                  name={`${o}.end`}
                  as={<MaskedInput mask="11:11:11" />}
                  control={control}
                />
              </div>
            ))}
            {Object.keys(errors) && (
              <p className={styles.error}>{getErrorMessage(errors)}</p>
            )}
          </div>
          <div className={styles.footer}>
            <Button key="cancel" htmlType="button">
              Cancel
            </Button>
            <Button type="primary" key="save" htmlType="submit">
              Save
            </Button>
          </div>
        </form>
      </Card>
      {/* Temporary use mp4 file */}
      {mainVideoUrlMP4 && (
        <div className={styles.playerWrapper}>
          <PlayerOverlay
            className={styles.playerOverlay}
            setValue={(key, val) => setValue(key, val)}
          />
          <VideoPlayer
            playerId={skipOptionsVideoPlayerId}
            onPlayerReady={onPlayerReady}
            videoFile={{
              video: {
                raw: mainVideoUrlMP4,
                dash: { url: mainVideoUrlMP4, type: VideoType.MPD },
                hls: { url: mainVideoUrlMP4, type: VideoType.M3U8 },
              },
            }}
            markers={markers}
            seekOptions={{ forward: 1, back: 1 }}
          />
        </div>
      )}
    </div>
  );
};
export default SkipOptions;
