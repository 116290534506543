import { createContext, useCallback, useContext, useMemo } from 'react';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getAssetContents } from 'actions/assets';
import { getContentFile, getTranscodedMP4File } from 'actions/content';
import { Content, UploadedFile } from 'types';
import { useAssetDetailsContext } from './assetContext';

interface AssetContentActions {
  contents?: Content[];
  sourceFileId?: string;
  sourceFile?: UploadedFile;
  mainContent?: Content;
  mainVideoUrlMP4?: string;
}

export const AssetContentContext = createContext<AssetContentActions>(null!);

export const useAssetContentContext = () =>
  useContext<AssetContentActions>(AssetContentContext);

export const AssetContentProvider = ({ ...props }) => {
  const { children } = props;
  const {
    asset: { id },
  } = useAssetDetailsContext();

  const [contents] = useFetchAction(
    useCallback(() => getAssetContents({ id: id! }), [id])
  );
  const mainContent = useMemo(
    () => contents?.find((c) => c.type === 'MAIN'),
    [contents]
  );

  const [sourceFile] = useFetchAction(
    useCallback(() => {
      if (mainContent) {
        return getContentFile({ id: mainContent?.id });
      } else {
        return Promise.resolve({} as UploadedFile);
      }
    }, [mainContent])
  );

  // temporary solution to get mp4 file
  const [mp4MainVideo] = useFetchAction(
    useCallback(
      () =>
        sourceFile && sourceFile.id
          ? getTranscodedMP4File(sourceFile.id)
          : Promise.resolve({} as { url: string }),
      [sourceFile]
    )
  );

  return (
    <AssetContentContext.Provider
      value={{
        mainContent,
        contents,
        sourceFileId: sourceFile?.sourceUuid,
        sourceFile,
        mainVideoUrlMP4: mp4MainVideo?.url,
      }}
    >
      {children}
    </AssetContentContext.Provider>
  );
};
