import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getAssetByUuid } from 'actions/assets';
import React, { useCallback } from 'react';

export interface FetchingAssetNameProps {
  uuid: string;
}

/**
 * Fetches and displays asset name
 */
const FetchingAssetName = ({ uuid }: FetchingAssetNameProps) => {
  const [asset] = useFetchAction(
    useCallback(() => getAssetByUuid(uuid), [uuid])
  );

  return <div>{asset?.name}</div>;
};

export default FetchingAssetName;
