import { Language } from '@laminar-product/client-commons-core/core';

const getMostUsedLang = (): Language[] => {
  switch (import.meta.env.VITE_ENVIRONMENT) {
    case 'dev':
      return [
        {
          alpha3: 'eng',
          alpha2: 'en',
          label: 'English',
        },
        { alpha3: 'pol', alpha2: 'pl', label: 'Polish' },
      ];
    case 'staging':
      return [
        {
          alpha3: 'eng',
          alpha2: 'en',
          label: 'English',
        },
        { alpha3: 'pol', alpha2: 'pl', label: 'Polish' },
        { alpha3: 'pan', alpha2: 'pa', label: 'Panjabi; Punjabi' },
      ];
    case 'prod-pitaara':
      return [
        {
          alpha3: 'eng',
          alpha2: 'en',
          label: 'English',
        },
        { alpha3: 'pan', alpha2: 'pa', label: 'Panjabi; Punjabi' },
      ];
    default:
      return [
        {
          alpha3: 'eng',
          alpha2: 'en',
          label: 'English',
        },
      ];
  }
};

export default getMostUsedLang;
