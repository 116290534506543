import { Input } from 'antd';
import FormGroup from 'components/FormGroup';
import LanguageChooser from 'components/LanguageChooser';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContentDistributionConfigForm } from 'types/contentDistribution';
import ContentDistributionConfigurationBasicCreate from '../ContentDistributionBasicConfigCreate';

const ContentDistributionConfigDetailsForm = () => {
  const { control, errors } = useFormContext<ContentDistributionConfigForm>();

  return (
    <>
      <ContentDistributionConfigurationBasicCreate />
      <FormGroup
        input={
          <Controller
            placeholder="Enter the distributor ID"
            as={Input}
            control={control}
            name="distributorUuid"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Distributor ID"
        errorMessage={errors?.distributorUuid?.message}
        defaultValue=""
      />
      <FormGroup
        input={
          <Controller
            render={(field) => (
              <LanguageChooser
                chosenLanguageCode={field.value}
                onChoose={field.onChange}
                enableMostUsed={false}
                placeholder="Select primary language"
              />
            )}
            control={control}
            name="primaryLanguage"
            rules={{ required: 'This field is required' }}
          />
        }
        label="Primary language"
        errorMessage={errors?.primaryLanguage?.message}
        defaultValue=""
      />
    </>
  );
};

export default ContentDistributionConfigDetailsForm;
