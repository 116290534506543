import React, { useEffect, useState } from 'react';
import { LayoutOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Tabs } from 'antd';
import PageCard from 'components/PageCard';
import { DevicePlatform } from 'types/devices';
import { PageComponent, PagePlatform } from 'types/pages';
import { arrayMove } from 'utils/arrayMove';
import {
  PAGE_COMPONENTS,
  PageComponentType,
  isFeaturedComponent,
} from 'utils/pageComponents';
import { v4 as uuid } from 'uuid';
import notification from 'utils/notification';
import ComponentList from './ComponentList';
import PageLayoutForm from './PageLayoutForm';

const { TabPane } = Tabs;

export interface PageLayoutProps {
  componentsByPlatform: PagePlatform;
  setComponents: (platforms: PagePlatform) => void;
  hasRegions?: boolean;
}

export type PlatformEntries = [DevicePlatform, PageComponent[]][];

const PageLayout = ({
  setComponents,
  componentsByPlatform,
  hasRegions,
}: PageLayoutProps) => {
  const [formState, changeFormState] = useState<'ADD' | 'EDIT'>();
  const [selectedComponent, setSelectedComponent] = useState<PageComponent>();
  const [canAddComponent, toggleCanAddComponent] = useState(true);

  useEffect(() => {
    if (hasRegions) {
      toggleCanAddComponent(true);
    }
  }, [hasRegions]);

  const getUpdatedBillboardComponents = (
    platform: PagePlatform[DevicePlatform.TV],
    newComponent: PageComponent
  ) => {
    let added = false;
    for (const component of platform) {
      const index = platform.indexOf(component);
      // note: billboard cannot be added near other billboards
      const canAddBillboardOnFirstPosition =
        index === 0 &&
        ![
          PageComponentType.JUMBOTRON,
          PageComponentType.BILLBOARD,
          PageComponentType.FEATURED_ASSET,
        ].includes(platform[index]?.type as PageComponentType);

      const canAddBillboard = ![
        platform[index]?.type,
        platform[index + 1]?.type,
      ].includes(PageComponentType.BILLBOARD);

      if (canAddBillboardOnFirstPosition) {
        platform.unshift(newComponent);
        added = true;
        break;
      }

      if (canAddBillboard) {
        platform.splice(index + 1, 0, newComponent);
        added = true;
        break;
      }
    }

    if (!added) {
      notification.error({
        title: 'Cannot add billboard',
        description:
          'Billboard have to be placed between two other component types',
      });
    }

    return platform;
  };

  const elementsPageTypeSetter = (component: PageComponent) =>
    PAGE_COMPONENTS.forEach((c) =>
      c.type === component.type ? (component.elements = c.elements) : null
    );

  const onAddComponent = (
    devicePlatforms: DevicePlatform[],
    { type: componentType, ...component }: PageComponent
  ) => {
    const updatedPlatforms = { ...componentsByPlatform };
    devicePlatforms.forEach((p) => {
      const platformComponents = updatedPlatforms[p] ?? [];
      const newComponent: PageComponent = {
        ...component,
        type: componentType,
        builderId: uuid(), // Create a builder id that we will use for builder modifications
        // we use separate builderId to avoid mistakingly updating multiple components before refreshing the page
      };

      elementsPageTypeSetter(newComponent);

      if (componentType !== PageComponentType.BILLBOARD) {
        updatedPlatforms[p] = isFeaturedComponent(componentType)
          ? [newComponent, ...platformComponents]
          : [...platformComponents, newComponent];
        return;
      }

      updatedPlatforms[p] = getUpdatedBillboardComponents(
        updatedPlatforms[p],
        newComponent
      );
    });

    setComponents(updatedPlatforms);
    onCancel();
  };

  const onEditComponent = (
    devicePlatforms: DevicePlatform[],
    component: any
  ) => {
    const updatedPlatforms = { ...componentsByPlatform };
    devicePlatforms.forEach((p) => {
      const platformComponents = updatedPlatforms[p] ?? [];

      updatedPlatforms[p] = platformComponents.map((c) => {
        if (c.builderId === selectedComponent?.builderId) {
          // Change only values
          return { ...component, type: c.type, builderId: c.builderId };
        }
        return c;
      });
    }, {} as PagePlatform);

    setComponents(updatedPlatforms);
    onCancel();
  };

  const onCancel = () => {
    setSelectedComponent(undefined);
    changeFormState(undefined);
  };

  const onAddClick = () => {
    if (!hasRegions) {
      toggleCanAddComponent(false);
    } else {
      changeFormState('ADD');
    }
  };

  const platformOrderIsValid = (components: PageComponent[]): boolean => {
    if (
      components.some(
        (component, index) =>
          component.type === PageComponentType.BILLBOARD &&
          components[index + 1]?.type === PageComponentType.BILLBOARD
      )
    ) {
      notification.info({
        title: 'Cannot move billboard',
        description: 'Billboard components cannot be next to each other',
      });
      return false;
    }
    return true;
  };

  const onComponentDelete = (
    platform: DevicePlatform,
    component: PageComponent
  ) => {
    const updatedPlatforms = {
      ...componentsByPlatform,
      [platform]: componentsByPlatform[platform].filter(
        (c) => c.builderId !== component.builderId
      ),
    };

    if (!platformOrderIsValid(updatedPlatforms[platform])) {
      return;
    }

    setComponents(updatedPlatforms);
  };

  return (
    <>
      <PageCard
        title="Layout"
        icon={<LayoutOutlined />}
        extra={
          <Button
            type="primary"
            ghost
            icon={<PlusOutlined />}
            onClick={onAddClick}
          >
            Add component
          </Button>
        }
      >
        {!hasRegions ? (
          <Alert
            type={canAddComponent ? 'info' : 'error'}
            message="Select region before adding components"
          />
        ) : null}
        <Tabs defaultActiveKey="WEB">
          {(Object.entries(componentsByPlatform) as PlatformEntries)
            ?.sort((a, b) => (a > b ? -1 : 1))
            ?.map(([platform, components]) => (
              <TabPane tab={platform} key={platform}>
                <ComponentList
                  components={components}
                  onDelete={(c) => onComponentDelete(platform, c)}
                  useDragHandle
                  onItemClick={(component) => {
                    changeFormState('EDIT');
                    setSelectedComponent(component);
                  }}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    // Another special case for featured asset
                    if (isFeaturedComponent(components[newIndex].type)) return;

                    const updatedPlatforms = {
                      ...componentsByPlatform,
                      [platform]: arrayMove(components, oldIndex, newIndex),
                    };

                    if (!platformOrderIsValid(updatedPlatforms[platform]))
                      return;

                    setComponents(updatedPlatforms);
                  }}
                />
              </TabPane>
            ))}
        </Tabs>
      </PageCard>

      {formState && (
        <PageLayoutForm
          selectedComponent={selectedComponent}
          formState={formState}
          onCancel={onCancel}
          onEdit={onEditComponent}
          onAdd={onAddComponent}
          componentsByPlatform={componentsByPlatform}
        />
      )}
    </>
  );
};

export default PageLayout;
