import { api } from 'actions/api';
import { ListingPageTranslation } from 'types/translations';

export const getGenres = async (name?: string): Promise<string[]> => {
  const { data } = await api().get<string[]>(`/genre`, { params: { name } });

  return data;
};

export const addGenre = async (data: ListingPageTranslation): Promise<void> => {
  await api().post(`/genre`, {
    name: data.name.toLowerCase(),
    translations: data.translations.map((t) => ({
      value: t.value,
      language: t.langCode,
    })),
  });
};

export const archiveGenre = async (name: string): Promise<void> =>
  await api().patch(`genre/${name}/archive`);

export const updateGenre = async (
  data: ListingPageTranslation
): Promise<void> => {
  await api().patch(
    `/genre`,
    data.translations.map((t) => ({ value: t.value, language: t.langCode })),
    {
      params: {
        name: data.name.toLowerCase(),
      },
    }
  );
};

export const getMoods = async (name?: string): Promise<string[]> => {
  const { data } = await api().get<string[]>(`/mood`, { params: { name } });

  return data;
};

export const addMood = async (data: ListingPageTranslation): Promise<void> => {
  await api().post(`/mood`, {
    name: data.name.toLowerCase(),
    translations: data.translations.map((t) => ({
      value: t.value,
      language: t.langCode,
    })),
  });
};

export const updateMood = async (
  data: ListingPageTranslation
): Promise<void> => {
  await api().patch(
    `/mood`,
    data.translations.map((t) => ({ value: t.value, language: t.langCode })),
    {
      params: {
        name: data.name.toLowerCase(),
      },
    }
  );
};

export const archiveMood = async (name: string): Promise<void> =>
  await api().patch(`mood/${name}/archive`);

export const getTags = async (): Promise<string[]> => {
  const { data } = await api().get<string[]>(`/tags`);

  return data;
};

export const getModerations = async (name?: string): Promise<string[]> => {
  const { data } = await api().get<string[]>(`/moderation`, {
    params: { name },
  });

  return data;
};

export const addModeration = async (
  data: ListingPageTranslation
): Promise<void> => {
  await api().post(`/moderation`, {
    name: data.name.toLowerCase(),
    translations: data.translations.map((t) => ({
      value: t.value,
      language: t.langCode,
    })),
  });
};

export const archiveModeration = async (name: string): Promise<void> =>
  await api().patch(`moderation/${name}/archive`);

export const updateModeration = async (
  data: ListingPageTranslation
): Promise<void> => {
  await api().patch(
    `/moderation`,
    data.translations.map((t) => ({ value: t.value, language: t.langCode })),
    {
      params: {
        name: data.name.toLowerCase(),
      },
    }
  );
};
