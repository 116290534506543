import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collection, CollectionsFilters } from 'types';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import { getCollectionsPagination } from 'actions/collections';
import PageHeader from 'components/PageHeader';
import PageContainer from 'components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import { Pagination, usePageParam } from 'utils/pagination';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import Search from 'components/Search';
import { Paths } from 'types/paths';
import CollectionTable from './CollectionTable';

const CollectionList = () => {
  const history = useHistory();
  const page = usePageParam();
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<CollectionsFilters>({});
  const [data, isLoading, error, refresh] = useFetchAction<
    Pagination<Collection>
  >(
    useCallback(
      () => getCollectionsPagination(filters, page, query),
      [page, query, filters]
    )
  );

  if (error) return <ErrorIndicator error={error} />;

  return (
    <PageContainer>
      <PageHeader
        title="Collections"
        extra={[
          <Search
            onSearch={(query) => {
              setQuery(query);
              history.push(`${Paths.COLLECTIONS}/page/1`);
            }}
            key="search"
          />,
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() =>
              history.push(`${Paths.COLLECTIONS}/create-new-collection`)
            }
          >
            Create new collection
          </Button>,
        ]}
      />
      <CollectionTable
        refresh={refresh}
        loading={isLoading}
        pagination={data}
        currentPage={page ?? 1}
        onFilter={(filters) => setFilters(filters)}
      />
    </PageContainer>
  );
};

export default CollectionList;
