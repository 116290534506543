import { Form, Input, Modal, notification } from 'antd';
import { Interest, InterestTranslation } from 'types/interests';
import { useEffect } from 'react';
import { saveInterest, updateInterest } from 'actions/interests';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import styles from './InterestsModal.module.scss';
import InterestsPublicDetailsForm from './InterestsPublicDetailsForm';

interface InterestsModalProps {
  interest?: Interest;
  onClose: () => void;
}
const InterestsModal = ({ interest, onClose }: InterestsModalProps) => {
  const [form] = Form.useForm();
  const action = !!interest ? updateInterest : saveInterest;
  const { sendAction, isLoading } = useSendActionObj(action, {
    onDone: () => {
      onClose();
      notification.success({
        message: interest
          ? `Interest ${interest.name} updated`
          : `Interest ${form.getFieldValue('name')} added`,
      });
      form.resetFields();
    },
    onError: (error: any) => {
      notification.error({
        message: 'Failed to save interest',
        description: error.response.data.details,
      });
    },
  });

  const onSave = async () => {
    await form.validateFields();
    return sendAction(form.getFieldsValue());
  };
  const onCloseHandler = async () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (
      interest ||
      form.getFieldValue(['translations', 0, 'language']) === 'en'
    )
      return;
    const checkIfEnglishExists = form
      .getFieldValue('translations')
      ?.some(
        (translation: InterestTranslation) => translation.language === 'en'
      );
    form.setFieldsValue({
      translations: checkIfEnglishExists ? [] : [{ language: 'en', value: '' }],
    });
  }, [form, interest]);

  useEffect(() => {
    if (interest) {
      form.setFieldsValue(interest);
    }
    return () => {
      form.resetFields();
    };
  }, [form, interest]);

  return (
    <Modal
      open
      onOk={onSave}
      onCancel={onCloseHandler}
      destroyOnClose
      title={interest ? 'Edit interest' : 'Add interest'}
      maskClosable={false}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        className={styles.root}
        initialValues={
          interest || {
            name: '',
            translations: [],
          }
        }
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: !interest, message: 'Name is required' }]}
          extra="Name of the interest can't be changed"
        >
          <Input disabled={!!interest?.name} />
        </Form.Item>
        <InterestsPublicDetailsForm form={form} />
      </Form>
    </Modal>
  );
};

export default InterestsModal;
