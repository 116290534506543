import { useEffect } from 'react';
import { Alert, Checkbox, Input, InputNumber, Modal, Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import Space from 'components/Space';
import FormGroup from 'components/FormGroup';
import cn from 'classnames';
import {
  Currency,
  IntervalType,
  PaymentGateway,
  PlanOptions,
  PlanPrice,
} from 'types/plan';
import {
  PAYMENT_GATEWAYS_WITH_INR_CURRENCY,
  isAllowedToChangeCurrency,
  isExternalPaymentGateway,
  shouldProvideBillingAmount,
  shouldProvideExternalProductId,
} from 'utils/pricing';
import { DevicePlatform } from 'types/devices';
import styles from './PricingModal.module.scss';

const defaultPriceValues = {
  gateway: PaymentGateway.PAYU,
  currency: Currency.INR,
  interval: IntervalType.DAY,
};

interface PricingModalProps {
  options?: PlanOptions;
  open: boolean;
  addPrice: (price: PlanPrice) => void;
  updateDistributionChannels?: (distributionChannels: Array<string>) => void;
  onClose: () => void;
  isLoading?: boolean;
  platforms?: DevicePlatform[];
  isDisabledChangePaymentChannel?: boolean;
  isDisabledChangeBillingValue?: boolean;
  isDisabledChangeBillingFrequency?: boolean;
  isDisabledChangeAmount?: boolean;
  isDisabledCurrency?: boolean;
  intervalMultiplier?: number;
  interval?: string;
  currency?: string;
  paymentGateway?: string;
  amount?: number | string;
  selectedPlatform?: Array<string>;
}

const PricingModal = ({
  open,
  addPrice,
  onClose,
  options,
  isLoading,
  platforms,
  isDisabledChangePaymentChannel,
  isDisabledChangeBillingValue,
  isDisabledChangeBillingFrequency,
  isDisabledChangeAmount,
  isDisabledCurrency,
  intervalMultiplier,
  interval,
  currency,
  paymentGateway,
  amount,
  selectedPlatform,
}: PricingModalProps) => {
  const { control, handleSubmit, errors, watch, setValue } =
    useForm<PlanPrice>();

  const onOk = () => {
    handleSubmit(addPrice)();
  };
  const onCancel = () => {
    onClose();
  };

  const gateway = watch('gateway') || defaultPriceValues.gateway;

  useEffect(() => {
    setValue(
      'currency',
      PAYMENT_GATEWAYS_WITH_INR_CURRENCY.includes(gateway)
        ? Currency.INR
        : Currency.USD
    );
  }, [gateway, setValue]);
  return (
    <Modal
      width={650}
      title="Add pricing"
      visible={open}
      className={styles.modal}
      onCancel={() => onCancel()}
      onOk={() => onOk()}
      destroyOnClose
      confirmLoading={isLoading}
    >
      <Space expand direction="vertical">
        <FormGroup
          input={
            <Controller
              as={Select}
              control={control}
              name="gateway"
              className={styles.fullWidth}
              defaultValue={paymentGateway ?? defaultPriceValues.gateway}
              rules={{ required: 'This field is required' }}
              options={Object.entries(PaymentGateway)
                .sort()
                .map(([key, gateway]) => ({
                  value: gateway,
                  key,
                }))}
              disabled={isDisabledChangePaymentChannel}
            />
          }
          errorMessage={errors?.gateway?.message}
          label="Payment channel"
        />

        <FormGroup
          input={
            <div className={styles.interval}>
              <Controller
                as={
                  <InputNumber
                    precision={0}
                    disabled={isDisabledChangeBillingValue}
                  />
                }
                control={control}
                rules={{ min: 1, required: 'This field is required' }}
                name="intervalMultiplier"
                type="number"
                defaultValue={intervalMultiplier ?? 1}
              />

              <Controller
                as={Select}
                control={control}
                name="interval"
                className={styles.intervalSelect}
                dropdownClassName={styles.dropdownList}
                defaultValue={interval ?? defaultPriceValues.interval}
                options={options?.interval.map((option) => ({
                  value: option[1],
                  label: option[0],
                }))}
                rules={{ required: 'This field is required' }}
                disabled={isDisabledChangeBillingFrequency}
              />
            </div>
          }
          errorMessage={
            errors?.interval?.message || errors?.intervalMultiplier?.message
          }
          label="Billing frequency"
        />

        {shouldProvideBillingAmount(gateway) && (
          <>
            <FormGroup
              input={
                <div className={styles.amountAndCurrency}>
                  <Controller
                    as={<InputNumber precision={2} />}
                    control={control}
                    name="amount"
                    rules={{ required: 'This field is required' }}
                    className={styles.amountInput}
                    type="number"
                    defaultValue={amount ?? ''}
                    disabled={isDisabledChangeAmount}
                  />
                  {currency ? (
                    <Controller
                      showSearch
                      as={Select}
                      name="currency"
                      className={styles.currencyInput}
                      rules={{ required: 'This field is required' }}
                      defaultValue={currency}
                      options={[
                        {
                          value: currency,
                          label: currency,
                        },
                      ]}
                      disabled={isDisabledCurrency}
                    />
                  ) : (
                    <Controller
                      showSearch
                      as={Select}
                      control={control}
                      name="currency"
                      className={styles.currencyInput}
                      rules={{ required: 'This field is required' }}
                      defaultValue={defaultPriceValues.currency}
                      options={options?.currency.map((option) => ({
                        value: option[1],
                        label: option[1],
                      }))}
                      disabled={!isAllowedToChangeCurrency(gateway)}
                    />
                  )}
                </div>
              }
              errorMessage={
                errors?.amount?.message || errors?.currency?.message
              }
              label="Billing amount"
            />
          </>
        )}

        {shouldProvideExternalProductId(gateway) && (
          <FormGroup
            input={
              <Controller
                as={<Input />}
                control={control}
                name="externalProductId"
                className={styles.fullWidth}
                defaultValue=""
                rules={{ required: 'This field is required' }}
              />
            }
            errorMessage={errors.externalProductId?.message}
            label="External product ID"
          />
        )}

        {selectedPlatform ? (
          <FormGroup
            input={
              <Controller
                control={control}
                name="distributionChannels"
                defaultValue={selectedPlatform}
                render={(field) => (
                  <Checkbox.Group
                    options={platforms}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            }
            label="Sales channel"
          />
        ) : (
          <FormGroup
            input={
              <Controller
                control={control}
                name="distributionChannels"
                type="checkbox"
                as={({ value, onChange }) => (
                  <Checkbox.Group
                    value={value}
                    onChange={onChange}
                    defaultValue={selectedPlatform}
                  >
                    {platforms?.map((platform: string) => (
                      <Checkbox
                        key={platform}
                        value={platform}
                        className={cn(styles.checkbox, styles.capitalize)}
                        checked
                      >
                        {platform.toLowerCase()}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                )}
              />
            }
            label="Sales channel"
          />
        )}

        {isExternalPaymentGateway(gateway) &&
          gateway !== PaymentGateway.RAZORPAY && (
            <Alert
              type="info"
              message="Price details for this payment channel should be configured in the relevant store"
            />
          )}

        {gateway === PaymentGateway.RAZORPAY && (
          <Alert
            type="info"
            message="Price details for this payment channel should be configured in the Razorpay panel"
          />
        )}
      </Space>
    </Modal>
  );
};

export default PricingModal;
