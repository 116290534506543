import { Button, Dropdown, Empty, Menu, Modal, Table } from 'antd';
import { useCallback, useState } from 'react';
import { Pagination, SubscriptionType, User } from 'types';
import { usePaginationObject } from 'utils/pagination';
import { MoreOutlined } from '@ant-design/icons';
import { getUserCurrentSubscription } from 'utils/users';
import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { cancelUserPrepaidPlan, cancelUserSubscription } from 'actions/user';
import moment from 'moment';
import { DATE_FORMAT, Permission } from 'utils/constants';
import { usePermission } from 'hooks/permissionsHook';
import { PlatformPartner } from 'types/platformPartners';
import { errorNotify } from 'utils/errorNotify';
import UserSignOutMenuItem from 'components/UserSignOutMenuItem';
import { signOutClientUser } from 'actions/users';
import UserClientsChangePlan from '../UserListClientsModals/UserClientsChangePlan';
import UserClientsCancelPlan from '../UserListClientsModals/UserClientsCancelPlan';
import UserClientsProlongPlan from '../UserListClientsModals/UserClientsProlongPlan';
import UserClientsChangeUser from '../UserListClientsModals/UserClientsChangeUser';
import UserClientsGenerateLink from '../UserListClientsModals/UserClientsGenerateLink';
import styles from './index.module.scss';
import {
  UserClientsFilters,
  renderPlanStatus,
  renderUserSubscriptionPaymentType,
} from './helpers';

const { Column } = Table;

interface UserClientsTableProps {
  page?: number;
  pagination?: Pagination<User>;
  loading?: boolean;
  refresh: () => void;
  filters?: UserClientsFilters;
  setFilters: (filters: UserClientsFilters) => void;
  platformPartners?: PlatformPartner[];
}

interface CancelPlanAction {
  uuid: string;
  subscriptionType?: SubscriptionType;
}

const UserClientsTable = ({
  pagination,
  loading,
  page,
  refresh,
  setFilters,
  filters,
  platformPartners,
}: UserClientsTableProps) => {
  const { canUse, canUseAny } = usePermission();

  const resetUserActionState = () => {
    setCancelPlanModalVisible(false);
    setSelectedUser(undefined);
    refresh();
  };

  const { sendAction: cancelPlan, isLoading: isCancellingPlan } =
    useSendActionObj<void, CancelPlanAction>(
      ({ uuid, subscriptionType }) =>
        subscriptionType === SubscriptionType.PREPAID
          ? cancelUserPrepaidPlan(uuid)
          : cancelUserSubscription(uuid),
      {
        onDone: resetUserActionState,
        onError: (error) => {
          resetUserActionState();
          errorNotify(error);
        },
      }
    );
  const [selectedUser, setSelectedUser] = useState<User>();
  const [changePlanModalVisible, setChangePlanModalVisible] =
    useState<boolean>(false);
  const [changeUserModalVisible, setChangeUserModalVisible] =
    useState<boolean>(false);
  const [cancelPlanModalVisible, setCancelPlanModalVisible] =
    useState<boolean>(false);
  const [prolongPlanModalVisible, setProlongPlanModalVisible] = useState(false);
  const [generateLinkModalVisible, setGenerateLinkModalVisible] =
    useState<boolean>(false);

  const onChangePlanModalClose = () => {
    setChangePlanModalVisible(false);
    setSelectedUser(undefined);
  };

  const onChangeUserModalClose = useCallback(() => {
    setChangeUserModalVisible(false);
    setSelectedUser(undefined);
  }, [setChangeUserModalVisible, setSelectedUser]);

  const onCancelPlanModalClose = () => {
    setCancelPlanModalVisible(false);
    setSelectedUser(undefined);
  };

  const onProlongPlanModalClose = () => {
    setProlongPlanModalVisible(false);
    setSelectedUser(undefined);
  };

  const onGenerateLinkModalClose = () => {
    setGenerateLinkModalVisible(false);
    setSelectedUser(undefined);
  };

  const onCancelPlan = () => {
    const activeSubscription = selectedUser?.subscriptions?.find(
      (s) => s.active
    );

    if (!activeSubscription || !selectedUser) return;

    const uuid =
      activeSubscription.type === SubscriptionType.PREPAID
        ? selectedUser.uid
        : activeSubscription.subscriptionId;

    cancelPlan({
      uuid,
      subscriptionType: activeSubscription.type,
    });
  };

  const changeUserOnClickHandler = (user: User) => () => {
    setSelectedUser(user);
    setChangeUserModalVisible(true);
  };
  const changePlanOnClickHandler = (user: User) => {
    setSelectedUser(user);
    setChangePlanModalVisible(true);
  };

  const cancelPlanOnClickHandler = (user: User) => {
    setSelectedUser(user);
    setCancelPlanModalVisible(true);
  };

  const prolongPlanOnClickHandler = (user: User) => {
    setSelectedUser(user);
    setProlongPlanModalVisible(true);
  };

  const generateLinkHandler = (user: User) => {
    setSelectedUser(user);
    setGenerateLinkModalVisible(true);
  };

  const userHasPlan = (user: User) =>
    !!user?.subscriptions?.some((s) => s.active);

  const handleFiltersChange = (filters: UserClientsFilters) =>
    setFilters({ userType: filters.userType });

  return (
    <>
      <Table
        dataSource={pagination?.data}
        rowKey="id"
        loading={loading}
        pagination={usePaginationObject('/clients', pagination, page ?? 1)}
        onChange={(_, filters: UserClientsFilters) =>
          handleFiltersChange(filters)
        }
        scroll={{ x: 1000 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div>
                  <p>
                    <b>No results</b>
                  </p>
                  While searching, please note that you need to enter the exact
                  email, uuid or phone number with the correct capitalization to
                  match the information in the database and get results. You can
                  also use the command <b>'explore:john'</b> to display all
                  results that include 'john'. Please note that this search
                  might take some time.
                </div>
              }
            />
          ),
        }}
      >
        <Column
          title="Email"
          dataIndex="email"
          render={(_, user: User) => user?.email || '-'}
          fixed="left"
          width={250}
        />
        <Column
          title="Phone number"
          dataIndex="phoneNumber"
          render={(_, user: User) => user?.phoneNumber || '-'}
          fixed="left"
          width={150}
        />
        <Column title="User ID" dataIndex="uid" width={150} />
        <Column
          width={100}
          title="User type"
          dataIndex="userType"
          render={(_, user: User) =>
            user.platformPartnerId && user.platformPartnerName
              ? `Partner (${user.platformPartnerName})`
              : 'Regular'
          }
          filters={platformPartners?.map((partner) => ({
            text: partner.administrativeName,
            value: partner.partnerId,
          }))}
          filterMultiple={false}
          filteredValue={!!filters?.userType ? [filters.userType] : null}
        />
        <Column
          width={100}
          title="Active plan"
          dataIndex="activePlan"
          render={(_, user: User) =>
            getUserCurrentSubscription(user)?.plan?.administrativeName || '-'
          }
        />
        <Column
          width={100}
          title="Payment type"
          dataIndex="paymentType"
          render={(_, user: User) => renderUserSubscriptionPaymentType(user)}
        />
        <Column
          width={150}
          title="Plan valid from"
          dataIndex="validFrom"
          render={(_, user: User) => {
            const validFrom = getUserCurrentSubscription(user)?.createdAt;
            return !!validFrom ? moment(validFrom).format(DATE_FORMAT) : '-';
          }}
        />
        <Column
          width={150}
          title="Next payment date"
          dataIndex="nextPaymentDate"
          render={(_, user: User) => {
            const currentSubscription = getUserCurrentSubscription(user);
            if (!currentSubscription) return '-';

            const { nextPaymentDate, active, isCancelationScheduled } =
              currentSubscription;

            const shouldDisplayNextPaymentDate =
              active && !isCancelationScheduled;

            return !!nextPaymentDate && shouldDisplayNextPaymentDate
              ? moment(nextPaymentDate).format(DATE_FORMAT)
              : '-';
          }}
        />
        <Column
          width={200}
          title="Payment Link"
          dataIndex="paymentLink"
          render={(_, user: User) => {
            const { paymentLinks } = user;
            return paymentLinks?.length &&
              paymentLinks[0].status === 'GENERATED' ? (
              <div className={styles.paymentLinkContainer}>
                <span className={styles.paymentLinkText}>
                  {paymentLinks[0]?.url}
                </span>
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(paymentLinks[0].url)
                  }
                  type="link"
                >
                  Copy
                </Button>
              </div>
            ) : (
              '--'
            );
          }}
        />
        <Column
          width={150}
          title="Payment Link Status"
          dataIndex="paymentLinkStatus"
          render={(_, user: User) => {
            const { paymentLinks } = user;
            return paymentLinks && paymentLinks.length > 0 ? (
              <div
                className={`${styles.paymentLinkStatus} ${
                  styles[paymentLinks[0].status.toLowerCase()]
                }`}
              >
                <span>{paymentLinks[0].status}</span>
              </div>
            ) : (
              '-'
            );
          }}
        />
        <Column
          width={150}
          title="Plan valid to"
          dataIndex="validTo"
          render={(_, user: User) => {
            const currentSubscription = getUserCurrentSubscription(user);
            if (!currentSubscription) return '-';

            const { nextPaymentDate, isCancelationScheduled, active } =
              currentSubscription;

            const shouldDisplayEndDate = isCancelationScheduled || !active;

            return !!nextPaymentDate && shouldDisplayEndDate
              ? moment(nextPaymentDate).format(DATE_FORMAT)
              : '-';
          }}
        />
        <Column
          width={120}
          title="Plan status"
          dataIndex="status"
          render={(_, user: User) => renderPlanStatus(user)}
        />
        {canUseAny([
          Permission.CREATE_SUBSCRIPTION_MANUAL,
          Permission.REMOVE_SUBSCRIPTION_MANUAL,
          Permission.PROLONG_SUBSCRIPTION,
          Permission.EDIT_USER,
          Permission.SIGN_OUT_CLIENT_USER_FROM_ALL_DEVICES,
        ]) && (
          <Column
            width={50}
            key="options"
            align="right"
            render={(user: User) => (
              <>
                <Dropdown
                  className={styles.button}
                  overlay={
                    <Menu>
                      {canUse([Permission.EDIT_USER]) && (
                        <Menu.Item
                          key={user.id + '_edit'}
                          onClick={changeUserOnClickHandler(user)}
                        >
                          Change user
                        </Menu.Item>
                      )}
                      <UserSignOutMenuItem
                        user={user}
                        setSelectedUser={setSelectedUser}
                        permissions={[
                          Permission.SIGN_OUT_CLIENT_USER_FROM_ALL_DEVICES,
                        ]}
                        signOutUser={signOutClientUser}
                      />

                      {canUse([Permission.CREATE_SUBSCRIPTION_MANUAL]) && (
                        <Menu.Item
                          key={user.id + '_change_plan'}
                          onClick={() => changePlanOnClickHandler(user)}
                        >
                          Change plan
                        </Menu.Item>
                      )}

                      {userHasPlan(user) && (
                        <>
                          {canUse([Permission.REMOVE_SUBSCRIPTION_MANUAL]) && (
                            <Menu.Item
                              key={user.id + '_cancel_plan'}
                              onClick={() => cancelPlanOnClickHandler(user)}
                            >
                              Cancel plan
                            </Menu.Item>
                          )}

                          {canUse([Permission.PROLONG_SUBSCRIPTION]) && (
                            <Menu.Item
                              key={user.id + '_prolong_plan'}
                              onClick={() => prolongPlanOnClickHandler(user)}
                            >
                              Prolong plan
                            </Menu.Item>
                          )}
                        </>
                      )}
                      {canUse([Permission.PAYMENT_LINK_GENERATE]) && (
                        <Menu.Item
                          key={user.id + '_generate_link'}
                          onClick={() => generateLinkHandler(user)}
                        >
                          Generate Link
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <MoreOutlined />
                </Dropdown>
              </>
            )}
          />
        )}
        )
      </Table>

      <Modal
        visible={changeUserModalVisible}
        onCancel={onChangeUserModalClose}
        destroyOnClose
        footer={null}
        title="Change user"
      >
        <UserClientsChangeUser
          refresh={refresh}
          user={selectedUser}
          onClose={onChangeUserModalClose}
        />
      </Modal>
      <Modal
        visible={changePlanModalVisible}
        onCancel={onChangePlanModalClose}
        destroyOnClose
        footer={null}
        title="Change plan"
      >
        <UserClientsChangePlan
          refresh={refresh}
          user={selectedUser}
          onClose={onChangePlanModalClose}
        />
      </Modal>
      <Modal
        visible={generateLinkModalVisible}
        onCancel={onGenerateLinkModalClose}
        destroyOnClose
        title="Generate Link"
        footer={null}
      >
        <UserClientsGenerateLink
          refresh={refresh}
          user={selectedUser}
          onClose={onGenerateLinkModalClose}
        />
      </Modal>

      <Modal
        open={cancelPlanModalVisible}
        onCancel={onCancelPlanModalClose}
        destroyOnClose
        footer={null}
        title="Are you sure you want to cancel a plan?"
      >
        <UserClientsCancelPlan
          user={selectedUser}
          onClose={onCancelPlanModalClose}
          onCancelConfirm={onCancelPlan}
          loading={isCancellingPlan}
        />
      </Modal>

      <Modal
        visible={prolongPlanModalVisible}
        onCancel={onProlongPlanModalClose}
        destroyOnClose
        footer={null}
        title="Prolong plan"
      >
        <UserClientsProlongPlan
          user={selectedUser}
          onClose={onProlongPlanModalClose}
          refresh={refresh}
        />
      </Modal>
    </>
  );
};

export default UserClientsTable;
