import { TaxProduct, TaxProductTypes } from 'pages/TaxManagement/types';
import { DataSourceType, MapToSourceDataProps } from '../types';
import {
  findProductByKey,
  findRegionByUuid,
  getProductDefaultTaxCode,
} from './helpers';

export const mapProductsToAdd = (
  targetKeys: string[],
  products?: DataSourceType[]
): (TaxProduct | null)[] | undefined => {
  if (!products?.length) return;

  return targetKeys.map((key) => {
    const product = findProductByKey(key, products);

    if (!product) return null;

    const { title, key: productId, productTaxCode, resourceType } = product;
    return {
      productTaxCode,
      resourceType,
      productName: title,
      resourceId: productId,
    };
  });
};

export const mapToSourceData = ({
  products,
  regions,
  defaultTaxCodes,
}: MapToSourceDataProps) => {
  return products.map(({ administrativeName, uuid, regionUuid }) => {
    const region = findRegionByUuid(regionUuid, regions);
    return {
      key: uuid,
      title: administrativeName,
      region: region?.name,
      regionUuid,
      resourceType: TaxProductTypes.PLAN,
      productTaxCode: getProductDefaultTaxCode(
        TaxProductTypes.PLAN,
        defaultTaxCodes
      ),
    };
  });
};
