import { Asset, Region } from 'types';
import { Promotion } from 'types/promotions';
import { DevicePlatform } from './devices';

export interface Plan {
  uuid: string;
  administrativeName: string;
  metadata?: { [key: string]: PlanMetaData };
  status: string;
  platforms: string[];
  quality: Quality[];
  screenLimit: number;
  profileLimit: number;
  currency: Currency;
  region: Region;
  interval: IntervalType;
  intervalCount: number;
  prices: PlanPrice[];
  assets: Asset[];
  availableRegions?: Region[];
  adDriven?: boolean;
  casting: boolean;
  // todo: Backend should return always string[] instead of whole object. As it is in all of the other places.
  interests?: string[];
}

export interface BasicPublishedPlan {
  uuid: string;
  administrativeName: string;
  regionUuid: string;
}

export interface PlanForm {
  uuid: string;
  administrativeName: string;
  metadata?: { [key: string]: PlanMetaData };
  status: string;
  platforms: string[];
  quality: Quality[];
  screenLimit: number;
  profileLimit: number;
  currency: Currency;
  region: Region;
  interval: IntervalType;
  intervalCount: number;
  prices: PlanPrice[];
  assets: Asset[];
  availableRegionsUuids?: string[];
  adDriven?: boolean;
  casting: boolean;
  interests?: string[];
}

export interface PlanModalForm {
  administrativeName: string;
  region: Region;
}

export interface PlanMetaData {
  name: string;
  description?: string;
  details?: string[];
  featuredDetails?: PlanDetail[];
  selectedLanguage?: string;
}

export type PlanDetail = {
  value: string;
  moreInfo: any;
};

export enum Quality {
  '720p' = '720',
  '1080p' = '1080',
  '2160p' = '2160',
}

export enum PlanType {
  FREE = 'AD_FREE',
  PAID = 'PAID',
}

export interface PlanPrice {
  priceUuid?: string;
  currency: Currency;
  interval: IntervalType;
  intervalMultiplier?: number;
  amount: number;
  planUuid: string;
  uuid: string;
  promotions: Promotion[];
  gateway: PaymentGateway;
  externalProductId?: string;
  distributionChannels?: DevicePlatform[];
}

export enum PaymentGateway {
  PAYU = 'PAYU',
  STRIPE = 'STRIPE',
  APPLE = 'APPLE',
  AMAZON = 'AMAZON',
  GOOGLE = 'GOOGLE',
  RAZORPAY = 'RAZORPAY',
  CCBILL = 'CCBILL',
  ROKU = 'ROKU',
}

export enum Currency {
  USD = 'USD',
  PLN = 'PLN',
  INR = 'INR',
}

export enum IntervalType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface PlanOptions {
  currency: [keyof Currency, Currency][];
  interval: [keyof IntervalType, IntervalType][];
  platforms: DevicePlatform[];
  quality: [keyof Quality, Quality][];
}

export enum PlanStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}
