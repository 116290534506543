import { InputNumber } from 'antd';
import FormGroup from 'components/FormGroup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const SingleCouponsForm = () => {
  const { errors, control } = useFormContext();

  return (
    <>
      <FormGroup
        label="Quantity"
        errorMessage={errors.quantity?.message}
        input={
          <Controller
            as={
              <InputNumber type="number" precision={0} placeholder="Quantity" />
            }
            type="number"
            control={control}
            name="quantity"
            rules={{
              required: 'Required',
              max: {
                value: 10000,
                message: 'You can generate maximum 10 000 at once',
              },
              min: {
                value: 1,
                message:
                  'The number of generating coupons must be a positive value',
              },
            }}
          />
        }
      />
    </>
  );
};

export default SingleCouponsForm;
