import { ApiActionType } from 'types';
import { Action, createReducer } from 'typesafe-actions';
import {
  archiveDictionaryAction,
  fetchDictionaryAction,
  sendDictionaryAction,
} from './actions';
import { DictionaryType } from './types';

export type DictionariesState = {
  readonly [key in DictionaryType]: string[] | undefined;
} & {
  readonly error?: { [key: string]: Error };
};

export const initialState = {
  genres: undefined,
  moods: undefined,
  tags: undefined,
  moderations: undefined,
  error: undefined,
};

export const dictionariesReducer = createReducer<DictionariesState, Action>(
  initialState
)
  .handleAction(
    fetchDictionaryAction.success,
    (state: DictionariesState, { payload: { data, dictionaryType } }) => ({
      ...state,
      [dictionaryType]: data,
    })
  )
  .handleAction(
    sendDictionaryAction.success,
    (
      state: DictionariesState,
      { payload: { name, dictionaryType, actionType } }
    ) => {
      if (actionType === ApiActionType.UPDATE) return state;

      const dictionary = state[dictionaryType];

      if (dictionary?.length) {
        return {
          ...state,
          [dictionaryType]: [...dictionary, name],
        };
      }
      return {
        ...state,
        [dictionaryType]: [name],
      };
    }
  )
  .handleAction(
    archiveDictionaryAction.success,
    (state: DictionariesState, { payload: { name, dictionaryType } }) => ({
      ...state,
      [dictionaryType]: state[dictionaryType]?.filter(
        (value) => value !== name
      ),
    })
  )
  .handleAction(
    [fetchDictionaryAction.failure, sendDictionaryAction.failure],
    (state: DictionariesState, { payload: { dictionaryId, error } }) => ({
      ...state,
      error: {
        ...state.error,
        [dictionaryId]: error,
      },
    })
  );
