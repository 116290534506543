import { useCallback, useEffect, useState } from 'react';
import { GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { createMenu, getMenu, updateMenu } from 'actions/menus';
import { Button, Skeleton, Tabs, message } from 'antd';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import { useHistory, useParams } from 'react-router-dom';
import { Region } from 'types';
import { DevicePlatform } from 'types/devices';
import { errorNotify } from 'utils/errorNotify';
import { RouteIdParams } from 'utils/routeParams';
import setIn from 'utils/setIn';
import { useGetRegions } from 'hooks/useRegions';
import { Menu, MenuItemData, MenuLink } from 'types/menu';
import uniqueId from 'lodash/uniqueId';
import MenuCustomPageElement from './MenuCustomPageElement';
import MenuCustomPageModal from './MenuCustomPageModal';
import MenuRegionSidebar from './MenuRegionSidebar';
import MenuTabPane from './MenuTabPane';
import { customPageItemGuard, externalLinkItemGuard } from './utils';

const { TabPane } = Tabs;

const defaultMenu: Menu = {
  uuid: '',
  name: '',
  regions: [],
  platforms: {
    [DevicePlatform.WEB]: [],
    [DevicePlatform.MOBILE]: [],
    [DevicePlatform.TV]: [],
  },
};

const MenuCustomPage = ({ menus: _menus }: { menus: Menu[] }) => {
  const { id } = useParams<RouteIdParams>();
  const { push } = useHistory();
  const { regions } = useGetRegions();
  const [selectedPlatform, setSelectedPlatform] = useState(DevicePlatform.WEB);
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const [isModalOpen, setIsModalOpen] = useState<{
    isOpen: boolean;
    link?: MenuLink;
  }>({
    isOpen: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>();
  const [menus, setMenus] = useState<Menu[]>(_menus);
  const [menu, setMenu] = useState<Menu>(defaultMenu);

  const [publish] = useSendAction<Menu, void>(
    useCallback(async () => {
      if (!menu.uuid?.length) {
        return await createMenu(menu);
      }
      return await updateMenu(menu);
    }, [menu]),
    {
      onDone: (data) => {
        // todo I think we don't need to copy the menus to the state
        setMenus(menus.map((m) => (m.uuid === menu.uuid ? menu : m)));
        if (menus.some((m) => m.uuid !== data.uuid)) {
          setMenus([...menus, data]);
        }
        push(`/menus/${data.uuid}`);
        message.success('The menu has been published');
      },
      onError: errorNotify,
    }
  );

  const getDefaultSelectedRegion = useCallback(
    (menuRegions: string[]) =>
      regions?.find((region) => menuRegions.includes(region.uuid)),
    [regions]
  );

  const addAddMenuItem = useCallback(
    (data: MenuItemData) => {
      const menuToUpdate: Menu =
        !menu.name && selectedRegion
          ? {
              name: selectedRegion.name,
              regions: [selectedRegion.uuid],
              platforms: {},
            }
          : { ...menu };

      data.types.forEach((t) => {
        const commonValues = {
          type: data.type,
          order: menu.platforms[t]?.length + 1 || 1,
          interests: data.interests,
        };

        //For page link details of link comes from page, for links from form payload
        const link: MenuLink =
          'page' in data
            ? {
                uuid: data.page.uuid!,
                metadata: data.page.metadata,
                name: data.page.name,
                ...commonValues,
              }
            : {
                uuid: uniqueId(),
                metadata: data.metadata,
                name: data.metadata?.['en']?.title, //English metadata is always provided,
                url: data.url,
                ...commonValues,
              };

        const links = menu.platforms[t]?.concat([link]) ?? [link];
        menuToUpdate.platforms[t] = links;
      });

      setMenu(menuToUpdate);
      setIsModalOpen({
        isOpen: false,
      });
    },
    [menu, selectedRegion]
  );

  const onSaveMenuItem = (data: MenuItemData) => {
    const menuToUpdate = menu.platforms[selectedPlatform];
    const link = menuToUpdate?.find((link) => link.uuid === data.uuid);
    if (!link) return;
    setMenu(
      setIn(
        menu,
        `platforms.${selectedPlatform}`,
        menuToUpdate.map((menu) => {
          if (menu.uuid === data.uuid) {
            if (customPageItemGuard(data)) {
              menu.uuid = data.page.uuid || '';
              menu.metadata = data.page.metadata;
              menu.name = data.page.name;
            }
            if (externalLinkItemGuard(data)) {
              menu.url = data.url;
              menu.name = data.metadata?.['en']?.title;
            }
            return { ...menu, ...data };
          }

          return menu.uuid === data.uuid ? { ...menu, ...data } : menu;
        })
      )
    );
    setIsModalOpen({
      isOpen: false,
    });
  };

  const handleEditLink = (link: MenuLink) =>
    setIsModalOpen({ isOpen: true, link });

  useEffect(() => {
    if (id !== 'create-menu') {
      setIsLoading(true);
      getMenu({ uuid: id }).then((menu) => {
        setMenu(menu);
        !selectedRegion &&
          setSelectedRegion(getDefaultSelectedRegion(menu.regions));
        setIsLoading(false);
      });
      return;
    }
    setMenu(defaultMenu);
  }, [getDefaultSelectedRegion, id, selectedRegion]);

  if (!menu.uuid && !selectedRegion) {
    return (
      <>
        <MenuRegionSidebar menus={menus} onRegionSelect={setSelectedRegion} />
        <PageContainer>You have to select region first</PageContainer>
      </>
    );
  }

  return (
    <>
      <MenuRegionSidebar menus={menus} onRegionSelect={setSelectedRegion} />

      <PageContainer>
        <PageHeader
          title={selectedRegion?.name}
          extra={[
            <Button
              key="addCustomPage"
              type="primary"
              ghost
              icon={<PlusOutlined />}
              onClick={() =>
                setIsModalOpen({
                  isOpen: true,
                })
              }
            >
              Add item
            </Button>,
            <Button
              key="publishMenu"
              type="primary"
              icon={<GlobalOutlined />}
              onClick={() => publish()}
            >
              Publish
            </Button>,
          ]}
        />

        {isLoading && <Skeleton />}

        {!isLoading && (
          <Tabs
            defaultActiveKey={DevicePlatform.WEB}
            onChange={(tab) => setSelectedPlatform(tab as DevicePlatform)}
          >
            {Object.entries(menu.platforms)?.map(([platform]) => (
              <TabPane
                tab={
                  <MenuTabPane
                    platform={platform}
                    count={menu.platforms[platform]?.length}
                  />
                }
                key={platform}
              >
                <MenuCustomPageElement
                  links={menu.platforms[platform]}
                  onLinksOrderChange={(links) =>
                    setMenu(setIn(menu, `platforms.${platform}`, links))
                  }
                  onLinkDelete={(link) => {
                    const links = menu.platforms[platform]
                      ?.filter((l: MenuLink) =>
                        'url' in l ? l.url !== link.url : l.uuid !== link.uuid
                      )
                      .map((link, index) => ({ ...link, order: index }));
                    setMenu(setIn(menu, `platforms.${platform}`, links));
                  }}
                  onLinkEdit={handleEditLink}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
      </PageContainer>

      {isModalOpen.isOpen && selectedRegion && (
        <MenuCustomPageModal
          links={menu.platforms}
          isModalOpen={isModalOpen.isOpen}
          onModalClose={() =>
            setIsModalOpen({
              isOpen: false,
            })
          }
          regions={[selectedRegion.uuid]} //Menu is always unique per selected region and is defined only in one region
          onAdd={addAddMenuItem}
          onSave={onSaveMenuItem}
          selectedLink={isModalOpen.link}
        />
      )}
    </>
  );
};

export default MenuCustomPage;
