import { Button, PageHeader } from 'antd';
import PageContainer from 'components/PageContainer';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Permission } from 'utils/constants';
import { usePermission } from 'hooks/permissionsHook';
import ContentDistributionConfigTable from '../components/ContentDistributionConfigTable';
import ContentDistributionConfigCreateModal from '../components/ContentDistributionConfigCreateModal';

const ContentDistributionList = () => {
  const { canUse } = usePermission();
  const [
    isCreateNewConfigurationModalVisible,
    setIsCreateNewConfigurationModalVisible,
  ] = useState(false);

  const onCreateConfigClick = () =>
    setIsCreateNewConfigurationModalVisible(true);

  const onCreateConfigModalClose = () =>
    setIsCreateNewConfigurationModalVisible(false);

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Content distribution configurations"
          extra={[
            canUse([Permission.CONTENT_DISTRIBUTION_READ]) && (
              <Button
                key="createNewConfiguration"
                type="primary"
                icon={<PlusOutlined />}
                onClick={onCreateConfigClick}
              >
                Create new configuration
              </Button>
            ),
          ]}
        />
        <ContentDistributionConfigTable />
      </PageContainer>

      {isCreateNewConfigurationModalVisible && (
        <ContentDistributionConfigCreateModal
          isOpen={isCreateNewConfigurationModalVisible}
          onClose={onCreateConfigModalClose}
        />
      )}
    </>
  );
};

export default ContentDistributionList;
