import React from 'react';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { createPlan } from 'actions/plans';
import { message } from 'antd';
import Button from 'components/Button';
import FormContainer from 'components/FormContainer';
import { useGetRegions } from 'hooks/useRegions';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { errorNotify } from 'utils/errorNotify';
import { captureError } from 'utils/captureError';
import { Name, Region } from '../PlanFormFields';

const PlanModal = () => {
  const formMethods = useForm<any>({ mode: 'onChange' });
  const { regions } = useGetRegions();
  const { push } = useHistory();

  const [save, isSavingPlan] = useSendAction<void, any>(
    async () => {
      const formValues = formMethods.getValues();
      const planToSave = {
        administrativeName: formValues.administrativeName,
        regionUuid: formValues.region.uuid,
      };

      const data = await createPlan(planToSave);
      message.success('Saved!');
      push(`/plans/${data.uuid}/`);
    },
    {
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  return (
    <FormContainer>
      <form onSubmit={formMethods.handleSubmit(save)}>
        <fieldset>
          <Name control={formMethods.control} errors={formMethods.errors} />

          <Region
            control={formMethods.control}
            errors={formMethods.errors}
            regions={regions ? regions : []}
          />

          <Button htmlType="submit" type="primary" loading={isSavingPlan}>
            Create
          </Button>
        </fieldset>
      </form>
    </FormContainer>
  );
};

export default PlanModal;
