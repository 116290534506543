import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import { createPrepaidCard } from 'actions/prepaid';
import { Modal } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { BasicPrepaidCard, PrepaidCard } from 'types/prepaidCard';
import { captureError } from 'utils/captureError';
import notification from 'utils/notification';
import PrepaidCardsBasicCreate from '../PrepaidCardsBasicCreate';

interface PrepaidCardsCreateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrepaidCardsCreateModal = ({
  isOpen,
  onClose,
}: PrepaidCardsCreateModalProps) => {
  const formMethods = useForm<BasicPrepaidCard>({
    mode: 'onSubmit',
  });
  const { handleSubmit } = formMethods;
  const { push } = useHistory();

  const onCreateSuccess = (id: string) => {
    notification.success({
      title: 'Success',
      description: 'Prepaid card has been saved',
    });
    onClose();
    push(`/prepaidCards/${id}`);
  };

  const { sendAction: createPrepaid, isLoading } = useSendActionObj<
    PrepaidCard,
    BasicPrepaidCard
  >((prepaid) => createPrepaidCard(prepaid), {
    onDone: (card) => onCreateSuccess(card.uuid),
    onError: (error: any) => {
      const errorCode = error.response?.data?.code;
      if (errorCode === 'duplicate_title_exception') {
        return notification.error({
          title: 'Error',
          description:
            'Prepaid card with that title already exists. Please change the title and try again',
        });
      }
      notification.error({
        title: 'Error',
        description:
          error?.response?.data?.details ||
          'There was an error during the request, please check provided values or try again later.',
      });
      captureError(error);
    },
  });

  const onSubmit = (values: BasicPrepaidCard) => createPrepaid({ ...values });

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      title="Create prepaid card"
      okText="Create"
      onOk={handleSubmit(onSubmit)}
      confirmLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <PrepaidCardsBasicCreate />
      </FormProvider>
    </Modal>
  );
};

export default PrepaidCardsCreateModal;
