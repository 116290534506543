import React from 'react';
import { DatePicker } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './index.module.scss';

const PromotionsValidity = () => {
  const { errors, control } = useFormContext();

  return (
    <>
      <FormGroup
        errorMessage={errors.startDate?.message || errors.endDate?.message}
        label="Limited duration"
        input={
          <div className={styles.flex}>
            <Controller
              as={<DatePicker placeholder="Start date" />}
              control={control}
              name="startDate"
              rules={{ required: 'Please provide correct data' }}
              defaultValue=""
            />
            <span className={styles.dateDivider}>-</span>

            <Controller
              as={<DatePicker placeholder="End date" />}
              control={control}
              name="endDate"
              rules={{ required: 'Please provide correct data' }}
              defaultValue=""
            />
          </div>
        }
      />
    </>
  );
};

export default PromotionsValidity;
