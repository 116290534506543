import { Action, createReducer } from 'typesafe-actions';
import { setUserVerified } from './actions';
import { UserState } from './types';

export const initialState: UserState = {
  userVerified: false,
};

export const userReducer = createReducer<UserState, Action>(
  initialState
).handleAction(setUserVerified, (state: UserState, { payload }) => ({
  ...state,
  userVerified: payload,
}));

export default userReducer;
