import React, { useEffect, useState } from 'react';
import { Popover, Radio } from 'antd';
import { ReactComponent as PlayerSettingsIcon } from 'assets/icons/player-settings.svg';
import cn from 'classnames';
import styles from './index.module.scss';

export function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const PlayerTrackListComponent = ({ player }: { player: any }) => {
  const forceRerender = useForceUpdate();
  const [audioTracks, setAudioTracks] = useState<any>();
  const [textTracks, setTextTracks] = useState<any>();
  const [offTextTrack, setOffTextTrack] = useState<{
    mode: 'showing' | 'disabled';
    id: string;
  }>({
    mode: 'showing',
    id: 'offTextTrack',
  });

  useEffect(() => {
    player.on('loadedmetadata', () => {
      const audioTracks: any = player?.audioTracks();
      const textTracks: any = player?.textTracks().tracks_.filter((t: any) => {
        return t.kind === 'subtitles' && !!t.language;
      });

      setAudioTracks(audioTracks);
      setTextTracks(textTracks);
    });
  }, [player]);

  function enableTextTrack(track: any) {
    if (track.id === 'offTextTrack') {
      setOffTextTrack({ ...offTextTrack, mode: 'showing' });
    } else {
      setOffTextTrack({ ...offTextTrack, mode: 'disabled' });
    }
    player?.textTracks().tracks_.forEach((t: any) => {
      if (t.id !== track.id) {
        t.mode = 'disabled';
      } else {
        t.mode = 'showing';
      }
    });
  }

  function enableAudioTrack(track: any) {
    track.enabled = true;
    forceRerender();
  }

  if (audioTracks?.length <= 1 && textTracks?.length < 1) return null;

  const popoverContent = () => (
    <div className={styles.popoverContent}>
      {audioTracks?.length ? (
        <div className={styles.tracksWrapper}>
          <h5 className={styles.heading}>Audio</h5>
          <ul className={styles.tracks}>
            {audioTracks.length &&
              audioTracks.tracks_.map((track: any) => (
                <li
                  key={track.id}
                  className={cn(styles.track, {
                    [styles.activeTrack]: track.enabled,
                  })}
                >
                  <Radio
                    checked={track.enabled}
                    onChange={() => enableAudioTrack(track)}
                    name="selectedAudio"
                  >
                    {track.label}
                  </Radio>
                </li>
              ))}
          </ul>
        </div>
      ) : null}
      {textTracks?.length ? (
        <div className={styles.tracksWrapper}>
          <h5 className={styles.heading}>Subtitles</h5>
          <ul className={styles.tracks}>
            <li
              key="textTrack-off"
              className={cn(styles.track, {
                [styles.activeTrack]: true,
              })}
            >
              <Radio
                checked={offTextTrack.mode === 'showing'}
                onChange={() => enableTextTrack(offTextTrack)}
                name="selectedText"
              >
                Off
              </Radio>
            </li>
            {textTracks.map((track: any) => (
              <li
                key={track.id}
                className={cn(styles.track, {
                  [styles.activeTrack]: track.enabled,
                })}
              >
                <Radio
                  checked={track.mode === 'showing'}
                  onChange={() => enableTextTrack(track)}
                  name="selectedText"
                >
                  {track.label}
                </Radio>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
  return (
    <Popover
      content={popoverContent}
      trigger="hover"
      color="rgba(43, 51, 63, 0.7)"
      getPopupContainer={
        () =>
          document.querySelector('.laminar-player-track-list') ?? document.body //this is required to show popover when video is fullscreen
      }
    >
      <PlayerSettingsIcon className={styles.settingsIcon} />
    </Popover>
  );
};

export default PlayerTrackListComponent;
