import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const FileSelect = ({
  disabled,
  onSelectFile,
  children,
  loading,
}: {
  disabled?: boolean;
  onSelectFile: Function;
  children: React.ReactNode;
  loading?: boolean;
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) onSelectFile(acceptedFiles[0]);
    },
    [onSelectFile]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        type="primary"
        icon={<UploadOutlined />}
        disabled={disabled}
        loading={loading}
      >
        {children}
      </Button>
    </div>
  );
};

export default FileSelect;
