import { Alert, Button, Card } from 'antd';
import React, { useState } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import StatusTag, { Status, getStatusTagConfig } from 'components/StatusTag';
import styles from './index.module.scss';
import ConfirmDialog from './ConfirmDialog';

export enum StatusCardStatuses {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  MODIFIED = 'MODIFIED',
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
}

interface StatusCardProps {
  status: string;
  title: string;
  titleIcon?: React.ReactNode;
  onPublish?: () => void;
  onUnpublish?: () => void;
  isSavingWhenPublishing?: boolean;
  isLoading?: boolean;
  canPublish: boolean;
  canUnpublish: boolean;
  publishConfirmDialogContent?: React.ReactNode;
  unpublishConfirmDialogContent?: React.ReactNode;
}

const StatusCard = ({
  status,
  title,
  titleIcon,
  onPublish,
  onUnpublish,
  isSavingWhenPublishing,
  isLoading,
  canPublish,
  canUnpublish,
  publishConfirmDialogContent,
  unpublishConfirmDialogContent,
}: StatusCardProps) => {
  const [isPublishConfirmDialogVisible, togglePublishConfirmDialogVisibility] =
    useState(false);
  const [
    isUnpublishConfirmDialogVisible,
    toggleUnpublishConfirmDialogVisibility,
  ] = useState(false);

  //Allows for republishing even if already published if save and publish option is on
  const shouldDisplayPublishButton =
    (status !== StatusCardStatuses.PUBLISHED || isSavingWhenPublishing) &&
    canPublish;

  return (
    <Card
      title={
        <>
          {titleIcon}
          <span className={styles.cardTitle}>{title}</span>
        </>
      }
      className={styles.cardWrapper}
    >
      <div className={styles.cardStatus}>
        <span className={styles.cardStatusText}>Status</span>
        <StatusTag type={getStatusTagConfig(status as Status)} />
      </div>

      {status === StatusCardStatuses.MODIFIED && (
        <Alert
          type="warning"
          showIcon
          message="Partner details have unpublished changes"
        />
      )}

      {shouldDisplayPublishButton && (
        <Button
          onClick={() =>
            publishConfirmDialogContent
              ? togglePublishConfirmDialogVisibility(true)
              : onPublish?.()
          }
          type="primary"
          className={styles.cardButton}
          loading={isLoading}
        >
          <GlobalOutlined />
          {isSavingWhenPublishing ? 'Save and publish' : 'Publish'}
        </Button>
      )}

      {(StatusCardStatuses.PUBLISHED === status ||
        StatusCardStatuses.MODIFIED === status) &&
        canUnpublish && (
          <Button
            onClick={() =>
              unpublishConfirmDialogContent
                ? toggleUnpublishConfirmDialogVisibility(true)
                : onUnpublish?.()
            }
            danger
            type="primary"
            className={styles.cardButton}
            loading={isLoading}
          >
            Unpublish
          </Button>
        )}

      <ConfirmDialog
        visible={isPublishConfirmDialogVisible}
        onClose={() => togglePublishConfirmDialogVisibility(false)}
        onOk={() => {
          onPublish?.();
          togglePublishConfirmDialogVisibility(false);
        }}
        isLoading={isLoading}
        children={publishConfirmDialogContent}
      />

      <ConfirmDialog
        visible={isUnpublishConfirmDialogVisible}
        onClose={() => toggleUnpublishConfirmDialogVisibility(false)}
        onOk={() => {
          onUnpublish?.();
          toggleUnpublishConfirmDialogVisibility(false);
        }}
        isLoading={isLoading}
        children={unpublishConfirmDialogContent}
      />
    </Card>
  );
};

export default StatusCard;
