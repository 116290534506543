import React, { useCallback, useEffect, useState } from 'react';
import {
  useFetchAction,
  usePolling,
} from '@laminar-product/client-commons-core/hooks';
import { getSourceFileSummary } from 'actions/files';
import {
  generateFileName,
  uploadMultipartFile,
  validateFileName,
} from 'actions/upload';
import { Badge, Tabs } from 'antd';
import cn from 'classnames';
import FileSelect from 'components/FileSelect';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { FileStatus, SourceStatus } from 'types';
import { CAN_UPLOAD_SOURCE_FILES } from 'utils/constants';
import { captureError } from 'utils/captureError';
import { Paths } from 'types/paths';
import styles from './index.module.scss';
import AssignedFilesTable from './pages/AssignedFilesTable';
import ReadyFilesTable from './pages/ReadyFilesTable';
import TranscodeFilesTable from './pages/TranscodeFilesTable';
import UploadFilesTable from './pages/UploadFilesTable';
import FailedFilesTable from './pages/FailedFilesTable';

const { TabPane } = Tabs;

enum UploadState {
  NONE = 'NONE',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERRORED = 'ERRORED',
}

const UPLOAD_STATUSES = [SourceStatus.PROCESSING];
const FAILED_STATUSES = [
  SourceStatus.PROCESSING_ERROR,
  SourceStatus.QUALITY_ERROR,
];

const uploadStateToRawFileState = {
  [UploadState.IN_PROGRESS]: FileStatus.UPLOADING,
  [UploadState.ERRORED]: FileStatus.UPLOAD_FAILED,
  [UploadState.FINISHED]: FileStatus.UPLOADED,
};

export default () => {
  const history = useHistory();
  const match = useRouteMatch<{ key: string }>(`${Paths.FILES}/:key`);
  const defaultActiveKey = match?.params?.key || 'upload';
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.NONE);
  const [progress, setProgress] = useState<number>();
  const [uploadFileName, setUploadFileName] = useState<string>();
  const [activeTabKey, setActiveTabKey] = useState<string>(defaultActiveKey);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [summary, , , refreshSummary] = useFetchAction(
    useCallback(() => getSourceFileSummary(), [])
  );

  useEffect(() => {
    const unregister = history.block(() => {
      if (uploadState === UploadState.IN_PROGRESS) {
        return 'File upload is in progress. Are you sure you want to leave?';
      }
    });

    return () => {
      unregister();
    };
  });

  const startUpload = async (file: File) => {
    const errors = validateFileName(file.name);
    if (errors) {
      alert(errors);
      return;
    }

    const newUploadFileName = generateFileName(file);

    setUploadState(UploadState.IN_PROGRESS);
    setProgress(0);
    setUploadFileName(newUploadFileName);

    uploadMultipartFile(file, newUploadFileName, (progress) =>
      setProgress(progress)
    )
      .then(() => {
        setProgress(0);
        setUploadState(UploadState.NONE);
      })
      .catch((e) => {
        captureError(e);
        setUploadState(UploadState.ERRORED);
      });
  };

  let localFile;
  if (uploadState !== UploadState.NONE) {
    localFile = {
      id: -1,
      name: uploadFileName ?? '',
      status: uploadStateToRawFileState[uploadState],
      metadataError: null,
    };
  }

  usePolling(refreshSummary);

  return (
    <>
      <PageContainer>
        <PageHeader title="Files" />
        <div className={styles.root}>
          <div className={styles.header}>
            <Tabs
              defaultActiveKey={defaultActiveKey}
              onChange={(key) => {
                history.push(`${Paths.FILES}/${key}`);
                setActiveTabKey(key);
              }}
            >
              {CAN_UPLOAD_SOURCE_FILES && (
                <TabPane
                  tab={
                    <div
                      className={styles.tab}
                      data-testid="Files__tab--upload"
                    >
                      Upload
                      <Badge
                        className={cn(styles.badge, {
                          [styles.badgeActive]: activeTabKey === 'upload',
                        })}
                        count={summary ? summary['PROCESSING'] : 0}
                      />
                    </div>
                  }
                  key="upload"
                />
              )}
              <TabPane
                tab={
                  <div className={styles.tab} data-testid="Files__tab--failed">
                    Failed
                    <Badge
                      className={cn(styles.badge, {
                        [styles.badgeActive]: activeTabKey === 'failed',
                      })}
                      count={summary ? summary['QUALITY_ERROR'] : 0}
                    />
                  </div>
                }
                key="failed"
              />
              <TabPane
                tab={
                  <div
                    className={styles.tab}
                    data-testid="Files__tab--transcode"
                  >
                    Transcode
                    <Badge
                      className={cn(styles.badge, {
                        [styles.badgeActive]: activeTabKey === 'transcode',
                      })}
                      count={
                        summary
                          ? (summary['QUALITY_PASSED'] ?? 0) +
                            (summary['QUALITY_WARNING'] ?? 0)
                          : 0
                      }
                    />
                  </div>
                }
                key="transcode"
              />
              <TabPane
                tab={
                  <div className={styles.tab} data-testid="Files__tab--ready">
                    Ready
                    <Badge
                      className={cn(styles.badge, {
                        [styles.badgeActive]: activeTabKey === 'ready',
                      })}
                      count={summary ? summary['UNASSIGNED'] : 0}
                    />
                  </div>
                }
                key="ready"
              />
              <TabPane
                tab={
                  <div
                    className={styles.tab}
                    data-testid="Files__tab--assigned"
                  >
                    Assigned
                    <Badge
                      className={cn(styles.badge, {
                        [styles.badgeActive]: activeTabKey === 'assigned',
                      })}
                      count={summary ? summary['ASSIGNED'] : 0}
                    />
                  </div>
                }
                key="assigned"
              />
            </Tabs>
            <div className={styles.actionStyles}>
              <Search
                onSearch={(data) => {
                  history.push(`${Paths.FILES}/${activeTabKey}`);
                  setSearchQuery(data);
                }}
                key="search"
              />
              {CAN_UPLOAD_SOURCE_FILES && (
                <div data-testid="Files__button--upload">
                  <FileSelect
                    disabled={uploadState === UploadState.IN_PROGRESS}
                    onSelectFile={startUpload}
                  >
                    Upload new file
                  </FileSelect>
                </div>
              )}
            </div>
          </div>
          <Switch>
            {/* Default tab */}
            <Route exact path={Paths.FILES}>
              <UploadFilesTable localFile={localFile} progress={progress} />
            </Route>
            {CAN_UPLOAD_SOURCE_FILES && (
              <Route exact path={`${Paths.FILES}/upload`}>
                <UploadFilesTable
                  localFile={localFile}
                  query={searchQuery}
                  progress={progress}
                  statuses={UPLOAD_STATUSES}
                />
              </Route>
            )}
            <Route exact path={`${Paths.FILES}/failed`}>
              <FailedFilesTable
                localFile={localFile}
                query={searchQuery}
                statuses={FAILED_STATUSES}
              />
            </Route>
            <Route exact path={`${Paths.FILES}/failed/page/:page`}>
              <FailedFilesTable
                localFile={localFile}
                query={searchQuery}
                statuses={FAILED_STATUSES}
              />
            </Route>
            <Route path={`${Paths.FILES}/upload/page/:page`}>
              <UploadFilesTable localFile={localFile} progress={progress} />
            </Route>

            <Route exact path={`${Paths.FILES}/transcode`}>
              <TranscodeFilesTable query={searchQuery} />
            </Route>
            <Route
              path={`${Paths.FILES}/transcode/page/:page`}
              component={TranscodeFilesTable}
            />

            <Route exact path={`${Paths.FILES}/ready`}>
              <ReadyFilesTable query={searchQuery} />
            </Route>
            <Route
              path={`${Paths.FILES}/ready/page/:page`}
              component={ReadyFilesTable}
            />

            <Route exact path={`${Paths.FILES}/assigned`}>
              <AssignedFilesTable query={searchQuery} />
            </Route>
            <Route
              path={`${Paths.FILES}/assigned/page/:page`}
              component={AssignedFilesTable}
            />
          </Switch>
        </div>
      </PageContainer>
    </>
  );
};
