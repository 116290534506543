import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { removeCollection } from 'actions/collections';
import { Modal, Table, Tooltip } from 'antd';
import StatusTag, { StatusTagConfig } from 'components/StatusTag';
import StringList from 'components/StringList';
import { useGetRegions } from 'hooks/useRegions';
import { useHistory } from 'react-router-dom';
import { Collection, CollectionStatus, CollectionsFilters } from 'types';
import { errorNotify } from 'utils/errorNotify';
import { Pagination, usePaginationObject } from 'utils/pagination';
import { captureError } from 'utils/captureError';
import { Paths } from 'types/paths';
import styles from './CollectionTable.module.scss';

const { Column } = Table;

const CollectionTable = ({
  pagination,
  currentPage,
  loading,
  onFilter,
  refresh,
}: {
  pagination?: Pagination<Collection>;
  currentPage: number;
  loading?: boolean;
  onFilter: ({ status, regions }: CollectionsFilters) => void;
  refresh: () => void;
}) => {
  const history = useHistory();
  const [selectedCollection, setSelectedCollection] = useState<Collection>();
  const [deleteCollection, isDeleteProcessing] = useSendAction(
    async () =>
      removeCollection({
        uuid: selectedCollection?.uuid ? selectedCollection.uuid : '',
      }),
    {
      onDone: () => {
        setSelectedCollection(undefined);
        refresh();
      },
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );
  const { regions } = useGetRegions();

  const statusTagType = (
    status?: CollectionStatus
  ): StatusTagConfig | undefined => {
    if (!status) return;
    switch (status) {
      case CollectionStatus.CREATED:
        return { color: 'default', text: 'Draft', icon: <EditOutlined /> };
      case CollectionStatus.PUBLISHED:
        return {
          color: 'success',
          text: 'Published',
          icon: <GlobalOutlined />,
        };
      case CollectionStatus.UNPUBLISHED:
        return {
          color: 'warning',
          text: 'Unpublished',
          icon: <GlobalOutlined />,
        };
    }
  };

  return (
    <>
      <Table
        dataSource={pagination?.data || []}
        rowKey="id"
        loading={loading}
        rowClassName={styles.row}
        onRow={(collection: Collection) => ({
          onClick: () =>
            history.push(`${Paths.COLLECTIONS}/${collection.uuid}`),
        })}
        pagination={usePaginationObject(
          '/collections',
          pagination,
          currentPage
        )}
        onChange={(pagination, filters) => onFilter(filters)}
      >
        <Column title="Name" dataIndex="name" key="name" />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(type, collection: Collection) => (
            <StatusTag type={statusTagType(collection?.status)} />
          )}
          filters={[
            { text: 'Published', value: CollectionStatus.PUBLISHED },
            { text: 'Unpublished', value: CollectionStatus.UNPUBLISHED },
            { text: 'Draft', value: CollectionStatus.CREATED },
          ]}
        />
        <Column
          title="Regions"
          dataIndex="regions"
          key="regions"
          render={(type, collection: Collection) => {
            const mappedRegions = regions
              ?.filter((region) => collection.regions?.includes(region.uuid))
              .map((region) => region.name);

            if (!mappedRegions) return;

            return <StringList items={mappedRegions} />;
          }}
          filterMultiple
          filters={regions?.map((region) => ({
            text: region.name,
            value: region.uuid,
          }))}
        />
        <Column
          className={styles.actionCol}
          dataIndex="action"
          render={(_, collection: Collection) =>
            collection.status === 'PUBLISHED' ? (
              <Tooltip title="Cannot delete published collection">
                <DeleteOutlined className={styles.disabledAction} />
              </Tooltip>
            ) : (
              <DeleteOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedCollection(collection);
                }}
              />
            )
          }
        />
      </Table>

      <Modal
        title="Confirmation"
        visible={!!selectedCollection}
        onCancel={() => setSelectedCollection(undefined)}
        confirmLoading={isDeleteProcessing}
        onOk={async () => deleteCollection()}
      >
        Are you sure you want to delete{' '}
        <strong>{selectedCollection?.name}</strong>?
      </Modal>
    </>
  );
};

export default CollectionTable;
