import { deletePromotion } from 'actions/promotions';
import { Table, TablePaginationConfig } from 'antd';
import Column from 'antd/lib/table/Column';
import DeleteActionButton from 'components/DeleteActionButton';
import PageStatusTag from 'components/PageStatusTag';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import {
  DiscountType,
  Promotion,
  PromotionRoutes,
  PromotionStatus,
} from 'types/promotions';
import { captureErrorAndNotify } from 'utils/captureError';
import { DATE_FORMAT } from 'utils/constants';
import notification from 'utils/notification';

interface InternalTableProps {
  data?: Promotion[];
  promotionRoute: PromotionRoutes;
  isLoading?: boolean;
  refresh: () => void;
  paginationConfig: TablePaginationConfig;
}

const PromotionTable = ({
  data,
  promotionRoute,
  isLoading,
  paginationConfig,
  refresh,
}: InternalTableProps) => {
  const history = useHistory();
  const onPromotionDelete = () => {
    notification.success({
      title: 'Success',
      description: 'Promotion removed!',
    });
    refresh();
  };

  const valueDiscountRenderer = (amount: number, promotion: Promotion) => {
    if (amount === null || promotion.discountType === DiscountType.PERIOD)
      return '-';
    return promotion.discountType === DiscountType.PERCENTAGE
      ? `${amount}%`
      : amount / 100;
  };

  return (
    <Table
      indentSize={40}
      dataSource={data}
      rowClassName="clickable-row"
      rowKey="uuid"
      loading={isLoading}
      onRow={(promotion) => ({
        onClick() {
          history.push(`/${promotionRoute}/${promotion.uuid}`);
        },
      })}
      pagination={paginationConfig}
    >
      <Column title="Administrative name" dataIndex="name" />
      <Column
        title="Status"
        dataIndex="status"
        render={(status) => <PageStatusTag status={status} />}
      />
      <Column title="Type" dataIndex="discountType" />
      <Column
        title="Discount"
        dataIndex="amount"
        render={valueDiscountRenderer}
      />
      <Column
        title="Period (days)"
        dataIndex="amount"
        render={(amount, promotion: Promotion) =>
          amount === null || promotion.discountType !== DiscountType.PERIOD
            ? '-'
            : amount
        }
      />
      <Column
        title="Valid from"
        dataIndex="startDate"
        render={(startDate: Date) => moment(startDate).format(DATE_FORMAT)}
      />
      <Column
        title="Valid to"
        dataIndex="endDate"
        render={(endDate: Date) => moment(endDate).format(DATE_FORMAT)}
      />
      <Column
        dataIndex="delete"
        key="delete"
        width={50}
        render={(_, promotion: Promotion) => (
          <DeleteActionButton
            disabledTooltipMessage={
              promotion.status === PromotionStatus.PUBLISHED
                ? 'Cannot remove published promotions'
                : undefined
            }
            onDone={onPromotionDelete}
            onError={captureErrorAndNotify}
            action={() =>
              deletePromotion({
                uuid: promotion?.uuid,
              })
            }
          />
        )}
      />
    </Table>
  );
};

export default PromotionTable;
