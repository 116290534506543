import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsLoading, getRequestStatus } from 'store/app/selectors';
import { RequestStatuses } from 'store/app/types';
import { fetchCountriesAction } from 'store/regions/actions';
import { getCountries } from 'store/regions/selectors';
import { getType } from 'typesafe-actions';

export const useCountries = () => {
  const dispatch = useDispatch();
  const countries = useSelector(getCountries);
  const isLoadingCountries = useSelector((state: RootState) =>
    getIsLoading(state, getType(fetchCountriesAction.request))
  );
  const requestStatus = useSelector((state: RootState) =>
    getRequestStatus(state, getType(fetchCountriesAction.request))
  );

  useEffect(() => {
    if (!countries && requestStatus !== RequestStatuses.RESOLVED) {
      dispatch(fetchCountriesAction.request());
    }
  }, [dispatch, countries, requestStatus]);

  return { countries, isLoadingCountries };
};
