import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import CensorshipRuleDetails from './CensorshipRuleDetails';
import CensorshipRules from './CensorshipRuleList';

export default () => (
  <Switch>
    <Route path={Paths.CENSORSHIPS} exact>
      <CensorshipRules />
    </Route>
    <Route path={`${Paths.CENSORSHIPS}/:region/:id`}>
      <CensorshipRuleDetails />
    </Route>
  </Switch>
);
