import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { GetPagesQuery, removePage } from 'actions/pages';
import { Modal, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import PageStatusTag from 'components/PageStatusTag';
import StringList from 'components/StringList';
import { useHistory } from 'react-router-dom';
import { Metadata, Region } from 'types';
import { Page } from 'types/pages';
import { DATE_FORMAT } from 'utils/constants';
import { errorNotify } from 'utils/errorNotify';
import { usePageParam, usePaginationObject } from 'utils/pagination';
import { transformSorter } from 'utils/table';
import { captureError } from 'utils/captureError';
import { useAllLanguages } from 'hooks/useLanguages';
import styles from './index.module.scss';

interface PagesTableProps {
  data?: Page[];
  count: number;
  isDataLoading?: boolean;
  regions?: Region[];
  onChange: (query: GetPagesQuery) => void;
  refreshData?: () => void;
}

const PagesTable = ({
  data,
  count,
  isDataLoading,
  regions,
  onChange,
  refreshData,
}: PagesTableProps) => {
  const { push } = useHistory();
  const currentPage = usePageParam();
  const [selectedPage, setSelectedPage] = useState<Page>();
  const { getLanguageNameByIsoCode } = useAllLanguages();
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: { regions?: string[] },
    sorter: SorterResult<Page> | SorterResult<Page>[]
  ) => {
    onChange({
      filters,
      sorter: transformSorter(sorter),
    });
  };

  const [onPageRemove, isRemoveInProgress] = useSendAction(
    async (id: string) => removePage(id),
    {
      onDone: () => {
        setSelectedPage(undefined);
        if (refreshData) {
          refreshData();
        }
      },
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  return (
    <>
      <Table
        indentSize={40}
        onChange={onTableChange}
        dataSource={data}
        loading={isDataLoading}
        pagination={usePaginationObject('/pages', { count }, currentPage)}
        rowClassName="clickable-row"
        rowKey="uuid"
        onRow={(page: Page) => ({
          onClick: () => push(`/pages/${page.uuid}`),
        })}
      >
        <Column
          title="Administrative name"
          dataIndex="name"
          render={(name) => name}
        />

        <Column
          title="Status"
          dataIndex="status"
          render={(status) => <PageStatusTag status={status} />}
        />

        <Column
          title="Regions"
          dataIndex="regions"
          filters={regions?.map((r: Region) => ({
            text: r.name,
            value: r.uuid,
          }))}
          render={(pageRegions: string[]) => <StringList items={pageRegions} />}
        />

        <Column
          title="Languages"
          dataIndex="metadata"
          render={(metadata: Metadata) => (
            <StringList
              items={Object.keys(metadata)
                .map((lang) => getLanguageNameByIsoCode(lang) ?? '')
                .filter((l) => !!l)}
            />
          )}
        />

        <Column
          title="Update date"
          dataIndex="updatedAt"
          /* When updating these please update initial query in PagesList/index.ts */
          sortDirections={['descend', 'ascend']}
          defaultSortOrder="descend"
          sorter
          render={(date: moment.Moment) => date.format(DATE_FORMAT)}
        />

        <Column
          className={styles.actionCol}
          dataIndex="action"
          render={(_, page: Page) =>
            page.status === 'PUBLISHED' ? (
              <Tooltip title="Cannot delete published page">
                <DeleteOutlined className={styles.disabledAction} />
              </Tooltip>
            ) : (
              <DeleteOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedPage(page);
                }}
              />
            )
          }
        />
      </Table>
      <Modal
        title="Confirmation"
        visible={!!selectedPage}
        onCancel={() => setSelectedPage(undefined)}
        confirmLoading={isRemoveInProgress}
        onOk={() => {
          if (selectedPage?.uuid) {
            onPageRemove(selectedPage.uuid);
          }
        }}
      >
        Are you sure you want to delete <strong>{selectedPage?.name}</strong>?
      </Modal>
    </>
  );
};

export default PagesTable;
