import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ErrorIndicator from 'components/ErrorIndicator';
import { getContentFile, getTranscodedMP4File } from 'actions/content';
import LoadingIndicator from 'components/LoadingIndicator';
import { AxiosError } from 'axios';
import { unassignFromContent } from 'actions/files';
import VideoPlayer from 'components/VideoPlayer';
import Button from 'components/Button';
import Space from 'components/Space';
import {
  useFetchAction,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import { RouteIdParams } from 'utils/routeParams';
import { UploadedFile } from '../../../types';
import ContentFileChooser from './ContentFileChooser';

const ContentFile = () => {
  const { id } = useParams<RouteIdParams>();
  const [file, isLoading, error, refresh] = useFetchAction<UploadedFile>(
    useCallback(() => getContentFile({ id: id! }), [id])
  );

  // Unassign
  const [unassign, isUnassinging, , , unassignError] = useSendAction(
    useCallback(() => unassignFromContent({ id: file?.id! }), [file]),
    { onDone: refresh }
  );

  // temporary solution to get mp4 file
  const [mp4MainVideo] = useFetchAction(
    useCallback(
      () =>
        file && file.id
          ? getTranscodedMP4File(file?.id)
          : Promise.resolve({} as { url: string }),
      [file]
    )
  );

  const isNotFound = error && (error as AxiosError).response?.status === 404;

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      {file && (
        <Space>
          {file.name}
          <Button disabled={isUnassinging} onClick={() => unassign()}>
            Unassign
          </Button>
          <ErrorIndicator error={unassignError} />
        </Space>
      )}

      {file?.details && (
        <VideoPlayer
          videoFile={{
            video: { ...file.details.video, raw: mp4MainVideo?.url },
          }}
        />
      )}

      {!isLoading && !file && <ContentFileChooser refresh={refresh} />}
      {!isNotFound && <ErrorIndicator error={error} />}
    </div>
  );
};

export default ContentFile;
