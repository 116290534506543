export enum RequestStatuses {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export interface IRequestAction {
  action: string;
  status: RequestStatuses;
}
