import React, { useCallback, useState } from 'react';
import { Asset, Pagination } from 'types';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import AssetsTableSearchable from 'components/AssetsTableSearchable';
import { mapAssetListToTableStructure } from 'utils/table';
import { getAssetsPagination } from 'actions/assets';
import AssetAddToEntityModal from 'components/AssetAddToEntityModal';
import Button from 'components/Button';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { AssetAccessType } from '@laminar-product/client-commons-core/core';
import _uniqBy from 'lodash/uniqBy';
import { AvodForm } from 'pages/Avod/index';
import { GuestAccessForm } from 'types/guestAccess';
import styles from './index.module.scss';

interface AssetListAvodProps {
  assignedAssetUuids?: string[];
  regionUuid?: string;
  form: UseFormMethods<AvodForm> | UseFormMethods<GuestAccessForm>;
  selectedAssets?: Asset[];
}

const AssetSearchableFormTable = ({
  assignedAssetUuids,
  regionUuid,
  form,
  selectedAssets = [],
}: AssetListAvodProps) => {
  const { setValue, register } = form;
  const [modalOpened, setModalOpened] = useState(false);
  const [query, setQuery] = useState('');

  useFetchActionObj(
    useCallback(() => {
      if (!regionUuid || !assignedAssetUuids?.length) {
        return Promise.resolve({ data: [], count: 0 });
      }

      return getAssetsPagination({
        assetUuids: assignedAssetUuids,
        regionUuids: [regionUuid],
        limit: assignedAssetUuids.length,
      });
    }, [assignedAssetUuids, regionUuid]),
    {
      onDone: useCallback(
        (assets: Pagination<Asset>) => {
          register('assets');
          setValue('assets', assets.data);
        },
        [register, setValue]
      ),
    }
  );
  const deleteSelectedAsset = useCallback(
    (uuid: string) =>
      setValue(
        'assets',
        selectedAssets?.filter((a) => a.uuid !== uuid)
      ),
    [selectedAssets, setValue]
  );
  const openModalHandler = () => setModalOpened(true);
  const assignAssetsHandler = useCallback(
    (assets: Asset[]) => {
      setValue(
        'assets',
        _uniqBy([...selectedAssets, ...assets], (a) => a.uuid)
      );
      setModalOpened(false);
    },
    [selectedAssets, setValue]
  );
  const closeModalHandler = () => setModalOpened(false);

  return (
    <div className={styles.assetsWrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>Free assets with ads</h3>
        <Button onClick={openModalHandler} icon={<PlusOutlined />}>
          Add asset
        </Button>
      </div>
      <Controller
        name="assets"
        control={form.control}
        render={({ value }: { value: Asset[] }) => {
          const data = mapAssetListToTableStructure({
            assets: value || [],
            query,
            actionColumn: (asset: Asset) => (
              <DeleteOutlined onClick={() => deleteSelectedAsset(asset.uuid)} />
            ),
          });
          return (
            <AssetsTableSearchable setSearchQuery={setQuery} data={data} />
          );
        }}
      />
      {modalOpened && (
        <AssetAddToEntityModal
          onAdd={assignAssetsHandler}
          open={modalOpened}
          onClose={closeModalHandler}
          regionUuids={[regionUuid || '']}
          selectedAssetsUuids={(selectedAssets || []).map((a) => a.uuid)}
          modalTitle="Add assets to configuration"
          accessTypes={[AssetAccessType.FREE]}
        />
      )}
    </div>
  );
};

export default AssetSearchableFormTable;
