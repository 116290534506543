import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback } from 'react';
import {
  FeaturedAssetEditModeChangeProps,
  FeaturedAssetSource,
  FeaturedAssetSourceEditProps,
} from 'types/featuredAsset';
import { Paths } from 'types/paths';
import { Link } from 'react-router-dom';
import ScheduledQueueAvailability from '../ScheduledQueueAvailability';
import ScheduledQueueAssetEdit from '../ScheduledQueueAssetEdit';
import styles from '../../index.module.scss';

interface FeaturedAssetScheduledQueueProps {
  originalQueuedAssets: FeaturedAssetSource[];
  setOriginalQueuedAssets: (items: FeaturedAssetSource[]) => void;
  queuedUuidsInEditMode: string[];
  setQueuedUuidsInEditMode: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading?: boolean;
  currentlyDisplayed?: FeaturedAssetSource;
  currentQueuedAssets: FeaturedAssetSource[];
  setCurrentQueuedAssets: React.Dispatch<
    React.SetStateAction<FeaturedAssetSource[]>
  >;
}

const FeaturedAssetScheduledQueue = ({
  originalQueuedAssets,
  setOriginalQueuedAssets,
  queuedUuidsInEditMode,
  setQueuedUuidsInEditMode,
  isLoading,
  currentlyDisplayed,
  currentQueuedAssets,
  setCurrentQueuedAssets,
}: FeaturedAssetScheduledQueueProps) => {
  const isEditMode = useCallback(
    (itemUuid: string) =>
      queuedUuidsInEditMode?.some((uuid) => uuid === itemUuid),
    [queuedUuidsInEditMode]
  );

  const onAssetChange = useCallback(
    ({ assetToEdit, type, source }: FeaturedAssetSourceEditProps) => {
      const sourceArray =
        source === 'original' ? originalQueuedAssets : currentQueuedAssets;

      let transformedArray: FeaturedAssetSource[] = [];

      if (type === 'edit') {
        transformedArray = sourceArray?.length
          ? sourceArray.map((a) =>
              a.sourceId === assetToEdit.sourceId ? assetToEdit : a
            )
          : [assetToEdit];
      }

      if (type === 'remove') {
        transformedArray = sourceArray?.filter(
          (a) => a.sourceId !== assetToEdit.sourceId
        );
      }

      source === 'original'
        ? setOriginalQueuedAssets(transformedArray)
        : setCurrentQueuedAssets(transformedArray);
    },
    [
      currentQueuedAssets,
      originalQueuedAssets,
      setCurrentQueuedAssets,
      setOriginalQueuedAssets,
    ]
  );

  const onEditModeChange = useCallback(
    ({ type, uuid }: FeaturedAssetEditModeChangeProps) => {
      const transformedArray =
        type === 'removeFromEdit'
          ? queuedUuidsInEditMode.filter((u) => u !== uuid)
          : [...queuedUuidsInEditMode, uuid];

      setQueuedUuidsInEditMode(transformedArray);
    },
    [queuedUuidsInEditMode, setQueuedUuidsInEditMode]
  );

  return (
    <Table
      dataSource={currentQueuedAssets}
      rowKey={(item) => item.sourceId || ''}
      rowClassName={(record) =>
        record.sourceId && isEditMode(record.sourceId) ? styles.editableRow : ''
      }
      loading={isLoading}
    >
      <Column
        title="Asset"
        dataIndex="sourceId"
        key="sourceId"
        width="30%"
        render={(_, item: FeaturedAssetSource) => (
          <div className={styles.nameColumn}>
            {item.sourceId === currentlyDisplayed?.sourceId && (
              <div className={styles.dot} />
            )}

            <Link
              to={`${Paths.ASSETS}/${item.sourceId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item?.name || item?.sourceId}
            </Link>
          </div>
        )}
      />

      <Column
        title="Available from"
        dataIndex="dateFrom"
        key="dateFrom"
        width="25%"
        render={(_, item: FeaturedAssetSource) => (
          <ScheduledQueueAvailability
            type="dateFrom"
            isEditMode={isEditMode(item.sourceId)}
            item={item}
            onAssetChange={onAssetChange}
          />
        )}
      />

      <Column
        title="Available to"
        dataIndex="dateTo"
        key="dateTo"
        width="25%"
        render={(_, item: FeaturedAssetSource) => (
          <ScheduledQueueAvailability
            type="dateTo"
            isEditMode={isEditMode(item.sourceId)}
            item={item}
            onAssetChange={onAssetChange}
          />
        )}
      />

      <Column
        title="Action"
        dataIndex="action"
        key="action"
        width="20%"
        render={(_, item: FeaturedAssetSource) => (
          <ScheduledQueueAssetEdit
            item={item}
            originalAsset={originalQueuedAssets.find(
              (a) => a.sourceId === item.sourceId
            )}
            isEditMode={isEditMode(item.sourceId)}
            onAssetChange={onAssetChange}
            onEditModeChange={onEditModeChange}
          />
        )}
      />
    </Table>
  );
};

export default FeaturedAssetScheduledQueue;
