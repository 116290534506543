import { useCallback } from 'react';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import {
  changeParent,
  deleteParent,
  updateAssetMainDetails,
} from 'actions/assets';
import { assignGenres } from 'actions/genres';
import { assignMoods } from 'actions/moods';
import { assignTags } from 'actions/tags';
import { useAssetDetailsContext } from 'contexts/assetContext';
import { isEqual } from 'lodash';
import { Asset } from 'types';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import { errorNotify } from 'utils/errorNotify';
import notification from 'utils/notification';
import { assignModerations } from 'actions/moderations';

const ASSET_KEYS_TO_OMIT_WHEN_SAVING_MAIN_DETAILS = [
  'tags',
  'moods',
  'genres',
  'moderations',
];

const useUpdateAssetDetails = () => {
  const {
    assetDraft,
    setAssetDraft,
    asset: { id, parentId },
  } = useAssetDetailsContext();

  const checkIfSelectChanged = useCallback(
    async (
      data: Asset,
      assetData: Asset,
      type: keyof Asset,
      assign: (data: string[], id: number) => Promise<void>
    ): Promise<void> => {
      if (!isEqual(data[type], assetData[type])) {
        await assign(data[type] ? (data[type] as string[]) : [], id);
      }
    },
    [id]
  );

  const [updateAsset, isUpdatingAsset] = useSendAction<void, Asset>(
    useCallback(
      async (data) => {
        if (
          !assetDraft.translations ||
          (assetDraft.translations && !assetDraft.translations['en'])
        ) {
          notification.error({
            title: 'Error',
            description:
              'You have to provide metadata, with obligatory English language',
          });

          return Promise.reject();
        }

        //Check if main details has changed to update an asset. Omit some properties as they have their own requests below
        const assetMainDetailsChanged = Object.entries(data).some(
          ([key, value]) => {
            if (ASSET_KEYS_TO_OMIT_WHEN_SAVING_MAIN_DETAILS.includes(key)) {
              return false;
            }

            return assetDraft[key as keyof Asset] !== value;
          }
        );

        if (assetMainDetailsChanged) {
          const { genres, tags, moods, parentId, ...rest } = data;
          setAssetDraft({ ...assetDraft, name: data.name });

          await updateAssetMainDetails({
            assetId: id,
            data: rest,
          });
        }

        await checkIfSelectChanged(data, assetDraft, 'tags', assignTags);
        await checkIfSelectChanged(data, assetDraft, 'moods', assignMoods);
        await checkIfSelectChanged(data, assetDraft, 'genres', assignGenres);
        await checkIfSelectChanged(
          data,
          assetDraft,
          'moderations',
          assignModerations
        );

        setAssetDraft({ ...assetDraft, ...data });

        if (assetDraft.parentId && !parentId) {
          await changeParent({ id, parentId: assetDraft.parentId });
        }
        if (
          assetDraft.parentId &&
          parentId &&
          assetDraft.parentId !== parentId
        ) {
          await deleteParent({ id });
          await changeParent({ id, parentId: assetDraft.parentId });
        }
        if (!assetDraft.parentId && parentId) {
          await deleteParent({ id: assetDraft.id });
        }
      },
      [id, parentId, assetDraft, checkIfSelectChanged, setAssetDraft]
    ),
    {
      onError: (err) => {
        errorNotify(err);
      },
      onDone: () => {
        notifyAboutRepublishRequirement();
      },
    }
  );

  return {
    updateAsset,
    isSaving: isUpdatingAsset,
  };
};

export default useUpdateAssetDetails;
