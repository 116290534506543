import VideoModal from 'components/VideoModal';
import VideoPlayer from 'components/VideoPlayer';
import React, { useState } from 'react';
import { VideoFile } from 'types';
import { AiModerationDetails } from 'types/ai';
import cn from 'classnames';
import styles from './index.module.scss';

const ModerationModal = ({
  data,
  onClose,
  videoContainerRef,
  mainCategory,
  videoFile,
  playerId,
}: {
  onClose: (data: {
    isOpen: boolean;
    data?: { [key: string]: number[] };
  }) => void;
  mainCategory: string;
  data?: AiModerationDetails;
  videoContainerRef: React.RefObject<HTMLDivElement>;
  videoFile?: VideoFile;
  playerId: string;
}) => {
  const [moderationData, setTimestamps] = useState<{
    category: string;
    timestamps: number[];
  }>({
    timestamps: data?.timestamps ? data.timestamps : [],
    category: mainCategory,
  });
  const [activeIndex, setActiveIndex] = useState<number>();

  return (
    <VideoModal
      sidebarElement={
        <>
          <div className={styles.sidebarContainer}>
            <h1
              className={styles.headerTitle}
              onClick={() => {
                setActiveIndex(undefined);
                setTimestamps({
                  category: mainCategory,
                  timestamps: data?.timestamps ? data.timestamps : [],
                });
              }}
            >
              {mainCategory}
            </h1>
            <h2 className={styles.subHeader}>Second-level category</h2>
          </div>
          {data?.children && (
            <ul className={styles.categories}>
              {Object.entries(data.children).map(([key, value], index) => (
                <li
                  key={index}
                  className={cn(styles.categoryElement, [
                    { [styles.active]: activeIndex === index },
                  ])}
                  onClick={() => {
                    setActiveIndex(index);
                    setTimestamps({ category: key, timestamps: value });
                  }}
                >
                  {key}
                </li>
              ))}
            </ul>
          )}
        </>
      }
      visible={true}
      onCancel={() => onClose({ data: undefined, isOpen: false })}
      videoElement={
        <div className={styles.videoContainer} ref={videoContainerRef}>
          {videoFile && (
            <VideoPlayer
              playerId={playerId}
              videoFile={videoFile}
              markers={[
                {
                  tag: moderationData.category,
                  timestamps: moderationData.timestamps,
                },
              ]}
            />
          )}
        </div>
      }
    />
  );
};

export default ModerationModal;
