import React, { ReactElement } from 'react';
import Tag from 'antd/lib/tag';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  GlobalOutlined,
} from '@ant-design/icons';

export interface StatusTagConfig {
  icon?: ReactElement;
  color: string;
  text: string;
}

export type Status =
  | 'DRAFT'
  | 'MODIFIED'
  | 'PUBLISHED'
  | 'UNPUBLISHED'
  | 'SCHEDULED'
  | 'CREATED';

export function getStatusTagConfig(status: Status): StatusTagConfig {
  switch (status) {
    case 'DRAFT':
      return {
        color: 'default',
        text: 'Draft',
      };
    case 'CREATED':
      return {
        color: 'default',
        text: 'Created',
      };
    case 'MODIFIED':
      return {
        color: 'success',
        text: 'Published', // MODIFIED means that there are unpublished changes, however the entity is published
        icon: <ClockCircleOutlined />,
      };
    case 'PUBLISHED':
      return {
        color: 'success',
        text: 'Published',
        icon: <GlobalOutlined />,
      };
    case 'UNPUBLISHED':
      return {
        color: 'default',
        text: 'Unpublished',
      };
    case 'SCHEDULED':
      return {
        color: 'blue',
        text: 'Scheduled',
        icon: <CalendarOutlined />,
      };
    default:
      return {
        color: 'default',
        text: 'Draft',
      };
  }
}

const StatusTag = ({ type }: { type?: StatusTagConfig }) => {
  if (!type) return <>No type</>;
  return (
    <Tag color={type.color} icon={type.icon}>
      {type.text}
    </Tag>
  );
};

export default StatusTag;
