import { Button, Divider, Modal, Steps, message } from 'antd';
import Spacer from 'components/Spacer';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Asset,
  AssetCreateForm,
  Content,
  ContentCreateForm,
  UploadedFile,
} from 'types';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { errorNotify } from 'utils/errorNotify';
import { assignContentToFile } from 'actions/files';
import { assignAssetToContent, createContent } from 'actions/content';
import { createAsset } from 'actions/assets';
import ContentAssign from './ContentAssign';
import AssetAssign from './AssetAssign';
import styles from './index.module.scss';
const { Step } = Steps;

export interface AssignFileModalProps {
  closeModal: () => void;
  isOpen: boolean;
  file: UploadedFile;
}

const AssignFileModal = ({
  file,
  isOpen,
  closeModal,
}: AssignFileModalProps) => {
  const { reset: resetContent, ...restContentCreateForm } =
    useForm<ContentCreateForm>({
      mode: 'onChange',
      defaultValues: { title: file.name.split('.')[0] },
    });

  const { reset: resetAsset, ...restAssetCreateForm } =
    useForm<AssetCreateForm>({
      mode: 'onChange',
    });

  const formProps = { labelCol: { span: 4 }, style: { marginTop: 20 } };
  const [step, setStep] = useState(0);
  const [mode, setMode] = useState<'select' | 'create'>('create');
  const [selectedContent, setSelectedContent] = useState<Content>();
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const [contentForm, setContentCreateForm] = useState<ContentCreateForm>();

  const resetWholeState = useCallback(() => {
    resetContent();
    resetAsset();
    setSelectedAsset(undefined);
    setSelectedContent(undefined);
  }, [resetAsset, resetContent, setSelectedAsset, setSelectedContent]);

  // Reset state when file changes
  useEffect(() => {
    setStep(0);
    setMode('create');
    resetWholeState();
  }, [file, resetWholeState]);

  const { isValid: isContentValid } = restContentCreateForm.formState;
  const { isValid: isAssetValid } = restAssetCreateForm.formState;

  const [assign] = useSendAction<
    void,
    {
      contentForm?: ContentCreateForm;
      assetForm?: AssetCreateForm;
    }
  >(
    useCallback(
      async ({ assetForm, contentForm }) => {
        let createdContent: Content | undefined;
        let createdAsset: Asset | undefined;

        if (contentForm?.title && contentForm?.type) {
          createdContent = await createContent({ form: contentForm });
        }

        await assignContentToFile({
          id: file?.id!,
          contentId: createdContent ? createdContent.id : selectedContent?.id!,
        });

        if (assetForm?.name) {
          createdAsset = await createAsset({ form: assetForm });
        }

        await assignAssetToContent({
          id: createdContent ? createdContent.id : selectedContent?.id!,
          assetId: createdAsset ? createdAsset.id : selectedAsset?.id!,
        });
      },
      [file, selectedAsset, selectedContent]
    ),
    {
      onError: (err) => {
        errorNotify(err);
        closeModal();
        resetWholeState();
      },
      onDone: () => {
        closeModal();
        resetWholeState();
        message.success('File has been assigned successfully');
      },
    }
  );

  return (
    <Modal
      title="Assign file"
      visible={isOpen}
      onCancel={closeModal}
      afterClose={resetWholeState}
      footer={
        <>
          {step === 0 && (
            <Button
              type="primary"
              disabled={mode === 'select' ? !selectedContent : !isContentValid}
              onClick={() => {
                setContentCreateForm(restContentCreateForm.getValues());
                setStep(1);
                setMode('select');
              }}
            >
              Continue
            </Button>
          )}
          {step === 1 && (
            <div className={styles.buttonWrapper}>
              <Spacer />
              <Button onClick={() => setStep(0)}>Back</Button>
              <Button
                disabled={mode === 'select' ? !selectedAsset : !isAssetValid}
                type="primary"
                onClick={() =>
                  assign({
                    assetForm: restAssetCreateForm.getValues(),
                    contentForm,
                  })
                }
              >
                Finish
              </Button>
            </div>
          )}
        </>
      }
    >
      <Steps size="small" current={step}>
        <Step title="Assign to content" />
        <Step title="Assign content to Asset" />
      </Steps>
      <Divider />
      {step === 0 ? (
        <ContentAssign
          form={{ ...restContentCreateForm, reset: resetContent }}
          formProps={formProps}
          mode={mode}
          onModeChange={setMode}
          onSelectContent={setSelectedContent}
          selectedContent={selectedContent}
        />
      ) : (
        <AssetAssign
          form={{ ...restAssetCreateForm, reset: resetAsset }}
          formProps={formProps}
          mode={mode}
          onModeChange={setMode}
          onSelectAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
        />
      )}
    </Modal>
  );
};

export default AssignFileModal;
