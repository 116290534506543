import { CancelToken } from 'axios';
import { CensorshipRule, CensorshipRuleTranslations } from 'types/censorships';
import { CensorshipRule as CensorshipRuleOld } from 'types';
import { api } from './api';

export const createCensorshipRule = async ({
  censorshipToSave,
}: {
  censorshipToSave: Pick<CensorshipRule, 'minAge' | 'name' | 'regionUuid'>;
  cancelToken?: CancelToken;
}): Promise<CensorshipRule> => {
  const { data } = await api().post<CensorshipRule>(
    `/censorship`,
    censorshipToSave
  );

  return data;
};

export const updateCensorshipRule = async ({
  censorshipToSave,
  id,
}: {
  censorshipToSave: CensorshipRule;
  id: string;
}): Promise<CensorshipRule> => {
  const { data } = await api().put(`/censorship/${id}`, censorshipToSave);

  if (
    censorshipToSave.translations &&
    Object.keys(censorshipToSave.translations).length > 0
  ) {
    await setCensorshipRuleTranslation({
      translationsToSave: censorshipToSave.translations,
      id,
    });
  }

  return data;
};

export const setCensorshipRuleTranslation = async ({
  translationsToSave,
  id,
}: {
  translationsToSave: CensorshipRuleTranslations;
  id: string;
}): Promise<CensorshipRule> => {
  const { data } = await api().put(
    `/censorship/${id}/translations`,
    translationsToSave
  );

  return data;
};

export const getCensorshipsRules = async (params: {
  regionUuid?: string;
  assetId?: number;
}): Promise<CensorshipRule[]> => {
  const { data } = await api().get<CensorshipRule[]>(`/censorship`, {
    params,
  });

  return data;
};

export const getCensorshipRules = async (): Promise<CensorshipRuleOld[]> => {
  const { data } = await api().get<CensorshipRuleOld[]>(`/censorship`);

  return data;
};

export const getCensorshipRule = async (
  id: string
): Promise<CensorshipRule> => {
  const { data } = await api().get<CensorshipRule>(`/censorship/${id}`);

  return data;
};

export const deleteCensorshipRule = async ({
  censorshipRuleId,
}: {
  censorshipRuleId: number;
}): Promise<void> => {
  const { data } = await api().delete(`/censorship/${censorshipRuleId}`);

  return data;
};

export const assignAssetCensorshipRule = async ({
  censorshipRuleId,
  assetId,
}: {
  censorshipRuleId: number;
  assetId: number;
}): Promise<void> => {
  await api().put(`/censorship/${censorshipRuleId}/assets`, { assetId });
};

export const deleteAssetCensorshipRule = async ({
  censorshipRuleId,
  assetId,
}: {
  censorshipRuleId: number;
  assetId: number;
}): Promise<void> => {
  await api().delete(`/censorship/${censorshipRuleId}/assets/${assetId}`);
};

export const publishCensorshipRules = async (): Promise<void> => {
  await api().post(`/censorship/publish`);
};
