import { Content, ContentType } from 'types';

export const contentSortComparator = (content: Content) => {
  switch (content.type) {
    case ContentType.MAIN:
      return -1;
    case ContentType.TRAILER:
      return 0;
    default:
      return 1;
  }
};

export const getContentSectionHeader = (contentType: string) => {
  switch (contentType) {
    case ContentType.MAIN:
      return 'MAIN CONTENT VIDEO';
    case ContentType.TRAILER:
      return 'TRAILERS';
    case ContentType.EXTRA:
      return 'EXTRAS';
    case ContentType.LIVE:
      return 'LIVES';
    default:
      return 'CONTENT';
  }
};
