import React from 'react';
import { Input, InputNumber } from 'antd';
import FormGroup from 'components/FormGroup';
import { Controller, UseFormMethods } from 'react-hook-form';
import { CensorshipRule, CensorshipRuleTranslation } from 'types/censorships';
import TextArea from 'antd/lib/input/TextArea';

export const Name = ({
  control,
  errors,
}: Pick<UseFormMethods<CensorshipRule>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={Input}
        control={control}
        name="name"
        rules={{ required: 'Required' }}
      />
    }
    label="Age Rating Label"
    errorMessage={errors.name?.message}
  />
);

export const MinAge = ({
  control,
  errors,
}: Pick<UseFormMethods<CensorshipRule>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={InputNumber}
        control={control}
        name="minAge"
        rules={{ required: 'Required' }}
      />
    }
    label="Minimum Age"
    errorMessage={errors.minAge?.message}
  />
);

export const TranslationName = ({
  control,
  errors,
}: Pick<UseFormMethods<CensorshipRuleTranslation>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={Input}
        control={control}
        name="name"
        rules={{ required: 'Required' }}
      />
    }
    label="Rating name"
    errorMessage={errors?.name?.message}
  />
);

export const TranslationDescription = ({
  control,
  errors,
}: Pick<UseFormMethods<CensorshipRuleTranslation>, 'control' | 'errors'>) => (
  <FormGroup
    input={
      <Controller
        as={TextArea}
        control={control}
        name="description"
        rules={{ required: 'Required' }}
      />
    }
    label="Rating description"
    errorMessage={errors.description?.message}
  />
);
