import { usePermissionContext } from 'contexts/currentUserContext';
import { Permission } from 'utils/constants';

export const usePermission = (): {
  canUse: (permissions: Permission[]) => boolean;
  canUseAny: (permissions: Permission[]) => boolean;
} => {
  const { currentUser } = usePermissionContext();

  const canUse = (permissions: Permission[]) =>
    permissions.every((p) => currentUser?.permissions?.includes(p));

  const canUseAny = (permissions: Permission[]) =>
    permissions.some((p) => currentUser?.permissions?.includes(p));

  return { canUse, canUseAny };
};
