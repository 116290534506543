import React, { ReactElement } from 'react';
import styles from './index.module.scss';

interface HeaderBadgeProps {
  icon: ReactElement;
  name: string;
  color: string;
}

const HeaderBadge = ({ color, icon, name }: HeaderBadgeProps) => (
  // this "30" tells that set 30% opacity on bg color. Now we have pass only
  // main color to component.
  <div className={styles.root} style={{ backgroundColor: `${color}30` }}>
    <span className={styles.icon} style={{ color, fill: color }}>
      {icon}
    </span>
    <span className={styles.name} style={{ color }}>
      {name}
    </span>
  </div>
);

export default HeaderBadge;
