import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { PageComponent } from 'types/pages';
import ComponentTile from './ComponentTile';
import ComponentListHead from './ComponentListHead';

interface SortableElementType {
  component: PageComponent;
  onDelete: () => void;
  onEdit: () => void;
}

interface SortableContainerType {
  components?: PageComponent[];
  onDelete: (c: PageComponent) => void;
  onItemClick: (c: PageComponent) => void;
}

const SortableItem = SortableElement<SortableElementType>(ComponentTile);

export interface ComponentListProps {
  components?: PageComponent[];
  onDelete: (component: PageComponent) => void;
  onItemClick: (component: PageComponent) => void;
}

const ComponentList = SortableContainer<SortableContainerType>(
  ({ components, onDelete, onItemClick }: ComponentListProps) => {
    return (
      <div>
        {/* This must be a div because of sortable-hoc */}
        <ComponentListHead />
        {components?.map((c, index) => (
          <SortableItem
            index={index}
            key={c.builderId}
            component={c}
            onDelete={() => onDelete(c)}
            onEdit={() => onItemClick(c)}
          />
        ))}
      </div>
    );
  }
);

export default ComponentList;
