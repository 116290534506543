import { Input, Modal } from 'antd';
import { User } from 'types';
import React, { useEffect } from 'react';
import FormGroup from 'components/FormGroup';
import Select from 'components/Select';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getRoles } from 'actions/users';
import { Controller, useForm } from 'react-hook-form';
import { UpdatedAdminUser } from 'types/user';

const EditUserModal = ({
  user,
  onOk,
  onCancel,
  opened,
  loading,
}: {
  user?: User;
  onOk: (updatedUser: UpdatedAdminUser) => void;
  onCancel: () => void;
  opened?: boolean;
  loading?: boolean;
}) => {
  const [roles] = useFetchAction<string[]>(getRoles);
  const { control, reset, handleSubmit, errors } = useForm<UpdatedAdminUser>();

  const rolesOptions = roles?.map((role) => ({ label: role, value: role }));

  const onCancelClick = () => {
    reset();
    onCancel();
  };

  const onConfirmClick = (values: UpdatedAdminUser) => {
    onOk(values);
    onCancelClick();
  };

  useEffect(() => {
    if (!user) return;

    reset({
      ...user,
    });
  }, [reset, user]);

  return (
    <Modal
      visible={opened}
      title="Edit"
      okText="Save"
      confirmLoading={loading}
      onOk={handleSubmit(onConfirmClick)}
      onCancel={onCancelClick}
    >
      <FormGroup
        errorMessage={errors.email?.message}
        label="Email"
        input={
          <Controller as={Input} control={control} name="email" disabled />
        }
      />

      <FormGroup
        label="Roles"
        input={
          <Controller
            as={<Select mode="multiple" options={rolesOptions} />}
            control={control}
            name="roles"
          />
        }
      />
    </Modal>
  );
};

export default EditUserModal;
