import React from 'react';
import { ReactComponent as EpisodeIcon } from 'assets/episode-icon.svg';
import { ReactComponent as MovieIcon } from 'assets/movie-icon.svg';
import { ReactComponent as SeasonIcon } from 'assets/season-icon.svg';
import { ReactComponent as ShowIcon } from 'assets/show-icon.svg';
import HeaderBadge from 'components/HeaderBadge';
import { AssetType } from '@laminar-product/client-commons-core/core';

interface AssetBadgeProps {
  assetType: AssetType;
}

const AssetBadge = ({ assetType }: AssetBadgeProps) => {
  switch (assetType) {
    case AssetType.EPISODE:
      return (
        <HeaderBadge icon={<EpisodeIcon />} name="Episode" color="#cc6002" />
      );
    case AssetType.MOVIE:
      return <HeaderBadge icon={<MovieIcon />} name="Movie" color="#00a94f" />;
    case AssetType.SEASON:
      return (
        <HeaderBadge icon={<SeasonIcon />} name="Season" color="#8680f2" />
      );
    case AssetType.SHOW:
      return <HeaderBadge icon={<ShowIcon />} name="Show" color="#0048ff" />;
    default:
      return <></>;
  }
};

export default AssetBadge;
