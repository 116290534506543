import { IRequestAction } from 'store/app/types';
import { Action, createReducer } from 'typesafe-actions';
import { requestAction } from './actions';

export interface IAppState {
  requestActions: IRequestAction[];
}

const initialState = {
  requestActions: [],
};

export const appReducer = createReducer<IAppState, Action>(
  initialState
).handleAction(requestAction, (state, { payload }) => {
  const actionExist = state.requestActions.some(
    ({ action }) => action === payload.action
  );

  if (!actionExist) {
    return {
      ...state,
      requestActions: [...state.requestActions, payload],
    };
  }
  return {
    ...state,
    requestActions: state.requestActions.map((action) => {
      if (action.action === payload.action) {
        return {
          ...action,
          status: payload.status,
        };
      }
      return action;
    }),
  };
});
