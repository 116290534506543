import React from 'react';
import { AssetContentProvider } from 'contexts/assetContentContext';
import { AssetProvider } from 'contexts/assetContext';
import { Route, Switch } from 'react-router-dom';
import { Paths } from 'types/paths';
import AssetDetails from './AssetDetails';
import AssetForm from './AssetForm';
import AssetList from './AssetList';

export default () => (
  <Switch>
    <Route exact path={Paths.ASSETS} component={AssetList} />
    <Route path={`${Paths.ASSETS}/page/:page`} component={AssetList} />
    <Route path={`${Paths.ASSETS}/create`} component={AssetForm} />
    <Route
      path={`${Paths.ASSETS}/:id`}
      children={
        <AssetProvider>
          <AssetContentProvider>
            <AssetDetails />
          </AssetContentProvider>
        </AssetProvider>
      }
    />
  </Switch>
);
