import { Checkbox, Input, Select } from 'antd';
import FormGroup from 'components/FormGroup';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import { IntervalType } from 'types/plan';
import styles from './index.module.scss';

type PartnerPriceFormProps<T extends FieldValues> = {
  control: Control<T>;
  errors: DeepMap<T, FieldError>;
  setPlanPriceEnabled: (value: boolean) => void;
  planPriceEnabled: boolean;
};

const PartnerPriceForm = <T extends FieldValues>({
  control,
  errors,
  planPriceEnabled,
  setPlanPriceEnabled,
}: PartnerPriceFormProps<T>) => (
  <>
    <FormGroup
      input={
        <Controller
          placeholder="Amount"
          rules={{
            required: planPriceEnabled ? 'This field is required' : false,
          }}
          as={(props) => (
            <div className={styles.priceContainer}>
              <Checkbox
                defaultChecked={planPriceEnabled}
                onChange={(value) => setPlanPriceEnabled(value.target.checked)}
              />
              <Input {...props} disabled={!planPriceEnabled} type="number" />
              <div className={styles.currency}>INR</div>
            </div>
          )}
          control={control}
          name="price.amount"
        />
      }
      label="Plan price"
      // todo: pass proper types for nested errors
      errorMessage={(errors?.price as any)?.amount?.message}
    />
    <FormGroup
      input={
        <div className={styles.intervalContainer}>
          <Controller
            rules={{
              required: planPriceEnabled ? 'This field is required' : false,
            }}
            as={(props) => (
              <Input {...props} disabled={!planPriceEnabled} type="number" />
            )}
            control={control}
            defaultValue={1}
            name="price.intervalMultiplier"
          />

          <Controller
            as={(props) => (
              <Select
                {...props}
                options={[
                  { value: IntervalType.DAY, label: IntervalType.DAY },
                  { value: IntervalType.WEEK, label: IntervalType.WEEK },
                  { value: IntervalType.MONTH, label: IntervalType.MONTH },
                  { value: IntervalType.YEAR, label: IntervalType.YEAR },
                ]}
                disabled={!planPriceEnabled}
              />
            )}
            defaultValue={IntervalType.DAY}
            control={control}
            name="price.interval"
          />
        </div>
      }
      label="Billing frequency"
      // todo: pass proper types for nested errors
      errorMessage={(errors?.price as any)?.intervalMultiplier?.message}
    />
  </>
);

export default PartnerPriceForm;
