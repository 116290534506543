import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Radio } from 'antd';
import styles from './index.module.scss';

interface AdvancedRadioElementProps {
  title?: string;
  description?: string | ReactNode;
  onSelect?: (value: string) => void;
  selected?: string;
  value: string;
  disabled?: boolean;
  className?: string;
  extra?: ReactNode;
}

const AdvancedRadioElement = ({
  title,
  description,
  onSelect,
  selected,
  value,
  disabled,
  className,
  extra,
}: AdvancedRadioElementProps) => {
  const onSelectHandler = () => {
    if (disabled) return;
    onSelect?.(value);
  };
  return (
    <div
      className={cn(styles.root, className, {
        [styles.active]: selected === value,
        [styles.disabled]: disabled,
      })}
      onClick={onSelectHandler}
    >
      <div className={styles.radioBox}>
        <Radio checked={selected === value} onChange={onSelectHandler} />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.wrapper}>
          {title && <div className={styles.title}>{title}</div>}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      </div>
      {extra}
    </div>
  );
};

export default AdvancedRadioElement;
