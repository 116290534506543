import { AssetType } from '@laminar-product/client-commons-core/core';

const mapAssetTypeToChildrenOrderingLabel: { [key in AssetType]?: string } = {
  [AssetType.SEASON]: 'Episodes',
  [AssetType.SHOW]: 'Seasons',
};

export const getLabelForChildrenOrdering = (type: AssetType) => {
  return mapAssetTypeToChildrenOrderingLabel[type];
};

export const shouldPresentChildrenOrdering = (type: AssetType) => {
  return [AssetType.SHOW, AssetType.SEASON].includes(type);
};
