import { TagProps } from 'antd';
import { LiveStreamInfrastructureStatus } from 'types/live';
import formatLabel from 'utils/formatLabel';

export const statusLiveTagColor = (
  status?: LiveStreamInfrastructureStatus
): TagProps['color'] => {
  switch (status) {
    case LiveStreamInfrastructureStatus.CREATED:
    case LiveStreamInfrastructureStatus.INITIALIZING:
    case LiveStreamInfrastructureStatus.STARTING:
      return 'blue';
    case LiveStreamInfrastructureStatus.LIVE:
      return 'red';
    case LiveStreamInfrastructureStatus.RUNNING:
      return 'green';
    default:
      return '';
  }
};

export const statusLiveLabel = (
  status?: LiveStreamInfrastructureStatus
): string => formatLabel(status || LiveStreamInfrastructureStatus.NOT_CREATED);
