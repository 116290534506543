import { LiveStreamInfrastructure, LiveStreamProfile } from 'types/live';
import api from 'actions/api';

export const getLiveStreamProfiles = async (): Promise<LiveStreamProfile[]> => {
  const { data } = await api().get<LiveStreamProfile[]>(
    '/live-stream/profiles'
  );

  return data;
};

export const createLiveStream = async (
  assetId: number,
  profileUuid: string
): Promise<LiveStreamInfrastructure> => {
  const { data } = await api().post(`/asset/${assetId}/live-stream`, {
    profileUuid,
  });

  return data;
};

export const getLiveStreamDetails = async (
  assetId: number
): Promise<LiveStreamInfrastructure> => {
  const { data } = await api().get<LiveStreamInfrastructure>(
    `/asset/${assetId}/live-stream`
  );

  return data;
};

export const startLiveStream = async (
  assetId: number
): Promise<LiveStreamInfrastructure> => {
  const { data } = await api().post(`/asset/${assetId}/live-stream/start`);
  return data;
};

export const shutdownLiveStream = async (
  assetId: number
): Promise<LiveStreamInfrastructure> => {
  const { data } = await api().post(`/asset/${assetId}/live-stream/shutdown`);
  return data;
};

export const goLive = async (
  assetId: number
): Promise<LiveStreamInfrastructure> => {
  const { data } = await api().post(`/asset/${assetId}/live-stream/publish`);
  return data;
};

export const endBroadcast = async (
  assetId: number
): Promise<LiveStreamInfrastructure> => {
  const { data } = await api().post(`/asset/${assetId}/live-stream/unpublish`);
  return data;
};
