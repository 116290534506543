import { CancelToken } from 'axios';
import { Pagination, User, UserCreateForm } from 'types';
import { PAGINATION_SIZE, pageToSkip } from 'utils/pagination';
import api from './api';

export const getAdmins = async (cancelToken?: CancelToken): Promise<User[]> => {
  const { data } = await api().get(`/user`, {
    cancelToken,
  });

  return data as User[];
};

export const getAdminsPagination = async (
  page?: number
): Promise<Pagination<User>> => {
  const { data } = await api().get(`/user`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
    },
  });

  return data;
};

export const getRoles = async (
  cancelToken?: CancelToken
): Promise<string[]> => {
  const { data } = await api().get(`/role`, {
    cancelToken,
  });

  return data as string[];
};

interface GetClientsPaginationProps {
  page: number;
  query?: string;
  platformPartnerId?: string;
}

export const getClientsPagination = async ({
  page,
  query,
  platformPartnerId,
}: GetClientsPaginationProps): Promise<Pagination<User>> => {
  const { data } = await api().get<Pagination<User>>(`/supervisor/client`, {
    params: {
      skip: pageToSkip(page),
      limit: PAGINATION_SIZE,
      query,
      'filters[platformPartnerId]': platformPartnerId,
    },
  });

  return data;
};

export const addUser = async ({
  cancelToken,
  form,
}: {
  cancelToken?: CancelToken;
  form: UserCreateForm;
}) => {
  const { data } = await api().post(`/user`, form, {
    cancelToken,
  });

  return data as User;
};

export const signOutClientUser = async (userUuid: string) => {
  const { data } = await api().get(
    `/supervisor/client/${userUuid}/revoke-all-tokens`
  );

  return data;
};

export const signOutAdminUser = async (userUuid: string) => {
  const { data } = await api().get(`/user/${userUuid}/revoke-all-tokens`);

  return data;
};
