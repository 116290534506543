import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { createAsset, getAssetTypes } from 'actions/assets';
import { Asset, AssetCreateForm } from 'types';
import { Input, Select } from 'antd';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Space from 'components/Space';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import FormContainer from 'components/FormContainer';
import {
  useFetchActionObj,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import AssetParentChooser from 'components/AssetParentChooser';
import FormGroup from 'components/FormGroup';
import { errorNotify } from 'utils/errorNotify';
import { captureError } from 'utils/captureError';
import { AssetType } from '@laminar-product/client-commons-core/core';
import formatLabel from 'utils/formatLabel';

const AssetForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    unregister,
    control,
    getValues,
    setValue,
    watch,
  } = useForm<AssetCreateForm>();
  const { data: assetTypes } = useFetchActionObj(getAssetTypes);
  const { push } = useHistory();

  useEffect(() => {
    register('parent');

    return () => unregister('parent');
  }, [getValues, register, unregister]);

  const [sendForm, isLoading, , , error] = useSendAction<any, AssetCreateForm>(
    (form) => createAsset({ form }),
    {
      onDone: (asset) => {
        reset();
        push(`/assets/${asset.uuid}`);
      },
      onError: (error) => {
        errorNotify(error);
        captureError(error);
      },
    }
  );

  return (
    <PageContainer>
      <PageHeader title="Create new asset" />
      <FormContainer>
        <form onSubmit={handleSubmit(sendForm)}>
          <fieldset>
            <Space direction="vertical" expand>
              <FormGroup
                input={
                  <Controller
                    as={Input}
                    name="name"
                    control={control}
                    rules={{ required: 'Required' }}
                  />
                }
                label="Name"
                errorMessage={errors.name?.message}
              />
              <FormGroup
                input={
                  <Controller
                    defaultValue={AssetType.MOVIE}
                    as={Select}
                    style={{ width: '100%' }}
                    name="type"
                    control={control}
                    options={assetTypes?.map((type) => ({
                      value: type,
                      label: formatLabel(type),
                    }))}
                    rules={{ required: 'Required' }}
                  />
                }
                label="Type"
                errorMessage={errors.type?.message}
              />
              {[AssetType.EPISODE, AssetType.SEASON].includes(watch().type) && (
                <FormGroup
                  input={
                    <AssetParentChooser
                      onChoose={(asset?: Asset) =>
                        setValue('parent', asset?.id)
                      }
                    />
                  }
                  label="Parent"
                  errorMessage={
                    errors.parent?.type === 'missingParent'
                      ? 'You have to select parent asset'
                      : undefined
                  }
                />
              )}
              <Button htmlType="submit" disabled={isLoading}>
                Create
              </Button>
              <ErrorIndicator error={error} />
            </Space>
          </fieldset>
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default AssetForm;
