import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Collection, CollectionStatus } from 'types';
import {
  createCollection,
  getCollectionDetails,
  publishCollection,
  updateCollection,
} from 'actions/collections';
import { Button, Skeleton, Tag } from 'antd';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { RouteIdParams } from 'utils/routeParams';
import PageDetailsHeader from 'components/PageDetailsHeader';
import PageContainer from 'components/PageContainer';
import { captureErrorAndNotify } from 'utils/captureError';
import formatLabel from 'utils/formatLabel';
import notification from 'utils/notification';
import { CREATE_NEW_COLLECTION_ROUTE } from 'utils/constants';
import styles from './index.module.scss';
import CollectionGeneralDetails from './components/CollectionGeneralDetails';
import CollectionPublicDetails from './components/CollectionPublicDetails';
import CollectionAssets from './components/CollectionAssets';
import CollectionLinkTitle from './components/CollectionLinkTitle';
import Publishing from './Publishing';
import CollectionDisplayRulesForm from './components/CollectionDisplayRulesForm';

const defaultCollection: Collection = {
  metadata: undefined,
  regions: [],
  name: '',
  assets: [],
  link: { uuid: '', type: 'COLLECTION' },
  uuid: '',
  filters: { types: [], genres: [], moods: [], tags: [] },
  status: CollectionStatus.CREATED,
};

const CollectionDetails = () => {
  const { id } = useParams<RouteIdParams>();
  const { replace } = useHistory();
  const [collection, setCollection] = useState<Collection>(defaultCollection);
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);
  const isCreatingNewCollection = CREATE_NEW_COLLECTION_ROUTE === id;

  const moveToCollectionDetails = (uuid: string) =>
    replace(`/collections/${uuid}`);

  const submitSaveAction = isCreatingNewCollection
    ? createCollection
    : updateCollection;

  useEffect(() => {
    if (isCreatingNewCollection) {
      return;
    }

    getCollectionDetails(id).then(setCollection).catch(captureErrorAndNotify);
  }, [id, isCreatingNewCollection]);

  const isCollectionValid = useMemo(() => {
    const { link, assets, metadata, regions, name, filters } = collection;

    return !(
      (link?.type === 'CUSTOM_PAGE' && !link.uuid) ||
      !name ||
      !regions?.length ||
      !(metadata && Object.keys(metadata).length && metadata['en']) ||
      !(
        assets?.length ||
        Object.values(filters ? filters : {})
          .flat()
          .some((filter) => filter)
      )
    );
  }, [collection]);

  const validateForm = () => {
    setShouldValidate(true);

    if (!isCollectionValid) {
      throw new Error();
    }

    setShouldValidate(false);
  };

  const [onSave, isSaving] = useSendAction<Collection, void>(
    async () => {
      validateForm();
      return await submitSaveAction(collection);
    },
    {
      onError: captureErrorAndNotify,
      onDone: (collection: Collection) => {
        setCollection(collection);

        if (isCreatingNewCollection) {
          moveToCollectionDetails(collection.uuid);
        }

        notification.success({
          title: 'Success',
          description: 'The collection has been saved!',
        });
      },
    }
  );

  const [onSaveAndPublish, isSavingAndPublishing] = useSendAction(
    async () => {
      validateForm();
      const collectionResponse = await submitSaveAction(collection);

      await publishCollection(collectionResponse.uuid);

      setCollection({
        ...collectionResponse,
        status: CollectionStatus.PUBLISHED,
      });

      if (isCreatingNewCollection) {
        moveToCollectionDetails(collectionResponse.uuid);
      }
    },
    {
      onError: captureErrorAndNotify,
      onDone: () => {
        notification.success({
          title: 'Success',
          description: 'The collection has been saved and published!',
        });
      },
    }
  );

  if (!collection && !isCreatingNewCollection) {
    return (
      <PageContainer>
        <Skeleton />
      </PageContainer>
    );
  }

  return (
    <>
      <PageDetailsHeader>
        {collection?.name ?? 'New collection'}

        {collection?.status === CollectionStatus.PUBLISHED && (
          <div className={styles.tag}>
            <Tag color="green">{formatLabel(collection?.status)}</Tag>
          </div>
        )}
      </PageDetailsHeader>

      <div className={styles.pageWrapper}>
        <div className={styles.cardWrapper}>
          <CollectionGeneralDetails
            collection={collection}
            onCollectionChange={setCollection}
            shouldValidate={shouldValidate}
          />
          <CollectionPublicDetails
            onCollectionChange={setCollection}
            collection={collection}
            shouldValidate={shouldValidate}
          />
          <CollectionLinkTitle
            onCollectionChange={setCollection}
            collection={collection}
            shouldValidate={shouldValidate}
          />
          <CollectionAssets
            onCollectionChange={setCollection}
            collection={collection}
            shouldValidate={shouldValidate}
          />

          <CollectionDisplayRulesForm
            onCollectionChange={setCollection}
            collection={collection}
          />

          <div className={styles.buttons}>
            <Button type="primary" onClick={() => onSave()} loading={isSaving}>
              Save
            </Button>
          </div>
        </div>

        <Publishing
          collection={collection}
          onCollectionChange={setCollection}
          onCollectionSaveAndPublish={onSaveAndPublish}
          isPublishing={isSavingAndPublishing}
        />
      </div>
    </>
  );
};

export default CollectionDetails;
