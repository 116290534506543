import { SkipOption } from 'types/skipOptions';
import api from './api';

export const getContentSkipOptions = async (contentUuid: string) => {
  const { data } = await api().get<SkipOption[]>(
    `/content/${contentUuid}/frame`
  );
  return data;
};

export const updateContentSkipOptions = async (
  contentUuid: string,
  data: SkipOption[]
) => {
  await api().patch(`/content/${contentUuid}/frame`, data);
};

export const deleteContentSkipOptions = async (contentUuid: string) => {
  await api().delete(`/content/${contentUuid}/frame`);
};
