import { useFetchActionObj } from '@laminar-product/client-commons-core/hooks';
import { getInterestsList } from 'actions/interests';
import { useCallback, useMemo, useRef, useState } from 'react';
import _debounce from 'lodash/debounce';

const DEBOUNCE_TIME = 500;

type UseInterestsTableFilterProps<Item> = {
  onFilter: (search: string | number | boolean, item: Item) => boolean;
};

const useInterestsTableFilter = <Item extends Object>({
  onFilter,
}: UseInterestsTableFilterProps<Item>) => {
  const [search, setSearch] = useState('');
  const debounced = useRef(_debounce(setSearch, DEBOUNCE_TIME));

  const { data } = useFetchActionObj(
    useCallback(
      () =>
        getInterestsList({
          limit: 15,
          name: search,
          skip: 0,
        }),
      [search]
    )
  );

  const interestsWithNoPreference = useMemo(() => {
    if (!data) return { data: [], count: 0 };
    return {
      ...data,
      data: [
        ...data.data,
        {
          name: 'no_preference',
          translations: [
            {
              language: 'en',
              value: 'No Preference',
            },
          ],
        },
      ],
      count: data.count + 1,
    };
  }, [data]);

  const filters = useMemo(
    () => ({
      filters:
        interestsWithNoPreference?.data.map(({ name, translations }) => ({
          text:
            translations.find(({ language }) => language === 'en')?.value ||
            name,
          value: name,
        })) || [],
      filterSearch: (value: string) => {
        debounced.current(value);
        return true;
      },
      onFilter,
      filterMultiple: true,
    }),
    [interestsWithNoPreference, onFilter]
  );

  return { filters };
};

export default useInterestsTableFilter;
