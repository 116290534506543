import _take from 'lodash/take';
import React from 'react';
import { Tooltip } from 'antd';
import styles from './index.module.scss';

interface StringListProps {
  items: string[];
  maxShowCount?: number;
}

const StringList = ({ items, maxShowCount = 2 }: StringListProps) => {
  const names = _take(items, maxShowCount);
  const countNotShown = items.length - names.length;

  return (
    <div className={styles.regionsWrapper}>
      {names.join(', ')}
      {!!countNotShown ? (
        <>
          <Tooltip
            color="rgba(128,128,128,0.8)"
            title={items.slice(maxShowCount, items.length).join(', ')}
          >
            <div className={styles.badge}>+{countNotShown}</div>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default StringList;
