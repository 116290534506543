import React, { useCallback } from 'react';
import { Collection, CollectionStatus } from 'types';
import { unpublishCollection } from 'actions/collections';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { InfoCircleOutlined } from '@ant-design/icons';
import StatusCard from 'components/StatusCard';
import { captureErrorAndNotify } from 'utils/captureError';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import notification from 'utils/notification';

interface PublishingProps {
  collection: Collection;
  onCollectionChange: (collection: Collection) => void;
  onCollectionSaveAndPublish: (arg: void) => void;
  isPublishing?: boolean;
}

const Publishing = ({
  collection,
  onCollectionChange,
  onCollectionSaveAndPublish,
  isPublishing,
}: PublishingProps) => {
  const { canUse } = usePermission();

  const [unpublish, isUnpublishing] = useSendAction<void, void>(
    useCallback(() => unpublishCollection(collection.uuid), [collection.uuid]),
    {
      onError: captureErrorAndNotify,
      onDone: () => {
        onCollectionChange({
          ...collection,
          status: CollectionStatus.UNPUBLISHED,
        });

        notification.success({
          title: 'Success',
          description: 'The collection has been unpublished!',
        });
      },
    }
  );

  return (
    <StatusCard
      status={collection.status || CollectionStatus.CREATED}
      title="Collection status"
      titleIcon={<InfoCircleOutlined />}
      unpublishConfirmDialogContent={`Are you sure you want to unpublish ${collection.name}?`}
      publishConfirmDialogContent={`Are you sure you want to save and publish ${collection.name}?`}
      onPublish={() => onCollectionSaveAndPublish()}
      onUnpublish={() => unpublish()}
      canPublish={canUse([
        Permission.PUBLISH_COLLECTION,
        Permission.SAVE_COLLECTION,
      ])}
      canUnpublish={canUse([Permission.PUBLISH_COLLECTION])}
      isLoading={isPublishing || isUnpublishing}
      isSavingWhenPublishing
    />
  );
};

export default Publishing;
