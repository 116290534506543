import React, { useCallback, useEffect } from 'react';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import { getAILabelsInfo } from 'actions/ai';
import PageContainer from 'components/PageContainer';
import { useAssetContentContext } from 'contexts/assetContentContext';
import useAICast from 'hooks/useAICast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { fetchAiRecognitionStatusAction } from 'store/ai/actions';
import { selectAiFaceRecognitionStatus } from 'store/ai/selectors';
import { AIRecognitionStatus } from 'types/ai';
import AiCelebrities from './components/AICelebrities';
import AiLabels from './components/AILabels';
import styles from './index.module.scss';

const AssetAIMetadata = () => {
  const assetCelebrities = useAICast();
  const { sourceFileId } = useAssetContentContext();
  const dispatch = useDispatch();
  const aiFaceRecognitionStatus = useSelector<RootState, AIRecognitionStatus>(
    (state) => selectAiFaceRecognitionStatus(state, sourceFileId)
  );
  const [labels] = useFetchAction(
    useCallback(() => {
      if (sourceFileId) {
        return getAILabelsInfo(sourceFileId);
      }
      return Promise.resolve({});
    }, [sourceFileId])
  );

  useEffect(() => {
    let id: NodeJS.Timeout | null = null;
    if (aiFaceRecognitionStatus === 'processing' && sourceFileId) {
      id = setInterval(() => {
        dispatch(fetchAiRecognitionStatusAction.request({ sourceFileId }));
      }, 10000);
    }

    if (aiFaceRecognitionStatus === 'completed' && id) {
      clearInterval(id);
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [aiFaceRecognitionStatus, dispatch, sourceFileId]);

  return (
    <div className={styles.root}>
      <PageContainer>
        <AiCelebrities
          assetCelebrities={assetCelebrities}
          sourceFileId={sourceFileId}
          aiFaceRecognitionStatus={aiFaceRecognitionStatus}
        />
      </PageContainer>
      <PageContainer>
        <AiLabels labels={labels} />
      </PageContainer>
    </div>
  );
};

export default AssetAIMetadata;
