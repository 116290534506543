import React, { useState } from 'react';
import { Pagination } from 'antd';
import { AITimestampMap } from 'types/ai';
import { PAGINATION_SIZE } from 'utils/constants';
import styles from '../../index.module.scss';

interface AiLabelsProps {
  labels?: string[] | AITimestampMap;
}

const AiLabels = ({ labels }: AiLabelsProps) => {
  const [page, setPage] = useState(1);
  const startPagination = PAGINATION_SIZE * (page - 1);
  const convertLabels = (labels?: AITimestampMap | string[]): string[] => {
    if (!labels) return [];

    if (Array.isArray(labels)) {
      return labels;
    }

    return Object.keys(labels);
  };
  const convertedLabels = convertLabels(labels);
  const pagedList = convertedLabels.slice(
    startPagination,
    startPagination + PAGINATION_SIZE
  );

  return (
    <section>
      <h2>Labels</h2>
      {!!convertedLabels.length && (
        <>
          <ul className={styles.list}>
            <li className={styles.listHeading}>Name</li>
            {pagedList.map((c) => (
              <li className={styles.listItem} key={c}>
                {c}
              </li>
            ))}
          </ul>
          <Pagination
            pageSize={PAGINATION_SIZE}
            current={page}
            onChange={(page) => setPage(page)}
            total={convertedLabels.length}
            hideOnSinglePage
          />
        </>
      )}
    </section>
  );
};

export default AiLabels;
